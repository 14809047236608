import {
  Fab,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-material-ui";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { signup } from "../../../reducers/user/reducer";
import callApi from "../../../utils/apiUtil";
import { DEFAULT_COUNTRY_CODE, HTTP_METHODS, NUMBERS_REGEX } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      padding: '1rem',
      marginTop: "15vh",
      alignContent: "center",
      justifyContent: "center",
    },
    formItem: {
      minWidth: "20rem",
      marginBottom: "1rem",
    },
    submitBtn: {
      color: "#fff",
      backgroundColor: "#0C2D48",
      minWidth: "20rem",
      marginTop: "1rem",
    },
    redirectText: {
      marginTop: "1rem",
      justifyContent: "center",
      color: "#005D68",
    },
    redirectLink: {
      display: "inline",
      cursor: "pointer",
      color: theme.palette.primary.main,
      marginLeft: "0.5rem",
    },
    mainText: {
      fontWeight: 600,
      fontSize: 36,
      color: "#0C2D48",
      marginBottom: "3rem",
    },
  })
);

const Signup: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    publisherName: "",
    roleCategory: "publisher",
    password: "",
    confirmPassword: "",
    contactNumber: {
      phoneNumber: "",
      countryCode: DEFAULT_COUNTRY_CODE
    }
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    if (values.password === values.confirmPassword) {
      const data = {
        firstName: values.firstName.trim(),
        lastName: values.lastName?.trim(),
        emailId: values.email.trim().toLowerCase(),
        publisherName: values.publisherName.trim(),
        password: values.password.trim(),
        roleCategory: values.roleCategory.trim(),
        primaryLanguage: "English",
        contactNumber: values.contactNumber
      }
      const response = await callApi(
        PATHNAMES.IAM_USER_SIGNUP,
        HTTP_METHODS.POST,
        {
          body: JSON.stringify({ data }),
        }
      );
      if (response && response.accessToken && response.data) {
        localStorage.setItem("temp-token", response.accessToken);
        localStorage.setItem("userId", response.data.id);
        dispatch(signup(response.data));
        navigate("/verifyOtp");
      } else {
        snackbar({
          message: response.message || "something went wrong",
          variant: SnackBarVariant.ERROR,
        });
      }
    } else {
      snackbar({
        message: "Passwords do not match",
        variant: SnackBarVariant.ERROR,
      });
    }
  };

  // disable will be false when values are valid and true
  const isDisable = () => !validationSchema.isValidSync(values)


  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email().required("Valid email is required."),
    firstName: Yup.string().trim().required("First Name is required."),
    lastName: Yup.string().trim(),
    publisherName: Yup.string().trim().required("Publisher Name is required."),
    password: Yup.string().trim().min(6, 'Password is too short - should be 6 chars minimum.').required("Password is required."),
    confirmPassword: Yup.string().trim().min(6).oneOf([Yup.ref('password')], 'Passwords must match').required("Passwords must match."),
    contactNumber: Yup.object().shape({
      phoneNumber: Yup.string().trim().required("Phone number is required.")
        .matches(NUMBERS_REGEX, "Only Numbers are allowed, no other character please")
        .min(10, 'Must be exactly 10 digits')
        .max(10, 'Must be exactly 10 digits'),
      countryCode: Yup.string().trim().required("Countrycode is required."),
    }).required()
  });

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className={classes.formContainer} spacing={4} style={{ maxWidth: isSmallScreen ? '100%' : '50%' }}>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
          <Typography variant="h1" className={classes.mainText}>
            Create an account
          </Typography>
        </Grid>
        <Grid container xs={12} style={{ marginBottom: "1rem", justifyContent: "center" }}>
          <Grid container xl={6} lg={6} md={8} sm={12} xs={12}>
            <Grid item xl={3} lg={3} md={4} sm={4} xs={12} style={{ paddingTop: "1.2rem", paddingLeft: "1rem" }}>
              <Typography variant="h3" style={{ fontWeight: "bolder" }}>
                I am a
              </Typography>
            </Grid>
            <Grid item xl={9} lg={9} md={8} sm={8} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  id="roleSelect"
                  value={values.roleCategory}
                  onChange={(e) => {
                    if (e.target.value && typeof e.target.value === "string") {
                      setValues({
                        ...values,
                        roleCategory: e.target.value,
                      });
                    }
                  }}
                  style={{ marginTop: "0.5rem", width: "95%", height: "3rem" }}
                >
                  <MenuItem value={"publisher"} key={0}>
                    {"Publisher"}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={values}
            onSubmit={(data, { setSubmitting }) => {
              handleSubmit();
              setSubmitting(false);
            }}
          >
            {() => (
              <Form style={{ width: "100%" }}>
                <Grid container xs={12} spacing={4}>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Field
                      component={InputField}
                      fullWidth
                      required
                      className={classes.formItem}
                      label="First Name"
                      placeholder="First Name"
                      name="firstName"
                      InputProps={{
                        value: values.firstName,
                        onChange: handleChange,
                      }}
                    ></Field>
                  </Grid>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Field
                      component={InputField}
                      fullWidth
                      className={classes.formItem}
                      label="Last Name"
                      placeholder="Last Name"
                      name="lastName"
                      InputProps={{
                        value: values.lastName,
                        onChange: handleChange,
                      }}
                    ></Field>
                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={4}>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Field
                      component={InputField}
                      fullWidth
                      required
                      className={classes.formItem}
                      label="Email"
                      placeholder="Email"
                      name="email"
                      InputProps={{
                        value: values.email,
                        onChange: handleChange,
                      }}
                    ></Field>
                  </Grid>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Field
                      component={InputField}
                      fullWidth
                      required
                      className={classes.formItem}
                      label="Phone Number"
                      placeholder="Phone Number"
                      name="contactNumber.phoneNumber"
                      inputProps={{ maxLength: 10 }}
                      InputProps={{
                        value: values.contactNumber.phoneNumber,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            contactNumber: {
                              ...values.contactNumber,
                              phoneNumber: e.target.value.trim()
                            }
                          })
                        }
                      }}
                    ></Field>

                  </Grid>
                </Grid>
                <Grid container xs={12} spacing={4}>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12} >
                    <Field
                      component={InputField}
                      fullWidth
                      required
                      className={classes.formItem}
                      label="Publisher Name"
                      placeholder="Publisher Name"
                      name="publisherName"
                      InputProps={{
                        value: values.publisherName,
                        onChange: handleChange,
                      }}
                    ></Field>
                  </Grid>

                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12} >
                    <Field
                      component={InputField}
                      fullWidth
                      required
                      className={classes.formItem}
                      label="Password"
                      type="password"
                      placeholder="Password"
                      name="password"
                      InputProps={{
                        value: values.password,
                        onChange: handleChange,
                      }}
                    ></Field>
                  </Grid>
                </Grid>


                <Grid container xs={12} justify="center" spacing={4}>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12} >
                    <Field
                      component={InputField}
                      fullWidth
                      required
                      className={classes.formItem}
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      type="password"
                      name="confirmPassword"
                      InputProps={{
                        value: values.confirmPassword,
                        onChange: handleChange,
                      }}
                    ></Field>
                  </Grid>
                </Grid>

                <Grid xs={12} container justify="center" alignItems="center" >
                  <Fab
                    variant="extended"
                    size="medium"
                    style={{ width: "264px", right: "20px", backgroundColor: "#0C2D48", color: "#fff" }}
                    disabled={isDisable()}
                    className={classes.submitBtn}
                    type="submit"
                  >
                    Signup
                  </Fab>
                </Grid>
                <Grid item container xs={12} className={classes.redirectText} justify="center" alignItems="center" style={{ margin: "14px -18px" }} >
                  <Typography variant="body1">
                    Already have an account?
                    <span
                      className={classes.redirectLink}
                      onClick={() => navigate("/login", { replace: true })}
                    >
                      Log in instead
                    </span>
                  </Typography>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Signup;
