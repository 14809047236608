import { Box, Chip, Grid, IconButton, Paper, Tooltip, Typography, makeStyles } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import logo from "../../../assets/rachnayeLight.png";
import { IBook } from '../../../reducers/books/types';
import { IPublisher } from '../../../reducers/publishers/types';
import { IAppState } from '../../../store';
import callApi from '../../../utils/apiUtil';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import { replaceHyphen } from '../../../utils/utils';
import CircularLoader from '../../common/CircularLoader';
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import BooksGrid from '../Dashboard/BooksGrid';

const useStyles = makeStyles((theme) => ({
  blueTitle: {
    display: 'inline',
    color: '#777', // New color
  },
  bookDetailHeader: {
    fontWeight: 'bold',
    display: 'inline',
  },
  bookCoverContainer: {
    width: '10rem',
    height: '14rem',
    borderRadius: 4,
    position: 'relative',
  },
  bookCoverStyle: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: 4,
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  noPaddingAndMargin: {
    padding: '0rem',
    margin: '0rem',
  },
  hoverProperties: {
    transition: 'all 0.5s ease-out',
    '&:hover': {
      transform: 'scale3d(1.10, 1.10, 1)',
    },
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3rem',
  },
  iconStyle: {
    borderRadius: '0rem',
    backgroundColor: '#F5F5F5',
  },
  tooltip: {
    fontSize: '13px',
    maxWidth: 400
  },
  shareButton: {
    marginTop: '1rem'
    , "&:hover": {
      color: theme.palette.primary.main
    }
  },
  chipStyle: {
    margin: '0.5rem',
    marginLeft: '0rem'
  }
}));

const PortalUserPublisherDescriptionPage: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const snackbar = useSnackBar();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [publisherData, setPublisherData] = useState<IPublisher>();
  const [publisherBooks, setPublisherBooks] = useState<IBook[]>([]);
  const [topPublisherBooks, setTopPublisherBooks] = useState<IBook[]>([]);
  const [visible1, setVisible1] = useState<boolean>(false);
  const [visible2, setVisible2] = useState<boolean>(false);
  const [fetchingPublisherOnPageLoad, setFetchingPublisherOnPageLoad] = useState<boolean>(true);
  const [firstTwoLanguage, setFirstTwoLanguage] = useState<string[]>([]);
  const [firstTwoCategory, setFirstTwoCategory] = useState<string[]>([]);


  const getPublisherData = (publisherId: string) => {
    callApi(PATHNAMES.GET_PUBLISHER_BY_ID_FOR_USER(publisherId), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setFetchingPublisherOnPageLoad(false);
        res.data.categories = replaceHyphen(res.data.categories);
        res.data.languages = replaceHyphen(res.data.languages);
        setPublisherData(res.data);
        setFirstTwoCategory(res.data.categories.slice(0, Math.min(2, res.data.categories?.length)));
        setFirstTwoLanguage(res.data.languages.slice(0, Math.min(2, res.data.languages?.length)));
        callApi(PATHNAMES.GET_BOOKS_BY_PUBLISHER_ID_PORTAL(publisherId, ""), HTTP_METHODS.GET).then((res2) => {
          if (res2 && res2.success && res2.data) {
            setPublisherBooks(res2.data);
          }
        });
        callApi(PATHNAMES.GET_TOP_BOOKS_BY_PUBLISHER_ID_PORTAL(publisherId), HTTP_METHODS.GET).then((res3) => {
          if (res3 && res3.success && res3.data) {
            setTopPublisherBooks(res3.data);
          }
        });
      }
    });
  };
  const handleCopyLink = async () => {
    if (publisherData && publisherData.id) {
      const data = {
        id: publisherData.id,
        resourceType: "publisher"
      }
      const res = await callApi(PATHNAMES.CREATE_DYNAMIC_LINK, HTTP_METHODS.POST, {
        body: JSON.stringify({ data })
      });
      if (res && res.success && res.data) {
        const { shortLink } = res.data
        navigator.clipboard.writeText(shortLink)
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: "Link copied successfully"
        })
        return
      }
    } else {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: "Something went wrong while copying link"
      })
      return
    }
  }
  useEffect(() => {
    if (location && location.search && mobileUserState && mobileUserState.id) {
      const params = new URLSearchParams(location.search);
      const publisherId = params.get("id");

      if (publisherId) {
        getPublisherData(publisherId);
      }
    }
  }, [location, mobileUserState]);
  if (fetchingPublisherOnPageLoad) {
    return <CircularLoader message={'Fetching Publisher Details...'} />;
  }
  return (
    <Grid container xs={12} lg={12} style={{ backgroundColor: '#f7f7f7', marginBottom: '1rem' }}>
      <Grid container xs={12} md={9} lg={8} spacing={2}>
        <Grid item xs={12} md={4} lg={3} style={{ marginTop: '1.3rem', justifyContent: 'center', display: 'flex' }}>
          <Paper variant="outlined" className={classes.bookCoverContainer}>
            <img src={logo} alt="Rachnaye" width={161} height={224} className={classes.bookCoverStyle} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Box style={{ display: "flex", alignItems: "center", gap: "8rem" }}>
            <Typography variant="h1" style={{ fontWeight: 'bold', color: '#333', marginTop: '1rem', fontSize: '2.5em' }}>
              {publisherData && publisherData.name.toUpperCase()}
            </Typography>
            <IconButton onClick={handleCopyLink} className={classes.shareButton} title="Copy Share Link">
              <ShareIcon style={{ fontSize: "2rem" }} />
            </IconButton>
          </Box>
          <Typography style={{ display: 'flex' }}>
            <Typography variant="h3" style={{ color: '#777', marginTop: '1rem' }}>
              Books on Rachnaye :
            </Typography>
            <Typography variant="h3" style={{ color: 'black', marginTop: '1rem', paddingLeft: '0.3rem' }}>
              {` ${publisherData?.totalbooks}`}
            </Typography>
          </Typography>
          <Typography variant="h3" style={{ color: '#777', marginTop: "1rem", marginBottom: '0.5rem' }}>
            Published in Category (s):
          </Typography>
          {visible2 ? <>{
            publisherData?.categories?.map((item) => (
              <Chip label={item} className={classes.chipStyle} />
            ))}
            <Chip label="Show less" onClick={() => setVisible2(!visible2)} className={classes.chipStyle} />
          </> :
            <>{firstTwoCategory.map((item) => (
              <Chip label={item} className={classes.chipStyle} />
            ))}
              {publisherData?.categories?.length! > 2 && <Chip label="Show More" onClick={() => setVisible2(!visible2)} className={classes.chipStyle} />}
            </>}
          <Typography variant="h3" style={{ color: '#777', marginTop: '1rem', marginBottom: '0.5rem' }}>
            Published in Language (s):
          </Typography>
          {visible1 ? <>{
            publisherData?.languages?.map((item) => (
              <Chip label={item} className={classes.chipStyle} />
            ))}
            <Chip label="Show less" onClick={() => setVisible1(!visible1)} className={classes.chipStyle} />
          </> :
            <>{firstTwoLanguage.map((item) => (
              <Chip label={item} className={classes.chipStyle} />
            ))}
              {publisherData?.languages?.length! > 2 && <Chip label="Show More" onClick={() => setVisible1(!visible1)} className={classes.chipStyle} />}
            </>}
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} lg={4} style={{ padding: '1rem', maxHeight: '350px', overflow: 'auto', marginTop: '1rem' }}>
        <Typography variant="h2" style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Publisher Bio
        </Typography>
        <Typography variant='h4' paragraph={true}>
          {publisherData?.bio && publisherData?.bio.length > 0 ? (publisherData?.bio && publisherData?.bio?.length <= 150 ? (publisherData?.bio) : (<>
            {`${publisherData?.bio?.slice(0, 150)}`}
            <Tooltip arrow classes={{ tooltip: classes.tooltip }} title={publisherData?.bio || ""}>
              <span style={{ color: "#FF5C3E", textDecoration: 'underline' }}> {`...more `}</span>
            </Tooltip>
          </>
          )) : (<>-</>)}
        </Typography>
      </Grid>
      <Typography variant="h2" style={{ fontWeight: 'bold', color: 'black', marginTop: '4.5rem', paddingLeft: '1rem', paddingBottom: '0.5rem', textTransform: 'uppercase' }}>
        Top Reads from {publisherData?.name}
      </Typography>
      <Grid container xs={12} spacing={0} >
        <BooksGrid books={topPublisherBooks} />
      </Grid>
      <Typography variant="h2" style={{ fontWeight: 'bold', color: 'black', marginTop: '4.5rem', paddingLeft: '1rem', textTransform: 'uppercase' }}>
        More Books from {publisherData?.name}
      </Typography>
      <Grid container xs={12} spacing={0}>
        <BooksGrid books={publisherBooks} />
      </Grid>
    </Grid>
  );
};

export default PortalUserPublisherDescriptionPage;
