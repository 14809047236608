import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-material-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { IFormattedDistributor } from "../../../../reducers/distributors/types";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { DownloadFileNames, HTTP_METHODS, OrderItemTypes, SubOrderStatus } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import FlatPickerBar from "../../../common/FlatPickerBar";
import DownloadButton from "./DownloadButton";
import OrderAddress from "./OrderAddress";
import { Summary } from "./OrderSummary";


const useStyle = makeStyles((theme) => ({
  orderHeading: { fontFamily: "Poppins", fontWeight: 600 },
  heading: {
    fontWeight: 600,
    fontFamily: "Poppins",
    fontStyle: "SemiBold",
    fontSize: "14px",
    paddingBottom: "0rem",
  },
  heading2: {
    fontWeight: 600,
    fontFamily: "Poppins",
    fontStyle: "Bold",
    fontSize: "16px",
    paddingBottom: "0rem",
  },
  content: {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontStyle: "SemiBold",
    fontSize: "14px",
  },
  margin: {
    paddingTop: '0.4rem',
    width: "32rem",
  },
  inputField: {
    paddingTop: '1rem',
  },
  datePicker: {
    marginTop: '0.4rem',
    height: '20rem'
  },
  button: {
    color: "black!important",
    backgroundColor: "#FFD36E!important",
    fontSize: "10px",
    marginRight: "0.8rem",
    padding: "0.9rem",
    width: "auto",
    blockSize: "1rem",
  },
  subHeading: {
    fontWeight: 600,
    fontFamily: "Poppins",
    fontStyle: "SemiBold",
    fontSize: "16px",
    paddingBottom: "0.3rem",
  },
  paper: {
    "border-radius": "15px",
  },
  redirectLink: {
    borderBottom: '1px solid',
    display: 'inline',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 300
  },
  downloadContainer: {
    padding: '0.8rem 0.8rem'
  }
}));

const OrderDetails: React.FC = () => {
  const location = useLocation();
  const classes = useStyle();
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [order, setOrder] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [distributor, setDistributor] = useState<IFormattedDistributor[]>([]);
  const [selectedDistributor, setSelectedDistributor] = useState<string>()
  const [bookId, setBookId] = useState<string>("")
  const [trackingData, setTrackingData] = useState({
    courierAgencyName: "",
    consignmentId: "",
    eta: "",
    trackingUrl: "",
  });
  const userState = useSelector((state: IAppState) => state.user);

  useEffect(() => {
    if (location && location.search && userState && userState.spaceId) {
      const params = new URLSearchParams(location.search);
      const orderId = params.get("order");
      if (orderId) {
        callApi(PATHNAMES.GET_PUBLISHER_SUB_ORDER_BY_ID(userState.spaceId, orderId), HTTP_METHODS.GET).then((res) => {
          if (res && res.success && res.data && res.data) {
            setOrder(res.data);
            setBookId(res.data.bookId)
          }
        });
      }
    }
  }, [location, userState]);

  useEffect(() => {
    fetchDistributors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId, order?.orderType])

  const fetchDistributors = async () => {
    if (order && order.orderType) {
      const bookDistributorRes = await callApi(PATHNAMES.GET_DISTRIBUTORS_FOR_BOOK(userState.spaceId || "", bookId, order.orderType.toLowerCase()), HTTP_METHODS.GET)
      if (bookDistributorRes?.success && bookDistributorRes?.data) {
        if (order.orderType === 'PAPERBACK') {
          setDistributor(bookDistributorRes.data.filter((item: IFormattedDistributor) => (item.inventory.paperbackQuantity >= order.quantity)))
        } else {
          setDistributor(bookDistributorRes.data.filter((item: IFormattedDistributor) => (item.inventory.hardboundQuantity >= order.quantity)))
        }
      }
    }
  }

  const displayAuthors = () => {
    let nameStr = "";
    if (order) {
      if (order.book.authors && order.book.authors.length) {
        const namesArr = order.book.authors.map((author: any) => {
          return author.name;
        });
        if (namesArr.length < 2) {
          nameStr = namesArr.join(", ");
        } else {
          nameStr = namesArr.slice(0, 2).join(", ") + " ...more";
        }
      }
    }
    return nameStr;
  };

  const raiseShippingRequest = () => {
    if (userState && userState.spaceId && order) {
      const data = { distributorId: selectedDistributor }
      callApi(PATHNAMES.RAISE_SHIPPING_REQUEST(userState.spaceId, order.id),
        HTTP_METHODS.POST, {
        body: JSON.stringify({ data })
      }
      ).then((res) => {
        if (res && res.success && res.data && userState.spaceId && order.id) {
          setOpen(false);
          setLoading(false);
          setTrackingData({
            courierAgencyName: "",
            consignmentId: "",
            eta: "",
            trackingUrl: "",
          });
          callApi(PATHNAMES.GET_PUBLISHER_SUB_ORDER_BY_ID(userState.spaceId, order.id), HTTP_METHODS.GET).then((res) => {
            if (res && res.success && res.data && res.data) {
              setOrder(res.data);
            }
          });
        }
      });
    }
  }
  
  const markOrderAsShipped = () => {
    if (userState && userState.spaceId) {
      const data = {
        courierAgencyName: trackingData.courierAgencyName,
        consignmentId: trackingData.consignmentId,
        shippedOn: moment().toISOString(),
        eta: trackingData.eta,
        trackingUrl: trackingData.trackingUrl,
        distributorId: selectedDistributor,
        rachnayeOwnedBook: false  // keeping rachnayeOwnedBook as false since publisher won't has acces to mark book as rachnaye book , it is superadmin's work
      }
      callApi(
        PATHNAMES.MARK_ORDER_AS_SHIPPED(userState.spaceId, order.id),
        HTTP_METHODS.POST, {
        body: JSON.stringify({ data }),
      }
      ).then((res) => {
        if (res && res.success && res.data && userState.spaceId && order.id) {
          setOpen(false);
          setLoading(false);
          setTrackingData({
            courierAgencyName: "",
            consignmentId: "",
            eta: "",
            trackingUrl: "",
          });
          callApi(PATHNAMES.GET_PUBLISHER_SUB_ORDER_BY_ID(userState.spaceId, order.id), HTTP_METHODS.GET).then((res) => {
            if (res && res.success && res.data && res.data) {
              setOrder(res.data);
            }
          });
        }
      });
    }
  };

  const markOrderAsDelivered = () => {
    if (userState && userState.spaceId) {
      setLoading(true);
      callApi(
        PATHNAMES.MARK_ORDER_AS_DELIVERED(userState.spaceId, order.id),
        HTTP_METHODS.PATCH
      ).then((res) => {
        if (res && res.success && res.data && userState.spaceId && order.id) {
          setLoading(false);
          setTrackingData({
            courierAgencyName: "",
            consignmentId: "",
            eta: "",
            trackingUrl: "",
          });
          callApi(PATHNAMES.GET_PUBLISHER_SUB_ORDER_BY_ID(userState.spaceId, order.id), HTTP_METHODS.GET).then((res) => {
            if (res && res.success && res.data && res.data) {
              setOrder(res.data);
            }
          });
        }
      });
    }
  };

  const downloadFile = (data: string, fileName: string) => {
    const link = document.createElement("a");
    link.download = `${fileName}.pdf`;
    link.href = data;
    link.click();
  }

  const validationSchema = Yup.object().shape({});

  if (!order) {
    return <CircularProgress />;
  }


  return (
    <Grid container xs={12} lg={12} xl={12} spacing={2} style={{ marginRight: "1.5rem" }}>
      {/* content part-1  */}
      <Grid item xs={12} lg={12} xl={12}>
        <Paper elevation={2} className={classes.paper}>
          {/* heading and button  */}
          <Grid item style={{ padding: "0rem 1rem 0rem 1rem" }}>
            <Grid container xs={12} lg={12} xl={12} spacing={2} style={{ margin: "0rem", padding: "0rem", borderBottom: "1px solid #C0C0C0" }}>
              <Grid container item xs={12} md={6} lg={6} xl={6} style={{ padding: "0.6rem 0.6rem 0rem 0.6rem" }}>
                <Typography variant="h2" className={classes.orderHeading}>
                  Order Ref No :</Typography>
                <Typography variant="h2" style={{ display: "inline", paddingLeft: "1rem" }}>{`${order.referenceNumber}`}</Typography>
              </Grid>
              <Grid container item xs={12} md={6} lg={6} xl={6} style={{ padding: "0.6rem 0.6rem 0rem 0.6rem" }} justify={isSmallScreen ? "flex-start" : "flex-end"}>
                <Typography variant={"h3"} style={{ margin: "0rem 0.5rem 1rem 1rem" }} className={classes.heading2}>
                  ORDER STATUS :
                </Typography>
                {order.status === SubOrderStatus.CANCELLED && (
                  <Typography
                    variant={"subtitle2"}
                    style={{
                      color: "#EF5350",
                      display: "inline",
                      margin: isSmallScreen
                        ? "0rem 1rem 1rem 1rem"
                        : "0rem 1rem 1rem 0rem",
                      padding: "0rem",
                    }}
                  >
                    {order.status?.replace("_", " ").toUpperCase()}
                  </Typography>
                )}
                {order.status === SubOrderStatus.CONFIRMED && (
                  <Typography
                    variant={"subtitle1"}
                    style={{
                      color: "#FFD36E",
                      display: "inline",
                      margin: isSmallScreen
                        ? "0rem 1rem 1rem 1rem"
                        : "0rem 1rem 1rem 0rem",
                      padding: "0rem ",
                    }}
                  >
                    {order.status.toUpperCase()}
                  </Typography>
                )}
                {order.status === SubOrderStatus.INTRANSIT && (
                  <Typography
                    variant={"subtitle1"}
                    style={{
                      color: "#FF5C3E",
                      display: "inline",
                      margin: isSmallScreen
                        ? "0rem 1rem 1rem 1rem"
                        : "0rem 1rem 1rem 0rem",
                      padding: "0rem ",
                    }}
                  >
                    {order.status?.replace("_", " ")?.toUpperCase()}
                  </Typography>
                )}
                {order.status === SubOrderStatus.COMPLETED && (
                  <Typography
                    variant={"subtitle1"}
                    style={{
                      color: "#5CD89F",
                      display: "inline",
                      margin: isSmallScreen
                        ? "0rem 1rem 1rem 1rem"
                        : "0rem 1rem 1rem 0rem",
                      padding: "0rem ",
                    }}
                  >
                    {order.status.toUpperCase()}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* detail and address*/}
          <Grid item style={{ padding: "0rem 1rem 0rem 1rem" }}>
            <Grid
              container
              xs={12}
              lg={12}
              xl={12}
              spacing={2}
              style={{
                margin: "0rem",
                padding: "0rem 0rem 0rem 0.5rem",
                borderBottom: "1px solid #C0C0C0",
              }}
            >
              <Grid
                item
                xs={6}
                md={4}
                lg={3}
                xl={2}
                style={{ padding: "0.1rem 0.6rem 0.6rem 0.6rem" }}
              >
                <Summary
                  title={"DATE & TIME"}
                  content={moment(order.createdAt).format("DD/MM/YYYY")}
                />
                <Typography variant={"body1"} className={classes.content}>
                  {moment(order.createdAt).format("HH:mm A")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                lg={3}
                xl={2}
                style={{ padding: "0.1rem 0.6rem 0.6rem 0.6rem" }}
              >
                <Summary
                  title={"ORDER BY"}
                  content={`${order.user.firstName} ${order.user.lastName}`}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={6}
                xl={8}
                style={{ padding: "0.1rem 0.6rem 0.6rem 0.6rem" }}
              >
                <OrderAddress
                  title={"DELIVER TO"}
                  address={order.order?.shippingAddress}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* shipping  detail*/}
          <Grid item style={{ padding: "0rem 1rem 1rem 1rem" }}>
            <Grid
              container
              xs={12}
              lg={12}
              xl={12}
              spacing={2}
              style={{ margin: "0rem", padding: "0rem 0rem 0rem 0.5rem" }}
            >
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={4}
                style={{ padding: "0.1rem 0.6rem 0.6rem 0.6rem" }}
              >
                <OrderAddress
                  title={"SHIPPING ADDRESS"}
                  address={order.order?.shippingAddress}
                  recipientName={order.order?.shippingAddress?.recipientName}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={4}
                style={{ padding: "0.1rem 0.6rem 0.6rem 0.6rem" }}
              >
                <OrderAddress
                  title={"BILLING ADDRESS"}
                  address={order.order?.billingAddress}
                  recipientName={order.order?.billingAddress?.recipientName}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* order items part-2  */}
      <Grid item xs={12} lg={12} xl={12}>
        <Paper elevation={2} className={classes.paper}>
          {/* heading*/}
          <Grid
            container
            xs={12}
            lg={12}
            xl={12}
            spacing={2}
            style={{
              margin: "0rem",
              padding: "0rem",
              borderBottom: "1px solid #C0C0C0",
            }}
          >
            <Grid
              container
              item
              xs={12}
              md={6}
              lg={12}
              xl={12}
              style={{ margin: "0rem", padding: "0.6rem 0.6rem 0rem 1.6rem" }}
            >
              <Typography variant="body1" className={classes.subHeading}>
                {" "}
                Order Items :
              </Typography>
            </Grid>
          </Grid>
          {/* detail row*/}
          <Grid item style={{ padding: "0rem 1rem 0rem 1rem" }}>
            <Grid
              container
              xs={12}
              lg={12}
              xl={12}
              spacing={2}
              style={{ margin: "0rem", padding: "0rem 0rem 0.7rem 0.5rem" }}
            >
              <Grid
                item
                xs={6}
                md={4}
                lg={2}
                xl={2}
                style={{ padding: "0rem 0.6rem 0rem 0.6rem" }}
              >
                <Summary title={"BOOK"} content={order.book.title} />
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                lg={2}
                xl={2}
                style={{ padding: "0rem 0.6rem 0rem 0.6rem" }}
              >
                <Summary title={"AUTHOR"} content={displayAuthors()} />
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                lg={2}
                xl={2}
                style={{ padding: "0rem 0.6rem 0rem 0.6rem" }}
              >
                <Summary title={"LANGUAGE"} content={order.book.language} />
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                lg={2}
                xl={2}
                style={{ padding: "0rem 0.6rem 0rem 0.6rem" }}
              >
                <Summary title={"ITEM PRICE"} content={`₹ ${order.unitPrice}`} />
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                lg={2}
                xl={2}
                style={{ padding: "0rem 0.6rem 0rem 0.6rem" }}
              >
                <Summary title={"QUANTITY"} content={order.quantity} />
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                lg={2}
                xl={2}
                style={{ padding: "0rem 0.6rem 0rem 0.6rem" }}
              >
                <Summary title={"Total Earning"} content={`₹ ${order.publisherShare}`} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* order shipment details part-4 */}
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Paper elevation={2} className={classes.paper}>
          {/* heading*/}
          <Grid container xs={12} lg={12} xl={12} spacing={2} style={{ margin: "0rem", padding: "0rem", borderBottom: "1px solid #C0C0C0" }}>
            <Grid container item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingBottom: "0rem" }}>
              <Typography
                variant="body1"
                className={classes.subHeading}
                style={{ padding: "0rem 0rem 0.4rem 1rem" }}
              >
                {" "}
                Shipping Details{" "}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={6} md={6} lg={6} xl={6} justify="flex-end" style={{ padding: "0.3rem 0.6rem 0.3rem 0.6rem" }}>
              {order.status === SubOrderStatus.CONFIRMED && !userState?.space?.requiresShipping && (
                <Button
                  variant="contained"
                  disabled={loading}
                  size="small"
                  className={classes.button}
                  onClick={() => setOpen(true)}
                >
                  Mark as Shipped
                </Button>
              )}
              {order.status === SubOrderStatus.INTRANSIT && !userState?.space?.requiresShipping && (
                <Button
                  variant="contained"
                  disabled={loading}
                  size="small"
                  className={classes.button}
                  onClick={markOrderAsDelivered}
                >
                  Mark as Delivered
                </Button>
              )}
            </Grid>
          </Grid>
          {/* detail row*/}
          <Grid item style={{ padding: "0rem 1rem 0rem 1rem" }}>
            {order?.status === SubOrderStatus.CONFIRMED ? (
              <Grid container xs={12} lg={12} xl={12} style={{ margin: "0rem", padding: "0rem 0rem 0.7rem 0rem", height: "10.2rem" }}
                justify="center"
                alignItems="center"
              >
                <Typography variant="h4" style={{ fontWeight: 300 }}>
                  {" "}
                  No shipping details to show. Please "Mark order as shipped" to
                  add shipping detail
                </Typography>
              </Grid>
            ) : (
              <Grid container xs={12} lg={12} xl={12} spacing={2} style={{ margin: "0rem", padding: "0rem 0rem 0.7rem 0rem", height: "auto", }}
              >
                <Grid item xs={6} md={4} lg={2} xl={2}>
                  <Summary title={"DATE"} content={moment(order?.delivery?.shippedOn).format("DD-MM-YYYY") || "-"} />
                </Grid>
                <Grid item xs={6} md={4} lg={2} xl={2}>
                  <Summary
                    title={"COURIER"}
                    content={order?.delivery?.courierAgencyName || "-"}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={2} xl={2}>
                  <Summary
                    title={"TRACKING ID"}
                    content={order?.delivery?.consignmentId || "-"}
                  />
                </Grid>
                <Grid item xs={6} md={4} lg={2} xl={2}>
                  <Summary
                    title={"ETA"}
                    content={
                      moment(order?.delivery?.eta).format("DD-MM-YYYY") || "-"
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={3} xl={3}>
                  <Summary
                    title={"TRACKING URL"}
                    url={order?.delivery?.trackingUrl || "-"}
                  />
                </Grid>

                {/* <Grid item xs={6} md={4} lg={4} xl={3}>
                  <Summary
                    title={"DISTRIBUTOR NAME"}
                    content={order?.distributor?.name || "-"}
                  />
                </Grid><Grid item xs={6} md={4} lg={4} xl={3}>
                  <Summary
                    title={"CONTACT NO"}
                    content={`${order?.distributor?.contactPerson?.contact?.countryCode}-${order?.distributor?.contactPerson?.contact.phoneNumber}` || "-"}
                  />
                </Grid><Grid item xs={6} md={4} lg={3} xl={3}>
                  <Summary
                    title={"ADDRESS"}
                    content={`${order?.distributor?.address.city}, ${order?.distributor?.address.state}` || "-"}
                  />
                </Grid> */}
                <Grid container xs={12}>
                  {order?.shiprocketManifestUrl &&
                    <Grid item xs={12} md={6} lg={4} xl={4} className={classes.downloadContainer} >
                      <DownloadButton
                        action={() => downloadFile(order.shiprocketManifestUrl, DownloadFileNames.MANIFEST)}
                        showIcon={true} title={'Download Manifest'} />
                    </Grid>}
                  {order?.shiprocketLabelUrl &&
                    <Grid item xs={12} md={6} lg={4} xl={4} className={classes.downloadContainer}>
                      <DownloadButton
                        action={() => downloadFile(order.shiprocketLabelUrl, DownloadFileNames.LABEL)}
                        showIcon={true} title={'Download Label'} />
                    </Grid>}
                  {order?.shiprocketInvoiceUrl &&
                    <Grid item xs={12} md={12} lg={4} xl={4} className={classes.downloadContainer}>
                      <DownloadButton
                        action={() => downloadFile(order.shiprocketInvoiceUrl, DownloadFileNames.INVOICE)}
                        showIcon={true} title={'Download Shipping Invoice'} />
                    </Grid>}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Grid container xs={12}>
            <Typography
              variant="body1"
              className={classes.subHeading}
              style={{ padding: "0.6rem" }}
            >
              Add Shipping Details{" "}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container xs={12}>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={trackingData}
              onSubmit={(data, { setSubmitting }) => {
                setLoading(true);
                if (userState.space?.requiresShipping) {
                  // raiseShippingRequest();
                } else {
                  markOrderAsShipped();
                }
                setSubmitting(false);
              }}
            >
              {(formikProps) => (
                <Form style={{ width: "100%" }}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid container xs={7} style={{ paddingLeft: '0.6rem' }}>
                      {distributor.length > 0 ?
                        <Autocomplete
                          options={distributor}
                          getOptionLabel={(option) => `${option.name} `}
                          renderOption={(option) => `${option.name.toUpperCase()} - ${option.address.city}, ${option.address.state} - ${order.orderType === OrderItemTypes.PAPERBACK ? `Paperback Quantity:${option.inventory.paperbackQuantity}` : `Hardbound Quantity:${option.inventory.hardboundQuantity}`} `}
                          style={{ width: 750 }}
                          onChange={(event: any, newValue: IFormattedDistributor | null) => {
                            if (newValue && newValue.id) {
                              setSelectedDistributor(newValue.id)
                            }
                          }}
                          renderInput={(params) => (
                            <TextField required {...params} variant="outlined" className={classes.margin} placeholder='Select a distributor' />
                          )}
                        /> :
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '1rem' }}>
                          <Typography variant='h4' className={classes.redirectLink} onClick={() => navigate(`view-book?book=${order.bookId}`)}> {`Please update ${order.orderType.toLowerCase()} inventory before proceeding >`}</Typography>
                        </Grid>
                      }
                    </Grid>
                    {!userState.space?.requiresShipping &&
                      <Grid container xs={5} style={{ paddingTop: "0.4rem" }}>
                        <Field
                          component={InputField}
                          fullWidth
                          required
                          label="Courier Agency Name"
                          placeholder="Courier Agency Name"
                          name="courierAgencyName"
                          InputProps={{
                            value: trackingData.courierAgencyName,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (e.target.value && typeof e.target.value === "string") {
                                setTrackingData({
                                  ...trackingData,
                                  courierAgencyName: e.target.value
                                })
                              };
                            },
                          }}
                        ></Field>
                      </Grid>}
                    {!userState.space?.requiresShipping && <>
                      <Grid item xs={4} className={classes.inputField} >
                        <Field
                          component={InputField}
                          fullWidth
                          required
                          label="Consignment Id"
                          placeholder="Consignment Id"
                          name="consignmentId"
                          InputProps={{
                            value: trackingData.consignmentId,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setTrackingData({
                                ...trackingData,
                                consignmentId: e.target.value || "",
                              });
                            },
                          }}
                        ></Field>
                      </Grid>
                      <Grid item xs={4} className={classes.inputField}>
                        <Field
                          component={InputField}
                          fullWidth
                          label="Tracking URL"
                          name="trackingUrl"
                          InputProps={{
                            value: trackingData.trackingUrl,
                            onChange: (
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setTrackingData({
                                ...trackingData,
                                trackingUrl: e.target.value || "",
                              });
                            },
                          }}
                        ></Field>
                      </Grid>
                      <Grid item xs={4} className={classes.datePicker} >
                        <FlatPickerBar
                          required
                          handleDateChange={(value: Date) => {
                            setTrackingData({
                              ...trackingData,
                              eta: moment(value)
                                .startOf("day")
                                .toISOString(),
                            });
                          }}
                          label="Estimated Time of Arrival"
                          identifier="eta"
                          name="eta"
                          minDate={moment()
                            .startOf("day")
                            .toDate()}
                          value={trackingData.eta}
                        />
                      </Grid>
                    </>}
                  </Grid>
                  <Grid container item xs={12} justify="flex-end">
                    <Fab
                      size="medium"
                      variant="extended"
                      className="blackBackButton"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </Fab>
                    <Box pl={2}></Box>
                    <Fab
                      size="medium"
                      variant="extended"
                      type="submit"
                      disabled={
                        userState.space?.requiresShipping ? !selectedDistributor ? true : false :
                          trackingData.courierAgencyName === "" ||
                            trackingData.consignmentId === "" ||
                            trackingData.eta === "" ||
                            selectedDistributor === ''
                            ? true
                            : false
                      }
                    >
                      Proceed
                    </Fab>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default OrderDetails;
