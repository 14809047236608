import { FormControlLabel, Grid, Paper, Switch, Typography } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IMobileUserState } from "../../../reducers/mobileUser/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../utils/common.interface";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import CircularLoader from "../../common/CircularLoader";
import { DataTable } from "../../common/Datatable/Datatable";
import { Pagination } from "../../common/Datatable/types";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
const VerifiedContentCreators: React.FC = () => {
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.user);

  const [fetchingUsers, setFetchingUsers] = useState<boolean>(true);
  const [users, setUsers] = useState<IMobileUserState[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(Pagination.DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState<number>(Pagination.DEFAULT_PAGE_SIZE);

  useEffect(() => {
    if (userState && userState.id && userState.spaceId) {
      setFetchingUsers(true);
      fetchContentCreatorsList({ pageNumber, pageSize })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState])

  const fetchContentCreatorsList = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args

    callApi(PATHNAMES.GET_ALL_CONTENT_CREATORS_FOR_ADMIN(userState.id, pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then((res) => {
      if (res && res.success && res.data) {
        setUsers(res.data.users);
        setTotalUsers(res.data.totalUsers);
        setPageNumber(res.data.pageNumber);
        setPageSize(res.data.pageSize)
      } else {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: res.message
        })
      }
      setFetchingUsers(false);
    })
  }

  const changeUsersVerifiedStatus = (userId: string) => {
    callApi(PATHNAMES.CHANGE_USERS_VERIFIED_CREATOR_STATUS(userState.id, userId), HTTP_METHODS.PATCH).then((res) => {
      if (res && res.success) {
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: res.message
        })
        fetchContentCreatorsList({ pageNumber, pageSize })
      } else {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: res.message
        })
      }
    })
  }

  const convertStatusInBool = (value: string) => value === "ACTIVE"

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      label: "NAME",
      name: "name"
    },
    {
      label: "PENNAME",
      name: "penName",
      options: {
        customBodyRender: (data: string) => data ? data : "-"
      }
    },
    {
      label: "PhoneNumber",
      name: "contactNumber",
      options: {
        customBodyRender: (data: any) => data.countryCode ? `${data.countryCode}-${data.phoneNumber}` : data.phoneNumber
      }
    },
    {
      label: "POSTS WRITTEN",
      name: "postsCount"
    },
    {
      label: "ACCOUNT STATUS",
      name: "status",
      options: {
        customBodyRender: (data: string) => <FiberManualRecordIcon
          style={{ paddingLeft: "1rem" }}
          fontSize="small"
          htmlColor={convertStatusInBool(data) ? "#5CD89F" : "#FF5C3E"}
        />
      }
    },
    {
      label: "VERIFIED",
      name: "isVerifiedContentCreator",
      options: {
        setCellProps: () => ({ style: { padding: '0rem' } }),
        customBodyRender: (value: boolean, tableMeta: any) => {
          return <>
            <FormControlLabel
              control={
                <Switch
                  checked={value}
                  onClick={(e: any) => {
                    e.stopPropagation()
                    changeUsersVerifiedStatus(tableMeta.rowData[0])
                  }}
                  value="checkedSwitch"
                />
              }
              label=""
            />
          </>
        }
      }
    }
  ]

  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    searchPlaceholder: "Search by name, or pen name, or post count",
    count: totalUsers,
  };

  if (fetchingUsers) {
    return <CircularLoader message="Fetching Content Creators .. :)" />
  }

  return (
    <Grid container xs={12}>
      <Paper elevation={0} style={{ width: "98%", padding: "1rem" }}>
        <Grid container xs={12}>
          <Grid container item xs={4}>
            <Typography variant="h2" style={{ fontWeight: 600, padding: "1rem" }}>
              Content Creators
              <Typography variant="h2" color="primary" style={{ display: "inline", marginLeft: "1rem" }}>
                {totalUsers}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <DataTable
            title={""}
            rows={users}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchContentCreatorsList}
          />
        </Grid>
      </Paper>
    </Grid>
  )
}

export default VerifiedContentCreators;