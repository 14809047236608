import {
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../../assets/rachnayeLight.png";
import OrderStatusIcon from "./OrderStatusIconMapping";
import { displayName } from "react-quill";
interface IProps {
  order: any,
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#e6e6e6",
    padding: "20px 10px",
    borderRadius: "4px",
  },
  fontSize: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  deliveryContainer: {
    backgroundColor: "white",
    padding: "1rem",
    borderRadius: "4px",
  },
  productContainer: {
    [theme.breakpoints.down('md')]: {
      justifyContent:"space-between",
      display: "flex",
    },
    backgroundColor: "#f5f5f5",
    padding: "10px 5px",
    borderRadius: "4px",
    marginTop: "1rem",
    marginBottom: "0.5rem",
    transition: "background-color 500ms",
    '&:hover': {
      backgroundColor: "#eaeaeb",
      cursor: "pointer"
    }
  },
  bookImage: {
    [theme.breakpoints.down('md')]: {
      width: 90,
      height: 120
    },
    [theme.breakpoints.up('md')]: {
      width: 135,
      height: 175
    },
  }
}))

const PortalOrderCard: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const classes = useStyles()
  const handleOrderClick = () => {
    // Navigate to DigitalBookDetail with the bookId
    if (props.order?.book?.id) {
      const subOrderId = props.order.id;
      navigate(`/portal/orderDetails/${subOrderId}`);
    }
    //Navigate to ReadSeries with seriesID
    else if (props.order?.series?.id) {
      const seriesId = props.order.id;
      navigate(`/portal/seriesOrderDetails/${seriesId}`, { state: props.order });
    }
  };

  return (
    <>
      <Grid container xs={12} className={classes.container} alignItems="center" justifyContent="center">
        <Grid container xs={12} lg={8} justifyContent="flex-start" className={classes.deliveryContainer}>
          <Grid container xs={12}
            alignItems="center"
            style={{
              backgroundColor: props.order.status.toUpperCase() === "CANCELLED" ? "#FF5C3E" : "#03a685",
              borderRadius: "4px",
              padding: "0.5rem"
            }}>
            <Grid item >
              <OrderStatusIcon status={props.order.status.toUpperCase()} style={{ fontSize: "2rem", display: "flex", color: "white" }} />
            </Grid>
            <Grid item style={{paddingLeft: "0.5rem", display: "flex" }} >
              <Grid item >
                <Typography className={classes.fontSize} variant="h2" style={{ fontWeight: "450", color: "white" }}>
                  {props.order?.orderStatus.replace("_", " ").toUpperCase() || props.order.status.replace("_", " ").toUpperCase()} | {`₹${props.order.totalAmount}`}
                </Typography>
              </Grid>
            </Grid>
            {props.order.events && <Grid item xs={12} style={{ paddingLeft: "0.5rem" }}>
              <Typography className={classes.fontSize} variant="h2" style={{ fontWeight: "400", color: "white" }}>
                {moment(props.order.events[props.order.events.length - 1].updatedAt).format('DD MMM, dddd, hh:mm A')}
              </Typography>
            </Grid>
            }
            {props.order.series && <Grid item xs={12} style={{ paddingLeft: "0.5rem" }}>
              <Typography className={classes.fontSize} variant="h2" style={{ fontWeight: "400", color: "white" }}>
                {moment(props.order.createdAt).format('DD MMM, dddd, hh:mm A')}
              </Typography>
            </Grid>
            }
          </Grid>

          <Grid container xs={12} className={classes.productContainer} spacing={1} onClick={handleOrderClick} >
            <Grid item xs={2}>
              <img className={classes.bookImage} alt={props.order?.book?.title || props.order?.series?.title} src={props.order?.book?.thumbnailFront || props.order?.series?.coverImage || logo} style={{ objectFit: "cover" }} />
            </Grid>
            <Grid item xs={8} md={9} style={{ marginLeft: "1rem"}} alignContent="space-between" spacing={4}>
              <Grid container xs={12} direction="column" spacing={1}>
                <Grid item>
                  <Typography className={classes.fontSize} variant="h2" style={{ fontWeight: "450" }}>Title : {props.order?.book?.title || props.order?.series?.title}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.fontSize} variant="h2" style={{ fontWeight: "450" }}>Quantity : {props.order?.quantity || 1}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.fontSize} variant="h2" style={{ fontWeight: "450" }}>Order# : {props.order.referenceNumber}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.fontSize} variant="h2" style={{ fontWeight: "450" }}>Unit Price : {`₹${props.order?.unitPrice || props.order?.subTotal}`}</Typography>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  )
}



export default PortalOrderCard;