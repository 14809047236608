import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IBook } from "../../../reducers/books/types";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import BookProductCard from "./BookProductCard";

const RecommendedBooks: React.FC = () => {
  const [books, setBooks] = useState<IBook[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchBooks();
  }, [])

  const fetchBooks = async () => {
    const res = await callApi(PATHNAMES.GET_RECOMMENDED_BOOKS_PORTAL(), HTTP_METHODS.GET)
    if (res && res.data) {
      setLoading(false);
      setBooks(res.data)
    }
  }

  return (
    <Grid container xs={12}>
      <Grid container xs={12} style={{marginBottom: "1rem"}}>
        <Typography variant='h2' style={{ fontWeight: "bolder" }}>
          Recommended Books
        </Typography>
      </Grid>
      {loading ? <CircularProgress /> :
      <Grid container xs={12} spacing={2}>
        {books.map((book) => {
          return (<Grid item xs={2}>
            <BookProductCard book={book} />
          </Grid>)
        })}
      </Grid>}
    </Grid>
  )
}

export default RecommendedBooks;