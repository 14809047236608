
import {
  CircularProgress,
  Card,
  CardMedia,
  Grid,
  Tooltip,
  makeStyles,
  Typography,
  TextareaAutosize,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IAuthor } from "../../../../reducers/books/types";
import EditIcon from "@material-ui/icons/Edit"
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import callApi from "../../../../utils/apiUtil";
import { PATHNAMES } from "../../../../utils/pathNames";
import { HTTP_METHODS, ResourceTypes } from "../../../../utils/constants";
import { ErrorHandler } from "../../../../utils/utils";
import { uploadFileToS3 } from "../../../../utils/s3Util";
import ImageUploader from "../../../common/ImageUploader";


interface IProps {
  bookId: string;
  publisherId: string;
  authors: IAuthor[];
  authorNumber: number;
  handleHideNavigation: () => void;
  fetchBook: (bookId: string) => void;
}

interface IAuthorUpdateInput {
  id: string;
  image: string;
}
const useStyles = makeStyles((theme) => ({
  subHeading: {
    fontSize: '12px',
    fontWeight: 500,
    color: "#808080"
  },
  subContent: {
    fontSize: '13px',
    fontWeight: 500,
    paddingTop: '0.5rem',
    color: "#000000",
  },
  tooltip: {
    fontSize: '13px',
    maxWidth: 400
  },
  editIcon: {
    fontSize: '1.2rem',
    cursor: 'pointer'
  },
  editButtonTooltip: {
    fontSize: '13px',
  },
  saveButton: {
    fontSize: '10px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: '1rem'
  },
  cancelButton: {
    fontSize: '10px',
    cursor: 'pointer',
  },
}))

const Author: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const snackBar = useSnackBar()
  const [authors, setAuthors] = useState<IAuthor[]>(props.authors);
  const [oldValues, setOldValues] = useState<IAuthor[]>(props.authors);
  const [key, setKey] = useState<number>(props.authorNumber)
  const [loading] = useState<boolean>(false);
  const [isBioEditable, setIsBioEditable] = useState<boolean>(false);
  const [wordCount, setWordCount] = useState<number>(0)

  useEffect(() => {
    if (props?.authors?.length) {
      handleAuthorValueChange(props.authors, setAuthors)
      handleAuthorValueChange(props.authors, setOldValues)
      setKey(props.authorNumber)
      if (props.authors?.length) {
        if (props.authors[props.authorNumber]?.bio) {
          const tempWordCount = props.authors[props.authorNumber]?.bio?.split(" ")?.length || 0
          setWordCount(tempWordCount)
        }
      }
    }
  }, [props.authors, props.authorNumber])

  const validateAuthor = async (author: IAuthor) => {
    try {
      if (!author.id) {
        throw new ErrorHandler('Author id is missing')
      }
      if (!author.bio) {
        throw new ErrorHandler('Please add author bio')
      }
      return { id: author.id, bio: author.bio }
    } catch (error) {
      throw error
    }
  }

  const uploadAuthorImage = async (file: File) => {
    const imageKey: string = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.AUTHOR_IMAGE,
      isAdmin: false,
      publisherId: props.publisherId
    });
    const authorUpdateInput: IAuthorUpdateInput = { id: authors[key]?.id, image: imageKey }
    updateAuthorImage(authorUpdateInput, props.bookId)
  }

  const updateAuthorImage = async (author: IAuthorUpdateInput, bookId: string) => {
    try {
      const data = { author }
      const response: any = await callApi(PATHNAMES.UPDATE_BOOK_AUTHORS_IMAGE(bookId), HTTP_METHODS.PATCH, {
        body: JSON.stringify({ data })
      })
      if (response && response.success && response.message) {
        props.fetchBook(props.bookId)
        snackBar({
          variant: SnackBarVariant.SUCCESS,
          message: response.message || "updated successfully",
        })
      } else {
        throw new ErrorHandler(response.message)
      }
    } catch (error: any) {
      snackBar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
    }
  }

  const updateAuthorBio = async (author: IAuthor, bookId: string) => {
    try {
      const validatedData = await validateAuthor(author)
      const data = {
        author: validatedData
      }
      const response = await callApi(PATHNAMES.UPDATE_BOOK_AUTHORS_BIO(bookId), HTTP_METHODS.PATCH, {
        body: JSON.stringify({ data })
      })
      if (response && response.success && response.message) {
        setIsBioEditable(false)
        props.fetchBook(props.bookId)
        props.handleHideNavigation()
        snackBar({
          variant: SnackBarVariant.SUCCESS,
          message: response.message || "updated successfully",
        })
      } else {
        throw new ErrorHandler(response.message)
      }
    } catch (error: any) {
      handleCancel()
      snackBar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
    }
  }

  const handleCancel = () => {
    setIsBioEditable(false)
    handleAuthorValueChange(oldValues, setAuthors)
    props.handleHideNavigation()
  }
  const handleEditbutton = () => {
    setIsBioEditable(true);
    props.handleHideNavigation()
  }

  // required for setting old value and initital value from props
  //direct assignment was causing JS reference error in props and props data was changing.
  const handleAuthorValueChange = (values: any[], setState: any) => {
    const tempArr = JSON.parse(JSON.stringify(values))
    setState(tempArr)
  }

  if (loading) {
    return (
      <Grid container style={{ paddingTop: '0.8rem', paddingLeft: '0.7rem', height: '10rem' }} justify="center" alignItems="center">
        <CircularProgress />
      </Grid>
    )
  }

  if (!authors?.length) {
    return (
      <Grid container style={{ paddingTop: '0.8rem', paddingLeft: '0.7rem', height: '10rem' }} justify="center" alignItems="center">
        <Typography variant='h2'> Please add Authors to the Book</Typography>
      </Grid>
    )
  }
  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={3} style={{ paddingTop: '0.8rem', paddingLeft: '0.7rem' }}>
      <Grid container item xs={6} sm={4} md={4} lg={4} xl={3} style={{ height: '10.5rem', padding: '0.6rem 0rem 0.6rem 0rem' }}>
        <Card style={{ maxWidth: '7rem' }}>
          {authors[key]?.image ?
            <CardMedia
              component="img"
              height="140"
              alt="Author image"
              title="Author image"
              image={authors[key]?.image}
            /> :
            <Grid container justify="center" alignContent="center" xs={12} style={{ height: 160 }}>
              <Typography variant='h3'>Add Image</Typography>
            </Grid>
          }
          <Grid container justify="center" >
            <ImageUploader id={'AuthorFrontCover'} handleSave={uploadAuthorImage} />
          </Grid>
        </Card>
      </Grid>

      <Grid container item xs={6} sm={8} md={8} lg={8} xl={9}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} direction="column" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: '1rem 0rem 0.5rem 0rem' }} >
            <Typography variant='h5' className={classes.subHeading}>AUTHOR NAME</Typography>
            <Typography variant='h6' className={classes.subContent}>{authors[key]?.name}</Typography>
          </Grid>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} alignItems='center'>
            <Grid item xs={6}>
              <Typography variant='h5' className={classes.subHeading}>ABOUT AUTHOR</Typography>
            </Grid>
            <Grid container xs={6} justify='flex-end'>
              {isBioEditable ?
                <>
                  <Typography variant="h4" className={classes.saveButton}
                    onClick={() => updateAuthorBio(authors[key], props.bookId)}
                  >
                    Save
                  </Typography>
                  <Typography variant="h4" className={classes.cancelButton}
                    onClick={handleCancel}>
                    Cancel
                  </Typography>
                </> :
                <Tooltip title="Edit about author" placement="bottom" classes={{ tooltip: classes.editButtonTooltip }}>
                  <EditIcon className={classes.editIcon} color='primary' onClick={handleEditbutton} />
                </Tooltip>
              }
            </Grid>
            {isBioEditable ?
              <TextareaAutosize
                rowsMin={6}
                rowsMax={6}
                className={classes.subContent}
                style={{ marginTop: "1rem", width: "98%", backgroundColor: "#f2f2f2" }}
                value={authors[key]?.bio}
                onChange={(e) => {
                  const currAuthor = authors[key]
                  currAuthor.bio = e.target.value
                  const authorArr = [...authors]
                  authorArr.splice(key, 1, currAuthor)
                  setAuthors(authorArr)
                }}
              />
              :
              wordCount < 10 ?
                <Typography variant='h6' paragraph={true} className={classes.subContent}>{authors[key]?.bio}</Typography>
                :
                <Typography variant='h6' paragraph={true} className={classes.subContent}>
                  {`${authors[key]?.bio?.slice(0, 150)}... `}
                  <Tooltip arrow classes={{ tooltip: classes.tooltip }} title={authors[key]?.bio || ""}><span style={{ color: "#FF5C3E", textDecoration: 'underline' }}> {`more `}</span></Tooltip>
                </Typography>
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default Author;