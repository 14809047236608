import { CardMedia, Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import RachnayeEssential from "../../assets/RachnayeEssential.svg";
import logo from "../../assets/rachnayeLight.png";


interface IBookImage {
  book: {
    id: string;
    title: string;
    thumbnailFront?: string;
    rachnayeEssential: boolean;
    preOrderAvailable?: boolean;
  },
  showTitle?: boolean
  className?: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
    bookCover: {
      position: "relative",
      width: "10rem",
      height: "14rem",
      borderRadius: 4,
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        width: "6rem",
        height: "9rem",
      }
    },
    img: {
      height: "1.2rem",
      [theme.breakpoints.down("xs")]: {
        height: "0.6rem",
      }
    },
    essentialDiv: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      bottom: 20,
      left: "50%",
      backgroundColor: "#FFD36E",
      width: "100%", transform: "translateX(-50%)",
    },
    proOrderText: {
      position: "absolute",
      color: "#ffae1a",
      bottom: "3.9rem",
      right: "5rem",
      fontWeight: 700,
      fontSize: "1.5rem",
      transform: "rotate(-90deg)",
      [theme.breakpoints.down("xs")]: {
        bottom: "1.7rem",
        right: "2.9rem",
        fontSize: "0.9rem",

      }
    }
  }))

const BookImage: React.FC<IBookImage> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate()


  return (
    <Grid item style={{ marginBottom: "1rem" }}>
      <div
        className={`${classes.bookCover} ${props.className}`}
        onClick={() => navigate(`/portal/book?id=${props.book.id}`)}
      >
        <CardMedia
          component="img"
          image={props.book.thumbnailFront || logo}
          title={props.book.title}
          style={{ filter: props.book.preOrderAvailable ? "blur(2px)" : "blur(0)" }}
          className={classes.bookCover}
        />
        {props.book.rachnayeEssential &&
          <div className={classes.essentialDiv}>
            <img alt={props.book.title} src={RachnayeEssential} className={classes.img} />
          </div>
        }
        {props.book.preOrderAvailable &&
          <Typography variant="h3" className={classes.proOrderText} >Preorder</Typography>
        }
        {
          props.showTitle &&
          <Typography variant="h4" style={{ padding: "1rem 0rem" }}>{props.book.title} </Typography>
        }
      </div>
    </Grid>

  )

};

export default BookImage;