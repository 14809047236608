import { FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Typography } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { defaultUserAddress } from "../../../../reducers/mobileUser/constants";
import { updateMobileUser } from "../../../../reducers/mobileUser/reducer";
import { IAddress } from "../../../../reducers/mobileUser/types";
import { IOrderResponse } from "../../../../reducers/orders/types";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import theme from "../../../common/Theme";
import EditUserAddress from "../../Portal/Profile/EditUserAddress";


const useStyles = makeStyles(() => ({
  borderStyle: {
    borderRadius: 4,
    border: "1px solid #9e9694"
  },
  addressHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
  }
}))

export const DEFAULT_ADDRESS_TYPES = {
  SHIPPING: "SHIPPING",
  BILLING: "BILLING",
  BOTH: "SHIPPING-BILLING"
};

interface IProps {
  order: IOrderResponse
  digitalOnly: boolean
  addAddressInOrder: (addressId: string) => void;
}

const OrderAddressContainer: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);

  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (mobileUserState?.addresses?.length) {
      const arr = mobileUserState.addresses.map((addr) => {
        // Create a new object with the existing properties of addr and the added isDefault property
        return {
          ...addr,
          isDefault: mobileUserState.defaultShippingAddress === addr.id ? true : false
        };
      }).sort((a, b) => b.isDefault ? 1 : -1);

      setAddresses(arr)
      setSelectedAddress(arr[0].id)
      props.addAddressInOrder(arr[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState]);

  const handleClose = () => {
    setOpen(false)
    if (mobileUserState?.id) {
      callApi(PATHNAMES.GET_MOBILE_USER(mobileUserState.id), HTTP_METHODS.GET).then((res) => {
        if (res && res.success && res.data) {
          dispatch(updateMobileUser(res.data));
        }
      })
    }
  }

  if (props.digitalOnly) {
    //don't show address container for digital buy
    return <></>
  }

  return (
    <Grid item xs={12} className={classes.borderStyle} style={{ marginTop: '1rem' }}>
      {/* HEADER  */}
      <Grid container xs={12} style={{ padding: '0.5rem 1rem' }}>
        <Grid item xs={8}>

          <div className={classes.addressHeaderContainer}>
            <LocationOnIcon style={{ fontSize: '2rem' }} />
            <Typography variant="h3" style={{ paddingLeft: '0.6rem' }}>{`Select a delivery address`}</Typography>
          </div>

        </Grid>
        <Grid container xs={4} justify="flex-end">
          <Typography
            variant="h3"
            style={{ fontWeight: "bold", marginBottom: "1rem", color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => setOpen(true)}
          >
            + Add New Address
          </Typography>
        </Grid>
      </Grid>

      {/* MAIN CONTAINER */}

      {/* SHOW ADDRESS DETAILS IF PRESENT */}
      <Grid container xs={12} >
        <Grid item xs={12} style={{ padding: '0.2rem 1.5rem' }}>
          <RadioGroup
            row
            name="address"
            value={selectedAddress}
            onChange={(event: any) => {
              setSelectedAddress(event.target.value)
              props.addAddressInOrder(event.target.value)
            }}
          >
            {
              addresses.map((item) => {
                return (
                  <FormControlLabel
                    style={{ marginTop: "1rem" }}
                    value={item.id}
                    control={<Radio />}
                    label={<Typography variant="h3">{`${item.recipientName}, ${item.address1}, ${item.city}, ${item.state}, Pincode-${item.pincode}, ${item.country}`}</Typography>}
                  />
                )
              })
            }
          </RadioGroup>
        </Grid>
      </Grid>
      <EditUserAddress
        open={open}
        onClose={handleClose}
        address={defaultUserAddress}
        addToOrder={true}
        addAddressInOrder={props.addAddressInOrder}
      />
    </Grid>
  )
}

export default OrderAddressContainer;