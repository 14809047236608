import { Grid, Typography } from '@material-ui/core';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import callApi, { buildQueryParamFromObject } from '../../../utils/apiUtil';
import { IFiltersAndSorting } from '../../../utils/common.interface';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import CircularLoader from '../../common/CircularLoader';
import { DataTable } from '../../common/Datatable/Datatable';
import { Pagination } from '../../common/Datatable/types';

const LessQuantityBooks: React.FC = () => {
  const [orders, setOrders] = useState<any[]>([]);
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [hasOrder, setHasOrder] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  // FETCH ORDER
  useEffect(() => {
    fetchLessQuantityBooks({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    count: totalOrders,
    searchPlaceholder: "Search by publisher, book",
  };

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      label: "Book",
      name: "book",
      options: {
        customBodyRender: (data: any) => data.title
      }
    },
    {
      label: "Publisher",
      name: "publisher",
      options: {
        customBodyRender: (data: any) => data.name
      }
    },
    {
      label: "Distributor",
      name: "name",
    },
    {
      label: "Paperback",
      name: "paperQuantity",
      options: {
        customBodyRender: (data: any) => typeof data === "number" ? data : "not available"
      }
    },
    {
      label: "hardbound",
      name: "harboundQuantity",
      options: {
        customBodyRender: (data: any) => typeof data === "number" ? data : "not available"
      }
    },

  ]

  const fetchLessQuantityBooks = (args: IFiltersAndSorting) => {
    const queryParam = buildQueryParamFromObject({
      ...args,
      // filters: [{ columnName: "title", }]
    })

    callApi(PATHNAMES.GET_SUPERADMIN_LESS_QUANTITY_BOOKS(queryParam), HTTP_METHODS.GET).then((bookResponse) => {
      if (bookResponse && bookResponse.success && bookResponse.data) {
        setOrders(bookResponse.data.bookRes);
        setTotalOrders(bookResponse.data.totalBooksCount)
        if (!hasOrder) {
          setHasOrder(bookResponse.data.bookPresent)
        }
      }
    })
    setLoading(false)
  }


  if (loading) {
    return <CircularLoader />
  }

  return (
    <Grid container xs={12} style={{ paddingRight: "2rem" }}>
      {!hasOrder ?
        <Grid item xs={12} style={{ padding: "2rem 0rem" }}>
          <Typography variant="h3">
            There are no books which has less quantity than 2.
          </Typography>
        </Grid>
        :
        <Grid container xs={12}>
          <DataTable
            title={`Books having less quantity (${totalOrders})`}
            rows={orders}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchLessQuantityBooks}
          />
        </Grid>
      }
    </Grid>
  )
}

export default LessQuantityBooks;