import {
  makeStyles,
  Typography,
  Grid,
  Link
} from "@material-ui/core";
import React from "react";

const useStyle = makeStyles((theme) => ({
  orderHeading: { fontFamily: 'Poppins', fontWeight: 600 },
  heading: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontStyle: 'SemiBold',
    fontSize: '12px',
    paddingBottom: "0.1rem",
    paddingTop: "0.1rem"
  },
  content: {
    fontFamily: 'Poppins',
    fontSize: '13px',
  },
  subHeading: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontStyle: 'SemiBold',
    fontSize: '14px',
    paddingBottom: "0.1rem"
  },
}))
interface IOrderSummaryProp {
  title: string;
  content?: string;
  url?: string
}
interface IOrderBillProp {
  title: string;
  content: string;
  bold?: boolean;
}


export const Summary = (props: IOrderSummaryProp) => {
  const classes = useStyle()

  return (<>
    <Typography variant={"h3"} color="textSecondary" className={classes.heading}>
      {props.title || ""}
    </Typography>
    <Typography variant={"body1"} className={classes.content}>
      {props.content || ""}
    </Typography>
    {props.url === "-" ?
      <Typography variant="body1"> {props.url}</Typography>
      :
      <Link className={classes.content} href={props.url} target="_blank" rel="noopener" underline="always">{props.url || ""}</Link>
    }
  </>
  )
}


export const OrderDetail = (props: IOrderBillProp) => {
  const classes = useStyle()

  return (<>
    <Grid item xs={9} style={{ padding: '0.7rem 0.7rem 0rem 0.7rem' }} >
      <Typography variant={props.bold ? "subtitle1" : "body1"} className={classes.content}>
        {props.title}
      </Typography>
    </Grid>
    <Grid container xs={3} style={{ padding: '0.7rem 0.7rem 0rem 0.7rem' }} justify="flex-end">
      <Typography variant={props.bold ? "subtitle1" : "body1"} className={classes.content}>
        {props.content}
      </Typography>
    </Grid>
  </>
  )
}