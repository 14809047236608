import { MuiThemeProvider } from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";
import React from "react";
import theme from "./Theme";

interface IDropzoneDialogProps {
  open: boolean;
  handleSave: (files: File[]) => void;
  deleteSelectedImage?: (file: File) => void;
  handleCloseDragDrop: () => void;
  filesLimit: number;
  title?: string;
  initialFiles?: any[];
}

export const DragDropImages = (props: IDropzoneDialogProps) => {
  const getFileLimitExceedCustomMessage = (filesLimit: number) => {
    return `Maximum ${filesLimit} images are allowed to upload.`
  }
  return (
    <MuiThemeProvider theme={theme}>
      <div className="dragDropDialouge">
        <DropzoneDialog
          open={props.open}
          onSave={props.handleSave}
          onDelete={props.deleteSelectedImage}
          acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
          initialFiles={props.initialFiles ? props.initialFiles : []}
          showPreviews={true}
          maxFileSize={5000000}
          onClose={props.handleCloseDragDrop}
          filesLimit={props.filesLimit || 10}
          dropzoneText={`Drag and drop a file or click (Maximum ${props.filesLimit} 
            ${props.filesLimit === 1 ? "image" : "images"} are allowed)`}
          maxWidth="md"
          dialogTitle={props.title ? props.title : `Upload Images`}
          showAlerts={false}
          getFileLimitExceedMessage={() => getFileLimitExceedCustomMessage(props.filesLimit)}
          previewGridProps={{
            container: { spacing: 0 }
          }}
          alertSnackbarProps={{
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }}
        />
      </div>
    </MuiThemeProvider>
  );
};
