import { Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { IAddress, IAddressCreate, IPinCodeDetails } from "../../../../reducers/mobileUser/types";
import callApi from "../../../../utils/apiUtil";
import { PATHNAMES } from "../../../../utils/pathNames";
import _ from "lodash";
import { HTTP_METHODS } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import PhoneNumber from "../../../common/PhoneNumber";
import { IContactNumber } from "../../../../reducers/orders/types";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";

interface IProps {
  open: boolean;
  address: IAddress | IAddressCreate;
  onClose: () => void;
  addToOrder?: boolean;
  addAddressInOrder?:(addressId: string) => void;
}

const EditUserAddress: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const [address, setAddress] = useState<IAddress>({
    recipientName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    contactNumber: {
      countryCode: "",
      phoneNumber: ""
    },
    id: ""
  });
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (props.address) {
      setAddress({
        id: props.address?.id ? props.address.id : "",
        recipientName: props.address.recipientName,
        address1: props.address.address1,
        address2: props.address.address2,
        city: props.address.city,
        state: props.address.state,
        country: props.address.country,
        pincode: props.address.pincode,
        contactNumber: {
          countryCode: props.address.contactNumber.countryCode,
          phoneNumber: props.address.contactNumber.phoneNumber
        }
      })
    }
  }, [props.address])

  const fetchPincodeDetail = async (pincode: string, dialCode: string) => {
    const response = await callApi(PATHNAMES.GET_PINCODE_DETAIL(pincode, dialCode), HTTP_METHODS.GET)
    if (response && response.success && response.data) {
      const { district, state, countryName }: IPinCodeDetails = response.data
      setAddress({
        ...address,
        city: district,
        state,
        country: countryName,
        pincode
      })
    }
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
  const searchPincode = useCallback(_.debounce(fetchPincodeDetail, 600),[])

  const handlePhoneNumberChange = (value: IContactNumber) => {
    setAddress({
      ...address,
      contactNumber: {
        countryCode: value.countryCode,
        phoneNumber: value.phoneNumber
      }
    })
  }

  const createUserAddress = async () => {
    const data = {
      address: {
        recipientName: address.recipientName,
        nickname: mobileUserState.firstName,
        companyName: "",
        address1: address.address1,
        address2: address.address2,
        street: "",
        city: address.city,
        state: address.state,
        country: address.country,
        pincode: address.pincode,
        contactNumber: address.contactNumber
      }
    }
    const response = await callApi(PATHNAMES.CREATE_USER_ADDRESS(), HTTP_METHODS.POST, {
      body: JSON.stringify({ data })
    })
    if (response && response.success && response.data?.id) {
      snackbar({
        variant: SnackBarVariant.SUCCESS,
        message: "Address updated successfully!"
      })
      props.onClose();
      if (props.addToOrder && props.addAddressInOrder) {
        props.addAddressInOrder(response.data.id)
      }
    } else {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: "Something went wrong!"
      })
    }
  }

  const updateAddress = async () => {
    if (address.id) {
      const data = {
        address: {
          recipientName: address.recipientName,
          nickname: mobileUserState.firstName,
          companyName: "",
          address1: address.address1,
          address2: address.address2,
          street: "",
          city: address.city,
          state: address.state,
          country: address.country,
          pincode: address.pincode,
          contactNumber: address.contactNumber
        }
      }
      const response = await callApi(PATHNAMES.UPDATE_USER_ADDRESS(address.id), HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      })
      if (response && response.success) {
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: "Address updated successfully!"
        })
        props.onClose();
      } else {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: "Something went wrong!"
        })
      }
    }
  }

  const handleSubmit = async () => {
    if (address.id) {
      updateAddress()
    } else {
      createUserAddress()
    }
  }

  return (
    <Grid container>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={false}
        maxWidth={isSmallScreen ? "lg" : "sm"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3">
            Add/Edit Address
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Full Name"
                placeholder="Full Name"
                variant="outlined"
                name="recipientName"
                InputProps={{
                  value: address.recipientName,
                  onChange: (e: any) => {
                    setAddress({
                      ...address,
                      recipientName: e.target.value
                    })
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Address Line 1"
                placeholder="Address Line 1"
                variant="outlined"
                name="address1"
                InputProps={{
                  value: address.address1,
                  onChange: (e: any) => {
                    setAddress({
                      ...address,
                      address1: e.target.value
                    })
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address Line 2"
                placeholder="Address Line 2"
                variant="outlined"
                name="address2"
                InputProps={{
                  value: address.address2,
                  onChange: (e: any) => {
                    setAddress({
                      ...address,
                      address2: e.target.value
                    })
                  }
                }}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                fullWidth
                label="Pincode"
                placeholder="Pincode"
                variant="outlined"
                name="pincode"
                required
                InputProps={{
                  value: address.pincode,
                  onChange: (e: any) => {
                    setAddress({
                      ...address,
                      pincode: e.target.value
                    })
                    searchPincode(e.target.value, mobileUserState.contactNumber?.countryCode || "91")

                  }
                }}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                fullWidth
                label="City"
                placeholder="City"
                variant="outlined"
                name="city"
                required
                InputProps={{
                  value: address.city,
                  onChange: (e: any) => {
                    setAddress({
                      ...address,
                      city: e.target.value
                    })
                  }
                }}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                fullWidth
                label="State"
                placeholder="State"
                variant="outlined"
                name="state"
                required
                InputProps={{
                  value: address.state,
                  onChange: (e: any) => {
                    setAddress({
                      ...address,
                      state: e.target.value
                    })
                  }
                }}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                fullWidth
                label="Country"
                placeholder="Country"
                variant="outlined"
                name="country"
                required
                InputProps={{
                  value: address.country,
                  onChange: (e: any) => {
                    setAddress({
                      ...address,
                      country: e.target.value
                    })
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <PhoneNumber
                required={false}
                handleOnChange={handlePhoneNumberChange}
                handleError={() => {}}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Fab variant="extended" size="medium" onClick={props.onClose} className="blackBackButton">
            Cancel
          </Fab>
          <Fab variant="extended" size="medium" onClick={handleSubmit}>
            Save
          </Fab>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default EditUserAddress;
