import userReducer from './reducers/user/reducer'
import { configureStore } from '@reduxjs/toolkit';
import { IIamUserState } from './reducers/user/types';
import sidebarReducer, { ISidebarState } from './reducers/sidebar/reducer';
import { IMobileUserState } from './reducers/mobileUser/types';
import mobileUserReducer from './reducers/mobileUser/reducer';

export interface IAppState {
  user: IIamUserState;
  mobileUser: IMobileUserState;
  sidebar: ISidebarState;
}

const store = configureStore({
  reducer: {
    user: userReducer,
    mobileUser: mobileUserReducer,
    sidebar: sidebarReducer
  }
})

export default store