import {
  Grid, Typography
} from "@material-ui/core";
import moment from "moment";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import callApi from "../../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../../utils/common.interface";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { ErrorHandler } from "../../../../utils/utils";
import { DataTable } from "../../../common/Datatable/Datatable";
import { Pagination } from "../../../common/Datatable/types";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";


const columns: any = [
  {
    label: "Order Id",
    name: "referenceNumber"
  },
  {
    label: "Status",
    name: "status",
    options: {
      customBodyRender: (data: string) => data.toUpperCase()
    }
  },
  {
    label: "ORDER BY",
    name: "user",
    options: {
      customBodyRender: (data: any) => data?.firstName + " " + data?.lastName,
    }
  }, {
    label: "Book Name",
    name: "book",
    options: {
      customBodyRender: (data: any) => data?.title,
    }
  },
  {
    label: "Order Type",
    name: "orderType"
  },
  {
    label: "Total Amount",
    name: "totalAmount",
    options: {
      customBodyRender: (data: number) => "₹" + data?.toLocaleString('en-IN')
    }
  },
  {
    label: "Order Date",
    name: "createdAt",
    options: {
      customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY, h:mm A")
    }
  }
]

const DistributorOrders: React.FC = () => {
  const snackBar = useSnackBar()
  const location = useLocation();

  const [orders, setOrders] = useState<any>([]);
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [hasOrders, setHasOrders] = useState<boolean>(true);

  const [publisherId, setPublisherId] = useState<string>("");
  const [distributorId, setDistributorId] = useState<string>("");

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const publisherId = params.get("publisher");
      const distributorId = params.get("distributor");
      if (publisherId) {
        setPublisherId(publisherId)
      }
      if (distributorId) {
        setDistributorId(distributorId)
      }
    }
  }, [location]);

  useEffect(() => {
    if (distributorId && publisherId) {
      fetchDistributorOrders({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributorId, publisherId]);

  const fetchDistributorOrders = async (args: IFiltersAndSorting) => {
    try {
      const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args
      const response = await callApi(PATHNAMES.GET_DISTRIBUTOR_ORDERS(distributorId, publisherId, pageNumber, pageSize,), HTTP_METHODS.POST, {
        body: JSON.stringify({
          data: {
            searchText,
            sortBy,
            applyFilter,
            filters
          }
        }),
      })

      if (response && response.success && response.message && response.data) {
        setOrders(response.data.orders)
        setTotalOrders(response.data.totalOrdersCount)
        // re-render only when we get new value
        setHasOrders(oldValue => oldValue === response.data.hasOrder ? oldValue : response.data.hasOrder)

      } else {
        throw new ErrorHandler(response.message)
      }
    } catch (error: any) {
      snackBar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
    }

  }

  const options: MUIDataTableOptions = {
    elevation: 0,
    count: totalOrders,
    searchPlaceholder: "Search by order Id, type, user, date [dd/mm/yy]",
  };


  return (
    <Grid container xs={12}>
      {!hasOrders ?
        <Typography variant='body1'>Distributor don't have any orders yet</Typography>
        :
        <DataTable
          title={`Orders (${totalOrders})`}
          rows={orders}
          columns={columns}
          options={options}
          hideSelectableRows={true}
          serverSide={true}
          fetchData={fetchDistributorOrders}
        />
      }
    </Grid>
  )
}

export default DistributorOrders;