export enum SelectableRows {
  MULTIPLE = "multiple",
  SINGLE = "single",
  NONE = "none"
}
export const Pagination = {
  DEFAULT_PAGE_SIZE: 10,
  DEFAULT_PAGE_NUMBER: 0,
  DEFAULT_PAGE_SIZE_OPTION: [10, 20, 30]
}

export const TableActions = {
  CHANGE_PAGE: "changePage",
  CHANGE_ROWS_PER_PAGE: "changeRowsPerPage",
  PROPS_UPDATE: "propsUpdate",
  SEARCH: "search",
  SORT: "sort",
  FILTER_CHANGE: "filterChange",
  FILTER_ACTION_RESET: "reset",
}
