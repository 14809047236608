import { Card, CardActions, CardMedia, Typography } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IBook } from "../../../reducers/books/types";
import logo from "../../../assets/rachnayeLight.png";
interface IProps {
  book: IBook;
}

const BookProductCard: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  return (
    <Card onClick={() => navigate(`/portal/book?id=${props.book.id}`)} style={{cursor: "pointer"}}>
      <CardMedia
        component="img"
        height="180"
        width="100"
        alt="Profile Image"
        title="Profile Image"
        image={props.book.thumbnailFront || logo}
      />
      <CardActions>
        <Typography style={{ marginTop: "1rem", color: "#808080", height: "2rem" }}>
          {props.book.title}
        </Typography>
        </CardActions>
    </Card>
  )
}

export default BookProductCard;