import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import AdminSettings from "./AdminSettings";
import PublisherSettings from "./PublisherSettings";

const Settings: React.FC = () => {
  const user = useSelector((state: IAppState) => state.user);

  const getSettingsPage = () => {
    if (user && user.role) {
      if (user.role.category === "publisher") {
        return <PublisherSettings />
      }
      if (user.role.category === "org") {
        return <AdminSettings />
      }
    }
  }
  return (
    <Grid container xs={12}>
      {getSettingsPage()}
    </Grid>
  )
}

export default Settings;