import { createStyles, Fab, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import AddSecondaryUser, { IUserCreate } from "./AddSecondaryUser";
import UserSummary from "./UserSummary";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "1rem",
      padding: "1rem",
      width: "100%",
      marginRight: "1rem"
    },
    addButton: {
      color: "black!important",
      backgroundColor: "#FFD36E!important",
      marginRight: "1rem",
    },
  })
);

const SecondaryUsers: React.FC = () => {
  const classes = useStyles();
  const [secondaryUsers, setSecondaryUsers] = useState<any[]>([]);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.user);

  useEffect(() => {
    if (userState && userState.id && userState.spaceId) {
      fetchSecondaryUsers(userState.spaceId)
    }
  }, [userState])

  const fetchSecondaryUsers = (publisherId: string) => {
    callApi(PATHNAMES.GET_SECONDARY_USERS(publisherId), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setSecondaryUsers(res.data);
      }
    })
  }
  const createUser = async (user: IUserCreate) => {
    callApi(PATHNAMES.CREATE_SECONDARY_IAM_USER, HTTP_METHODS.POST, {
      body: JSON.stringify({ data: user })
    }).then(() => {
      if (userState && userState.id && userState.spaceId) {
        fetchSecondaryUsers(userState.spaceId)
      }
    })
  }

  return (
    <Grid container xs={12}>
      <Paper className={classes.root} elevation={0}>
        <Grid container xs={12}>
          <Grid container item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Typography variant="h2" style={{ fontWeight: 600 }}>
              Secondary Users
            </Typography>
          </Grid>
          {userState?.role?.name === "superAdmin" &&
            <Grid container item xs={12} sm={12} md={6} lg={8} xl={8} justify="flex-end" >
              <Fab
                variant="extended"
                size="small"
                className={classes.addButton}
                onClick={() => setUploadModalOpen(true)}
              >
                Add New
              </Fab>
            </Grid>
          }
        </Grid>
        <Grid container xs={12} spacing={2} style={{ marginTop: '0.6rem' }}>
          {
            secondaryUsers.map((secondaryUser) => {
              return (
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Paper elevation={2} style={{ width: "95%", padding: "1rem" }}>
                    <UserSummary showUpdateRole={true} user={secondaryUser} fetchSecondaryUsers={fetchSecondaryUsers} />
                  </Paper>
                </Grid>
              )
            })
          }
        </Grid>
        <Grid container xs={12}>
          <AddSecondaryUser
            open={uploadModalOpen}
            handleClose={() => setUploadModalOpen(false)}
            createUser={createUser}
          />
        </Grid>
      </Paper>
    </Grid>
  )
}

export default SecondaryUsers;