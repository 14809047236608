import {
  CircularProgress,
  Grid,
  Typography
} from "@material-ui/core";
import { MUIDataTableMeta, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import callApi from "../../../../utils/apiUtil";
import { BookTypes, HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { DataTable } from "../../../common/Datatable/Datatable";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import CustomBookQuantityUpdate from "./CustomBookQuantityUpdater";

interface IDistributorBook {
  bookId: string;
  publisherId: string
  title: string;
  paperback: {
    quantity: number
    hasPaperback: boolean;
  },
  hardbound: {
    hasHardbound: boolean;
    quantity: number
  }
}

const DistributorBooks: React.FC = () => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [books, setBooks] = useState<IDistributorBook[]>([]);
  const [, setPublisherId] = useState<string>("");
  const [distributorId, setDistributorId] = useState<string>("");
  const snackBar = useSnackBar()

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const publisherId = params.get("publisher");
      const distributorId = params.get("distributor");
      if (publisherId) {
        setPublisherId(publisherId)
      }
      if (distributorId) {
        setDistributorId(distributorId)
      }
      if (distributorId && publisherId) {
        setLoading(true);
        fetchDistributorBooks(distributorId, publisherId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const fetchDistributorBooks = (distributorId: string, publisherId: string): void => {
    callApi(PATHNAMES.GET_DISTRIBUTORS_BOOKS(distributorId, publisherId), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setBooks(res.data);
        setLoading(false);
        snackBar({
          variant: SnackBarVariant.SUCCESS,
          message: res.message || "Books fetched successfully",
        })
      } else {
        setLoading(false);
        snackBar({
          variant: SnackBarVariant.ERROR,
          message: res.message || "Some error occured",
        })
      }
    })
  }

  const options: MUIDataTableOptions = {
    elevation: 0,
  };

  const columns: any = [
    {
      label: "ID",
      name: "bookId",
      options: {
        display: false,
        filter: false,
      }
    }, {
      label: "PublisherId",
      name: "publisherId",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      label: "Title",
      name: "title",
      options: {
        setCellProps: () => ({ style: { minWidth: "25rem" } }),
      }
    },
    {
      label: "PaperBack",
      name: "paperback",
      options: {
        setCellProps: () => ({ style: { minWidth: "25rem" } }),
        customBodyRender: (data: any, tableData: MUIDataTableMeta) => {
          return <CustomBookQuantityUpdate
            data={tableData}
            distributorId={distributorId}
            isAvailable={data.hasPaperback}
            quantity={data.quantity}
            bookType={BookTypes.PAPERBACK} />
        }
      }
    },
    {
      label: "Hardbound",
      name: "hardbound",
      options: {
        setCellProps: () => ({ style: { minWidth: "25rem" } }),
        customBodyRender: (data: any, tableData: MUIDataTableMeta) => {
          return <CustomBookQuantityUpdate
            data={tableData}
            distributorId={distributorId}
            isAvailable={data.hasHardbound}
            quantity={data.quantity}
            bookType={BookTypes.HARDBOUND} />
        }
      }
    },
  ]

  if (loading) {
    return (
      <Grid container xs={12} justify='center'>
        <CircularProgress />
      </Grid>
    )
  }
  return (
    <Grid container xs={12}>
      {!books.length ?
        <Typography variant='body1'>
          Distributor don't have any books in inventory
        </Typography> :
        <Grid container xs={12}>
          <DataTable
            title={`Books (${books.length})`}
            rows={books}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide={false}
            fetchData={() => null}
          />
        </Grid>
      }
    </Grid>
  )
}

export default DistributorBooks;