export default {
  primary: {
    light: "#5D6498",
    // main: "#464B72",
    main: "#FF5C3E",
    dark: "#2F324C",
    contrastText: "#ffffff"
  },
  secondary: {
    light: "#EDEDED",
    main: "#002856",
    dark: "#EDEDED",
    contrastText: "#ffffff"
  },
  tertiary: {
    main: "#73BA9B",
    dark: "#EDEDED"
  },
  yellow: {
    main: "#FFD36E",
  },
  info: {
    main: "#2A9CB7"
  },
  success: {
    main: "#479F4B",
    dark: "#EDEDED"
  },
  error: {
    light: "#EDEDED",
    main: "#D13135",
    dark: "#EDEDED"
  }
};
