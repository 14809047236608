import { Grid, Typography } from '@material-ui/core';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import callApi, { buildQueryParamFromObject } from '../../../utils/apiUtil';
import { IFiltersAndSorting } from '../../../utils/common.interface';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import CircularLoader from '../../common/CircularLoader';
import { DataTable } from '../../common/Datatable/Datatable';
import { Pagination } from '../../common/Datatable/types';
import moment from 'moment';

const NotifyMeForBookUsers: React.FC = () => {
  const [userBooks, setUserBooks] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasMoreUserBooks, setHasMoreUserBooks] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  // FETCH ORDER
  useEffect(() => {
    fetchNotifyMeForBooksUsers({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    count: totalCount,
    searchPlaceholder: "Search by user, publisher, book , bookType",
  };

  const columns: any = [
    {
      label: "User Name",
      name: "user",
      options: {
        customBodyRender: (data: any) => data.lastName ? `${data.firstName} ${data.lastName}` : data.firstName
      }
    },
    {
      label: "Contact Number",
      name: "user",
      options: {
        customBodyRender: (data: any) => `+${data.contactNumber.countryCode}-${data.contactNumber.phoneNumber}`
      }
    },
    {
      label: "Book",
      name: "bookTitle",
    },
    {
      label: "Book Type",
      name: "bookType",
    },
    {
      label: "Publisher",
      name: "publisherName",
    },
    {
      label: "CreatedAt",
      name: "createdAt",
      options: {
        customBodyRender: (data: any) => moment(data).format("DD/MM/YYYY, h:mm A")
      }
    },
  ]

  const fetchNotifyMeForBooksUsers = (args: IFiltersAndSorting) => {
    const queryParam = buildQueryParamFromObject({
      ...args,
    })

    callApi(PATHNAMES.GET_NOTIFY_ME_FOR_BOOKS_USERS(queryParam), HTTP_METHODS.GET).then((bookUserResponse) => {
      if (bookUserResponse && bookUserResponse.success && bookUserResponse.data) {
        setUserBooks(bookUserResponse.data.bookRes);
        setTotalCount(bookUserResponse.data.totalBooksCount)
        if (!hasMoreUserBooks) {
          setHasMoreUserBooks(bookUserResponse.data.bookPresent)
        }
      }
    })
    setLoading(false)
  }


  if (loading) {
    return <CircularLoader />
  }

  return (
    <Grid container xs={12} style={{ paddingRight: "2rem" }}>
      {!hasMoreUserBooks ?
        <Grid item xs={12} style={{ padding: "2rem 0rem" }}>
          <Typography variant="h3">
            There are not User who has asked to notify him for out of stock books
          </Typography>
        </Grid>
        :
        <Grid container xs={12}>
          <DataTable
            title={`User & Books To Notify (${totalCount})`}
            rows={userBooks}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchNotifyMeForBooksUsers}
          />
        </Grid>
      }
    </Grid>
  )
}

export default NotifyMeForBookUsers;