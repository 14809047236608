import { Avatar, Box, createStyles, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import ShareIcon from '@material-ui/icons/Share';
import Comment from '@material-ui/icons/Textsms';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS, ReactionOn, ReactionTypes } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { getInitials } from "../../../utils/utils";
import CircularLoader from "../../common/CircularLoader";
import GuestUsersNameChange from "../../common/GuestUsersNameChange";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import VideoPlayer from "../../common/VideoPlayer";
import CommentsDialogBoxPost from "./CommentsDialogBoxPost";
import PostViewsAndLike from "./PostViewsAndComment";

const useStyles = makeStyles((theme) =>
  createStyles({
    mainPage: {
      padding: "2rem 6rem"
    },
    userInfoHeader: {
      borderBottom: "1px solid black",
      paddingBottom: "0.7rem"
    },
    postTitle: {
      padding: '1rem 0rem',
      fontWeight: 300,
      fontSize: "1.4rem",
      wordBreak: "break-word",
      lineHeight: "30px"
    },
    imgBox: {
      padding: '0.4rem'
    },
    userNamebox: {
      padding: '0.2rem 0.6rem'
    },
    mainAvatar: {
      backgroundColor: "#FB8F67",
      width: "2.6rem",
      height: "2.6rem",
    },
    userPenNameText: {
      fontSize: "0.8rem"
    },
    shareButton: {
      "&:hover": {
        color: theme.palette.primary.main
      }
    },
    qContainer: {
      "& .ql-container": {
        fontSize: "1rem",
        fontFamily: theme.typography.h2.fontFamily,
        border: 'none',
        padding: "1rem 0rem"
      },
      "& .ql-editor": {
        lineHeight: "30px",
        overflowY: "visible",
        padding: "0.6rem 0rem",  // add padding when want to move quill editor data
      }
    },
  })
);


const ReadRachnaye: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const snackbar = useSnackBar();
  const [loading, setLoading] = useState<boolean>(true);
  const [askForGuestUsersInfoOpen, setAskForGuestUsersInfoOpen] = useState<boolean>(false);

  // content will be in json quill delta format 
  // it will change while writing in html string format becouse of compatibility and value change for component
  const [post, setPost] = useState<any>({
    content: JSON.stringify('')
  })
  const [openCommentBox, setOpenCommentBox] = useState<boolean>(false)

  // fetch post detail
  useEffect(() => {
    getPostData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, mobileUserState]);

  useEffect(() => {
    if (post.id) {
      setLoading(false)
    }
  }, [post]);


  const getPostData = () => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const postId = params.get("id");
      if (postId) {
        callApi(PATHNAMES.GET_POST_BY_ID(mobileUserState.id, postId), HTTP_METHODS.GET).then(res => {
          if (res && res.success && res.data) {
            setPost((v: any) => res.data)
          }
        })
      }
    }
  }

  const addLikeReactionToPost = () => {
    if (mobileUserState.firstName.includes("Guest_")) {
      setAskForGuestUsersInfoOpen(true)
      return
    }
    if (post.id) {
      callApi(PATHNAMES.ADD_REACTION(), HTTP_METHODS.POST, {
        body: JSON.stringify({
          data: {
            reactionOnId: post.id,
            reactionType: ReactionTypes.LIKE,
            reactionOn: ReactionOn.POST,
          }
        }),
      }).then(res => {
        if (res && res.success && res.data) {
          getPostData()
        }
      })
    }
  }


  // to handle closing of comment box when clicked outside comment Component
  useEffect(() => {
    const closeCommentDiv = () => {
      setOpenCommentBox(false)
    }
    document.body.addEventListener("click", closeCommentDiv)
    return () => document.body.removeEventListener("click", closeCommentDiv)
  }, []);

  // to handle opening and closing of comment box when clicked on comment icon on post view
  const handleCommentBoxOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    // if user is guest user ask for details before letting him comment on post
    if (mobileUserState.firstName.includes("Guest_")) {
      setAskForGuestUsersInfoOpen(true)
    } else {
      setOpenCommentBox(v => !openCommentBox)
    }
  }

  const handleCopyLink = async () => {
    if (post && post.id) {
      const data = {
        id: post.id,
        resourceType: "post"
      }
      const res = await callApi(PATHNAMES.CREATE_DYNAMIC_LINK, HTTP_METHODS.POST, {
        body: JSON.stringify({ data })
      });
      if (res && res.success && res.data) {
        const { shortLink } = res.data
        navigator.clipboard.writeText(shortLink)
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: "Link copied successfully"
        })
        return
      }
    } else {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: "Something went wrong while copying link"
      })
      return
    }
  }

  if (loading) {
    return <CircularLoader />
  }

  return (
    // PAGE CONTAINER
    <Grid container spacing={3} xs={12} sm={12} md={12} lg={12} xl={12} justify={openCommentBox ? "flex-start" : "center"} className={classes.mainPage}>
      {/* MIDDLE VIEW CONTAINER */}
      <Grid container xs={9} >
        {/* USER NAME AND INFO HEADER */}
        <Grid container xs={12} justify="space-between" className={classes.userInfoHeader}>
          <Grid container xs={9}>
            <Box className={classes.imgBox}>
              {post?.user?.profileImageUrl ?
                <Avatar alt={post?.user?.firstName || "user"} src={post?.user?.profileImageUrl} className={classes.mainAvatar} /> :
                <Avatar alt={post?.user?.firstName || "user"} className={classes.mainAvatar}>
                  {getInitials(`${post?.user?.firstName} ${post?.user?.lastName}`)}
                </Avatar>
              }
            </Box>
            <Box className={classes.userNamebox}>
              <Grid container alignItems="center" style={{ height: "100%" }}>
                <Grid item xs={12} >
                  <Typography variant="subtitle1">
                    {post?.user?.penName ? post?.user?.penName : `${post?.user?.firstName} ${post?.user?.lastName}`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid container alignItems="center" xs={1}>
            <IconButton onClick={handleCopyLink} className={classes.shareButton} title="Copy Share Link">
              <ShareIcon />
            </IconButton>
          </Grid>
        </Grid>

        {/* POST TITLE CONTAINER */}
        <Grid container xs={12} direction="column" justify="center" alignItems="center" style={{ minHeight: "30vh", }}>
          <Grid container justify="center" alignItems="center" style={{ width: "80%" }}>
            <Typography className={classes.postTitle}>
              {post.title}
            </Typography>
          </Grid>
          <Grid container justify="center" alignItems="center" style={{ paddingBottom: "0rem" }}>
            <Typography style={{ fontSize: "11px" }}>
              {`${moment(post.publishedAt).format("LL")}`}
            </Typography>
          </Grid>
          <Grid container justify="center" alignItems="center" style={{ marginTop: "1rem" }}>
            <PostViewsAndLike
              image={ThumbUpAltIcon}
              text={post.totalReactions}
              reactionGiven={post.isReactionAvailable}
              onClick={addLikeReactionToPost}
              style={{
                icon: { fontSize: "26px", },
                text: { fontSize: "20px", padding: "0rem 0.2rem" },
                mainDiv: { padding: "0.8rem 1.2rem", cursor: "pointer" }
              }} />
            <PostViewsAndLike
              image={VisibilityIcon}
              text={post.viewCount}
              style={{
                icon: { fontSize: "26px" },
                text: { fontSize: "20px", padding: "0rem 0.2rem" }

              }} />
            <div onClick={handleCommentBoxOpen} style={{ cursor: "pointer" }}>
              <PostViewsAndLike image={Comment}
                text={post.totalComments}
                style={{
                  icon: { fontSize: "26px" },
                  text: { fontSize: "20px", padding: "0rem 0.2rem" },
                  mainDiv: { padding: "0.8rem 1.2rem", }

                }} />
            </div>
          </Grid>
        </Grid>

        {/* POST CONTENT CONTAINER */}
        <Grid container xs={12}>

          {post.type === "video" ?
            <VideoPlayer videoLink={post.mediaUrl} />
            :
            post.content ?
              <ReactQuill
                preserveWhitespace
                readOnly
                theme="bubble"
                value={JSON.parse(post.content)}
                className={classes.qContainer}
              /> : <></>
          }

        </Grid>
      </Grid>

      <CommentsDialogBoxPost
        open={openCommentBox}
        postId={post.id}
        fetchPost={getPostData}
        totalComments={post.totalComments}
        handleClose={() => setOpenCommentBox(false)} />

      <GuestUsersNameChange open={askForGuestUsersInfoOpen} handleClose={() => setAskForGuestUsersInfoOpen(false)} titleMessage="Add your Info" subMessage="You are a guest user , you need to add your actual name before commenting on this Rachnaye" />

    </Grid>
  );
};

export default ReadRachnaye;
