
import { CircularProgress, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import callApi from '../../../utils/apiUtil';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from "../../../utils/pathNames";
import CustomizedSelects from './CustomizedSelects';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
      labels: {
        usePointStyle: true
      }
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (item: any) => ` ${item.dataset.label} : ₹ ${item.parsed.y} `,
        labelColor: (item: any) => {
          return {
            backgroundColor: item.dataset.backgroundColor,
            borderColor: '#FFF',
            borderWidth: 2,
            borderRadius: 2,
          };
        },

      }
    }
  },
  scales: {
    y: {
      ticks: {
        callback: (value: any) => `₹ ${value}`
      }
    }
  },
};

interface IPieStats {
  label: string[],
  income: {
    buyDigital: number[];
    rental: number[];
    paperback: number[];
    hardbound: number[];
  },
  expense: {
    bookMaintenanceCharges: number[];
    featuredBooks: number[];
    featuredPublisher: number[];
    featuredAuthor: number[];
    charges: number[];
    refund: number[];
  }
}

const useStyles = makeStyles(() => ({
  revenueText: {
    fontWeight: 600,
    fontSize: "14px", color:
      "#FF5C3E", marginTop: "6px"
  },
  header: {
    fontWeight: 600,
    padding: "1rem 0rem 0.3rem 1rem"
  },
  legendSpan: {
    position: "relative",
    top: "0.4rem"
  },
  legendTitle: {
    fontSize: "12px",
    fontWeight: "normal",
    color: "#808080"
  },
  typography: {
    fontSize: '9px',
    lineHeight: '10px',
    wordSpacing: 2
  }
}))
const IncomeVsExpense: React.FC = () => {
  const classes = useStyles()
  const userState = useSelector((state: IAppState) => state.user);
  const [selectedMonthCount, setSelectedMonthCount] = useState<number>(3);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [revenue] = useState<number>(0);
  const [income, setIncome] = useState<number[]>([]);
  const [expense, setExpense] = useState<number[]>([]);
  const [stats, setStats] = useState<IPieStats>({
    label: [],
    income: {
      buyDigital: [],
      rental: [],
      paperback: [],
      hardbound: [],
    },
    expense: {
      bookMaintenanceCharges: [],
      featuredBooks: [],
      featuredPublisher: [],
      featuredAuthor: [],
      charges: [],
      refund: [],
    }
  });

  const fetchPublisherData = async (spaceId: string) => {
    setIsLoading(true);
    try {
      const res = await callApi(PATHNAMES.PUBLISHER_INCOME_VS_EXPENSE_PIECHART(spaceId || "", selectedMonthCount), HTTP_METHODS.GET)
      if (res?.data) {
        setStats(res.data);
        const { incomeSum, expenseSum } = calculateSumOfIncomeAndExpenseFn(res.data)
        setIncome(incomeSum)
        setExpense(expenseSum)
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (userState && userState.spaceId) {
      fetchPublisherData(userState.spaceId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState, selectedMonthCount]);

  const calculateSumOfIncomeAndExpenseFn = (stats: IPieStats) => {
    let incomesArr = Object.values(stats.income)
    let expensesArr = Object.values(stats.expense)
    // add income/expense based on arr index [1,2]+[1,2] =[2,4] to show bar graph
    const incomeSum = incomesArr.reduce((a, b) => a.map((v, i) => v + b[i]))
    const expenseSum = expensesArr.reduce((a, b) => a.map((v, i) => v + b[i]))
    return {
      incomeSum, expenseSum
    }
  }

  const data = {
    labels: stats.label,
    datasets: [
      {
        label: 'INCOME',
        data: income,
        backgroundColor: '#5CD89F',
      },
      {
        label: 'EXPENSE',
        data: expense,
        backgroundColor: "#FFD36E",
      },

    ],
  };

  const handleMonthCount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedMonthCount(event.target.value as number);
  };
  return (
    <Paper elevation={5} style={{ width: "100%" }} >
      {isLoading ?
        <div style={{ marginLeft: '40%', marginTop: '30%' }}><CircularProgress /></div>
        :
        <>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} >
            <Typography variant="h3" className={classes.header}>
              INCOME VS EXPENSE
            </Typography>
          </Grid>
          {/* on loading, if income and expense is 0 then dont show filter 
              handled for new publisher having no data in current month
          */}
          {selectedMonthCount === 0 ? <></> :
            <Grid item xs={12} sm={12} md={12} lg={12} xl={5}>
              <CustomizedSelects value={selectedMonthCount} onChange={handleMonthCount} type={'lifetime2'} />
            </Grid>}
          {stats && revenue === 0 ?
            <>
              <Grid container xs={12} spacing={1} style={{ padding: '1rem' }}>
                <Bar options={options} data={data} />
              </Grid>
            </>
            :
            <Grid container justify="center" alignContent="center" style={{ marginTop: '20%' }}><Typography variant="h2">No Data to display</Typography></Grid>
          }
        </>
      }
    </Paper>
  );
};


export default IncomeVsExpense;

