import { Fab, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "../../../reducers/mobileUser/reducer";
import { IContactNumber } from "../../../reducers/orders/types";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS, USER_TYPES } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { getPlatformOSAndOSVersion, setOrGetDeviceId } from "../../../utils/utils";
import PhoneNumber from "../../common/PhoneNumber";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
const MobileUserLogin: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const [countryCode, setCountryCode] = useState<string>("")
  const [mobile, setMobile] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [otpSent, setOtpSent] = useState(false);
  const [isError, setIsError] = useState(false);

  const handlePhoneNumberChange = (value: IContactNumber) => {
    setCountryCode(value.countryCode)
    setMobile(value.phoneNumber)
  }
  const urlParams = new URLSearchParams(window.location.search);
  const requestedUrl = urlParams.get('requestedUrl');

  const confidentialNumber = (str: string) => {
    const length = str.length;
    const hiddenPart = "X".repeat(length - 4);
    return hiddenPart + str.slice(length - 4);
  };

  const hideEmailUsername = (str: string) => {
    const [username, domain] = str.split('@');
    const hiddenPart = 'x'.repeat(username.length - 2);
    const visiblePart = username.slice(0, 2);
    return visiblePart + hiddenPart + '@' + domain;
  };

  const handleGetError = (value: boolean) => {
    setIsError(value)
  }

  const handleSendOtpError = (res: any) => {
    let errorMsg = "Error Occoured"
    let variant = SnackBarVariant.ERROR
    if (res) {
      if (res.message && typeof res.message === 'string' && res.message.includes("Account not found")) {
        errorMsg = res.message;
        variant = SnackBarVariant.INFORMATION
      } else if (res.desc && res.desc.length) {
        errorMsg = res.desc[0]?.msg
      }
    }
    snackbar({
      message: errorMsg,
      variant,
    })
  }

  const sendOtp = async () => {
    if (!isError) {
      const res = await callApi(PATHNAMES.MOBILE_USER_SEND_OTP, HTTP_METHODS.POST, {
        body: JSON.stringify({
          data: {
            phoneNumber: mobile,
            countryCode: countryCode,
            email
          }
        })
      })
      if (res && res.success) {
        setOtpSent(true)
      } else {
        handleSendOtpError(res)
      }
    }
  }

  const platformInfo = getPlatformOSAndOSVersion()
  const verifyOtp = async () => {
    const response = await callApi(PATHNAMES.MOBILE_USER_VERIFY_OTP, HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          phoneNumber: mobile,
          countryCode: countryCode,
          otp: parseInt(otp),
          email
        },
        userAgentDetails: {
          deviceId: setOrGetDeviceId(),
          os: platformInfo.platformOS,
          osVer: platformInfo.osVersion,
          userAgent: platformInfo.userAgent,
        }
      })
    })
    if (response && response.success && response.accessToken && response.data.user) {
      localStorage.setItem("userId", response.data.user.id);
      localStorage.setItem("token", response.accessToken);
      localStorage.setItem("userType", USER_TYPES.MOBILE);
      dispatch(login(response.data.user));
      const from = requestedUrl|| location.state?.from || {pathname: "/portal/home"}
      navigate(from,{replace:true});
    } else {
      snackbar({
        message: response.message || "Invalid Credentials",
        variant: SnackBarVariant.ERROR
      });
    }
  }

  return (
    <Grid container xs={12} justify="center" spacing={2} style={{ padding: "1rem 0rem" }}>
      {!otpSent ? <>
        <Grid item xs={12} style={{ maxWidth: "20rem" }} >
          <PhoneNumber
            required={true}
            handleOnChange={handlePhoneNumberChange}
            handleError={handleGetError}
          />
        </Grid>
        {countryCode && countryCode !== "91" && <Grid item xs={12} style={{ maxWidth: "20rem", }} >
          <TextField
            fullWidth
            required
            label="Email"
            placeholder="Email"
            variant="outlined"
            name="email"
            InputProps={{
              value: email,
              onChange: (e: any) => {
                setEmail(e.target.value)
              }
            }}
          />
        </Grid>}
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
          <Fab
            variant="extended"
            size="medium"
            style={{ marginTop: "1rem", backgroundColor: "#0C2D48", width: "300px" }}
            onClick={sendOtp}
          >
            Receive OTP
          </Fab>
        </Grid>
      </> : <>
        <Grid>
          <Grid container xs={12} style={{ width: "20rem", margin: "0 0.5rem 0 0" }} spacing={4}>
            <NumericFormat
              value={otp}
              customInput={TextField}
              decimalScale={0}
              allowNegative={false}
              inputProps={{ maxLength: 6 }}
              variant="outlined"
              id="OTP"
              label="OTP"
              placeholder="Enter OTP received"
              required
              fullWidth
              onValueChange={(valueObj: NumberFormatValues) => {
                setOtp(valueObj.value)
              }}
            />
          </Grid>

          <Grid container xs={12} style={{ width: "20rem", }} justify="center">
            <Typography variant="body1" style={{ color: "#FF5C3E", marginTop: "0.4rem" }}>
              {countryCode === "91" ? `Otp sent on  ` + confidentialNumber(mobile) : `Otp sent on  ` + hideEmailUsername(email)}
            </Typography>
          </Grid>

        </Grid>

        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
          <Fab
            variant="extended"
            size="medium"
            style={{
              marginTop: "1rem",
              backgroundColor: "#0C2D48"
            }}
            onClick={verifyOtp}
          >
            Verify OTP
          </Fab>
        </Grid>
      </>}
    </Grid>
  )
};

export default MobileUserLogin