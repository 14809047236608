import React from "react";
import { useSelector } from "react-redux";

import { IAppState } from "../../../store";

export interface IAllowedRole {
  name: string;
  category: string;
}
// Authorization HOC
export const Authorization = (allowedRoles?: IAllowedRole[]) => (
  SecondaryComponent: React.ElementType
) => (MainComponent: React.ElementType) => {
  return function Authorization({ ...props }) {
    const userReducer = useSelector((state: IAppState) => state.user);
    if (
      (allowedRoles && allowedRoles.some((role) => {
        return role.name === userReducer?.role?.name && role.category === userReducer?.role?.category
      })) ||
      !allowedRoles
    ) {
      return <MainComponent {...props} />;
    }
    return <SecondaryComponent />;
  };
};

export const Null = () => {
  return <></>;
};

export const AccessDenied = () => {
  return (
    <>
      <h1>Access denied</h1>
    </>
  );
};

export enum UserRoles {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  PUBLISHER = "PUBLISHER",
  SUPERVISOR = "SUPERVISOR",
  ACCOUNTANT = "ACCOUNTANT",
  CUSTOMER_SUPPORT ="CUSTOMER_SUPPORT",
  EDITOR ="EDITOR",
  AUTHOR = "AUTHOR",
  DESIGNER ="DESIGNER",
  MARKETEER="MARKETEER",
  PROOF_READER = "PROOF_READER",
  SALES = "SALES"
}

export const UserRolesValue: { [key in UserRoles]: string } = {
  SUPER_ADMIN: "superAdmin",
  ADMIN: "admin",
  PUBLISHER: "publisher",
  SUPERVISOR: "supervisor",
  ACCOUNTANT: "accountant",
  CUSTOMER_SUPPORT:"customerSupport",
  EDITOR:"editor",
  AUTHOR:"author",
  DESIGNER:"designer",
  MARKETEER:"marketeer",
  PROOF_READER:"proofReader",
  SALES:"sales"
};

export const ALL_ALLOWED = [
  {
    name: UserRolesValue.SUPER_ADMIN,
    category: "org"
  },
  {
    name: UserRolesValue.SUPER_ADMIN,
    category: "publisher"
  },
  {
    name: UserRolesValue.ADMIN,
    category: "publisher"
  },
  {
    name: UserRolesValue.ACCOUNTANT,
    category: "publisher"
  },
  {
    name: UserRolesValue.SUPERVISOR,
    category: "publisher"
  },   
]

export const ALL_ALLOWED_EXCEPT_SUPERADMIN = [
  {
    name: UserRolesValue.SUPER_ADMIN,
    category: "publisher",
  },
  {
    name: UserRolesValue.ADMIN,
    category: "publisher",
  },
  {
    name: UserRolesValue.ACCOUNTANT,
    category: "publisher",
  },
  {
    name: UserRolesValue.SUPERVISOR,
    category: "publisher",
  },
];

export const ADMIN_ALLOWED = [
  {
    name: UserRolesValue.SUPER_ADMIN,
    category: "org"
  },
   {
    name:UserRolesValue.CUSTOMER_SUPPORT,
    category:'org'
  }
];

export const CustomerSupport_Allowed =[
   {
    name: UserRolesValue.CUSTOMER_SUPPORT,
    category: "org"
   }
]

export const PUBLISHER_ALLOWED = [
  {
    name: UserRolesValue.SUPER_ADMIN,
    category: "publisher"
  }
]

export const EDITOR_ALLOWED = [
  {
    name: UserRolesValue.EDITOR,
    category: "org"
  }
]

export const SUPERVISOR_ALLOWED = [
  {
    name: UserRolesValue.SUPER_ADMIN,
    category: "publisher"
  },
  {
    name: UserRolesValue.ADMIN,
    category: "publisher"
  },
  {
    name: UserRolesValue.SUPERVISOR,
    category: "publisher"
  }
]

export const ACCOUNTANT_ALLOWED = [
  {
    name: UserRolesValue.SUPER_ADMIN,
    category: "publisher"
  },
  {
    name: UserRolesValue.ADMIN,
    category: "publisher"
  },
  {
    name: UserRolesValue.ACCOUNTANT,
    category: "publisher"
  },
]
export const NullAdmin = Authorization(ADMIN_ALLOWED)(Null);
export const AccessDeniedAdmin = Authorization(ADMIN_ALLOWED)(AccessDenied);
export const AccessDeniedPublisher = Authorization(PUBLISHER_ALLOWED)(AccessDenied);