import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab, Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-material-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import FlatPickerBar from "../../../common/FlatPickerBar";
import { FloatInput } from "../../../common/FloatInput";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { SummaryField } from "../../../common/SummaryField";


interface IProps {
  publisher: any;
  setApproveModalOpen: (flag: boolean) => void;
  fetchPublisher: (adminId: string, publisherId: string) => void;
}

const useStyles = makeStyles(() => ({
  publisherSummary: {
    backgroundColor: "#EAE9E0",
    padding: "1rem",
  }
}));

const PublisherSummary: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const [open, setOpen] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.user);
  const [featuredFrom, setFeaturedFrom] = useState<string>(moment().toISOString());
  const [featuredTill, setFeaturedTill] = useState<string>(moment().endOf("day").toISOString());
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [values, setValues] = useState({
    rentalShare: 0,
    digitalShare: 0,
    audiobookShare: 0,
    paperbackShare: 0,
    hardboundShare: 0,
    perBookCharges: 0,
    selfFeatureCharges: 0,
    bookFeatureCharges: 0
  })

  const [editableValues, setEditableValues] = useState({
    rentalShare: 0,
    digitalShare: 0,
    audiobookShare: 0,
    paperbackShare: 0,
    hardboundShare: 0,
    perBookCharges: 0,
    selfFeatureCharges: 0,
    bookFeatureCharges: 0
  })
  const [charges, setCharges] = useState(0)
  useEffect(() => {
    if (props.publisher) {
      setValues({
        rentalShare: props.publisher.rentalShare,
        digitalShare: props.publisher.digitalShare,
        audiobookShare: props.publisher.audiobookShare,
        paperbackShare: props.publisher.paperbackShare,
        hardboundShare: props.publisher.paperbackShare,
        selfFeatureCharges: props.publisher.selfFeatureCharges,
        bookFeatureCharges: props.publisher.bookFeatureCharges,
        perBookCharges: props.publisher.perBookCharges
      })

      setEditableValues({
        rentalShare: props.publisher.rentalShare,
        digitalShare: props.publisher.digitalShare,
        audiobookShare: props.publisher.audiobookShare,
        paperbackShare: props.publisher.paperbackShare,
        hardboundShare: props.publisher.paperbackShare,
        selfFeatureCharges: props.publisher.selfFeatureCharges,
        bookFeatureCharges: props.publisher.bookFeatureCharges,
        perBookCharges: props.publisher.perBookCharges
      })
    }
  }, [props.publisher])

  const markPublisherAsFeatured = () => {
    if (userState && userState.spaceId) {
      const data = {
        publisherId: props.publisher.id,
        charges: charges,
        featuredFrom,
        featuredTill
      }
      callApi(PATHNAMES.FEATURE_PUBLISHER, HTTP_METHODS.PATCH, {
        body: JSON.stringify({ data })
      }).then((res) => {
        if (res && res.success) {
          snackbar({
            variant: SnackBarVariant.SUCCESS,
            message: "Publisher featured successfully"
          })
          props.fetchPublisher(userState.id, props.publisher.id)
        } else {
          snackbar({
            variant: SnackBarVariant.ERROR,
            message: res.message || "Something went wrong"
          })
        }
        setOpen(false);
        setLoading(false);
      })
    }
  }

  const handleClose = () => {
    setOpen(false);
    setFeaturedFrom(moment().toISOString());
    setFeaturedTill(moment().endOf("day").toISOString());
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditableValues({
      ...editableValues,
      [event.target.name]: parseInt(event.target.value)
    })
  };
  const resetEditableValues = () =>{
    setEditableValues({
      rentalShare: props.publisher.rentalShare,
      digitalShare: props.publisher.digitalShare,
      audiobookShare: props.publisher.audiobookShare,
      paperbackShare: props.publisher.paperbackShare,
      hardboundShare: props.publisher.paperbackShare,
      selfFeatureCharges: props.publisher.selfFeatureCharges,
      bookFeatureCharges: props.publisher.bookFeatureCharges,
      perBookCharges: props.publisher.perBookCharges
    })
  }


  const toIndianCurrency = (num: any) => {
    const curr = num?.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR'
    });
    return curr;
  };

  const isFeatured = () => {
    if (props.publisher.featuredTill) {
      if (moment(props.publisher.featuredTill) < moment()) {
        return false
      }
      return true
    }
    return false
  }
  const updateShare = () => {
    setIsEditable(false)
    callApi(PATHNAMES.UPDATE_PUBLISHER_SHARE(userState.id, props.publisher.id), HTTP_METHODS.PUT, {
      body: JSON.stringify({
        data: { ...editableValues, hardboundShare: editableValues.paperbackShare, digitalShare: editableValues.rentalShare }
      })
    }).then((response) => {
      if (response.success && response.message) {
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: response.message
        })
        setValues(editableValues)

      } else {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: "Error while update share"
        })
      }
    })
  }

  const validationSchema = Yup.object().shape({
    rentalShare: Yup.number().min(0, "must be between 0 to 100")
      .max(100, "must be between 0 to 100")
      .required("Digital rental share is required"),
    audiobookShare: Yup.number().min(0, "must be between 0 to 100")
      .max(100, "must be between 0 to 100")
      .required("Audio book share is required"),
    paperbackShare: Yup.number().min(0, "must be between 0 to 100")
      .max(100, "must be between 0 to 100")
      .required("Digital rental share is required"),
    perBookCharges: Yup.number().min(0, "must be 0 or above")
      .required("Per book charge is required"),
  });

  if (loading) {
    return <CircularProgress />
  }
  return (
    <Grid container xs={12}>
      <Grid container xs={12} className={classes.publisherSummary}>
        <Grid container lg={3} sm={3} xs={12}>
          <Grid xs={12}>
            <Typography variant="h2">
              {props.publisher.name}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h4">
              {props.publisher.superAdmin.emailId}
            </Typography>
          </Grid>
          <Grid xs={12}>
            {props.publisher?.contactNumber?.phoneNumber ?
              <Typography variant="h4">
                {`${props.publisher.contactNumber.countryCode}-${props.publisher.contactNumber.phoneNumber}`}
              </Typography>
              : <Typography variant="h4">
                {"-"}
              </Typography>
            }
          </Grid>
        </Grid>
        <Grid item lg={2} sm={2} xs={2}>
          <Typography variant={"h4"}>
            Total Revenue:
          </Typography>
          <Typography variant="h6">
            {(toIndianCurrency(props.publisher.ledgerAccount.totalRevenue))}
          </Typography>
          <Typography variant={"h4"} style={{ marginTop: '1rem' }}>
            Total Paid:
          </Typography>
          <Typography variant="h6">
            {(toIndianCurrency(props.publisher.ledgerAccount.totalPaid))}
          </Typography>
          <Grid style={{ marginTop: '1rem' }}>
            <SummaryField
              label="Audio Book Share"
              value={values.audiobookShare ? `${values.audiobookShare}%` : "-"} />
          </Grid>
        </Grid>
        <Grid item lg={2} sm={2} xs={2}>
          <Typography variant={"h4"}>
            Withdrawable Amount:
          </Typography>
          <Typography variant="h6">
            {(toIndianCurrency(props.publisher.ledgerAccount.withdrawableAmount))}
          </Typography>
          <Grid style={{ marginTop: '1rem' }}>
            <SummaryField
              label="Print Purchase Share"
              value={values.paperbackShare ? `${values.paperbackShare}%` : "-"} />
          </Grid>
        </Grid>
        <Grid item lg={2} sm={2} xs={2} style={{ marginLeft: '2rem' }}>
          <SummaryField
            label="Digital Rental Share"
            value={values.rentalShare ? `${values.rentalShare}%` : "-"}
          />
          <Grid style={{ marginTop: '1rem' }}>
            <SummaryField
              label="Per Book Charges"
              value={values.perBookCharges ? `${values.perBookCharges} ₹` : "-"}
            />
          </Grid>
        </Grid>
        <Grid container lg={2} sm={2} xs={12} style={{ marginLeft: '2rem' }}>
          <SummaryField
            label="Self Feature Charges"
            value={values.selfFeatureCharges ? `${values.selfFeatureCharges} ₹` : "-"}
          />
          <Grid style={{ marginTop: '1rem' }}>
            <SummaryField
              label="Book Feature Charges"
              value={values.bookFeatureCharges ? `${values.bookFeatureCharges} ₹` : "-"}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} justify="flex-end" alignItems="center" style={{ paddingTop: "1rem" }}>
          <Grid item style={{ marginRight: '2rem' }}>
            {props.publisher.status === "pending" ?
              <Fab
                variant="extended"
                color="primary"
                size="medium"
                onClick={() => props.setApproveModalOpen(true)}
              >
                Approve
              </Fab> :
              <>
                <Typography variant="h3">
                  Status: {props.publisher.status && props.publisher.status.toUpperCase()}
                </Typography>
              </>
            }
          </Grid>
          <Grid item style={{ marginRight: '1rem' }}>
            {isFeatured() ?
              <Typography variant="h4" style={{ backgroundColor: "#FF5C3E", padding: "0.5rem" }}>
                Featured till {moment(props.publisher.featuredTill).format("DD/MM/YYYY")}
              </Typography> :
              <Fab
                variant="extended"
                size="small"
                disabled={props.publisher.totalPublishedBooks === 0}
                onClick={() => setOpen(true)}
              >
                Mark Featured
              </Fab>}
          </Grid>
          <Fab
            size="medium"
            variant="extended"
            onClick={() => setIsEditable(true)}
          >
            Edit
          </Fab>
        </Grid>
      </Grid>

      {/* UPDATE PUBLISHER SHARE */}
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={isEditable}
        onClose={() => {setIsEditable(false);resetEditableValues()}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>
          <Grid container xs={12}>
            <Typography variant="h3">Rental Share</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true} >
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={values}
            onSubmit={(data, { setSubmitting }) => {

              updateShare();
              setSubmitting(false);
            }}
          >
            {() => (
              <Form style={{ width: "100%" }}>
                <Grid container xs={12} spacing={2} style={{ margin: '0.5rem 0rem 2rem 0rem' }}>
                  <Grid item xs={4}>
                    <Field
                      component={InputField}
                      variant="outlined"
                      label="Digital Rental Share"
                      placeholder="Digital Rental Share"
                      name="rentalShare"
                      fullWidth
                      required
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        onChange: handleChange,
                        value: values.rentalShare,
                        inputComponent: FloatInput as any,
                      }}
                      inputProps={{
                        maxIntegerDigitsLength: 2,
                        maxMantissaDigitsLength: 0,
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={InputField}
                      variant="outlined"
                      label="Audio Book Share"
                      placeholder="Audio Book Share"
                      name="audiobookShare"
                      fullWidth
                      required
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        onChange: handleChange,
                        value: values.audiobookShare,
                        inputComponent: FloatInput as any,
                      }}
                      inputProps={{
                        maxIntegerDigitsLength: 2,
                        maxMantissaDigitsLength: 0,
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={InputField}
                      variant="outlined"
                      label="Print Purchase Share"
                      placeholder="Print Purchase Share"
                      name="paperbackShare"
                      fullWidth
                      required
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        onChange: handleChange,
                        value: values.paperbackShare,
                        inputComponent: FloatInput as any,
                      }}
                      inputProps={{
                        maxIntegerDigitsLength: 2,
                        maxMantissaDigitsLength: 0,
                        allowNegative: false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={NumericFormat}
                      customInput={TextField}
                      name="perBookCharges"
                      label="Per Book Charges"
                      placeholder="Per Book Charges"
                      variant="outlined"
                      allowNegative={false}
                      value={values.perBookCharges}
                      fullWidth
                      required
                      decimalScale={2}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">₹</InputAdornment>,
                      }}
                      onValueChange={(eventValues: NumberFormatValues) => {
                        if (eventValues.floatValue) {
                          setEditableValues({
                            ...editableValues,
                            perBookCharges: eventValues.floatValue
                          })
                        } else {
                          setEditableValues({
                            ...editableValues,
                            perBookCharges: 0
                          })
                        }
                      }}
                    />
                    {/*  commented it out since , we are accepting 0 rs for per book charges.
                     {values.perBookCharges < 1 ?
                      <Typography variant="subtitle2" color='primary' style={{ marginTop: '0.2rem', fontSize: '11px' }} >
                        * Per book charges is required, must be above 1 rs.
                      </Typography>
                      : <></>
                    } */}
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={NumericFormat}
                      customInput={TextField}
                      name="selfFeatureCharges"
                      label="Self Feature Charges"
                      placeholder="Self Feature Charges"
                      variant="outlined"
                      allowNegative={false}
                      value={values.selfFeatureCharges}
                      fullWidth
                      required
                      decimalScale={2}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">₹</InputAdornment>,
                      }}
                      onValueChange={(eventValues: NumberFormatValues) => {
                        if (eventValues.floatValue) {
                          setEditableValues({
                            ...editableValues,
                            selfFeatureCharges: eventValues.floatValue
                          })
                        } else {
                          setEditableValues({
                            ...editableValues,
                            selfFeatureCharges: 0
                          })
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={NumericFormat}
                      customInput={TextField}
                      name="bookFeatureCharges"
                      label="Book Feature Charges"
                      placeholder="Book Feature Charges"
                      variant="outlined"
                      allowNegative={false}
                      value={values.bookFeatureCharges}
                      fullWidth
                      required
                      decimalScale={2}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">₹</InputAdornment>,
                      }}
                      onValueChange={(eventValues: NumberFormatValues) => {
                        if (eventValues.floatValue) {
                          setEditableValues({
                            ...editableValues,
                            bookFeatureCharges: eventValues.floatValue
                          })
                        } else {
                          setEditableValues({
                            ...editableValues,
                            bookFeatureCharges: 0
                          })
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <DialogActions >
                  <Grid container item xs={12} justify="flex-end" style={{ marginBottom: '0rem' }}>
                    <Fab
                      size="medium"
                      variant="extended"
                      className="blackBackButton"
                      onClick={() => setIsEditable(false)}
                    >
                      Cancel
                    </Fab>
                    <Box pl={2}></Box>
                    <Fab
                      size="medium"
                      variant="extended"
                      type='submit'
                    >
                      Proceed
                    </Fab>
                  </Grid>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* FEATURE PUBLISHER */}
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Grid container xs={12}>
            <Typography variant="h3">Feature Publisher</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true} style={{ minHeight: "25rem" }}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={5}>
              <FlatPickerBar
                handleDateChange={(value: Date) => {
                  setFeaturedFrom(moment(value).toISOString())
                  if (moment(value) > moment(featuredTill)) {
                    setFeaturedTill(moment(value).endOf("day").toISOString())
                  }
                }}
                label="Feature From"
                identifier="featuredFrom"
                name="featuredFrom"
                minDate={moment().startOf("day").toDate()}
                value={featuredFrom}
              />
            </Grid>
            <Grid item xs={5}>
              <FlatPickerBar
                handleDateChange={(value: Date) => {
                  setFeaturedTill(moment(value).endOf("day").toISOString())
                }}
                label="Feature Till"
                identifier="featuredTill"
                name="featuredTill"
                minDate={moment(featuredFrom).toDate()}
                value={featuredTill}
              />
            </Grid>
            <Grid item xs={2}>
              <NumericFormat
                allowNegative={false}
                value={charges || 0}
                customInput={TextField}
                decimalScale={0}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                }}
                label="Charges"
                required
                onValueChange={(value: NumberFormatValues) => {
                  // floatValue always gives a number or undefined
                  if (!value.floatValue) {
                    setCharges(0)
                  } else {
                    setCharges(value.floatValue)
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item xs={12} justify="flex-end">
            <Fab
              size="medium"
              variant="extended"
              className="blackBackButton"
              onClick={handleClose}
            >
              Cancel
            </Fab>
            <Box pl={2}></Box>
            <Fab
              size="medium"
              variant="extended"
              onClick={markPublisherAsFeatured}
            >
              Proceed
            </Fab>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default PublisherSummary;