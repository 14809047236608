import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Typography,
  makeStyles
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { isValidEpub } from "../../../../utils/bookUtils";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";

interface IProps {
  open: boolean;
  handleClose: () => void;
  uploadDigitalBook: (book: IDigitalBookInput) => void;
}

export interface IDigitalBookInput {
  file: any;
  showLoader: boolean;
  uploadComplete: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "2rem",
  },
  uploadButton: {
    width: "8rem",
    backgroundColor: "#FF5C3E",
    color: "white",
    padding: ".8rem 1rem",
    borderRadius: ".3rem",
  },
  orangeButton: {
    backgroundColor: "#FF5C3E",
    color: "white",
    padding: ".8rem 1rem",
    borderRadius: ".3rem",
  },
  fileSelectButton: {
    color: "white",
    padding: "1rem",
    backgroundColor: theme.palette.primary.dark,
  },
  dividerClass: {
    color: "gray",
  },
}));
const UploadEBook: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const [digitalBook, setDigitalBook] = useState<IDigitalBookInput>(
    {
      file: undefined,
      showLoader: false,
      uploadComplete: false,
    },
  );

  //check proceed button disable
  const isDisable = () => {
    if (digitalBook.file) {
      return false
    } else {
      return true
    }
  }

  const setDefaultValues = () => {
    setDigitalBook(
      {
        file: undefined,
        showLoader: false,
        uploadComplete: false,
      }
    )
  }

  const validationSchema = Yup.object().shape({});

  return (
    <Dialog
      maxWidth={undefined}
      fullWidth={true}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Grid container xs={12}>
          <Grid item xs={6}>
            <Typography variant="h3">Upload EBook</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container xs={12}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={digitalBook}
            onSubmit={(data, { setSubmitting }) => {
              props.uploadDigitalBook(digitalBook);
              setDefaultValues()
              setSubmitting(false);
            }}
          >
            {() => (
              <Form style={{ width: "100%" }}>
                <Grid item container xs={12}>
                  <Grid item container xs={12} spacing={1} style={{ marginBottom: "1.5rem" }}>
                    {!digitalBook.file && <Grid item xs={12}>
                      <input
                        id={`btn-upload`}
                        name={`btn-upload`}
                        style={{ display: "none" }}
                        type="file"
                        accept=".epub"
                        onChange={(e) => {
                          if (e.target.files?.length) {
                            const isEpub = isValidEpub(e.target.files[0])
                            if (!isEpub) {
                              snackbar({
                                message: "Only epubs are allowed",
                                variant: SnackBarVariant.WARNING
                              })
                            }
                            if (isEpub) {
                              const item = digitalBook;
                              item.file = e.target.files[0];
                              setDigitalBook({ ...item });
                            }
                          }
                        }}
                      />
                      <label htmlFor={`btn-upload`}>
                        <Button
                          variant="contained"
                          size="small"
                          component="span"
                          className={classes.uploadButton}
                        >
                          Choose File
                        </Button>
                      </label>
                    </Grid>}
                    {digitalBook.file &&
                      <Grid item xs={12}>
                        <Typography style={{ display: 'inline' }} variant="h4">{digitalBook?.file?.name || ""}</Typography>
                        <IconButton
                          onClick={() => setDefaultValues()}
                          aria-label="delete"
                          style={{ display: 'inline' }}
                        >
                          <CancelIcon />
                        </IconButton>
                      </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid container xs={12} justify="flex-end">
                  <Fab
                    size="small"
                    variant="extended"
                    className="blackBackButton"
                    onClick={() => {
                      setDefaultValues()
                      props.handleClose()
                    }}
                  >
                    Cancel
                  </Fab>
                  <Box pl={2}></Box>
                  <Fab size="medium" variant="extended" type="submit" disabled={isDisable()}>
                    Proceed
                  </Fab>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UploadEBook;
