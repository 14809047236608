import { Button, CircularProgress, Grid, makeStyles, Popover, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NotificationsMenuIcon from "../../assets/NotificationsMenuIcon.svg";
import { useSelector } from "react-redux";
import { IAppState } from "../../store";
import callApi from "../../utils/apiUtil";
import { PATHNAMES } from "../../utils/pathNames";
import { HTTP_METHODS } from "../../utils/constants";
import { INotification } from "../../reducers/notifications/types";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: "#FB8F67",
    height: "2.5rem"
  },
  mainAvatar: {
    backgroundColor: "#FB8F67",
    width: "3.5rem",
    height: "3.5rem",
  },
  popoverContainer: {
    width: "25rem",
  },
  iconStyle: {
    cursor: "pointer",
    height: "20px"
  }

}))

const NotificationCard: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const userState = useSelector((state: IAppState) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (userState && userState.id && userState.spaceId) {
      setLoading(true);
      callApi(PATHNAMES.GET_NOTIFICATIONS(userState.id), HTTP_METHODS.GET).then((res) => {
        if (res && res.success && res.data) {
          setNotifications(res.data.userNotifications);
          setLoading(false);
        }
      })
    }
  }, [userState])

  const handleRead = () => {
    setLoading(true);
    if (notifications.length) {
      const data = { notificationIds: notifications.map(item => item.id) }
      callApi(PATHNAMES.MARK_NOTIFICATIONS_AS_READ, HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      }).then((response) => {
        if (response && response.success && response.data) {
          setNotifications(response.data.userNotifications);
          setLoading(false);
        }
      })
    }
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRoute = (notification: any) => {
    const data = { status: "READ" }
    callApi(PATHNAMES.MARK_NOTIFICATION_AS_READ(notification.id), HTTP_METHODS.PUT, {
      body: JSON.stringify({ data })
    }).then((response) => {
      let NewNotification = notifications.filter(notification => notification.id !== response.data.id)
      NewNotification = [...NewNotification, response.data]
      setNotifications(NewNotification)
    })
    if (notification.resourceType === "order") {
      navigate(`/view-order?order=${notification.resourceId}`)
    }
    if (notification.resourceType === "book") {
      navigate(`/view-book?book=${notification.resourceId}`)
    }
  }

  const open = Boolean(anchorEl);
  return (
    <Grid container justify="flex-end" alignItems="center">
      <img alt="Notifications" src={NotificationsMenuIcon} className={classes.iconStyle} onClick={handleClick} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Grid container className={classes.popoverContainer}>
          {loading ?
            <CircularProgress /> :
            <Grid container xs={12} style={{ paddingTop: "1rem" }}>
              <Grid item xs={8}>
                <Typography variant='h4' style={{ marginLeft: '2vw', marginRight: '9vw', marginTop: "1vh" }}>Notifications</Typography>
              </Grid>
              <Grid item xs={4}>
                {notifications.length ? <Button style={{ marginBottom: '3vh' }} onClick={handleRead}><Typography variant='caption'>Mark All As Read</Typography></Button> : <></>}
              </Grid>
              {notifications.length ?
                notifications.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()).map((notification) => {
                  return (
                    <Grid container xs={12} style={{ padding: "1rem", width: "fit-content", height: "fit-content", borderBottom: "black 1px solid", background: notification.status === "READ" ? 'WhiteSmoke' : "white", cursor: 'pointer' }} onClick={() => {
                      handleRoute(notification)
                    }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <div style={{ display: 'flex', alignItems: 'center', }}>
                            <div>
                              <FiberManualRecordIcon style={{ fontSize: "15px", color: notification.status === "READ" ? "grey" : "CornflowerBlue", paddingRight: '10px' }} />
                            </div>
                            <div>
                              <Typography variant="h4">{notification.subject}</Typography>
                              <Grid item xs={12}>
                                <Typography variant='subtitle2'>{notification.content}</Typography>
                              </Grid>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }) :
                <Grid item style={{ padding: "1rem" }}>
                  <Typography variant="body1" style={{ fontStyle: "italic" }}>
                    You don't have any new notifications!
                  </Typography>
                </Grid>
              }
            </Grid>
          }
        </Grid>
      </Popover>
    </Grid>
  )
}
export default NotificationCard;