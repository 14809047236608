import { IAddressCreate } from "./types";

export const defaultUserAddress: IAddressCreate = {
  recipientName: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
  contactNumber: {
    countryCode: "",
    phoneNumber: ""
  }
}