import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ISubOrder } from "../../../reducers/orders/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../utils/common.interface";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import CircularLoader from "../../common/CircularLoader";
import CustomContextMenu from "../../common/CustomContextMenu/CustomContextMenu";
import { IContextMenuPosition } from "../../common/CustomContextMenu/types";
import { DataTable } from "../../common/Datatable/Datatable";
import { Pagination } from "../../common/Datatable/types";

const Orders: React.FC = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<ISubOrder[]>([]);
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [fetchingOrders, setFetchingOrders] = useState<boolean>(false);
  const [fetchingOrdersOnPageLoad, setFetchingOrdersOnPageLoad] = useState<boolean>(true);
  const userState = useSelector((state: IAppState) => state.user);

  const [filterOptionList, setFilterOptionList] = useState({
    bookTitle: [],
    userName: [],
    createdAt: [],
    referenceNumber: [],
    orderType: [],
    status: [],
  })

  const [contextMenu, setContextMenu] = useState<IContextMenuPosition | null>(null);
  const [menuAttributes, setMenuAttributes] = useState({
    link: "",
  })


  useEffect(() => {
    if (userState && userState.id && userState.spaceId) {
      fetchPublisherOrders({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
      fetchBookFilters()
      setFetchingOrdersOnPageLoad(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState])

  const fetchBookFilters = () => {
    callApi(PATHNAMES.PUBLISHER_SUBORDER_FILTERS(userState.spaceId), HTTP_METHODS.GET,).then((ordersResponse) => {
      if (ordersResponse && ordersResponse.success && ordersResponse.data) {
        setFilterOptionList(ordersResponse.data);
      }
    })
  }

  const fetchPublisherOrders = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args
    setFetchingOrders(true);
    callApi(PATHNAMES.GET_PUBLISHER_SUB_ORDERS(userState.spaceId, pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then((ordersResponse) => {
      if (ordersResponse && ordersResponse.success && ordersResponse.data) {
        setOrders(ordersResponse.data.subOrders);
        setTotalOrders(ordersResponse.data.totalSubOrdersCount)
        setFetchingOrders(false);
      }
    })
  }

  const handleContextMenuClick = (
    event: React.MouseEvent, orderId: string
  ) => {
    event.preventDefault();
    const value: any = contextMenu === null ? {
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    } : null;
    setContextMenu(value);
    setMenuAttributes({
      ...menuAttributes,
      link: `view-order?order=${orderId}`
    })
  };


  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      label: "DATE & TIME",
      name: "createdAt",
      options: {
        customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY, h:mm A"),
        filter: false,
      }
    },
    {
      label: "ORDER ID",
      name: "referenceNumber",
      options: {
        filterOptions: {
          names: filterOptionList.referenceNumber
        },
      }
    },
    {
      label: "BOOK NAME",
      name: "book",
      options: {
        customBodyRender: (data: any) => data?.title || "",
        filterOptions: {
          names: filterOptionList.bookTitle
        },
      }
    },
    {
      label: "ORDER BY",
      name: "user",
      options: {
        customBodyRender: (data: any) => data?.firstName + " " + data?.lastName,
        // filterOptions: {
        //   names: filterOptionList.userName
        // },
        filter: false,
      }
    },
    {
      label: "Order From",
      name: "platform",
      options: {
        customBodyRender: (data: string) => data?.toUpperCase()
      }
    },
    {
      label: "ORDER TYPE",
      name: "orderType",
      options: {
        filterOptions: {
          names: filterOptionList.orderType
        }
      }
    },
    {
      label: "STATUS",
      name: "status",
      options: {
        customBodyRender: (data: string) => data?.replace("_", " ").toUpperCase() || "",
        options: {
          filterOptions: {
            names: filterOptionList.status
          }
        }
      }
    }
  ]

  const options: MUIDataTableOptions = {
    elevation: 0,
    onRowClick: (rowData: string[]) => {
      if (rowData && rowData.length) {
        navigate(`/view-order?order=${rowData[0]}`)
      }
    },
    setRowProps: (row: any, rowIndex: number) => {
      return {
        onContextMenu: (event: React.MouseEvent) => {
          const [orderId] = row
          return handleContextMenuClick(event, orderId)
        }
      }
    },
    searchPlaceholder: "Search by date[dd-mm-yyyy], order id, type, book name, status",
    count: totalOrders,
  };

  if (fetchingOrdersOnPageLoad) {
    return <CircularLoader message={"Fetching your Orders.."} />
  }

  return (
    <Grid container xs={12}>
      <Paper elevation={0} style={{ width: "98%", padding: "1rem" }}>
        <Grid container xs={12}>
          <Typography variant="h2" style={{ fontWeight: 600 }}>
            Orders
            {fetchingOrders ? (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            ) :
              <Typography variant="h2" color="primary" style={{ display: "inline", marginLeft: "1rem" }}>
                {totalOrders}
              </Typography>
            }
          </Typography>
        </Grid>
        <Grid container xs={12}>
          <DataTable
            title={""}
            rows={orders}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchPublisherOrders} // provide fetchData function when serverSide is true , else void fuction
          />
          <CustomContextMenu
            onClose={() => setContextMenu(null)}
            position={contextMenu}
            attributes={menuAttributes}
          />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Orders;