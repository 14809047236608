import {
  Box, createStyles, Grid,
  ListItemIcon,
  ListItemText,
  makeStyles, Menu, MenuItem
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cart from "../../assets/Cart.png";
import logo from "../../assets/LogoRegisteredDark.svg";
import PenPaper from "../../assets/PenPaper.svg";
import PostIcon from "../../assets/PostIcon.svg";
import SeriesIcon from "../../assets/Series.svg";
import StoriesCollection from "../../assets/StoriesCollection.png";
import VideoIcon from "../../assets/VideoIcon.png";
import { updateMobileUser } from "../../reducers/mobileUser/reducer";
import { IAppState } from "../../store";
import callApi from "../../utils/apiUtil";
import { HTTP_METHODS } from "../../utils/constants";
import { PATHNAMES } from "../../utils/pathNames";
import { useSnackBar } from "./SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "./SnackbarWrapper/SnackbarWrapper";
import UserCard from "./UserCard";


const useStyles = makeStyles((theme) =>
  createStyles({
    headerContainer: {
      minHeight: "3.5rem",
      alignItems: "center",
      backgroundColor: "white",
      position: "fixed",
      zIndex: 900,
      boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.1)",
    },
    logoText: {
      color: "#fff",
      marginLeft: "3rem",
      cursor: "pointer"
    },
    linkText: {
      color: "#fff",
      marginRight: "2rem",
      cursor: "pointer"
    },
    logoStyle: {
      marginLeft: "1rem",
    },
    headerItem: {
      fontWeight: 600,
      fontSize: "16px",
      cursor: "pointer"
    },
    menuIcon: {
      cursor: "pointer",
      marginRight: "1rem"
    },
    menuItem: {
      cursor: "pointer",
      color: theme.palette.primary.main,
      padding: '0rem 0.6rem',
      fontWeight: 500,
      fontSize: "1rem",
      "&:hover": {
        color: theme.palette.primary.light
      }
    },
    publisherLogo: {
      display: "inline",
      marginLeft: "1rem",
      fontWeight: 600,
      position: "relative",
      bottom: "0.7rem"
    },
    btnIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: "2rem",
      width: "3rem",

      padding: '0.7rem 0.6rem',
      cursor: "pointer",
    },
    iconImage: { height: "1.7rem", },
    icons: {
      minWidth: "25px"
    },
    cartCount: {
      backgroundColor: 'red',
      color: 'white',
      borderRadius: '50%',
      padding: '0.0625rem 0.375rem',
      fontSize: '0.8rem',
      position: 'absolute',
      top: '0.1rem',
      right: '6.4rem',
    },
  })
);

const MobileUserHeader: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const snackbar = useSnackBar();

  const dispatch = useDispatch();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [cartCount, setCartCount] = useState<number>(0);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (mobileUserState?.cartQuantity >= 0) {
      setCartCount(mobileUserState.cartQuantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState.cartQuantity])


  useEffect(() => {
    if (mobileUserState) {
      const userId = mobileUserState.id ? mobileUserState.id : localStorage.getItem("userId")
      if (userId) {
        // user data
        callApi(PATHNAMES.GET_MOBILE_USER(userId), HTTP_METHODS.GET).then((res) => {
          if (res && res.success && res.data) {
            dispatch(updateMobileUser(res.data));
            localStorage.setItem("userId", res.data.id);
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState.id]);

  const handleMyRachnayeLinkNav = (link: string) => {
    if (mobileUserState.firstName.includes("Guest_")) {
      snackbar({
        message: "Your profile is incomplete, you need to complete it before writing rachnaye",
        variant: SnackBarVariant.WARNING,
      })
      return
    }
    navigate(link);
    handleClose()
    return
  }

  return (
    <Grid container className={classes.headerContainer}>
      {/* RACHNAYE LOGO */}

      <Grid container item xl={2} lg={2} md={2} sm={2} xs={2} >
        <Box style={{ margin: "0% 5%", cursor: "pointer", justifyContent: "center", alignItems: "center" }} onClick={() => navigate("/portal/home")}>
          <img src={logo} alt="logo" width={170} style={{ paddingTop: "0.4rem" }} />
        </Box>
      </Grid>

      {/* Menu Items */}
      <Grid container xl={10} lg={10} md={10} sm={10} xs={10} justify="flex-end" style={{ alignItems: "center" }}>

        <Grid container xl={10} lg={10} md={10} sm={10} xs={10} justify="flex-end" >
          <Box title="Write" pl={2} className={classes.btnIconContainer} onClick={handleClick}>
            <img alt="Write" src={PenPaper} className={classes.iconImage} />
          </Box>
          <Box title="Cart" pl={2} className={classes.btnIconContainer} onClick={() => navigate("/portal/user/cart")}>
            <img alt="Cart" src={Cart} className={classes.iconImage} />
            {cartCount > 0 && (<span className={classes.cartCount} style={cartCount < 2 ? { padding: "0.0625rem 0.5rem" } : {}}>
              {cartCount}
            </span>
            )}

          </Box>
          <Box pl={2} title={"Your Profile\n" + mobileUserState.firstName} className={classes.btnIconContainer}>
            <UserCard />
          </Box>
        </Grid>

        <div>
          <Menu
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            style={{ marginTop: "2.6rem" }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => handleMyRachnayeLinkNav("/portal/rachnaye/post/create?type=text")}>
              <ListItemIcon className={classes.icons}><img alt="post" src={PostIcon} width='15px' /></ListItemIcon>
              <ListItemText>Create Post</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleMyRachnayeLinkNav("/portal/rachnaye/post/create?type=video")}>
              <ListItemIcon className={classes.icons}><img alt="video" src={VideoIcon} width='15px' /></ListItemIcon>
              <ListItemText>Create Video Post</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleMyRachnayeLinkNav("/portal/rachnaye/series/create?type=text")}>
              <ListItemIcon className={classes.icons}><img alt="series" src={SeriesIcon} width='15px' /></ListItemIcon>
              <ListItemText>Create Series</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleMyRachnayeLinkNav("/portal/rachnaye/series/create?type=video")}>
              <ListItemIcon className={classes.icons}><img alt="video" src={VideoIcon} width='15px' /></ListItemIcon>
              <ListItemText>Create Video Series</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleMyRachnayeLinkNav("/portal/myRachnaye")}>
              <ListItemIcon className={classes.icons}><img alt="my rachnaye" src={StoriesCollection} width='15px' /></ListItemIcon>
              <ListItemText>My Rachnaye</ListItemText>
            </MenuItem>
          </Menu>
        </div>
      </Grid>
    </Grid>
  );
};

export default MobileUserHeader;