import { CircularProgress, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import ApprovePublisher, { IApprovePublisherArgs } from "../ApprovePublisher";
import PublisherDetailTabView from "./PublisherDetailTabView";
import PublisherSummary from "./PublisherSummary";

const PublisherDetail: React.FC = () => {
  const location = useLocation();
  const [values, setValues] = useState<any>();
  const [approveModalOpen, setApproveModalOpen] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.user);

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const publisherId = params.get("publisher");
      if (publisherId && userState?.id) {
        fetchPublisher(userState.id, publisherId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, userState]);

  const fetchPublisher = async (adminId: string, publisherId: string) => {
    const res = await callApi(PATHNAMES.GET_PUBLISHER_BY_ID(userState.id, publisherId), HTTP_METHODS.GET)
    if (res && res.success && res.data) {
      setValues(res.data);
    }
  }

  const approvePublisher = (data: IApprovePublisherArgs) => {
    callApi(PATHNAMES.APPROVE_PUBLISHER_BY_ID(userState.id, values.id), HTTP_METHODS.PUT, {
      body: JSON.stringify({ data })
    }).then(res => {
      if (res && res.success) {
        setApproveModalOpen(false);
        fetchPublisher(userState.id, values.id)
      }
    })
  }

  if (!values) {
    return <CircularProgress />
  }

  return (
    <Grid container xs={12} style={{ marginRight: "1rem" }}>
      <PublisherSummary
        publisher={values}
        setApproveModalOpen={setApproveModalOpen}
        fetchPublisher={fetchPublisher}
      />
      <PublisherDetailTabView
        publisher={values}
      />
      <Grid container xs={12}>
        <ApprovePublisher
          open={approveModalOpen}
          handleClose={() => setApproveModalOpen(false)}
          handleSubmit={approvePublisher}
        />
      </Grid>
    </Grid>
  )
};

export default PublisherDetail;