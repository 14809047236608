import {
  Grid,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Fab,
  Box,
  InputAdornment,
  TextField,
  Avatar,
  Tooltip,
  withStyles
} from "@material-ui/core";
import moment from "moment";
import { MUIDataTableMeta, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { IAuthor } from "../../../../reducers/authors/types";
import { IBook } from "../../../../reducers/books/types";
import { DataTable } from "../../../common/Datatable/Datatable";
import FlatPickerBar from "../../../common/FlatPickerBar";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { PATHNAMES } from "../../../../utils/pathNames";
import { bookUpdateFieldsMap, bookUploadFieldsMap, CsvUploadStatusType, HTTP_METHODS, possibleCsvMimeTypes } from "../../../../utils/constants";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { ErrorHandler } from "../../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { BulkCsvUploadDialog } from "../../Books/BulkCsvUploadDialog";
import  { NumberFormatValues,NumericFormat } from "react-number-format";
import CustomContextMenu from "../../../common/CustomContextMenu/CustomContextMenu";
import { BulkCsvUpdateDialog } from "../../Books/BulkCsvUpdateDialog";
import { IContextMenuPosition } from "../../../common/CustomContextMenu/types";
import { IFiltersAndSorting } from "../../../../utils/common.interface";
import { Pagination } from "../../../common/Datatable/types";
import logo from "../../../../assets/rachnayeLight.png";
import BooksExcelDownloadBtn from "../../../common/BooksExcelDownload";
const xlsxParser = require('xlsx-parse-json');


interface IProps {
  publisherId: string;
}
interface IFeaturedBook {
  charges: number;
  featuredFrom: string;
  featuredTill: string;
}

const styles = () => ({
  tooltip: {
    fontSize: '1rem',
    backgroundColor: "black"
  },
});


const validateFeaturedBookData = async (args: IFeaturedBook) => {
  if (!args.featuredFrom) {
    throw new ErrorHandler("Featured From time is required")
  }
  if (moment(args.featuredFrom) > moment(args.featuredTill)) {
    throw new ErrorHandler("Featured From day should be less than Featured Till")
  }
  if (!args.featuredTill) {
    throw new ErrorHandler("Featured Till time is required")
  }
  if (moment(args.featuredTill) < moment(args.featuredFrom)) {
    throw new ErrorHandler("Featured Till day should be grater than Featured From")
  }
}

const PublisherBooks: React.FC<IProps> = (props) => {
  const userState = useSelector((state: IAppState) => state.user);
  const snackbar = useSnackBar()
  const navigate = useNavigate()

  const [books,
    setBooks] = useState<IBook[]>([]);
  const [publisherHasBooks, setPublisherHasBooks] = useState<boolean>(true);
  const [totalBooks, setTotalBooks] = useState<number>(0);
  const [bookId, setBookId] = useState<string>("");

  const [open, setOpen] = useState<boolean>(false);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [bulkUpdate, setBulkUpdate] = useState<boolean>(false);
  const [featuredBook, setFeaturedBook] = useState<IFeaturedBook>({
    charges: 0,
    featuredFrom: moment().toISOString(),
    featuredTill: moment().endOf("day").toISOString()
  });

  const [fileValue, setFileValue] = useState<File | undefined>();
  const [uploadStatus, setUploadStatus] = useState<number>(
    CsvUploadStatusType.DEFAULT
  );
  const [fileName, setFileName] = useState<string>("");
  const [contextMenu, setContextMenu] = useState<IContextMenuPosition | null>(null);
  const [menuAttributes, setMenuAttributes] = useState({
    link: "",
  })


  useEffect(() => {
    if (props.publisherId) {
      fetchPublisherBooks({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.publisherId])

  const handleClose = () => {
    setOpen(false);
    setFeaturedBook({
      charges: 0,
      featuredFrom: moment().toISOString(),
      featuredTill: moment().endOf("day").toISOString()
    })
  }

  const fetchPublisherBooks = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args
    callApi(PATHNAMES.PUBLISHER_BOOKS_LIST(props.publisherId, pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then((booksResponse: any) => {
      if (booksResponse && booksResponse.success && booksResponse.data) {
        setBooks(booksResponse.data.books);
        setTotalBooks(booksResponse.data.totalBooksCount)
        // re-render only when we get new value
        setPublisherHasBooks(oldValue => oldValue === booksResponse.data.publisherHasBooks ? oldValue : booksResponse.data.publisherHasBooks)
      }
    })
  }

  const onSelectDocument = (event: any) => {
    const file: File = event.target.files[0];
    setUploadStatus(CsvUploadStatusType.DEFAULT);
    if (file) {
      setFileName(file.name);
      if (possibleCsvMimeTypes.includes(file.type)) {
        setFileValue(file);
      } else {
        return snackbar({
          message: "Invalid File Type. Allowed type: xlsx",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  const markBookAsFeatured = async () => {
    try {
      if (userState && userState.spaceId && bookId) {
        await validateFeaturedBookData(featuredBook)
        const response = await callApi(PATHNAMES.FEATURE_BOOK(bookId), HTTP_METHODS.PATCH, {
          body: JSON.stringify({ data: featuredBook })
        })
        if (response && response.success && response.message && response.data) {
          fetchPublisherBooks({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
          handleClose()
          snackbar({
            variant: SnackBarVariant.SUCCESS,
            message: "Book has been featured successfully",
          })
        } else {
          throw new ErrorHandler(response.message)
        }
      }
    } catch (error: any) {
      setOpen(false)
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
    }
  }

  const uploadBooksViaFile = async (booksInputArr: any[]) => {
    const response = await callApi(PATHNAMES.UPLOAD_BOOKS_VIA_CSV, HTTP_METHODS.POST, {
      body: JSON.stringify({ books: booksInputArr, publisherId: props.publisherId })
    });
    if (response && response.success) {
      const { uploadedBooks, updatedBooks, rejectedBooks } = response.data
      let message = ""
      let variant = SnackBarVariant.SUCCESS

      if (uploadedBooks.length) {
        message += `${uploadedBooks.length} Books got uploaded. `
      }
      if (updatedBooks.length) {
        message += `${updatedBooks.length} Books got updated. `
      }

      if (rejectedBooks.length) {
        message += `${rejectedBooks.length} Books got rejected. `
      }

      if (rejectedBooks.length && !uploadedBooks.length && !updatedBooks.length) {
        message = `All Books got rejected. `
        variant = SnackBarVariant.WARNING
      }
      if (uploadedBooks.length && !rejectedBooks.length && !updatedBooks.length) {
        message = `All Books got uploaded. `
      }
      if (updatedBooks.length && !rejectedBooks.length && !uploadedBooks.length) {
        message = `All Books got updated. `
      }

      snackbar({
        message,
        variant
      });
      fetchPublisherBooks({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
      setUploadModalOpen(false);
      setUploadStatus(CsvUploadStatusType.SUCCESS);
      setFileValue(undefined);
      setFileName("");
    } else {
      setUploadStatus(CsvUploadStatusType.ERROR);
      snackbar({
        message: "Xlsx contains incorrect values",
        variant: SnackBarVariant.ERROR
      })
    }
  }

  const updateBooksViaFile = async (booksInputArr: any[]) => {
    const response = await callApi(PATHNAMES.UPDATE_BOOKS_VIA_CSV, HTTP_METHODS.POST, {
      body: JSON.stringify({ books: booksInputArr, publisherId: userState.spaceId })
    });
    if (response && response.success) {
      const { updatedBooks, rejectedBooks } = response.data
      let message = ""
      let variant = SnackBarVariant.SUCCESS

      if (updatedBooks.length) {
        message += `${updatedBooks.length} Books got updated. `
      }

      if (rejectedBooks.length) {
        message += `${rejectedBooks.length} Books got rejected. `
      }

      if (rejectedBooks.length && !updatedBooks.length) {
        message = `All Books got rejected. `
        variant = SnackBarVariant.WARNING
      }

      if (updatedBooks.length && !rejectedBooks.length) {
        message = `All Books got updated. `
      }

      snackbar({
        message,
        variant
      });

      setBulkUpdate(false);
      setUploadStatus(CsvUploadStatusType.SUCCESS);
      setFileValue(undefined);
      setFileName("");
    } else {
      setUploadStatus(CsvUploadStatusType.ERROR);
      snackbar({
        message: "Xlsx contains incorrect values",
        variant: SnackBarVariant.ERROR
      })
    }
  }

  const uploadCsv = () => {
    try {
      if (fileValue) {
        setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
        xlsxParser.onFileSelection(fileValue)
          .then((parsedData: any) => {
            const booksInputArr = [];
            if (parsedData && parsedData.Worksheet?.length) {
              for (let index = 0; index < parsedData.Worksheet.length; index++) {
                const element: any = parsedData.Worksheet[index];
                const data: any = {}
                for (let key in element) {
                  data[bookUploadFieldsMap[key]] = element[key]
                }
                data.authors = data.authors.split(",")
                if (data.ageRange) {
                  switch (data.ageRange) {
                    case `0-11`:
                      data.ageRange = {
                        lowerLimit: 0,
                        upperLimit: 11
                      }
                      break;
                    case `11-18`:
                      data.ageRange = {
                        lowerLimit: 11,
                        upperLimit: 18
                      }
                      break;
                    case `18+`:
                      data.ageRange = {
                        lowerLimit: 18,
                        upperLimit: 100
                      }
                      break;
                    default:
                      data.ageRange = {
                        lowerLimit: 18,
                        upperLimit: 100
                      }
                      break;
                  }
                }
                booksInputArr.push(data);
              }
            }
            if (booksInputArr.length > 0) {
              uploadBooksViaFile(booksInputArr);
            } else {
              setUploadStatus(CsvUploadStatusType.ERROR);
              snackbar({
                message: "No data found.",
                variant: SnackBarVariant.ERROR
              });
            }
          });
      }
    } catch (error) {
      setUploadStatus(CsvUploadStatusType.ERROR);
    }
  }

  const updateCsv = () => {
    try {
      if (fileValue) {
        setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
        xlsxParser.onFileSelection(fileValue)
          .then((parsedData: any) => {
            const booksInputArr = [];
            if (parsedData && parsedData.Worksheet?.length) {
              for (let index = 0; index < parsedData.Worksheet.length; index++) {
                const element: any = parsedData.Worksheet[index];
                const data: any = {}
                for (let key in element) {
                  data[bookUpdateFieldsMap[key]] = element[key]
                }
                data.quantity = parseInt(data.quantity)
                data.price = parseInt(data.price)
                booksInputArr.push(data);
              }
            }
            if (booksInputArr.length > 0) {
              updateBooksViaFile(booksInputArr);
            } else {
              setUploadStatus(CsvUploadStatusType.ERROR);
              snackbar({
                message: "No data found.",
                variant: SnackBarVariant.ERROR
              });
            }
          });
      }
    } catch (error) {
      setUploadStatus(CsvUploadStatusType.ERROR);
    }
  }

  const options: MUIDataTableOptions = {
    elevation: 0,
    onRowClick: (rowData: any, rowMeta: any) => {
      const currentIndex: number = rowMeta.dataIndex
      navigate(`/view-book?book=${books[currentIndex].id}`)
    },
    setRowProps: (row: any, rowIndex: number) => {
      return {
        onContextMenu: (event: React.MouseEvent) => {
          const [bookId] = row
          return handleContextMenuClick(event, bookId)
        }
      }
    },
    filter: false,
    searchPlaceholder: "Search with Book name, language, category or plan",
    count: totalBooks,
  };

  const handleContextMenuClick = (
    event: React.MouseEvent, bookId: string
  ) => {
    event.preventDefault();
    const value: any = contextMenu === null ? {
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    } : null;
    setContextMenu(value);
    setMenuAttributes({
      ...menuAttributes,
      link: `view-book?book=${bookId}`
    })
  };


  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      label: "Cover",
      name: "thumbnailFront",
      options: {
        customBodyRender: (data: string) => {
          return (
            <Avatar
              alt="Book Thumbnail"
              variant="rounded"
              style={{ borderRadius: "50%" }}
              src={data || logo}
            ></Avatar>
          );
        },
      },
    },
    {
      label: "Title",
      name: "title"
    },
    {
      label: "Author(s)",
      name: "authors",
      options: {
        sort: false,
        setCellHeaderProps: () => ({ style: { fontWeight: 600, fontSize: '14px' } }),
        customBodyRender: (data: IAuthor[]) => {
          const CustomTooltip = withStyles(styles)(Tooltip);
          const authors = data.map((item) => item.name);
          const names = authors.join(",  ")
          return <CustomTooltip title={names} arrow>
            <div style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {names}
            </div>
          </CustomTooltip>
        }
      }
    },
    {
      label: "Language",
      name: "language",
      options: {
        customBodyRender: (data: string) => data?.toUpperCase() || ""
      }
    },
    {
      label: "Category",
      name: "category",
      options: {
        customBodyRender: (data: string) => data?.toUpperCase() || ""
      }
    },
    {
      label: "Featured Till",
      name: "featuredTill",
      options: {
        customBodyRender: (data: string, tableData: MUIDataTableMeta) => {
          //if FeaturedTill not there or if it's over then 
          if (!data || (moment() > moment(data))) {

            return <Typography variant="h5" style={{ fontWeight: 400, textAlign: 'start', maxWidth: '6rem' }} color="primary" onClick={(event) => {
              const [id] = tableData.rowData
              setBookId(id)
              event.stopPropagation()
              setOpen(true)
            }
            }> Mark Featured</Typography >
          } else {
            return moment(data).format("DD/MM/YY, hh:mm A")
          }
        }
      }
    }
  ]

  return (
    <Grid container xs={12}>
      <Grid container xs={12} justify="flex-end">
        <BooksExcelDownloadBtn
          publisherId={props.publisherId}
        />
        <Box ml={1}></Box>
        <Fab
          variant="extended"
          size="medium"
          onClick={() => setUploadModalOpen(true)}
        >
          Create
        </Fab>
        <Box ml={1}></Box>
        <Fab
          variant="extended"
          size="medium"
          onClick={() => setBulkUpdate(true)}
        >
          Update
        </Fab>
        <Box ml={1}></Box>
        <Fab
          variant="extended"
          size="medium"
          onClick={() => navigate(`/create-distributor?publisher=${props.publisherId}`)}
        >
          Create Distributor
        </Fab>
      </Grid>
      {!publisherHasBooks ?
        <Typography variant='body1'>
          Publisher hasn't uploaded any books yet
        </Typography> :
        <Grid container xs={12}>
          <DataTable
            title={`Books (${totalBooks})`}
            rows={books}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchPublisherBooks}
          />
          <CustomContextMenu
            onClose={() => setContextMenu(null)}
            position={contextMenu}
            attributes={menuAttributes}
          />
        </Grid>
      }
      <Grid container xs={12}>
        {uploadModalOpen && <BulkCsvUploadDialog
          title={"Books"}
          subTitle={"Books"}
          csvTemplateLink={"https://rachnaye-dev.s3.ap-south-1.amazonaws.com/rachnaye/Book_upload_template.xlsx"}
          uploadErrors={[]}
          uploadStatus={uploadStatus}
          onDocumentSelect={onSelectDocument}
          fileName={fileName}
          uploadCsv={uploadCsv}
          handleClose={() => setUploadModalOpen(false)}
        />}
      </Grid>
      <Grid container xs={12}>
        {bulkUpdate && (
          <BulkCsvUpdateDialog
            title={"Books"}
            subTitle={"Books"}
            csvTemplateLink={"https://rachnaye-dev.s3.ap-south-1.amazonaws.com/rachnaye/Book_update_template.xlsx"}
            uploadErrors={[]}
            uploadStatus={uploadStatus}
            onDocumentSelect={onSelectDocument}
            fileName={fileName}
            updateCsv={updateCsv}
            handleClose={() => setBulkUpdate(false)}
          />
        )}
      </Grid>
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Grid container xs={12}>
            <Typography variant="h3">Feature Book</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true} style={{ minHeight: "45vh" }}>
          <Grid container xs={12} spacing={4}>
            <Grid item xs={5}>
              <FlatPickerBar
                handleDateChange={(value: Date) => {
                  if (moment(value) > moment(featuredBook.featuredTill)) {
                    setFeaturedBook({
                      ...featuredBook,
                      featuredFrom: moment(value).toISOString(),
                      featuredTill: moment(value).endOf("day").toISOString()
                    })
                  } else {
                    setFeaturedBook({
                      ...featuredBook,
                      featuredFrom: moment(value).toISOString(),
                    })
                  }
                }}
                label="Feature From"
                identifier="featuredFrom"
                name="featuredFrom"
                minDate={moment().startOf("day").toDate()}
                value={featuredBook.featuredFrom}
                required
              />
            </Grid>
            <Grid item xs={5}>
              <FlatPickerBar
                handleDateChange={(value: Date) => {
                  setFeaturedBook({
                    ...featuredBook,
                    featuredTill: moment(value).endOf("day").toISOString()
                  })
                }}
                label="Feature Till"
                identifier="featuredTill"
                name="featuredTill"
                minDate={moment(featuredBook.featuredFrom).toDate()}
                value={featuredBook.featuredTill}
                required
              />
            </Grid>
            <Grid item xs={2}>
              <NumericFormat
                allowNegative={false}
                value={featuredBook.charges}
                customInput={TextField}
                decimalScale={0}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                }}
                label="Charges"
                required
                onValueChange={(value: NumberFormatValues) => {
                  // floatValue always gives a number or undefined
                  if (!value.floatValue) {
                    setFeaturedBook({
                      ...featuredBook,
                      charges: 0
                    })
                  } else {
                    setFeaturedBook({
                      ...featuredBook,
                      charges: value.floatValue
                    })
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item xs={12} justify="flex-end">
            <Fab
              size="medium"
              variant="extended"
              className="blackBackButton"
              onClick={handleClose}
            >
              Cancel
            </Fab>
            <Box pl={2}></Box>
            <Fab
              size="medium"
              variant="extended"
              onClick={markBookAsFeatured}
            >
              Proceed
            </Fab>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default PublisherBooks;