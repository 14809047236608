export default {
  h1: {
    fontFamily: [`Poppins, 'Noto Sans JP', sans-serif`].join(", "),
    fontWeight: 300,
    fontSize: "30px",
    letterSpacing: "-0.24px",
    lineHeight: "41px"
  },
  h2: {
    fontFamily: [`Poppins, 'Noto Sans JP', sans-serif`].join(", "),
    fontSize: "20px",
    letterSpacing: "-0.24px",
    lineHeight: "27px"
  },
  h3: {
    fontFamily: [`Poppins, 'Noto Sans JP', sans-serif`].join(", "),
    fontSize: "18px",
    letterSpacing: "-0.06px",
    lineHeight: "25px"
  },
  h4: {
    fontWeight: 600,
    fontFamily: [`Poppins, 'Noto Sans JP', sans-serif`].join(", "),
    fontSize: "14px",
    letterSpacing: "-0.06px",
    lineHeight: "19px"
  },
  h5: {
    fontFamily: [`Poppins, 'Noto Sans JP', sans-serif`].join(", "),
    fontSize: "12px",
    letterSpacing: "-0.05px",
    lineHeight: "15px"
  },
  h6: {
    fontWeight: 500,
    fontFamily: [`Poppins, 'Noto Sans JP', sans-serif`].join(", "),
    fontSize: "14px",
    letterSpacing: "-0.05px",
    lineHeight: "20px"
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: [`Poppins, 'Noto Sans JP', sans-serif`].join(", "),
    letterSpacing: "-0.05px",
    lineHeight: "25px"
  },
  subtitle2: {
    fontWeight: 400,
    fontFamily: [`Poppins, 'Noto Sans JP', sans-serif`].join(", "),
    fontSize: "14px",
    letterSpacing: "-0.05px",
    lineHeight: "21px"
  },
  body1: {
    fontFamily: [`Poppins, 'Noto Sans JP', sans-serif`].join(", "),
    letterSpacing: "-0.05px",
    fontSize: "14px",
    lineHeight: "19px"
  },
  body2: {
    fontSize: "12px",
    fontFamily: [`Poppins, 'Noto Sans JP', sans-serif`].join(", "),
    letterSpacing: "-0.04px",
    lineHeight: "19px"
  },
  button: {
    fontWeight: 600,
    lineHeight: "19px",
    fontSize: "14px",
    fontFamily: [`Poppins, 'Noto Sans JP', sans-serif`].join(", ")
  }
};
