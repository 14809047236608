import { Box, Card, CardContent, CardMedia, createStyles, Grid, makeStyles, TextareaAutosize, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/rachnayeLight.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    mainContainer: {
      padding: "2rem 0.6rem",
      width: "100%"
    },
    div1: {
      backgroundColor: "#F5F7F9",
      borderRadius: "4px", width: "100%"
    },
    div2: {
      padding: "1rem",
      width: "100%"
    },
    card: {
      display: "flex"
    },
    cardMedia: {
      width: 100,
      height: 144,
      borderRadius: 4,
      margin: "0.3rem"
    },
    contentBox: {
      width: "95%",
      cursor: "pointer"
    },
    cardContent: {
      padding: "1rem 1.6rem 0rem 1.6rem"
    },
    timeStamp: {
      fontSize: "11px"
    },
    emptyBox: {
      height: "3.5rem"
    },
    textArea: {
      width: "100%",
      border: 'none',
      padding: "0.6rem 0rem",
      height: "1.9rem !important",
      outline: "none",
      fontSize: "12px",
      fontFamily: "Poppins",
      backgroundColor: "transparent",
      resize: "none"
    },
    editBtn: {
      padding: "1rem 0.6rem",
      "& :hover": {
        cursor: 'pointer',
        color: theme.palette.primary.main
      }
    },
    seriesBottomLine1: {
      margin: "0rem 0.5rem",
      borderRadius: "10px",
      height: "0.2rem",
      backgroundColor: "grey"
    },
    seriesBottomLine2: {
      margin: "0.2rem 0.9rem",
      borderRadius: "10px",
      height: "0.2rem",
      backgroundColor: "grey"
    },
  })
);

interface IProps {
  items?: any[];
  lastElementRef?: (node: any) => void;
}

const UsersPublishedSeries: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const navigate = useNavigate();

  const isLastElementinList = (index: number): boolean => props.items?.length === index + 1

  return (
    <Grid container xs={12} className={classes.mainContainer}>
      {
        props.items && props.items.length > 0 && props?.items.map((series: any, index: number) => (

          <Grid key={series.id} ref={isLastElementinList(index) ? props.lastElementRef : null} container className={classes.div1}>
            <Grid item className={classes.div2}>
              <Card variant="outlined" className={classes.card}>
                <div>
                  <CardMedia
                    component="img"
                    className={classes.cardMedia}
                    image={series.coverImage || logo}
                    alt="Cover Img"
                  />
                  <div className={classes.seriesBottomLine1} ></div>
                  <div className={classes.seriesBottomLine2} ></div>
                </div>

                <Box className={classes.contentBox} onClick={() => navigate(`/portal/rachnaye/series/read?id=${series.id}`)}>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="subtitle1">
                      {series.title}
                    </Typography>
                    {series.description ?
                      <TextareaAutosize
                        value={series.description}
                        className={classes.textArea}
                      />
                      : <Box className={classes.emptyBox} ></Box>

                    }
                    <Grid container xs={12}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" className={classes.timeStamp}>
                          {`Published at : ${moment(series.publishedAt).format("LLL")}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Box>
                <Box className={classes.editBtn}>
                  <Typography style={{ textDecoration: "underline" }} onClick={() => navigate(`/portal/rachnaye/series/description?id=${series.id}`)} color="primary" variant="body1">Edit</Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
        ))
      }
    </Grid >
  );
};

export default UsersPublishedSeries;
