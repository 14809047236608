import { DoneAll, HelpOutline } from "@material-ui/icons";
import CancelIcon from '@material-ui/icons/Cancel';
import ReplayIcon from '@material-ui/icons/Replay';
import LocalShipping from "@material-ui/icons/LocalShipping";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { EventNote } from "@material-ui/icons";
import React from "react";

interface OrderStatusIconProps {
    status: string;
    style?: React.CSSProperties; // Optional style prop
  }
  
  const OrderStatusIcon: React.FC<OrderStatusIconProps> = ({ status, style }) => {
    const getOrderStatusIcon = (status: string) => {
      switch (status.replace(" ", "_").toUpperCase()) {
        case 'COMPLETED':
          return <DoneAll style={style} />;
          case 'DELIVERED':
          return <DoneAll style={style} />;
        case 'CANCELLED':
          return <CancelIcon style={style} />;
        case 'RETURNED':
          return <ReplayIcon style={style} />;
        case 'INTRANSIT':
          return <LocalShipping style={style} />;
        case 'CONFIRMED':
          return <CheckCircle style={style} />;
        case 'PICKUP_SCHEDULED':
          return <EventNote style={style} />;
        default:
          return <HelpOutline style={style} />;
      }
    };
  
    return getOrderStatusIcon(status);
  };

  export default OrderStatusIcon;
