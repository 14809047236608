import { CircularProgress, Fab, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import CustomDivider from '../../common/Divider';
import DevicesStatsTable from './DevicesStatsTable';
import StatsCard from './StatsCard';

import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import callApi from '../../../utils/apiUtil';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';
import { PATHNAMES } from '../../../utils/pathNames';
import { HTTP_METHODS } from '../../../utils/constants';

var XLSX = require("xlsx");




// values by 'react-date-range'
interface ICalendarDate {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: string;
};

interface IExcelJson {
  [key: string]: {
    name: string;
    value: string[][]
  }
}

const FinancialReport: React.FC = () => {
  const snackbar = useSnackBar()
  const [loading, setLoading] = useState<Boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [calendarDateApplied, setCalendarDateApplied] = useState<Boolean>(false);

  const [selectedCalendarDate, setSelectedCalendarDate] = useState<ICalendarDate>({
    startDate: new Date(),
    endDate: undefined,
    key: 'selection'
  });
  const [selectedStartDate, setSelectedStartDate] = useState<Date | string>("")
  const [selectedEndDate, setSelectedEndDate] = useState<Date | string>("")



  const containerRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    // to detect clicks outside the container
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (calendarDateApplied &&
      selectedCalendarDate.startDate &&
      selectedCalendarDate.endDate
    ) {
      setSelectedStartDate(selectedCalendarDate.startDate)
      setSelectedEndDate(selectedCalendarDate.endDate)
      setShowCalendar(false)
      setCalendarDateApplied(false)
    }
  }, [calendarDateApplied]);


  const disableBtn = () => {
    if (!selectedStartDate || !selectedEndDate) {
      return true
    }
    return false
  }

  const calendarBtnTitle = () => {
    let title = ""
    if (!selectedStartDate && !selectedEndDate) {
      title = "Click to select date"
    } else {
      title = "Change date"
    }
    return title
  }

  const showSelectedDatesToUser = () => {

    let title = ""
    if (!selectedStartDate && !selectedEndDate) {
      title = "No dates Selected"
    } else {
      title = `Selected Dates : ${moment(selectedStartDate).format("YYYY/MM/DD")}   to   ${moment(selectedEndDate).format("YYYY/MM/DD")}`
    }
    return title
  }

  const handleDownloadReport = async () => {
    setLoading(true)

    const startDate = moment(selectedStartDate).format("YYYY/MM/DD")
    const endDate = moment(selectedEndDate).format("YYYY/MM/DD")
    const res = await callApi(PATHNAMES.GET_FINANCIAL_REPORT(startDate, endDate), HTTP_METHODS.GET)

    if (!res || !res.success) {
      snackbar({
        message: res.message,
        variant: SnackBarVariant.ERROR
      })
      setLoading(false)
      return
    }

    await convertJSONtoExcelAndDownload(res.data)
    setLoading(false)
    return
  }

  const convertJSONtoExcelAndDownload = async (data: IExcelJson) => {

    // Convert data to Excel format (assuming you have a function for this)
    const excelData: any = await convertToExcel(data);

    // Create blob from Excel data
    const blob = new Blob([excelData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    // Create URL for the blob
    const bookURL = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `FinancialReport${new Date().getTime()}.xlsx`;
    link.href = bookURL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(bookURL);

  }
  const convertToExcel = (data: IExcelJson) => {
    const sheets = Object.keys(data)

    const excelWorkSheets = sheets.map(sheet => {
      return {
        sheetName: data[sheet].name,
        excelWorkSheet: XLSX.utils.aoa_to_sheet(data[sheet].value)
      }
    })

    const excelWorkbook = XLSX.utils.book_new();
    excelWorkSheets.forEach((worksheet) => XLSX.utils.book_append_sheet(excelWorkbook, worksheet.excelWorkSheet, worksheet.sheetName))
    const excelBuffer = XLSX.write(excelWorkbook, { type: 'array', bookType: 'xlsx' });
    return excelBuffer
  }

  return (
    <Grid container xs={12} spacing={2} justifyContent="center">
      <Grid item xs={12} style={{ margin: "1rem 0rem 0rem 0rem" }}>
        <Typography variant='h2' style={{ fontWeight: 700 }}>Download Financial Report</Typography>
      </Grid>

      <Grid container xs={12} style={{ padding: "2rem 1rem" }} spacing={2}>
        <Grid container xs={12} sm={9} style={{ marginBottom: "2rem" }} >
          <Grid item md={5}>
            <Fab size="medium" variant="extended" onClick={() => setShowCalendar(v => !v)}>
              {calendarBtnTitle()}
            </Fab>
          </Grid>
          <Grid item md={7} style={{ margin: "1rem 0rem" }}>
            {
              showCalendar ?
                <Grid ref={containerRef} container xs={12}
                  justifyContent="center"
                  spacing={2}
                  style={{
                    padding: "1rem",
                    // margin: "1rem",
                    width: "25rem",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "white",
                    animation: "popUp 0.5s ease forwards"
                  }}>
                  <Grid item>
                    <DateRange
                      editableDateInputs={false} // prevent user from changing date manually, only selection is allowed
                      maxDate={new Date()}
                      minDate={moment("2024-05-01").toDate()}
                      onChange={item => {
                        const { startDate, endDate, key } = item.selection;
                        if (startDate && endDate && key) {
                          setSelectedCalendarDate({
                            startDate,
                            endDate,
                            key
                          });
                        }
                      }
                      }
                      moveRangeOnFirstSelection={false}
                      ranges={[selectedCalendarDate]}
                    />
                  </Grid>
                  <Grid item>
                    <Fab
                      variant="extended"
                      size="medium"
                      onClick={() => {
                        setCalendarDateApplied(true)
                      }}
                    >
                      Apply
                    </Fab>
                  </Grid>

                </Grid>
                :
                <Typography variant='h2'>{showSelectedDatesToUser()} </Typography>
            }

          </Grid>

        </Grid>

        <Grid container xs={12} sm={3} style={{ marginBottom: "2rem" }}>
          <Fab size="medium"
            variant="extended"
            disabled={disableBtn()}
            onClick={handleDownloadReport}
          >
            {loading ? <CircularProgress size={"1.6rem "} style={{ color: "white" }} /> : "Download Report"}
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FinancialReport;