import { Box, createStyles, List, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { NavBarLinks } from './constants';
import AppMenuItem from './AppMenuItem';
import { Authorization, Null } from '../hoc/Authorization';

const UseStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    logoStyle: {
      width: "100%",
      height: "100%"
    },
    menuItem: {
      color: theme.palette.secondary.main,
      "& .MuiListItemText-primary:hover": {
        color: theme.palette.primary.main
      },
      "& .MuiListItemIcon-root": {
        color: theme.palette.primary.main
      },
      boxShadow: "0px -1px 0px rgba(255, 255, 255, 0.08)",
      "&.active": {
        backgroundColor: theme.palette.primary.main,
        color: "#000",
        "& .MuiListItemIcon-root": {
          color: "#000"
        }
      },
    },
    menuIcon: {
      color: theme.palette.secondary.main,
      "&.MuiSvgIcon-root:hover": {
        color: theme.palette.primary.main
      }
    }
  })
);

export const AppMenu = () => {

  const classes = UseStyles();
  return (
    <Box className={`sidebar-menu-main ${classes.root}`}>
      <List component="nav" disablePadding>
        {NavBarLinks.map((item: any, index: number) => {
          const NullAppMenuItem = Authorization(item.allowed)(Null)(AppMenuItem);
          return <NullAppMenuItem
            {...item}
            key={index}
          />;
        })}
      </List>
    </Box >
  )
}
