import { Grid, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/CheckCircle";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMobileUser } from "../../../../reducers/mobileUser/reducer";
import { IAddress, IMobileUserState } from "../../../../reducers/mobileUser/types";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import EditUserAddress from "./EditUserAddress";

interface IProps {
  address: IAddress
  user: IMobileUserState
}

const UserAddressCard: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false)
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);

  const handleClose = () => {
    setOpen(false)
    if (mobileUserState?.id) {
      callApi(PATHNAMES.GET_MOBILE_USER(mobileUserState.id), HTTP_METHODS.GET).then((res) => {
        if (res && res.success && res.data) {
          dispatch(updateMobileUser(res.data));
        }
      })
    }
  }

  return (
    <Grid style={{ border: "4px solid #F5F6F8", padding: "1rem", borderRadius: "10px" }}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {props.address.recipientName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 500 }} variant="body1">
          {props.address.address1}
        </Typography>
      </Grid>
      {props.address.address2 && <Grid item xs={12}>
        <Typography style={{ fontWeight: 500 }} variant="body1">
          {props.address.address2}
        </Typography>
      </Grid>}
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 500 }} variant="body1">
          {props.address.city} {props.address.state} - {props.address.pincode}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 500 }} variant="body1">
          {props.address.country}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 500 }} variant="body1">
          Phone: {props.address.contactNumber.phoneNumber}
        </Typography>
      </Grid>
      <Grid container xs={12} style={{ marginTop: "1rem" }}>
        <Grid item xs={4} lg={2}>
          <Typography variant="body1" style={{ color: "teal", cursor: "pointer" }} onClick={() => setOpen(true)}>
            Edit
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6} lg={6}>
          {props.address.isDefault ? <Typography variant="body1" style={{ color: "black", display: "inline", position: "relative", bottom: "0.25rem" }}>
            Default
          </Typography> : <Typography variant="body1" style={{ color: "teal", cursor: "pointer", display: "inline", position: "relative", bottom: "0.25rem" }}>
            Set as Default
          </Typography>}

          {props.address.isDefault && <CheckIcon style={{ color: "green", fontSize: 18, marginLeft: "0.3rem", display: "inline" }} />}
        </Grid>
      </Grid>
      <EditUserAddress open={open} onClose={handleClose} address={props.address} />
    </Grid>
  )
}

export default UserAddressCard;