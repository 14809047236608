import {
  Avatar,
  Box,
  Grid,
  Popover,
  Typography,
  makeStyles
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LanguageIcon from "../../assets/Language.png";
import Logout from "../../assets/Logout.png";
import OrderBagIcon from "../../assets/OrderBagIcon.png";
import RupeeIcon from "../../assets/RupeeSymbol.png";
import UserIcon from "../../assets/UserIcon.png";
import { logout as mobileUserLogout } from "../../reducers/mobileUser/reducer";
import { IMobileUserState } from "../../reducers/mobileUser/types";
import { logout } from "../../reducers/user/reducer";
import { IIamUserState } from "../../reducers/user/types";
import { IAppState } from "../../store";
import callApi from "../../utils/apiUtil";
import { HTTP_METHODS, USER_TYPES } from "../../utils/constants";
import { PATHNAMES } from "../../utils/pathNames";
import { getInitials, getUserType, logOut } from "../../utils/utils";
import { useSnackBar } from "./SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "./SnackbarWrapper/SnackbarWrapper";


const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#FB8F67",
    height: "1.8rem",
    width: "1.8rem",
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#FF5C3E",
    },
  },
  mainAvatar: {
    backgroundColor: "#FB8F67",
    width: "2.6rem",
    height: "2.6rem",
  },
  popoverContainer: {
    height: "auto",
    padding: "1rem",
    width: "16rem",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
    transition: "box-shadow 0.3s",
    "&:hover": {
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
    },
  },
  imgBox: {
    padding: "0.4rem",
  },
  userNamebox: {
    padding: "0.1rem 0.3rem",
    width: "10rem",
  },
  imgNameContainer: {
    height: "4rem",
    borderBottom: "1px solid grey",
  },
  containerBorder: {
    borderBottom: "1px solid grey",
  },
  usersEmailorNumberText: {
    fontSize: "0.8rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  menuItemContainer: {
    padding: "0.6rem 0.6rem",
    "& :hover": {
      color: "#FF5C3E",
    },
  },
  menuItemSpan: {
    display: "flex",
    alignItems: "center",
    padding: "0.6rem 0rem",
    cursor: "pointer",
    transition: "color 0.3s",
    "&:hover": {
      color: "#FF5C3E",
    },
  },
  menuItemText: {
    fontSize: "14px",
    marginLeft: "0.7rem",
    transition: "color 0.3s",
    "&:hover": {
      color: "#FF5C3E",
    },
  },
  menuItemIcon: {
    height: "1.1rem",
    padding: "0rem 0.7rem 0rem 0rem",
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
}));




const UserCard: React.FC = () => {
  const snackbar = useSnackBar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType')
  const userState: IMobileUserState | IIamUserState = useSelector((state: IAppState) => (userType === USER_TYPES.MOBILE ? state.mobileUser : state.user));
  // const mobileUserState = useSelector((state: IAppState) => state.mobileUser)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      menuTitle: "Profile & Setting",
      icon: UserIcon,
      click: () => {
        navigate("/portal/user/profile");
        return handleClose();
      }
    },
    {
      menuTitle: "Orders",
      icon: OrderBagIcon,
      click: () => {
        navigate("/portal/orders");
        return handleClose();
      }
    },
    {
      menuTitle: "Earning & Payouts",
      icon: RupeeIcon,
      click: () => {
        navigate("/portal/earningAndPayouts");
        return handleClose();
      }
    },
    {
      menuTitle: "Change Language",
      icon: LanguageIcon,
      click: () => {
        navigate("/portal/categoryLanguageSelection");
        return handleClose();
      }
    }
  ]

  const handleLogOut = () => {

    if (userType === USER_TYPES.MOBILE) {
      // once log out api is hit, don't wait for res. beacause on any error user should be logged out eventually.
      callApi(PATHNAMES.MOBILE_USER_LOGOUT, HTTP_METHODS.POST)
    }
    // logout steps for any iam and mobile both user
    snackbar({
      message: "Logged out",
      variant: SnackBarVariant.SUCCESS
    })
    logOut()
    if (userType === USER_TYPES.MOBILE) {
      dispatch(mobileUserLogout());
    } else {
      dispatch(logout());
    }
    navigate("/login", { replace: true })
    return
  }

  const open = Boolean(anchorEl);

  return (
    <Grid item >
      {userState.profileImageUrl ?
        <Avatar onClick={handleClick} src={userState.profileImageUrl} className={classes.avatar} /> :
        <Avatar className={classes.avatar} onClick={handleClick}>
          {getInitials(`${userState.firstName} ${userState.lastName}`)}
        </Avatar>
      }
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={4}
        style={{ marginTop: '1rem', }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Grid container className={classes.popoverContainer}>

          {/* USER INFO CONTAINER */}
          <Grid container xs={12} className={classes.imgNameContainer}>
            <Box className={classes.imgBox}>
              {userState.profileImageUrl ?
                <Avatar src={userState.profileImageUrl} className={classes.mainAvatar} /> :
                <Avatar className={classes.mainAvatar}>
                  {getInitials(`${userState.firstName} ${userState.lastName}`)}
                </Avatar>
              }
            </Box>
            <Box className={classes.userNamebox}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {`${userState.firstName} ${userState.lastName}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.usersEmailorNumberText}>
                  {getUserType() === USER_TYPES.MOBILE ?
                    `${userState.contactNumber?.countryCode}-${userState.contactNumber?.phoneNumber}`
                    :
                    (userState as IMobileUserState).email || (userState as IIamUserState).emailId
                  }
                </Typography>
              </Grid>
            </Box>
          </Grid>


          {/* MENUiTEMS CONTAINER */}
          {getUserType() === USER_TYPES.MOBILE &&
            <Grid container xs={12} className={classes.containerBorder}>
              <Grid item xs={12} className={classes.menuItemContainer}>
                {menuItems.map((menuItem) => (
                  <span className={classes.menuItemSpan} onClick={menuItem.click}>
                    <img alt={menuItem.menuTitle} src={menuItem.icon} className={classes.menuItemIcon} />
                    <Typography variant="subtitle2" className={classes.menuItemText}>{menuItem.menuTitle}</Typography>
                  </span>
                ))}
              </Grid>
            </Grid>
          }

          {/* LOGOUT CONTAINER */}
          <Grid container xs={12}>
            <Grid item xs={12} className={classes.menuItemContainer}>
              <span className={classes.menuItemSpan} onClick={handleLogOut}>
                <img alt="Logout" src={Logout} className={classes.menuItemIcon} />
                <Typography variant="subtitle2" className={classes.menuItemText}>Log Out</Typography>
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  )
};

export default UserCard;