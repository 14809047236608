import { Card, CardContent, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ILedgerAccount } from "../../../reducers/ledger/types";
import { IBankAccountInput, IPaymentAccount, IVpaInput } from "../../../reducers/paymentAccounts/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS, TRANSFER_MODES } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { SummaryField } from "../../common/SummaryField";
import Payouts from "../Payouts/Payouts";
import { getWithdrawlCharges, maskEmail, maskName, maskString } from "../../../utils/utils";
import CircularLoader from "../../common/CircularLoader";

const useStyles = makeStyles((theme) =>
  createStyles({
    revenueSharing: {
      height: "8rem",
    },
    forwardIcon: {
      backgroundColor: "#FF5C3E",
      color: "white",
      padding: "0.2rem",
      borderRadius: "1rem",
      cursor: "pointer"
    },
    withdrawalLoader: {
      width: '30em',
      [theme.breakpoints.down('sm')]: {
        width: "15em",
      },
    }
  })
);

const bankDetailSchema = {
  bankName: "",
  accountName: "",
  ifsc: "",
  accountNumber: "",
  accountType: ""
}
const vpaSchema: IVpaInput = {
  vpa: "",
  name: ""
}
const ledgerAccountSchema: ILedgerAccount = {
  id: "",
  consumerId: "",
  consumerType: "",
  totalRevenue: 0,
  payouts: [],
  totalPaid: 0,
  outstanding: 0,
  withdrawableAmount: 0,
  amountWithheld: 0,
  lastCompletedPayoutId: "",
  subOrders: []
}

const Payment: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const user = useSelector((state: IAppState) => state.user);
  const [publisherId, setPublisherId] = useState<string>("")
  const [ledgerAccountDetails, setLedgerAccountDetails] = useState<ILedgerAccount>(ledgerAccountSchema);
  const [tempBankDetails, setTempBankDetails] = useState<IBankAccountInput>(bankDetailSchema)
  const [bankDetails, setBankDetails] = useState<IBankAccountInput>(bankDetailSchema);
  const [bankDetailsFound, setBankDetailsFound] = useState<boolean>(false)
  const [upiDetailsFound, setUpiDetailsFound] = useState<boolean>(false)

  const [tempVpa, setTempVpa] = useState<IVpaInput>(vpaSchema)
  const [vpa, setVpa] = useState<IVpaInput>(vpaSchema)

  const [isEditableUpiDetail, setIsEditableUpiDetail] = useState<boolean>(false);
  const [isEditableBankDetail, setIsEditableBankDetail] = useState<boolean>(false);
  const [withdrawMethodDialog, setWithdrawMethodDialog] = useState<boolean>(false);

  const [upiLoading, setUpiLoading] = useState<boolean>(false)
  const [bankLoading, setBankLoading] = useState<boolean>(false)

  const [loader, setLoader] = useState<boolean>(true)
  const [processingWithdrawlLoader, setProcessingWithdrawlLoader] = useState<boolean>(false)
  const [payoutComponentNewKey, setPayoutComponentNewKey] = useState<number>(1) // used for rerendering a payout component after each withdraw


  useEffect(() => {
    if (ledgerAccountDetails?.id) {
      setLoader(false)
    }
  }, [ledgerAccountDetails?.id])


  useEffect(() => {
    if (user && user.spaceId) {
      setPublisherId(user.spaceId)
      callApi(PATHNAMES.GET_LEDGER_ACCOUNT(user.spaceId), HTTP_METHODS.GET).then(res => {
        if (res.success && res.data) {
          setLedgerAccountDetails(res.data);
        }
      })

      callApi(PATHNAMES.GET_PAYMENT_ACCOUNT(user.spaceId), HTTP_METHODS.GET).then(res => {
        if (res?.success && res.data) {
          const paymentAccount: IPaymentAccount = res.data;
          if (paymentAccount.bankAccount) {
            setBankDetails(paymentAccount.bankAccount)
            setTempBankDetails(paymentAccount.bankAccount)
            setBankDetailsFound(true)

          }
          if (paymentAccount.vpa) {
            setVpa(paymentAccount.vpa)
            setTempVpa(paymentAccount.vpa)
            setUpiDetailsFound(true)
          }
        }
      })
    }
  }, [user])

  const requestWithdrawl = (mode: string) => {
    setProcessingWithdrawlLoader(true)
    if (mode) {
      if (mode === TRANSFER_MODES.UPI && !upiDetailsFound) {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: "Please add UPI details first for UPI withdrawal",
          duration: 5000
        })
        setWithdrawMethodDialog(false)
        setProcessingWithdrawlLoader(false)
        return
      }
      if (mode !== TRANSFER_MODES.UPI && !bankDetailsFound) {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: "Please add Bank details first for IMPS/NEFT withdrawal",
          duration: 5000
        })
        setWithdrawMethodDialog(false)
        setProcessingWithdrawlLoader(false)
        return
      }

      const data = {
        transferMode: mode,
        withdrawlCharges: getWithdrawlCharges(mode, ledgerAccountDetails.withdrawableAmount)
      }
      callApi(PATHNAMES.REQUEST_WITHDRAWAL, HTTP_METHODS.POST, {
        body: JSON.stringify({ data }),
      }
      ).then(res => {
        if (res?.success && user?.spaceId) {
          reRenderPayouts()
          callApi(PATHNAMES.GET_LEDGER_ACCOUNT(user.spaceId), HTTP_METHODS.GET).then(response => {
            if (response.success && response.data) {
              setLedgerAccountDetails(response.data);
              snackbar({
                variant: SnackBarVariant.SUCCESS,
                message: "Withdrawl request processed successfully"
              })
              setProcessingWithdrawlLoader(false)
            } else {
              snackbar({
                variant: SnackBarVariant.ERROR,
                message: response.message || "Error while requesting withdrawl"
              })
              setProcessingWithdrawlLoader(false)
            }
          })
          setWithdrawMethodDialog(false);
        } else {
          snackbar({
            variant: SnackBarVariant.ERROR,
            message: res.message || "Error while requesting withdrawl"
          })
          setProcessingWithdrawlLoader(false)
        }
      })
    }
  }

  const reRenderPayouts = () => {
    const newKey = Math.floor(Math.random() * 100);
    setPayoutComponentNewKey(v => newKey)
  }

  //update bank detail
  const updateBankDetails = () => {
    setBankLoading(true)
    if (user && user.spaceId) {
      const data: any = {}

      if (bankDetails.accountNumber && bankDetails.accountName) {
        data.bankAccount = {
          accountName: bankDetails.accountName,
          bankName: bankDetails.bankName,
          accountNumber: bankDetails.accountNumber,
          ifsc: bankDetails.ifsc,
          accountType: bankDetails.accountType
        }
      }
      callApi(PATHNAMES.UPDATE_BANK_DETAILS(user.spaceId), HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      }).then(res => {
        setBankLoading(false)
        if (res.success && res.data) {
          setIsEditableBankDetail(false);
          snackbar({
            variant: SnackBarVariant.SUCCESS,
            message: "Account details updated successfully!"
          })
          const paymentAccount: IPaymentAccount = res.data;
          if (paymentAccount.bankAccount) {
            setTempBankDetails(res.data.bankAccount)
          }
        } else {
          setBankDetails(tempBankDetails)
          snackbar({
            variant: SnackBarVariant.ERROR,
            message: res.message || "Error while updating Upi details"
          })
          setIsEditableBankDetail(false);
        }
      })
    }
  }

  //update UPI detail
  const updateUpiDetails = () => {
    setUpiLoading(true)
    if (user && user.spaceId) {
      const data: any = {}
      if ((vpa.vpa && !vpa.name) || (vpa.name && !vpa.vpa)) {
        snackbar({
          message: "VPA Name & Id are mandatory",
          variant: SnackBarVariant.ERROR
        })
        return
      }
      if (vpa.vpa) {
        data.vpa = {
          name: vpa.name,
          vpa: vpa.vpa
        }
      }
      callApi(PATHNAMES.UPDATE_UPI_DETAILS(user.spaceId), HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      }).then(res => {
        setUpiLoading(false)
        setIsEditableUpiDetail(false);
        if (res.success && res.data && user && user.spaceId) {
          snackbar({
            variant: SnackBarVariant.SUCCESS,
            message: "UPI details updated successfully!"
          })
          const paymentAccount: IPaymentAccount = res.data;
          if (paymentAccount.vpa) {
            setTempVpa(res.data.vpa)
          }
        } else {
          setVpa(tempVpa)
          snackbar({
            variant: SnackBarVariant.ERROR,
            message: res.message || "Error while updating Upi details"
          })
          setIsEditableUpiDetail(false);
        }
      })
    }
  }

  const handleCancelUpiEdit = () => {
    setIsEditableUpiDetail(false);
    setVpa(tempVpa);
    setUpiLoading(false);
  }

  const handleCancleBankEdit = () => {
    setIsEditableBankDetail(false);
    setBankDetails(tempBankDetails);
    setBankLoading(false);
  }

  if (loader) {
    return <CircularLoader />
  }


  return (
    <Grid container xs={12} spacing={2}>
      <Grid container xs={6} style={{ padding: "1rem" }}>
        <Paper elevation={2} style={{ width: "100%", padding: "1rem" }}>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <Typography variant="h2" style={{ fontWeight: "bolder" }}>
                Outstanding Balance
              </Typography>
              <Grid container xs={12} style={{ marginTop: "4rem" }}>
                <Typography variant="h4">
                  Last Payout: {ledgerAccountDetails?.lastCompletedPayout?.amountWithdrawn ? `₹ ${ledgerAccountDetails?.lastCompletedPayout?.amountWithdrawn}` : "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={6} justify="flex-end">
              <Typography variant="h3" style={{ fontWeight: "bold" }} color="primary">
                {`₹${ledgerAccountDetails?.withdrawableAmount}` || 0}
              </Typography>
              <Grid container xs={12} style={{ marginTop: "4rem" }} justify="flex-end">
                <Fab
                  variant="extended"
                  size="medium"
                  onClick={() => setWithdrawMethodDialog(true)}
                  disabled={ledgerAccountDetails.withdrawableAmount < 1000}
                >
                  Withdraw
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

      </Grid>
      <Grid container xs={6} style={{ padding: "1rem" }}>
        <Paper elevation={2} style={{ width: "100%", padding: "1rem" }}>
          <Grid container xs={12} className={classes.revenueSharing}>
            <Grid xs={12}>
              <Typography variant="h2" style={{ fontWeight: "bolder" }}>
                Revenue Share
              </Typography>
            </Grid>
            <Grid xs={4}>
              <SummaryField
                label="Digital Rental Share"
                value={user?.space?.rentalShare ? `${user?.space?.rentalShare}%` : "-"}
              />
            </Grid>
            <Grid xs={4}>
              <SummaryField
                label="Print Purchase Share"
                value={user?.space?.paperbackShare ? `${user?.space?.paperbackShare}%` : "-"}
              />
            </Grid>
            <Grid xs={4}>
              <SummaryField
                label="Audio Book Share"
                value={user?.space?.audiobookShare ? `${user?.space?.audiobookShare}%` : "-"}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">
                Note: Minimum withdrawable amount is ₹1000
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid container xs={12} style={{ padding: "1rem" }}>
        <Paper elevation={2} style={{ width: "100%", padding: "1rem" }}>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h2" style={{ fontWeight: "bolder" }}>
                Bank Accounts
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ marginTop: "1rem" }}>
            <Grid item xs={6} style={{ borderRight: "solid 0.5px rgba(0, 0, 0, 0.18)" }}>
              <Grid container xs={12}>
                <Grid item xs={8}>
                  <Typography variant="h3" style={{ fontWeight: "bolder" }}>
                    UPI Details
                  </Typography>
                </Grid>
                <Grid container xs={4} justify="flex-end" style={{ paddingRight: '1.5rem' }}>
                  {isEditableUpiDetail ?
                    <>
                      {upiLoading ? <></> :
                        <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer", marginRight: "1rem" }} color="primary"
                          onClick={updateUpiDetails}>
                          Save
                        </Typography>}

                      <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer" }}
                        onClick={handleCancelUpiEdit}>
                        Cancel
                      </Typography>
                    </>
                    :
                    <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer" }} color="primary"
                      onClick={() => {
                        if (vpa.lastValidatedOn) {
                          if (moment().diff(moment(vpa.lastValidatedOn), "days") > 14) {
                            setIsEditableUpiDetail(true)
                          } else {
                            snackbar({
                              variant: SnackBarVariant.WARNING,
                              message: "You can change UPI details only after 14 days"
                            })
                          }
                        } else {
                          setIsEditableUpiDetail(true)
                        }
                      }}
                    >
                      Edit
                    </Typography>}
                </Grid>
              </Grid>

              {
                isEditableUpiDetail ?
                  upiLoading ?
                    <Grid container xs={12} justify="center" alignContent="center" style={{ marginTop: "2rem" }}>
                      <CircularProgress />
                    </Grid>
                    :
                    <Grid container xs={12} spacing={2} style={{ marginTop: "1rem", paddingRight: "1rem" }}>
                      <Grid item xs={6}>
                        <Typography variant="h4" style={{ color: "#808080" }}>
                          UPI Id/VPA
                        </Typography>
                        <TextField
                          variant="outlined"
                          value={vpa.vpa}
                          fullWidth
                          onChange={(e) => {
                            setVpa({
                              ...vpa,
                              vpa: e.target.value
                            })
                          }}
                          style={{ marginTop: "0.5rem" }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" style={{ color: "#808080" }}>
                          Name of account holder
                        </Typography>
                        <TextField
                          variant="outlined"
                          value={vpa.name}
                          fullWidth
                          onChange={(e) => {
                            setVpa({
                              ...vpa,
                              name: e.target.value
                            })
                          }}
                          style={{ marginTop: "0.5rem" }}
                        />
                      </Grid>
                    </Grid>
                  :
                  <Grid container xs={12} spacing={2} style={{ marginTop: "1rem", paddingRight: "1rem" }}>
                    <Grid item xs={12}>
                      <Typography variant="h4" style={{ display: "inline", marginRight: "1rem" }}>
                        UPI Id:
                      </Typography>
                      <Typography variant="body1" style={{ display: "inline" }}>
                        {vpa.vpa ? maskEmail(vpa.vpa) : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h4" style={{ display: "inline", marginRight: "1rem" }}>
                        UPI Holder:
                      </Typography>
                      <Typography variant="body1" style={{ display: "inline" }}>
                        {vpa.name ? maskName(vpa.name) : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
              }
            </Grid>
            <Grid container xs={6} style={{ paddingLeft: "1rem" }}>
              <Grid container xs={12}>
                <Grid item xs={8}>
                  <Typography variant="h3" style={{ fontWeight: "bolder" }}>
                    Account Details
                  </Typography>
                </Grid>
                <Grid container xs={4} justify="flex-end" style={{ paddingRight: '1.5rem' }}>
                  {isEditableBankDetail ?
                    <>
                      {bankLoading ? <></> :
                        <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer", marginRight: "1rem" }} color="primary"
                          onClick={updateBankDetails}>
                          Save
                        </Typography>}
                      <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer" }}
                        onClick={handleCancleBankEdit}>
                        Cancel
                      </Typography>
                    </>
                    :
                    <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer" }} color="primary"
                      onClick={() => {
                        if (bankDetails.lastValidatedOn) {
                          if (moment().diff(moment(bankDetails.lastValidatedOn), "days") > 14) {
                            setIsEditableBankDetail(true)
                          } else {
                            snackbar({
                              variant: SnackBarVariant.WARNING,
                              message: "You can change Bank details only after 14 days"
                            })
                          }
                        } else {
                          setIsEditableBankDetail(true)
                        }
                      }}>
                      Edit
                    </Typography>}
                </Grid>
              </Grid>
              {
                isEditableBankDetail ?
                  bankLoading ?
                    <Grid container xs={12} justify="center" alignContent="center" style={{ marginTop: "2rem" }}>
                      <CircularProgress />
                    </Grid>
                    :
                    <Grid container xs={12} spacing={2} style={{ marginTop: "1rem", paddingRight: "1rem" }}>
                      <Grid item xs={6}>
                        <Typography variant="h4" style={{ color: "#808080" }}>
                          Bank Name
                        </Typography>
                        <TextField
                          variant="outlined"
                          value={bankDetails.bankName}
                          fullWidth
                          onChange={(e) => {
                            setBankDetails({
                              ...bankDetails,
                              bankName: e.target.value || ""
                            })
                          }}
                          style={{ marginTop: "0.5rem" }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" style={{ color: "#808080" }}>
                          Account Holder's Name
                        </Typography>
                        <TextField
                          variant="outlined"
                          value={bankDetails.accountName}
                          fullWidth
                          onChange={(e) => {
                            setBankDetails({
                              ...bankDetails,
                              accountName: e.target.value || ""
                            })
                          }}
                          style={{ marginTop: "0.5rem" }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" style={{ color: "#808080" }}>
                          Account Number
                        </Typography>
                        <TextField
                          variant="outlined"
                          value={bankDetails.accountNumber}
                          fullWidth
                          onChange={(e) => {
                            setBankDetails({
                              ...bankDetails,
                              accountNumber: e.target.value || ""
                            })
                          }}
                          style={{ marginTop: "0.5rem" }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" style={{ color: "#808080" }}>
                          Ifsc Code
                        </Typography>
                        <TextField
                          variant="outlined"
                          value={bankDetails.ifsc}
                          fullWidth
                          onChange={(e) => {
                            setBankDetails({
                              ...bankDetails,
                              ifsc: e.target.value || ""
                            })
                          }}
                          style={{ marginTop: "0.5rem" }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="h4" style={{ color: "#808080" }}>
                          Account Type
                        </Typography>
                        <TextField
                          variant="outlined"
                          value={bankDetails.accountType}
                          fullWidth
                          onChange={(e) => {
                            setBankDetails({
                              ...bankDetails,
                              accountType: e.target.value || ""
                            })
                          }}
                          style={{ marginTop: "0.5rem" }}
                        />
                      </Grid>
                    </Grid> :
                  <Grid container xs={12} style={{ marginTop: "1rem", paddingRight: "1rem" }}>
                    <Grid item xs={12} style={{ position: "relative" }}>
                      <Typography variant="h4" style={{ display: "inline", marginRight: "1rem" }}>
                        Bank Name:
                      </Typography>
                      <Typography variant="body1" style={{ display: "inline", position: "absolute", right: "5rem" }}>
                        {bankDetails.bankName || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ position: "relative" }}>
                      <Typography variant="h4" style={{ display: "inline", marginRight: "1rem" }}>
                        Account Holder's Name:
                      </Typography>
                      <Typography variant="body1" style={{ display: "inline", position: "absolute", right: "5rem" }}>
                        {bankDetails.accountName ? maskName(bankDetails.accountName) : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ position: "relative" }}>
                      <Typography variant="h4" style={{ display: "inline", marginRight: "1rem" }}>
                        Account Number:
                      </Typography>
                      <Typography variant="body1" style={{ display: "inline", position: "absolute", right: "5rem" }}>
                        {bankDetails.accountNumber ? maskString(bankDetails.accountNumber, 4) : "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ position: "relative" }}>
                      <Typography variant="h4" style={{ display: "inline", marginRight: "1rem" }}>
                        IFSC Code:
                      </Typography>
                      <Typography variant="body1" style={{ display: "inline", position: "absolute", right: "5rem" }}>
                        {bankDetails.ifsc || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ position: "relative" }}>
                      <Typography variant="h4" style={{ display: "inline", marginRight: "1rem" }}>
                        Account Type:
                      </Typography>
                      <Typography variant="body1" style={{ display: "inline", position: "absolute", right: "5rem" }}>
                        {bankDetails.accountType || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
              }
            </Grid>
          </Grid>
        </Paper >
      </Grid >
      <Grid container xs={12} style={{ padding: "1rem" }}>
        <Paper elevation={0} style={{ width: "100%", padding: "1rem" }}>
          <Grid item xs={12}>
            <Typography variant="h2" style={{ fontWeight: "bolder" }}> Payouts </Typography>
          </Grid>
          <Payouts key={payoutComponentNewKey} publisherId={publisherId} />
        </Paper>
      </Grid>
      <Dialog
        maxWidth={"sm"}
        open={withdrawMethodDialog}
        onClose={() => setWithdrawMethodDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={false}
      >
        {processingWithdrawlLoader ?
          <div className={classes.withdrawalLoader}>
            <CircularLoader />
          </div>
          :
          <>
            <DialogTitle id="alert-dialog-title">
              <Typography variant="h3">
                Select method for withdrawl
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container xs={12}>
                <Card style={{ display: "flex", width: "100%" }}>
                  <CardContent style={{ flex: '1 0 auto' }}>
                    <Grid container xs={12}>
                      <Grid item xs={10}>
                        <Typography component="h5" variant="h3">
                          UPI Withdrawl
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Withdrawl charges: {`₹${getWithdrawlCharges(TRANSFER_MODES.UPI, ledgerAccountDetails.withdrawableAmount)}`}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Amount to be credited: {`₹${ledgerAccountDetails.withdrawableAmount - getWithdrawlCharges(TRANSFER_MODES.UPI, ledgerAccountDetails.withdrawableAmount)}`}
                        </Typography>
                      </Grid>
                      <Grid container xs={2} alignItems="center" justify="center">
                        <ArrowForwardIcon className={classes.forwardIcon} onClick={() => requestWithdrawl(TRANSFER_MODES.UPI)} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card style={{ display: "flex", width: "100%", marginTop: "0.2rem" }}>
                  <CardContent style={{ flex: '1 0 auto' }}>
                    <Grid container xs={12}>
                      <Grid item xs={10}>
                        <Typography component="h5" variant="h3">
                          IMPS Withdrawl
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Withdrawl charges: {`₹${getWithdrawlCharges(TRANSFER_MODES.IMPS, ledgerAccountDetails.withdrawableAmount)}`}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Amount to be credited: {`₹${ledgerAccountDetails.withdrawableAmount - getWithdrawlCharges(TRANSFER_MODES.IMPS, ledgerAccountDetails.withdrawableAmount)}`}
                        </Typography>
                      </Grid>
                      <Grid container xs={2} alignItems="center" justify="center">
                        <ArrowForwardIcon className={classes.forwardIcon} onClick={() => requestWithdrawl(TRANSFER_MODES.IMPS)} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card style={{ display: "flex", width: "100%", marginTop: "0.2rem" }}>
                  <CardContent style={{ flex: '1 0 auto' }}>
                    <Grid container xs={12}>
                      <Grid item xs={10}>
                        <Typography component="h5" variant="h3">
                          NEFT Withdrawl
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Withdrawl charges: {`₹${getWithdrawlCharges(TRANSFER_MODES.NEFT, ledgerAccountDetails.withdrawableAmount)}`}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Amount to be credited: {`₹${ledgerAccountDetails.withdrawableAmount - getWithdrawlCharges(TRANSFER_MODES.NEFT, ledgerAccountDetails.withdrawableAmount)}`}
                        </Typography>
                      </Grid>
                      <Grid container xs={2} alignItems="center" justify="center">
                        <ArrowForwardIcon className={classes.forwardIcon} onClick={() => requestWithdrawl(TRANSFER_MODES.NEFT)} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Fab variant="extended" size="medium" onClick={() => setWithdrawMethodDialog(false)} className="blackBackButton">
                Cancel
              </Fab>
            </DialogActions>
          </>
        }
      </Dialog>
    </Grid >
  );
};

export default Payment;