import { Typography } from "@material-ui/core";
import React from "react";
import { IDigitalBookResponse } from "../../../../reducers/books/types";
import { BookRentalTypes, OrderItemTypes } from "../../../../utils/constants";

interface IProps {
  book: IDigitalBookResponse
  selectedBookTypeToBuy: string
  selectedRentalTypeToBuy: string
}
interface IDiscountProp {
  mrp: number;
  discountedPrice: number
}

const ShowCurrentPrice: React.FC<IProps> = (props) => {

  //  function to tell offer discount is present on book type or not.
  const isOfferDiscountPresent = () => {
    let flag: boolean = false
    if (props.book.isDiscounted && props.book?.discountedPrice) {
      switch (props.selectedBookTypeToBuy) {
        case OrderItemTypes.BUY_DIGITAL:
          if (props.book.discountedPrice.sellPrice < props.book.pricing.sellPrice) {
            flag = true
          }
          break;
        case OrderItemTypes.AUDIOBOOK:
          //CHECKME: fix if price is in audiobook
          const audibookPrice = props.book?.audioBook?.price ?? 0
          if (props.book.discountedPrice.audiobookPrice < audibookPrice) {
            flag = true
          }
          break;
        case OrderItemTypes.PAPERBACK:
          if (props.book.discountedPrice.paperBackPrice < props.book.pricing.paperBackPrice) {
            flag = true
          }
          break;
        case OrderItemTypes.HARDBOUND:
          if (props.book.discountedPrice.hardboundPrice < props.book.pricing.hardboundPrice) {
            flag = true
          }
          break;
        case OrderItemTypes.RENTAL_DIGITAL:
          switch (props.selectedRentalTypeToBuy) {
            case BookRentalTypes.WEEKLY:
              if (props.book?.discountedPrice?.rentalPrice?.weekly < props.book?.pricing?.rentalPrice?.weekly) {
                flag = true
              }
              break;
            case BookRentalTypes.FORTNIGHTLY:
              if (props.book?.discountedPrice?.rentalPrice?.fortNightly < props.book?.pricing?.rentalPrice?.fortNightly) {
                flag = true
              }
              break;
            case BookRentalTypes.MONTHLY:
              if (props.book?.discountedPrice?.rentalPrice?.monthly < props.book?.pricing?.rentalPrice?.monthly) {
                flag = true
              }
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
    return flag
  }

  // DiscountPriceDiv COMPONENT
  const DiscountPriceDiv: React.FC<IDiscountProp> = (props) => (
    <div style={{ display: "flex" }}>
      <Typography variant="h2" style={{ marginRight: "0.3rem" }}>for</Typography>
      <Typography variant="h2" style={{ textDecoration: "line-through", marginRight: "0.3rem" }}>{"₹" + props.mrp}</Typography>
      <Typography variant="h2">{" ₹" + props.discountedPrice}</Typography>
    </div>)


  // ShowCurrentPrice  COMPONENT
  return (
    <>
      {props.selectedBookTypeToBuy === OrderItemTypes.PAPERBACK &&
        <>
          {isOfferDiscountPresent() ?
            <DiscountPriceDiv mrp={props.book.pricing.paperBackPrice} discountedPrice={props.book.discountedPrice?.paperBackPrice || 0} /> :
            <Typography variant="h2">{"for ₹" + props.book.pricing.paperBackPrice || 0}</Typography>}
        </>
      }
      {props.selectedBookTypeToBuy === OrderItemTypes.HARDBOUND &&
        <>
          {isOfferDiscountPresent() ?
            <DiscountPriceDiv mrp={props.book.pricing.hardboundPrice} discountedPrice={props.book.discountedPrice?.hardboundPrice || 0} /> :
            <Typography variant="h2">{"for ₹" + props.book.pricing.hardboundPrice || 0}</Typography>}
        </>
      }
      {props.selectedBookTypeToBuy === OrderItemTypes.AUDIOBOOK &&
        <>
          {isOfferDiscountPresent() ?
            // CHECKME: price in audioschema
            <DiscountPriceDiv mrp={props.book.audioBook?.price || 0} discountedPrice={props.book.discountedPrice?.audiobookPrice || 0} /> :
            <Typography variant="h2">{"for ₹" + props.book.audioBook?.price || 0}</Typography>}
        </>
      }
      {/* when user selects Buy Digital copy */}
      {props.selectedBookTypeToBuy === OrderItemTypes.BUY_DIGITAL &&
        <>
          {isOfferDiscountPresent() ?
            <DiscountPriceDiv mrp={props.book.pricing.sellPrice} discountedPrice={props.book.discountedPrice?.sellPrice || 0} /> :
            <Typography variant="h2">{"for ₹" + props.book.pricing.sellPrice || 0}</Typography>}
        </>
      }
      {/* when user selects Buy Rental copy */}
      {props.selectedBookTypeToBuy === OrderItemTypes.RENTAL_DIGITAL &&
        <>
          {props.selectedRentalTypeToBuy === BookRentalTypes.WEEKLY &&
            <>
              {isOfferDiscountPresent() ?
                <DiscountPriceDiv mrp={props.book?.pricing?.rentalPrice?.weekly} discountedPrice={props.book?.discountedPrice?.rentalPrice?.weekly || 0} /> :
                <Typography variant="h2">{"for ₹" + props.book?.pricing?.rentalPrice?.weekly || 0}</Typography>}
            </>}
          {props.selectedRentalTypeToBuy === BookRentalTypes.FORTNIGHTLY &&
            <>
              {isOfferDiscountPresent() ?
                <DiscountPriceDiv mrp={props.book?.pricing?.rentalPrice?.fortNightly} discountedPrice={props.book.discountedPrice?.rentalPrice?.fortNightly || 0} /> :
                <Typography variant="h2">{"for ₹" + props.book?.pricing?.rentalPrice?.fortNightly || 0}</Typography>}
            </>}
          {props.selectedRentalTypeToBuy === BookRentalTypes.MONTHLY &&
            <>
              {isOfferDiscountPresent() ?
                <DiscountPriceDiv mrp={props.book?.pricing?.rentalPrice?.monthly} discountedPrice={props.book.discountedPrice?.rentalPrice?.monthly || 0} /> :
                <Typography variant="h2">{"for ₹" + props.book?.pricing?.rentalPrice?.monthly || 0}</Typography>}
            </>}
        </>
      }
    </>)
}

export default ShowCurrentPrice;