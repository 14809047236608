import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import callApi from '../../../utils/apiUtil';
import { IFiltersAndSorting } from '../../../utils/common.interface';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import CircularLoader from '../../common/CircularLoader';
import { DataTable } from '../../common/Datatable/Datatable';
import { Pagination } from '../../common/Datatable/types';

interface IActiveUserStat {
  name: string;
  contactNumber: string;
  lastLogin: string,
  osVersion: string,
  deviceModel: string,
  fcmTokenPresent: string,
  lastNotificationClicked: string,
  lastNotificationSubject: string
}

interface IGeneralStat {
  name: string;
  contactNumber: string;
}

interface IProps {
  selectedType: string
  title: string
}

const columnsForActiveUser: any = [
  {
    label: "User",
    name: "name",
  },
  {
    label: "Contact",
    name: "contactNumber",
  },
  {
    label: "Last Login",
    name: "lastLogin",
    options: {
      customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY, h:mm A")
    }
  },
  {
    label: "Device Model",
    name: "deviceModel",
  },
  {
    label: "OS Version ",
    name: "osVersion",
  },
  {
    label: "FCM Present",
    name: "fcmTokenPresent",
  },
  {
    label: "Last Notification Clicked",
    name: "lastNotificationClicked",
  },
  {
    label: "Last Notification Subject",
    name: "lastNotificationSubject",
  },
]

const columnsForGeneralType: any = [
  {
    label: "User",
    name: "name",
  },
  {
    label: "Contact",
    name: "contactNumber",
  }]


const DevicesStatsTable: React.FC<IProps> = (props) => {
  const [stats, setStats] = useState<IActiveUserStat[] | IGeneralStat[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasMoreCount, setHasMoreCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  // FETCH ORDER
  useEffect(() => {
    setLoading(true)
    fetchDevicesStats({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [props.selectedType])


  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    count: totalCount,
    search: false,
    sort: false
  };


  const fetchDevicesStats = (args: IFiltersAndSorting) => {

    callApi(PATHNAMES.GET_MOBILE_DEVICE_STATS_TABLE_FOR_SELECTED_TYPE(props.selectedType, args.pageNumber, args.pageSize), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setStats(res.data.data);
        setTotalCount(res.data.totalCount)
        if (!hasMoreCount) {
          setHasMoreCount(res.hasMoreData)
        }
      }
    })
    setLoading(false)
  }


  if (loading) {
    return <CircularLoader />
  }


  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h2'>{props.title}</Typography>
      </Grid>
      <Grid container xs={12}>
        <DataTable
          title={`Total ${props.title} (${totalCount})`}
          rows={stats}
          columns={props.selectedType === 'active' ? columnsForActiveUser : columnsForGeneralType}
          options={options}
          hideSelectableRows={true}
          serverSide
          fetchData={fetchDevicesStats}
        />
      </Grid>
    </Grid>
  )
}

export default DevicesStatsTable;