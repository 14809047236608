import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Typography,
  Fab,
  makeStyles,
  Box,
  InputAdornment,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import React, { useState } from "react";

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (data: IApprovePublisherArgs) => void;
}

export interface IApprovePublisherArgs {
  rentalShare: number;
  digitalShare: number;
  audiobookShare: number;
  paperbackShare: number;
  hardboundShare: number;
  perBookCharges: number;
  requiresShipping: boolean
}

const useStyles = makeStyles(() => ({
  itemStyle: { marginTop: '1rem' }
}))

const ApprovePublisher: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [requiresShipping, setRequiresShipping] = useState<boolean>(true);
  const [values, setValues] = useState({
    rentalShare: 0,
    digitalShare: 0,
    audiobookShare: 0,
    paperbackShare: 0,
    hardboundShare: 0,
    perBookCharges: 0,
  })

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography variant="h3">
              Approve Publisher
            </Typography>
          </Grid>
        </Grid>

      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={3} className={classes.itemStyle}>
            <TextField
              label="Digital Rental Share"
              type="number"
              fullWidth
              defaultValue={values.rentalShare}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              onChange={(event: any) => {
                let q = event.target.value;
                if (parseFloat(q)) {
                  setValues({
                    ...values,
                    rentalShare: parseFloat(q),
                    digitalShare: parseFloat(q)
                  })
                }
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.itemStyle}>
            <TextField
              label="Audio Book Share"
              type="number"
              fullWidth
              defaultValue={values.audiobookShare}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              onChange={(event: any) => {
                let q = event.target.value;
                if (parseFloat(q)) {
                  setValues({
                    ...values,
                    audiobookShare: parseFloat(q),
                  })
                }
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.itemStyle}>
            <TextField
              label="Print Purchase Share"
              type="number"
              fullWidth
              defaultValue={values.paperbackShare}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              onChange={(event: any) => {
                let q = event.target.value;
                if (parseFloat(q)) {
                  setValues({
                    ...values,
                    paperbackShare: parseFloat(q),
                    hardboundShare: parseFloat(q)
                  })
                }
              }}
            />
          </Grid>
          <Grid item xs={3} className={classes.itemStyle}>
            <TextField
              label="Per Book Charges"
              type="number"
              fullWidth
              defaultValue={values.perBookCharges}
              InputProps={{
                endAdornment: <InputAdornment position="end">₹</InputAdornment>
              }}
              onChange={(event: any) => {
                let q = event.target.value;
                if (parseFloat(q)) {
                  setValues({
                    ...values,
                    perBookCharges: parseFloat(q)
                  })
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.itemStyle}>
          <FormControlLabel
            label="Requires Shipping by Rachnaye"
            control={
              <Checkbox
                checked={requiresShipping}
                onChange={(e: any) => { setRequiresShipping(e.target.checked) }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
        </Grid>
        <Grid container item xs={12} className={classes.itemStyle}>
          <Fab
            size="medium"
            variant="extended"
            type="submit"
            onClick={() => props.handleSubmit({ ...values, requiresShipping })}
          >
            Approve
          </Fab>
          <Box pl={2}></Box>
          <Fab
            size="medium"
            variant="extended"
            className="blackBackButton"
            onClick={props.handleClose}
          >
            Cancel
          </Fab>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ApprovePublisher;