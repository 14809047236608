import { Box, Checkbox, CircularProgress, Fab, FormControlLabel, Grid, InputAdornment, MenuItem, Radio, RadioGroup, TextField, TextareaAutosize, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import React, { useState } from 'react';
import callApi from '../../../utils/apiUtil';
import { BOOK_CATEGORIES, BOOK_LANGUAGES, GroupNotificationCategory, HTTP_METHODS, MobileOS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import { ErrorHandler } from '../../../utils/utils';
import FlatPickerBar from '../../common/FlatPickerBar';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';
import { ISendTestNotificationArgs } from './UpdateTemplates';



const useStyles = makeStyles((theme) => ({
  button1: {
    color: "black!important",
    backgroundColor: "#FFD36E!important",
  },
  numberField: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  phoneNumberTextArea: {
    width: "98%",
    padding: "0.8rem",
    fontSize: "1rem",
    minHeight: "5rem",
    fontFamily: "Poppins",
    backgroundColor: "#f2f2f2",
    resize: "none"
  }
}))

interface INotificationToSend {
  subject: string;
  body?: string;
  action: string;
  id?: string;
  scheduled: boolean;
  scheduledDate: string;
  priority: number;
  allUsers: boolean;
}

interface INotificationDetails {
  completedOrderStartDate: string,
  completedOrderEndDate: string,
  categories: string[],
  languages: string[],
  mobileOS: string[]
}

const NOTIFICATION_ACTION_PAGE = [
  "author",
  'book',
  'bundle',
  'cart',
  'home',
  'offer',
  'order',
  'post',
  'publisher',
  'search',
  'series',
  "writer"
]

// pages where resource id is options
// for validation purpose
const NOTIFICATION_ACTION_PAGES_WITHOUT_RESOURCE_ID = [
  'home',
  'offer',
  'cart'
]


const NotificationPriority = [
  {
    key: "VERY_IMPORTANT_AND_URGENT",
    value: 0
  },
  {
    key: "IMPORTANT",
    value: 1
  },
  {
    key: "AVERAGE_IMPORTANT",
    value: 2
  },
  {
    key: "NOT_IMPORTANT",
    value: 3
  },
  {
    key: "LOWEST",
    value: 4
  }
]

const MsgDivStyle = {
  backgroundColor: "#CBF7B8",
  borderRadius: "5px",
  padding: "3px"
}

const SendGeneralNotification: React.FC = () => {
  const classes = useStyles()
  const snackbar = useSnackBar();
  const [updating, setUpdating] = useState<boolean | undefined>(false)
  const [sendingNotificationToTest, setSendingNotificationToTest] = useState<boolean>(false)
  const [phoneNumbers, setPhoneNumbers] = useState<string>()
  const [notificationToSend, setNotificationToSend] = useState<INotificationToSend>({
    subject: "",
    body: "",
    action: "",
    id: "",
    scheduled: false, // false means urgent, true means shceduled/nonUrgent,
    scheduledDate: moment().startOf("day").toISOString(),
    priority: NotificationPriority[0].value,
    allUsers: false
  })
  const [notificationDetails, setNotificationDetails] = useState<INotificationDetails>({
    completedOrderStartDate: '',
    completedOrderEndDate: '',
    categories: [],
    languages: [],
    mobileOS: []
  })
  const [byOrderDateChecked, setByOrderDateChecked] = React.useState(false);
  const [urgentNotification, setUrgentNotification] = useState('urgent');
  const [notificationType, setNotificatinType] = useState(GroupNotificationCategory.PHONE_NUMBER_BASED);
  const [testNumber, setTestNumber] = useState<string>()

  //inputValue is textArea value
  const checkThePhoneNumberLengthFn = () => {
    if (!phoneNumbers) {
      return
    };
    const formattedNumbers = phoneNumbers.split(",").map((number: string) => number.trim()).filter((number: string) => number)
    if (formattedNumbers.length > 0) {
      const invalidNumbers: string[] = []
      formattedNumbers.forEach((phoneNum: string) => {
        if (phoneNum.length !== 10) {
          invalidNumbers.push(phoneNum)
        }
      })
      if (invalidNumbers.length) {
        snackbar({
          message: `${invalidNumbers.join(", ")} not valid 10 digit number`,
          variant: SnackBarVariant.ERROR
        })
      }

      return
    }
  }


  // for putting many users' phone number
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    if (/^[0-9,\s]*$/.test(inputValue)) {
      // Only update the state if the input is valid
      setPhoneNumbers(inputValue);
    } else {
      setPhoneNumbers(phoneNumbers);
    }
    return
  };

  //  phone number to test dummy notification by admin
  const handleTestNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    // maximum length of 10 digits
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setTestNumber(inputValue);
    }
  }


  const handleClickToTest = async () => {
    try {
      setSendingNotificationToTest(true)
      if (!testNumber || !testNumber.length) {
        throw new ErrorHandler("Valid Number is required to send Demo Notification")
      }

      if (!notificationToSend.subject || !notificationToSend.body) {
        throw new ErrorHandler("Subject and conent is missing for Demo Notification")
      }

      // validation of subject and content
      handleTemplateValidation()

      // api call to send notification
      const data: ISendTestNotificationArgs = {
        phoneNumber: testNumber,
        subject: notificationToSend.subject,
        content: notificationToSend.body || "",
        resourceEntityId: notificationToSend.id || "",
        action: notificationToSend.action || ""
      }

      const res = await callApi(PATHNAMES.SEND_TEST_NOTIFICATION(), HTTP_METHODS.POST, {
        body: JSON.stringify({ data })
      })
      if (res && res.success) {
        snackbar({
          message: res.message,
          variant: SnackBarVariant.SUCCESS
        })
      }
      setSendingNotificationToTest(false)
      return
    } catch (error: any) {
      snackbar({
        message: error.message,
        variant: SnackBarVariant.ERROR
      })
      setSendingNotificationToTest(false)
      return
    }
  }


  const handleTemplateValidation = () => {
    try {
      if (!notificationToSend || !notificationToSend.subject || !notificationToSend.body) {
        throw new ErrorHandler("Fill Subject and body before saving")
      }

      if (byOrderDateChecked && !notificationDetails.completedOrderStartDate && !notificationDetails.completedOrderEndDate) {
        throw new ErrorHandler("Please add Order Date")
      }

      if (byOrderDateChecked && notificationDetails.completedOrderStartDate && notificationDetails.completedOrderEndDate) {
        if (notificationDetails.completedOrderEndDate < notificationDetails.completedOrderStartDate) {
          throw new ErrorHandler("Order End Date can not be less than Order Start Date")
        }
      }

      if (notificationType === GroupNotificationCategory.PHONE_NUMBER_BASED) {

        if (notificationToSend.allUsers) {
          return
        }

        if (phoneNumbers === undefined || (Array.isArray(phoneNumbers) && !phoneNumbers.map(v => v.trim()).length)) {
          throw new ErrorHandler("Please add phone number(s)")
        }
      }

      // variable finder regex
      const regex = /#([^#]+)#/g;

      const notifiSubjectVariableMatches = [];
      const notifiBodyVariableMatches = [];
      let match;

      // updated subject variables
      while ((match = regex.exec(notificationToSend?.subject || "")) !== null) {
        notifiSubjectVariableMatches.push(match[0]);
      }

      match = null

      // updated body variables
      while ((match = regex.exec(notificationToSend?.body || "")) !== null) {
        notifiBodyVariableMatches.push(match[0]);
      }

      // variable should not be added for general notification/s
      if (notifiSubjectVariableMatches.length) {
        throw new ErrorHandler(`${notifiSubjectVariableMatches.join(", ")} : Sorry, varibale insertion not allowed`)
      }
      if (notifiBodyVariableMatches.length) {
        throw new ErrorHandler(`${notifiBodyVariableMatches.join(", ")} : Sorry, varibale insertion not allowed`)
      }

      // resourceId check
      if (!notificationToSend.action) {
        return
      }


      if (notificationToSend.action &&
        NOTIFICATION_ACTION_PAGES_WITHOUT_RESOURCE_ID.includes(notificationToSend.action) &&
        !notificationToSend.id
      ) {
        return
      }
      if (notificationToSend.action &&
        NOTIFICATION_ACTION_PAGE.includes(notificationToSend.action) &&
        !notificationToSend.id
      ) {
        throw new ErrorHandler("Resource Id required for Selected Action page selected")
      }


      return
    } catch (error) {
      throw error
    }
  }

  const handleCancelBtn = () => {
    setNotificationToSend({
      subject: "",
      body: "",
      action: "",
      id: "",
      scheduled: false,
      scheduledDate: "",
      priority: NotificationPriority[0].value,
      allUsers: false
    })
    setPhoneNumbers("")
    setTestNumber(undefined)
    return
  }

  const getNotificationDetails = () => {
    const { completedOrderStartDate, completedOrderEndDate, ...rest } = notificationDetails;
    return byOrderDateChecked ? { ...rest, completedOrderStartDate, completedOrderEndDate } : { ...rest };
  };

  const handleSaveBtn = async () => {
    try {
      setUpdating(true)

      //validation
      handleTemplateValidation()

      // api call to update
      const formattedPhoneNumbers = phoneNumbers?.replace(/\n/g, "").split(",")

      let data;
      if (notificationType === GroupNotificationCategory.QUERY_SELECTION_BASED) {
        data = {
          groupNotificationCategory: GroupNotificationCategory.QUERY_SELECTION_BASED,
          ...getNotificationDetails(),
          scheduled: notificationToSend.scheduled,
          subject: notificationToSend.subject,
          content: notificationToSend.body,
          priority: notificationToSend.priority,
          ...(urgentNotification !== 'urgent' && { scheduledDate: notificationToSend.scheduledDate }),
          metaAttributes: {
            action: notificationToSend.action
          },
          resourceEntityId: notificationToSend.id
        }
      } else {
        data = {
          groupNotificationCategory: GroupNotificationCategory.PHONE_NUMBER_BASED,
          phoneNumbers: formattedPhoneNumbers,
          scheduled: notificationToSend.scheduled,
          subject: notificationToSend.subject,
          content: notificationToSend.body,
          priority: notificationToSend.priority,
          allUsers: notificationToSend.allUsers,
          scheduledDate: notificationToSend.scheduledDate,
          metaAttributes: {
            action: notificationToSend.action
          },
          resourceEntityId: notificationToSend.id
        }
      }
      await sendNotificationToAllApiCall(data)
      setUpdating(false)
      return
    } catch (error: any) {
      snackbar({
        message: error.message,
        variant: SnackBarVariant.ERROR
      })
      setUpdating(false)
      return
    }
  }

  const sendNotificationToAllApiCall = async (data: any) => {
    try {
      const res = await callApi(PATHNAMES.SEND_GROUP_NOTIFICATION(), HTTP_METHODS.POST, {
        body: JSON.stringify({ data })
      })

      if (!res || !res.success) {
        throw new ErrorHandler(res)
      }

      setUpdating(false)
      snackbar({
        message: notificationToSend.scheduled ? "Notification Scheduled" : "Notification sent to all",
        variant: SnackBarVariant.SUCCESS
      })
      return
    } catch (error) {
      throw error
    }

  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotificatinType(event.target.value as GroupNotificationCategory);
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setByOrderDateChecked(event.target.checked);
  };

  const handleUrgentNotificaiton = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationToSend({
      ...notificationToSend,
      scheduled: event.target.value === "urgent" ? false : true
    })
    setUrgentNotification((event.target as HTMLInputElement).value);
  };

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h2'> Send Custom Notifications To Mobile User</Typography>
      </Grid>
      <Grid container alignItems='center' xs={12} style={{ padding: "0.6rem", margin: '0.6rem 0rem' }} >
        <Grid item xs={12}>
          <Typography variant='h3'> What type of notification you want to send?</Typography>
        </Grid>
        <Grid container xs={8} style={{ marginTop: "0.5rem" }} >
          <RadioGroup style={{ width: "100%" }} aria-label="urgentNotification" name="urgentNotification" value={urgentNotification} onChange={handleUrgentNotificaiton}>
            <Grid container xl={12} lg={12} md={12} sm={12} xs={12} >
              <FormControlLabel value="urgent" control={<Radio />} label="Urgent notificaion" />
              <FormControlLabel value="non-urgent" control={<Radio />} label="Date based notifications" />
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid container spacing={2} alignItems='center' style={{ marginTop: "0.5rem" }}>
          {notificationToSend.scheduled &&
            <>
              <Grid item xs={12} md={2}>
                <FlatPickerBar
                  handleDateChange={(value: Date) => {
                    setNotificationToSend({
                      ...notificationToSend,
                      scheduledDate: moment(value).startOf("day").toISOString()
                    })
                  }}
                  defaultDateTime={moment().startOf("day").toISOString()}
                  label={`Scheduled Date`}
                  identifier="scheduledDate"
                  name="Scheduled Date"
                  required
                  value={notificationToSend.scheduledDate}
                  minDate={moment().startOf('day').toDate()}
                />
              </Grid>

              <Grid item xs={12} md={3} style={{ padding: "0.6rem" }}>
                <TextField
                  select
                  fullWidth
                  label="Select Schedule Priority"
                  onChange={(e) => {
                    setNotificationToSend({
                      ...notificationToSend,
                      priority: parseInt(e.target.value)
                    })
                  }}
                  placeholder={"Schedule Priority"}
                  InputProps={{
                    style: { fontSize: '1rem' },
                  }}
                  value={notificationToSend.priority}
                  name={"priority"}
                  variant="outlined"
                >
                  {NotificationPriority.map((priority, idx) => (
                    <MenuItem key={idx} value={priority.value}>
                      {priority.key.toUpperCase().replaceAll("_", " ")}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </>
          }
        </Grid>
      </Grid>


      <Grid container alignItems='center' xs={12} style={{ padding: "0.6rem", margin: '0.6rem 0rem' }} >
        <Grid item xs={12} >
          <Typography variant='h3'>How you want to select users?</Typography>
        </Grid>
        <Grid container xs={8} style={{ marginTop: "0.5rem" }}>
          <RadioGroup style={{ width: "100%" }} value={notificationType} aria-label="notificationType" name="notificationType" defaultChecked={true} onChange={handleChange}>
            <Grid container xl={12} lg={12} md={12} sm={12} xs={12} >
              <FormControlLabel value="phoneNumberBased" control={<Radio />} label="By Phone Number" />
              <FormControlLabel value="customQuerySelectionBased" control={<Radio />} label="By Query" />
            </Grid>
          </RadioGroup>
        </Grid>
        {notificationType === GroupNotificationCategory.QUERY_SELECTION_BASED ?
          <Grid container xs={12}>

            <Grid container alignItems='center' xs={12} md={6} style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
              <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: 'start' }}>

                <Checkbox
                  style={{ margin: "0 1 0 0rem" }}
                  checked={byOrderDateChecked}
                  onChange={handleCheckbox}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography>By order date?</Typography>
              </Grid>
              {byOrderDateChecked &&
                <Grid container spacing={2} alignItems='center' style={{ marginTop: '0.4rem' }}>
                  <Grid xs={12} md={3} item >
                    <FlatPickerBar
                      handleDateChange={(value: Date) => {
                        setNotificationDetails({
                          ...notificationDetails,
                          completedOrderStartDate: moment(value).startOf("day").toISOString()
                        })
                        if (moment(value) > moment(notificationDetails.completedOrderEndDate)) {
                          setNotificationDetails({
                            ...notificationDetails,
                            completedOrderEndDate: moment(value).endOf("day").toISOString()
                          })
                        }
                      }}
                      label={`Order Start Date`}
                      identifier="startDate"
                      name="startDate"
                      required
                      value={notificationDetails.completedOrderStartDate}
                    />
                  </Grid>
                  <Grid xs={12} md={3} item style={{ padding: "0.6rem" }}>
                    <FlatPickerBar
                      handleDateChange={(value: Date) => {
                        setNotificationDetails({
                          ...notificationDetails,
                          completedOrderEndDate: moment(value).endOf("day").toISOString()
                        })
                      }}
                      label={`Order End Date`}
                      identifier="endDate"
                      name="endDate"
                      value={notificationDetails.completedOrderEndDate}
                    />
                  </Grid>
                </Grid>
              }


            </Grid>
            <Grid container xs={12} style={{ justifyContent: "start", display: "flex", paddingBottom: "1rem", marginTop: "0.5rem" }} spacing={1}>
              <Grid item xs={12} style={{ marginBottom: '0.4rem' }}>
                <Typography>Select the parameters</Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal={true}
                  multiple
                  id="tags-outlined"
                  options={BOOK_CATEGORIES}
                  getOptionLabel={(option) => option.label}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Categories"
                      placeholder="Categories"
                    />
                  )}
                  onChange={(event, value) => {
                    setNotificationDetails({
                      ...notificationDetails,
                      categories: value.map(item => item.value)
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ paddingInline: "0.6rem" }}>
                <Autocomplete
                  disablePortal={true}
                  multiple
                  id="tags-outlined"
                  options={BOOK_LANGUAGES}
                  getOptionLabel={(option) => option.label}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Languages"
                      placeholder="Languages"
                    />
                  )}
                  onChange={(event, value) => {
                    setNotificationDetails({
                      ...notificationDetails,
                      languages: value.map(item => item.value)
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} style={{ paddingInline: "0.6rem" }}>
                <Autocomplete
                  disablePortal={true}
                  multiple
                  id="tags-outlined"
                  options={MobileOS}
                  getOptionLabel={(option) => option.label}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Mobile OS"
                      placeholder="Mobile OS"
                    />
                  )}
                  onChange={(event, value) => {
                    setNotificationDetails({
                      ...notificationDetails,
                      mobileOS: value.map(item => item.value)
                    })
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={7} style={{ paddingTop: "0.6rem" }}>
                {/* add more notes f needed */}
                {[
                  "Note: Notifications will be sent based on all selected field combinations.",
                  "If none of field selected, it will be considered as select all for that particular field, example: category, language or mobile os"
                ].map(item => {
                  return (<Typography variant='h6' style={{ color: 'red', padding: "0.3rem" }}> {`• ${item}`}</Typography>)
                })}
              </Grid>
            </Grid>

          </Grid> :
          <Grid container xs={12}>
            <Grid container xs={12}>
              {/* field to add numbers */}
              <Grid item xs={12} style={{ padding: "0.6rem", marginTop: "0.4rem" }}>
                <TextareaAutosize
                  id='addPhonenumberTextArea'
                  // ref={textarea_ref}
                  disabled={notificationToSend.allUsers}
                  aria-label="minimum height"
                  placeholder={`Add Phone Numbers without +91, with " , "(comma) as separater`}
                  value={phoneNumbers}
                  onBlur={checkThePhoneNumberLengthFn}
                  onChange={handleInputChange}
                  className={classes.phoneNumberTextArea}
                />
              </Grid>
            </Grid>
            {/* send all checkbox */}
            <Grid container xs={12}>
              <Grid item xs={12} style={{ paddingLeft: "0.6rem" }}>
                OR  <span style={notificationToSend.allUsers ? MsgDivStyle : {}}>send to all Users in system</span>
                <Checkbox
                  aria-label='all user checkbox'
                  value={notificationToSend.allUsers}
                  onChange={e => setNotificationToSend({ ...notificationToSend, allUsers: e.target.checked })}
                />
              </Grid>
            </Grid>
          </Grid>}

      </Grid>



      {/* subject and content */}
      <Grid container xs={12} style={{ marginTop: "0.5rem" }}>
        <Grid item xs={12} style={{ paddingInline: "0.6rem", marginBottom: "0.6rem" }}>
          <Typography variant='h3'> Notification Body</Typography>
        </Grid>
        <Grid item xs={12} md={5} style={{ padding: "0.6rem" }}>
          <TextField
            style={{ backgroundColor: "#E8E8E8" }}
            fullWidth
            onChange={(e) => {
              setNotificationToSend({
                ...notificationToSend,
                subject: e.target.value
              })
            }}
            placeholder={"Subject"}
            InputProps={{
              style: { fontSize: '1rem' }
            }}
            value={notificationToSend?.subject}
            name={"subject"}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12} md={7} style={{ padding: "0.6rem" }}>
          <TextField
            style={{ backgroundColor: "#E8E8E8", fontSize: "3rem" }}
            fullWidth
            onChange={(e) => {
              setNotificationToSend({
                ...notificationToSend,
                body: e.target.value
              })
            }}
            InputProps={{
              style: { fontSize: '1rem' }
            }}
            placeholder={"Content"}
            value={notificationToSend?.body}
            name={"content"}
            variant='outlined'
          />
        </Grid>

      </Grid>
      {/* action and entity id */}
      <Grid container xs={12} style={{ marginTop: "1rem" }}>
        <Grid item xs={12} style={{ paddingInline: "0.6rem", marginBottom: "0.6rem" }}>
          <Typography variant='h3'> Notification Action</Typography>
        </Grid>
        <Grid item xs={12} md={2} style={{ padding: "0.6rem" }}>
          <TextField
            select
            fullWidth
            label="Select Action Page"
            onChange={(e) => {
              setNotificationToSend({
                ...notificationToSend,
                action: e.target.value
              })
            }}
            placeholder={"Action Page"}
            InputProps={{
              style: { fontSize: '1rem' },
            }}
            value={notificationToSend.action}
            name={"actionPage"}
            // helperText="Select Action Page"
            variant="outlined"
          >
            {NOTIFICATION_ACTION_PAGE.map((value, idx) => (
              <MenuItem key={idx} value={value}>
                {value.toUpperCase()}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} style={{ padding: "0.6rem" }}>
          <TextField
            fullWidth
            label="Resource ID (optional for HomePage)"
            className={classes.numberField}
            value={notificationToSend.id}
            onChange={(e) => {
              setNotificationToSend({
                ...notificationToSend,
                id: e.target.value
              })
            }}
            variant="filled" />
        </Grid>

      </Grid>
      <Grid container xs={12} >
        <Grid container xs={12} lg={7} alignItems='center' style={{ margin: "1rem 0rem" }} >
          <Grid item xs={12} style={{ paddingInline: "0.6rem", marginBottom: "0.6rem" }}>
            <Typography variant='h3'>Test Live Notification</Typography>
          </Grid>
          <Grid item xs={4} style={{ padding: "0.6rem" }}>
            <TextField
              label="Phone Number"
              type="numeric"
              className={classes.numberField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {`+91 `}
                  </InputAdornment>
                ),
              }}
              value={testNumber}
              onChange={handleTestNumberChange}
              variant="filled" />
          </Grid>
          <Grid item xs={3}>
            <Fab
              variant="extended"
              size="medium"
              className={classes.button1}
              onClick={handleClickToTest}
            >
              {sendingNotificationToTest ?
                <CircularProgress size={'1rem'} style={{ color: "black" }} /> :
                "click to test"
              }
            </Fab>
          </Grid>
        </Grid>
        <Grid container xs={12} >
          <Grid item xs={12} alignItems='center' style={{ padding: "0.6rem", display: "flex", justifyContent: "start", }} spacing={2}>
            <Fab
              variant="extended"
              size="medium"
              onClick={handleSaveBtn}
            >
              {updating ?
                <CircularProgress size={'1rem'} style={{ color: "white" }} />
                :
                "Save"
              }
            </Fab>
            <Box
              pl={2}
              style={{ marginLeft: "1rem" }}
            />
            <Fab
              variant="extended"
              size="medium"
              className={'blackBackButton'}
              onClick={handleCancelBtn}
            >Cancel
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SendGeneralNotification;