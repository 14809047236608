import {
  Avatar, CircularProgress, Fab,
  Grid,
  makeStyles, Paper, Typography
} from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/rachnayeLight.png";
import { IBook } from "../../../reducers/books/types";
import { IIamUserState } from "../../../reducers/user/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { getCategoryLabelByValue } from "../../../utils/bookUtils";
import { IFiltersAndSorting } from "../../../utils/common.interface";
import { bookUpdateFieldsMap, bookUploadFieldsMap, CsvUploadStatusType, HTTP_METHODS, possibleCsvMimeTypes } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { handleRowHover, handleRowHoverLeave } from "../../../utils/utils";
import BooksExcelDownloadBtn from "../../common/BooksExcelDownload";
import CustomContextMenu from "../../common/CustomContextMenu/CustomContextMenu";
import { IContextMenuPosition } from "../../common/CustomContextMenu/types";
import { DataTable } from "../../common/Datatable/Datatable";
import { Pagination } from "../../common/Datatable/types";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { BulkCsvUpdateDialog } from "./BulkCsvUpdateDialog";
import { BulkCsvUploadDialog } from "./BulkCsvUploadDialog";
const xlsxParser = require('xlsx-parse-json');


const useStyles = makeStyles((theme) => ({
  bookCardDiv: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: "solid 0.5px rgba(0, 0, 0, 0.18)",
    marginRight: "1rem"
  },
  bookHeader: {
    height: "5rem",
    padding: "1rem",
    borderBottom: "solid 0.5px rgba(0, 0, 0, 0.18)"
  },
  headRow: {
    backgroundColor: "#FFF4F2",
    height: "2rem"
  },
  addButton: {
    color: "black!important",
    backgroundColor: "#FFD36E!important",
    marginRight: "1rem",
  },
  fileSelectButton: {
    color: "white",
    backgroundColor: theme.palette.primary.dark
  },
  dividerClass: {
    color: "gray"
  },
  cardStyle: {
    display: "flex",
    height: "250px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px 3px 0px",
    cursor: "pointer"
  },
  paginationContainer: {
    padding: '1rem 3rem 0rem'
  },
  paginationStyle: {
    fontSize: '15px',
    "& .MuiTablePagination-caption": {
      fontSize: '15px'
    },
    "& .MuiInputBase-root": {
      borderRadius: '4px',
      border: "1px solid",
      height: '2rem'
    }
  }
}))


const Books: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState: IIamUserState = useSelector((state: IAppState) => state.user);

  const [, setUploadModalOpen] = useState<boolean>(false);
  const [fetchingBooks, setFetchingBooks] = useState<boolean>(true);
  const [books, setBooks] = useState<IBook[]>([]);
  const [totalBooks, setTotalBooks] = useState<number>(0);

  const [filterOptionList, setFilterOptionList] = useState({
    bookNames: [],
    languages: [],
    categories: [],
    contentTypes: [], //(plans)
  })

  const [fileValue, setFileValue] = useState<File | undefined>();
  const [fileName, setFileName] = useState<string>("");
  const [uploadStatus, setUploadStatus] = useState<number>(CsvUploadStatusType.DEFAULT);
  const [bulkUpload, setBulkUpload] = useState<boolean>(false);
  const [bulkUpdate, setBulkUpdate] = useState<boolean>(false);

  const [contextMenu, setContextMenu] = useState<IContextMenuPosition | null>(null);
  const [menuAttributes, setMenuAttributes] = useState({
    link: "",
  })


  useEffect(() => {
    if (userState && userState.id && userState.spaceId) {
      fetchPublisherBooks({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
      fetchBookFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState])


  const fetchBookFilters = () => {
    callApi(PATHNAMES.PUBLISHER_BOOKS_FILTERS(userState.spaceId), HTTP_METHODS.GET,).then((booksResponse) => {
      if (booksResponse && booksResponse.success && booksResponse.data) {
        setFilterOptionList(booksResponse.data);
      }
    })
  }

  const fetchPublisherBooks = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args
    setFetchingBooks(true);
    callApi(PATHNAMES.PUBLISHER_BOOKS_LIST(userState.spaceId, pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then((booksResponse: any) => {
      if (booksResponse && booksResponse.success && booksResponse.data) {
        setBooks(booksResponse.data.books);
        setTotalBooks(booksResponse.data.totalBooksCount)
        setFetchingBooks(false);
      }
    })
  }

  const onSelectDocument = (event: any) => {
    const file: File = event.target.files[0];
    setUploadStatus(CsvUploadStatusType.DEFAULT);
    if (file) {
      setFileName(file.name);
      if (possibleCsvMimeTypes.includes(file.type)) {
        setFileValue(file);
      } else {
        return snackbar({
          message: "Invalid File Type. Allowed type: xlsx",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  const uploadBooksViaFile = async (booksInputArr: any[]) => {
    const response = await callApi(PATHNAMES.UPLOAD_BOOKS_VIA_CSV, HTTP_METHODS.POST, {
      body: JSON.stringify({ books: booksInputArr, publisherId: userState.spaceId })
    });
    if (response && response.success) {
      const { uploadedBooks, updatedBooks, rejectedBooks } = response.data
      let message = ""
      let variant = SnackBarVariant.SUCCESS

      if (uploadedBooks.length) {
        message += `${uploadedBooks.length} Books got uploaded. `
      }
      if (updatedBooks.length) {
        message += `${updatedBooks.length} Books got updated. `
      }

      if (rejectedBooks.length) {
        message += `${rejectedBooks.length} Books got rejected. `
      }

      if (rejectedBooks.length && !uploadedBooks.length && !updatedBooks.length) {
        message = `All Books got rejected. `
        variant = SnackBarVariant.WARNING
      }
      if (uploadedBooks.length && !rejectedBooks.length && !updatedBooks.length) {
        message = `All Books got uploaded. `
      }
      if (updatedBooks.length && !rejectedBooks.length && !uploadedBooks.length) {
        message = `All Books got updated. `
      }

      snackbar({
        message,
        variant
      });

      setUploadModalOpen(false);
      setBulkUpload(false);
      setUploadStatus(CsvUploadStatusType.SUCCESS);
      setFileValue(undefined);
      setFileName("");
      if (userState.spaceId) {
        fetchPublisherBooks({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
      }
    } else {
      setUploadStatus(CsvUploadStatusType.ERROR);
      snackbar({
        message: "Xlsx contains incorrect values",
        variant: SnackBarVariant.ERROR
      })
    }
  }

  const updateBooksViaFile = async (booksInputArr: any[]) => {
    const response = await callApi(PATHNAMES.UPDATE_BOOKS_VIA_CSV, HTTP_METHODS.POST, {
      body: JSON.stringify({ books: booksInputArr, publisherId: userState.spaceId })
    });
    if (response && response.success) {
      const { updatedBooks, rejectedBooks } = response.data
      let message = ""
      let variant = SnackBarVariant.SUCCESS

      if (updatedBooks.length) {
        message += `${updatedBooks.length} Books got updated. `
      }

      if (rejectedBooks.length) {
        message += `${rejectedBooks.length} Books got rejected. `
      }

      if (rejectedBooks.length && !updatedBooks.length) {
        message = `All Books got rejected. `
        variant = SnackBarVariant.WARNING
      }

      if (updatedBooks.length && !rejectedBooks.length) {
        message = `All Books got updated. `
      }

      snackbar({
        message,
        variant
      });

      setBulkUpdate(false);
      setUploadStatus(CsvUploadStatusType.SUCCESS);
      setFileValue(undefined);
      setFileName("");
      if (userState.spaceId) {
        fetchPublisherBooks({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
      }
    } else {
      setUploadStatus(CsvUploadStatusType.ERROR);
      snackbar({
        message: "Xlsx contains incorrect values",
        variant: SnackBarVariant.ERROR
      })
    }
  }

  const uploadCsv = () => {
    try {
      if (fileValue) {
        setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
        xlsxParser.onFileSelection(fileValue)
          .then((parsedData: any) => {
            const booksInputArr = [];
            if (parsedData && parsedData.Worksheet?.length) {
              for (let index = 0; index < parsedData.Worksheet.length; index++) {
                const element: any = parsedData.Worksheet[index];
                const data: any = {}
                for (let key in element) {
                  data[bookUploadFieldsMap[key]] = element[key]
                }
                data.authors = data.authors.split(",")
                if (data.ageRange) {
                  switch (data.ageRange) {
                    case `0-11`:
                      data.ageRange = {
                        lowerLimit: 0,
                        upperLimit: 11
                      }
                      break;
                    case `11-18`:
                      data.ageRange = {
                        lowerLimit: 11,
                        upperLimit: 18
                      }
                      break;
                    case `18+`:
                      data.ageRange = {
                        lowerLimit: 18,
                        upperLimit: 100
                      }
                      break;
                    default:
                      data.ageRange = {
                        lowerLimit: 18,
                        upperLimit: 100
                      }
                      break;
                  }
                }
                booksInputArr.push(data);
              }
            }
            if (booksInputArr.length > 0) {
              uploadBooksViaFile(booksInputArr);
            } else {
              setUploadStatus(CsvUploadStatusType.ERROR);
              snackbar({
                message: "No data found.",
                variant: SnackBarVariant.ERROR
              });
            }
          });
      }
    } catch (error) {
      setUploadStatus(CsvUploadStatusType.ERROR);
    }
  }

  const updateCsv = () => {
    try {
      if (fileValue) {
        setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
        xlsxParser.onFileSelection(fileValue)
          .then((parsedData: any) => {
            const booksInputArr = [];
            if (parsedData && parsedData.Worksheet?.length) {
              for (let index = 0; index < parsedData.Worksheet.length; index++) {
                const element: any = parsedData.Worksheet[index];
                const data: any = {}
                for (let key in element) {
                  data[bookUpdateFieldsMap[key]] = element[key]
                }
                data.quantity = parseInt(data.quantity)
                data.price = parseInt(data.price)
                booksInputArr.push(data);
              }
            }
            if (booksInputArr.length > 0) {
              updateBooksViaFile(booksInputArr);
            } else {
              setUploadStatus(CsvUploadStatusType.ERROR);
              snackbar({
                message: "No data found.",
                variant: SnackBarVariant.ERROR
              });
            }
          });
      }
    } catch (error) {
      setUploadStatus(CsvUploadStatusType.ERROR);
    }
  }

  const handleContextMenuClick = (
    event: React.MouseEvent, bookId: string
  ) => {
    event.preventDefault();
    const value: any = contextMenu === null ? {
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    } : null;
    setContextMenu(value);
    setMenuAttributes({
      ...menuAttributes,
      link: `view-book?book=${bookId}`
    })
  };

  const displayAuthors = (authors: any) => {
    let nameStr = "";
    if (authors && authors.length) {
      const namesArr = authors.map((author: any) => {
        return author.name
      })
      if (namesArr.length < 2) {
        nameStr = namesArr.join(", ");
      } else {
        nameStr = namesArr.slice(0, 2).join(", ") + " ...more"
      }
    }
    return nameStr
  }

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      label: " ",
      name: "thumbnailFront",
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (thumbnail: string) => (
          <Avatar style={{ width: "2rem", height: "2rem" }} src={thumbnail || logo} alt="Book">
          </Avatar >)
      }
    },
    {
      label: "BOOK NAME",
      name: "title",
      options: {
        filterOptions: {
          names: filterOptionList.bookNames
        },
      }
    },
    {
      label: "AUTHORS",
      name: "authors",
      options: {
        setCellHeaderProps: () => ({ style: { fontWeight: 600, fontSize: '14px' } }),
        customBodyRender: displayAuthors,
        filter: false,
        sort: false,
      },
    },
    {
      label: "LANGUAGE",
      name: "language",
      options: {
        filterOptions: {
          names: filterOptionList.languages
        },
        customBodyRender: (data: string) => data?.toUpperCase()
      }
    },
    {
      label: "CATEGORY",
      name: "category",
      options: {
        filterOptions: {
          names: filterOptionList.categories
        },
        customBodyRender: getCategoryLabelByValue
      }
    },
    {
      label: "PLAN",
      name: "contentType",
      options: {
        filterOptions: {
          names: filterOptionList.contentTypes
        },
        customBodyRender: (data: string) => data?.toUpperCase(),
      }
    }, {
      label: "STATUS",
      name: "status",
      options: {
        filter: false,
        customBodyRender: (data: string) => data?.toUpperCase()
      }

    }, {
      label: "ACTIVE",
      name: "isActivated",
      options: {
        filter: false,
        customBodyRender: (data: any) => {
          return (
            <FiberManualRecordIcon
              fontSize="small"
              htmlColor={data ? "#5CD89F" : "#FF5C3E"}
            />
          );
        },
      },
    },
  ]


  const options: MUIDataTableOptions = {
    elevation: 0,
    onRowClick: (rowData: any, rowMeta: any) => {
      const [bookId] = rowData
      if (bookId) {
        navigate(`/view-book?book=${bookId}`)
      }
    },
    setRowProps: (row: any, rowIndex: number) => {
      return {
        onMouseEnter: () => handleRowHover(rowIndex),
        onMouseLeave: () => handleRowHoverLeave(rowIndex),
        onContextMenu: (event: React.MouseEvent) => {
          const [bookId] = row
          return handleContextMenuClick(event, bookId)
        }
      }
    },
    customToolbar: () => {
      return <>
        <BooksExcelDownloadBtn
          publisherId={userState.spaceId}
          className={classes.addButton}
        />
        <Fab
          variant="extended"
          size="small"
          className={classes.addButton}
          onClick={() => setBulkUpload(true)}
        >
          Create
        </Fab>
        <Fab
          variant="extended"
          size="small"
          className={classes.addButton}
          onClick={() => setBulkUpdate(true)}
        >
          Update
        </Fab></>
    },
    searchPlaceholder: "Search with Book name, language, category or plan",
    count: totalBooks,
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container>
        <DataTable
          title={<Typography variant="h2" style={{ fontWeight: 600, position: 'relative', right: '1rem' }}>
            Uploaded Books
            {fetchingBooks ? (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            ) :
              <Typography variant="h2" color="primary" style={{ display: "inline", marginLeft: "1rem" }}>
                {totalBooks}
              </Typography>
            }
          </Typography>}
          rows={books}
          columns={columns}
          options={options}
          hideSelectableRows={true}
          serverSide
          fetchData={fetchPublisherBooks}
        />
        <Grid container xs={12}>
          {bulkUpload && (
            <BulkCsvUploadDialog
              title={"Books"}
              subTitle={"Books"}
              csvTemplateLink={"https://rachnaye-dev.s3.ap-south-1.amazonaws.com/rachnaye/Book_upload_template.xlsx"}
              uploadErrors={[]}
              uploadStatus={uploadStatus}
              onDocumentSelect={onSelectDocument}
              fileName={fileName}
              uploadCsv={uploadCsv}
              handleClose={() => setBulkUpload(false)}
            />
          )}
        </Grid>
        <Grid container xs={12}>
          {bulkUpdate && (
            <BulkCsvUpdateDialog
              title={"Books"}
              subTitle={"Books"}
              csvTemplateLink={"https://rachnaye-dev.s3.ap-south-1.amazonaws.com/rachnaye/Book_update_template.xlsx"}
              uploadErrors={[]}
              uploadStatus={uploadStatus}
              onDocumentSelect={onSelectDocument}
              fileName={fileName}
              updateCsv={updateCsv}
              handleClose={() => setBulkUpdate(false)}
            />
          )}
        </Grid>
        <CustomContextMenu
          onClose={() => setContextMenu(null)}
          position={contextMenu}
          attributes={menuAttributes}
        />
      </Grid>
    </Paper>
  );
};

export default Books;