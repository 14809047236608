import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton, makeStyles,
  TextField, Typography
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { Autocomplete } from "@material-ui/lab";
import { Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {NumericFormat} from "react-number-format";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { IBook } from "../../../../reducers/books/types";
import { IDistributor, IFormattedDistributor, IUpdateInventoryDistributor } from "../../../../reducers/distributors/types";
import callApi from "../../../../utils/apiUtil";
import { BookTypes, HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";

interface IProps {
  open: boolean;
  handleClose: () => void;
  inventoryType: string;
  book: IBook;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "2rem",
  },
  uploadButton: {
    width: "12rem",
    backgroundColor: "#FF5C3E",
    color: "white",
    padding: ".8rem 1rem",
    borderRadius: ".3rem",
  },
  orangeButton: {
    backgroundColor: "#FF5C3E",
    color: "white",
    padding: ".8rem 1.5rem",
    borderRadius: ".3rem",
  },
  addButton: {
    color: "white",
    backgroundColor: theme.palette.primary.dark,
  },
  fileSelectButton: {
    color: "white",
    padding: "1rem",
    backgroundColor: theme.palette.primary.dark,
  },
  dividerClass: {
    color: "gray",
  },
  redirectLink: {
    display: "inline",
    cursor: "pointer",
    color: theme.palette.primary.main,
  }
}));


const UpdateInventory: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const [bookId] = useState<string>(props.book.id);
  const [publisherId] = useState<string>(props.book.publisher)
  const [bookDistributors, setBookDistributors] = useState<IUpdateInventoryDistributor[]>([])
  const [tempBookDistributors, setTempBookDistributors] = useState<IFormattedDistributor[]>([])
  const [publisherDistributors, setPublisherDistributors] = useState<IDistributor[]>([])
  const [autocompleteOptions, setAutocompleteOptions] = useState<string[]>([])


  // useEffect region
  useEffect(() => {
    //fetch distributor data
    fetchDistributors()
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!bookDistributors.length) {
      addDidtributorRows();
    }
    if (bookDistributors.length) {
      setBookDistributors(bookDistributors)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookDistributors])

  useEffect(() => {
    setPublisherDistributors(publisherDistributors)
  }, [publisherDistributors])


  useEffect(() => {
    const publisherNamesOptions = _.differenceBy(publisherDistributors, bookDistributors, 'name')
    const publisherNameArr: string[] = publisherNamesOptions.map(pub => pub.name)
    setAutocompleteOptions(publisherNameArr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, bookDistributors])

  // end -useEffect region


  const fetchDistributors = async () => {
    const bookDistributorRes = await callApi(PATHNAMES.GET_DISTRIBUTORS_FOR_BOOK(publisherId, bookId, props.inventoryType), HTTP_METHODS.GET)
    const pubDistributorRes = await callApi(PATHNAMES.GET_DISTRIBUTORS_FOR_PUBLISHER_LIST(publisherId || ""), HTTP_METHODS.GET)
    if (bookDistributorRes?.success && pubDistributorRes?.success && bookDistributorRes?.data && pubDistributorRes?.data) {
      setBookDistributors(bookDistributorRes.data)
      setTempBookDistributors(bookDistributorRes.data)
      setPublisherDistributors(pubDistributorRes.data)
    }
  }

  const addDidtributorRows = () => {
    const rowsArr = [...bookDistributors];
    rowsArr.push({
      id: "",
      name: "",
      publisherId: publisherId,
      inventory:
      {
        bookId: bookId,
        publisherId: publisherId,
        paperbackQuantity: 0,
        hardboundQuantity: 0
      }
    });
    setBookDistributors(rowsArr);
  };
  const updateInventoryFn = async (inventories: IUpdateInventoryDistributor[]) => {
    const data = { inventories }
    const res = await callApi(PATHNAMES.UPDATE_BOOK_INVENTORY(bookId, props.inventoryType), HTTP_METHODS.PATCH, {
      body: JSON.stringify({ data })
    })

    if (res?.success) {
      navigate(`/view-book?book=${bookId}`)
      props.handleClose()
      snackbar({
        message: res.message,
        variant: SnackBarVariant.SUCCESS
      })
    } else {
      props.handleClose()
      snackbar({
        message: "Something went wrong",
        variant: SnackBarVariant.ERROR
      })
    }
  }


  const validationSchema = Yup.object().shape({});
  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Grid container xs={12}>
          <Grid item xs={10}>
            <Typography variant="h3" style={{ fontWeight: 500, marginTop: '0.6rem' }}>{props.inventoryType === BookTypes.PAPERBACK ? `Update Paperback Inventory` : `Update Harbound Inventory`} </Typography>
          </Grid>
          {publisherDistributors.length > bookDistributors.length && <Grid item xs={2}>
            <Fab
              className={classes.orangeButton}
              onClick={addDidtributorRows}
              variant="extended"
              size="large"
            >
              <AddCircleIcon style={{ marginRight: "10px" }} />
              Add New
            </Fab>
          </Grid>}
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true}>
        {<Grid container xs={12} style={{ marginTop: '1rem' }}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={bookDistributors}
            onSubmit={(data, { setSubmitting }) => {
              updateInventoryFn(bookDistributors);
              setSubmitting(false);
            }}
          >
            {() => (
              <Form style={{ width: "100%" }}>
                <Grid item container xs={12}>
                  {bookDistributors.map((distributor, idx: number) => {
                    return (
                      <Grid item container xs={12} spacing={1} style={{ marginBottom: "1rem" }}>
                        <Grid item xs={4}>
                          <Autocomplete
                            autoSelect
                            options={autocompleteOptions}
                            value={distributor.name}
                            onChange={(e: any, value: any) => {
                              if (typeof value === "string") {
                                let selectedDistributor = publisherDistributors.find(distributor => distributor.name.toLowerCase() === value.toLowerCase())
                                const item = bookDistributors[idx]
                                item.id = selectedDistributor?.id || "";
                                item.name = selectedDistributor?.name || "";
                                const distributorsArr = [...bookDistributors];
                                distributorsArr.splice(idx, 1, item);
                                setBookDistributors(distributorsArr)
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Distributor Name"
                                variant="outlined"
                                placeholder="Search for Distributor via name" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={2} style={{ alignSelf: "center", marginRight: '2rem' }}>
                          <NumericFormat
                            value={props.inventoryType === BookTypes.PAPERBACK ? distributor?.inventory?.paperbackQuantity || 0 : distributor?.inventory?.hardboundQuantity || 0}
                            customInput={TextField}
                            decimalScale={0}
                            allowNegative={false}
                            variant="outlined"
                            label="Stock"
                            required
                            fullWidth
                            onValueChange={(value) => {
                              if (value?.floatValue !== undefined) {
                                const item = bookDistributors[idx]
                                if (props.inventoryType === BookTypes.PAPERBACK) {
                                  item.inventory.paperbackQuantity = value.floatValue || 0
                                } else {
                                  item.inventory.hardboundQuantity = value.floatValue || 0
                                }
                                const distributorsArr = [...bookDistributors];
                                distributorsArr.splice(idx, 1, item);
                                setBookDistributors(distributorsArr)
                              }
                            }}
                          />
                        </Grid>
                        <Grid item container xs={1} style={{ marginLeft: '1rem' }}>
                          <IconButton
                            onClick={() => {
                              const rowsArr = [...bookDistributors];
                              rowsArr.splice(idx, 1);
                              if (!rowsArr.length) {
                                props.handleClose();
                                if (!tempBookDistributors.length) {
                                  setBookDistributors([
                                    {
                                      id: "",
                                      name: "",
                                      publisherId: publisherId,
                                      inventory:
                                      {
                                        bookId: bookId,
                                        publisherId: publisherId,
                                        paperbackQuantity: 0,
                                        hardboundQuantity: 0
                                      }
                                    }
                                  ])
                                } else {
                                  setBookDistributors(tempBookDistributors)
                                }
                              } else {
                                setBookDistributors(rowsArr);
                              }
                            }}
                            aria-label="delete"
                          >
                            <CancelIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container item xs={12}>
                  <Grid container xs={12} justify="flex-end">
                    <Fab
                      size="medium"
                      variant="extended"
                      className="blackBackButton"
                      onClick={() => {
                        props.handleClose()
                        if (!bookDistributors.length) {
                          const rowsArr = []
                          rowsArr.push({
                            id: "",
                            name: "",
                            publisherId: publisherId,
                            inventory:
                            {
                              bookId: bookId,
                              publisherId: publisherId,
                              paperbackQuantity: 0,
                              hardboundQuantity: 0
                            }
                          });
                          setBookDistributors(rowsArr);
                        } else {
                          setBookDistributors(tempBookDistributors)
                        }
                      }}
                    >
                      Cancel
                    </Fab>
                    <Box pl={2}></Box>
                    <Fab size="medium" variant="extended" type="submit">
                      Proceed
                    </Fab>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        }
      </DialogContent>
    </Dialog>
  );
};

export default UpdateInventory;
