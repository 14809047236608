import { logOut, setOrGetDeviceId } from "./utils";

interface IPath {
  url: string;
  operationId: string;
}

const callApi = async (pathObj: IPath, method: string, options?: any) => {
  try {
    const token = localStorage.getItem("token");
    const baseUri = localStorage.getItem("backend");
    const apiPrefix = "/api";
    const path = `${baseUri}${apiPrefix}${pathObj.url}`;
    const data = {
      method, // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // FIXME: TODO: find a work around to send unique device id from browser as same as mobile app is doing
        // currenty browser dosnot provides unique device id, and need to impelent a method combining localstorage and user model
        userAgentDetails: JSON.stringify({ deviceId: setOrGetDeviceId() }),
        operationId: pathObj.operationId,
        "x-header-device-id": setOrGetDeviceId(),
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      ...options
    };
    if (token) {
      data.headers = {
        ...data.headers,
        Authorization: `Bearer ${token}`
      };
    }
    const response = await fetch(path, data);
    const jsonRes = await response.json() // parses JSON response into native JavaScript objects
    const res = { status: response.status, ...jsonRes }

    if (res.status === 401 && res?.desc?.error === "Token expired") {
      logOut()
      const requestedUrl = window.location.pathname + window.location.search;
      window.location.href = `${window.location.origin}/login?requestedUrl=${encodeURIComponent(requestedUrl)}`;
      return
    }
    return res;
  } catch (err) {
    const response = {
      success: false,
      message: err
    };
    return response;
  }
};


//  return a query param string from object of params
export function buildQueryParamFromObject(args: object | any) {
  const objKeys = Object.keys(args)

  const argsAsQueryString: string = objKeys.map(key => {
    const value = args[key]

    if (value !== null || value !== undefined) {
      if (typeof value === "string" && (!value.trim())) {
        return null
      }
      return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
    }
    return null
  }).filter(v => v).join("&")
  return argsAsQueryString
}

export default callApi;
