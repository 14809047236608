import { Fab, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/rachnayeLight.png";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import CircularLoader from "../../common/CircularLoader";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import OrderPriceContainer from "../Dashboard/DigitalBookDetail/OrderPriceContainer";

const useStyles = makeStyles(() => ({
  mainHeader: { fontWeight: 1000, padding: '1.4rem 0.2rem' },
  paymentDiv: { margin: '2rem 0rem' },
  disabledBtn: {
    backgroundColor: "grey!important"
  },
  borderStyle: {
    borderRadius: 4,
    border: "1px solid #9e9694"
  },
  orderCoverContainer: { width: "4.5rem !important", height: "6rem", },
  orderCoverStyle: { objectFit: "fill", height: "100%", width: "100%", borderRadius: 4 },
  fontWeight200: { fontWeight: 200 },
}))

const SeriesOrderSummary: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useSnackBar();

  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<any>();

  // fetch order data
  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const seriesId = params.get("id");
      if (seriesId) {
        setLoading(true)
        createOrder(seriesId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);


  const createOrder = (seriesId: string) => {
    callApi(PATHNAMES.CREATE_SERIES_ORDER(seriesId), HTTP_METHODS.POST).then(res => {
      if (res && res.success && res.data) {
        // fetch order details
        callApi(PATHNAMES.GET_SERIES_ORDER_DETAIL(res.data.id), HTTP_METHODS.GET).then(res2 => {
          if (res2 && res2.success && res2.data) {
            setOrder((v: any) => res2.data)
          } else {
            snackbar({
              variant: SnackBarVariant.ERROR,
              message: res.message || 'error while fetching series order',
            })
            navigate(-1)
          }
          setLoading(false)
        })

      } else {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: res.message || 'error while creating series order',
        })
        navigate(-1)
      }
    })
  }


  const handleProceedForPayment = async () => {
    if (order) {
      const res = await callApi(PATHNAMES.CREATE_CF_ORDER_PORTAL(order.id), HTTP_METHODS.POST);
      if (res && res.success && res.data) {
        navigate(`/portal/payment?pgSessionId=${res.data.paymentSessionId}&orderId=${order.id}&orderType=series`)
      } else {
        snackbar({
          message: "Something went wrong, Please go back and try again",
          variant: SnackBarVariant.ERROR
        })
      }
    }
  }

  if (!order || loading) {
    return <CircularLoader message="Wait, We are fetching order details  :)" />
  }

  return (
    <Grid container xs={12} justify='center' >
      <Grid container xl={5} lg={6} md={8} sm={11} xs={12} >
        <Grid item xs={12}><Typography variant="h2" className={classes.mainHeader}>Your Order Summary</Typography></Grid>

        <Grid item xs={12} className={classes.borderStyle}>
          <Grid container xs={12} style={{ padding: '0.3rem' }}>
            {/* IMAGE  */}
            <Grid item xl={2} lg={2} md={2} sm={3} xs={4} style={{ padding: '0.6rem 0.6rem' }} >
              <Grid container xs={12} className={classes.orderCoverContainer} justify='center' >
                <Paper className={classes.orderCoverContainer} >
                  <img alt="Book" className={classes.orderCoverStyle} src={order.series.coverImage || logo} />
                </Paper>
              </Grid>
            </Grid>
            {/* DESCRIPTION  */}
            <Grid item xl={9} lg={8} md={9} sm={8} xs={8} style={{ padding: "1rem 0.6rem" }}>
              <Typography variant='h3'>{order.series.title}</Typography>
              <div style={{ display: 'flex', marginTop: "1rem" }}>
                <Typography variant="h3" className={classes.fontWeight200}>{" ₹" + order.subTotal}</Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <OrderPriceContainer order={{ totalSellingPrice: order.subTotal, taxAmount: order.taxAmount, totalAmount: order.totalAmount, convenienceFee: order.convenienceFee }} />
        {/* PAYMENT BUTTON  */}
        <Grid item xs={12} className={classes.paymentDiv}>
          <Fab variant="extended"
            onClick={handleProceedForPayment}
            size="small"
            style={{ width: "100%", height: "3rem", }}
          >
            PROCEED TO PAY
          </Fab>
        </Grid>
      </Grid>
    </Grid >
  )
}

export default SeriesOrderSummary;