import React, { useState, useEffect } from "react";
import { unstable_usePrompt } from "react-router-dom";


const useUnsavedChangesWarning = (message = "Are you sure want to discard changes?") => {
  const [isAlert, setIsAlert] = useState<boolean>(false);

  useEffect(() => {
    //Detecting browser closing
    window.onbeforeunload = (() => {
      if (isAlert) {
        return true
      }
    })

    return () => {
      window.onbeforeunload = null;
    };
  }, [isAlert]);
//TODO:
// Previously, <Prompt> from React Router v5 was used for managing navigation prompts. 
// However, in v6, <Prompt> was removed. Instead, we use unstable_usePrompt,
// which is currently considered unstable and may change in future releases.
// This hook provides similar functionality and is expected to be stabilized in future versions.
  const routerPrompt : void | React.ReactNode = unstable_usePrompt({when: isAlert, message: message})

  return [routerPrompt, () => setIsAlert(true), () => setIsAlert(false)] as const;
};

export default useUnsavedChangesWarning;