import {
  Box,
  Card,
  CardActions,
  CardMedia,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  makeStyles,
  Paper,
  TextareaAutosize,
  Typography
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-material-ui";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { updateIamUser } from "../../../reducers/user/reducer";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS, NUMBERS_REGEX, ResourceTypes } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { uploadFileToS3 } from "../../../utils/s3Util";
import ImageUploader from "../../common/ImageUploader";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import PublisherSummaryCard from "./PublisherSummaryCard";


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "1rem",
      padding: "1rem",
      width: "100%",
      marginRight: "1rem"
    },
    addButton: {
      color: "black!important",
      backgroundColor: "#FFD36E!important",
      marginRight: "1rem",
    },
    mainAvatar: {
      backgroundColor: "#FB8F67",
      width: "3.5rem",
      height: "3.5rem",
    },
    cameraBtn: {
      paddingTop: "0.5rem",
      cursor: "pointer"
    },
    textAreaStyle: {
      minWidth: '98%',
      height: "100%",
      resize: 'none',
      fontSize: "1.1rem"
    }

  })
);

const PublisherInfoCard: React.FC = () => {
  const snackbar = useSnackBar();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: IAppState) => state.user);
  const [profileImage, setProfileImage] = useState<string>(user.space?.profileImageUrl || "");
  const [values, setValues] = useState<any>({
    name: user.space?.name || "",
    gstin: user.space?.gstin || "",
    bio: user.space?.bio || "",
    address: user.space?.address?.street || "",
    contactNumber: {
      countryCode: user.space?.contactNumber?.countryCode || "+91",
      phoneNumber: user.space?.contactNumber?.phoneNumber || "",
    }
  });
  const [open, setOpen] = useState<boolean>(false);

  const uploadDocument = async (file: File) => {
    const key = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.IAM_USER_PROFILE,
      isAdmin: false,
      publisherId: user.spaceId
    });
    const data = { ...values }
    if (data) {
      data.profileImageUrl = key;
      setValues(data)
    }
    const res = await callApi(PATHNAMES.GET_SIGNED_URL, HTTP_METHODS.POST, {
      body: JSON.stringify({ data: { key } })
    });
    if (res && res.success && res.data) {
      setProfileImage(res.data)
    }
  }

  const handleSubmit = () => {
    const data: any = {
      name: values.name?.trim(),
      gstin: values.gstin?.trim(),
      bio: values.bio?.trim(),
      address: {
        street: values.address?.trim(),
        city: "",
        state: "",
        country: "",
        pincode: ""
      },
      contactNumber: {
        countryCode: values.contactNumber.countryCode,
        phoneNumber: values.contactNumber.phoneNumber
      }
    }
    if (values.profileImageUrl) {
      data.profileImageUrl = values.profileImageUrl
    }
    callApi(PATHNAMES.UPDATE_PUBLISHER_BASIC_INFO(user.spaceId || ""), HTTP_METHODS.PUT, {
      body: JSON.stringify({ data })
    }).then((res) => {
      if (res && res.success && res.data) {
        callApi(PATHNAMES.GET_IAM_USER(user.id), HTTP_METHODS.GET).then((res) => {
          if (res && res.success && res.data) {
            dispatch(updateIamUser(res.data));
            localStorage.setItem("user", JSON.stringify(res.data));
          }
        })
        snackbar({
          message: "Company profile updated successfully",
          variant: SnackBarVariant.SUCCESS
        })
        setOpen(false)
      }
    })
  }

  if (!user.space) {
    return <CircularProgress />
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //GTS number cannot have white spaces
    //but address and bio can have whitespaces in between
    if (event.target.name === "gstin") {
      setValues({
        ...values,
        [event.target.name]: event.target.value.trim()
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Enter Publisher Name")
      .min(3, "First name is too short - should be 3 chars minimum"),
    address: Yup.lazy(value => {
      if (value) {
        return Yup.string().trim().required()
          .min(3, "Address is too short - should be 3 chars minimum")
          .max(400, "Address name is too long - should be 400 chars maximum")
      }
      return Yup.string().trim().notRequired()
    }),
    contactNumber: Yup.object().shape({
      phoneNumber: Yup.string().trim().required("PhonNumber is required.")
        .matches(NUMBERS_REGEX, "Only Numbers are allowed, no other character please")
        .min(10, 'Must be exactly 10 digits')
        .max(10, 'Must be exactly 10 digits'),
      countryCode: Yup.string().trim().required("Countrycode is required."),
    }).required()
  });

  // disable will be false when values are valid and true
  // const isDisable = () => !validationSchema.isValidSync(values)


  return (
    <Grid container xs={12}>
      <Paper className={classes.root}>
        <Grid container xs={12}>
          <Grid container item xs={8}>
            <Typography variant="h2" style={{ fontWeight: 600 }}>
              Company Profile
            </Typography>
          </Grid>
          {user.role?.name === "superAdmin" && <Grid container xs={4} justify="flex-end">
            <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer" }} color="primary"
              onClick={() => setOpen(true)}>
              Edit
            </Typography>
          </Grid>}
        </Grid>
        <Grid container xs={8}>
          <PublisherSummaryCard publisher={user.space} />
        </Grid>
      </Paper>
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Grid container xs={12}>
            <Typography variant="h3">Edit Publisher Info</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true} style={{ minHeight: '14rem' }}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={values}
            onSubmit={(data, { setSubmitting }) => {
              handleSubmit();
              setSubmitting(false);
            }}
          >
            {() => (
              <Form style={{ width: "100%" }}>
                <Grid container xs={12} spacing={2}>
                  <Grid container xs={3} style={{ padding: "1rem" }}>
                    <Card style={{ height: '13rem', maxWidth: '8rem' }}>
                      {profileImage ?
                        <CardMedia
                          component="img"
                          height="160"
                          alt="Profile Image"
                          title="Profile Image"
                          image={profileImage}
                        /> :
                        <Grid container justify="center" alignContent="center" xs={12} style={{ height: 160 }}>
                          <Typography variant='h3'>Add Image</Typography>
                        </Grid>
                      }
                      <CardActions>
                        <Typography variant='h5' style={{ marginTop: "0.2rem", color: "#808080" }}>Profile Image</Typography>
                        <ImageUploader id={'PrimaryUser'} handleSave={uploadDocument} />
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid container xs={9} spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        component={InputField}
                        fullWidth
                        required
                        variant="outlined"
                        label="Publisher Name"
                        placeholder="Publisher Name"
                        name="name"
                        disabled
                        InputProps={{
                          value: values.name,
                        }}
                        style={{ marginTop: "0.5rem" }}
                      ></Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={InputField}
                        fullWidth
                        variant="outlined"
                        label="GST"
                        placeholder="GST"
                        name="gstin"
                        InputProps={{
                          value: values.gstin,
                          onChange: handleChange
                        }}
                        style={{ marginTop: "0.5rem" }}
                      ></Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextareaAutosize}
                        fullWidth
                        className={classes.textAreaStyle}
                        variant="outlined"
                        label="Bio"
                        placeholder="Add Company Bio"
                        name="bio"
                        value={values.bio}
                        onChange={(e: any) => {
                          setValues({
                            ...values,
                            bio: e.target.value
                          })
                        }}
                        style={{ marginTop: "0.5rem", width: '100%', height: "6rem" }}
                      ></Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={InputField}
                        fullWidth
                        variant="outlined"
                        label="Address"
                        placeholder="Address"
                        name="address"
                        InputProps={{
                          value: values.address,
                          onChange: handleChange
                        }}
                        style={{ marginTop: "0.5rem" }}
                      ></Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={InputField}
                        fullWidth
                        required
                        variant="outlined"
                        label="Phone Number"
                        placeholder="Phone Number"
                        name="contactNumber.phoneNumber"
                        inputProps={{ maxLength: 10 }}
                        InputProps={{
                          value: values.contactNumber.phoneNumber,
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setValues({
                              ...values,
                              contactNumber: {
                                ...values.contactNumber,
                                phoneNumber: e.target.value
                              }
                            })
                          }
                        }}
                        style={{ marginTop: "0.5rem" }}
                      ></Field>
                    </Grid>
                  </Grid>
                </Grid>
                <DialogActions >
                  <Grid container item xs={12} justify="flex-end" style={{ marginBottom: '0rem' }}>
                    <Fab
                      size="medium"
                      variant="extended"
                      className="blackBackButton"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </Fab>
                    <Box pl={2}></Box>
                    <Fab
                      size="medium"
                      variant="extended"
                      type='submit'
                    >
                      Proceed
                    </Fab>
                  </Grid>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default PublisherInfoCard;