import {
  Card,
  CardActions,
  CardMedia,
  Checkbox,
  Fab,
  FormControlLabel,
  Grid,
  InputAdornment, Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-material-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { IBookCategory, IBookForOffer } from "../../../reducers/books/types";
import { allSelectionIds, allSelectionLable, getArrOf, OfferDiscountType, OfferedByUser } from "../../../reducers/offers/constants";
import { IOffer } from "../../../reducers/offers/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { BOOK_CATEGORIES, BOOKS_FOR_OFFERS, DEFAULT_COUNTRY_CODE, HTTP_METHODS, ResourceTypes } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { uploadFileToS3 } from "../../../utils/s3Util";
import { ErrorHandler } from "../../../utils/utils";
import CircularLoader from "../../common/CircularLoader";
import FlatPickerBar from "../../common/FlatPickerBar";
import ImageUploader from "../../common/ImageUploader";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";

const autoCompletefilter = createFilterOptions<any>();

interface IGetBookArgs {
  publishers: string[]
  categories: string[]
}
interface IBook {
  id: string
  title: string
}

interface IPublisher {
  id: string
  name: string
}

const OfferType = {
  OFFER: "offer",
  COUPON: "coupon",
  GLOBAL_OFFER: "globalOffer",
}

const pathParams = {
  OFFER: "update-offer",
  COUPON: "update-coupon",
  GLOBAL_OFFER: "update-global-offer",
}

const Manage = {
  ALL: "all",
  ONLY_BOOK: "onlyBook",
  ONLY_PUBLISHER_CATEGORY: 'onlyPublisherAndCategory'
}

const UpdateOffer: React.FC = () => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const location = useLocation();
  const userState = useSelector((state: IAppState) => state.user);
  const [loading, setLoading] = useState<boolean>(true);

  const [publishers, setPublishers] = useState<IPublisher[]>([]); // api 
  const [publishersAC, setPublishersAC] = useState<IPublisher[]>([]); // autocomplete
  const [selectedPublishers, setSelectedPublishers] = useState<IPublisher[] | null>(null); // user interaction

  const [categories] = useState<IBookCategory[]>(BOOK_CATEGORIES);
  const [categoriesAC, setCategoriesAC] = useState<IBookCategory[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<IBookCategory[] | null>(null);

  const [books, setBooks] = useState<IBook[]>([]);
  const [booksAC, setBooksAC] = useState<IBook[]>([]);
  const [selectedBooks, setSelectedBooks] = useState<IBook[]>([]);

  const [bookTypes] = useState<IBookForOffer[]>(BOOKS_FOR_OFFERS);
  const [bookTypesAC, setBookTypesAC] = useState<IBookForOffer[]>([]);
  const [selectedBookTypes, setSelectedBookTypes] = useState<IBookForOffer[]>([]);

  const [thumbnail, setThumbnail] = useState<string>("")
  const [offerType, setOfferType] = useState<string>('');
  const [offerTypeTitle, setOfferTypeTitle] = useState<string>("");

  const [renderAfterDataFetch, setRenderAfterDataFetch] = useState<boolean>(false)

  const [values, setValues] = useState<IOffer>({
    id: "",
    name: "",
    code: "",
    image: "",
    description: "",
    termsAndConditions: "",
    maxCount: undefined,
    offeredBy: OfferedByUser.ADMIN,
    startDate: "",
    endDate: "",
    isLifeTime: false,
    type: offerType,
    discount: {
      type: OfferDiscountType.PERCENTAGE,
      value: undefined,
      platformSplit: undefined,
      publisherOrWriterSplit: undefined,
      shippingFree: false,
    },
    applicability: {
      orderValue: undefined,
      applyWhenAllIncluded: false,
      minimumBuy: undefined,
      categories: [],
      publishers: [],
      books: [],
      bookIds: [],
      seriesIds: [],
      writers: []
    },
    isHiddenOffer: false,
    deductPublisherShareAlso: false,
    thirdPartyOffer: false,
    thirdPartyInfo: {
      name: "",
      contactNumber: {
        phoneNumber: "",
        countryCode: DEFAULT_COUNTRY_CODE
      }
    },
    createdAt: "",
    updatedAt: ""
  })

  // know the page to render based on url path -> pages update-[offer/coupon/global offer]
  useEffect(() => {
    if (location) {
      const path = location.pathname;
      const segments = path.split('/').filter(segment => segment !== '');
      const lastSegment = segments[segments?.length - 1];
      switch (lastSegment) {
        case pathParams.OFFER:
          setOfferType(OfferType.OFFER)
          setOfferTypeTitle('Offer')
          break;
        case pathParams.COUPON:
          setOfferType(OfferType.COUPON)
          setOfferTypeTitle('Coupon')
          break;
        case pathParams.GLOBAL_OFFER:
          setOfferType(OfferType.GLOBAL_OFFER)
          setOfferTypeTitle('Global Offer')
          break;
        default:
          break;
      }
    }
  }, [location])

  // DOESN'T DEPENED ON API RESPONSE
  //  AUTOCOMPLETE LIST DATA START
  // GET ALL CATGORIES
  useEffect(() => {
    const categorisACinput = [{ value: allSelectionIds.CATEGORIES, label: allSelectionLable.CATEGORIES }, ...categories]
    setCategoriesAC(categorisACinput);

    const bookTypesACinput = [{ value: allSelectionIds.BOOK_TYPES, label: allSelectionLable.BOOK_TYPES }, ...bookTypes]
    setBookTypesAC(bookTypesACinput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // GET ALL PUBLISHERS
  useEffect(() => {
    if (userState && userState.id && userState.spaceId) {
      callApi(PATHNAMES.ALL_ACTIVE_PUBLISHERS(), HTTP_METHODS.GET).then((publisherResponse) => {
        if (publisherResponse && publisherResponse.success && publisherResponse.data) {
          setPublishers(publisherResponse.data);
          let publisherACinput = publisherResponse.data
          if (publisherResponse?.data?.length) {
            publisherACinput = [{ id: allSelectionIds.PUBLISHERS, name: allSelectionLable.PUBLISHERS }, ...publisherResponse.data]
          }
          setPublishersAC(publisherACinput);
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.id])

  //  AUTOCOMPLETE LIST DATA END


  //GET OFFER/COUPON/GLOABL_OFFER
  useEffect(() => {
    if (location && offerType) {
      const params = new URLSearchParams(location.search);
      const id = params.get("id");
      if (id && userState?.spaceId) {
        callApi(PATHNAMES.GET_OFFER(id), HTTP_METHODS.GET).then(res => {
          if (res && res.success) {
            if (offerType !== res.data.type) {
              snackbar({
                message: "Invalid Offer/Coupon",
                variant: SnackBarVariant.ERROR
              })
              return
            }
            setValues(v => ({ ...values, ...res.data }))
            if (res.data.image) { setThumbnail(res.data.image) }
            // set selected category and publisher by comparing cp values 
            // to fetch books based on publisher and category.
            manageAvailability(res.data, Manage.ONLY_PUBLISHER_CATEGORY)
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerType])




  useEffect(() => {
    if (publishers !== null && values.id && categories !== null) {
      if (!renderAfterDataFetch) {
        // first render will happen only when value changes to true.
        // not after that, so dont update state after that to prevent unnecessory renders
        setRenderAfterDataFetch(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishers, values.id, categories])


  useEffect(() => {
    if (renderAfterDataFetch) {
      manageAvailability(values, Manage.ALL)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderAfterDataFetch])


  useEffect(() => {
    manageAvailability(values, Manage.ONLY_BOOK)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [books])



  // GET ALL BOOKS
  useEffect(() => {
    let publisherParam: string[] = getValueArrFor(getArrOf.PUBLISHERS);
    let categoryParam: string[] = getValueArrFor(getArrOf.CATEGORIES);
    getBooks({ publishers: publisherParam, categories: categoryParam })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublishers, selectedCategories])


  // GET BOOKS API CALL 
  const getBooks = (args: IGetBookArgs) => {
    callApi(PATHNAMES.GET_PUBLISHER_CATEGORY_BOOKS(), HTTP_METHODS.POST, {
      body: JSON.stringify({ data: { publishers: args.publishers, categories: args.categories } })
    }).then((booksResponse) => {
      if (booksResponse && booksResponse.success && booksResponse.data) {
        setBooks(booksResponse.data);
        let booksACinput = booksResponse.data
        if (booksResponse?.data?.length) {
          booksACinput = [{ id: allSelectionIds.BOOKS, title: allSelectionLable.BOOKS }, ...booksResponse.data]
        }
        setBooksAC(booksACinput);
      }
    })
  }

  // STATE MANAGEMENT FOR AUTOCOMPLETE -- start

  // fill pre selected availability data
  const manageAvailability = (coupon: IOffer, type: string) => {
    if (type === Manage.ONLY_PUBLISHER_CATEGORY) {
      manageSelectPublishers(coupon)
      manageSelectCategories(coupon)
      return
    }
    if (type === Manage.ONLY_BOOK) {
      manageSelectBooks(coupon)
      return
    }
    manageSelectPublishers(coupon)
    manageSelectCategories(coupon)
    manageSelectBooks(coupon)
    manageSelectedBookTypes(coupon)
    return
  }

  const manageSelectPublishers = (coupon: IOffer) => {
    // if all publishers
    if (!coupon?.applicability?.publishers?.length) {
      setSelectedPublishers([{ id: allSelectionIds.PUBLISHERS, name: allSelectionLable.PUBLISHERS }])
      return
    }
    if (publishers?.length) {
      // if selected publishers, return {name , id} from id
      const formattedArr = coupon.applicability.publishers.map((id: string) =>
        publishers.find(publisher => publisher.id === id) as IPublisher
      )
      setSelectedPublishers(formattedArr)
    }
  }

  const manageSelectCategories = (coupon: IOffer) => {
    // if all categories
    if (!coupon?.applicability?.categories?.length) {
      setSelectedCategories([{ value: allSelectionIds.CATEGORIES, label: allSelectionLable.CATEGORIES }])
      return
    }
    // if selected categories, return {value , label } as IBookCategory from value
    const formattedArr = coupon.applicability.categories.map((value: string) =>
      categories.find(category => category.value === value) as IBookCategory
    )
    setSelectedCategories(formattedArr)
  }


  const manageSelectBooks = (coupon: IOffer) => {
    // if all books
    if (!coupon?.applicability?.bookIds?.length) {
      setSelectedBooks([{ id: allSelectionIds.BOOKS, title: allSelectionLable.BOOKS }])
      return
    }
    // if selected books, return {title , id} as IBook from id 
    const formattedArr = coupon.applicability.bookIds.map((id: string) => books.find(book => book.id === id) as IBook).filter(book => book)
    setSelectedBooks(formattedArr)
  }

  const manageSelectedBookTypes = (coupon: IOffer) => {
    // if all bookTypes
    if (!coupon?.applicability?.books?.length || !coupon?.applicability?.books[0]?.bookTypes?.length) {
      setSelectedBookTypes([{ value: allSelectionIds.BOOK_TYPES, label: allSelectionLable.BOOK_TYPES }])
      return
    }
    // if selected bookTypes, return {value , label } as IBookCategory from value
    const formattedArr = coupon.applicability?.books[0]?.bookTypes?.map((value: string) =>
      bookTypes.find(bookType => bookType.value === value) as IBookForOffer
    )
    setSelectedBookTypes(formattedArr || [])
  }


  // STATE MANAGEMENT FOR AUTOCOMPLETE -- end


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  // UPLOAD COUPON IMAGE
  const uploadDocument = async (file: File) => {
    const key = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.OFFER_IMAGE,
      isAdmin: true
    });
    const data = { ...values }
    if (data) {
      data.image = key;
      setValues(data)
    }
    const res = await callApi(PATHNAMES.GET_SIGNED_URL, HTTP_METHODS.POST, {
      body: JSON.stringify({ data: { key } })
    });
    if (res && res.success && res.data) {
      setThumbnail(res.data)
    }
  }

  // handle update coupon (submit)
  const handleSubmit = async () => {
    try {
      await validateValues()
      const data = await formatDataforApiCall()
      const res = await callApi(PATHNAMES.UPDATE_OFFER(data.id), HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      })
      if (res && res.success) {
        let link = "/"
        const message = `${offerTypeTitle} updated successfully`

        switch (offerType) {
          case OfferType.OFFER:
            link = "/offers"
            break;
          case OfferType.COUPON:
            link = "/coupons"
            break;
          case OfferType.GLOBAL_OFFER:
            link = "/global-offers"
            break;
          default:
            break;
        }
        navigate(link)
        snackbar({
          message: message,
          variant: SnackBarVariant.SUCCESS
        })
      } else {
        throw new ErrorHandler(res.message)
      }
    } catch (error: any) {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
    }
  }

  const formatDataforApiCall = async () => {
    const data = {
      ...values,
      maxCount: values.maxCount ? values.maxCount : -1,
      applicability: {
        ...values.applicability,
        orderValue: values.applicability.orderValue ? values.applicability.orderValue : -1,
        categories: getValueArrFor(getArrOf.CATEGORIES),
        publishers: getValueArrFor(getArrOf.PUBLISHERS),
        bookIds: getValueArrFor(getArrOf.BOOKS),
      }
    }

    if (selectedBookTypes?.length) {
      const bookTypeRelatedData = {
        sale: "purchased",
        bookTypes: getValueArrFor(getArrOf.BOOKS_TYPES),
      }
      // store only 1 type for now, since UI is simple.
      data.applicability.books[0] = bookTypeRelatedData
    }

    if (data?.image?.includes("https")) {
      delete data.image
    }
    if (!data.thirdPartyOffer) {
      delete data.thirdPartyInfo
    }
    return data
  }

  // return array of ids
  const getValueArrFor = (arrType: string) => {
    let arr: any = []
    switch (arrType) {
      case getArrOf.PUBLISHERS:
        const allPubs = selectedPublishers?.find(publisher => publisher.id === allSelectionIds.PUBLISHERS)
        if (!allPubs) {
          arr = selectedPublishers?.map(publisher => publisher.id)
        }
        break;
      case getArrOf.CATEGORIES:
        const allcats = selectedCategories?.find(category => category.value === allSelectionIds.CATEGORIES)
        if (!allcats) {
          arr = selectedCategories?.map(catgory => catgory.value)
        }
        break;
      case getArrOf.BOOKS:
        const allBooks = selectedBooks.find(book => book.id === allSelectionIds.BOOKS)
        if (!allBooks) {
          arr = selectedBooks.map(book => book.id)
        }
        break;
      case getArrOf.BOOKS_TYPES:
        const allBookTypes = selectedBookTypes.find(bookType => bookType.value === allSelectionIds.BOOK_TYPES)
        if (!allBookTypes) {
          arr = selectedBookTypes.map(bookType => bookType.value)
        }
        break;
      default:
        throw new ErrorHandler("Invalid type provided")
    }
    return arr
  }

  // VALIDATION - START

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().min(3, `${offerTypeTitle} name is too short - give some meaningful name.`)
      .required(`${offerTypeTitle} name is required.`),
    code: Yup.string().trim().min(2, `${offerTypeTitle} code is too short - give some meaningful code.`)
      .required(`${offerTypeTitle} code is required.`),
  });

  const validateValues = async () => {
    if (!values.name) {
      throw new ErrorHandler(`${offerTypeTitle} name is empty`)
    }
    if (!values.code) {
      throw new ErrorHandler(`${offerTypeTitle} percentage is empty`)
    }
    if (!values.startDate) {
      throw new ErrorHandler(`${offerTypeTitle} start date is empty`)
    }
    if (!values.isLifeTime) {
      if (!values.endDate) {
        throw new ErrorHandler(`${offerTypeTitle} end date is empty`)
      }
    }
    if (values.discount.type === OfferDiscountType.PERCENTAGE) {
      // CHECKME: hinding minimum value check of 15% to allow admin 100% value coupon , for some time.
      if (values.discount.value && values.discount.value > 100) {
        throw new ErrorHandler("Discount value maximum value is 100%")
      }
    }
    if (values.discount.value !== ((values?.discount?.platformSplit || 0) + (values?.discount?.publisherOrWriterSplit || 0))) {
      throw new ErrorHandler("Discount split both side is wrong, not matching with value")
    }
    if (!selectedPublishers?.length) {
      throw new ErrorHandler("Please select Availibility for Publishers")
    }
    if (values.thirdPartyOffer) {
      if (!values.thirdPartyInfo?.name) {
        throw new ErrorHandler("Please add third party client's name")
      }
      if (!values.thirdPartyInfo?.contactNumber?.phoneNumber) {
        throw new ErrorHandler("Please add third party client's phone number")
      }
    }
  }
  // VALIDATION - END

  if (loading) {
    return <CircularLoader message={`Wait for a bit, ${offerTypeTitle} data is loading....`} />
  }

  return (
    <Grid container>
      <Grid container xs={12}>
        <Typography variant="h2" style={{ fontWeight: 600 }}>
          {`Update ${offerTypeTitle}`}
        </Typography>
      </Grid>
      <Grid container xs={12} style={{ marginTop: "2rem" }}>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={values}
          onSubmit={(data, { setSubmitting }) => {
            handleSubmit();
            setSubmitting(false);
          }}
        >
          {() => (
            <Form style={{ width: "100%" }}>
              {/* NAME AND DATE ROW  */}
              <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} >
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    component={InputField}
                    fullWidth
                    required
                    label={`${offerTypeTitle} Name`}
                    placeholder={`${offerTypeTitle} Name`}
                    name="name"
                    InputProps={{
                      value: values.name,
                      onChange: handleChange
                    }}
                  ></Field>
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                  <Field
                    component={InputField}
                    placeholder={`${offerTypeTitle} Code`}
                    label={`${offerTypeTitle} Code`}
                    name="code"
                    fullWidth
                    required
                    InputProps={{
                      value: values.code,
                      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          code: event.target.value.trim().toUpperCase()
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <FlatPickerBar
                    handleDateChange={(value: Date) => {
                      setValues({
                        ...values,
                        startDate: moment(value).startOf("day").toISOString()
                      })
                      if (moment(value) > moment(values.endDate)) {
                        setValues({
                          ...values,
                          endDate: moment(value).endOf("day").toISOString()
                        })
                      }
                    }}
                    label={`${offerTypeTitle} Start Date`}
                    identifier="startDate"
                    name="startDate"
                    required
                    minDate={moment(values.startDate).startOf("day").toDate()}
                    value={values.startDate}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  {!values.isLifeTime &&
                    <FlatPickerBar
                      handleDateChange={(value: Date) => {
                        setValues({
                          ...values,
                          endDate: moment(value).endOf("day").toISOString()
                        })
                      }}
                      label={`${offerTypeTitle} End Date`}
                      identifier="endDate"
                      name="endDate"
                      value={values.endDate}
                      minDate={moment(values.startDate).toDate()}
                    />}
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Field
                    as={FormControlLabel}
                    type="checkbox"
                    style={{ margin: "0 1 0 0rem" }}
                    name="isLifeTime"
                    control={
                      <Checkbox
                        checked={values.isLifeTime}
                        onChange={(e: any) => {
                          setValues({ ...values, isLifeTime: e.target.checked })
                        }}
                      />}
                    label={`Do you want to create this ${offerTypeTitle} as a Lifetime ${offerTypeTitle}?`}
                  />
                  {offerType === OfferType.COUPON &&
                    <>
                      <Field
                        as={FormControlLabel}
                        type="checkbox"
                        style={{ margin: "0 1 0 0rem" }}
                        name="isHiddenOffer"
                        control={
                          <Checkbox
                            checked={values.isHiddenOffer}
                            onChange={(e: any) => {
                              setValues({ ...values, isHiddenOffer: e.target.checked })
                            }}
                          />}
                        label={`Do you want to hide this coupon from users?`}
                      />
                      <Field
                        as={FormControlLabel}
                        style={{ margin: "0 1 0 0rem" }}
                        type="checkbox"
                        name="thirdPartyOffer"
                        control={
                          <Checkbox
                            checked={values.thirdPartyOffer}
                            onChange={(e: any) => {
                              setValues({ ...values, thirdPartyOffer: e.target.checked })
                            }}
                          />}
                        label="Do you want to create this coupon for third party client?"
                      />
                    </>
                  }
                </Grid>
              </Grid>

              {/* THIRD PARTY CLIENTS NAME */}
              {values.thirdPartyOffer &&
                <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} alignItems='center' style={{ marginTop: '1.3rem' }}>
                  <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                    <Typography variant='h3'>Client's Info</Typography>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                    <Field
                      component={InputField}
                      fullWidth
                      required
                      label="Client's Name"
                      placeholder="Client's Name"
                      name="thirdPartyInfo.name"
                      InputProps={{
                        value: values.thirdPartyInfo?.name,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setValues({
                            ...values,
                            thirdPartyInfo: {
                              ...values.thirdPartyInfo,
                              name: e.target.value
                            }
                          })
                        }
                      }}
                    ></Field>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                    <Field
                      required
                      variant="outlined"
                      label="Phone Number"
                      placeholder="Client's Phone Number"
                      name="thirdPartyInfo.phoneNumber"
                      component={NumericFormat}
                      customInput={TextField}
                      allowNegative={false}
                      value={values?.thirdPartyInfo?.contactNumber?.phoneNumber || ""}
                      fullWidth
                      decimalScale={0}
                      onValueChange={(eventValues: NumberFormatValues) => {
                        setValues({
                          ...values,
                          thirdPartyInfo: {
                            ...values.thirdPartyInfo,
                            contactNumber: {
                              countryCode: values?.thirdPartyInfo?.contactNumber?.countryCode || DEFAULT_COUNTRY_CODE,
                              phoneNumber: eventValues.formattedValue

                            }
                          }
                        })

                      }}
                      inputProps={{ maxLength: 10 }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{values.thirdPartyInfo?.contactNumber?.countryCode}</InputAdornment>,

                      }}
                    ></Field>
                  </Grid>
                </Grid>}

              {/* TYPE AND VALUE ROW  */}
              <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} style={{ marginTop: '0.6rem' }}>
                {/* IAMGE  */}
                {offerType !== OfferType.OFFER &&
                  <Grid item xl={4} lg={5} md={6} sm={12} xs={12} style={{ paddingTop: "0.6rem" }}>
                    <Card >
                      {thumbnail ?
                        <CardMedia
                          component="img"
                          alt={`${offerTypeTitle} Cover`}
                          title={`${offerTypeTitle} Cover`}
                          image={thumbnail}
                        /> :
                        <Grid container justify="center" alignContent="center" xs={12} style={{ height: 160 }}>
                          <Typography variant='h3'>Add Cover Image (700 x 700)</Typography>
                        </Grid>
                      }
                      <CardActions style={{ backgroundColor: "#fcf5e8" }}>
                        <Typography style={{ marginTop: "0.2rem", color: "#808080" }}>{`${offerTypeTitle} Cover`}</Typography>
                        <ImageUploader id={`${offerTypeTitle}Image`} handleSave={uploadDocument} />
                      </CardActions>
                    </Card>
                  </Grid>
                }

                {/* DISCOUNT AND VALUE  */}
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  {/* SELECT DISCOUNT TYPE  */}
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ maxWidth: '35rem' }}>
                    <Field component={RadioGroup} name="discount.type"
                      value={values.discount.type}
                      onChange={(event: any) => {
                        setValues({
                          ...values,
                          discount: {
                            ...values.discount,
                            type: event.target.value
                          }
                        })
                      }}
                    >
                      <Grid container xl={12} lg={12} md={12} sm={12} xs={12} >
                        <Grid item xl={12} lg={6} md={12} sm={12} xs={12}>
                          <Typography variant='h4' style={{ paddingTop: '0.6rem' }}>You want to give Discount In :</Typography>
                        </Grid>
                        <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                          <FormControlLabel
                            value={OfferDiscountType.PERCENTAGE}
                            control={<Radio />}
                            label="Percentage"
                          />
                        </Grid>
                        <Grid item xl={2} lg={3} md={6} sm={6} xs={12} >
                          <FormControlLabel
                            value={OfferDiscountType.AMOUNT}
                            control={<Radio />}
                            label="Amount"
                          />
                        </Grid>
                      </Grid>
                    </Field>
                  </Grid>
                  {/* DISCOUNT VALUE MAX COUNT   */}
                  <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} >
                    <Grid item xl={4} lg={6} md={6} sm={6} xs={8}>
                      <Field
                        component={NumericFormat}
                        customInput={TextField}
                        name="discount.value"
                        label="Discount value"
                        placeholder="Discount Value"
                        variant="outlined"
                        allowNegative={false}
                        value={values.discount.value}
                        fullWidth
                        required
                        max={100}
                        decimalScale={0}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{values.discount.type === OfferDiscountType.PERCENTAGE ? "%" : "₹"}</InputAdornment>,

                        }}
                        inputProps={{ maxLength: values.discount.type === OfferDiscountType.PERCENTAGE ? 3 : 5 }}
                        onValueChange={(eventValues: NumberFormatValues) => {
                          setValues({
                            ...values,
                            discount: {
                              ...values.discount,
                              value: eventValues.floatValue
                            }
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xl={4} lg={6} md={6} sm={6} xs={8}>
                      <Field
                        component={NumericFormat}
                        customInput={TextField}
                        name="discount.publisherOrWriterSplit"
                        label="publisher/Writer Split"
                        placeholder="Publisher/Writer Split"
                        variant="outlined"
                        allowNegative={false}
                        value={values.discount.publisherOrWriterSplit}
                        fullWidth
                        required
                        max={100}
                        decimalScale={2}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{values.discount.type === OfferDiscountType.PERCENTAGE ? "%" : "₹"}</InputAdornment>,
                        }}
                        inputProps={{ maxLength: values.discount.type === OfferDiscountType.PERCENTAGE ? 3 : 5 }}
                        onValueChange={(eventValues: NumberFormatValues) => {
                          setValues({
                            ...values,
                            discount: {
                              ...values.discount,
                              publisherOrWriterSplit: eventValues.floatValue
                            }
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xl={4} lg={6} md={6} sm={6} xs={8}>
                      <Field
                        component={NumericFormat}
                        customInput={TextField}
                        name="discount.platformSplit"
                        label="platform Split"
                        placeholder="Platform Split"
                        variant="outlined"
                        allowNegative={false}
                        value={values.discount.platformSplit}
                        fullWidth
                        required
                        max={100}
                        decimalScale={2}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{values.discount.type === OfferDiscountType.PERCENTAGE ? "%" : "₹"}</InputAdornment>,
                        }}
                        inputProps={{ maxLength: values.discount.type === OfferDiscountType.PERCENTAGE ? 3 : 5 }}
                        onValueChange={(eventValues: NumberFormatValues) => {
                          setValues({
                            ...values,
                            discount: {
                              ...values.discount,
                              platformSplit: eventValues.floatValue
                            }
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xl={4} lg={6} md={6} sm={6} xs={8}>
                      <Field
                        component={NumericFormat}
                        customInput={TextField}
                        name="maxCount"
                        label="Max use"
                        placeholder="unlimited times"
                        variant="outlined"
                        allowNegative={false}
                        value={values.maxCount}
                        fullWidth
                        decimalScale={0}
                        onValueChange={(eventValues: NumberFormatValues) => {
                          setValues({
                            ...values,
                            maxCount: eventValues.floatValue
                          })

                        }}
                      />
                    </Grid>
                    {offerType !== OfferType.OFFER &&
                      <Grid item xl={4} lg={6} md={6} sm={6} xs={8}>
                        <Field
                          component={NumericFormat}
                          customInput={TextField}
                          name="applicability.orderValue"
                          label="Order Value"
                          placeholder="No limit"
                          variant="outlined"
                          allowNegative={false}
                          value={values.applicability.orderValue}
                          fullWidth
                          decimalScale={0}
                          onValueChange={(eventValues: NumberFormatValues) => {
                            setValues({
                              ...values,
                              applicability: {
                                ...values.applicability,
                                orderValue: eventValues.floatValue
                              }
                            })
                          }}
                        />
                      </Grid>
                    }
                    <Grid item xl={4} lg={6} md={6} sm={6} xs={8}>
                      <Field
                        as={FormControlLabel}
                        type="checkbox"
                        style={{ margin: "0 1 0 0rem" }}
                        name="shippingFree"
                        control={
                          <Checkbox
                            checked={values.discount.shippingFree}
                            onChange={(e: any) => {
                              setValues({
                                ...values,
                                discount: {
                                  ...values.discount,
                                  shippingFree: e.target.checked
                                }
                              })
                            }}
                          />}
                        label={`Give Free Shipping`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Description and term and condition  */}
              <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} style={{ marginTop: '1.3rem' }}>
                <Grid item xl={5} lg={6} md={6} sm={12} xs={12}>
                  <Field
                    component={TextareaAutosize}
                    fullWidth
                    placeholder={`Add some description for ${offerTypeTitle} ...`}
                    name="description"
                    style={{ width: '100%', height: "6rem" }}
                    value={values.description}
                    onChange={(e: any) => {
                      setValues({
                        ...values,
                        description: e.target.value
                      })
                    }}
                  ></Field>
                </Grid>
                <Grid item xl={5} lg={6} md={6} sm={12} xs={12}>
                  <Field
                    component={TextareaAutosize}
                    fullWidth
                    placeholder={`Add some Terms and conditions for ${offerTypeTitle} ...`}
                    name="termsAndConditions"
                    style={{ width: '100%', height: "6rem" }}
                    value={values.termsAndConditions}
                    onChange={(e: any) => {
                      setValues({
                        ...values,
                        termsAndConditions: e.target.value
                      })
                    }}
                  ></Field>
                </Grid>
              </Grid>

              {/* AVAILABILITY */}
              <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} style={{ marginTop: '1.3rem' }}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant='h3'>Select Availability</Typography>
                </Grid>
                <Grid container xs={12} style={{ marginLeft: "0.09rem" }} spacing={2}>
                  <Grid item xl={4} lg={6} md={6} sm={6} xs={8}>
                    <Field
                      component={NumericFormat}
                      customInput={TextField}
                      name="applicability.minimumBuy"
                      label="Minmum Book Order"
                      placeholder="Minimum Book order"
                      variant="outlined"
                      allowNegative={false}
                      value={values.applicability.minimumBuy}
                      fullWidth
                      decimalScale={0}
                      onValueChange={(eventValues: NumberFormatValues) => {
                        setValues({
                          ...values,
                          applicability: {
                            ...values.applicability,
                            minimumBuy: eventValues.floatValue || 1
                          }
                        })
                      }}
                    />
                  </Grid>
                  <Grid item xl={5} lg={6} md={6} sm={12} xs={12}>
                    <Field
                      as={FormControlLabel}
                      type="checkbox"
                      style={{ margin: "0 1 0 0rem" }}
                      name="applyWhenAllIncluded"
                      control={
                        <Checkbox
                          checked={values.applicability.applyWhenAllIncluded}
                          onChange={(e: any) => {
                            setValues({
                              ...values,
                              applicability: {
                                ...values.applicability,
                                applyWhenAllIncluded: e.target.checked
                              }
                            })
                          }}
                        />}
                      label={`Do you want to include all books mentioned in availability to apply this ${offerTypeTitle}`}
                    />
                  </Grid>
                </Grid>


                {/* PUBLISHER  */}
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  {selectedPublishers === null ?
                    <CircularLoader style={{ margin: "1rem 0rem" }} />
                    :
                    <Field
                      component={Autocomplete}
                      multiple
                      name="publishers"
                      disablePortal={true}
                      value={selectedPublishers}
                      filterSelectedOptions
                      // noOptionsText={!publishers.length ? 'No publishers found, sorry !' : null}
                      disableCloseOnSelect
                      options={publishersAC}
                      getOptionLabel={(item: IPublisher) => item.name}
                      renderOption={(option: IPublisher) => (
                        <Typography style={{ fontSize: "1rem" }}>{option.name}</Typography>
                      )}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          placeholder="Search and select publishers"
                          label="Publishers"
                        />)
                      }
                      filterOptions={(publishersAC: IPublisher[], filterState: any) => {
                        // drop down optins available to user while searching or selecting.
                        const filteredList = autoCompletefilter(publishersAC, filterState)
                        const isAllIncluded = filteredList.find(publisher => publisher.id === allSelectionIds.PUBLISHERS)
                        // if all publisher option is available in drop down then return same list
                        if (isAllIncluded) {
                          return filteredList
                        }
                        // else user has selected all publisher , it is present in chips , so don't show remaing publisher in drop down
                        return []
                      }}
                      onChange={(event: any, publisherValues: IPublisher[]) => {
                        setSelectedPublishers(publisherValues) //user selected options as chip
                      }}
                    />
                  }
                </Grid>
                {/* CATEGORY  */}
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  {selectedCategories === null ?
                    <CircularLoader style={{ margin: "1rem 0rem" }} />
                    :
                    <Field
                      component={Autocomplete}
                      value={selectedCategories}
                      multiple
                      filterSelectedOptions
                      disablePortal={true}
                      name="categories"
                      disableCloseOnSelect
                      noOptionsText={!categories?.length ? 'No categories found, sorry !' : "You have selecetd all category"}
                      options={categoriesAC}
                      getOptionLabel={(item: IBookCategory) => item.label}
                      renderOption={(option: IBookCategory) => (
                        <Typography style={{ fontSize: "1rem" }}>{option.label}</Typography>
                      )}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          placeholder="Search and select categories"
                          label="Categories"
                        />)
                      }
                      filterOptions={(categoriesAC: IBookCategory[], filterState: any) => {
                        // drop down options available to user while searching or selecting.
                        const filteredList = autoCompletefilter(categoriesAC, filterState)
                        const isAllIncluded = filteredList.find(category => category.value === allSelectionIds.CATEGORIES)
                        // if all categories option is available in drop down then return same list
                        if (isAllIncluded) {
                          return filteredList
                        }
                        // else user has selected all categories , it is present in chips , so don't show remaing categories in drop down
                        return []
                      }}

                      onChange={(event: any, categoryValues: IBookCategory[]) => {
                        setSelectedCategories(categoryValues) //user selected options as chip
                      }}
                    />
                  }
                </Grid>
                {/* BOOKS */}
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  <Field
                    component={Autocomplete}
                    multiple
                    name="books"
                    value={selectedBooks}
                    disablePortal={true}
                    required
                    filterSelectedOptions
                    noOptionsText={!books?.length ? 'No books found, sorry !' : "You have selecetd all books"}
                    disableCloseOnSelect
                    options={booksAC}
                    getOptionLabel={(item: IBook) => item?.title}
                    renderOption={(option: IBook) => (
                      <Typography style={{ fontSize: "1rem" }}>{option.title}</Typography>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Search and select books"
                        label="Books"
                      />)
                    }
                    filterOptions={(booksAC: IBook[], filterState: any) => {
                      // drop down optins available to user while searching or selecting.
                      const filteredList = autoCompletefilter(booksAC, filterState)
                      const isAllIncluded = filteredList.find(book => book.id === allSelectionIds.BOOKS)
                      // if all books option is available in drop down then return same list
                      if (isAllIncluded) {
                        return filteredList
                      }
                      // else user has selected all books , it is present in chips , so don't show remaing books in drop down
                      return []
                    }}

                    onChange={(event: any, bookValues: IBook[]) => {
                      setSelectedBooks(bookValues) //user selected options as chip

                    }}
                  />
                </Grid>
                {/* BOOK TYPE [PAPERBACK?HARDBOUN?AUDIO]  */}
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  <Field
                    component={Autocomplete}
                    value={selectedBookTypes}
                    multiple
                    filterSelectedOptions
                    disablePortal={true}
                    name="bookTypes"
                    disableCloseOnSelect
                    noOptionsText={!bookTypes?.length ? 'No Type found, sorry !' : "You have selecetd all Types"}
                    options={bookTypesAC}
                    getOptionLabel={(item: IBookForOffer) => item.label}
                    renderOption={(option: IBookForOffer) => (
                      <Typography style={{ fontSize: "1rem" }}>{option.label}</Typography>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Search and select book types"
                        label="BookTypes"
                      />)
                    }
                    filterOptions={(bookTypesAC: IBookForOffer[], filterState: any) => {
                      // drop down options available to user while searching or selecting.
                      const filteredList = autoCompletefilter(bookTypesAC, filterState)
                      const isAllIncluded = filteredList.find(bookType => bookType.value === allSelectionIds.BOOK_TYPES)
                      // if all types option is available in drop down then return same list
                      if (isAllIncluded) {
                        return filteredList
                      }
                      // else user has selected all types , it is present in chips , so don't show remaing types in drop down
                      return []
                    }}

                    onChange={(event: any, bookTypeValues: IBookForOffer[]) => {
                      setSelectedBookTypes(bookTypeValues) //user selected options as chip
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} style={{ marginTop: "1rem" }}>
                <Fab
                  variant="extended"
                  size="medium"
                  type="submit"
                >
                  Update
                </Fab>
              </Grid>

            </Form>
          )
          }
        </Formik >
      </Grid >
    </Grid >
  )
}

export default UpdateOffer;