import { CircularProgress, Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateMobileUser } from "../../../reducers/mobileUser/reducer";
import { ISubOrder } from "../../../reducers/orders/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS, SubOrderStatus } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
let { cashfreeSandbox, cashfreeProd } = require('cashfree-pg-sdk-javascript');

const DigitalPayment: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentSessionId, setPaymentSessionId] = useState<string>();
  const [orderId, setOrderId] = useState<string>();
  const [order, setOrder] = useState<ISubOrder>();
  const [paymentTriggered, setPaymentTriggered] = useState<boolean>(false);
  const [paymentSucceeded, setPaymentSucceeded] = useState<boolean>(false);
  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [env, setEnv] = useState<string>("development")
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const dispatch = useDispatch();


  //fetch book data
  useEffect(() => {
    if (location && location.search && mobileUserState && mobileUserState.id) {
      const baseUri = localStorage.getItem("backend");
      if (baseUri === "https://api.rachnaye.com") {
        setEnv("production")
      }
      setPaymentTriggered(false);
      setPaymentSucceeded(false);
      const params = new URLSearchParams(location.search);
      const pgSessionIf = params.get("pgSessionId");
      if (pgSessionIf) {
        setPaymentSessionId(pgSessionIf)
      }
      const id = params.get("orderId");
      const orderType = params.get("orderType");
      if (id && orderType) {
        setOrderId(id)
        fetchOrderDetails(orderType, id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, mobileUserState.id]);

  useEffect(() => {
    if (paymentSessionId && order) {
      const dropConfig = {
        "components": [
          "order-details",
          "card",
          "netbanking",
          "app",
          "upi",
          "paylater",
          "credicardemi",
          "cardlessemi",
        ],
        "onSuccess": handleSuccess,
        "onFailure": handleSuccess,
        "style": {
          //to be replaced by the desired values
          "backgroundColor": "#ffffff",
          "color": "#FF5C3E",
          "fontFamily": "Lato",
          "fontSize": "14px",
          "errorColor": "#ff0000",
          "theme": "light", //(or dark)
        }
      }
      let parent: any = document.getElementById('drop_in_container');
      if (parent) {
        parent.innerHTML = '';
        if (env === "production") {
          let cashfree = new cashfreeProd.Cashfree(paymentSessionId);
          cashfree.drop(parent, dropConfig);
        } else {
          let cashfree = new cashfreeSandbox.Cashfree(paymentSessionId);
          cashfree.drop(parent, dropConfig);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSessionId, order])

  const fetchOrderDetails = async (orderType: string, id: string) => {
    let res
    if (orderType === 'series') {
      res = await callApi(PATHNAMES.GET_SERIES_ORDER_DETAIL(id), HTTP_METHODS.GET);
    }
    if (orderType === 'book') {
      res = await callApi(PATHNAMES.GET_PORTAL_ORDER(id), HTTP_METHODS.GET);
    }

    if (res && res.success && res.data) {
      setOrder(res.data)
    } else {
      alert("Please click on payment button to proceed")
      navigate(-1)
    }
  }

  const handleSuccess = async (data: any) => {
    if (orderId) {
      setOrderLoading(true)
      const res = await callApi(PATHNAMES.VERIFY_PAYMENT_FOR_PORTAL_ORDER(orderId), HTTP_METHODS.GET);
      setPaymentTriggered(true)
      setOrderLoading(false)
      if (res && res.success && res.data) {
        setPaymentSucceeded(true);
        fetchUserCartQuantity(mobileUserState.id)
      } else {
        setPaymentSucceeded(false);
      }
    }
  }

  const fetchUserCartQuantity = async (userId: string) => {
    const res = await callApi(PATHNAMES.GET_MOBILE_USER_CART_QUANTITY(userId), HTTP_METHODS.GET);
    if (res && res.success) {
      dispatch(updateMobileUser({ ...mobileUserState, cartQuantity: res.data }));
    }
  }

  if (!order) {
    return <CircularProgress />
  }

  if (orderLoading) {
    return (
      <Grid container xs={12} justify="center">
        <CircularProgress />
        <Grid container xs={12} justify="center">
          <Typography style={{ marginTop: "1rem", fontWeight: "bolder", fontSize: 18 }}>
            Kindly wait, your payment is in progress!
          </Typography>
        </Grid>
        <Grid container xs={12} justify="center">
          <Typography style={{ marginTop: "1rem", fontWeight: "bolder", fontSize: 18 }}>
            Please do not refresh page or press back button!
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container xs={12}>
      {!paymentTriggered && order.status === SubOrderStatus.CREATED ?
        <div
          className="dropin-parent"
          id="drop_in_container"
          style={{ margin: "0 auto", width: 750 }}
        >
          Your component will come here
        </div> :
        (paymentSucceeded || order.status === SubOrderStatus.COMPLETED ?
          <Grid container xs={12} justify="center">
            <Grid container xs={6} justify="center">
              <CheckCircleIcon style={{ color: "green", fontSize: 180, margin: "0 auto" }} />
              <Grid container xs={12} justify="center">
                <Typography style={{ marginTop: "1rem", fontWeight: "bolder", fontSize: 18 }}>
                  Congratulations! Your order was successful!
                </Typography>
              </Grid>
              <Grid container xs={12} justify="center">
                <Typography
                  style={{ marginTop: "1rem", cursor: "pointer", fontSize: 16, color: "blue", textDecoration: "underline" }}
                  onClick={() => navigate(`/portal/home`)}
                >
                  Continue Shopping
                </Typography>
              </Grid>
            </Grid>
          </Grid> :
          <Grid container xs={12} justify="center">
            <Grid container xs={6} justify="center">
              <ErrorIcon style={{ color: "red", fontSize: 180, margin: "0 auto" }} />
              <Grid container xs={12} justify="center">
                <Typography style={{ marginTop: "1rem", fontWeight: "bolder", fontSize: 18 }}>
                  Sorry! Your order was unsuccessful!
                </Typography>
              </Grid>
              <Grid container xs={12} justify="center">
                <Typography style={{ marginTop: "1rem", fontWeight: "bolder", fontSize: 18 }}>
                  Please try again!
                </Typography>
              </Grid>
              <Grid container xs={12} justify="center">
                <Typography
                  style={{ marginTop: "1rem", cursor: "pointer", fontSize: 16, color: "blue", textDecoration: "underline" }}
                  onClick={() => navigate(`/portal/home`)}
                >
                  Go to home
                </Typography>
              </Grid>
            </Grid>
          </Grid>)
      }

    </Grid>
  )
}

export default DigitalPayment;