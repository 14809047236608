import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import RevenueCard from "../../common/RevenueCard";
import Demographic from "./Demographic";
import RevenueDateRangeSelect from "./RevenueDateRangeSelect";

interface IStats {
  books: number;
  users: number;
  publishers: number;
  orders: number;
  adminRevenue: number;
  adminRevenueByBook: number;
  adminRevenueBySeries: number;
  publisherRevenue: number;
  writerRevenue: number;
  overAllRevenue: number;   //admin + publisher + writer revenue
  shippingCharges: number;
  convenienceFee: number;
  offlineOrders: number;
  offlineOverAllRevenue: number;
  offlinePlatformShare: number;
  offlinePublisherShare: number;
};
const useStyles = makeStyles(() => ({
  cardContainer: {
    padding: '0.5rem'
  },
  graphContainer: {
    padding: "1rem",
    minHeight: '20rem'
  },
  onlineCard: {
    backgroundColor: '#ffedea', // Color for the Online cards
  },
  offlineCard: {
    backgroundColor: '#cbe2b5', // Color for the Offline cards
  },
  chargesCard: {
    backgroundColor: '#eae2b7', // Color for the Shipping and Convenience cards
  },
  activeCard: {
    backgroundColor: '#c4e3ed', // Color for the Active cards
  }
}))


const AdminDashboard: React.FC = () => {
  const classes = useStyles()
  const userState = useSelector((state: IAppState) => state.user);
  const [stats, setStats] = useState<IStats>();
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: "",
    endDate: ""
  });


  const handleSelctedDateRange = (timeObj: any) => {
    setSelectedDateRange(v => timeObj);
  };

  const fetchPublisherData = async (spaceId: string, selectedDateRange: any) => {
    try {
      const res = await callApi(PATHNAMES.ADMIN_STATS(spaceId, selectedDateRange.startDate, selectedDateRange.endDate), HTTP_METHODS.GET)
      if (res?.data) {
        setStats(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);

    }
  }


  useEffect(() => {
    if (userState && userState.spaceId && selectedDateRange.endDate && selectedDateRange.startDate) {
      fetchPublisherData(userState.spaceId, selectedDateRange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);



  return (
    <Grid container xs={12} justify="flex-start">
      <RevenueDateRangeSelect heading="Revenue :" handleOptionChange={handleSelctedDateRange} />
      {isLoading ?
        <CircularProgress />
        :
        <Grid container xs={12} spacing={2} style={{ marginTop: "1rem" }}>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="revenue"
              name="Online Revenue"
              value={stats?.overAllRevenue || 0}
              className={classes.onlineCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="revenue"
              name="Platform Revenue"
              value={stats?.adminRevenue || 0}
              className={classes.onlineCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="revenue"
              name="Publisher Revenue"
              value={stats?.publisherRevenue || 0}
              className={classes.onlineCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="revenue"
              name="Writer Revenue"
              value={stats?.writerRevenue || 0}
              className={classes.onlineCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="revenue"
              name="Revenue By Book"
              value={stats?.adminRevenueByBook || 0}
              className={classes.onlineCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="revenue"
              name="Revenue By Series"
              value={stats?.adminRevenueBySeries || 0}
              className={classes.onlineCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="orders"
              name="Online Orders"
              value={stats?.orders || 0}
              className={classes.onlineCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="revenue"
              name="Shipping Charges"
              value={stats?.shippingCharges || 0}
              className={classes.chargesCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="books"
              name="Active Books"
              value={stats?.books || 0}
              className={classes.activeCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="users"
              name="Active Users"
              value={stats?.users || 0}
              className={classes.activeCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="publisher"
              name="Publishers"
              value={stats?.publishers || 0}
              className={classes.activeCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="revenue"
              name="Convenience Fee"
              value={stats?.convenienceFee || 0}
              className={classes.chargesCard}
            />
          </Grid>
          {/* OFFLINE CARDS */}
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="revenue"
              name="Offline Revenue"
              value={stats?.offlineOverAllRevenue || 0}
              className={classes.offlineCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="revenue"
              name="Platform Revenue"
              value={stats?.offlinePlatformShare || 0}
              className={classes.offlineCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="revenue"
              name="Paid To Publisher"
              value={stats?.offlinePublisherShare || 0}
              className={classes.offlineCard}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="orders"
              name="Offline Orders"
              value={stats?.offlineOrders || 0}
              className={classes.offlineCard}
            />
          </Grid>
        </Grid>
      }
      <Grid container xs={12} spacing={3} style={{ marginTop: '2rem' }}>
        <Grid container xs={12} sm={6} md={6} lg={4} xl={3} className={classes.graphContainer}>
          <Demographic />
        </Grid>
      </Grid>

    </Grid >
  )
};

export default AdminDashboard;