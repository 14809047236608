import { Grid } from '@material-ui/core';
import React from 'react'
import DigitalBooksOrders from './DigitalBooksOrders';
import SeriesOrders from './SeriesOrders';

const DigitalOrders: React.FC = () => {
  return (
    <Grid container xs={12}>
      <DigitalBooksOrders />
      <SeriesOrders />
    </Grid>
  )
}

export default DigitalOrders;