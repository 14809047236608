import {
  makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";

const useStyle = makeStyles(() => ({
  orderHeading: { fontFamily: 'Poppins', fontWeight: 600 },
  heading: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontStyle: 'SemiBold',
    fontSize: '12px',
    paddingBottom: "0.2rem",
    paddingTop: "0.1rem"
  },
  content: {
    fontWeight: 400,
    fontFamily: 'Poppins',
    fontStyle: 'SemiBold',
    fontSize: '13px',
  },
  subHeading: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontStyle: 'SemiBold',
    fontSize: '14px',
    paddingBottom: "0.1rem"
  },
}))
interface IProp {
  title: string;
  address: any;
  recipientName?: string;
}
const OrderAddress = (props: IProp) => {
  const classes = useStyle()

  return (<>
    <Typography variant={"h3"} color="textSecondary" className={classes.heading}>
      {props.title}
    </Typography>
    {props.recipientName && <Typography variant={"body1"} className={classes.subHeading}>
      {`${props.recipientName}` || ""}
    </Typography>
    }
    <Typography variant={"body1"} className={classes.content}>
      {`${props.address?.address1}, ${props.address?.city}, PinCode- ${props.address?.pincode}` || ""}
    </Typography>
    <Typography variant={"body1"} className={classes.content}>
      {(`${props.address?.state}, ${props.address?.country}` || "") && props.address?.contactNumber?.phoneNumber ? `Mob No. ${props.address?.contactNumber?.phoneNumber}` : ''}
    </Typography>
  </>
  )
}

export default OrderAddress