import React, { useState, useEffect } from 'react';
import DatamapsIndia from 'react-datamaps-india'
import { Grid, CircularProgress, Paper, makeStyles } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Typography } from '@material-ui/core';
import CustomizedSelects from './CustomizedSelects';
import callApi from './../../../utils/apiUtil';
import { PATHNAMES } from '../../../utils/pathNames';
import { useSelector } from 'react-redux';
import { IAppState } from './../../../store';
import { HTTP_METHODS } from './../../../utils/constants';

const useStyles = makeStyles(() => ({
  mapIndia: {
    margin: '1rem',
    position: "relative",
    "& svg": {
      height: '14rem',
      marginTop: '-2rem',
    }
  },
  legend1: {
    marginRight: "1rem",
    display: 'flex',
    alignItems: 'center'
  },
  legend2: {
    marginRight: "1.3rem",
    display: 'flex',
    alignItems: 'center'
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginTop: "5rem"
  },
  header: {
    padding: "1rem",
    paddingBottom: "0.3rem"
  },
}))

const dataMapOption = {
  title: '',
  legendTitle: '',
  startColor: "#FFDAB9",
  endColor: "#FF6347",
  hoverTitle: "Count",
  noDataColor: "#f5f5f5",
  borderColor: "#8D8D8D",
  hoverBorderColor: "#8D8D8D",
  hoverColor: "green",
}
const Demographic: React.FC = () => {
  const classes = useStyles()
  const userState = useSelector((state: IAppState) => state.user);
  const [selectedDemoDiff, setSelectedDemoDiff] = React.useState<number>(1);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [DemoReads, setDemoReads] = useState<any>({})

  const fetchData = async (spaceId: string, isSuperAdmin: boolean) => {
    try {
      const diff = selectedDemoDiff
      let responseDemoReads
      if (isSuperAdmin) {
        responseDemoReads = await callApi(PATHNAMES.ADMIN_READER_DEMOGRAPHIC(diff), HTTP_METHODS.GET)
      } else {
        responseDemoReads = await callApi(PATHNAMES.PUBLISHER_READER_DEMOGRAPHIC(spaceId || "", diff), HTTP_METHODS.GET)
      }
      setDemoReads(responseDemoReads.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (userState && userState.spaceId) {
      const role = userState.role;
      if (role && role.active) {
        //superAdmin view
        if (role.category === "org") {          
          fetchData(userState.spaceId, true)
        } else {
          fetchData(userState.spaceId, false)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState, selectedDemoDiff]);

  const handleDemoDiffChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedDemoDiff(event.target.value as number);
  };

  return (
    <Paper elevation={5} style={{ width: "100%" }} >
      {isLoading ? <Grid container alignItems="center" justify="center" style={{ minHeight: '28vh' }}>
        < CircularProgress />;
      </Grid> :
        <>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className={classes.header}>
            <Typography variant="h3" style={{ fontWeight: 600 }}>READERS DEMOGRAPHIC </Typography>
          </Grid>
          <Grid container lg={12}>
            {DemoReads ?
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CustomizedSelects value={selectedDemoDiff} onChange={handleDemoDiffChange} type={'lifetime'} />
                </Grid>
                <Grid container xs={6} sm={6} md={6} lg={6} xl={6} >
                  <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className={classes.mapIndia}>
                    <DatamapsIndia
                      regionData={DemoReads}
                      hoverComponent={({ value }: any) => {
                        return (
                          <Grid>
                            <Grid>{value.value} Readers</Grid>
                          </Grid>
                        );
                      }}
                      mapLayout={dataMapOption}
                    />
                  </Grid>
                </Grid>
                <Grid container xs={5} sm={5} md={5} lg={5} xl={5} justify='flex-end'>
                  <Grid container lg={12} className={classes.mapContainer}>
                    <Grid item className={classes.legend1}>
                      <FiberManualRecordIcon htmlColor="#FF6347" />
                      <Typography>{'> 500 Readers'}</Typography>
                    </Grid>
                    <Grid item className={classes.legend2}>
                      <FiberManualRecordIcon htmlColor='#FFDAB9' />
                      <Typography>{'< 100 Readers'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
              :
              <Grid container xs={12} justify="center" alignContent="center" style={{ marginTop: '20%' }}><Typography variant="h2">No Data to display</Typography></Grid>
            }
          </Grid>
        </>
      }
    </Paper>
  );
}


export default Demographic;