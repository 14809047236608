import React, { useState } from "react";
import {
  Grid,
  Fab,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import callApi from "../../../utils/apiUtil";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { PATHNAMES } from "../../../utils/pathNames";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-material-ui";
import { HTTP_METHODS } from "../../../utils/constants";

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      marginTop: "20vh",
      maxWidth: "25%",
      alignContent: "center",
      justifyContent: "center",
    },
    formItem: {
      minWidth: "20rem",
      marginBottom: "1rem"
    },
    submitBtn: {
      marginTop: "1rem",
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
      minWidth: "20rem",
    },
    redirectText: {
      marginTop: "2rem",
      justifyContent: "center",
      color: "#005D68",
    },
    redirectLink: {
      display: "inline",
      cursor: "pointer",
      color: theme.palette.primary.main,
      marginLeft: "0.5rem"
    },
    mainText: {
      fontWeight: 600,
      fontSize: 36,
      color: theme.palette.primary.main,
      marginBottom: "15%"
    }
  })
);

const ResetPassword: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar()
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    otp: "",
    password: ""
  });

  const [otpSent, setOtpSent] = useState(false)


  const STATUS = {
    SENDOTP: "sendotp",
    RESETPASSWORD: "reset",
  };

  const [status, setStatus] = useState(STATUS.SENDOTP);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleDisableButton = () => {

    const emailEmpty = (values.email === '')
    const otpPasswordEmpty = (values.otp === '' && values.password === '')
    //otp yet not sent
    if (!otpSent) {
      if (emailEmpty) return true
    } else {
      //otp sent
      if (otpPasswordEmpty) return true
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email(),
    otp: Yup.number(),
    password: Yup.string().trim().min(6, 'Password is too short - should be 6 chars minimum.'),
  });


  const handleSubmit = async () => {

    //#region send OTP
    if (status === STATUS.SENDOTP) {
      const data = {
        emailId: values.email,
      }
      const otpResponse = await callApi(
        PATHNAMES.SEND_RESET_PASSWORD_OTP,
        HTTP_METHODS.POST,
        {
          body: JSON.stringify({ data }),
        }
      )

      if (otpResponse && otpResponse.success) {
        snackbar({
          message: otpResponse.message,
          variant: SnackBarVariant.SUCCESS,
        });
        setStatus(STATUS.RESETPASSWORD)
        // otp sent and otp and password field made visible
        setOtpSent(true)
      } else {
        snackbar({
          message: otpResponse.message || "something went wrong",
          variant: SnackBarVariant.ERROR,
        });
      }
    }
    //#endregion send OTP

    //#region password update 
    if (status === STATUS.RESETPASSWORD) {
      const data = {
        emailId: values.email,
        otp: values.otp,
        newPassword: values.password
      }
      const response = await callApi(PATHNAMES.RESET_PASSWORD, HTTP_METHODS.POST, {
        body: JSON.stringify({ data })
      })

      if (response && response.success) {
        snackbar({
          message: response.message,
          variant: SnackBarVariant.SUCCESS
        });

        navigate("/login", { replace: true })
      } else {
        snackbar({
          message: response.message || "Something went wrong",
          variant: SnackBarVariant.ERROR
        });
      }


    }
    //#endregion password update 
  }


  return (
    <Grid container justify="center">
      <Grid container xs={12} className={classes.formContainer}>
        <Grid container xs={12} justify="center">
          <Typography className={classes.mainText}>
            Reset Your Password
          </Typography>
        </Grid>
        <Grid container xs={12}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={values}
            onSubmit={(data, { setSubmitting }) => {
              handleSubmit();
              setSubmitting(false);
            }}
          >
            {() => (
              <Form style={{ width: "100%" }}>
                <Grid item xs={12} hidden={otpSent}>
                  <Field
                    component={InputField}
                    fullWidth
                    required
                    className={classes.formItem}
                    label="Email"
                    placeholder="Email"
                    name="email"
                    InputProps={{
                      value: values.email,
                      onChange: handleChange
                    }}
                  ></Field>
                </Grid>
                <Grid item xs={12} hidden={!otpSent}>
                  <Field
                    component={InputField}
                    fullWidth
                    required={otpSent}
                    className={classes.formItem}
                    label="Otp"
                    placeholder="Otp"
                    name="otp"
                    type="password"
                    InputProps={{
                      value: values.otp,
                      onChange: handleChange
                    }}
                  ></Field>
                </Grid>
                <Grid item xs={12} hidden={!otpSent}>
                  <Field
                    component={InputField}
                    fullWidth
                    required={otpSent}
                    className={classes.formItem}
                    label="Password"
                    placeholder="Password"
                    name="password"
                    type="password"
                    InputProps={{
                      value: values.password,
                      onChange: handleChange
                    }}
                  ></Field>
                </Grid>
                <Grid container xs={12} justify="center">
                  <Fab
                    variant="extended"
                    size="medium"
                    disabled={handleDisableButton()}
                    className={classes.submitBtn}
                    type="submit"
                  >
                    {!otpSent ? 'Send Otp' : 'Reset'}
                  </Fab>
                </Grid>
                <Grid item container xs={12} className={classes.redirectText}>
                  <Typography variant="body1">
                    Have an account?
                    <span
                      className={classes.redirectLink}
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </span>
                  </Typography>
                </Grid>
                <Grid item container style={{ marginTop: '0.8rem' }} xs={12} className={classes.redirectText}>
                  <Typography variant="body1">
                    Don't have an account?
                    <span
                      className={classes.redirectLink}
                      onClick={() => navigate("/signup")}
                    >
                      Signup
                    </span>
                  </Typography>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid >
  );
};

export default ResetPassword;