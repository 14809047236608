import { Box, Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/rachnayeLight.png";

const BackgroundImage = "https://images.unsplash.com/photo-1544716278-e513176f20b5?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTZ8MGZiSnFoZ0hZQ0V8fGVufDB8fHx8fA%3D%3D"
// const BackgroundImage = "https://images.unsplash.com/photo-1591951425328-48c1fe7179cd?w=800&auto=format&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGJvb2tzfGVufDB8fDB8fHww"

const NotFound: React.FC = () => {
  return (
    <Box style={{
      height: "100vh",
      position: 'relative',
      overflow: 'hidden',
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: "cover",
    }}>
      <Container maxWidth="md" >
        <Grid container xs={12} justifyContent='center'>
          <Grid item justifyContent='center' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "2.5rem 0rem" }}>
            <img alt="Rachnaye" src={logo}
              style={{
                maxWidth: "80%",
                height: "auto",
                borderRadius: "50%"
              }}
            />
          </Grid>
          <Grid container item justifyContent='center' style={{ padding: "2rem 0rem" }}>
            <Typography variant='h1' style={{ fontWeight: 600, paddingBottom: "2rem" }}>404 Page Not Found</Typography>
            <Typography variant='h2' style={{ fontWeight: 500, paddingBottom: "2rem" }} >Sorry It seems what you are trying to navigate does not exist or you don't have a access for it</Typography>
            <Typography variant='h2' style={{ fontWeight: 500 }} >Go to <Link to="/" style={{ textDecoration: "underline" }}>Home Page</Link></Typography>
          </Grid>
          <Grid>
          </Grid>
        </Grid>
      </Container >
    </Box>
  )
};

export default NotFound;