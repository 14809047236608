import { AppBar, Grid, makeStyles, Paper, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { TabPanel } from "../../../common/TabPanel";
import Payouts from "../../Payouts/Payouts";
import PublisherBooks from "./PublisherBooks";
import PublisherDistributors from "./PublisherDistributors";
import PublisherOrders from "./PublisherOrders";
import PublisherUsers from "./PublisherUsers";

interface IProps {
  publisher: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
    minHeight: "55vh",
    width: "95%"
  }
}));

const PublisherDetailTabView: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  return (
    <Grid container xs={12}>
      <AppBar position="static" color="default" style={{ zIndex: 1 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Books" />
          <Tab label="Distributors" />
          <Tab label="Orders" />
          <Tab label="Payouts" />
          <Tab label="Users" />
        </Tabs>
      </AppBar>
      <Paper className={classes.root}>
        <TabPanel value={value} index={0}>
          <PublisherBooks publisherId={props.publisher.id} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PublisherDistributors publisherId={props.publisher.id} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PublisherOrders publisherId={props.publisher.id} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Payouts publisherId={props.publisher.id} showTitle />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <PublisherUsers publisherId={props.publisher.id} />
        </TabPanel>
      </Paper>
    </Grid>
  );
};

export default PublisherDetailTabView;