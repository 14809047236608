import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Typography
} from "@material-ui/core";
import React, { useEffect } from "react";

interface IProps {
  description: string;
  isOpen: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
  isInProgress?: boolean;
  confirmText?: string;
  cancelText?: string;
  disabledBackDropClick?: boolean;
  onBackDropClick?: () => void;
}


export const ConfirmationDialog = (props: IProps) => {
  const [open, setOpen] = React.useState(props.isOpen);

  const isOpen = props.isOpen;

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleCancel = () => {
    setOpen(false);
    props.onCancel();
  };

  const handleConfirm = () => {
    props.onConfirm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={props.disabledBackDropClick ? props.disabledBackDropClick : false}
      onBackdropClick={props.onBackDropClick}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h3">
          {props.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Fab variant="extended" size="medium" onClick={handleConfirm}style={{ marginRight: 10 }}>
          {props.isInProgress && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
          {props.confirmText ? props.confirmText : "Confirm"}
        </Fab>
        <Fab variant="extended" size="medium" onClick={handleCancel} className="blackBackButton">
          {props.cancelText ? props.cancelText : "Cancel"}
        </Fab>
      </DialogActions>
    </Dialog>
  );
};
