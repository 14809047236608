import { Button, Card, CardMedia, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { HTTP_METHODS, ResourceTypes } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { uploadFileToS3 } from "../../../../utils/s3Util";
import { ConfirmationDialog } from "../../../common/ConfirmationDialog";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { DragDropImages } from './../../../common/DragDropImages';
import ReArrangePreviews from "./ReArrangePreviews";

interface Iprops {
  bookId: string
}
interface IPreview {
  idx: number;
  previewImage: string;
  previewImageUrl: string;
}
const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontStyle: 'Bold',
    fontSize: '16px',
  },
  card: {
    height: '10rem',
    width: '7rem',
    marginRight: '1rem',
    backgroundColor: '#F5F5F5'
  },
  deleteBtn: {
    padding: 3,
    cursor: "pointer"
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1rem'
  }
}))

const LookInside: React.FC<Iprops> = (props) => {
  const classes = useStyles()
  const [previews, setPreviews] = useState<IPreview[]>([])
  const [selectedItem, setSelectedItem] = useState<IPreview>()
  const [LookInside, setLookInside] = useState<boolean>(false)
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth)
  const [maxCounter, setMaxCounter] = useState<number>(0)
  const [openDeletePreviewCD, setOpenDeletePreviewCD] = useState<boolean>(false) // CD-confirmationDialog
  const [openRearrange, setOpenRearrange] = useState<boolean>(false)
  const [currentPreviewIndex, setcurrentPreviewIndex] = useState<number>(0)
  const userState = useSelector((state: IAppState) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const snackbar = useSnackBar();
  const [loading, setLoading] = useState<boolean>(false);

  window.addEventListener("resize", () => setWindowSize(window.innerWidth))

  let fetchData = async (bookId: string) => {
    const res = await callApi(PATHNAMES.GET_BOOK_PREVIEWS(bookId), HTTP_METHODS.GET)
    if (res.success && res.data) {
      setPreviews(res.data)
      setMaxCounter(res.data.length)
    }
  }

  const goLeft = () => {
    setcurrentPreviewIndex((currentPreviewIndex - 1))
  }

  //go right
  const goRight = () => {
    setcurrentPreviewIndex((currentPreviewIndex + 1))
  }

  useEffect(() => {
    if (location && location.search && userState && userState.id) {
      const params = new URLSearchParams(location.search);
      const bookId = params.get("book");
      if (bookId) {
        fetchData(bookId)
      }
    }
  }, [location, userState])


  // to change the preview list limit
  useEffect(() => {
    if (previews && previews.length > 1) {
      if (windowSize > 1280) {
        if (previews.length < 3) {
          setMaxCounter(2)
        } else {
          setMaxCounter(3)
        }
      }
      if (windowSize < 1280 && windowSize > 853) {
        setMaxCounter(2)
      }
      if (windowSize < 2560 && windowSize > 1920) {
        setMaxCounter(4)
      }
      if (windowSize < 3840 && windowSize > 2560) {
        setMaxCounter(6)
      }
      if (windowSize > 3840) {
        if (previews.length > 5) {
          setMaxCounter(6)
        } else {
          setMaxCounter(previews.length)
        }
      }

    }
  }, [windowSize, previews])

  // upload previews
  const uploadPreviews = async (files: File[]) => {
    let keyArr: string[] = []
    setLookInside(false)
    setLoading(true)
    for (let k = 0; k < files.length; k++) {
      const key = await uploadFileToS3({
        file: files[k],
        resourceType: ResourceTypes.BOOK_IMAGE,
        isAdmin: false,
        publisherId: userState.spaceId
      });
      keyArr.push(key)
    }
    if (keyArr.length) {
      const data = {
        previews: [...keyArr]
      }
      const res = await callApi(PATHNAMES.UPLOAD_PREVIEWS(props.bookId), HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      })
      if (res.success && res.data) {
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: res.message || "Previews added successfully"
        })
        navigate(`/view-book?book=${props.bookId}`)
      } else {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: res.message || "Something went wrong"
        })
      }
      setLoading(false)
    }
  }

  //delete preview
  const deleteCurrPreview = async (item: IPreview) => {
    const newArr: IPreview[] = previews.filter(preview => preview.previewImage !== item.previewImage)
    setOpenDeletePreviewCD(false)
    setPreviews(newArr)
    const data = { previews: [item.previewImage] }
    const res = await callApi(PATHNAMES.DELETE_PREVIEWS(props.bookId), HTTP_METHODS.PATCH, {
      body: JSON.stringify({ data })
    })
    if (res.success && res.data) {
      snackbar({
        variant: SnackBarVariant.SUCCESS,
        message: res.message || "Preview deleted successfully"
      })
      navigate(`/view-book?book=${props.bookId}`)
    } else {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: res.message || "Something went wrong"
      })
    }
  }

  if (loading) {
    return (
      <>
        <Grid container xs={12} justify='center' alignContent="center" >
          <Grid item >
            <CircularProgress style={{ marginLeft: '6rem' }} />
            <br />
            <Typography variant="body1" >Wait for a bit, previews are uploading....</Typography>
          </Grid>
        </Grid>
      </>)
  }

  return (
    <>
      <DragDropImages
        open={LookInside}
        handleCloseDragDrop={() => setLookInside(false)}
        handleSave={uploadPreviews}
        initialFiles={[]}
        filesLimit={15}
        title={"Upload Image"}
      />
      <Grid container item xs={7} sm={6} md={8} lg={6} xl={8}>
        <Typography variant='h3' className={classes.title}> Uploaded sample pages</Typography>
      </Grid>
      <Grid container item xs={5} sm={6} md={4} lg={6} xl={4} style={{ paddingRight: '0px' }} justify="flex-end" >
        {previews.length > 0 &&
          <Button style={{ color: 'red' }} onClick={() => { setOpenRearrange(true) }}>Re-arrange</Button>
        }
        <Button style={{ color: 'red' }} onClick={() => { setLookInside(true) }}>Upload</Button>
      </Grid>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: '15rem', marginTop: "1.4rem", overflowY: 'visible' }} justify='center'>
        {!previews.length ?
          <Typography style={{ marginTop: '5rem' }} variant='h2'>Upload Pages</Typography>
          :
          <>
            {previews?.slice(currentPreviewIndex, currentPreviewIndex + maxCounter).map((preview) => {
              return <Card className={classes.card}>
                <CardMedia
                  component="img"
                  height="85%"
                  alt="Front Cover"
                  title="Front Cover"
                  image={preview.previewImageUrl}
                />
                <Grid container justify="center" >
                  <DeleteIcon fontSize="small" className={classes.deleteBtn} onClick={() => {setSelectedItem(preview); setOpenDeletePreviewCD(true)}} />
                </Grid>
              </Card>
            })}
            <Grid container className={classes.sliderContainer}>
              <Button onClick={goLeft} disabled={currentPreviewIndex === 0}> <ChevronLeftIcon /> </Button>
              {currentPreviewIndex > previews?.length - 1 ? `${previews?.length} of ${previews?.length}` : `${currentPreviewIndex + 1}-${currentPreviewIndex + maxCounter} of ${previews?.length}`}
              <Button onClick={goRight} disabled={currentPreviewIndex + maxCounter >= previews?.length} ><ChevronRightIcon /></Button>
            </Grid>
          </>}
      </Grid>
      <ConfirmationDialog
        isOpen={openDeletePreviewCD}
        onCancel={() => setOpenDeletePreviewCD(false)}
        onConfirm={() => {
          if (selectedItem) {
            return deleteCurrPreview(selectedItem)
          }
        }}
        title={"Confirm Preview Delete"}
        description={"Are you sure, you want to delete this preview"}
      />
      <ReArrangePreviews
        previews={previews}
        bookId={props.bookId}
        open={openRearrange}
        handleClose={() => setOpenRearrange(false)}
      />
    </>

  )
}
export default LookInside;