import { Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import CircularLoader from "../../common/CircularLoader";


interface IProps {
  apiCall: (args: any) => Promise<any[]>;
  pagination?: boolean
  pageNumber: number;
  pageSize: number;
  children: React.ReactElement;
  reLoadApi?: number; // just increment previous value whenever we want to reload , not using boolean , since useEffect not updates 
  msgWhenDataIsEmpty: string;
  noMoreDataMessage: string;
}
const InfiniteScroll: React.FC<IProps> = (props) => {

  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);

  const [items, setItems] = useState<any[] | null>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [pageSize] = useState<number>(props.pageSize);
  const [pageNumber, setPageNumber] = useState<number>(props.pageNumber);

  // hard refresh
  const [forceEffect, setForceEffect] = useState(false);


  useEffect(() => {
    if (mobileUserState.id) {
      // reset generally occurs when reload hits and reset all the data to start.
      // which is eventually pageNumber 0
      const reset = pageNumber === 0 ? true : false
      const cancelApiCall = fetchData(reset)
      return cancelApiCall
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, mobileUserState, forceEffect]);

  useEffect(() => {
    if (props.reLoadApi && mobileUserState.id) {
      handleSetPageNumber(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reLoadApi]);


  const handleSetPageNumber = (newValue: number) => {
    if (newValue === 0 && pageNumber === 0) {
      // If pageNumber is being set to 0 and it was already 0, toggle forceEffect
      setForceEffect(prev => !prev);
    } else {
      setPageNumber(newValue);
    }
  };

  // calls api, sets the data and at end
  //return a fn to canecll api call
  const fetchData = (reset?: boolean) => {
    setLoading(true)
    setError(false)

    const controller = new AbortController();
    const signal = controller.signal;
    props.apiCall({ pageNumber, pageSize, signal }).then((res) => {
      if(signal.aborted) return
     
      if (reset) {
        setItems((prev: any) => [...res])
      } else {
        setItems((prev: any) => _.unionBy(prev, res, 'id'))
      }

      // it indicates where there is more items to load for next api call , only if we are usig pagination
      if (props.pagination) {
        setHasMore(res.length === pageSize)
      }
      if(!signal.aborted){
        setLoading(false)
      }
    }).catch((err) => {
      if (err.name === 'AbortError') {
        console.debug('successfully aborted');
        return
      }
      setError(true)
    });
   
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    }
  }


  const observer = useRef<IntersectionObserver>()

  // last element visible in list 
  const lastElementRef = useCallback((node: Element) => {
    if (loading || !props.pagination) {
      return
    }
    if (observer.current) {
      observer.current.disconnect()
    }

    observer.current = new IntersectionObserver(entries => {

      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) {
      observer.current.observe(node)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, hasMore])

  // READ THIS NOTE BEFORE APPYING 
  // NOTE: the iterable div or container's last div must have lastElementRef as ref value,
  //  every child component passed to infinite scroll wil get items(array of items) on which it can iterate
  //  and UI can be customised
  return (
    <div style={{ width: "100%" }}>
      {React.cloneElement(props.children, { items, fetchData, lastElementRef })}
      {loading ? (
        <CircularLoader />
      ) : items ? (
        <>
          {items.length === 0 && !loading && (
            <Grid container justify="center" alignItems="center" style={{ width: '100%', padding: "2rem 1rem" }}>
              <Typography variant='h3'>
                {props.msgWhenDataIsEmpty}
              </Typography>
            </Grid>
          )}
          {!hasMore && items.length > 0 && (
            <Grid container justify="center" alignItems="center" style={{ width: '100%', padding: "4rem 0 0" }}>
              <Typography variant="h4">
                {props.noMoreDataMessage}
              </Typography>
            </Grid>
          )}
        </>
      )
    :
    error && (
      <Grid justify="center" alignItems="center" container xs={12}>
        <Typography variant='subtitle1'>Opps !! something went wrong</Typography>
      </Grid>
    )
    }
    </div>
  );
};

export default InfiniteScroll;