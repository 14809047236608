import { Button, Dialog, Grid, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import rachnayeLogo from "../../../../assets/rachnayeLight.png";
import OrderStatusIcon from "./OrderStatusIconMapping";
import callApi from "../../../../utils/apiUtil";
import { PATHNAMES } from "../../../../utils/pathNames";
import { HTTP_METHODS } from "../../../../utils/constants";
import CircularLoader from "../../../common/CircularLoader";
import moment from "moment";
import { PaymentDetail } from "../../../../reducers/paymentAccounts/types";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "#f5f5f5",
        padding: "20px 10px",
        borderRadius: "4px",
    },
    detailsContainer: {
        [theme.breakpoints.down('md')]: {
            padding: "0.5rem",
        },
        [theme.breakpoints.up('md')]: {
            padding: "1rem",
        },

        '& .MuiTypography-root': {
            [theme.breakpoints.down('md')]: {
                fontSize: "0.8rem",
            },
            [theme.breakpoints.up('md')]: {
                fontSize: "1rem"
            },
        },
        '& .MuiButton-root': {
            [theme.breakpoints.down('md')]: {
                fontSize: "0.7rem",
                padding: "0.4rem"
            },
            [theme.breakpoints.up('md')]: {
                fontSize: "0.8rem",
                padding: "0.5rem"
            },
        },

        borderRadius: "4px",
        backgroundColor: "#f5f5f5",
        '& > .MuiGrid-item > .MuiTypography-root': {
            fontWeight: 'normal',
        },
    },
    bookImage: {
        [theme.breakpoints.down('md')]: {
            width: 135,
            height: 175
        },
        [theme.breakpoints.up('md')]: {
            width: 200,
            height: 240
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    deliveryStatus: {
        paddingInline: "1rem",
        marginTop: "1rem",
        borderRadius: "5px"
    },
    DialogContainer: {
        padding: "2rem",
        '& .MuiGrid-container': {
            justifyContent: 'space-between',
            marginTop: "1rem"
        },
    }
}))


const PortalSeriesDetails: React.FC = () => {
    const classes = useStyles()
    const { seriesId } = useParams();
    const location = useLocation();
    const order = location.state;
    const [subOrderDetails, setSubOrderDetails] = useState<any | null>(null)
    const [showBreakup, setShowBreakup] = useState(false)
    const [itemPaymentDetails, setItemPaymentDetails] = useState<PaymentDetail[]>([]);
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(true)

    if (!seriesId) {
        navigate(-1)
    }

    useEffect(() => {
        const fetchSeriesOrderDetails = async () => {
            setLoading(true)
            if (seriesId) {
                try {
                    const res = await callApi(PATHNAMES.GET_SERIES_SUB_ORDER_BY_ID(seriesId), HTTP_METHODS.GET);
                    const _itemPaymentDetails: PaymentDetail[] = [
                        { label: 'Item Price', value: `₹${res.data.subTotal}` },
                        { label: 'Taxes', value: `₹${res.data.taxAmount}` },
                        { label: 'Convenience Fee', value: `₹${res.data.convenienceFee}` },
                    ];
                    setItemPaymentDetails(_itemPaymentDetails)
                    setSubOrderDetails(res.data)
                } catch (error) {

                } finally {
                    setLoading(false)
                }
            }
        }

        fetchSeriesOrderDetails()

    }, [seriesId])

    const handleDialogClose = () => {
        setShowBreakup(false);
    };

    const handleDialogOpen = () => {
        setShowBreakup(true);
    };

    return (
        loading ? <CircularLoader />
            :
            subOrderDetails ?
                <Grid container className={classes.container} alignItems="center" justifyContent="center">

                    <Dialog onClose={() => handleDialogClose()} aria-labelledby="simple-dialog-title" open={showBreakup}>
                        <Grid className={classes.DialogContainer} container xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="h2" style={{ fontWeight: "800", color: "black" }}>
                                    Payment Information
                                </Typography>
                            </Grid>
                            {itemPaymentDetails.map((detail, index) => (
                                <Grid container key={index} xs={12}>
                                    <Grid item>
                                        <Typography variant="h3">{detail.label}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h3">{detail.value}</Typography>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid container xs={12}>
                                <Grid item>
                                    <Typography variant="h3" style={{ fontWeight: "bold" }}>SubTotal</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h3" style={{ fontWeight: "bold" }}> ₹{subOrderDetails.payment.amount}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Dialog>

                    <Grid container item xs={12} lg={8} alignItems="center" justifyContent="center" className={classes.detailsContainer} direction="column">
                        <Grid item>
                            <img
                                onClick={() => navigate(`/portal/rachnaye/series/read?id=${order.series.id}`)}

                                className={classes.bookImage} src={subOrderDetails.series.coverImage || rachnayeLogo} alt="Product" />
                        </Grid>
                        <Grid item>
                            <Typography variant="h3">
                                <span style={{ fontWeight: "500" }}>Title:</span> {subOrderDetails.series.title}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h3" style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: "24rem", // Adjust this value based on your layout
                            }}>
                                <span style={{ fontWeight: "500" }}>Description:</span> {subOrderDetails.series.description}
                            </Typography>
                        </Grid>


                        <Grid xs={12} container
                            style={{
                                backgroundColor: subOrderDetails.status === "CANCELLED" ? "#FF5C3E" : "#03a685",
                            }}
                            className={classes.deliveryStatus} direction="row" spacing={2} alignItems="center"
                        >
                            <Grid item>
                                <OrderStatusIcon status={subOrderDetails.status.toUpperCase()} style={{ fontSize: "50px", color: "white" }} />
                            </Grid>

                            <Grid item direction="column" style={{}}>
                                <Grid item>
                                    <Typography variant="h3" style={{ fontWeight: "500", color: "white" }}>
                                        {subOrderDetails.status.toUpperCase()}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h3" style={{ fontWeight: "500", color: "white" }}>
                                        on {moment(subOrderDetails.updatedAt).format('DD MMM, dddd, hh:mm A')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container xs={12} style={{ marginTop: "1rem", backgroundColor: "white", padding: "1rem" }} spacing={1} >
                            <Grid item xs={12}  >
                                <Typography variant="h3" style={{ fontWeight: "normal" }}>
                                    • &nbsp;  You Paid <span style={{ fontWeight: "bold" }}>₹{subOrderDetails.payment.amount}</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={12}  >
                                <Typography variant="h3" style={{ fontWeight: "normal" }}>
                                    • &nbsp;  Reference: #<span style={{ fontWeight: "400" }}>₹{subOrderDetails.referenceNumber}</span>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12} style={{ marginTop: "1rem", backgroundColor: "white", padding: "1rem" }} spacing={1} >
                            <Grid container xs={12} style={{ justifyContent: "space-between" }}>
                                <Grid item >
                                    <Typography variant="h3" style={{ fontWeight: "500" }}>
                                        Total Order Price: <span style={{ fontWeight: "bold" }}>₹{subOrderDetails.totalAmount}</span>
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <Button onClick={() => { handleDialogOpen() }} style={{ borderRadius: "4px", border: "1px solid black", padding: "0.5rem" }}>VIEW BREAKUP</Button>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} style={{ backgroundColor: "#f5f5f5", padding: "1rem", borderRadius: "4px", marginTop: "0.5rem" }} >
                                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                                    Paid by {subOrderDetails.payment.paymentGateway}
                                </Typography>
                            </Grid>
                        </Grid>

                        {subOrderDetails?.invoiceUrl && subOrderDetails?.invoiceUrl.length > 0 &&
                            <Grid container xs={12} style={{ marginTop: "1rem", backgroundColor: "white", padding: "1rem" }} spacing={1} >
                                <Grid container xs={12} spacing={1}>
                                    <Grid item xs={12}>
                                        <a href={subOrderDetails.invoiceUrl} download>
                                            <Button style={{ borderRadius: "4px", border: "1px solid black", width: "100%", padding: "1rem" }}>
                                                Get Invoice
                                            </Button>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                    </Grid>

                </Grid>
                :
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <Typography>Order Details not found</Typography>
                    </Grid>
                </Grid>

    )
}

export default PortalSeriesDetails;