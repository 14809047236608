import { Card, CardMedia, Dialog, DialogContent, DialogTitle, Fab, Grid, makeStyles, Typography } from '@material-ui/core';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import callApi from "../../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { ErrorHandler } from "../../../../utils/utils";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";

interface Iprops {
  previews: IPreview[]
  bookId: string;
  open: boolean;
  handleClose: () => void;
}
interface IPreview {
  idx: number;
  previewImage: string;
  previewImageUrl: string;
}
const useStyles = makeStyles(() => ({
  card: {
    margin: '1rem',
    height: '10rem',
    width: '6rem',
    backgroundColor: '#F5F5F5'
  },
  imageFooter: {
    padding: 3,
  },
  dialogTitle: {
    fontWeight: 500,
    marginTop: '0.6rem'
  },
  mainContainer: {
    marginTop: '1rem'
  },
  btnContainer: {
    marginTop: '2rem'
  },
  previewDiv: {
    cursor: 'move'
  }
}))

const ReArrangePreviews: React.FC<Iprops> = (props) => {
  const classes = useStyles()
  const [previews, setPreviews] = useState<IPreview[]>(props.previews)
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  //save reference for dragItem and dragOverItem
  const dragItem = React.useRef<any>(null)
  const dragOverItem = React.useRef<any>(null)

  useEffect(() => {
    setPreviews(props.previews)
  }, [props.previews])



  // handle dragNdrop sorting
  const handleSort = () => {
    // INITIAL preview array
    // [{ id: 0, key: 'A' }, { id: 1, key: 'B' }, { id: 2, key: 'C' }, { id: 3, key: 'D' }]

    // after placing key B before key A (STEP-1)
    // [ { id:1, key:'B' },{ id:0, key:'A'},{ id:2, key:'C'},{ id:3, key:'D'} ]

    //STEP -1
    //remove dragged item content from INITIAL array
    let _previews: IPreview[] = [...previews]
    const draggedItemContent = _previews.splice(dragItem.current, 1)[0]

    //switch the position ,  
    _previews.splice(dragOverItem.current, 0, draggedItemContent)


    // now reOrder the id of item (STEP-2)
    //after reordering 
    // [ { id:0, key:'B' },{ id:1, key:'A'},{ id:2, key:'C'},{ id:3, key:'D'} ]

    // set new sorted id to array elements to keep order in DB
    let lowerValue: number = 0
    let upperValue: number = 0

    if (dragItem.current < dragOverItem.current) {
      lowerValue = dragItem.current
      upperValue = dragOverItem.current
    } else {
      lowerValue = dragOverItem.current
      upperValue = dragItem.current
    }

    // take out all items in between, where swap happened between two values.
    const itemsInBetweenSwap: IPreview[] = _previews.filter((item) => item.idx >= lowerValue && item.idx <= upperValue)
    const remainingItems: IPreview[] = _.differenceBy(_previews, itemsInBetweenSwap, "idx")
    let swappedItemsWithIndexCorrection: IPreview[] = itemsInBetweenSwap.map((item, index) => {
      return { ...item, idx: index + lowerValue }
    })

    _previews = [...remainingItems, ...swappedItemsWithIndexCorrection]

    _previews.sort((previous, next) => previous.idx - next.idx)

    //reset the position ref
    dragItem.current = null
    dragOverItem.current = null

    //update the actual array
    setPreviews(_previews)
  }

  const handleUpdatePreview = async () => {
    try {
      // delete image Url before api call
      const formattedPreviews = previews.map((preview) => {
        return {
          idx: preview.idx,
          previewImage: preview.previewImage
        }
      })

      //prepare req.body
      const data = {
        previews: formattedPreviews
      }
      const response = await callApi(PATHNAMES.REARRANGE_PREVIEWS(props.bookId), HTTP_METHODS.PATCH, {
        body: JSON.stringify({ data })
      })
      if (response && response.success && response.message) {
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: response.message || "updated successfully",
        })
        props.handleClose()
        navigate(`/view-book?book=${props.bookId}`)
      } else {
        throw new ErrorHandler(response.message)
      }
    } catch (error: any) {
      props.handleClose()
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
    }
  }


  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={props.open}
      scroll='paper'
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.dialogTitle}> Re-Arrange Book Previews </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container xs={12} justify='center' className={classes.mainContainer}>
          {previews.map((data, index) => (
            <div key={index}
              className={classes.previewDiv}
              draggable
              onDragStart={(e) => (dragItem.current = index)}
              onDragEnter={(e) => (dragOverItem.current = index)}
              onDragEnd={handleSort}
              onDragOver={(e) => e.preventDefault()}>
              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  height="85%"
                  width="120"
                  alt={`Preview Image ${data.idx + 1}`}
                  title={`Preview Image ${data.idx + 1}`}
                  image={data.previewImageUrl}
                />
                <Grid container justify="center" >
                  <Typography className={classes.imageFooter}>{data.idx + 1}</Typography>
                </Grid>
              </Card>
            </div>
          ))}
        </Grid>
        <Grid container item justify="flex-end" xs={12} className={classes.btnContainer}>
          <Fab size="medium" variant="extended" onClick={handleUpdatePreview}>
            Update
          </Fab>
        </Grid>
      </DialogContent>
    </Dialog >
  )
}
export default ReArrangePreviews;