import { ThemeProvider } from "@material-ui/styles";
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import App from './App';
import { SnackBarProvider } from './components/common/SnackBarContext/SnackBarContext';
import theme from "./components/common/Theme/index";
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { setBackendUrl, setOrGetDeviceId } from './utils/utils';

setBackendUrl();
setOrGetDeviceId();

const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)
root.render(
  <StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackBarProvider>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </SnackBarProvider>
    </ThemeProvider>
  </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

