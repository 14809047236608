import { CardMedia, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useEffect, useState } from 'react';
import callApi from '../../../utils/apiUtil';
import { HTTP_METHODS, ResourceTypes } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import { uploadFileToS3 } from '../../../utils/s3Util';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import { DragDropImages } from '../../common/DragDropImages';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';


const useStyles = makeStyles((theme) => ({
  rachnayeCover: {
    height: "12rem",
    objectFit: "contain",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "6rem",
      height: "9rem",
    }
  },
}))

interface ICoverImage {
  key: string;
  imageUrl: string;
}

const updateTypes = {
  ADD: "add",
  REMOVE: "remove",
}
const CustormRachnayeCoverImages: React.FC = () => {
  const classes = useStyles()
  const snackbar = useSnackBar();

  const [coverImages, setCoverImages] = useState<ICoverImage[]>([]);
  const [open, setOpen] = useState<boolean>(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [deleteImage, setDeleteImage] = useState<string>("")
  const handleChange = () => setOpen(true)

  useEffect(() => {
    fetchCoverImages()
  }, [])


  const fetchCoverImages = async () => {
    const res = await callApi(PATHNAMES.GET_RACHNAYE_COVER_IMAGES(), HTTP_METHODS.GET)
    if (res.success && res.message) {
      setCoverImages(res.data)
    }
  }

  // upload images
  const uploadCoverImages = async (files: File[]) => {
    let keyArr: string[] = coverImages.map(obj => obj.key)

    setOpen(false)
    for (let k = 0; k < files.length; k++) {
      const key = await uploadFileToS3({ file: files[k], resourceType: ResourceTypes.RACHNAYE_COVER_IMAGE, isAdmin: true });
      keyArr.push(key)
    }
    await updateApiCall(keyArr, updateTypes.ADD)
    fetchCoverImages()
  }


  const updateApiCall = async (values: string[], updateType: string) => {
    const data = {
      images: values
    }
    const res = await callApi(PATHNAMES.UPDATE_RACHNAYE_COVER_IMAGES(), HTTP_METHODS.PUT, {
      body: JSON.stringify({ data })
    })
    if (res.success) {
      snackbar({
        variant: SnackBarVariant.SUCCESS,
        message: updateType === updateTypes.ADD ? "Images added successfully" : "Images deleted successfully"
      })
    } else {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: res.message || "Something went wrong"
      })
    }
    return
  }


  const handleDeleteImage = async () => {
    const keys: string[] = coverImages.map(obj => obj.key)
    const valuesToUpdate = keys.filter(value => value !== deleteImage)
    await updateApiCall(valuesToUpdate, updateTypes.REMOVE)
    fetchCoverImages()
    setOpenConfirmationDialog(false)
  }


  return (
    <Grid container xs={12} style={{ padding: "1rem 0rem 2rem 0rem" }} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" style={{ fontWeight: "bolder", display: "inline" }}>Custom Rachnaye Cover Images</Typography>
        <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer", display: "inline", padding: '1rem' }} color="primary"
          onClick={handleChange}>
          Add images
        </Typography>
      </Grid>
      <Grid container xs={12} spacing={1} style={{ padding: "1rem 1rem" }}>
        <Grid container xs={12} alignItems='center'>
          {coverImages.map(imgObj => {
            return (
              <div className={classes.rachnayeCover} style={{ padding: "0.6rem", position: "relative" }}>
                <CardMedia
                  component="img"
                  image={imgObj.imageUrl}
                  className={classes.rachnayeCover}
                />
                <div style={{ top: -8, right: -8, position: "absolute" }}>
                  <IconButton onClick={() => {
                    setDeleteImage(imgObj.key)
                    setOpenConfirmationDialog(true)
                  }}>
                    <CancelIcon color='primary' />
                  </IconButton>
                </div>
              </div>
            )
          })}
        </Grid>
      </Grid>

      <DragDropImages
        open={open}
        handleCloseDragDrop={() => setOpen(false)}
        handleSave={uploadCoverImages}
        initialFiles={[]}
        filesLimit={50}
        title={"Upload Image"}
      />
      <ConfirmationDialog
        isOpen={openConfirmationDialog}
        onCancel={() => setOpenConfirmationDialog(false)}
        onConfirm={handleDeleteImage}
        title={"Remove Cover Image"}
        description={`Are you sure, you want to delete this image?`}
      />
    </Grid>
  )
}

export default CustormRachnayeCoverImages;