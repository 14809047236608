import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import RachnayeGrid from "../Dashboard/RachnayeGrid";
import CircularLoader from "../../common/CircularLoader";

interface IPost {
  id: string,
  title: string;
  coverImage: string;
}

const PortalDigitalReads: React.FC = () => {
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [newSeries, setNewSeries] = useState<IPost[]>([]);
  const [newRachnaye, setNewRachnaye] = useState<IPost[]>([]);
  const [accoladesRachnaye, setAccoladesRachnaye] = useState<IPost[]>([]);
  const [narrationRachnaye, setNarrationRachnaye] = useState<IPost[]>([]);
  const [narrationSeries, setNarrationSeries] = useState<IPost[]>([]);
  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    fetchPostForPortal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState.id]);

  const fetchPostForPortal = async () => {
    setLoader(true)
    callApi(PATHNAMES.GET_NEW_SERIES_PORTAL(), HTTP_METHODS.GET).then((res) => {
      if (res && res.data) {
        setNewSeries(res.data)
      }
    })
    callApi(PATHNAMES.GET_NEW_RACHNAYE_PORTAL(mobileUserState.id), HTTP_METHODS.GET).then((res) => {
      if (res && res.data) {
        setNewRachnaye(res.data)
      }
    })
    callApi(PATHNAMES.GET_ACCOLADES_RACHNAYE_PORTAL(mobileUserState.id), HTTP_METHODS.GET).then((res) => {
      if (res && res.data) {
        setAccoladesRachnaye(res.data)
      }
    })

    callApi(PATHNAMES.GET_NARRATION_RACHNAYE_PORTAL(), HTTP_METHODS.GET).then((res) => {
      if (res && res.data) {
        setNarrationRachnaye(res.data)
      }
    })

    callApi(PATHNAMES.GET_NARRATION_SERIES_PORTAL(), HTTP_METHODS.GET).then((res) => {
      if (res && res.data) {
        setNarrationSeries(res.data)
        setLoader(false)
      }
    })
  }


  if (loader) {
    return (<CircularLoader message="Please wait .. bringing rachnaye of you likings" />)
  }

  return (
    <Grid container xs={12} justify="flex-start" >
      {newRachnaye.length > 0 && <Grid container xs={12} spacing={2} style={{ margin: "2rem 0rem", marginLeft: "1.5rem" }}>
        <Grid container xs={12}>
          <Grid item xs={11}>
            <Typography variant="h3" style={{ fontWeight: "bolder" }}>
              Rachnaye
            </Typography>
          </Grid>
          <Grid container xs={1}>
          </Grid>
        </Grid>
        <RachnayeGrid posts={newRachnaye} type='post' />
      </Grid>
      }

      {newSeries.length > 0 && <Grid container xs={12} spacing={2} style={{ margin: "2rem 0rem", marginLeft: "1.5rem" }}>
        <Grid container xs={12}>
          <Grid item xs={11}>
            <Typography variant="h3" style={{ fontWeight: "bolder" }}>
              Series
            </Typography>
          </Grid>
          <Grid container xs={1}>
          </Grid>
        </Grid>
        <RachnayeGrid posts={newSeries} type='series' />
      </Grid>
      }

      {accoladesRachnaye.length > 0 && <Grid container xs={12} spacing={2} style={{ margin: "2rem 0rem", marginLeft: "1.5rem" }}>
        <Grid container xs={12}>
          <Grid item xs={11}>
            <Typography variant="h3" style={{ fontWeight: "bolder" }}>
              Rachnaye By Accolades
            </Typography>
          </Grid>
          <Grid container xs={1}>
          </Grid>
        </Grid>
        <RachnayeGrid posts={accoladesRachnaye} type='post' />
      </Grid>
      }

      {narrationRachnaye.length > 0 && <Grid container xs={12} spacing={2} style={{ margin: "2rem 0rem", marginLeft: "1.5rem" }}>
        <Grid container xs={12}>
          <Grid item xs={11}>
            <Typography variant="h3" style={{ fontWeight: "bolder" }}>
              Narration Rachnaye
            </Typography>
          </Grid>
          <Grid container xs={1}>
          </Grid>
        </Grid>
        <RachnayeGrid posts={narrationRachnaye} type='post' />
      </Grid>
      }

      {narrationSeries.length > 0 && <Grid container xs={12} spacing={2} style={{ margin: "2rem 0rem", marginLeft: "1.5rem" }}>
        <Grid container xs={12}>
          <Grid item xs={11}>
            <Typography variant="h3" style={{ fontWeight: "bolder" }}>
              Narration Series
            </Typography>
          </Grid>
          <Grid container xs={1}>
          </Grid>
        </Grid>
        <RachnayeGrid posts={narrationSeries} type='series' />
      </Grid>
      }
    </Grid >
  );
};

export default PortalDigitalReads;
