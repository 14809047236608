import { createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import VideoUplaod from "../../../assets/VideoUpload.png";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { isValidVideo } from "../../../utils/bookUtils";
import { AUTOSAVE_TIME_IN_MILLISECOND, HTTP_METHODS, PostType, ResourceTypes, SeriesChapterPageType } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { deleteFileFromS3, uploadFileToS3 } from "../../../utils/s3Util";
import CircularLoader from "../../common/CircularLoader";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";

interface ICopyright {
  id: string;
  title: string;
  description: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    navBtn: {
      position: 'fixed',
      top: '50%',
      zIndex: 50,
      height: "100%",
    },
    stick: {
      position: "sticky",
      top: "4.2rem",
      zIndex: 1000,
    },
    selectedDiv: {
      backgroundColor: "#49767B",
      borderRadius: '3px',
      margin: '0rem 0rem 1rem 0rem',
      "& > *": {
        color: "#fff",
      }
    },
    pageTypeBox: {
      cursor: "pointer",
      border: '1px solid grey',
      borderRadius: '3px',
      margin: '0rem 0rem 1rem 0rem',
      "&:hover": {
        backgroundColor: "#DEDEDE"
      }
    },
    pageTypeBox2: {
      padding: '0.6rem 1rem',
    },
    qContainer: {
      "& .ql-container": {
        fontSize: "1rem",
        fontFamily: theme.typography.h2.fontFamily,
        border: 'none',
        padding: "1rem 0.6rem"
      },
      "& .ql-toolbar": {
        // FIXME:not showing toolbar because of compatibilty issue, if decided to show again just remove display none
        display: "none",
        // 
        border: 'none',
        padding: "1.5rem 0rem",
        position: "sticky",
        top: "3.6em",
        backgroundColor: "white",
        zIndex: 900,
        [theme.breakpoints.down('xs')]: {
          padding: "3rem 0rem",
        },
      },
      "& .ql-editor": {
        lineHeight: "30px",
        overflowY: "visible",
        padding: "0.6rem 0rem",
        "&:before": {
          position: "absolute",
          left: 10,
          fontFamily: theme.typography.h1.fontFamily,
          fontWeight: 300,
          fontStyle: "normal",
          color: "#A2A2A2"
        },
      }
    },
  })
);


let intervalId: any

// https://www.npmjs.com/package/react-quill#custom-toolbar:~:text=Custom%20Toolbar-,Default%20Toolbar%20Elements,-The%20Quill%20Toolbar
// keeping a empty array so toolbar will be empty to prevent adding any formatted text in editor since mobile app is not supporting formatted text
const QuillMdouleAndFormats = {
  quillToolBar: { toolbar: [] },
  quillFormats: []
}

const NewChapter: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const snackbar = useSnackBar();
  const inputFile = useRef<HTMLInputElement>(null);

  // content will be in json quill delta format 
  // it will change while writing in html string format becouse of compatibility and value change for component
  const [seriesId, setSeriesId] = useState<string>("");
  const [totalChapters, setTotalChapters] = useState<number>();

  const [id, setId] = useState<string>("");
  const [title, setTitle] = useState<string>("")
  const [content, setContent] = useState<any>('');
  const [jsonContent, setJsonContent] = useState<any>(); // to be updated in DB
  const [thumbnail, setThumbnail] = useState<string>() // value to be updated in DB
  const [thumbnailForPreview, setThumbnailForPreview] = useState<string>() // signed url to show img

  const [openPageSelectionModal, setOpenPageSelectionModal] = useState<boolean>(false);
  const [selectedPageType, setSelectedPageType] = useState<string>("")
  const [selectedPageTypeDivIdx, setSelectedPageTypeDivIdx] = useState<number>()

  // counter variables to keep eye on continuous draft update
  const [intervalChanged, setIntervalChanged] = useState<number>(0);
  const [callApiFn, setCallApiFn] = useState<boolean>(false); // used to make update api call inside interval fn
  const [loading, setLoading] = useState<boolean>(false);

  const [nextChapterId, setNextChapterId] = useState<string>("")
  const [postType, setPostType] = useState<string>(PostType.TEXT); // serieType is same for postType values
  const [narrationVideo, setNarrationVideo] = useState<string>() // value to be updated in DB
  const [narrationVideoForPreview, setNarrationVideoForPreview] = useState<string>() // signed url to show img



  // when mounts
  useEffect(() => {
    // since updating directly via setInterval is haywired.. use counter to update/create post data
    intervalId = setInterval(() => setIntervalChanged(v => v + 1), AUTOSAVE_TIME_IN_MILLISECOND)

    // stop updating content when unmount
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const _seriesId = params.get("seriesId");
      const _chapterId = params.get("chapterId");
      const _pageType = params.get("page");
      if (_seriesId) {
        fetchSeriesInfo(_seriesId)
      }
      if (_seriesId && _chapterId) {
        setLoading(true)
        fetchChapterInfo(_seriesId, _chapterId)
        setLoading(false)
      }
      // when user come to create a chapter it won't have pagetype selected , so ask to select
      if (!_pageType || !Object.values(SeriesChapterPageType).includes(_pageType)) {
        setOpenPageSelectionModal(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, mobileUserState]);

  useEffect(() => {
    createOrupdateChapter()
    setCallApiFn(v => false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalChanged])



  const fetchSeriesInfo = (seriesId: string) => {
    callApi(PATHNAMES.GET_SERIES_BY_ID(mobileUserState.id, seriesId), HTTP_METHODS.GET).then(res => {
      if (res && res.success && res.data) {
        setSeriesApiData(res.data)
      }
    }).catch(error => {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Error while fetching series"
      })
      // if series not found or eny error occurs then route to
      navigate(`/portal/myRachnaye`, {replace:true})
    })
  }

  const fetchChapterInfo = (seriesId: string, chapterId: string) => {
    callApi(PATHNAMES.GET_SERIES_CHAPTER_BY_ID(mobileUserState.id, seriesId, chapterId), HTTP_METHODS.GET).then(res => {
      if (res && res.success && res.data) {
        setChapterApiData(res.data)
      }
    }).catch(error => {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Error while fetching series"
      })
      // if series not found or eny error occurs then route to
      navigate(`/portal/rachnaye/series/edit?id=${seriesId}`, {replace:true})
    })
  }

  const setSeriesApiData = (series: any) => {
    const { id, chapters, type } = series
    setSeriesId(v => id);
    setTotalChapters(v => chapters.length)
    setPostType(v => type)
  }


  const setChapterApiData = (chapter: any) => {
    const { seriesId, id, title, content, coverImage, chapterType, mediaUrl } = chapter
    setId(v => id);
    setSeriesId(v => seriesId);
    setTitle(v => title)
    if (content) {
      setContent((v: any) => JSON.parse(content))
    }
    setSelectedPageType(v => chapterType)
    setNextChapterId(v => chapter.nextChapterId)

    if (coverImage) {
      setThumbnailForPreview(v => coverImage)
      const urlFinder = ".com/"
      const splittedText = coverImage.split(urlFinder)
      if (splittedText.length > 1) {
        // amazom url + image key
        setThumbnail(v => splittedText[1])
      }
    }
    if (mediaUrl) {
      setNarrationVideoForPreview(v => mediaUrl)
      const urlFinder = ".com/"
      const splittedText = mediaUrl.split(urlFinder)
      if (splittedText.length > 1) {
        // amazom url + image key
        setNarrationVideo(v => splittedText[1])
      }
    }

  }

  const handlePageSelection = (pageTypeKey: string, idx: number) => {
    const pageType: any = SeriesChapterPageType[pageTypeKey]
    setSelectedPageTypeDivIdx(v => idx)
    setSelectedPageType(pageType)
  }


  const createOrupdateChapter = async () => {

    if (!callApiFn) {   // callApIFn becomes true whenever there is data to be updated or new data gets created
      return
    }
    if (id) {      // if post has id, then update other data which gets added or modified

      await updateChapterContent()
      return
    }
    if (postType === PostType.TEXT) {
      if (title && jsonContent) {  // id is not peresent in content means , document has not been saved in DB at all , create new 
        await createChapter()
        return
      }
    }
    if (postType === PostType.VIDEO) {
      if (title && narrationVideo) {  // id is not peresent in content means , document has not been saved in DB at all , create new 
        await createChapter()
        return
      }
    }
  }


  const createChapter = async () => {
    const data = {
      title,
      content: jsonContent,
      mediaUrl: narrationVideo ? narrationVideo : undefined, //TODO: fix o api side ""
      chapterType: selectedPageType
    }
    const res = await callApi(PATHNAMES.CREATE_CHAPTER(seriesId), HTTP_METHODS.POST, {
      body: JSON.stringify({ data }),
    })
    if (res && res.success && res.data) {

      navigate(`/portal/rachnaye/series/chapter/edit?seriesId=${res.data.seriesId}&chapterId=${res.data.id}&page=${res.data.chapterType}`, {replace:true})

    } else {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: res.message || "Error while creating creating"
      })
    }
  }

  const updateChapterContent = async () => {
    const data: any = {
      title,
      content: jsonContent,
      coverImage: thumbnail ? thumbnail : "",
      mediaUrl: narrationVideo ? narrationVideo : undefined
    }
    const res = await callApi(PATHNAMES.UPDATE_CHAPTER(seriesId, id), HTTP_METHODS.PUT, {
      body: JSON.stringify({ data }),
    })
    if (!res || !res.success) {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: res.message || "Error while updating chapter"
      })
    }
  }

  const uploadDocument = async (file: File) => {
    if (thumbnail) {
      await deleteFileFromS3(thumbnail)
    }
    const key = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.USER_POST,
      isAdmin: false,
      publisherId: mobileUserState.id
    });
    if (key) {
      setThumbnail(key)
      setCallApiFn(v => true)
    }
    const res = await callApi(PATHNAMES.GET_SIGNED_URL, HTTP_METHODS.POST, {
      body: JSON.stringify({ data: { key } })
    });

    if (res && res.success && res.data) {
      setThumbnailForPreview(res.data)
    }
  }

  const uploadVideoDocument = async (file: File) => {
    if (narrationVideo) {
      await deleteFileFromS3(narrationVideo)
    }
    const key = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.USER_POST,
      isAdmin: false,
      publisherId: mobileUserState.id
    });
    if (key) {
      setNarrationVideo(key)
      setCallApiFn(v => true)
    }
    const res = await callApi(PATHNAMES.GET_SIGNED_URL, HTTP_METHODS.POST, {
      body: JSON.stringify({ data: { key } })
    });

    if (res && res.success && res.data) {
      setNarrationVideoForPreview(res.data)
    }
  }

  const handleRemoveVideo = async () => {
    if (narrationVideo) {
      await deleteFileFromS3(narrationVideo)
      setNarrationVideoForPreview("")
      setNarrationVideo("")
    }
  }

  const handleOpenFileExplorer = () => {
    const videoInput = document.getElementById("videoSelector")
    videoInput?.click()
  }


  const button1Disable = () => {
    if (postType === PostType.TEXT) {
      return !(title && content)
    }
    if (postType === PostType.VIDEO) {
      return !(title && narrationVideo)
    }
    if (!id) {
      return true
    }
  }

  const handlePublishButton1Click = () => {
    navigate(`/portal/rachnaye/series/publish?seriesId=${seriesId}`)
  }

  const handleAddNewChapter = () => {
    resetToInitialState()
    navigate(`/portal/rachnaye/series/chapter/create?seriesId=${seriesId}`)
  }


  const handleGoForward = () => {
    resetToInitialState()
    navigate(`/portal/rachnaye/series/chapter/edit?seriesId=${seriesId}&chapterId=${nextChapterId}`)
  }

  const handleGoBackword = () => {
    resetToInitialState()
    navigate(1)
  }


  const resetToInitialState = () => {
    setSeriesId("")
    setTotalChapters(v => undefined)
    setPostType(v => PostType.TEXT)
    setNarrationVideo(v => undefined)
    setNarrationVideoForPreview(v => undefined)
    setId("")
    setTitle("")
    setContent("")
    setJsonContent((v: any) => undefined)
    setThumbnail(v => undefined)
    setThumbnailForPreview(v => undefined)
    setOpenPageSelectionModal(false)
    setSelectedPageType("")
    setSelectedPageTypeDivIdx(v => undefined)
    setIntervalChanged(0)
    setCallApiFn(false)
    setLoading(false)
  }

  if (loading) {
    return <CircularLoader />
  }

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center" style={{ padding: "1rem 3rem", }}>
      <Grid container xs={12} sm={6} md={6} lg={6} xl={6} ></Grid>  {/* container seperator */}
      <Grid container xs={12} sm={6} md={6} lg={6} xl={6} justify='flex-end' className={classes.stick}>
        <Grid container xs={5} md={12} justify='flex-end' >
          <Fab
            variant="extended"
            size="medium"
            style={{ marginRight: "1rem" }}
            disabled={button1Disable()}
            onClick={handleAddNewChapter}
          >
            <Typography variant="body1">+ Add New Part</Typography>
          </Fab>
          <Fab
            variant="extended"
            size="medium"
            onClick={handlePublishButton1Click}
            disabled={button1Disable()}
          >
            <Typography variant="body1">Proceed</Typography>
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          placeholder="Give a Title"
          fullWidth
          variant="standard"
          inputProps={{ style: { fontSize: "1.6rem" } }} // font size of input text
          value={title}
          onChange={(e) => {
            let newTitle: string
            if (!title && e.target.value) {
              const value: string = e.target.value
              const firstLetter = value.slice(0, 1).toUpperCase()
              const remainingLetter = value.slice(1)
              newTitle = firstLetter + remainingLetter
            } else {
              newTitle = e.target.value
            }
            setTitle(newTitle)
            setCallApiFn(v => true)
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ padding: "2rem 0rem" }}>
        {postType === PostType.TEXT ?
          <ReactQuill
            preserveWhitespace
            theme="snow"
            value={content}
            modules={QuillMdouleAndFormats.quillToolBar}
            formats={QuillMdouleAndFormats.quillFormats}
            onChange={(updatedValue, delta, source, editor) => {
              setContent((v: any) => updatedValue)
              setJsonContent((v: any) => JSON.stringify(editor.getContents().ops))
              setCallApiFn(v => true)
            }}
            className={classes.qContainer}
            placeholder="Write here .. "
          />
          :
          narrationVideoForPreview ?
            <Grid xs={12} container alignItems="center">
              <Grid container xs={12} sm={9}>
                <video
                  controls
                  controlsList="nodownload"
                  style={{ width: "90%", height: "60vh", borderRadius: "10px", marginTop: "1rem" }}
                  src={narrationVideoForPreview}
                />
              </Grid>
              <Grid container alignItems="center" spacing={2} direction="column" xs={12} sm={3}>
                <Grid item xs={12}>
                  <Fab variant='extended' size="medium" onClick={handleOpenFileExplorer}>Change video</Fab>
                </Grid>
                <Grid item xs={12}>
                  <Fab variant='extended' size="medium" onClick={handleRemoveVideo}>Remove video</Fab>
                </Grid>
              </Grid>
            </Grid> :


            <Grid id='v1' container justify="center"
              onClick={handleOpenFileExplorer}
              style={{ borderRadius: '5px', border: "2px dashed #FB8F67", minHeight: "40vh", marginTop: "4rem", padding: "3rem 1rem", cursor: "pointer" }} >
              <Grid container xs={12} justify="center" alignItems="center">
                <img src={VideoUplaod} height='50px' alt="video" />
              </Grid>
              <Grid item xs='auto'>
                <Typography align="center" variant='h2'>Upload you narration video here</Typography>
              </Grid>
            </Grid>}
      </Grid>
      <input
        id='videoSelector'
        type="file"
        hidden
        onClick={(e: any) => {
          e.target.value = null;
        }}
        accept="video/mp4,video/x-m4v,video/*"
        onChange={(e: any) => {
          const isVideo = isValidVideo(e.target.files[0])
          if (!isVideo) {
            snackbar({
              message: "Only mp4/m4v/mkv videos are allowed",
              variant: SnackBarVariant.WARNING
            })
          }
          // In mobile app there is a limit of 50 mb, webapp side restriction is stopped for time being.
          // const size = e?.target?.files[0]?.size || 0
          // const sizeInMB = (size / (1024 * 1024)).toFixed(2);
          // if (parseInt(sizeInMB) > MAXIMUM_VIDEO_SIZE_LIMIT) {
          //   snackbar({
          //     message: `Maximum file size should be ${MAXIMUM_VIDEO_SIZE_LIMIT} MB`,
          //     variant: SnackBarVariant.WARNING,
          //   })
          // return
          // }
          uploadVideoDocument(e.target.files[0])
        }}
      />

      <Dialog
        open={openPageSelectionModal}
        onClose={() => setOpenPageSelectionModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle>
          <Typography variant="h3">
            Select what you want to add
          </Typography>
        </DialogTitle>
        <DialogContent>
          {Object.keys(SeriesChapterPageType).map((pageType, idx) => {
            return (
              <Grid key={pageType} id={pageType} container className={idx === selectedPageTypeDivIdx ? classes.selectedDiv : classes.pageTypeBox}
                onClick={() => handlePageSelection(pageType, idx)}
              >
                <Grid item className={classes.pageTypeBox2}>
                  <Typography variant='body1'>{pageType.replace("_", " ")}</Typography>
                </Grid>
              </Grid>)
          })}
        </DialogContent>
        <DialogActions>
          <Fab variant="extended" size="small"
            onClick={() => setOpenPageSelectionModal(false)}
            disabled={!selectedPageType}
            style={{ marginRight: 10 }}
          >
            Proceed
          </Fab>
        </DialogActions>
      </Dialog>

      {/* TODO: implement nav */}

      {/* <div className={classes.navBtn} style={{ left: 30, }} onClick={handleGoBackword}>
        <IconButton style={{ backgroundColor: "#ededed" }}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      {nextChapterId &&
        <div className={classes.navBtn} style={{ right: 40, }}>
          <IconButton style={{ backgroundColor: "#ededed" }} onClick={handleGoForward}>
            <ChevronRightIcon />
          </IconButton>
        </div>
      } */}
    </Grid >
  );
};

export default NewChapter;

