import { CircularProgress, Grid, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IBook } from "../../../reducers/books/types";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import BookImage from "../../common/BookImage";
import InfiniteScroll from "../MyRachnaye/InfiniteScroll";

const useStyles = makeStyles(() => ({
  hoverProperties: {
    transition: "all 0.5s ease-out",
    "&:hover": {
      transform: "scale3d(1.10, 1.10, 1)"
    },
  },
}));

const CategoryBooks: React.FC = () => {
  const classes = useStyles()
  const [books, setBooks] = useState<IBook[]>([]);
  const [category, ] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    fetchBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const fetchBooks = async () => {
    const params = new URLSearchParams(location.search);
    const name = params.get("name");
    if (name) {
      const res = await callApi(
        PATHNAMES.LIST_BOOKS_BY_CATERGORY_PORTAL(name),
        HTTP_METHODS.GET,);
      if (res && res.data) {
        setBooks(res.data)
      }
      return res.data;
    }
  };

  if (category === null) {
    return <CircularProgress />
  }

  return (
    <Grid container xs={12}>
      <Grid container xs={12} style={{ marginBottom: "1rem" }}>
        <Typography variant='h2' style={{ fontWeight: "bolder" }}>
          {category}
        </Typography>
      </Grid>
      <InfiniteScroll
        pageNumber={0}
        pageSize={20}
        apiCall={fetchBooks}
        msgWhenDataIsEmpty="No books available"
        noMoreDataMessage="No more data to load"

      >
        <Grid container xs={12} style={{ margin: "2rem 0rem", gap: "6rem 2rem" }}>
          {books.map((book, index) => {
            return (
              <Grid item xs='auto' >
                <BookImage book={book} className={classes.hoverProperties} showTitle />
              </Grid>
            )
          })}
        </Grid>
      </InfiniteScroll>

    </Grid>
  )
}

export default CategoryBooks;