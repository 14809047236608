import { Grid, Paper, Typography } from "@material-ui/core";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import moment from "moment";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IInvoice } from "../../../reducers/invoices/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../utils/common.interface";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { DataTable } from "../../common/Datatable/Datatable";
import { Pagination } from "../../common/Datatable/types";

const columns: any = [
  {
    label: "Invoice Ref",
    name: "referenceNumber"
  },
  {
    label: "Date",
    name: "invoiceDate",
    options: {
      customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY, h:mm A")
    }
  },
  {
    label: "Amount",
    name: "totalAmount"
  },
  {
    label: "Status",
    name: "status",
    options: {
      customBodyRender: (data: string) => data?.toUpperCase() || ""
    }
  },
  {
    label: "Actions",
    name: "invoiceUrl",
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({ style: { fontWeight: 600, fontSize: '14px' } }),
      customBodyRender: (data: string) => {
        if (data) {
          return <DownloadIcon
            color="primary"
            onClick={() => {
              const link = document.createElement("a");
              link.download = `invoice.pdf`;
              link.href = data;
              link.click();
            }} />
        }
      }
    }
  }
]

const Billing: React.FC = () => {
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [totalInvoices, setTotalInvoices] = useState<number>(0);
  const user = useSelector((state: IAppState) => state.user);

  useEffect(() => {
    if (user && user.spaceId) {
      fetchInvoices({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE, })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])


  const fetchInvoices = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args

    callApi(PATHNAMES.GET_SPACE_INVOICES(user.spaceId, pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then(res => {
      if (res.success && res.data) {
        setInvoices(res.data.invoices);
        setTotalInvoices(res.data.toatalInvoices)
      }
    })
  }

  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    searchPlaceholder: "Search by invoice ref, date, amount or status",
    count: totalInvoices,
  };

  return (
    <Grid container xs={12}>
      <Paper elevation={0} style={{ width: "100%", padding: "1rem" }}>
        <Grid container xs={12}>
          <Typography variant="h2" style={{ fontWeight: "bolder" }}>
            Invoices
            <Typography variant="h2" color="primary" style={{ display: "inline", marginLeft: "1rem" }}>
              {totalInvoices}
            </Typography>
          </Typography>
        </Grid>
        <Grid container xs={12}>
          <DataTable
            title={""}
            rows={invoices}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchInvoices}
          />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Billing;