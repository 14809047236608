import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { IContactNumber } from "../../reducers/orders/types";
import _ from "lodash"

interface IProps {
  placeholder?: string;
  value?: string;
  required?: boolean;
  country?: ICountry
  enableAreaCodes?: boolean;
  handleOnChange: (data: IContactNumber) => void;
  handleError: (error: boolean) => void;
}
interface ICountry {
  prefferedCountry?: string;
  onlyCountries?: string[];
  preferredCountries?: string[];
  excludeCountries?: string[];
}

const re = new RegExp(/\./, "g");

export const PhoneNumber = (props: IProps) => {
  const [mobileLength, setMobileLength] = useState<number>(0)
  const [errorMsg, setErrorMsg] = useState<string>("")
  const [error, setError] = useState<boolean>(false)

  const isValidNumber = (inputNumber: string, country: any, countries: any) => {
    const validNumber: boolean = countries.some((country: any) => {
      return _.startsWith(inputNumber, country.dialCode);
    });
    return validNumber
  }

  const handleErrorMessage = (value: string, country: CountryData) => {
    const format = country.format // eg. +.. .....-.....  for +91 99999-88888
    const totalDots = format.match(re)?.length || 0  //12 
    const dialCodelength = country?.dialCode.length // 2
    const requiredPhoneNumberLength = totalDots - value.length
    const phoneNumberLength = totalDots - dialCodelength // without countrycode
    // for most of the countries
    if (phoneNumberLength === 10) {
      setErrorMsg(` Phone Number must be ${totalDots - dialCodelength} digits long`)
      setMobileLength(requiredPhoneNumberLength)
    } else {
      // dont show error msg for other countries ,
      //  since we may not know if they accepet less or more than 10 digits
      setErrorMsg("")
      setMobileLength(0)
    }
  }


  const DEFAULT_COUNTRY = 'in'
  return (
    <div>
      <PhoneInput
        country={props.country?.prefferedCountry || DEFAULT_COUNTRY}
        value={props.value || ""}
        inputStyle={{
          width: "100%",
        }}
        specialLabel=''
        placeholder={props?.placeholder || 'Enter Mobile number'}
        inputProps={{
          enableSearch: true,
          required: props.required || false,
          autoFormat: true,
          enableAreaCodes: props.enableAreaCodes || true,
          enableLongNumbers: true
        }}
        isValid={isValidNumber}
        defaultErrorMessage="Not a valid number"
        onlyCountries={props.country?.onlyCountries}
        preferredCountries={props.country?.preferredCountries}
        excludeCountries={props.country?.excludeCountries}
        onBlur={(event: React.FocusEvent<HTMLInputElement>, data: CountryData) => {
          if (mobileLength) {
            setError(true)
            props.handleError(true)
          } else {
            setError(false)
            props.handleError(false)
          }
        }}
        onFocus={() => setError(false)}
        onChange={(value: string, country: CountryData) => {
          // check for error message
          handleErrorMessage(value, country)
          // update field value
          const contactNumber = {
            countryCode: country.dialCode,
            phoneNumber: value.slice(country.dialCode.length)
          }
          props.handleOnChange(contactNumber)
        }}
      />
      {error && <Typography variant='h5' style={{ color: 'red', padding: '0.4rem' }}>{errorMsg}</Typography>}
    </div>
  );
};

export default PhoneNumber;