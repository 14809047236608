import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Fab,
  makeStyles,
  Box,
  FormControl,
  MenuItem,
  Select
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-material-ui";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { IContactNumber } from "../../../reducers/distributors/types";
import callApi from "../../../utils/apiUtil";
import { DEFAULT_COUNTRY_CODE, HTTP_METHODS, NUMBERS_REGEX } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";

interface IProps {
  open: boolean;
  handleClose: () => void;
  createUser: (user: IUserCreate) => void;
}

export interface IUserCreate {
  emailId: string;
  firstName: string;
  lastName: string;
  roleId: string;
  primaryLanguage: string;
  contactNumber: IContactNumber
}

const useStyles = makeStyles(() => ({
  selectMenu: { marginTop: "0.5rem", width: "100%", height: "3rem" },
  typography: { color: "#808080" },
  mainContainer: { marginBottom: "1rem" },
  errorField: { marginTop: '1rem' },
  phoneNumberField: { marginTop: "1.68rem" },
  form: { width: '100%' }
}))

const AddSecondaryUser: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState<IUserCreate>({
    emailId: "",
    firstName: "",
    lastName: "",
    roleId: "",
    primaryLanguage: "English",
    contactNumber: {
      countryCode: DEFAULT_COUNTRY_CODE,
      phoneNumber: "",
    }
  });

  useEffect(() => {
    callApi(PATHNAMES.GET_ALL_ROLES, HTTP_METHODS.GET).then((res) => {
      if (res && res.data && res.data) {
        setRoles(res.data)
      }
    })
  }, [])

  const validationSchema = Yup.object().shape({
    emailId: Yup.string().trim().email().required("Email is required"),
    firstName: Yup.string().trim()
      .min(3, "First name is too short - should be 3 chars minimum")
      .max(20, "First name is too long - should be 20 chars maximum")
      .required(),
    lastName: Yup.string().trim()
      .min(3, "Last name is too short - should be 3 chars minimum")
      .max(20, "Last name is too long - should be 20 chars maximum")
      .required(),
    roleId: Yup.string().trim().required("Role is required"),
    primaryLanguage: Yup.string().trim().required("Primary Language is required."),
    contactNumber: Yup.object().shape({
      phoneNumber: Yup.string().trim().required("PhonNumber is required.")
        .matches(NUMBERS_REGEX, "Only Numbers are allowed, no other character please")
        .min(10, 'Must be exactly 10 digits')
        .max(10, 'Must be exactly 10 digits'),
      countryCode: Yup.string().trim().required("Countrycode is required."),
    }).required()
  });

  // disable will be false when values are valid and true
  const isDisable = () => !validationSchema.isValidSync(user)

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography variant="h3">
              Create User
            </Typography>
          </Grid>
        </Grid>

      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container xs={12}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={user}
            onSubmit={(data, { setSubmitting }) => {
              props.createUser(user);
              props.handleClose();
              setUser({
                emailId: "",
                firstName: "",
                lastName: "",
                roleId: "",
                primaryLanguage: "English",
                contactNumber: {
                  countryCode: DEFAULT_COUNTRY_CODE,
                  phoneNumber: ""
                }
              });
              setSubmitting(false);
            }}
          >
            {() => (
              <Form className={classes.form}>
                <Grid item container xs={12} spacing={1} className={classes.mainContainer}>
                  <Grid item xs={4}>
                    <Field
                      component={InputField}
                      fullWidth
                      required
                      label="EmailId"
                      placeholder="EmailId"
                      name="emailId"
                      InputProps={{
                        value: user.emailId,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setUser({
                            ...user,
                            emailId: e.target.value
                          })
                        }
                      }}
                    ></Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={InputField}
                      fullWidth
                      required
                      label="First Name"
                      placeholder="First Name"
                      name="firstName"
                      InputProps={{
                        value: user.firstName,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setUser({
                            ...user,
                            firstName: e.target.value
                          })
                        }
                      }}
                    ></Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={InputField}
                      fullWidth
                      required
                      label="Last Name"
                      placeholder="Last Name"
                      name="lastName"
                      InputProps={{
                        value: user.lastName,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setUser({
                            ...user,
                            lastName: e.target.value
                          })
                        }
                      }}
                    ></Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h4" className={classes.typography}>
                      Role*
                    </Typography>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        required
                        value={user.roleId}
                        label="Role"
                        onChange={(e) => {
                          if (e.target.value && typeof e.target.value === "string") {
                            setUser({
                              ...user,
                              roleId: e.target.value
                            })
                          }
                        }}
                        className={classes.selectMenu}
                      >
                        {roles.map(
                          (role: any, key: number) => {
                            return (
                              <MenuItem value={role.id} key={key}>
                                {role.name.toUpperCase()}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={InputField}
                      fullWidth
                      required
                      variant="outlined"
                      label="Phone Number"
                      placeholder="Phone Number"
                      name="contactNumber.phoneNumber"
                      inputProps={{ maxLength: 10 }}
                      InputProps={{
                        value: user.contactNumber.phoneNumber,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setUser({
                            ...user,
                            contactNumber: {
                              ...user.contactNumber,
                              phoneNumber: e.target.value.trim()
                            }
                          })
                        }
                      }}
                      className={classes.phoneNumberField}
                    ></Field>
                  </Grid>
                  <Grid item xs={12} className={classes.errorField}>
                    {isDisable() ?
                      <Typography variant="h5" color='primary' >
                        * Fill all fields to proceed.
                      </Typography>
                      : <></>
                    }
                  </Grid>
                </Grid>
                <Grid container item xs={12} justify="flex-end">
                  <Fab
                    size="medium"
                    variant="extended"
                    className="blackBackButton"
                    onClick={props.handleClose}
                  >
                    Cancel
                  </Fab>
                  <Box pl={2}></Box>
                  <Fab
                    size="medium"
                    variant="extended"
                    type="submit"
                  >
                    Proceed
                  </Fab>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddSecondaryUser;