import { Chip, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import callApi from '../../../utils/apiUtil';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';


const useStyles = makeStyles(() => ({
  autocompleteFont: {
    fontSize: '1rem',
  },
  textArea: {
    fontSize: '1rem',
    minWidth: "20rem"
  },
  tagsChip: {
    fontSize: '1.2rem',
    fontWeight: 500,
    borderRadius: 20,
    background: "#e1e1e1",
    border: "1px solid #cacaca",
    "&.MuiAutocomplete-tag": {
      margin: "10px 0px 5px 10px"
    }
  },
}))

const TrendinSearches: React.FC = () => {
  const classes = useStyles()
  const snackbar = useSnackBar();
  const [edit, setEdit] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)

  const [trendingSearches, setTrendingSearches] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");


  //fetch suggested keywords list for autocomplete
  useEffect(() => {
    if (inputValue) {
      fetchKeywordsSuggestions(inputValue)
    }
  }, [inputValue]);

  //fetch pre created trending words
  useEffect(() => {
    fetchTrendingSearchesList()
  }, []);


  //get keywords suggestion for input value
  const fetchKeywordsSuggestions = (searchValue: string) => {
    callApi(PATHNAMES.GET_KEYWORD_SUGGESTIONS(searchValue), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setSuggestions(res.data)
      }
    })
  }

  //get trending searches
  const fetchTrendingSearchesList = () => {
    callApi(PATHNAMES.GET_LIST_OF_TRENDING_SEARCHES(), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setTrendingSearches(res.data)
        // if no pre data to show, set it in edit mode.
        if (!res.data.length) {
          setEdit(true)
        }
      }
    })
  }

  const handleChange = () => setEdit(true)
  const handleCancel = () => {
    if (!trendingSearches.length) {
      snackbar({
        message: "Not a valid action",
        variant: SnackBarVariant.INFORMATION
      })
      return
    }
    fetchTrendingSearchesList()
    return setEdit(false)
  }

  const updateTrendinSearches = async () => {
    if (updating) {
      return
    }

    if (!trendingSearches.length) {
      snackbar({
        message: "Add words to save",
        variant: SnackBarVariant.INFORMATION
      })
      return
    }
    //  user has not added new word properly, he might have forgot to click enter to add a word
    if (inputValue && !trendingSearches.includes(inputValue)) {
      snackbar({
        message: "Click Enter key to add words",
        variant: SnackBarVariant.INFORMATION
      })
      return
    }

    setUpdating(true)
    const res = await callApi(PATHNAMES.UPDATE_LIST_OF_TRENDING_SEARCHES(), HTTP_METHODS.PUT, {
      body: JSON.stringify({
        data: { newList: trendingSearches }
      })
    })

    if (res && res.success && res.data) {
      setTrendingSearches(res.data)
      snackbar({
        message: res.message || "Trending words updated successfully",
        variant: SnackBarVariant.SUCCESS
      })
    } else {
      snackbar({
        message: res.message || "Something went wrong",
        variant: SnackBarVariant.ERROR
      })
    }
    setUpdating(false)
    setEdit(false)
  }

  return (
    <Grid container xs={12} style={{ padding: "1rem 0rem 2rem 0rem" }} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" style={{ fontWeight: "bolder" }}>Trending Searches on Platform</Typography>
      </Grid>
      <Grid container xs={12} spacing={2} style={{ padding: "1rem 1rem" }}>
        <Grid item xs={'auto'}>
          {/* not in edit mode and have valid list  */}
          {!edit ?
            trendingSearches.map(item => {
              return (
                <Chip
                  classes={{ root: classes.tagsChip }}
                  style={{ margin: '0.5rem 0.5rem 1rem 0rem', background: "#C4C4C4" }}
                  label={item}
                />
              )
            })
            :
            <Autocomplete
              multiple
              classes={{ input: classes.autocompleteFont }}
              onInputChange={(e, value) => setInputValue(value)}
              id="tags-filled"
              options={suggestions}
              value={trendingSearches}
              freeSolo
              filterSelectedOptions
              onChange={(event, newValue) => setTrendingSearches(newValue.filter((item) => item.trim().length))}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    classes={{ root: classes.tagsChip }}
                    label={option}
                    deleteIcon={<CancelIcon
                      onMouseOver={(e) => e.currentTarget.style.color = 'red'}
                      onMouseOut={(e) => e.currentTarget.style.color = '#5a5a5a'}
                      style={{ fontSize: 14, color: '#5a5a5a' }} />}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.textArea}
                  variant="outlined"
                  label="Trending Searches"
                  placeholder="Add new one"
                />
              )}
            />

          }
        </Grid>

        <Grid item container xs={1} justify='flex-start' alignContent="center">
          {edit ?
            <Grid item xs={'auto'}>
              <Typography variant="h4" color='primary' style={{ display: "inline", fontWeight: 600, cursor: "pointer", marginRight: "1rem" }}
                onClick={updateTrendinSearches}>
                Save
              </Typography>
              <Typography variant="h4" style={{ display: "inline", fontWeight: 600, cursor: "pointer" }}
                onClick={handleCancel}>
                Cancel
              </Typography>
            </Grid>
            :
            <Grid item xs={12}>
              <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer" }} color="primary"
                onClick={handleChange}>
                Change
              </Typography>
            </Grid>
          }
        </Grid>
      </Grid>


    </Grid>
  )
}

export default TrendinSearches;