//DropDown Button Component (1,3,6 months)-----

import React from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { InputBase, MenuItem } from '@material-ui/core';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

interface IProps {
  value: string | number;
  onChange: (event: any) => void;
  type: string;  // default , lifetime
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid black',
      fontSize: 16,
      padding: '4px 26px 4px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    iconStyle: {
      marginRight: "4px",
      marginLeft: "-5px",
      fontSize: "19px",
      marginBottom: "-3px"
    }
  }),
);


export default function CustomizedSelects(props: IProps) {
  const classes = useStyles();

  if (props.type === 'lifetime') {
    return (
      <div style={{ marginTop: "-18px", marginLeft: "8px" }}>
        <FormControl variant="outlined" size="small" className={classes.margin}>
          <InputLabel style={{ color: "#000000" }}></InputLabel>
          <Select
            value={props.value}
            onChange={props.onChange}
            label="Time"
            input={<BootstrapInput />}
            id="demo-simple-select-outlined-label"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
            <MenuItem value={1}>{<QueryBuilderIcon className={classes.iconStyle} />}Last One Month</MenuItem>
            <MenuItem value={3}>{<QueryBuilderIcon className={classes.iconStyle} />}Last Three Month</MenuItem>
            <MenuItem value={6}>{<QueryBuilderIcon className={classes.iconStyle} />}Last Six Month</MenuItem>
            <MenuItem value={12}>{<QueryBuilderIcon className={classes.iconStyle} />}Last One Year </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }

  if (props.type === 'lifetime2') {
    return (
      <div style={{ marginTop: "-18px", marginLeft: "8px" }}>
        <FormControl variant="outlined" size="small" className={classes.margin}>
          <InputLabel style={{ color: "#000000" }}></InputLabel>
          <Select
            value={props.value}
            onChange={props.onChange}
            label="Time"
            input={<BootstrapInput />}
            id="demo-simple-select-outlined-label"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
            <MenuItem value={3}>{<QueryBuilderIcon className={classes.iconStyle} />}Last Three Month</MenuItem>
            <MenuItem value={6}>{<QueryBuilderIcon className={classes.iconStyle} />}Last Six Month</MenuItem>
            <MenuItem value={12}>{<QueryBuilderIcon className={classes.iconStyle} />}Last One Year </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }

  return (
    <div style={{ marginTop: "-18px", marginLeft: "8px" }}>
      <FormControl variant="outlined" size="small" className={classes.margin}>
        <InputLabel style={{ color: "#000000" }}></InputLabel>
        <Select
          value={props.value}
          onChange={props.onChange}
          label="Time"
          input={<BootstrapInput />}
          id="demo-simple-select-outlined-label"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
          <MenuItem value={1}>{<QueryBuilderIcon className={classes.iconStyle} />}Last One Month</MenuItem>
          <MenuItem value={3}>{<QueryBuilderIcon className={classes.iconStyle} />}Last Three Month</MenuItem>
          <MenuItem value={6}>{<QueryBuilderIcon className={classes.iconStyle} />}Last Six Month</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
