import { Button, Dialog, Grid, Typography, makeStyles } from "@material-ui/core";
import PhoneIcon from '@material-ui/icons/Phone';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import rachnayeLogo from "../../../../assets/rachnayeLight.png";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import CircularLoader from "../../../common/CircularLoader";
import OrderStatusIcon from "./OrderStatusIconMapping";
import CancelOrReturnOrder from "./CancelOrReturnOrder";
import { PaymentDetail } from "../../../../reducers/paymentAccounts/types";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#f5f5f5",
    padding: "20px 10px",
    borderRadius: "4px",
  },
  detailsContainer: {
    [theme.breakpoints.down('md')]: {
      padding: "0.5rem",
    },
    [theme.breakpoints.up('md')]: {
      padding: "1rem",
    },

    '& .MuiTypography-root': {
      [theme.breakpoints.down('md')]: {
        fontSize: "0.8rem",
      },
      [theme.breakpoints.up('md')]: {
        fontSize: "1rem"
      },
    },
    '& .MuiButton-root': {
      [theme.breakpoints.down('md')]: {
        fontSize: "0.7rem",
        padding: "0.4rem"
      },
      [theme.breakpoints.up('md')]: {
        fontSize: "0.8rem",
        padding: "0.5rem"
      },
    },

    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    '& > .MuiGrid-item > .MuiTypography-root': {
      fontWeight: 'normal',
    },
  },
  bookImage: {
    [theme.breakpoints.down('md')]: {
      width: 135,
      height: 175
    },
    [theme.breakpoints.up('md')]: {
      width: 200,
      height: 240
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  deliveryStatus: {
    paddingInline: "1rem",
    marginTop: "1rem",
    borderRadius: "5px"
  },
  DialogContainer: {
    padding: "2rem",
    '& .MuiGrid-container': {
      justifyContent: 'space-between',
      marginTop: "1rem"
    },
  }
}))



const PortalOrderDetails: React.FC = () => {
  const classes = useStyles()
  const { subOrderId } = useParams();
  const navigate = useNavigate()
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [subOrderDetails, setSubOrderDetails] = useState<any | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [showBreakups, setShowBreakups] = useState({ itemBreakup: false, orderBreakup: false })
  const [orderPaymentDetails, setOrderPaymentDetails] = useState<PaymentDetail[]>([]);
  const [itemPaymentDetails, setItemPaymentDetails] = useState<PaymentDetail[]>([]);
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [orderStatusChanged, setOrderStatusChanged] = useState(false)
  if (!subOrderId) {
    navigate(-1)
  }

  useEffect(() => {
    const fetchOrdersForUser = async () => {
      setLoading(true)
      if (mobileUserState?.id && subOrderId) {
        const res = await callApi(PATHNAMES.GET_USER_SUB_ORDER_BY_ID(mobileUserState.id, subOrderId), HTTP_METHODS.GET);
        if (res && res.success && res.data) {
          setSubOrderDetails(res.data)

          const _orderPaymentDetails: PaymentDetail[] = [
            ...(res.data.otherSuborders.length > 0
              ? res.data.otherSuborders.map((subOrder: any) => ({
                label: `${subOrder.quantity} X ${subOrder.book.title}`,
                value: `₹${subOrder.unitPrice}`,
              }))
              : []),
            { label: `${res.data.quantity} X ${res.data.book.title}`, value: `₹${res.data.unitPrice}` },
            res.data.order.totalExclusiveBirthDayOfferDiscount > 0 && { label: 'Birthday Discount', value: `- ₹${res.data.order.totalExclusiveBirthDayOfferDiscount}` },
            res.data.order.totalDiscountOnSellingPrice > 0 && { label: 'Offer discount', value: `- ₹${res.data.order.totalDiscountOnSellingPrice}` },
            res.data.order.totalCouponDiscount > 0 && { label: 'Coupon Discount', value: `- ₹${res.data.order.totalCouponDiscount}` },
            res.data.order.totalGlobalOfferDiscount > 0 && { label: 'Global Offer Discount', value: `- ₹${res.data.order.totalGlobalOfferDiscount}` },
            { label: 'Subtotal',  value: `₹${(res.data.order.subTotal + res.data.order.taxAmount).toFixed(2)}` },
            { label: 'Shipping Fee', value: `₹${res.data.order.shippingCharges}` },
            // Taxes and subTotal should come in gray and inside the border as sub data
            // { label: 'Taxes', value: `₹${res.data.order.taxAmount}` },
            { label: 'Convenience Fee', value: `₹${res.data.order.convenienceFee}` },
          ].filter(Boolean);  // Filter out any falsey values

          const _itemPaymentDetails: PaymentDetail[] = [
            { label: 'Item Total', value: `${res.data.quantity} X ₹${res.data.unitPrice}` },
            { label: 'Offer Discount', value: `- ₹${res.data.discountOnSellingPrice}` },
            { label: 'Global Offer Discount', value: `- ₹${res.data.globalOfferDiscountPrice}` },
            res.data?.exclusiveBirthDayDiscountPrice && { label: 'Birthday Discount', value: `- ₹${res.data.exclusiveBirthDayDiscountPrice}` },
            ...(res.data.couponDiscountPrice > 0 ? [{ label: 'Coupon Discount', value: `- ₹${res.data.couponDiscountPrice}` }] : []),
            { label: 'Total before tax', value: `₹${res.data.subTotal}` },
            { label: 'Taxes', value: `₹${res.data.taxAmount}` }
          ].filter(Boolean);

          setOrderPaymentDetails(_orderPaymentDetails)
          setItemPaymentDetails(_itemPaymentDetails)
          setLoading(false)
        }
      }
    }

    fetchOrdersForUser()

  }, [subOrderId, orderStatusChanged])

  const handleDialogClose = (key: string) => {
    setShowBreakups(prevState => ({
      ...prevState,
      [key]: false
    }));
  };

  const handleDialogOpen = (key: string) => {
    setShowBreakups(prevState => ({
      ...prevState,
      [key]: true
    }));
  };

  const nonCancellableOrderStates = ['created', 'completed', 'cancelled', 'returned', 'delivered']

  return (
    loading ? <CircularLoader />
      :
      subOrderDetails ?
        <Grid container className={classes.container} alignItems="center" justifyContent="center">

          <Dialog onClose={() => handleDialogClose('orderBreakup')} aria-labelledby="simple-dialog-title" open={showBreakups.orderBreakup}>
            <Grid className={classes.DialogContainer} container xs={12}>
              <Grid item xs={12}>
                <Typography variant="h2" style={{ fontWeight: "800", color: "black" }}>
                  Payment Information
                </Typography>
              </Grid>
              {orderPaymentDetails.map((detail, index) => (
                <Grid container key={index} xs={12}>
                  <Grid item>
                    <Typography variant="h3">{detail.label}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h3">{detail.value}</Typography>
                  </Grid>
                </Grid>
              ))}
              <Grid container xs={12}>
                <Grid item>
                  <Typography variant="h3" style={{ fontWeight: "bold" }}>Total</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" style={{ fontWeight: "bold" }}> ₹{subOrderDetails.payment.amount}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Dialog>


          <Dialog onClose={() => handleDialogClose('itemBreakup')} aria-labelledby="simple-dialog-title" open={showBreakups.itemBreakup}>
            <Grid className={classes.DialogContainer} container xs={12}>
              <Grid item xs={12}>
                <Typography variant="h2" style={{ fontWeight: "800", color: "black" }}>
                  Payment Information
                </Typography>
              </Grid>
              {itemPaymentDetails.map((detail, index) => (
                <Grid container key={index} xs={12}>
                  <Grid item>
                    <Typography variant="h3">{detail.label}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h3">{detail.value}</Typography>
                  </Grid>
                </Grid>
              ))}

              <Grid container xs={12}>
                <Grid item>
                  <Typography variant="h3" style={{ fontWeight: "bold" }}>Order Total</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" style={{ fontWeight: "bold" }}>₹{subOrderDetails.totalAmount}</Typography>
                </Grid>
              </Grid>

            </Grid>
          </Dialog>

          {showCancelDialog && <CancelOrReturnOrder showDialog={showCancelDialog} onClose={(value, succcess) => {
            setShowCancelDialog(value);
            if (succcess) {
              setOrderStatusChanged((prev) => !prev)
            }
          }}
            subOrderId={subOrderDetails.id} />
          }

          <Grid container item xs={12} lg={8} alignItems="center" justifyContent="center" className={classes.detailsContainer} direction="column">
            <Grid item>
              <img
                onClick={() => navigate(`/portal/book?id=${subOrderDetails.book.id}`)}
                className={classes.bookImage} src={subOrderDetails.book.thumbnailFront || rachnayeLogo} alt="Product" />
            </Grid>
            <Grid item>
              <Typography variant="h3">
                <span style={{ fontWeight: "500" }}>Title:</span> {subOrderDetails.book.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3" style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "24rem", // Adjust this value based on your layout
              }}>
                <span style={{ fontWeight: "500" }}>Description:</span> {subOrderDetails.book.description}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">
                <span style={{ fontWeight: "500" }}>Quantity:</span> {subOrderDetails.quantity}
              </Typography>
            </Grid>


            <Grid xs={12} container
              style={{
                backgroundColor: subOrderDetails.orderStatus === "CANCELLED" ? "#FF5C3E" : "#03a685",
              }}
              className={classes.deliveryStatus} direction="row" spacing={2} alignItems="center"
            >
              <Grid item>
                <OrderStatusIcon status={subOrderDetails.orderStatus.toUpperCase()} style={{ fontSize: "50px", color: "white" }} />
              </Grid>

              <Grid item direction="column" style={{}}>
                <Grid item>
                  <Typography variant="h3" style={{ fontWeight: "500", color: "white" }}>
                    {subOrderDetails?.orderStatus.replace("_", " ").toUpperCase() || subOrderDetails.status.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" style={{ fontWeight: "500", color: "white" }}>
                    on {moment(subOrderDetails.events[subOrderDetails.events.length - 1].updatedAt).format('DD MMM, dddd, hh:mm A')}

                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} style={{ marginTop: "1rem", backgroundColor: "white", padding: "1rem" }} spacing={1} >
              <Grid item xs={12}  >
                <Typography variant="h3" style={{ fontWeight: "normal" }}>
                  • &nbsp;  You Paid: <span style={{ fontWeight: "bold" }}>₹{subOrderDetails.payment.amount}</span>
                </Typography>
              </Grid>

              {!nonCancellableOrderStates.includes(subOrderDetails.orderStatus.toLowerCase())
                &&
                <Grid item xs={12} style={{ display: "flex" }} >
                  <Grid item >
                    • &nbsp;<Button onClick={() => setShowCancelDialog(true)} style={{ borderRadius: "4px", border: "1px solid black", padding: "0.4rem", paddingInline: "1rem" }}>CANCEL</Button>
                  </Grid>
                </Grid>
              }

              <Grid item xs={12}  >
                <Typography variant="h3" style={{ fontWeight: "normal" }}>
                  • &nbsp;  Reference: #<span style={{ fontWeight: "400" }}>₹{subOrderDetails.referenceNumber}</span>
                </Typography>
              </Grid>

              <Grid item xs={12}  >
                <Typography variant="h3" style={{ fontWeight: "normal" }}>
                  • &nbsp;  Expected Delivery Date: By <span style={{ fontWeight: "400" }}>{moment(subOrderDetails.createdAt).add(7, 'days').format('DD MMM, dddd')}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12}  >
                <Typography variant="h3" style={{ fontWeight: "normal" }}>
                  • &nbsp;  Ordered on: <span style={{ fontWeight: "400" }}>{moment(subOrderDetails.createdAt).format('DD MMM, dddd, hh:mm A')}</span>
                </Typography>
                
              </Grid>
            </Grid>

            {subOrderDetails.order.shippingAddress && <Grid container xs={12} style={{ marginTop: "1rem", backgroundColor: "white", padding: "1rem" }} spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h2" style={{ fontWeight: "500" }}>
                  Delivery Address
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  <span style={{ fontWeight: "500" }}>Receiver Name:</span> {subOrderDetails.order.shippingAddress.recipientName}
                </Typography>
                <Typography variant="h3">
                  <span style={{ fontWeight: "500" }}>Phone no.:</span> {subOrderDetails.order.shippingAddress.contactNumber.phoneNumber}
                </Typography>
                <Typography variant="h3">
                  <span style={{ fontWeight: "500" }}>Address:</span> {subOrderDetails.order.shippingAddress.address1},&nbsp;
                  {subOrderDetails.order.shippingAddress.city},&nbsp;
                  {subOrderDetails.order.shippingAddress.pincode}
                </Typography>
              </Grid>


            </Grid>
            }

            {/* Total Item Price */}
            {subOrderDetails.order.orderItems.length > 1 && <Grid container xs={12} style={{ marginTop: "1rem", backgroundColor: "white", padding: "1rem" }} spacing={1} >

              {/* Total  Price */}
              <Grid container xs={12} style={{ justifyContent: "space-between" }}>
                <Grid item >
                  <Typography variant="h3" style={{ fontWeight: "500" }}>
                    Total item Price
                  </Typography>
                </Grid>
                <Grid item >
                  <Typography variant="h3" style={{ fontWeight: "500" }}>
                    ₹ {subOrderDetails.subTotal}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container xs={12} style={{ justifyContent: "space-between", marginTop: "0.5rem" }}>
                <Grid item >
                  <Typography variant="h3" style={{ fontWeight: "normal" }}>
                    You saved  ₹{subOrderDetails.totalDiscountsAvailedOnSubOrder} on this item
                  </Typography>
                </Grid>
                <Grid item >
                  <Button onClick={() => { handleDialogOpen('itemBreakup') }} style={{ borderRadius: "4px", border: "1px solid black", padding: "0.5rem" }}>VIEW BREAKUP</Button>
                </Grid>

              </Grid>
            </Grid>}



            <Grid container xs={12} style={{ marginTop: "1rem", backgroundColor: "white", padding: "1rem" }} spacing={1} >
              <Grid container xs={12} style={{ justifyContent: "space-between" }}>
                <Grid item >
                  <Typography variant="h3" style={{ fontWeight: "500" }}>
                    Total Order Price
                  </Typography>
                </Grid>
                <Grid item >
                  <Typography variant="h3" style={{ fontWeight: "500" }}>
                    ₹{subOrderDetails.order.totalAmount}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container xs={12} style={{ justifyContent: "space-between", marginTop: "0.5rem" }}>
                <Grid item >
                  <Typography variant="h3" style={{ fontWeight: "normal" }}>
                    You saved  ₹{subOrderDetails.order.totalDiscountsAvailedOnOrder} on this order
                  </Typography>
                </Grid>
                <Grid item >
                  <Button onClick={() => { handleDialogOpen('orderBreakup') }} style={{ borderRadius: "4px", border: "1px solid black", padding: "0.5rem" }}>VIEW BREAKUP</Button>
                </Grid>

              </Grid>
              <Grid item xs={12} style={{ backgroundColor: "#f5f5f5", padding: "1rem", borderRadius: "4px", marginTop: "0.5rem" }} >
                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                  Paid by {subOrderDetails.payment.paymentGateway}
                </Typography>
              </Grid>
            </Grid>

            <Grid container xs={12} style={{ marginTop: "1rem", backgroundColor: "white", padding: "1rem" }} spacing={1} >
              <Grid container xs={12} spacing={1}>
                <Grid item >
                  <Typography variant="h3" style={{ fontWeight: "500" }}>
                    Type of order: {subOrderDetails.orderType}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <a href={subOrderDetails.invoiceUrl} download>
                    <Button style={{ borderRadius: "4px", border: "1px solid black", width: "100%", padding: "1rem" }}>
                      Get Invoice
                    </Button>
                  </a>
                </Grid>
              </Grid>

            </Grid>

            {subOrderDetails.order.shippingAddress?.contactNumber?.phoneNumber && <Grid container xs={12} style={{ marginTop: "1rem", backgroundColor: "white", padding: "1rem" }} spacing={1} >
              <Grid item xs={12}  >
                <Typography variant="h3" style={{ fontWeight: "bold" }}>
                  Updates sent to
                </Typography>
              </Grid>
              <Grid xs={12} container spacing={2} style={{ marginTop: "0.3rem" }}>
                <Grid item >
                  <PhoneIcon />
                </Grid>
                <Grid item >
                  <Typography variant="h3" style={{ fontWeight: "normal" }}>
                    {subOrderDetails.order.shippingAddress.contactNumber.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>}
          </Grid>

        </Grid>
        :
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography>Order Details not found</Typography>
          </Grid>
        </Grid>

  )
}

export default PortalOrderDetails;