import { BOOK_AGE_RANGE, BOOK_CATEGORIES, BOOK_LANGUAGES } from "./constants";
import { IBook } from "../reducers/books/types";
import { ErrorHandler } from "./utils";

export const getAgeRangeValueOrLabel = (isLabel: boolean, book: IBook) => {
  const rangeValue = BOOK_AGE_RANGE.find((item) => {
    return (item.value.lowerLimit === book?.ageRange?.lowerLimit && item.value.upperLimit === book?.ageRange?.upperLimit)
  })
  if (isLabel) {
    return rangeValue?.label as string
  } else {
    return rangeValue?.value as Object
  }
}

export const isValidEpub = (file: File): boolean => {
  const re: RegExp = /^.+\.(?:[eE][pP][uU][bB])$/
  return re.test(file?.name)
}

export const isValidImage = (file: File): boolean => {
  const re: RegExp = /^.+\.(?:[jJ][pP][gG]|[pP][nN][gG]|[jJ][pP][eE][gG])$/
  return re.test(file?.name)
}

export const isValidVideo = (file: File): boolean => {
  const re: RegExp = /^.+\.(?:[mM][pP][4]|[mM][4][vV]|[mM][kK][vV])$/
  return re.test(file?.name)
}

export const validateBookData = async (book: IBook) => {
  try {
    if (!book.title) {
      throw new ErrorHandler("Please Add Title")
    }
    if (!book.description) {
      throw new ErrorHandler("Please Add Description")
    }
    if (!book.category) {
      throw new ErrorHandler("Please Add Category")
    }
    if (!book.language) {
      throw new ErrorHandler("Please Add Language")
    }
    if (!book.ageRange || !book.ageRange.upperLimit) {
      throw new ErrorHandler("Please Add Age Range")
    }
    if (!book.pricing.planName) {
      throw new ErrorHandler("Please Add Plan Name")
    }
    if ((book.hasDigital || book.hasRental) && !book.digital.pages) {
      throw new ErrorHandler("Please Add Pages in book")
    }
    if (book.hasDigital && book.contentType === "PAID") {
      if (!book.pricing.sellPrice) {
        throw new ErrorHandler("Please Add Ebook price")
      }
    }
    if (book.hasRental && book.contentType === "PAID") {
      if (book.pricing.rentalPrice) {
        if (!book.pricing.rentalPrice.weekly) {
          throw new ErrorHandler("Please Add Weekly price")
        }
        if (!book.pricing.rentalPrice.fortNightly) {
          throw new ErrorHandler("Please Add FortNighlty price")
        }
        if (!book.pricing.rentalPrice.monthly) {
          throw new ErrorHandler("Please Add Monthly price")
        }
      } else {
        throw new ErrorHandler("Please Add All prices to book")
      }
    }
    if (book.hasPaperback) {
      if (!book?.paperback?.pages) {
        throw new ErrorHandler("Please Add Pages to PaperBack")
      }
      if (!book.pricing.paperBackPrice) {
        throw new ErrorHandler("Please Add PaperBack price")
      }
      if (!book?.paperback?.height ||
        !book?.paperback?.height?.value ||
        !book?.paperback?.height?.unit) {
        throw new ErrorHandler("Please Add Length to PaperBack")
      }
      if (!book?.paperback?.width ||
        !book?.paperback?.width?.unit ||
        !book?.paperback?.width?.value) {
        throw new ErrorHandler("Please Add Breadth to PaperBack")
      }
      if (!book?.paperback?.depth ||
        !book?.paperback?.depth?.unit ||
        !book?.paperback?.depth?.value) {
        throw new ErrorHandler("Please Add Height to PaperBack")
      }
      if (!book?.paperback?.weight?.unit || !book?.paperback?.weight?.value) {
        throw new ErrorHandler("Please Add Weight of PaperBack")
      }
    }
    if (book.hasHardbound) {
      if (!book?.hardbound?.pages) {
        throw new ErrorHandler("Please Add Pages to HardBound")
      }
      if (!book?.pricing?.hardboundPrice) {
        throw new ErrorHandler("Please Add HardBound price")
      }
      if (!book?.hardbound?.height ||
        !book?.hardbound?.height?.value ||
        !book?.hardbound?.height?.unit) {
        throw new ErrorHandler("Please Add Length to Hardbound")
      }
      if (!book?.hardbound?.width ||
        !book?.hardbound?.width?.unit ||
        !book?.hardbound?.width?.value) {
        throw new ErrorHandler("Please Add Breadth to Hardbound")
      }
      if (!book?.hardbound?.depth ||
        !book?.hardbound?.depth?.unit ||
        !book?.hardbound?.depth?.value) {
        throw new ErrorHandler("Please Add Height to Hardbound")
      }
      if (!book?.hardbound?.weight?.unit || !book?.hardbound?.weight?.value) {
        throw new ErrorHandler("Please Add Weight of HardBound")
      }
    }
    if (book.preOrderAvailable && !(book.preOrderStartDate && book.bookLaunchDate)) {
      throw new ErrorHandler("Please Add required pre order dates")
    }
    return book
  } catch (error) {
    throw error
  }
}

export const getCategoryLabelByValue = (value: string) => {
  const category: any = BOOK_CATEGORIES.find(item => item.value === value)
  return category?.label || ""
}

export const getLanguageLabelByValue = (value: string) => {
  const language: any = BOOK_LANGUAGES.find(item => item.value === value)
  return language?.label || ""
}

export const getAgeRangeLabelByValue = (value: any) => {
  const ageRange: any = BOOK_AGE_RANGE.find(ageRange => ageRange.value.upperLimit === value.upperLimit && ageRange.value.lowerLimit === value.lowerLimit)
  return ageRange?.label || "18+"
}