import { Fab, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, useNavigationType, NavigationType } from "react-router-dom";
import { IValidCouponResponse } from "../../../../reducers/offers/types";
import { IOrderResponse } from "../../../../reducers/orders/types";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { HTTP_METHODS, OrderItemTypes } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import CircularLoader from "../../../common/CircularLoader";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import CouponContainer from "./CouponContainer";
import OrderBookContainer from "./OrderBookContainer";
import OrderPriceContainer from "./OrderPriceContainer";
import OrderAddressContainer from "./OrderAddressContainer";
import { ErrorHandler } from "../../../../utils/utils";


const useStyles = makeStyles(() => ({
  mainHeader: { fontWeight: 1000, padding: '1.4rem 0.2rem' },
  paymentDiv: { margin: '2rem 0rem' },
  disabledBtn: {
    backgroundColor: "grey!important"
  }
}))

const DigitalBookOrderSummary: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const snackbar = useSnackBar();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [digitalOnly, setDigitalOnly] = useState<boolean>(true)

  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<IOrderResponse>();
  const [coupons, setCoupons] = useState<IValidCouponResponse[]>([])

  // fetch order data
  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const orderId = params.get("orderId");
      if (orderId) {
        setLoading(true)
        // if  user comes to page by cliking forward or back button , then re generate order (because order may be modified)
        // else get old order

        if (navigationType === NavigationType.Pop) {
          reGenerateNewOrder(orderId)
        } else {
          getOrderData(orderId)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (order && order.id) {
      let flag: boolean = order.orderItems.some((item) => [OrderItemTypes.HARDBOUND.toString(), OrderItemTypes.PAPERBACK.toString()].includes(item.orderType));
      if (flag) {
        setDigitalOnly(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.id])

  // fetch coupons 
  useEffect(() => {
    if (mobileUserState && mobileUserState.id && order && order.id) {
      getCoupons(order.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState?.id, order?.id]);

  const reGenerateNewOrder = (orderId: string) => {
    callApi(PATHNAMES.RE_GENERATE_ORDER(orderId), HTTP_METHODS.POST).then((res) => {
      if (res && res.success && res.data) {
        setLoading(false);
        setOrder(v => res.data);
        navigate(`/portal/orderSummary?orderId=${res.data.id}`, { replace: true })
      } else {
        snackbar({
          message: "Something went wrong",
          variant: SnackBarVariant.ERROR
        })
        navigate("/digital")
      }
    })
  }

  const getOrderData = (orderId: string) => {
    callApi(PATHNAMES.GET_PORTAL_ORDER(orderId), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setLoading(false);
        setOrder(v => res.data);
      } else {
        snackbar({
          message: "Something went wrong",
          variant: SnackBarVariant.ERROR
        })
        navigate("/digital")
      }
    })
  }

  //FETCH COUPONS
  const getCoupons = (orderId: string) => {
    callApi(PATHNAMES.GET_COUPONS_FOR_ORDER(mobileUserState.id, orderId), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setCoupons(v => res.data);
      }
    })
  }

  const handleApplyCoupon = (couponCode: string) => {
    if (!couponCode.length) {
      snackbar({
        variant: SnackBarVariant.WARNING,
        message: "Please apply a valid coupon"
      })
      return
    }
    if (order) {
      callApi(PATHNAMES.APPLY_COUPON_ON_ORDER(couponCode), HTTP_METHODS.POST, {
        body: JSON.stringify({ data: { orderId: order.id } })
      }).then(res => {
        if (res && res.success && res.data) {
          getOrderData(order.id)
        } else {
          snackbar({
            variant: SnackBarVariant.ERROR,
            message: res.message || "invalid coupon"
          })
        }
      })
    }
  }

  const handleRemoveCoupon = () => {
    if (order && order.offerCode) {
      callApi(PATHNAMES.REMOVE_COUPON_FROM_ORDER(order.offerCode), HTTP_METHODS.PATCH, {
        body: JSON.stringify({ data: { orderId: order.id } })
      }).then(res => {
        if (res && res.success && res.data) {
          getOrderData(order.id)
        }
      })
    }
  }

  const handleProceedForPayment = async () => {
    if (!digitalOnly && !order?.billingAddress) {
      snackbar({
        message: "Please add address to deliver",
        variant: SnackBarVariant.ERROR
      })
      return
    }
    if (order) {
      const res = await callApi(PATHNAMES.CREATE_CF_ORDER_PORTAL(order.id), HTTP_METHODS.POST);
      if (res && res.success && res.data) {
        navigate(`/portal/payment?pgSessionId=${res.data.paymentSessionId}&orderId=${order.id}&orderType=book`)
      } else {
        snackbar({
          message: "Something went wrong, Please go back and try again",
          variant: SnackBarVariant.ERROR
        })
      }
    }
  }

  const addAddressInOrder = async (addressId: string) => {
    try {
      if (order) {
        const response2 = await callApi(PATHNAMES.ADD_ADDRESS_IN_ORDER(order.id, addressId), HTTP_METHODS.PATCH)
        if (!response2 && !response2.success) {
          throw new ErrorHandler(response2.message)
        }
        setOrder(v => response2.data)
      }
    } catch (error) {
      throw error
    }
  }

  if (!order || loading) {
    return <CircularLoader message="Wait, We are fetching order details  :)" />
  }

  return (
    <Grid container xs={12} justify='center' >
      <Grid container xl={5} lg={6} md={8} sm={11} xs={12} >
        <Grid item xs={12}><Typography variant="h2" className={classes.mainHeader}>Your Order Summary</Typography></Grid>

        <OrderBookContainer order={order} />
        <OrderAddressContainer order={order} digitalOnly={digitalOnly} addAddressInOrder={addAddressInOrder} />
        <CouponContainer
          order={order}
          coupons={coupons}
          handleApplyCoupon={handleApplyCoupon}
          handleRemoveCoupon={handleRemoveCoupon}
        />
        <OrderPriceContainer order={order} />

        {/* PAYMENT BUTTON  */}
        <Grid item xs={12} className={classes.paymentDiv}>
          <Fab variant="extended"
            onClick={handleProceedForPayment}
            size="small"
            style={{ width: "100%", height: "3rem", }}
          >
            PROCEED TO PAY
          </Fab>
        </Grid>
      </Grid>
    </Grid >
  )
}

export default DigitalBookOrderSummary;