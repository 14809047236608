import { FormControl, FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Typography } from "@material-ui/core";
import React from "react";
import { IDigitalBookResponse } from "../../../../reducers/books/types";
import { BookContentTypes, BookRentalTypes, OrderItemTypes } from "../../../../utils/constants";
import ShowCurrentPrice from "./ShowCurrentPrice";

const useStyles = makeStyles((theme) => ({
  tagsChip: {
    background: "#FFFFFF",
    border: "1px solid #E8E8E8",
    "&.MuiAutocomplete-tag": {
      margin: "10px 0px 5px 10px"
    },
    margin: '0.5rem 0.5rem 1rem 0rem', borderRadius: "2rem", padding: "0.5rem", fontSize: 16, fontWeight: 600
  },
  disabledBtn: {
    backgroundColor: "grey!important"
  },
  offerContainer: {
    borderRadius: 4,
    border: "1px solid #9e9694",
    padding: "0.6rem 1rem 0rem 1rem"
  },
  informText: { color: "#FF5C3E", fontWeight: "bolder", padding: "0.6rem 0rem" },
  rentalRadioButton: { padding: "0.5rem 0rem" }
}))

interface IProps {
  book: IDigitalBookResponse;
  selectedBookTypeToBuy: string
  selectedRentalTypeToBuy: string;
  bookAlreadyPurchased: boolean;
  bookIsFreeToRead: boolean;
  changeSelectedBookType: (value: string) => void;
  changeSelectedRentalType: (value: string) => void;
}

const PriceContainer: React.FC<IProps> = (props) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      {props.book.contentType === BookContentTypes.PAID &&
        <Grid item xs={12} className={classes.offerContainer}>
          <FormControl >
            <RadioGroup
              row
              name="orderType"
              value={props.selectedBookTypeToBuy}
              onChange={(event) => {
                props.changeSelectedBookType(event.target.value)
              }}
            >
              {props.book.hasPaperback && <Grid item xs={12}>
                <FormControlLabel
                  value={OrderItemTypes.PAPERBACK}
                  control={<Radio color="primary" />}
                  label={
                    <>
                      <Typography variant="h4">{"Paperback"}</Typography>
                      {props.selectedBookTypeToBuy === OrderItemTypes.PAPERBACK && <ShowCurrentPrice book={props.book} selectedBookTypeToBuy={props.selectedBookTypeToBuy} selectedRentalTypeToBuy={props.selectedRentalTypeToBuy} />}
                    </>
                  }
                /> </Grid>}
              {props.book.hasHardbound && <Grid item xs={12}> <FormControlLabel
                value={OrderItemTypes.HARDBOUND}
                control={<Radio color="primary" />}
                label={
                  <>
                    <Typography variant="h4">{"Hardbound"}</Typography>
                    {props.selectedBookTypeToBuy === OrderItemTypes.HARDBOUND && <ShowCurrentPrice book={props.book} selectedBookTypeToBuy={props.selectedBookTypeToBuy} selectedRentalTypeToBuy={props.selectedRentalTypeToBuy} />}
                  </>
                }
              /> </Grid>}
              {props.book.hasAudiobook && <Grid item xs={12}> <FormControlLabel
                value={OrderItemTypes.AUDIOBOOK}
                control={<Radio color="primary" />}
                label={
                  <>
                    <Typography variant="h4">{"Audiobook"}</Typography>
                    {props.selectedBookTypeToBuy === OrderItemTypes.AUDIOBOOK && <ShowCurrentPrice book={props.book} selectedBookTypeToBuy={props.selectedBookTypeToBuy} selectedRentalTypeToBuy={props.selectedRentalTypeToBuy} />}
                  </>
                }
              /> </Grid>}
              {props.book.hasDigital && <Grid item xs={12}><FormControlLabel
                value={OrderItemTypes.BUY_DIGITAL}
                control={<Radio color="primary" />}
                label={
                  <>
                    <Typography variant="h4">{"Digital"}</Typography>
                    {props.selectedBookTypeToBuy === OrderItemTypes.BUY_DIGITAL && <ShowCurrentPrice book={props.book} selectedBookTypeToBuy={props.selectedBookTypeToBuy} selectedRentalTypeToBuy={props.selectedRentalTypeToBuy} />}
                  </>
                }
              /> </Grid>}

              {props.book.hasRental && <Grid item xs={12}><FormControlLabel
                className={classes.rentalRadioButton}
                value={OrderItemTypes.RENTAL_DIGITAL}
                control={<Radio color="primary" />}
                label={
                  <>
                    <Typography variant="h4">{"Rent-Digital"}</Typography>
                    {props.selectedBookTypeToBuy === OrderItemTypes.RENTAL_DIGITAL && <ShowCurrentPrice book={props.book} selectedBookTypeToBuy={props.selectedBookTypeToBuy} selectedRentalTypeToBuy={props.selectedRentalTypeToBuy} />}

                  </>
                }
              /> </Grid>}


              {props.selectedBookTypeToBuy === OrderItemTypes.RENTAL_DIGITAL &&
                <Grid item xs={12} >
                  <FormControl >
                    <RadioGroup
                      row
                      name="rentalType"
                      value={props.selectedRentalTypeToBuy}
                      onChange={(event) => {
                        props.changeSelectedRentalType(event.target.value)
                      }}
                    >
                      <FormControlLabel
                        value={BookRentalTypes.WEEKLY}
                        control={<Radio color="primary" size="small" />}
                        label={<Typography variant="body2">{"Weekly"}</Typography>}

                      />
                      <FormControlLabel
                        value={BookRentalTypes.FORTNIGHTLY}
                        control={<Radio color="primary" size="small" />}
                        label={<Typography variant="body2">{"Fortnightly"}</Typography>}

                      />
                      <FormControlLabel
                        value={BookRentalTypes.MONTHLY}
                        control={<Radio color="primary" size="small" />}
                        label={<Typography variant="body2">{"monthly"}</Typography>}

                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              }
            </RadioGroup>
          </FormControl>
        </Grid>
      }

      {![OrderItemTypes.AUDIOBOOK.toString(), OrderItemTypes.PAPERBACK.toString(), OrderItemTypes.HARDBOUND.toString()].includes(props.selectedBookTypeToBuy) &&
        props.bookAlreadyPurchased && <Typography className={classes.informText}>
          Note: This book is already in your library. Kindly go to mobile app and read it.
        </Typography>}
      {(OrderItemTypes.AUDIOBOOK.toString() === props.selectedBookTypeToBuy) &&
        props.book.canListenAudioBook && <Typography className={classes.informText}>
          Note: This book is already in your library. Kindly go to mobile app to listen it.
        </Typography>}
      {props.bookIsFreeToRead && <Typography className={classes.informText}>
        Note: This book is free to read. Kindly go to mobile app and read it.
      </Typography>}
      {([OrderItemTypes.PAPERBACK.toString(), OrderItemTypes.HARDBOUND.toString()].includes(props.selectedBookTypeToBuy)) &&
        props.book.saleableTypesOnlyViaBundle.includes(props.selectedBookTypeToBuy) && <Typography className={classes.informText}>
          Note: This book is a part of bundle, kindly go to mobile app to buy at lower price.
        </Typography>}
    </Grid>
  )
}


export default PriceContainer;