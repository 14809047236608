import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import CustomDivider from '../../common/Divider';
import BackUpOTPs from './BackUpOTPs';
import CustormRachnayeCoverImages from './CustomRachnayeCoverImages';
import OrderTaxesAndFees from './OrderTaxesAndFees';
import TrendinSearches from './TrendingSearches';

const useStyles = makeStyles((theme) => ({
  dividerMargin: {
    margin: "1rem 0rem",
  }
}))

const PlatformSettings: React.FC = () => {
  const classes = useStyles()
  return (
    <Grid container xs={12}>
      <OrderTaxesAndFees />
      <CustomDivider className={classes.dividerMargin} />
      <BackUpOTPs />
      <CustomDivider className={classes.dividerMargin} />
      <TrendinSearches />
      <CustomDivider className={classes.dividerMargin} />
      <CustormRachnayeCoverImages />
    </Grid>
  )
}

export default PlatformSettings;