import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import CustomizedSelects from "./CustomizedSelects";

ChartJS.register(
  CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend
);

const TopPerformingBooks = () => {
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [topPerformers, setTopPerformers] = useState<any>({})
  const [selectedDiff, setSelectedDiff] = useState<number>(1);

  const userState = useSelector((state: IAppState) => state.user);

  let currDiffData: any, titlesArr: any[], revenueArr: any[], data: any

  const fetchPublisherData = async (spaceId: string) => {
    setIsLoading(true);
    try {
      const count = 3 //no. of top performing books to show
      const response1 = await callApi(PATHNAMES.PUBLISHER_TOP_PERFORMING(spaceId || "", count, selectedDiff), HTTP_METHODS.GET)
      setTopPerformers((curr: any) => ({ ...curr, [selectedDiff]: response1?.data }))

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);

    }
  }

  useEffect(() => {
    if (userState && userState.spaceId) {
      fetchPublisherData(userState.spaceId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState, selectedDiff]);

  const handleDiffChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedDiff(event.target.value as number);
  };


  currDiffData = topPerformers[selectedDiff]
  titlesArr = currDiffData?.map((book: { title: any; }) => `${book.title.slice(0, 15)}...`)
  revenueArr = currDiffData?.map((book: { revenue: any; }) => book.revenue)
  data = {
    // type:'bar',
    labels: titlesArr,

    datasets: [
      {
        label: "REVENUE (in Rupees)",
        backgroundColor: "#FF5C3E",
        borderWidth: 2,
        data: revenueArr,
        barThickness: 20,
      }
    ],
  }

  // if (selectedDiff === SelectedMonths.ONE_MONTH && monthOne) {
  //   currDiffData = topPerformers[selectedDiff]
  //   titlesArr = currDiffData?.map((book: { title: any; }) => `${book.title.slice(0, 15)}...`)
  //   revenueArr = currDiffData?.map((book: { revenue: any; }) => book.revenue)
  //   data = {
  //     // type:'bar',
  //     labels: titlesArr,

  //     datasets: [
  //       {
  //         label: "REVENUE (in Rupees)",
  //         backgroundColor: "#FF5C3E",
  //         borderWidth: 2,
  //         data: revenueArr,
  //         barThickness: 20,
  //       }
  //     ],
  //   }
  // }

  // if (selectedDiff === SelectedMonths.THREE_MONTH && monthThree) {
  //   currDiffData = topPerformers[selectedDiff]
  //   titlesArr = currDiffData.map((book: { title: any; }) => `${book.title.slice(0, 15)}...`)
  //   revenueArr = currDiffData.map((book: { revenue: any; }) => book.revenue)
  //   data = {
  //     // type:'bar',
  //     labels: titlesArr,

  //     datasets: [
  //       {
  //         backgroundColor: "#FF5C3E",
  //         borderWidth: 2,
  //         data: revenueArr,
  //         barThickness: 20,

  //       }

  //     ],
  //   }
  // }

  // if (selectedDiff === SelectedMonths.SIX_MONTH && monthSix) {
  //   currDiffData = topPerformers[selectedDiff]
  //   titlesArr = currDiffData.map((book: { title: any; }) => `${book.title.slice(0, 15)}...`)
  //   revenueArr = currDiffData.map((book: { revenue: any; }) => book.revenue)
  //   data = {
  //     // type:'bar',
  //     labels: titlesArr,

  //     datasets: [
  //       {
  //         backgroundColor: "#FF5C3E",
  //         borderWidth: 2,
  //         data: revenueArr,
  //         barThickness: 20,

  //       }
  //     ],
  //   }
  // }
  const options = {
    indexAxis: 'x' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
        }
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: (value: any) => `₹ ${value}`
        }
      }
    },
  }

  return (
    <Paper elevation={5} style={{ width: "100%" }} >
      {isLoading ? (<div style={{ marginLeft: '40%', marginTop: '30%' }}><CircularProgress /></div>) : (<>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: "1rem", paddingBottom: "0.3rem" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
            <Typography variant="h3" style={{ fontWeight: 600 }}>
              TOP PERFORMING BOOKS
            </Typography>
          </Grid>
        </Grid>
        <Grid container lg={12}>
          {
            currDiffData ? (<div className="TopPerformingBarchart" style={{ width: "100%" }}>
              <CustomizedSelects value={selectedDiff} onChange={handleDiffChange} type={'lifetime'} />
              <Bar options={options} data={data} style={{ padding: '1rem' }} />
            </div>) : (<Grid container xs={12} justify="center" alignContent="center" style={{ marginTop: '20%' }}><Typography variant="h2">No Data to display</Typography></Grid>)
          }
        </Grid>
      </>)}
    </Paper>
  );
};

export default TopPerformingBooks;
