import callApi from "./apiUtil";
import { HTTP_METHODS, ResourceTypes } from "./constants";
import { PATHNAMES } from "./pathNames";

export interface IUploadFileToS3Args {
  file: File;
  publisherId?: string;
  bookId?: string;
  isAdmin: boolean;
  resourceType: string;
}

export const uploadFileToS3 = async (args: IUploadFileToS3Args) => {
  try {
    let {file, publisherId, bookId, isAdmin, resourceType} = args;
    if (!file) {
      throw new Error("File does not exist");
    }
    var re = /(?:\.([^.]+))?$/;
    const arr = re.exec(file?.name);
    if (!arr || arr.length <= 1) {
      throw new Error("Incorrect file name");
    }
    const extension = arr[1];
    const fileName = file.name.split(".")[0]
    const data: any = {
      extension, resourceType, isAdmin, publisherId, bookId
    }
    if (extension === "epub") {
      data.fileName = fileName
    }
    const res = await callApi(PATHNAMES.GET_PRESIGNED_URL, HTTP_METHODS.POST, {
      body: JSON.stringify({ data })
    });
    if (res && res.success && res.data && res.data.key) {
      const myHeaders = new Headers({ "Content-Type": file.type });
      await fetch(res.data.uploadUrl, {
        method: "PUT",
        headers: myHeaders,
        body: file
      });
      return res.data.key;
    }
  } catch (err) {
    throw err;
  }
};

export const deleteFileFromS3 = async (fileKey: string) => {
  try {
    if (!fileKey) {
      throw new Error("File does not exist");
    }
    const urlFinder = ".com/"
    const splittedText = fileKey.split(urlFinder)
    if (!splittedText.length) {
      throw new Error("Key can not be seperated")
    }
    let actualKey
    if (splittedText.length > 1) {
      actualKey = splittedText[1]
    } else {
      actualKey = splittedText[0]
    }
    const res = await callApi(PATHNAMES.DELETE_FILE_FROM_S3(actualKey), HTTP_METHODS.DELETE);
    if (res) {
      return
    }
    throw new Error("Error while deleting file")

  } catch (err) {
    throw err;
  }
};

export const uploadAudioFileToS3 = async (args: IUploadAudioFilesArgs) => {
  try {
    let {file, publisherId, bookId, isAdmin} = args
    return await uploadFileToS3({
      file,
      resourceType: ResourceTypes.BOOK_AUDIO, 
      isAdmin: isAdmin ? true : false,
      publisherId,
      bookId
    });
  } catch (error) {
    throw error;
  }
}

interface IUploadAudioFilesArgs {
  file: File;
  publisherId: string;
  bookId: string;
  isAdmin?: boolean
}