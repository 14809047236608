import {
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";
import { IDistributor } from "../../../../reducers/distributors/types";

interface IProps {
  distributor: IDistributor;
}

const useStyles = makeStyles((theme) => ({
  publisherSummary: {
    backgroundColor: "#EAE9E0",
    padding: "1rem",
  },
  contentContainer: {
    padding: '1rem 1rem 1rem 0rem'
  },
  contentHeading: {
    fontWeight: 500
  },
  contentSummary: {
    display: 'inline',
    fontWeight: 400
  }
}));

const DistributorSummary: React.FC<IProps> = (props) => {
  const classes = useStyles();
  return (
    <Grid container xs={12}>
      <Grid container xs={12} className={classes.publisherSummary}>
        <Grid container lg={12} xs={12} style={{ maxHeight: '4rem' }}>
          <Typography variant="h2">
            {props.distributor.name}
          </Typography>
        </Grid>
        <Grid item xl={2} lg={3} md={3} sm={4} xs={12} className={classes.contentContainer} >
          <Typography variant="h4" className={classes.contentHeading}>Email </Typography>
          <Typography variant="h4" className={classes.contentSummary}>{props.distributor.email}</Typography>
        </Grid>
        <Grid item xl={2} lg={3} md={3} sm={4} xs={12} className={classes.contentContainer} >
          <Typography variant="h4" className={classes.contentHeading}>Ref Name </Typography>
          <Typography variant="h4" className={classes.contentSummary}>{props.distributor.refName}</Typography>
        </Grid>
        <Grid item xl={2} lg={3} md={3} sm={4} xs={12} className={classes.contentContainer} >
          <Typography variant="h4" className={classes.contentHeading}>Contact No.</Typography>
          <Typography variant="h4" className={classes.contentSummary}>{`${props.distributor.contactPerson.contact.countryCode}-${props.distributor.contactPerson.contact.phoneNumber}` || "-"}</Typography>
        </Grid>
        <Grid item xl={4} lg={3} md={3} sm={4} xs={12} className={classes.contentContainer} >
          <Typography variant="h4" className={classes.contentHeading}>Address </Typography>
          <Typography variant="h4" className={classes.contentSummary}>{props.distributor.address.line1}, </Typography>
          <Typography variant="h4" className={classes.contentSummary}>{props.distributor.address.line2}</Typography>
          <Typography variant="h4" className={classes.contentSummary}>{props.distributor.address.city}, </Typography>
          <Typography variant="h4" className={classes.contentSummary}>{props.distributor.address.state}- </Typography>
          <Typography variant="h4" className={classes.contentSummary}>{props.distributor.address.pincode}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DistributorSummary;