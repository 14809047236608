import {
  Fab,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-material-ui";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { login } from "../../../reducers/user/reducer";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS, MINIMUM_PASSWORD_LENGTH, USER_TYPES } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      marginTop: "20vh",
      alignContent: "center",
      justifyContent: "center",
    },
    formItem: {
      minWidth: "20rem",
      marginBottom: "1rem"
    },
    submitBtn: {
      marginTop: "1rem",
      color: "#fff",
      backgroundColor: "#0C2D48 !important",
      minWidth: "20rem",
    },
    redirectText: {
      marginTop: "2rem",
      justifyContent: "center",
      color: "#0d6060",
    },
    redirectLink: {
      display: "inline",
      cursor: "pointer",
      color: theme.palette.primary.main,
      marginLeft: "0.5rem"
    },
    mainText: {
      fontWeight: 600,
      fontSize: 36,
      color: theme.palette.primary.main,
      marginBottom: "10%"
    }
  })
);

const IamUserLogin: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: ""
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value.trim()
    });
  };

  const handleSubmit = async () => {
    const data = {
      emailId: values.email.toLowerCase(),
      password: values.password
    }
    const response = await callApi(PATHNAMES.IAM_USER_LOGIN, HTTP_METHODS.POST, {
      body: JSON.stringify({ data })
    })
    if (response && response.success && response.accessToken && response.data) {
      localStorage.setItem("userId", response.data.id);
      dispatch(login(response.data));
      if (!response.data.emailVerified) {
        localStorage.setItem("temp-token", response.accessToken);
        navigate("/verifyOtp");
      } else {
        localStorage.setItem("token", response.accessToken);
        localStorage.setItem("userType", USER_TYPES.WEBAPP);
        const role = response.data.role;
        if (role && role.active) {
          if (role.category === "org") {
            navigate("/admin-dashboard");
          } else {
            navigate("/dashboard");
          }
        }
      }
    } else {
      snackbar({
        message: response.message || "Invalid Credentials",
        variant: SnackBarVariant.ERROR
      });
    }
  }

  // disable will be false when values are valid and true
  const isDisable = () => !validationSchema.isValidSync(values)

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email().required("Enter email"),
    password: Yup.string().trim().min(MINIMUM_PASSWORD_LENGTH, 'Password must be 6 chars minimum.'),
  });

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center" style={{ padding: "1rem 0rem" }}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={values}
        onSubmit={(data, { setSubmitting }) => {
          handleSubmit();
          setSubmitting(false);
        }}
      >
        {() => (
          <Form style={{ maxWidth: "20rem" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Field
                variant="outlined"
                component={InputField}
                fullWidth
                required
                className={classes.formItem}
                label="Email"
                placeholder="Email"
                name="email"
                InputProps={{
                  value: values.email,
                  onChange: handleChange
                }}
              ></Field>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Field
                variant="outlined"
                component={InputField}
                fullWidth
                required
                className={classes.formItem}
                label="Password"
                placeholder="Password"
                name="password"
                type="password"
                InputProps={{
                  value: values.password,
                  onChange: handleChange
                }}
              ></Field>
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
              <Fab
                variant="extended"
                size="medium"
                style={{ width: "320px", color: "#fff" }}
                disabled={isDisable()}
                className={classes.submitBtn}
                type="submit"
              >
                Login
              </Fab>
            </Grid>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} className={classes.redirectText}>
              <Typography variant="body1">
                <span
                  className={classes.redirectLink}
                  onClick={() => navigate("/reset-password")}
                >
                  Forgot password?
                </span>
              </Typography>
            </Grid>
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '0.8rem' }} className={classes.redirectText}>
              <Typography variant="body1">
                Don't have an account?
                <span
                  className={classes.redirectLink}
                  onClick={() => navigate("/signup")}
                >
                  Signup
                </span>
              </Typography>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default IamUserLogin;