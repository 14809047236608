import { createStyles, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import moment from "moment";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../../utils/common.interface";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { DataTable } from "../../../common/Datatable/Datatable";
import { Pagination } from "../../../common/Datatable/types";


const useStyles = makeStyles(() =>
  createStyles({
    outlinee: {
      marginTop: "1.5rem",
      "& > *": {
        // outline: "red dashed 1px"
      }
    },
    popUp: {
      // margin: "0.8rem 0rem",
      "& > *": {
        margin: "10px 0"
      }
    },
    card: {
      backgroundColor: "#FAFAFA",
      margin: "1rem 1rem",
      minWidth: "13rem",
      transition: "transform .3s",
      "&:hover": {
        transform: "scale(1.1)"
      }
    },
    textField: {
      padding: "0.3rem",
      "& .MuiOutlinedInput-input": {
        padding: "0.3rem 1rem"
      }
    },
    forwardIcon: {
      backgroundColor: "#FF5C3E",
      color: "white",
      padding: "0.2rem",
      borderRadius: "1rem",
      cursor: "pointer"
    }
  })
);



const WritersPayouts: React.FC = () => {
  const classes = useStyles();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [payouts, setPayouts] = useState([]);
  const [totalPayouts, setTotalPayouts] = useState<number>(0);
  const [userHasPayouts, setUserHasPayouts] = useState<boolean>(true);


  useEffect(() => {
    if (mobileUserState.id) {
      fetchPayouts({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState.id])

  const fetchPayouts = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args
    callApi(PATHNAMES.GET_PAYOUTS_FOR_WRITER(mobileUserState.id, pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then((res) => {
      if (res && res.success && res.data && res.data) {
        setPayouts(res.data.payouts);
        setTotalPayouts(res.data.totalPayouts);
        setUserHasPayouts(oldValue => oldValue === res.data.userHasPayout ? oldValue : res.data.userHasPayout)
      }
    })
  }

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        sort: false,
        display: false,
        fliter: false
      }
    },
    {
      label: "Date",
      name: "withdrawlRequestTime",
      options: {
        customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY, h:mm A")
      }
    },
    {
      label: "Invoice Ref",
      name: "invoice",
      options: {
        customBodyRender: (data: any) => data?.referenceNumber
      }
    },
    {
      label: "Amount Withdrawn",
      name: "amountWithdrawn",
      options: {
        customBodyRender: (data: number) => `₹${data}`
      }
    },
    {
      label: "Amount Credited",
      name: "amountCredited",
      options: {
        customBodyRender: (data: number) => `₹${data}`
      }
    },
    {
      label: "Withdrawl Charges",
      name: "withdrawlCharges",
      options: {
        customBodyRender: (data: number) => `₹${data}`
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (data: string) => data?.toUpperCase() || ""
      }
    },
    {
      label: "Actions",
      name: "invoice",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { fontWeight: 600, fontSize: '14px' } }),
        customBodyRender: (data: any) => {
          if (data.invoiceUrl) {
            return <DownloadIcon
              color="primary"
              onClick={() => {
                const link = document.createElement("a");
                link.download = `invoice.pdf`;
                link.href = data.invoiceUrl;
                link.click();
              }} />
          }
        }
      }
    }

  ]


  const options: MUIDataTableOptions = {
    elevation: 0,
    // onRowClick: (rowData: string[]) => {
    //   if (rowData && rowData.length) {
    //     if (userState.role.category === "org") {
    //       return;
    //     }
    //     navigate(`/view-payout?payout=${rowData[0]}`)
    //   }
    // },
    filter: false,
    searchPlaceholder: "Search by Amount, Invoice Ref, Withdrawn by or Date[dd/mm/yyyy]",
    count: totalPayouts
  };

  return (
    <Grid container xs={12} spacing={2} className={classes.outlinee}>
      <Grid container xs={12}>
        <Grid item xs={12} style={{ padding: '1rem' }}>
          <Typography variant="h2" style={{ fontWeight: "bolder" }}> Payouts </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={1}>
            <Grid container >
              <Grid container xs={12}>
                {!userHasPayouts ?
                  <Typography variant="body1">
                    you don't have any payouts yet
                  </Typography> :
                  <DataTable
                    title={`Payouts (${totalPayouts})`}
                    rows={payouts}
                    columns={columns}
                    options={options}
                    hideSelectableRows={true}
                    serverSide
                    fetchData={fetchPayouts}
                  />
                }
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid >
  );
};

export default WritersPayouts;