import { createStyles, Fab, Grid, makeStyles, TextareaAutosize, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { AUTOSAVE_TIME_IN_MILLISECOND, HTTP_METHODS, PostType } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import CircularLoader from "../../common/CircularLoader";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";


const useStyles = makeStyles((theme) =>
  createStyles({
    navBtn: {
      position: 'fixed',
      top: '50%',
      zIndex: 50,
      height: "100%",
    },
    stick: {
      position: "sticky",
      top: "4.2rem",
      zIndex: 1000,
    },
    textArea: {
      width: "100%",
      border: 'none',
      padding: "1rem",
      outline: "none",
      fontSize: "16px",
      fontFamily: "Poppins",
      backgroundColor: "transparent",
      resize: "none"
    }
  })
);


let intervalId: any

const NewSeries: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const snackbar = useSnackBar();

  // content will be in json quill delta format 
  // it will change while writing in html string format becouse of compatibility and value change for component
  const [id, setId] = useState<string>("");
  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<any>('');
  // counter variables to keep eye on continuous draft update
  const [intervalChanged, setIntervalChanged] = useState<number>(0);
  const [callApiFn, setCallApiFn] = useState<boolean>(false); // used to make update api call inside interval fn
  const [loading, setLoading] = useState<boolean>(true);
  const [postType, setPostType] = useState<string>(PostType.TEXT);  // serieType is same for postType values


  // when mounts
  useEffect(() => {
    // since updating directly via setInterval is haywired.. use counter to update/create post data
    intervalId = setInterval(() => setIntervalChanged(v => v + 1), AUTOSAVE_TIME_IN_MILLISECOND)

    // stop updating content when unmount
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const seriesId = params.get("id");
      if (!seriesId) {
        setLoading(false)
      }
      // serieType is same for postType values
      const postType = params.get("type");
      if (postType) {
        if (postType === PostType.VIDEO) {
          setPostType(PostType.VIDEO)
        }
        if (postType === PostType.TEXT) {
          setPostType(PostType.TEXT)
        }
      }
      if (seriesId) {
        callApi(PATHNAMES.GET_SERIES_BY_ID(mobileUserState.id, seriesId), HTTP_METHODS.GET).then(res => {
          if (res && res.success && res.data) {
            setApiData(res.data)
          }
        })
      }
    }
  }, [location, mobileUserState]);


  // stop loader
  useEffect(() => {
    if (id) {
      setLoading(false)
    }
  }, [id])



  const setApiData = (series: any) => {
    const { id, title, description, chapters} = series
    setId(v => id);
    setTitle(v => title);
    setDescription((v: string) => description)
  
  }


  useEffect(() => {
    createOrupdateSeries()
    setCallApiFn(v => false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalChanged])



  const createOrupdateSeries = async () => {
    if (!callApiFn) {   // callApIFn becomes true whenever there is data to be updated or new data gets created
      return
    }
    if (id) {      // if post has id, then update other data which gets added or modified
      await updateSeriesContent()
      return
    }
    if (title && description) {  // id is not peresent in content means , document has not been saved in DB at all , create new 
      await createSeries()
      return
    }

  }

  const createSeries = async () => {
    setLoading(true)
    const data = {
      title,
      description,
      type: postType
    }
    const res = await callApi(PATHNAMES.CREATE_SERIES(), HTTP_METHODS.POST, {
      body: JSON.stringify({ data }),
    })
    if (res && res.success && res.data) {
      navigate(`/portal/rachnaye/series/edit?id=${res.data.id}&type=${postType}`, {replace:true})
      snackbar({
        message: "Your series gets auto saved. Keep writing",
        variant: SnackBarVariant.SUCCESS,
        duration: 5000
      })
    } else {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: res.message || "Error while creating post"
      })
    }
  }

  const updateSeriesContent = async () => {
    const data: any = {
      title,
      description,
    }
    const res = await callApi(PATHNAMES.UPDATE_SERIES(id), HTTP_METHODS.PUT, {
      body: JSON.stringify({ data }),
    })
    if (!res || !res.success) {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: res.message || "Error while updating series"
      })
    }
  }

  const button1Disable = () => !(title && description && id)

  if (loading) {
    return <CircularLoader />
  }

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center" style={{ padding: "1rem 3rem", }}>
      <Grid container xs={12} sm={6} md={6} lg={6} xl={6} ></Grid>  {/* container seperator */}
      <Grid container xs={12} sm={6} md={6} lg={6} xl={6} justify='flex-end' className={classes.stick}>
        <Grid container xs={5} md={4} justify='flex-end' >
          <Fab
            variant="extended"
            size="medium"
            disabled={button1Disable()}
            onClick={() => navigate(`/portal/rachnaye/series/chapter/create?seriesId=${id}`)}
          >
            <Typography variant="body1">+ Add New Part</Typography>
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          placeholder="Give Series a Title"
          fullWidth
          variant="standard"
          inputProps={{ style: { fontSize: "1.6rem" } }} // font size of input text
          value={title}
          onChange={(e) => {
            if (!title && e.target.value) {
              const value: string = e.target.value
              const firstLetter = value.slice(0, 1).toUpperCase()
              const remainingLetter = value.slice(1)
              return setTitle(firstLetter + remainingLetter)
            }
            return setTitle(e.target.value)
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ padding: "2rem 0rem" }}>
        <TextareaAutosize
          placeholder="Write Series Description ... "
          value={description}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setDescription(e.target.value)
            setCallApiFn(v => true)
          }}
          className={classes.textArea}
        />
      </Grid>


      {/* TODO: implement nav */}
      {/* {nextChapterId && <div className={classes.navBtn} style={{ left: 30, }} onClick={handleGoForward}>
        <IconButton style={{ backgroundColor: "#ededed" }}>
          <ChevronLeftIcon />
        </IconButton>
      </div>} */}
    </Grid>
  );
};

export default NewSeries;
