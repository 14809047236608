import { Box, Card, CardContent, CardMedia, createStyles, Grid, makeStyles, TextareaAutosize, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/rachnayeLight.png";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";


const useStyles = makeStyles((theme) =>
  createStyles({
    mainContainer: {
      padding: "2rem 0.6rem",
      width: "100%"
    },
    div1: {
      backgroundColor: "#F5F7F9",
      borderRadius: "4px", width: "100%"
    },
    div2: {
      padding: "1rem",
      width: "100%"
    },
    card: {
      display: "flex"
    },
    cardMedia: {
      width: 100,
      height: 144,
      borderRadius: 4,
      margin: "0.3rem"
    },
    contentBox: {
      width: "95%",
      cursor: "pointer"
    },
    deleteIconBox: {
      width: "5%"
    },
    emptyBox: {
      height: "3.5rem"
    },
    deleteBtn: {
      padding: "1rem 0.1rem",
      "& :hover": {
        cursor: 'pointer',
        color: theme.palette.primary.main
      }
    },
    textArea: {
      width: "100%",
      border: 'none',
      padding: "0.6rem 0rem",
      height: "1.9rem !important",
      outline: "none",
      fontSize: "12px",
      fontFamily: "Poppins",
      backgroundColor: "transparent",
      resize: "none"
    },
    timeStamp: {
      fontSize: "11px"
    },
    cardContent: {
      padding: "1rem 1.6rem 0rem 1.6rem"
    },
    seriesBottomLine1: {
      margin: "0rem 0.5rem",
      borderRadius: "10px",
      height: "0.2rem",
      backgroundColor: "grey"
    },
    seriesBottomLine2: {
      margin: "0.2rem 0.9rem",
      borderRadius: "10px",
      height: "0.2rem",
      backgroundColor: "grey"
    },
  })
);


interface IProps {
  items?: any[];
  fetchData?: (reset?: boolean) => void;
  lastElementRef?: (node: any) => void
}

const UsersDraftSeries: React.FC<IProps> = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const snackbar = useSnackBar();

  const [open, setOpen] = useState<boolean>(false)
  const [seriesIdToDelete, setSeriesIdToDelete] = useState<string>("") // seriesId

  const isLastElementinList = (index: number): boolean => props.items?.length === index + 1

  const deleteDraftSeries = async () => {
    if (seriesIdToDelete) {
      const res = await callApi(PATHNAMES.DELETE_DRAFT_SERIES(seriesIdToDelete), HTTP_METHODS.DELETE)
      if (res && res.success) {
        if (typeof props.fetchData === 'function') {
          await props.fetchData(true)
        }
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: res.message || "Post deleted successfully"
        })
      } else {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: res.message || "Error while deleting post"
        })
      }
      setOpen(v => false)
      setSeriesIdToDelete(v => "")
    }
  }


  return (
    <Grid container xs={12} className={classes.mainContainer}>

      {props.items && props.items.length > 0 && props?.items.map((series: any, index: number) => (
        // {data.map((post: any, index: number) => (

        <Grid key={series.id} ref={isLastElementinList(index) ? props.lastElementRef : null} container className={classes.div1}>
          <Grid item className={classes.div2}>

            <Card variant="outlined" className={classes.card}>
              <div>
                <CardMedia
                  component="img"
                  className={classes.cardMedia}
                  image={series.coverImage || logo}
                  alt="Cover Img"
                />
                <div className={classes.seriesBottomLine1} ></div>
                <div className={classes.seriesBottomLine2} ></div>

              </div>

              <Box className={classes.contentBox} onClick={() => navigate(`/portal/rachnaye/series/description?id=${series.id}`)}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="subtitle1">
                    {series.title}
                  </Typography>
                  {series.description ?
                    <TextareaAutosize
                      value={series.description}
                      className={classes.textArea}
                    />
                    : <Box className={classes.emptyBox} ></Box>

                  }
                  <Typography variant="subtitle2" className={classes.timeStamp}>
                    {`Last updated at : ${moment(series.updatedAt).format("LLL")}`}
                  </Typography>
                </CardContent>

              </Box>
              <Box className={classes.deleteIconBox}>
                <DeleteIcon fontSize="small" className={classes.deleteBtn} onClick={() => {
                  setSeriesIdToDelete(v => series.id)
                  setOpen(true)
                }} />
              </Box>

            </Card>
          </Grid>
        </Grid>
      ))}

      <ConfirmationDialog
        isOpen={open}
        onCancel={() => setOpen(false)}
        onConfirm={deleteDraftSeries}
        title={"Confirm Series Delete"}
        description={"Are you sure, you want to delete this Draft series permanently"}
      />
    </Grid>
  );
};

export default UsersDraftSeries;
