import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import CustomDivider from '../../common/Divider';
import DevicesStatsTable from './DevicesStatsTable';
import StatsCard from './StatsCard';



const statTypesAndTitle = [
  {
    type: 'active',
    title: "Active Users"
  },
  {
    type: 'inactive',
    title: "Inactive Users"
  },
  {
    type: 'deletedAccounts',
    title: "Deleted Users"
  },
  {
    type: 'uninstalled',
    title: "Uninstalled App Users"
  },
]

const MobileDevicesStats: React.FC = () => {
  const [selectedCardType, setSelectedCardType] = useState<string | null>(null)

  const handleClick = (selectedType: string) => {
    // select un select card
    setSelectedCardType(selectedType === selectedCardType ? null : selectedType)
  }

  const getTitle = () => {
    const stat = statTypesAndTitle.find(stat => stat.type === selectedCardType)
    return stat?.title || ""
  }
  return (
    <Grid container xs={12} spacing={2} justifyContent="center">
      <Grid item xs={12} style={{ margin: "1rem 0rem 0rem 0rem" }}>
        <Typography variant='h2'>Mobile User Stats</Typography>
      </Grid>

      <StatsCard title='Active Users' handleClick={handleClick} cardType='active' cardSelected={selectedCardType === 'active'} />
      <StatsCard title='Inactive Users' handleClick={handleClick} cardType='inactive' cardSelected={selectedCardType === 'inactive'} />
      <StatsCard title='Deleted Users' handleClick={handleClick} cardType='deletedAccounts' cardSelected={selectedCardType === 'deletedAccounts'} />
      <StatsCard title='Uninstalled App Users' handleClick={handleClick} cardType='uninstalled' cardSelected={selectedCardType === 'uninstalled'} />
      {
        selectedCardType !== null &&
        <>
          <CustomDivider />
          <DevicesStatsTable title={getTitle()} selectedType={selectedCardType} />
        </>
      }


    </Grid>
  )
}

export default MobileDevicesStats;