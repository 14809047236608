import { Box, Card, CardContent, CardMedia, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import Comment from '@material-ui/icons/Textsms';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from "moment";
import React from "react";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/rachnayeLight.png";
import PostViewsAndLike from "./PostViewsAndComment";

const useStyles = makeStyles((theme) =>
  createStyles({
    mainContainer: {
      padding: "2rem 0.6rem",
      width: "100%"
    },
    div1: {
      backgroundColor: "#F5F7F9",
      borderRadius: "4px", width: "100%"
    },
    div2: {
      padding: "1rem",
      width: "100%"
    },
    card: {
      display: "flex"
    },
    cardMedia: {
      width: 100,
      height: 144
    },
    contentBox: {
      width: "95%",
      cursor: "pointer"
    },
    cardContent: {
      padding: "1rem 1.6rem 0rem 1.6rem"
    },
    timeStamp: {
      fontSize: "11px"
    },
    emptyBox: {
      height: "3.5rem"
    },
    editor: {
      "& .ql-editor": {
        cursor: "pointer",
        height: "3.5rem",
        overflowY: "hidden",
        padding: "1rem 0rem 0rem 0rem",
      },
    },
  })
);

interface IProps {
  items?: any[];
  lastElementRef?: (node: any) => void;
}

const UsersPublishedRachnaye: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const navigate = useNavigate();

  const isLastElementinList = (index: number): boolean => props.items?.length === index + 1

  return (
    <Grid container xs={12} className={classes.mainContainer}>
      {
        props.items && props.items.length > 0 && props?.items.map((post: any, index: number) => (

          <Grid key={post.id} ref={isLastElementinList(index) ? props.lastElementRef : null} container className={classes.div1}>
            <Grid item className={classes.div2}>
              <Card variant="outlined" className={classes.card}>
                <CardMedia
                  component="img"
                  className={classes.cardMedia}
                  image={post.coverImage || logo}
                  alt="Cover Img"
                />

                <Box className={classes.contentBox} onClick={() => navigate(`/portal/rachnaye/post/read?id=${post.id}`)}>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="subtitle1">
                      {post.title}
                    </Typography>
                    {post.content ?
                      <ReactQuill
                        preserveWhitespace
                        readOnly
                        theme="bubble"
                        value={JSON.parse(post.content)}
                        className={classes.editor}
                      /> : <Box className={classes.emptyBox} ></Box>
                    }
                    <Grid container xs={12}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" className={classes.timeStamp}>
                          {`Published at : ${moment(post.publishedAt).format("LLL")}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container justify="flex-end" xs={12}>
                          <PostViewsAndLike image={ThumbUpAltIcon} text={post.totalReactions} />
                          <PostViewsAndLike image={VisibilityIcon} text={post.viewCount} />
                          <PostViewsAndLike image={Comment} text={post.totalComments} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

export default UsersPublishedRachnaye;
