import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import callApi from "../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../utils/common.interface";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { DataTable } from "../../common/Datatable/Datatable";
import { Pagination } from "../../common/Datatable/types";
interface IProps {
  publisherId: string;
  showTitle?: boolean;
}

const Payouts: React.FC<IProps> = (props) => {
  const [payouts, setPayouts] = useState([]);
  const [totalPayouts, setTotalPayouts] = useState<number>(0);
  const [publisherHasPayouts, setPublisherHasPayouts] = useState<boolean>(true);

  useEffect(() => {
    if (props.publisherId) {
      fetchPayouts({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.publisherId])

  const fetchPayouts = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args
    callApi(PATHNAMES.GET_PAYOUTS(props.publisherId, pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then((res) => {
      if (res && res.success && res.data && res.data) {
        setPayouts(res.data.payouts);
        setTotalPayouts(res.data.totalPayouts);
        // re-render only when we get new value
        setPublisherHasPayouts(oldValue => oldValue === res.data.publisherHasPayout ? oldValue : res.data.publisherHasPayout)

      }
    })
  }

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        sort: false,
        display: false,
        fliter: false
      }
    },
    {
      label: "Date",
      name: "withdrawlRequestTime",
      options: {
        customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY, h:mm A")
      }
    },
    {
      label: "Amount Withdrawn",
      name: "amountWithdrawn",
      options: {
        customBodyRender: (data: number) => `₹${data}`
      }
    },
    {
      label: "Amount Credited",
      name: "amountCredited",
      options: {
        customBodyRender: (data: number) => `₹${data}`
      }
    },
    {
      label: "Withdrawl Charges",
      name: "withdrawlCharges",
      options: {
        customBodyRender: (data: number) => `₹${data}`
      }
    },
    {
      label: "Invoice Ref",
      name: "invoice",
      options: {
        customBodyRender: (data: any) => data?.referenceNumber
      }
    },
    {
      label: "Withdrawn By",
      name: "withdrawnBy",
      options: {
        customBodyRender: (data: any) => `${data?.firstName} ${data?.lastName}`
      }
    }
  ]


  const options: MUIDataTableOptions = {
    elevation: 0,
    // onRowClick: (rowData: string[]) => {
    //   if (rowData && rowData.length) {
    //     if (userState.role.category === "org") {
    //       return;
    //     }
    //     navigate(`/view-payout?payout=${rowData[0]}`)
    //   }
    // },
    filter: false,
    searchPlaceholder: "Search by Amount, Invoice Ref, Withdrawn by or Date[dd/mm/yyyy]",
    count: totalPayouts
  };

  return (
    <Grid container xs={12}>
      {!publisherHasPayouts ?
        <Typography variant="body1">
          Publisher don't have any payouts yet
        </Typography> :
        <DataTable
          title={props.showTitle ? `Payouts (${totalPayouts})` : ""}
          rows={payouts}
          columns={columns}
          options={options}
          hideSelectableRows={true}
          serverSide
          fetchData={fetchPayouts}
        />
      }
    </Grid>
  )
}

export default Payouts;