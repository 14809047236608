import { CardMedia, Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/rachnayeLight.png";


interface IProps {
  post: {
    id: string,
    title: string;
    coverImage: string;
  };
  showTitle?: boolean;
  type: string;
  className?: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
    seriesBottomLine1: {
      margin: "0.3rem 0.3rem",
      borderRadius: "10px",
      height: "0.2rem",
      backgroundColor: "grey"
    },
    seriesBottomLine2: {
      margin: "0rem 1.2rem",
      borderRadius: "10px",
      height: "0.2rem",
      backgroundColor: "grey"
    },
    rachnayeCover: {
      width: "10rem",
      height: "14rem",
      borderRadius: 7,
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        width: "6rem",
        height: "9rem",
      }
    },
  }))


const RachnayeCard: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const handleClick = () => {
    if (props.type === 'post') {
      navigate(`/portal/rachnaye/post/read?id=${props.post.id}`)
    }
    if (props.type === 'series') {
      navigate(`/portal/rachnaye/series/read?id=${props.post.id}`)
    }
  }
  return (
    <Grid item style={{ marginBottom: "1rem" }}>
      <div style={{ position: "relative" }}
        className={`${classes.rachnayeCover} ${props.className}`}
        onClick={handleClick}
      >
        <CardMedia
          component="img"
          image={props.post.coverImage || logo}
          title={props.post.title}
          className={classes.rachnayeCover}
        />
        {props.type === 'series' &&
          <>
            <div className={classes.seriesBottomLine1} ></div>
            <div className={classes.seriesBottomLine2} ></div>
          </>
        }
        {
          props.showTitle &&
          <Typography variant="h4" style={{ padding: "1rem 0rem" }}>{props.post.title} </Typography>
        }
      </div>
    </Grid>


  )
}

export default RachnayeCard;