export default {
  root:  {
    "&.MuiFab-extended.MuiFab-sizeMedium": {
      // backgroundColor: "#FB8F67",
      backgroundColor: "#FF5C3E",
      boxShadow: "none",
      color: "#fff",
      fontWeight: 500,
      letterSpacing: 1.25,
      padding: "20px 20px",
      fontSize: 16,
      "&:hover": {
        backgroundColor: "#FEE2D7"
      },
      "&.secondaryBtn": {
        backgroundColor: "#fff",
        color: "#FF5C3E",
        border: "2px solid #FF5C3E"
      },
      "&.blackBackButton": {
        background: "#404040",
        "&:hover": {
          background: "#292929"
        }
      },
      "&.interiorImageButton": {
        background: "#FFFFFF",
        border: "2px solid #FF5C3E",
        color: "#FF5C3E",
        opacity: "100%",
        fontWeight: "bold"
      },
      "&.imageUploadButton": {
        background: "#FFFFFF",
        border: "2px solid #FF5C3E",
        color: "#FF5C3E",
        opacity: "100%",
        fontWeight: "bold"
      },
      "&.documentUploadButton": {
        background: "#FFFFFF",
        border: "2px solid #FF5C3E",
        color: "#FF5C3E",
        opacity: "100%",
        fontWeight: "bold"
      },
      "&.greyButton": {
        color: "#000",
        background: "#D5D5D5",
        "&:hover": {
          background: "#C1C1C1",
          color: "#000"
        }
      },
      "&.Mui-disabled.Mui-disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.18)",
        color: "rgba(0, 0, 0, 0.26)"
      }
    },
    "&.MuiFab-extended.MuiFab-sizeSmall": {
      backgroundColor: "#FF5C3E",
      boxShadow: "none",
      color: "#fff",
      fontWeight: 500,
      letterSpacing: 1.25,
      padding: "20px 20px",
      fontSize: 16,
      textTransform: 'initial',
      "&:hover": {
        backgroundColor: "#FEE2D7"
      },
      "&.secondaryBtn": {
        backgroundColor: "#fff",
        color: "#FF5C3E",
        border: "2px solid #FF5C3E"
      },
      "&.greyButton": {
        color: "#000",
        background: "#D5D5D5",
        "&:hover": {
          background: "#C1C1C1",
          color: "#000"
        }
      },
      "&.blackBackButton": {
        background: "#404040",
        "&:hover": {
          background: "#292929"
        }
      },
      "&.redButton": {
        background: "#ffe3e2",
        color: '#da1616',
        "&:hover": {
          background: "#ffd9d7"
        }
      },
    },
    "&.Mui-disabled.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.18)",
      color: "rgba(0, 0, 0, 0.26)"
    },
    borderRadius: "5px !important"
  }
};
