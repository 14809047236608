import {
  Fab,
  Grid,
  Typography,
  TextField,
  makeStyles,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-material-ui";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { IDistributor } from "../../../reducers/distributors/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS, NUMBERS_REGEX, DEFAULT_COUNTRY_CODE } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { ErrorHandler } from "../../../utils/utils";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  paper: {
    border: "1px solid black"
  },
  resetButton: {
    color: "black!important",
    backgroundColor: "#FFD36E!important",
    marginRight: "1rem",
  },
  disableTextColor: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black"
    }
  }
}))

const DistributorActions = {
  CREATE: "cerateDistributor",
  UPDATE: "updateDistributor"
}
const DistributorOrgType = {
  RACHNAYE: 'rachnaye',
  INDEPENDENT: 'independent',
}
const NewDistributor: React.FC = () => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const [distributors, setDistributors] = useState<IDistributor[]>([]);
  const [isSelection, setIsSelection] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.user);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false)
  const [actionType, setActionType] = useState<string>(DistributorActions.CREATE)
  const [distributorACOpen, setDistributorACOpen] = useState<boolean>(false);
  const [values, setValues] = useState<IDistributor>({
    id: '',
    name: "",
    orgType: DistributorOrgType.INDEPENDENT,
    refName: "",
    publisherId: [],
    email: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      pincode: ""
    },
    inventory: [],
    contactPerson: {
      name: "",
      contact: {
        phoneNumber: "",
        countryCode: DEFAULT_COUNTRY_CODE
      }
    }
  })

  useEffect(() => {
    if (location) {
      if (location.pathname.includes('update')) {
        setActionType(DistributorActions.UPDATE)
      }
      const params = new URLSearchParams(location.search);
      const publisherId = params.get("publisher");
      if (publisherId) {
        setValues({
          ...values,
          publisherId: [publisherId]
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (location && actionType === DistributorActions.UPDATE) {
      const params = new URLSearchParams(location.search);
      const distributorId = params.get("distributor");
      if (distributorId) {
        const existingDistributor = distributors.find(distributor => distributor.id === distributorId)
        if (existingDistributor) {
          setValues({
            ...existingDistributor,
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributors, actionType])

  useEffect(() => {
    if (userState && userState.id && userState.spaceId) {
      fetchDistributors();
    }
  }, [userState])

  useEffect(() => {
    /*
      if superAdmin is accessing distributors directly from side bar menu,
      then mark the superAdmin true and on create distributor page don't show
      distributor list.
    */
    if (location) {
      const params = new URLSearchParams(location.search);
      const publisherId = params.get("publisher");
      if (!publisherId) {
        if (userState?.role) {
          const role = userState.role;
          if (role && role.active) {
            if (role.category === "org") {
              setIsSuperAdmin(true)
            }
          }
        }
      }
    }
  }, [userState, location])



  const fetchDistributors = () => {
    callApi(PATHNAMES.GET_ALL_DISTRIBUTORS_LIST(), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setDistributors(res.data);
      }
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const setDefaultValues = () => {
    setIsSelection(false)
    setValues({
      id: '',
      name: "",
      orgType: DistributorOrgType.INDEPENDENT,
      refName: "",
      publisherId: [],
      email: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        country: "",
        pincode: ""
      },
      inventory: [],
      contactPerson: {
        name: "",
        contact: {
          phoneNumber: "",
          countryCode: DEFAULT_COUNTRY_CODE
        }
      }
    })
  }

  const handleSubmitForCreateBtn = async () => {
    try {
      // when user selects from list of option 
      if (isSelection) {
        const data = {
          publisherId: values.publisherId[0]
        }
        const response: any = await callApi(PATHNAMES.ADD_DISTRIBUTOR_TO_PUBLISHER(values.id), HTTP_METHODS.PATCH, {
          body: JSON.stringify({ data })
        })
        if (response?.success && response?.message) {
          if (values.publisherId[0] === userState.spaceId) {
            navigate("/distributors")
          } else {
            navigate(`/update-publisher?publisher=${values.publisherId[0]}`)
          }
          snackbar({
            message: "Distributor created successfully",
            variant: SnackBarVariant.SUCCESS
          })
          setDefaultValues()
        } else {
          throw new ErrorHandler(response.message)
        }
      }

      // when user fill up manually
      if (!isSelection) {
        const data: any = { ...values }
        delete data.id
        const res: any = await callApi(PATHNAMES.CREATE_DISTRIBUTOR, HTTP_METHODS.POST, {
          body: JSON.stringify({ data })
        })
        if (res?.success && res?.message) {
          // if publisher or admin came via side bar, then after create, navigate to same path
          if (values.publisherId[0] === userState.spaceId || isSuperAdmin) {
            navigate("/distributors")
          } else {
            navigate(`/update-publisher?publisher=${values.publisherId[0]}`)
          }
          snackbar({
            message: "Distributor created successfully",
            variant: SnackBarVariant.SUCCESS
          })
          setDefaultValues()
        } else {
          throw new ErrorHandler(res.message)
        }
      }

    } catch (error: any) {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
    }
  }

  // will be used by super admin to update distributor.
  const handleSubmitForUpdateBtn = async () => {
    try {
      const data: any = { ...values }
      delete data.id
      delete data.publisherId
      delete data.inventory
      const response: any = await callApi(PATHNAMES.UPDATE_DISTRIBUTOR(values.id), HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      })
      if (response && response.success && response.message) {
        navigate("/distributors")
        setDefaultValues()
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: response.message || "updated successfully",
        })
      } else {
        throw new ErrorHandler(response.message)
      }
    } catch (error: any) {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email().required("Email is required."),
    name: Yup.string().trim().min(3, 'Name is too short - should be 3 chars minimum.').required("Name is required."),
    address: Yup.object().shape({
      line1: Yup.string().trim().required(" Line 1 is required."),
      line2: Yup.string().trim().optional(),
      city: Yup.string().trim().required("City Name is required."),
      state: Yup.string().trim().required("State  is required."),
      country: Yup.string().trim().required("Country is required."),
      pincode: Yup.string().trim().required("Pincode  is required.")
        .matches(NUMBERS_REGEX, "Must be only digits")
        .min(6, 'Must be exactly 6 digits')
        .max(6, 'Must be exactly 6 digits'),
    }).required(),
    contactPerson: Yup.object().shape({
      name: Yup.string().trim()
        .min(3, 'Contact Name is too short - should be 3 chars minimum.')
        .max(40, "First name is too long - should be 40 chars maximum")
        .required("Contact Name is required."),
      contact: Yup.object().shape({
        phoneNumber: Yup.string().trim().required("PhonNumber is required.")
          .matches(NUMBERS_REGEX, "Only Numbers are allowed, no other character please")
          .min(10, 'Must be exactly 10 digits')
          .max(10, 'Must be exactly 10 digits'),
        countryCode: Yup.string().trim().required("Countrycode is required."),
      }).required()
    }).required(),
  });

  // disable will be false when values are valid and true

  return (
    <Grid container>
      <Grid container xs={12}>
        <Typography variant="h2" style={{ fontWeight: 600, marginTop: "1rem" }}>
          {actionType === DistributorActions.CREATE ? "Create " : "Update "}Distributor
        </Typography>
      </Grid>

      <Grid container xs={12} style={{ marginTop: "2rem" }}>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={values}
          onSubmit={(data, { setSubmitting }) => {
            if (actionType === DistributorActions.CREATE) {
              handleSubmitForCreateBtn();
            }
            if (actionType === DistributorActions.UPDATE) {
              handleSubmitForUpdateBtn();
            }
            setSubmitting(false);
          }}
        >
          {() => (
            <Form style={{ width: "100%" }}>
              <Grid container xs={12}>
                <Typography variant="h3" style={{ fontWeight: 600 }}>
                  General Info:
                </Typography>
              </Grid>
              <Grid container xs={12} spacing={2} style={{ marginTop: "0.5rem" }}>
                <Grid item xs={4}>
                  {isSuperAdmin ?
                    <Autocomplete
                      autoSelect
                      options={[]}
                      freeSolo
                      className={classes.disableTextColor}
                      value={values.name}
                      onChange={(event, newInputValue) => {
                        let existingDistributor
                        if (typeof newInputValue === "string") {
                          existingDistributor = distributors.find(distributor => distributor.name.trim().toLowerCase() === newInputValue.trim().toLowerCase())
                          if (typeof existingDistributor === "object") {
                            snackbar({
                              message: "Distributor with same name exists",
                              variant: SnackBarVariant.ERROR
                            })
                          } else {
                            setValues({
                              ...values,
                              name: newInputValue
                            })
                          }
                        }
                      }}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Distributor Name"
                          variant="outlined"
                          placeholder="Add distributor name" />
                      )}
                    />
                    :
                    <Autocomplete
                      open={distributorACOpen}
                      onClose={() => setDistributorACOpen(false)}
                      onInputChange={(e, value) => {
                        if (value.trim()) {
                          setDistributorACOpen(true);
                        } else {
                          setDistributorACOpen(false);
                        }
                      }}
                      autoSelect
                      options={distributors.map(item => item.name)}
                      freeSolo
                      className={classes.disableTextColor}
                      disabled={isSelection}
                      value={values.name}
                      onChange={(event: any, newValue: any) => {
                        let existingDistributor
                        if (typeof newValue === "string") {
                          existingDistributor = distributors.find(distributor => distributor.name.trim().toLowerCase() === newValue.trim().toLowerCase())
                          if (typeof existingDistributor === "object") {
                            setIsSelection(true)
                            setValues({
                              ...values,
                              ...existingDistributor,
                            })
                          } else {
                            setIsSelection(false)
                            setValues({
                              ...values,
                              name: newValue
                            })
                          }
                        }
                      }}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Distributor Name"
                          variant="outlined"
                          placeholder="Search for Distributor via name or email, Or create" />
                      )}
                    />}
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputField}
                    fullWidth
                    disabled={isSelection}
                    className={classes.disableTextColor}
                    label="Distributor Email"
                    placeholder="Distributor Email"
                    name="email"
                    InputProps={{
                      value: values.email?.trim(),
                      onChange: handleChange
                    }}
                  ></Field>
                </Grid>
                <Grid item container alignContent="center" xs={4}>
                  {isSuperAdmin ?
                    <Field
                      as={FormControlLabel}
                      type="checkbox"
                      style={{ margin: "0 1 0 0rem" }}
                      name="isLifeTime"
                      control={
                        <Checkbox
                          checked={values.orgType === DistributorOrgType.RACHNAYE}
                          onChange={(e: any) => {
                            setValues({ ...values, orgType: e.target.checked ? DistributorOrgType.RACHNAYE : DistributorOrgType.INDEPENDENT })
                          }}
                        />}
                      label="Create as Rachnaye Distributor"
                    />
                    : <></>}
                </Grid>
              </Grid>
              <Grid container xs={12} style={{ marginTop: "2rem" }}>
                <Typography variant="h3" style={{ fontWeight: 600 }}>
                  Address Info:
                </Typography>
              </Grid>
              <Grid container xs={12} spacing={2} style={{ marginTop: "0.5rem" }}>
                <Grid item xs={6}>
                  <Field
                    component={InputField}
                    fullWidth
                    disabled={isSelection}
                    required
                    className={classes.disableTextColor}
                    label="Address line1"
                    placeholder="Address line1"
                    name="address.line1"
                    InputProps={{
                      value: values.address.line1,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          address: {
                            ...values.address,
                            line1: e.target.value
                          }
                        })
                      }
                    }}
                  ></Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={InputField}
                    fullWidth
                    disabled={isSelection}
                    label="Address line2"
                    className={classes.disableTextColor}
                    placeholder="Address line2"
                    name="address.line2"
                    InputProps={{
                      value: values.address.line2,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          address: {
                            ...values.address,
                            line2: e.target.value
                          }
                        })
                      }
                    }}
                  ></Field>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputField}
                    fullWidth
                    disabled={isSelection}
                    required
                    className={classes.disableTextColor}
                    label="City"
                    placeholder="City"
                    name="address.city"
                    InputProps={{
                      value: values.address.city,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          address: {
                            ...values.address,
                            city: e.target.value
                          }
                        })
                      }
                    }}
                  ></Field>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputField}
                    fullWidth
                    disabled={isSelection}
                    required
                    className={classes.disableTextColor}
                    label="State"
                    placeholder="State"
                    name="address.state"
                    InputProps={{
                      value: values.address.state,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          address: {
                            ...values.address,
                            state: e.target.value
                          }
                        })
                      }
                    }}
                  ></Field>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputField}
                    fullWidth
                    disabled={isSelection}
                    required
                    className={classes.disableTextColor}
                    label="Country"
                    placeholder="Country"
                    name="address.country"
                    InputProps={{
                      value: values.address.country,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          address: {
                            ...values.address,
                            country: e.target.value
                          }
                        })
                      }
                    }}
                  ></Field>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={InputField}
                    fullWidth
                    disabled={isSelection}
                    required
                    className={classes.disableTextColor}
                    label="Pincode"
                    placeholder="Pincode"
                    name="address.pincode"
                    InputProps={{
                      value: values.address.pincode,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          address: {
                            ...values.address,
                            pincode: e.target.value?.trim()
                          }
                        })
                      }
                    }}
                  ></Field>
                </Grid>
              </Grid>
              <Grid container xs={12} style={{ marginTop: "2rem" }}>
                <Typography variant="h3" style={{ fontWeight: 600 }}>
                  Contact Info:
                </Typography>
              </Grid>
              <Grid container xs={12} spacing={2} style={{ marginTop: "0.5rem" }}>
                <Grid item xs={4}>
                  <Field
                    component={InputField}
                    fullWidth
                    disabled={isSelection}
                    required
                    className={classes.disableTextColor}
                    label="Name"
                    placeholder="Name"
                    name="contactPerson.name"
                    InputProps={{
                      value: values.contactPerson.name,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          contactPerson: {
                            ...values.contactPerson,
                            name: e.target.value
                          }
                        })
                      }
                    }}
                  ></Field>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={InputField}
                    fullWidth
                    disabled={isSelection}
                    required
                    className={classes.disableTextColor}
                    label="Phone"
                    placeholder="Phone"
                    name="contactPerson.contact.phoneNumber"
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      value: values.contactPerson.contact.phoneNumber,
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setValues({
                          ...values,
                          contactPerson: {
                            ...values.contactPerson,
                            contact: {
                              ...values.contactPerson.contact,
                              phoneNumber: e.target.value?.trim()
                            }
                          }
                        })
                      }
                    }}
                  ></Field>
                </Grid>
              </Grid>
              <Grid container xs={12} style={{ marginTop: "2rem" }}>
                {actionType === DistributorActions.CREATE &&
                  <>
                    <Grid item xs={1}>
                      <Fab
                        variant="extended"
                        size="medium"
                        className={classes.resetButton}
                        onClick={setDefaultValues}
                      >
                        Reset
                      </Fab>
                    </Grid>
                    <Grid item xs={1}>
                      <Fab
                        variant="extended"
                        size="medium"
                        type="submit"
                      >
                        Create
                      </Fab>
                    </Grid>
                  </>}
                {actionType === DistributorActions.UPDATE &&
                  <Grid item xs={1}>
                    <Fab
                      variant="extended"
                      size="medium"
                      type="submit"
                    >
                      Update
                    </Fab>
                  </Grid>
                }
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default NewDistributor;