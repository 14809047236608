import { Chip, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { IOrderResponse } from "../../../../reducers/orders/types";
import { OrderItemTypes } from "../../../../utils/constants";
import logo from "../../../../assets/rachnayeLight.png";


interface IProps {
  order: IOrderResponse
}

const useStyles = makeStyles(() => ({
  borderStyle: {
    borderRadius: 4,
    border: "1px solid #9e9694"
  },
  bookCoverContainer: { width: "4rem", height: "6rem", },
  bookCoverStyle: { objectFit: "cover", height: "100%", width: "100%", borderRadius: 4 },
  tagChip: { borderRadius: 5, margin: '0.6rem 1rem 0rem 0rem', fontSize: '0.9rem' },
  priceText: { textDecoration: "line-through", marginRight: "0.7rem" },
  fontWeight200: { fontWeight: 200 },
}))

const OrderBookContainer: React.FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.borderStyle}>

      {props.order.orderItems.map((orderItem) => (

        <Grid container xs={12} style={{ padding: '0.3rem' }}>
          {/* IMAGE  */}
          <Grid item xl={2} lg={2} md={3} sm={3} xs={4} style={{ padding: '0.6rem 1rem' }} >
            <Grid container xs={12} className={classes.bookCoverContainer} justify='center' >
              <Paper className={classes.bookCoverContainer} >
                <img alt={orderItem.bookName} className={classes.bookCoverStyle} src={orderItem.imageUrl || logo} />
              </Paper>
            </Grid>
          </Grid>
          {/* DESCRIPTION  */}
          <Grid item xl={7} lg={8} md={9} sm={9} xs={8} style={{ padding: "1rem 0.6rem" }}>
            <Typography variant='h3'>{orderItem.bookName}</Typography>
            <Chip
              color='primary'
              size='small'
              className={classes.tagChip}
              label={orderItem.orderType.replace("_", " ")}
            />
            {orderItem.orderType === OrderItemTypes.RENTAL_DIGITAL &&
              <Chip
                color='primary'
                size='small'
                className={classes.tagChip}
                label={orderItem.rentalType}
              />
            }
            {orderItem.unitOfferPrice > 0 ?
              <div style={{ display: 'flex', marginTop: "0.6rem" }}>
                <Typography variant="h3" className={`${classes.priceText} ${classes.fontWeight200}`}>{" ₹" + orderItem.unitPrice}</Typography>
                <Typography variant="h3" className={classes.fontWeight200}>{" ₹" + orderItem.unitOfferPrice}</Typography>

              </div> :
              <div style={{ display: 'flex', marginTop: "1rem" }}>
                <Typography variant="h3" className={classes.fontWeight200}>{" ₹" + orderItem.unitPrice}</Typography>
              </div>

            }
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default OrderBookContainer;