import { Typography, createStyles, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    mainDiv: {
      display: "flex",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      paddingLeft: "0.6rem",
    },
    icon: {
      fontSize: "16px",
      color: "#7C7C7C",
      paddingRight: "0.2rem",
      "& :hover": {
        color: 'red'
      }
    },
    text: {
      fontSize: "11px",
    }
  }))

interface IPostViewAndLike {
  image: any;
  text: string;
  reactionGiven?: boolean;
  onClick?: () => void;
  style?: {
    icon?: object;
    text?: object;
    mainDiv?: object
  }
}
const PostViewsAndLike: React.FC<IPostViewAndLike> = (props) => {
  const classes = useStyles();
  const ImageIcon = props.image

  return (
    <div style={{ ...props.style?.mainDiv }}
      className={classes.mainDiv}
      onClick={props.onClick}
    >
      <ImageIcon className={classes.icon} style={{ color: props.reactionGiven ? "blue" : 'default', ...props.style?.icon }} />
      <Typography variant="subtitle2" className={classes.text} style={{ ...props.style?.text }}>
        {props.text}
      </Typography>
    </div>
  )
}

export default PostViewsAndLike;
