import { Card, CardMedia, createStyles, Dialog, DialogContent, Fab, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Comment from '@material-ui/icons/Textsms';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../../assets/rachnayeLight.png";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS, ReactionOn, ReactionTypes } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import CircularLoader from "../../common/CircularLoader";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import VideoPlayer from "../../common/VideoPlayer";
import CommentsDialogBoxSeries from "./CommentsDialogBoxSeries";
import PostViewsAndLike from "./PostViewsAndComment";
import GuestUsersNameChange from "../../common/GuestUsersNameChange";


const useStyles = makeStyles((theme) =>
  createStyles({
    mainPage: {
      padding: "2rem 6rem"
    },
    navBtn: {
      position: 'fixed',
      top: '50%',
      zIndex: 50,
      height: "100%",
    },
    userInfoHeader: {
      borderBottom: "1px solid black",
      paddingBottom: "0.7rem"
    },
    postTitle: {
      padding: '1rem 0rem',
      fontWeight: 300,
      fontSize: "1.4rem",
      wordBreak: "break-word",
      lineHeight: "30px"
    },
    imgBox: {
      padding: '0.4rem'
    },
    userNamebox: {
      padding: '0.2rem 0.6rem'
    },
    card: {
      display: "flex",
      height: 170
    },
    cardMedia: {
      width: 120,
      height: 170
    },
    mainAvatar: {
      backgroundColor: "#FB8F67",
      width: "2.6rem",
      height: "2.6rem",
    },
    userPenNameText: {
      fontSize: "0.8rem"
    },
    qContainer: {
      "& .ql-container": {
        fontSize: "1rem",
        fontFamily: theme.typography.h2.fontFamily,
        border: 'none',
        padding: "1rem 0rem"
      },
      "& .ql-editor": {
        lineHeight: "30px",
        overflowY: "visible",
        padding: "0.6rem 0rem",  // add padding when want to move quill editor data
      }
    },
  })
);


export const ViewOnType = {
  SERIES: 'series',
  CHAPTER: 'chapter'
}

const ReadChapter: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const snackbar = useSnackBar();

  // content will be in json quill delta format 
  // it will change while writing in html string format becouse of compatibility and value change for component
  const [chapter, setChapter] = useState<any>({
    content: JSON.stringify('')
  });
  const [seriesShortDetail, setSeriesShortDetail] = useState<any>({});
  const [openCommentBox, setOpenCommentBox] = useState<boolean>(false)
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [showBuySeriesModal, setShowBuySeriesModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true);
  const [askForGuestUsersInfoOpen, setAskForGuestUsersInfoOpen] = useState<boolean>(false); // when going to comment or like

  // fetch chapter detail
  useEffect(() => {
    getChapterData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, mobileUserState]);

  // add chapter view
  useEffect(() => {
    // user's view not recorded
    if (chapter.id && !chapter.userViewGiven) {
      addUsersViewOnChapter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter.id]);

  useEffect(() => {
    if (chapter.id) {
      setLoading(false)
    }
  }, [chapter]);


  const getSeriesShortDetail = () => {
    if (location) {
      setModalLoading(true)
      const params = new URLSearchParams(location.search);
      const seriesId = params.get("seriesId");
      if (seriesId) {
        callApi(PATHNAMES.SERIES_SHORT_DETAIL(seriesId), HTTP_METHODS.GET).then(res => {
          if (res && res.success && res.data) {
            setSeriesShortDetail((v: any) => res.data)
            setModalLoading(false)
          }
        })
      }
    }
  }


  const addUsersViewOnChapter = () => {
    if (chapter.id && chapter.seriesId) {
      const data = {
        chapterId: chapter.id,
        seriesId: chapter.seriesId,
        viewOn: ViewOnType.CHAPTER
      }
      callApi(PATHNAMES.ADD_USER_VIEW_FOR_CHAPTER(), HTTP_METHODS.POST, {
        body: JSON.stringify({ data }),
      }).then(res => {
        if (res && res.success && res.data) {
          // do nothing,
        }
      })
    }
  }


  const getChapterData = () => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const seriesId = params.get("seriesId");
      const chapterId = params.get("chapterId");
      if (seriesId && chapterId) {
        callApi(PATHNAMES.GET_SERIES_CHAPTER_BY_ID_FOR_READERS(seriesId, chapterId), HTTP_METHODS.GET).then(res => {
          if (res && res.success && res.data) {
            setChapter((v: any) => res.data)
          }
          if (res?.status === 200) {
            return
          }
          // chapter is paid, show modal
          if (!res?.success && res.status === 402) {
            getSeriesShortDetail()
            setShowBuySeriesModal(true)
            setLoading(false)
          }
          if (res?.status !== 402) {
            // any other error occured
            snackbar({
              variant: SnackBarVariant.ERROR,
              message: res.message || "Error while fetching chapter"
            })
          }
        })
      }
    }
  }

  const addLikeReactionToChapter = () => {
    if (mobileUserState.firstName.includes("Guest_")) {
      setAskForGuestUsersInfoOpen(true)
      return
    }
    if (chapter.id) {
      callApi(PATHNAMES.ADD_REACTION(), HTTP_METHODS.POST, {
        body: JSON.stringify({
          data: {
            reactionOnId: chapter.id,
            reactionType: ReactionTypes.LIKE,
            reactionOn: ReactionOn.CHAPTER,
          }
        }),
      }).then(res => {
        if (res && res.success && res.data) {
          getChapterData()
        }
      })
    }
  }


  // to handle closing of comment box when clicked outside comment Component
  useEffect(() => {
    const closeCommentDiv = () => {
      setOpenCommentBox(false)
    }
    document.body.addEventListener("click", closeCommentDiv)
    return () => document.body.removeEventListener("click", closeCommentDiv)
  }, []);

  // to handle opening and closing of comment box when clicked on comment icon on post view
  const handleCommentBoxOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    // if user is guest user ask for details before letting him comment on post
    if (mobileUserState.firstName.includes("Guest_")) {
      setAskForGuestUsersInfoOpen(true)
    } else {
      setOpenCommentBox(v => !openCommentBox)
    }
  }

  const handleGoForward = () => {
    navigate(`/portal/rachnaye/chapter/read?seriesId=${chapter.seriesId}&chapterId=${chapter.nextChapterId}`)
  }

  const handleGoBackword = () => {
    navigate(`/portal/rachnaye/chapter/read?seriesId=${chapter.seriesId}&chapterId=${chapter.previousChapterId}`)
  }

  const handleProceedToBuySeries = () => {
    navigate(`/portal/rachnaye/series/order?id=${seriesShortDetail.id}`)
    setShowBuySeriesModal(false)
  }

  if (loading) {
    return <CircularLoader />
  }

  return (
    // PAGE CONTAINER
    <Grid container spacing={3} xs={12} sm={12} md={12} lg={12} xl={12} justify={openCommentBox ? "flex-start" : "center"} className={classes.mainPage}>
      {/* MIDDLE VIEW CONTAINER */}
      <Grid container xs={9} >
        {/* POST TITLE CONTAINER */}
        <Grid container xs={12} direction="column" justify="center" alignItems="center" style={{ minHeight: "30vh", }}>
          <Grid container justify="center" alignItems="center" style={{ width: "80%" }}>
            <Typography align="center" className={classes.postTitle}>
              {chapter.title}
            </Typography>
          </Grid>
          <Grid container justify="center" alignItems="center" style={{ paddingBottom: "0rem" }}>
            <Typography style={{ fontSize: "11px" }}>
              {`${moment(chapter.publishedAt).format("LL")}`}
            </Typography>
          </Grid>
          <Grid container justify="center" alignItems="center" style={{ marginTop: "1rem" }}>
            <PostViewsAndLike
              image={ThumbUpAltIcon}
              text={chapter.reactionsOnChapter}
              reactionGiven={chapter.userReactionGiven}
              onClick={addLikeReactionToChapter}
              style={{
                icon: { fontSize: "26px", },
                text: { fontSize: "20px", padding: "0rem 0.2rem" },
                mainDiv: { padding: "0.8rem 1.2rem", cursor: "pointer" }
              }} />
            <PostViewsAndLike
              image={VisibilityIcon}
              text={chapter.viewsOnChapter}
              reactionGiven={chapter.userViewGiven}
              style={{
                icon: { fontSize: "26px" },
                text: { fontSize: "20px", padding: "0rem 0.2rem" }

              }} />
            <div onClick={handleCommentBoxOpen} style={{ cursor: "pointer" }}>
              <PostViewsAndLike image={Comment}
                text={chapter.commentsOnChapter}
                reactionGiven={chapter.userCommentGiven}
                style={{
                  icon: { fontSize: "26px" },
                  text: { fontSize: "20px", padding: "0rem 0.2rem" },
                  mainDiv: { padding: "0.8rem 1.2rem", }

                }} />
            </div>
          </Grid>
        </Grid>

        {/* POST CONTENT CONTAINER */}
        <Grid container xs={12}>

          {chapter.type === "video" ?
            <VideoPlayer videoLink={chapter.mediaUrl} />
            :
            chapter.content ?
              <ReactQuill
                preserveWhitespace
                readOnly
                theme="bubble"
                value={JSON.parse(chapter.content)}
                className={classes.qContainer}
              /> : <></>
          }

        </Grid>
      </Grid>

      <CommentsDialogBoxSeries
        open={openCommentBox}
        chapterId={chapter.id}
        fetchPost={getChapterData}
        totalComments={chapter.commentsOnChapter}
        handleClose={() => setOpenCommentBox(false)} />

      <GuestUsersNameChange open={askForGuestUsersInfoOpen} handleClose={() => setAskForGuestUsersInfoOpen(false)} titleMessage="Add your Info" subMessage="You are a guest user , you need to add your actual name before commenting on this Rachnaye" />

      {chapter.previousChapterId && <div className={classes.navBtn} style={{ left: 30, }} onClick={handleGoBackword}>
        <IconButton style={{ backgroundColor: "#ededed" }}>
          <ChevronLeftIcon />
        </IconButton>
      </div>}
      {chapter.nextChapterId &&
        <div className={classes.navBtn} style={{ right: 40, }}>
          <IconButton style={{ backgroundColor: "#ededed" }} onClick={handleGoForward}>
            <ChevronRightIcon />
          </IconButton>
        </div>
      }


      <Dialog
        maxWidth={'sm'}
        fullWidth
        open={showBuySeriesModal}
        onClose={() => setShowBuySeriesModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogContent>
          <Grid container xs={12} justify="center" alignItems="center" style={{ minHeight: "50vh" }}>
            {modalLoading ?
              <CircularLoader />
              :
              <Grid container justify="center">
                <Grid container justify="center" style={{ margin: "0.6rem 0rem" }}>
                  <Typography align="center" variant='h4'>{`buy now unlocking this series you are supporting ${seriesShortDetail?.user?.penName ? seriesShortDetail?.user?.penName : seriesShortDetail?.user?.firstName?.concat(" ", seriesShortDetail?.user?.lastName)}`}</Typography>
                  <Typography align="center" variant='h6' style={{ paddingTop: '0.6rem' }}>{"Handpicked by editorial experts"}</Typography>
                </Grid>
                <Grid container justify="center" style={{ margin: "0.6rem 0rem" }}>
                  <Card variant="outlined" className={classes.card}>
                    <CardMedia
                      component="img"
                      className={classes.cardMedia}
                      image={seriesShortDetail.coverImage || logo}
                      alt="Cover Img"
                    />
                  </Card>
                </Grid>
                <Grid container justify="center" style={{ margin: "0.6rem 0rem" }}>
                  <Typography align="center" variant='h3'>{seriesShortDetail.title}</Typography>
                </Grid>
                <Grid container justify="center" style={{ margin: "0.6rem 0rem" }}>
                  <Typography align="center" style={{ fontWeight: 600 }} variant='h3'>{`Price : ${seriesShortDetail.price}`}</Typography>
                </Grid>
                <Grid container justify="center" style={{ margin: "0.6rem 0rem" }}>
                  <Fab variant="extended" size="small"
                    onClick={handleProceedToBuySeries}
                  >
                    Buy Now
                  </Fab>
                </Grid>

              </Grid>}
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid >
  );
};

export default ReadChapter;
