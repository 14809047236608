import { createSlice } from '@reduxjs/toolkit';
import { IMobileUserState } from "./types";

const initialState: IMobileUserState = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  dateOfBirth: "",
  status: "",
  appLanguage: "",
  preferredCategories: [],
  readingLanguage: [],
  emailStatus: "",
  phoneVerified: false,
  isVerifiedContentCreator: false,
  addresses: [],
  cart: []
};

const mobileUserSlice = createSlice({
  name: 'mobileUser',
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    login(state, action) {
      return {
        ...state,
        ...action.payload
      }
    },
    signup(state, action) {
      return {
        ...state,
        ...action.payload
      }
    },
    updateMobileUser(state, action) {
      return {
        ...state,
        ...action.payload
      }
    }
  }
})

export const { logout, login, signup, updateMobileUser } = mobileUserSlice.actions;
export default mobileUserSlice.reducer;
