import {
  Grid,
  Paper,
  Typography,
  makeStyles
} from "@material-ui/core";
import React, { useState } from "react";
import { IOrderItem } from "../../../../reducers/orders/types";
import { OrderItemTypes } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "../../../common/ConfirmationDialog";
import logo from "../../../../assets/rachnayeLight.png"
import RachnayeEssential from "../../../../assets/RachnayeEssential.svg"

interface IProps {
  imageUrl: string;
  bookId: string;
  bookName: string;
  preOrderAvailable: boolean;
  rachnayeEssential: boolean;
  rachnayeFullfilled: boolean;
}

const useStyles = makeStyles(() => ({
  borderStyle: {
    borderRadius: 4,
    border: "1px solid #9e9694"
  },
  bookCoverContainer: {
    width: "8.6rem",
    height: "12rem",
    position: "relative"
  },
  bookCoverStyle: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    borderRadius: 4,
    cursor: "pointer"
  },
  tagChip: {
    borderRadius: 5,
    margin: '0.6rem 1rem 0rem 0rem',
    fontSize: '0.9rem'
  },
  priceText: {
    textDecoration: "line-through",
    marginRight: "0.7rem"
  },
  essentialDiv: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    bottom: 20,
    left: "50%",
    backgroundColor: "#FFD36E",
    width: "100%", transform: "translateX(-50%)",
  },
  fullfilledDiv: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    bottom: 20,
    left: "50%",
    backgroundColor: "#FFD36E",
    width: "100%", transform: "translateX(-50%)",
  },
  proOrderText: {
    position: "absolute",
    color: "#ffae1a",
    bottom: "2rem",
    right: "3rem",
    fontWeight: 700,
    fontSize: "1rem",
    transform: "rotate(-90deg)",
  },
  img: {
    height: "1rem",
  },
}))

const CartBookImage: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Grid container justify="center" item xs={12}>
      <Paper className={classes.bookCoverContainer} >
        <img
          alt={props.bookName}
          title={props.bookName}
          className={classes.bookCoverStyle}
          src={props.imageUrl || logo}
          onClick={() => navigate(`/portal/book?id=${props.bookId}`)}
          style={{ filter: props.preOrderAvailable ? "blur(2px)" : "blur(0)" }}
        />

        {props.rachnayeEssential &&
          <div className={classes.essentialDiv}>
            <img alt="Rachnaye Essential" src={RachnayeEssential} className={classes.img} />
          </div>
        }
        {props.rachnayeFullfilled &&
          <div className={classes.essentialDiv}>
            <img alt="Rachnaye Essential" src={RachnayeEssential} className={classes.img} />
          </div>
        }
        {props.preOrderAvailable &&
          <Typography variant="h3" className={classes.proOrderText} >Preorder</Typography>
        }
      </Paper>
    </Grid>
  )
}

export default CartBookImage;