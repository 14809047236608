import { Grid } from '@material-ui/core';
import React from 'react'
import CreateOfflineOrder from './CreateOfflineOrder';
import OfflineOrders from './OfflineOrders';

const PlatformSettings: React.FC = () => {
  return (
    <Grid container xs={12}>
      <CreateOfflineOrder />
      <OfflineOrders />
    </Grid>
  )
}

export default PlatformSettings;