import { Box, Checkbox, Chip, createStyles, Fab, FormControl, FormControlLabel, Grid, IconButton, InputLabel, makeStyles, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PhotoIcon from '@material-ui/icons/Photo';
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { getAgeRangeLabelByValue, getCategoryLabelByValue, getLanguageLabelByValue, isValidImage } from "../../../utils/bookUtils";
import { AUTOSAVE_TIME_IN_MILLISECOND, BOOK_AGE_RANGE, BOOK_CATEGORIES, BOOK_LANGUAGES, HTTP_METHODS, ResourceTypes, SeriesStatus } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { deleteFileFromS3, uploadFileToS3 } from "../../../utils/s3Util";
import CircularLoader from "../../common/CircularLoader";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";


interface ICopyright {
  id: string;
  title: string;
  description: string;
}

interface IAgeRange {
  upperLimit: number;
  lowerLimit: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    stick: {
      position: "sticky",
      top: "4.2rem",
      zIndex: 1000,
    },
    textArea: {
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(15px, 1px) scale(0.75)"
      },
    },
    tagsChip: {
      borderRadius: 2,
      background: "#FAFAFA",
      border: "1px solid #E8E8E8",
      "&.MuiAutocomplete-tag": {
        margin: "10px 0px 5px 10px"
      }
    },
    cancelButton: {
      "&:hover": {
        color: theme.palette.primary.main
      }
    },
    copyrightSelectMenu: {
      marginTop: "0rem",
      width: "100%",
      height: "3rem",
      fontSize: '12px',
      whiteSpace: 'normal',
      "& .MuiSelect-selectMenu": {
        whiteSpace: "normal"
      }
    },
    margin: {
      paddingTop: '0.4rem',
      height: '1.8rem',
      "& .MuiFilledInput-input": {
        paddingTop: '10px',
        fontSize: '12px',
      },
      "& .MuiAutocomplete-clearIndicator": {
        fontSize: '1rem'
      },
      "& .MuiFilledInput-root": {
        "border-radius": '8px',
        "paddingTop": '1px'
      },
      "& .MuiFilledInput-underline:before": {
        "border-bottom": 'none'
      },
      "& .MuiFilledInput-underline:after": {
        "border-bottom": 'none'
      },
    },
    selectMenu: {
      marginTop: "0rem",
      width: "100%",
      height: "3rem",
      fontSize: '12px'
    },
    imgBox: {
      cursor: "pointer",
      height: "13rem",
      width: "9rem",
      backgroundColor: "#EEE",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    },
  })
);


let intervalId: any

const PublishSeries: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const snackbar = useSnackBar();
  const inputFile = useRef<HTMLInputElement>(null);

  // content will be in json quill delta format 
  // it will change while writing in html string format becouse of compatibility and value change for component
  const [seriesId, setSeriesId] = useState<string>("");
  const [chapters, setChapters] = useState<any[]>([]);
  const [draftChapterList, setDraftChapterList] = useState<any[]>([]);
  const [language, setLanguage] = useState<string>()
  const [category, setCategory] = useState<string>()
  const [keywords, setKeywords] = useState<string[]>([])
  const [seriesCopyright, setSeriesCopyright] = useState<string>("")
  const [ageRange, setAgeRange] = useState<IAgeRange>()
  const [thumbnail, setThumbnail] = useState<string>() // value to be updated in DB
  const [thumbnailForPreview, setThumbnailForPreview] = useState<string>() // signed url to show img

  // counter variables to keep eye on continuous draft update
  const [intervalChanged, setIntervalChanged] = useState<number>(0);
  const [callApiFn, setCallApiFn] = useState<boolean>(false); // used to make update api call inside interval fn
  const [agreeTermsCondtn, setAgreeTermsCondtn] = useState<boolean>(false); // on publish page
  const [copyrights, setCopyrights] = useState<ICopyright[]>([])
  const [paygPlans, setPaygPlans] = useState<object[]>([])
  const [loading, setLoading] = useState<boolean>(false);

  const [seriesType, setSeriesType] = useState<string>("free")
  const [selectedPlanId, setSelectedPlanId] = useState<string>("")
  const [publishTillChapterId, setPublishTillChapterId] = useState<string>("")
  const [seriesStatus, setSeriesStatus] = useState<string>("")

  const [chaptersForFreeChapterSelection, setChaptersForFreeChapterSelection] = useState<any[]>([])
  const [freeTillChapterId, setFreeTillChapterId] = useState<string>("")

  //ageRange and copyright values specifically for dropdown menu handling
  const [selectedAgeRange, setSelectedAgeRange] = useState<string>("")
  const [selectedCopyrightTitle, setSelectedCopyrightTitle] = useState<string>("")
  const [selectedCopyrightsDescription, setSelectedCopyrightsDescription] = useState<string>("")
  const [disableFieldsForPublishedSeries, setDisableFieldsForPublishedSeries] = useState<boolean>(false)


  // when mounts
  useEffect(() => {
    callApi(PATHNAMES.GET_COPYRIGHTS_INFO(), HTTP_METHODS.GET).then((res) => {
      if (res && res.success) {
        setCopyrights(res.data.content.copyrights)
      }
    })
    callApi(PATHNAMES.GET_PAYG_PLANS_FOR_WRITER(), HTTP_METHODS.GET).then((res) => {
      if (res && res.success) {
        setPaygPlans(res.data)
      }
    })
    // since updating directly via setInterval is haywired.. use counter to update/create post data
    intervalId = setInterval(() => setIntervalChanged(v => v + 1), AUTOSAVE_TIME_IN_MILLISECOND)
    // stop updating content when unmount
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const _seriesId = params.get("seriesId");
      if (!_seriesId) {
        return
      }
      if (_seriesId) {
        fetchSeriesInfo(_seriesId)
        fetchDraftChapterList(_seriesId)
        setLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, mobileUserState]);


  useEffect(() => {
    updateSeries()
    setCallApiFn(v => false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalChanged])

  useEffect(() => {
    // the draft chapter list hai , usme se ek array banana hai .
    const indexOfPublishTillChapterId = draftChapterList.findIndex(chapter => chapter.id === publishTillChapterId)
    //  array from which free chapters will be selected
    const tobeSelectedChapters = draftChapterList.slice(0, indexOfPublishTillChapterId + 1)
    const onlyChaptersHavingPageTypeChapter = tobeSelectedChapters.filter(chapter => chapter.chapterType === "chapter")
    setChaptersForFreeChapterSelection(v => onlyChaptersHavingPageTypeChapter)

  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [publishTillChapterId])

  useEffect(() => {
    if (ageRange && ageRange.upperLimit) {
      const label = getAgeRangeLabelByValue(ageRange)
      setSelectedAgeRange(label)
    }
  }, [ageRange])


  const fetchDraftChapterList = (seriesId: string) => {
    callApi(PATHNAMES.GET_DRAFT_CHAPTER_LIST(seriesId), HTTP_METHODS.GET).then((res) => {
      if (res && res.success) {
        setDraftChapterList(v => res.data)
      }
    })
  }


  const fetchSeriesInfo = (seriesId: string) => {
    callApi(PATHNAMES.GET_SERIES_BY_ID(mobileUserState.id, seriesId), HTTP_METHODS.GET).then(res => {
      if (res && res.success && res.data) {
        if (res.data.status === "banned") {
          snackbar({
            variant: SnackBarVariant.ERROR,
            message: "Series is banned"
          })
          navigate(`/portal/myRachnaye`, { replace: true })
        }
        setSeriesApiData(res.data)
      }
    }).catch(error => {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Error while fetching series"
      })
      // if series not found or eny error occurs then route to
      navigate(`/portal/myRachnaye`, { replace: true })
    })
  }

  const setSeriesApiData = (series: any) => {
    setSeriesId(v => series.id);
    setLanguage(v => series.language);
    setCategory(v => series.category);
    setKeywords(v => series.tags)
    setChapters(v => series.chapters)
    setSeriesStatus(v => series.status)
    if (series.ageRange) {
      setAgeRange(v => series.ageRange)
    }
    if (series.coverImage) {
      setThumbnailForPreview(v => series.coverImage)
      const urlFinder = ".com/"
      const splittedText = series.coverImage.split(urlFinder)
      if (splittedText.length > 1) {
        // amazom url + image key
        setThumbnail(v => splittedText[1])
      }
    }
    if (series.copyright) {
      setSeriesCopyright(series.copyright.id)
      setSelectedCopyrightTitle(series.copyright.title)
      setSelectedCopyrightsDescription(series.copyright.description)
    }
    if (series.status === SeriesStatus.PUBLISHED) {
      setDisableFieldsForPublishedSeries(true)
    }
  }


  const updateSeries = async () => {
    if (!callApiFn) {   // callApIFn becomes true whenever there is data to be updated or new data gets created
      return
    }
    if (seriesId) {      // if post has id, then update other data which gets added or modified
      await updateSeriesContent()
      return
    }
  }

  const updateSeriesContent = async () => {
    const data: any = {
      language,
      category,
      ageRange,
      tags: keywords,
      copyright: seriesCopyright,
      coverImage: thumbnail ? thumbnail : ""

    }
    const res = await callApi(PATHNAMES.UPDATE_SERIES(seriesId), HTTP_METHODS.PUT, {
      body: JSON.stringify({ data }),
    })
    if (!res || !res.success) {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: res.message || "Error while updating series"
      })
    }
  }


  const noPageTypeChapterPresent = () => {
    // disable
    if (!chapters.length || !draftChapterList.length) {
      return true
    }
    const pageTypeChapterPresnt = chapters.some(chapter => chapter.chapterType === 'chapter')
    if (!pageTypeChapterPresnt) {
      return true
    }
  }


  const publishSeries = async () => {
    // user want to mark series as paid
    if (seriesStatus === 'draft' && seriesType === 'paid') {
      const data = {
        seriesId,
        paygId: selectedPlanId,
        lastChapterIdToPublish: publishTillChapterId,
        lastFreeChapterId: freeTillChapterId
      }
      callApi(PATHNAMES.ADD_SERIES_TO_PAYG_AND_PUBLISH(), HTTP_METHODS.POST, {
        body: JSON.stringify({ data }),
      }).then(res => {
        if (res && res.success && res.data) {
          clearInterval(intervalId)
          snackbar({
            variant: SnackBarVariant.SUCCESS,
            message: res.message || "Series published successfully"
          })
          navigate("/portal/myRachnaye", { replace: true })
          return () => clearInterval(intervalId)
        }
      })
    } else {
      // free publish and extra chapter publish
      callApi(PATHNAMES.PUBLISH_SERIES_TILL_CHAPTERS(seriesId, publishTillChapterId), HTTP_METHODS.PATCH).then(res => {
        if (res && res.success && res.data) {
          clearInterval(intervalId)
          snackbar({
            variant: SnackBarVariant.SUCCESS,
            message: res.message || "Series and its chapter published successfully"
          })
          navigate("/portal/myRachnaye", { replace: true })
          return () => clearInterval(intervalId)
        }
      })
    }
  }

  const uploadDocument = async (file: File) => {
    if (seriesStatus === SeriesStatus.PUBLISHED) {
      snackbar({
        message: "Cover image can not be changes once published",
        variant: SnackBarVariant.WARNING
      })
      return
    }
    if (thumbnail) {
      await deleteFileFromS3(thumbnail)
    }
    const key = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.USER_SERIES,
      isAdmin: false,
      publisherId: mobileUserState.id
    });
    if (key) {
      setThumbnail(key)
      setCallApiFn(v => true)
    }
    const res = await callApi(PATHNAMES.GET_SIGNED_URL, HTTP_METHODS.POST, {
      body: JSON.stringify({ data: { key } })
    });

    if (res && res.success && res.data) {
      setThumbnailForPreview(res.data)
    }
  }

  const handleImgeUploadViaInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const isImage = isValidImage(e.target.files[0])
      if (!isImage) {
        snackbar({
          message: "Only jpg/jpeg/png are allowed",
          variant: SnackBarVariant.WARNING
        })
      }
      if (isImage) {
        uploadDocument(e.target.files[0])
      }
    }
  }

  const button2Disable = () => {
    if (!agreeTermsCondtn ||
      !category ||
      !language ||
      !ageRange ||
      !seriesCopyright ||
      !publishTillChapterId ||
      (seriesStatus === 'draft' && seriesType === 'paid' && !selectedPlanId)

    ) {
      return true //disable
    }
    return false // enable
  }

  const onImgContainerClick = () => {
    inputFile?.current?.click();
  };

  if (loading) {
    return <CircularLoader />
  }

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center" style={{ padding: "1rem" }}>
      {/* CANCEL BUTTON */}
      <Grid container xs={12} justify='flex-end' className={classes.stick}>
        <Grid container justify='flex-end' xs={1} >
          <IconButton className={classes.cancelButton} onClick={() => navigate(-1)}>
            <CloseIcon fontSize='small' />
          </IconButton>
        </Grid>
      </Grid>

      {/* IMAGE AND PUBLISHING CONTENT */}

      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: "0.6rem" }}>
        {/* IMAGE COVER */}
        <Grid container xs={12} sm={12} md={6} lg={6} xl={6} justify="center">
          <Grid container xs={12} sm={12} md={12} lg={12} xl={8}>
            <Box onClick={onImgContainerClick} className={classes.imgBox}>
              {/* IMAGE COVER CENTER BOX */}
              {thumbnailForPreview ?
                <img alt="Preview Thumbnail" style={{ objectFit: "contain", height: "100%", width: "100%" }} src={thumbnailForPreview} />
                :
                <Box>
                  <PhotoIcon fontSize="small" color="primary" style={{ margin: "0rem 2rem" }} />
                  <Typography variant="body1">Add a Cover</Typography>
                </Box>
              }
              <input
                ref={inputFile}
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                onChange={handleImgeUploadViaInputField}
              />
            </Box>
            <Box style={{ width: "50%" }}>
              <Typography style={{ padding: '0.8rem', fontWeight: 600 }} variant="h3">Why Cover is Important? </Typography>
              <Typography style={{ padding: '0.8rem' }} variant="subtitle2">Stories get more attention from user, if they have cover image on it.</Typography>
              <Typography style={{ padding: '0rem 0.8rem' }} variant="subtitle2">A suitable cover gives good exposer to story on Rachnaye.</Typography>
            </Box>
          </Grid>
        </Grid>


        {/* RIGHT SIDE CONTAINER (LANGUAGE CATEGORY AND OTHER INFO */}
        <Grid container xs={12} sm={12} md={6} lg={6} xl={6} >

          {/* LANGUAGE AND CATEGORY CONTAINER */}
          <Grid container xs={6} justify='center' style={{ padding: '1rem' }}>
            <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
              <Typography style={{ fontWeight: 400, display: "inline" }} variant="h4">Select Language</Typography>
              <Typography color="primary" style={{ fontWeight: 400, display: "inline" }} variant="h4">*</Typography>
              <Autocomplete
                disabled={disableFieldsForPublishedSeries}
                options={BOOK_LANGUAGES}
                autoHighlight
                disablePortal={true}
                value={{ label: getLanguageLabelByValue(language || ""), value: language || "" }}
                onChange={(e: any, selectedLanguage) => {
                  if (selectedLanguage) {
                    setLanguage(selectedLanguage.value)
                    setCallApiFn(v => true)
                  }
                }}
                getOptionLabel={(language: any) => language.label}
                renderOption={(language) => (
                  <Typography style={{ fontSize: "12px" }}>{language.label}</Typography>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="filled" className={classes.margin} placeholder="Search for language" />
                )}
              />
            </Grid>

            <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
              <Typography style={{ fontWeight: 400, display: "inline" }} variant="h4">Select Category</Typography>
              <Typography color="primary" style={{ fontWeight: 400, display: "inline" }} variant="h4">*</Typography>
              <Autocomplete
                disabled={disableFieldsForPublishedSeries}
                options={BOOK_CATEGORIES}
                autoHighlight
                disablePortal={true}
                value={{ label: getCategoryLabelByValue(category || ""), value: category || "" }}
                onChange={(e: any, selectedCategory) => {
                  if (selectedCategory) {
                    setCategory(selectedCategory.value)
                    setCallApiFn(v => true)
                  }
                }}
                getOptionLabel={(category: any) => category.label}
                renderOption={(category) => (
                  <Typography style={{ fontSize: "12px" }}>{category.label}</Typography>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="filled" className={classes.margin} placeholder="Search for category" />
                )}
              />
            </Grid>

          </Grid>
          {/* AGE AND COPYRIGHT CONTAINER */}
          <Grid container xs={6} justify='center' style={{ padding: '1rem' }}>
            <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
              <Typography style={{ fontWeight: 400, display: "inline" }} variant="h4">Select Age Range</Typography>
              <Typography color="primary" style={{ fontWeight: 400, display: "inline" }} variant="h4">*</Typography>
              <FormControl variant="filled" fullWidth className={classes.margin} >
                {!selectedAgeRange && <InputLabel id="select-ageRange" style={{ fontSize: "12px" }} >Select age range</InputLabel>}
                <Select
                  disabled={disableFieldsForPublishedSeries}
                  labelId="select-ageRange"
                  value={selectedAgeRange}
                  placeholder="Select age range"
                  onChange={(e: any) => {
                    const ageRangeValue = BOOK_AGE_RANGE.find(values => values.label === e.target.value)
                    setAgeRange(ageRangeValue?.value)
                    setCallApiFn(v => true)
                  }}
                  className={classes.selectMenu}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {BOOK_AGE_RANGE.map(
                    (ageRange: any, key: number) => {
                      return (
                        <MenuItem value={ageRange.label} key={key}>
                          <Typography style={{ fontSize: '12px' }}> {ageRange.label}</Typography>
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
              <Typography style={{ fontWeight: 400, display: "inline" }} variant="h4">Copyright</Typography>
              <Typography color="primary" style={{ fontWeight: 400, display: "inline" }} variant="h4">*</Typography>
              <FormControl variant="filled" fullWidth className={classes.margin}>
                {!selectedCopyrightTitle && <InputLabel id="select-copyright" style={{ fontSize: "12px" }} >Select copyright</InputLabel>}
                <Select
                  disabled={disableFieldsForPublishedSeries}
                  labelId="select-copyright"
                  value={selectedCopyrightTitle}
                  onChange={(e: any) => {
                    const value = e.target.value
                    const copyright = copyrights.find(cr => cr.title === value) as ICopyright
                    setSeriesCopyright(copyright.id)
                    setSelectedCopyrightTitle(copyright.title)
                    setSelectedCopyrightsDescription(copyright?.description)
                    setCallApiFn(v => true)
                  }}
                  className={classes.copyrightSelectMenu}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {copyrights.map(
                    (copyright: ICopyright, key: number) => {
                      return (
                        <MenuItem value={copyright.title} key={key}>
                          <Typography style={{ fontSize: '12px' }}>{copyright.title}</Typography>
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {selectedCopyrightsDescription &&
            <Grid container xs={12} style={{ padding: '0rem 1rem 1rem 1rem' }}>
              <Typography color="primary" style={{ fontSize: '12px' }}>{selectedCopyrightsDescription}</Typography>
            </Grid>
          }
        </Grid>

        {/* KEYWORDS AND CONDITION CHECKBOX CONTAINER */}
        <Grid container xs={12} sm={12} md={6} lg={6} xl={6} style={{ padding: "1.6rem 1.6rem 0rem 0rem" }}>
          <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
            <Typography style={{ fontWeight: 400, paddingBottom: "0.2rem" }} variant="h4">Keywords</Typography>
            <Typography style={{ fontWeight: 300, paddingBottom: "0.4rem", fontSize: '10px' }} variant="body1">Adding keywords will help you post for better recommendation </Typography>
            <Autocomplete
              multiple
              fullWidth
              closeIcon={true}
              value={keywords || []}
              freeSolo={true}
              onChange={(event, newValue) => {
                setKeywords(newValue.filter((item) => item.trim().length))
                setCallApiFn(v => true)
              }}
              style={{ backgroundColor: "#EEE" }}
              options={keywords || []}
              filterOptions={() => []}
              getOptionLabel={(option) => option}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    classes={{ root: classes.tagsChip }}
                    label={option}
                    deleteIcon={<CloseIcon style={{ fontSize: "14px", color: "black", width: "12px" }} />}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  className={classes.textArea}
                  multiline
                  {...params}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>

        {/* PUBLISH CHAPTER SELECTION AND CONTENT TYPE*/}
        <Grid container xs={12} sm={12} md={6} lg={6} xl={6} style={{ padding: "0rem 1rem" }}>
          <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
            <Typography style={{ fontWeight: 400, display: "inline" }} variant="h4">Select till Which chapter you want to publish </Typography>
            <Typography color="primary" style={{ fontWeight: 400, display: "inline" }} variant="h4">*</Typography>
            <FormControl variant="filled" fullWidth className={classes.margin} disabled={noPageTypeChapterPresent()}>
              <Select
                onChange={(e: any) => {
                  setPublishTillChapterId(e.target.value)
                }}
                className={classes.selectMenu}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {draftChapterList.map(
                  (chapter: any, key: number) => {
                    return (
                      <MenuItem value={chapter.id} key={key}>
                        <Typography style={{ fontSize: '12px' }}> {chapter.title}</Typography>
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </Grid>

          {noPageTypeChapterPresent() &&
            <Grid container xs={12} style={{ padding: '0.3rem 0rem 1rem 0rem' }}>
              <Typography color="primary" style={{ fontSize: '12px' }}>{"Your series does not have chapter page, Add chapter to publish"}</Typography>
            </Grid>
          }

          {/* show payg when only series is coming first time to get published */}

          {seriesStatus !== SeriesStatus.PUBLISHED &&

            <Grid container style={{ paddingTop: '1rem' }}>
              {/* chapter type selection */}
              <Grid item xs={6}>
                <FormControl disabled={noPageTypeChapterPresent()}>
                  <Typography style={{ fontWeight: 400 }} variant="h4">Series would be ? </Typography>
                  <RadioGroup
                    defaultValue="free"
                    onChange={(event) => {
                      setSeriesType(event.target.value)
                      setSelectedPlanId("")
                    }}
                    row
                  >
                    <FormControlLabel value="free" control={<Radio color="primary" size="small" />} label="Free" />
                    <FormControlLabel value="paid" control={<Radio color="primary" size="small" />} label="PAID" />
                  </RadioGroup>
                </FormControl>
              </Grid>


              {/* PLAN AMOUNT SELECTION */}
              {seriesType === "paid" && (
                <>
                  <Grid item xs={6}>
                    <Typography style={{ fontWeight: 400, display: "inline" }} variant="h4">Select price for Series</Typography>
                    <Typography color="primary" style={{ fontWeight: 400, display: "inline" }} variant="h4">*</Typography>
                    <FormControl variant="filled" fullWidth className={classes.margin} >
                      <Select
                        onChange={(e: any) => {
                          setSelectedPlanId(e.target.value)
                        }}
                        className={classes.selectMenu}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                      >
                        {paygPlans.map(
                          (plan: any, key: number) => {
                            return (
                              <MenuItem value={plan.id} key={key}>
                                <Typography style={{ fontSize: '12px' }}> {`${plan.amount} ${plan.currency}`}</Typography>
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  </Grid>

                  {selectedPlanId &&
                    <>
                      <Grid container xs={12} style={{ padding: '1rem 0rem' }}>
                        <Typography color="primary" style={{ fontSize: '12px' }}>{"This price will include all chapters and future chapter that might you add in this series."}</Typography>
                      </Grid>

                      <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
                        <Typography style={{ fontWeight: 400, display: "inline" }} variant="h4">Select free chapters to be given </Typography>
                        <Typography color="primary" style={{ fontWeight: 400, display: "inline" }} variant="h4">*</Typography>

                        {freeTillChapterId &&
                          <Typography style={{ fontWeight: 300, paddingBottom: "0.4rem", fontSize: '10px' }} variant="body1">all chapter till you have selected including pages other that type chapter will be free </Typography>
                        }

                        <FormControl variant="filled" fullWidth className={classes.margin}>
                          <Select
                            onChange={(e: any) => {
                              const keys = chaptersForFreeChapterSelection.map(chapter => chapter.id)
                              if (keys.includes(e.target.value)) {
                                setFreeTillChapterId(e.target.value)
                              } else {
                                setFreeTillChapterId("")
                              }
                            }}
                            className={classes.selectMenu}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null
                            }}
                          >
                            <MenuItem value="Only pages which are not chapter">
                              <Typography style={{ fontSize: '12px', color: "#49767B" }}>Only pages which are not chapter</Typography>
                            </MenuItem>
                            {chaptersForFreeChapterSelection.map(
                              (chapter: any, key: number) => {
                                return (
                                  <MenuItem value={chapter.id} key={key}>
                                    <Typography style={{ fontSize: '12px' }}> {chapter.title}</Typography>
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  }
                </>
              )}

            </Grid >
          }



        </Grid>

        <Grid container>
          <Grid item xs={12} style={{ paddingTop: "2rem" }}>
            <FormControlLabel
              label={<Typography style={{ fontWeight: 400 }} variant="subtitle2">I agree that this content is written by me, I also agree that I have the right to use the selected image as the cover for my Rachnaye</Typography>}
              control={
                <Checkbox
                  color="primary"
                  size='small'
                  checked={agreeTermsCondtn}
                  onChange={(e: any) => { setAgreeTermsCondtn(e.target.checked) }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            />
          </Grid>
        </Grid>
        {/* Publish button */}
        <Grid container justify="flex-end" xs={12} style={{ padding: "2rem 0rem" }}>
          <Fab
            variant="extended"
            size="medium"
            disabled={button2Disable()}
            onClick={publishSeries}
          >
            Publish Now
          </Fab>
        </Grid>

      </Grid>
    </Grid >
  )
};

export default PublishSeries;

