import { Box, Chip, Grid, IconButton, Paper, Tooltip, Typography, makeStyles } from '@material-ui/core';
import ShareIcon from "@material-ui/icons/Share";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import logo from "../../../assets/rachnayeLight.png";
import { IAuthor } from '../../../reducers/authors/types';
import { IBook } from '../../../reducers/books/types';
import { IAppState } from '../../../store';
import callApi from '../../../utils/apiUtil';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import CircularLoader from '../../common/CircularLoader';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';
import BooksGrid from '../Dashboard/BooksGrid';


const useStyles = makeStyles((theme) => ({
  blueTitle: {
    display: 'inline',
    color: '#777', // New color
  },
  bookDetailHeader: {
    fontWeight: 'bold',
    display: 'inline',
  },
  bookCoverContainer: {
    width: '10rem',
    height: '14rem',
    borderRadius: 4,
    position: 'relative',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    outline: '2px solid rgba(0, 0, 0, 0.2)',
  },
  bookCoverStyle: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: 4,
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  noPaddingAndMargin: {
    padding: '0rem',
    margin: '0rem',
  },
  hoverProperties: {
    transition: 'all 0.5s ease-out',
    '&:hover': {
      transform: 'scale3d(1.10, 1.10, 1)',
    },
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3rem',
  },
  iconStyle: {
    borderRadius: '0rem',
    backgroundColor: '#F5F5F5',
  },
  tooltip: {
    fontSize: '15px',
    maxWidth: 400
  },
  shareButton: {
    marginTop: '1rem'
    , "&:hover": {
      color: theme.palette.primary.main
    }
  },
  chipStyle: {
    margin: '0.5rem',
    marginLeft: '0rem'
  },
}));

const PortalUserAuthorDescriptionPage: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const snackbar = useSnackBar();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [authorData, setAuthorData] = useState<IAuthor>();
  const [authorBooks, setAuthorBooks] = useState<IBook[]>([]);
  const [topAuthorBooks, setTopAuthorBooks] = useState<IBook[]>([]);
  const [visible1, setVisible1] = useState<Boolean>(false);
  const [visible2, setVisible2] = useState<Boolean>(false);
  const [fetchingAuthorOnPageLoad, setFetchingAuthorOnPageLoad] = useState<Boolean>(true);
  const [firstTwoLanguage, setFirstTwoLanguage] = useState<string[]>([]);
  const [firstTwoCategory, setFirstTwoCategory] = useState<string[]>([]);


  const getAuthorData = (id: string) => {
    callApi(PATHNAMES.GET_AUTHOR_BY_ID(id), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setFetchingAuthorOnPageLoad(false);
        res.data.categories = replaceHyphen(res.data.categories);
        res.data.languages = replaceHyphen(res.data.languages);
        setAuthorData(res.data);
        setFirstTwoCategory(res.data.categories.slice(0, Math.min(2, res.data.categories?.length)));
        setFirstTwoLanguage(res.data.languages.slice(0, Math.min(2, res.data.languages?.length)));
        callApi(PATHNAMES.GET_BOOKS_BY_AUTHOR_ID(id), HTTP_METHODS.GET).then(
          (res2) => {
            if (res2 && res2.success && res2.data) {
              setAuthorBooks(res2.data);
            }
          }
        );
        callApi(PATHNAMES.GET_TOP_BOOKS_BY_AUTHOR_ID(id), HTTP_METHODS.GET).then(
          (res3) => {
            if (res3 && res3.success && res3.data) {
              setTopAuthorBooks(res3.data);
            }
          });
      }
    });
  };
  const replaceHyphen = (texts: string[]) => {
    let replacedTexts = texts.map((text) => {
      let replacedText = text.replace(/-(.)/g, (match, nextChar) => ` ${nextChar.toUpperCase()}`);
      replacedText = replacedText.charAt(0).toUpperCase() + replacedText.slice(1);
      replacedText = replacedText.replace(/ And /g, ' and ');
      return replacedText;
    });
    return replacedTexts;
  };
  const handleCopyLink = async () => {
    if (authorData && authorData.id) {
      const data = {
        id: authorData.id,
        resourceType: "author"
      }
      const res = await callApi(PATHNAMES.CREATE_DYNAMIC_LINK, HTTP_METHODS.POST, {
        body: JSON.stringify({ data })
      });
      if (res && res.success && res.data) {
        const { shortLink } = res.data
        navigator.clipboard.writeText(shortLink)
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: "Link copied successfully"
        })
        return
      }
    } else {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: "Something went wrong while copying link"
      })
      return
    }
  }
  useEffect(() => {
    if (location && location.search && mobileUserState && mobileUserState.id) {
      const params = new URLSearchParams(location.search);
      const authorId = params.get("id");

      if (authorId) {
        getAuthorData(authorId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, mobileUserState]);
  if (fetchingAuthorOnPageLoad) {
    return <CircularLoader message={'Fetching Author Details...'} />;
  }

  return (
    <Grid container xs={12} lg={12} style={{ backgroundColor: '#f7f7f7', marginBottom: '1rem' }}>
      <Grid container xs={12} md={9} lg={8} spacing={2}>
        <Grid item xs={12} md={4} lg={3}
          style={{ marginTop: '1.3rem', justifyContent: 'center', display: 'flex' }}>
          <Paper variant="outlined" className={classes.bookCoverContainer}>
            <img className={classes.bookCoverStyle} src={authorData?.image || logo} alt="Author Cover" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Box style={{ display: "flex", alignItems: "center", gap: "12rem" }}>
            <Typography variant="h1" style={{ fontWeight: 'bold', color: '#333', marginTop: '1rem', fontSize: '2.5em' }}>
              {authorData && authorData.name.toUpperCase()}
            </Typography>
            <IconButton onClick={handleCopyLink} className={classes.shareButton} title="Copy Share Link">
              <ShareIcon style={{ fontSize: "2rem" }} />
            </IconButton>
          </Box>
          {authorData?.popularName && authorData.popularName.length > 0 && (
            <Typography style={{ display: 'flex' }}>
              <Typography variant="h3" style={{ color: '#777', marginTop: '10px' }}>
                Popular Name :
              </Typography>
              <Typography
                variant="h3"
                style={{ color: 'black', marginTop: '10px', paddingLeft: '0.3rem' }}
              >
                {` ${authorData.popularName}`}
              </Typography>
            </Typography>
          )}
          <Typography style={{ display: 'flex' }}>
            <Typography variant="h3" style={{ color: '#777', marginTop: '10px' }}>
              Author's Book on Rachnaye :
            </Typography>
            <Typography variant="h3" style={{ color: 'black', marginTop: '10px', paddingLeft: "0.3rem" }}>
              {` ${authorBooks?.length}`}
            </Typography>
          </Typography>
          <Typography variant="h3" style={{ color: '#777', marginTop: "0.8rem", marginBottom: '0.5rem' }}>
            Published in Category (s):
          </Typography>
          {visible2 ? <>{
            authorData?.categories?.map((item) => (
              <Chip label={item} className={classes.chipStyle} />
            ))}
            <Chip label="Show less" onClick={() => setVisible2(!visible2)} className={classes.chipStyle} />
          </> :
            <>{firstTwoCategory.map((item) => (
              <Chip label={item} className={classes.chipStyle} />
            ))}
              {authorData?.categories?.length! > 2 && <Chip label="Show More" onClick={() => setVisible2(!visible2)} className={classes.chipStyle} />}
            </>}
          <Typography variant="h3" style={{ color: '#777', marginTop: '1rem', marginBottom: '0.5rem' }}>
            Published in Language (s):
          </Typography>
          {visible1 ? <>{
            authorData?.languages?.map((item) => (
              <Chip label={item} className={classes.chipStyle} />
            ))}
            <Chip label="Show less" onClick={() => setVisible1(!visible1)} className={classes.chipStyle} />
          </> :
            <>{firstTwoLanguage.map((item) => (
              <Chip label={item} className={classes.chipStyle} />
            ))}
              {authorData?.languages?.length! > 2 && <Chip label="Show More" onClick={() => setVisible1(!visible1)} className={classes.chipStyle} />}
            </>}
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} lg={4} style={{ padding: '1rem', maxHeight: '350px', overflow: 'auto', marginTop: '1rem' }}>
        <Typography variant="h2" style={{ fontWeight: 'bold', marginBottom: '0.5rem', textAlign: 'center', textDecoration: 'underline' }}>
          Author Bio
        </Typography>
        <Typography variant='h4' paragraph={true}>
          {authorData?.bio && authorData?.bio.length > 0 ? (authorData?.bio && authorData?.bio?.length <= 150 ? (authorData?.bio) : (
            <>
              {`${authorData?.bio?.slice(0, 150)}`}
              <Tooltip arrow classes={{ tooltip: classes.tooltip }} title={authorData?.bio || ""}>
                <span style={{ color: "#FF5C3E", textDecoration: 'underline' }}> {`...more `}</span>
              </Tooltip>
            </>
          )) : (<>--</>)}
        </Typography>
      </Grid>
      <Typography variant="h2" style={{ fontWeight: 'bold', color: 'black', marginTop: '4.5rem', paddingLeft: '1rem', paddingBottom: '0.5rem', textTransform: 'uppercase' }}>
        Top Reads from {authorData?.name}
      </Typography>
      <Grid container xs={12} spacing={0}>
        <BooksGrid books={topAuthorBooks} />
      </Grid>
      <Typography variant="h2" style={{ fontWeight: 'bold', color: 'black', marginTop: '4.5rem', paddingLeft: '1rem', textTransform: 'uppercase' }}>
        More Books from {authorData?.name}
      </Typography>
      <Grid container xs={12} style={{ marginTop: '1rem', marginBottom: '2rem' }} spacing={0}>
        <BooksGrid books={authorBooks} />
      </Grid>
    </Grid>
  );
};

export default PortalUserAuthorDescriptionPage;