import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { updateListExpanded } from "../../../actions/sidebar/sidebarActions";
import { updateListExpanded } from "../../../reducers/sidebar/reducer";
import { IAppState } from "../../../store";
import { Authorization, Null } from "../hoc/Authorization";
import AppMenuItemComponent from "./AppMenuItemComponent";

export interface IAppMenuItem {
  Icon: JSX.Element;
  link: string;
  items: any[];
  name: any;
  auth?: string;
}
const AppMenuItem = (props: IAppMenuItem) => {
  const { name, link, Icon, items = [] } = props;
  const dispatch = useDispatch();
  const listExpanded = useSelector((state: IAppState) => state.sidebar.listExpanded);
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!listExpanded) {
      setOpen(true);
    }
    setOpen(listExpanded === name.props.children);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listExpanded]);

  const handleClick = () => {
    dispatch(updateListExpanded(listExpanded === name.props.children ? "" : name.props.children));
  }

  const MenuItemRoot = (
    <AppMenuItemComponent
      className={classes.menuItem}
      link={link}
      onClick={handleClick}
    >
      {!!Icon && (
        <ListItemIcon className={`${classes.menuItemIcon} menu-item-icon-main`}>
          {Icon}
        </ListItemIcon>
      )}
      <ListItemText primary={name} inset={!Icon} />
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse
      className="collapsible-menus"
      in={open}
      timeout="auto"
      unmountOnExit
    >
      <Divider />
      <List component="div" disablePadding>
        {items.map((item: any, index: any) => {
          const NullAppMenuItem = Authorization(item.allowed)(Null)(
            AppMenuItem
          );
          return <NullAppMenuItem {...item} key={index} />;
        })}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      color: "#545454",
      "& .MuiListItemText-primary:hover": {
        color: theme.palette.primary.main
      },
      "& .MuiListItemIcon-root": {
        color: theme.palette.primary.main,  
        filter: 'invert(0.30)',
      },
      boxShadow: "0px -1px 0px rgba(255, 255, 255, 0.08)",
      "&.active": {
        backgroundColor: theme.palette.primary.main,
        color: "#000",
        "& .MuiListItemIcon-root": {
          color: "#000",
          //we are using invert function here, to change the color of an SVG icons when get selected
        filter: 'invert(0)', 
        }
      },
    },
    menuItemIcon: {
      color: "#fff",
      minWidth: 33,
      fontWeight: 600,
      fontSize: 18
    }
  })
);

export default AppMenuItem;
