export const UserActionTypes = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  LOGOUT: "LOGOUT",
  UPDATE_USER: "UPDATE_USER"
};

export const RouteAccess = {
  ALL_USERS: 'allUsers',
  SUPER_ADMIN: 'superAdmin',
  ADMIN: 'admin',
  MOBILE_USER: 'mobileUser',
  ADMIN_AND_SUPER_ADMIN: "adminAndSuperAdmin"
}


export const MobileUserActionTypes = {
  LOGIN: "LOGIN",
  UPDATE_USER: "UPDATE_USER"
};

export const MobileOS = [
  { value: "iOS", label: "IOS" },
  { value: "Android", label: "ANDROID" }
]

export const AUTOSAVE_TIME_IN_MILLISECOND = 3000 //3 second

export const MINIMUM_PASSWORD_LENGTH = 6

export const GST_REGEX = /[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/

export const NUMBERS_REGEX = /^\d+$/

export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const PHONE_NUMBER_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const STRING_WITHOUT_SPACE_REGEX = /^[a-zA-Z]+$/  // no special char

export const STRING_WITH_SPACE_REGEX = /^[a-zA-Z]+( [a-zA-Z]+)*$/ // no special char

export const DEFAULT_COUNTRY_CODE = "+91"

export const BOOK_CATEGORIES = [
  { value: "general-non-fiction", label: "GENERAL NON-FICTION" },
  {
    value: "graphic-novels-and-comic-books",
    label: "GRAPHIC NOVELS AND COMIC BOOKS"
  },
  { value: "history-and-politics", label: "HISTORY AND POLITICS" },
  { value: "humour", label: "HUMOUR" },
  { value: "kids-and-teens", label: "KIDS AND TEENS" },
  { value: "lifestyle-and-wellness", label: "LIFESTYLE AND WELLNESS" },
  { value: "poetry-and-plays", label: "POETRY AND PLAYS" },
  { value: "travel", label: "TRAVEL" },
  { value: "sports", label: "SPORTS" },
  { value: "short-stories", label: "SHORT STORIES" },
  { value: "novels", label: "NOVELS" },
  { value: "classics", label: "CLASSICS" },
  { value: "business", label: "BUSINESS" },
  {
    value: "academics-and-reference",
    label: "ACADEMICS AND REFERENCE"
  },
  { value: "art-and-design", label: "ART AND DESIGN" },
  {
    value: "biography-and-autobiography",
    label: "BIOGRAPHY AND AUTOBIOGRAPHY"
  },
  { value: "self-help", label: "SELF HELP" },
  { value: "sci-fi-fantasy", label: "SCI-FI FANTASY" },
  { value: "contemporary-fiction", label: "CONTEMPORARY FICTION" },
  { value: "other", label: "OTHER" },
  { value: "action-adventure", label: "ACTION AND ADVENTURE" },
  { value: "crime-thriller-mystery", label: "CRIME, THRILLER AND MYSTERY" },
  { value: "health-fitness-nutrition", label: "HEALTH, FITNESS AND NUTRITION" },
  { value: "historical-fiction", label: "HISTORICAL FICTION" },
  { value: "language-linguistics-writing", label: "LANGUAGE, LINGUISTICS AND WRITING" },
  { value: "law", label: "LAW" },
  { value: "literature-fiction", label: "LITERATURE AND FICTION" },
  { value: "religion-spirituality", label: "RELIGION AND SPIRITUALITY" },
  { value: "romance", label: "ROMANCE" },
  { value: "society-social-sciences", label: "SOCIETY AND SOCIAL SCIENCES" },
  { value: "higher-education", label: "HIGHER EDUCATION" },
  { value: "textbooks", label: "TEXTBOOKS" },
  { value: "horror", label: "HORROR" },
  { value: "science", label: "SCIENCE" },
  { value: "technology", label: "TECHNOLOGY" },
  { value: "economic", label: "ECONOMIC" },
  { value: "media", label: "MEDIA" },
  { value: "management", label: "MANAGEMENT" },
  { value: "health", label: "HEALTH" },
  { value: "magazine", label: "MAGAZINE" },
  { value: "satire", label: "SATIRE" },
  { value: "lyrics-songs", label: "LYRICS/SONGS" },

];

export const BOOKS_FOR_OFFERS = [
  { value: 'audiobooks', label: 'AUDIOBOOKS' },
  { value: 'digital', label: 'DIGITAL' },
  { value: 'paperback', label: 'PAPERBACK' },
  { value: 'hardbound', label: 'HARDBOUND' },
]

export const PLAN_NAMES = [
  {
    value: "FREE",
    label: "FREE"
  },
  {
    value: "BASIC",
    label: "BASIC"
  },
  {
    value: "PREMIUM",
    label: "PREMIUM"
  }
];

export const TRANSFER_MODES = {
  UPI: "upi",
  IMPS: "imps",
  NEFT: "neft"
}

export const BOOK_LANGUAGES = [
  { value: "assamese", label: "ASSAMESE" },
  { value: "bengali", label: "BENGALI" },
  { value: "bhili", label: "BHILI" },
  { value: "english", label: "ENGLISH" },
  { value: "gujarati", label: "GUJARATI" },
  { value: "hindi", label: "HINDI" },
  { value: "kannada", label: "KANNADA" },
  { value: "maithili", label: "MAITHILI" },
  { value: "malayalam", label: "MALAYALAM" },
  { value: "marathi", label: "MARATHI" },
  { value: "odia", label: "ODIA" },
  { value: "punjabi", label: "PUNJABI" },
  { value: "tamil", label: "TAMIL" },
  { value: "telugu", label: "TELUGU" },
  { value: "sanskrit", label: "SANSKRIT" },
  { value: "pali", label: "PALI" },
  { value: "angika", label: "ANGIKA" },
  { value: "nepali", label: "NEPALI" },
  { value: "magadhi", label: "MAGADHI" },
  { value: "bhojpuri", label: "BHOJPURI" },
  { value: "awadhi", label: "AWADHI" },
  { value: "bundeli", label: "BUNDELI" },
  { value: "brajabhasha", label: "BRAJABHASHA" },
  { value: "pahadi", label: "PAHADI" },
  { value: "mundari", label: "MUNDARI" },
  { value: "bodo", label: "BODO" },
  { value: "chhattisgarhi", label: "CHHATTISGARHI" },
  { value: "dogari", label: "DOGARI" },
  { value: "garhwali", label: "GARHWALI" },
  { value: "hariyanvi", label: "HARIYANVI" },
  { value: "kashmiri", label: "KASHMIRI" },
  { value: "konkani", label: "KONKANI" },
  { value: "rajasthani", label: "RAJASTHANI" },
  { value: "santhali", label: "SANTHALI" },
  { value: "urdu", label: "URDU" }

];

export const BOOK_CONTENT_TYPE = [
  { value: 'FREE', label: 'FREE' },
  { value: 'PAID', label: 'PAID' },
]

export const BOOK_STATUS = [
  { value: true, label: 'ACTIVE' },
  { value: false, label: 'INACTIVE' },
]

export const BOOK_AGE_RANGE = [
  {
    value: {
      lowerLimit: 0,
      upperLimit: 11
    },
    label: `0-11`
  },
  {
    value: {
      lowerLimit: 11,
      upperLimit: 18
    },
    label: `11-18`
  },
  {
    value: {
      lowerLimit: 18,
      upperLimit: 100
    },
    label: `18+`
  }
]

export const BOOK_DIMENSIONS = [
  {
    value: {
      height: {
        value: 5,
        unit: "inch"
      },
      width: {
        value: 8,
        unit: "inch"
      }
    },
    label: `5" x 8"`
  },
  {
    value: {
      height: {
        value: 5.5,
        unit: "inch"
      },
      width: {
        value: 8.5,
        unit: "inch"
      }
    },
    label: `5.5" x 8.5"`
  },
  {
    value: {
      height: {
        value: 6,
        unit: "inch"
      },
      width: {
        value: 9,
        unit: "inch"
      }
    },
    label: `6" x 9"`
  },
  {
    value: {
      height: {
        value: 8.5,
        unit: "inch"
      },
      width: {
        value: 8.5,
        unit: "inch"
      }
    },
    label: `8.5" x 8.5"`
  },
  {
    value: {
      height: {
        value: 8.5,
        unit: "inch"
      },
      width: {
        value: 11,
        unit: "inch"
      }
    },
    label: `8.5" x 11"`
  }
]

//WEBAPP = IamUser,MOBILE=mobileuser
export const USER_TYPES = {
  MOBILE: "MOBILE",
  WEBAPP: "WEBAPP"
}

export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE"
};

export const AccountPrimaryMethodOptions = {
  BANK_ACCOUNT: "bank-account",
  VPA: "vpa"
};

export const SubOrderStatus = {
  CREATED: "created",
  CANCELLED: "cancelled",
  CONFIRMED: "confirmed",
  INTRANSIT: "intransit",
  COMPLETED: "completed",
  PICKUP_SCHEDULED: "pickupScheduled",
  RETURNED: "returned"
};

export const PayoutStatus = {
  PENDING_APPROVAL: "pending-approval",
  PENDING: "pending",
  COMPLETED: "completed",
  FAILED: "failed",
  CANCELLED: "cancelled"
};

export const PublisherStatus = {
  PENDING: "pending",
  ACTIVE: "active",
  SUSPENDED: "suspended",
  TERMINATED: "terminated"
};

export const ResourceTypes = {
  BOOK_EPUB: "books-epub",
  BOOK_IMAGE: "books-images",
  BOOK_AUDIO: "books-audio",
  OFFER_IMAGE: "offer-images",
  BUNDLE_IMAGE: "bundle-images",
  AUTHOR_IMAGE: "author-images",
  AUTHOR: "authors",
  SPACE_INVOICE: "space-invoices",
  USER_INVOICE: "user-invoices",
  IAM_USER_PROFILE: "user-profiles",
  USER_PROFILE: "userProfileImage",
  USER_POST: "post",
  USER_SERIES: "series",
  USER_CHAPTERS: "chapters",
  RACHNAYE_COVER_IMAGE: "rachnaye-cover-image",
}

export const PostType = {
  VIDEO: "video",
  TEXT: "text"
}

export const possibleCsvMimeTypes = [
  "text/plain",
  "text/x-csv",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/csv",
  "application/x-csv",
  "text/csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values",
  ""
];

export enum CsvUploadStatusType {
  DEFAULT,
  IN_PROGRESS,
  ERROR,
  SUCCESS
}
export interface ICsvUploadError {
  index: number;
  message: string;
}

export const bookUploadCsvHeaders = [
  "ISBN",
  "Title",
  "Description",
  "Authors",
  "Binding",
  "Publishing Year",
  "Reprint Year",
  "Edition",
  "Price",
  "Pages",
  "Language",
  "Category",
  "Age Range",
  "Weight(grams)",
  "Length(cm)",
  "Breadth(cm)",
  "Height(cm)"
];

export const bookUploadFieldsMap: any = {
  "ISBN": "isbn",
  "Title": "title",
  "Description": "description",
  "Authors": "authors",
  "Binding": "bindingType",
  "Publishing Year": "yearOfPublishing",
  "Reprint Year": "yearOfReprint",
  "Edition": "version",
  "Price": "price",
  "Pages": "pages",
  "Language": "language",
  "Category": "category",
  "Age Range": "ageRange",
  "Weight(grams)": "weight",
  "Length(cm)": "height",
  "Breadth(cm)": "width",
  "Height(cm)": "depth",
  "Country": "originCountry",
  "Distributor": "distributor",
  "Quantity": "quantity"
}

export const bookSeoUpdateFieldsMap: any = {
  "Book Id": "id",
  "Title": "title",
  "Image Url": "imageUrl",
  "Seo Url": "seoUrl",
  "Meta Title": "metaTitle",
  "Meta Description": "metaDescription",
  "Meta Keywords": "metaKeywords",
  "JSON LD Schema Markup": "schemaMarkup"
}

export const bookUpdateFieldsMap: any = {
  "ISBN": "isbn",
  "Distributor": "distributor",
  "Binding": "binding",
  "Price": "price",
  "Quantity": "quantity"
}

export enum GroupNotificationCategory {
  PHONE_NUMBER_BASED = "phoneNumberBased",
  QUERY_SELECTION_BASED = 'customQuerySelectionBased',
}
export enum BookContentTypes {
  FREE = "FREE",
  PAID = "PAID"
}

export enum BookTypes {
  FREE = "free",
  DIGITAL = "digital",
  RENTAL_DIGITAL = "rental_digital",
  PAPERBACK = "paperback",
  HARDBOUND = "hardbound",
  AUDIOBOOK = "audiobook"
}

export enum OrderItemTypes {
  PAPERBACK = "PAPERBACK",
  HARDBOUND = "HARDBOUND",
  BUY_DIGITAL = "BUY_DIGITAL",
  RENTAL_DIGITAL = "RENTAL_DIGITAL",
  AUDIOBOOK = "AUDIOBOOK"
}
export enum ReasonType {
  CANCEL = 'cancel',
  RETURN ='return'
}

export const CancellationReasons = {
  ORDERED_BY_MISTAKE: "orderedByMistake",
  TOO_LONG_TO_DELIVER: "tooLongToDeliver",
  MIND_CHANGED: "mindChanged",
  REASON_NOT_LISTED: "reasonNotListed",
}

export enum BookRentalTypes {
  FORTNIGHTLY = "FORTNIGHTLY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
}

export const TAX_PERCENTAGE = 0

export enum DownloadFileNames {
  MANIFEST = "manifest",
  LABEL = "label",
  INVOICE = 'invoice'
}

export enum IamUserType {
  PUBLISHER = "publisher",
  ORG = "org",
}
export enum BookShareTypes {
  PUBLISHER_SHARE = 'publisherShare',
  PLATFORM_SHARE = 'platformShare'
}

export const SeriesStatus = {
  PUBLISHED: "published",
  DRAFT: 'draft'
}

export const CommentType = {
  COMMENT_ON_POST: "comment_on_post",
  COMMENT_ON_SERIES: "comment_on_series",
  COMMENT_ON_CHAPTER: "comment_on_chapter",
  REPLY_ON_COMMENT: "reply_on_comment"
};

export const ReactionOn = {
  COMMENT: "comment",
  POST: "post",
  REPLY: "reply",
  SERIES: "series",
  CHAPTER: "chapter"
};

export const ReactionTypes = {
  LIKE: "like",
  HEART: "heart"
};

export const SeriesChapterPageType: { [key: string]: string } = {
  ACKNOWLEDMENT: 'acknowledmentsPage',
  CHAPTER: 'chapter',
  COPYRIGHT: 'copyrightPage',
  DEDICATION: 'dedicationPage',
  PRAISE: 'praisesPage',
  PREFACE: 'prefacePage',
  PROLOUGE: 'prolougePage',
}

export const MAXIMUM_VIDEO_SIZE_LIMIT = 100