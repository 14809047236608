import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import CircularRupeeIcon from "../../assets/CircularRupeeBold.svg";
import BookOpenIcon from "../../assets/BookOpenBold.svg";
import UserAccountsMenuIcon from "../../assets/UserAccountsMenuIconBold.svg";
import DashboardMenuIcon from "../../assets/DashboardMenuIcon.svg";
import PublisherBuildingIcon from "../../assets/PublisherBuildingIcon.svg"

interface IProps {
  icon: string
  name: string;
  value: number;
  subheading?: string;
  className?: string;
}

const RevenueCard: React.FC<IProps> = (props) => {

  const getIcon = (iconName: string) => {
    switch (iconName) {
      case "revenue":
        return CircularRupeeIcon;
      case "books":
        return BookOpenIcon;
      case "users":
      case "followers":
        return UserAccountsMenuIcon;
      case "orders":
        return DashboardMenuIcon;
      case "publisher":
        return PublisherBuildingIcon;
      default:
        return CircularRupeeIcon;
    }
  }
  return (
    <Paper elevation={5} style={{ width: "95%" }}>
      <Grid  className={`${props.className}`} container xs={12} style={{ padding: "1rem" }}>
        <Grid item xs={'auto'} style={{ padding: '0.8rem 0.8rem 0.8rem 0rem' }}>
          <img alt="" src={getIcon(props.icon)} />
        </Grid>
        <Grid item xs={'auto'}>
          <Typography variant="h3" style={{ fontWeight: 500, color: "#808080" }}>
            {props.name}
          </Typography>
          {props.value === null ?
            <Grid container alignContent="center" style={{ marginTop: '2%' }} ><Typography variant="h5">No Data to display</Typography></Grid>
            :
            <>
              <Typography variant="h3" style={{ fontWeight: 600 }}>
                {props.value?.toLocaleString()}
              </Typography>
              {props.subheading?.length && props.subheading.length > 0 ?
                <Typography variant="h4" style={{ fontWeight: 500, marginTop: '0.5rem', color: 'red' }}>
                  {props.subheading}
                </Typography>
                :
                <></>
              }
            </>
          }
        </Grid>
      </Grid>
    </Paper >
  )
}

export default RevenueCard;