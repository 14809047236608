import { Card, CardContent, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, IconButton, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PurchasedSeries from "./PurchasedSeries";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { HTTP_METHODS, TRANSFER_MODES } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import CloseIcon from "@material-ui/icons/Close";
import CircularLoader from "../../../common/CircularLoader";
import { useNavigate } from "react-router-dom";
import { IBankAccountInput, IPaymentAccount, IVpaInput } from "../../../../reducers/paymentAccounts/types";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { ILedgerAccount } from "../../../../reducers/ledger/types";
import { getWithdrawlCharges, maskEmail, maskName, maskString } from "../../../../utils/utils";
import GuestUsersNameChange from "../../../common/GuestUsersNameChange";


const useStyles = makeStyles((theme) =>
  createStyles({
    outlinee: {
      marginTop: "1.5rem",
      "& > *": {
        // outline: "red dashed 1px"
      }
    },
    popUp: {
      // margin: "0.8rem 0rem",
      "& > *": {
        margin: "10px 0"
      }
    },
    card: {
      backgroundColor: "#FAFAFA",
      margin: "1rem 1rem",
      minWidth: "13rem",
      transition: "transform .3s",
      "&:hover": {
        transform: "scale(1.1)"
      }
    },
    textField: {
      padding: "0.3rem",
      "& .MuiOutlinedInput-input": {
        padding: "0.3rem 1rem"
      }
    },
    forwardIcon: {
      backgroundColor: "#FF5C3E",
      color: "white",
      padding: "0.2rem",
      borderRadius: "1rem",
      cursor: "pointer"
    },
    withdrawalLoader: {
      width: '30em',
      [theme.breakpoints.down('sm')]: {
        width: "15em",
      },
    }
  })
);

interface IAmountCardProps {
  title: string,
  amount: number
}
const AmountCard: React.FC<IAmountCardProps> = ({ title, amount }) => {
  const classes = useStyles();

  return (
    <Grid item xs='auto' className={classes.card}>
      <Paper elevation={3} style={{ borderRadius: "14px" }}>
        <Grid container justify="center" style={{ padding: "1rem 1rem 0rem 1rem" }}>
          <Typography variant="h3" style={{ color: "#6c6c6c" }} >{title}</Typography>
        </Grid>
        <Grid container justify="center" style={{ padding: "1rem 1rem" }}>
          <Typography align="center" variant="h2" style={{ fontWeight: 500 }}>{`₹ ${amount?.toLocaleString()}`}</Typography>
        </Grid>
      </Paper>
    </Grid>
  )
}




const bankDetailSchema = {
  bankName: "",
  accountName: "",
  ifsc: "",
  accountNumber: "",
  accountType: "",
  emailId: ""
}
const vpaSchema: IVpaInput = {
  vpa: "",
  name: "",
  emailId: ""
}

const ledgerAccountSchema: ILedgerAccount = {
  id: "",
  consumerId: "",
  consumerType: "",
  totalRevenue: 0,
  payouts: [],
  totalPaid: 0,
  outstanding: 0,
  withdrawableAmount: 0,
  amountWithheld: 0,
  lastCompletedPayoutId: "",
  subOrders: []
}

const WritersEarnings: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);

  const [ledgerAccount, setLedgerAccount] = useState<ILedgerAccount>(ledgerAccountSchema)
  const [loader, setLoader] = useState<boolean>(true)
  const [noLedgerAccFound, setNoLedgerAccFound] = useState<boolean>(false)
  const [bankDetailsFound, setBankDetailsFound] = useState<boolean>(false)
  const [upiDetailsFound, setUpiDetailsFound] = useState<boolean>(false)

  const [tempBankDetails, setTempBankDetails] = useState<IBankAccountInput>(bankDetailSchema)
  const [bankDetails, setBankDetails] = useState<IBankAccountInput>(bankDetailSchema);

  const [tempVpa, setTempVpa] = useState<IVpaInput>(vpaSchema)
  const [vpa, setVpa] = useState<IVpaInput>(vpaSchema)

  const [isEditableUpiDetail, setIsEditableUpiDetail] = useState<boolean>(false);
  const [isEditableBankDetail, setIsEditableBankDetail] = useState<boolean>(false);
  const [withdrawMethodDialog, setWithdrawMethodDialog] = useState<boolean>(false);
  const [askForGuestUsersInfoOpen, setAskForGuestUsersInfoOpen] = useState<boolean>(false);


  const [upiLoading, setUpiLoading] = useState<boolean>(false)
  const [bankLoading, setBankLoading] = useState<boolean>(false)
  const [processingWithdrawlLoader, setProcessingWithdrawlLoader] = useState<boolean>(false)


  useEffect(() => {
    if (mobileUserState && mobileUserState.id && !ledgerAccount.id) {
      fetchWriterLedger()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState.id])


  useEffect(() => {
    if (ledgerAccount.id) {
      setLoader(false)
    }
  }, [ledgerAccount.id])


  const fetchWriterLedger = () => {
    callApi(PATHNAMES.GET_LEDGER_ACCOUNT_FOR_WRITER(mobileUserState.id), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setLedgerAccount(res.data)
        fetchWriterPaymentAccountDetails()
      } else {
        setNoLedgerAccFound(true)
        setLoader(false)
      }
    })
  }


  const fetchWriterPaymentAccountDetails = () => {
    callApi(PATHNAMES.GET_PAYMENT_ACCOUNT_FOR_WRITER(mobileUserState.id), HTTP_METHODS.GET).then(res => {
      if (res?.success && res.data) {
        const paymentAccount: IPaymentAccount = res.data;
        if (paymentAccount.bankAccount) {
          setBankDetails(paymentAccount.bankAccount)
          setTempBankDetails(paymentAccount.bankAccount)
          setBankDetailsFound(true)
        }
        if (paymentAccount.vpa) {
          setVpa(paymentAccount.vpa)
          setTempVpa(paymentAccount.vpa)
          setUpiDetailsFound(true)
        }
      }
    })
  }

  //update bank detail
  const updateBankDetails = () => {
    setBankLoading(true)
    if (mobileUserState && mobileUserState.id) {

      // validation for fields
      if (!bankDetails.accountName ||
        !bankDetails.bankName ||
        !bankDetails.accountNumber ||
        !bankDetails.ifsc ||
        !bankDetails.accountType ||
        !bankDetails.emailId) {
        snackbar({
          message: "All Bank detail field are mandatory",
          variant: SnackBarVariant.ERROR
        })
        return
      }

      const data: any = {}

      // api data prep
      if (bankDetails.accountNumber && bankDetails.accountName) {
        data.bankAccount = {
          accountName: bankDetails.accountName,
          bankName: bankDetails.bankName,
          accountNumber: bankDetails.accountNumber,
          ifsc: bankDetails.ifsc,
          accountType: bankDetails.accountType,
          emailId: bankDetails.emailId
        }
      }

      callApi(PATHNAMES.UPDATE_BANK_DETAILS_FOR_WRITER(mobileUserState.id), HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      }).then(res => {
        setBankLoading(false)
        if (res.success && res.data) {
          setIsEditableBankDetail(false);
          fetchWriterPaymentAccountDetails()
          snackbar({
            variant: SnackBarVariant.SUCCESS,
            message: "Account details updated successfully!"
          })
        } else {
          setBankDetails(tempBankDetails)
          snackbar({
            variant: SnackBarVariant.ERROR,
            message: res.message || "Error while updating Upi details"
          })
          setIsEditableBankDetail(false);
        }
      })
    }
  }


  //update UPI detail
  const updateUpiDetails = () => {
    setUpiLoading(true)
    if (mobileUserState && mobileUserState.id) {
      if (!vpa.vpa || !vpa.name || !vpa.emailId) {
        snackbar({
          message: "VPA Name & Id & emailId are mandatory",
          variant: SnackBarVariant.ERROR
        })
        return
      }

      const data: any = {}
      data.vpa = vpa

      callApi(PATHNAMES.UPDATE_UPI_DETAILS_FOR_WRITER(mobileUserState.id), HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      }).then(res => {
        setUpiLoading(false)
        setIsEditableUpiDetail(false);
        if (res.success && res.data && mobileUserState && mobileUserState.id) {
          snackbar({
            variant: SnackBarVariant.SUCCESS,
            message: "UPI details updated successfully!"
          })
          fetchWriterPaymentAccountDetails()
        } else {
          setVpa(tempVpa)
          snackbar({
            variant: SnackBarVariant.ERROR,
            message: res.message || "Error while updating Upi details"
          })
          setIsEditableUpiDetail(false);
        }
      })
    }
  }

  const requestWithdrawl = (mode: string) => {
    setProcessingWithdrawlLoader(true)
    if (mode) {
      if (mode === TRANSFER_MODES.UPI && !upiDetailsFound) {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: "Please add UPI details first for UPI withdrawal",
          duration: 5000
        })
        setWithdrawMethodDialog(false)
        setProcessingWithdrawlLoader(false)
        return
      }
      if (mode !== TRANSFER_MODES.UPI && !bankDetailsFound) {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: "Please add Bank details first for IMPS/NEFT withdrawal",
          duration: 5000
        })
        setWithdrawMethodDialog(false)
        setProcessingWithdrawlLoader(false)
        return
      }

      const data = {
        transferMode: mode,
        withdrawlCharges: getWithdrawlCharges(mode, ledgerAccount.withdrawableAmount)
      }
      callApi(PATHNAMES.REQUEST_WITHDRAWAL_FOR_WRITER, HTTP_METHODS.POST, {
        body: JSON.stringify({ data }),
      }
      ).then(res => {
        if (res?.success && res.data && mobileUserState?.id) {
          setWithdrawMethodDialog(false);
          fetchWriterLedger()
          snackbar({
            variant: SnackBarVariant.SUCCESS,
            message: "Withdrawl request processed successfully"
          })
          setProcessingWithdrawlLoader(false)
        } else {
          setWithdrawMethodDialog(false);
          snackbar({
            variant: SnackBarVariant.ERROR,
            message: res.message || "Error while requesting withdrawl"
          })
          setProcessingWithdrawlLoader(false)
        }
      })
    }
  }

  const handleCancelUpiEdit = () => {
    setIsEditableUpiDetail(false);
    setVpa(tempVpa);
    setUpiLoading(false);
  }

  const handleCancleBankEdit = () => {
    setIsEditableBankDetail(false);
    setBankDetails(tempBankDetails);
    setBankLoading(false);
  }

  const handleLinkNav = (link: string) => {
    if (mobileUserState.firstName.includes("Guest_")) {
      setAskForGuestUsersInfoOpen(true)
      return
    }
    navigate(link)
    return
  }

  if (loader) {
    return <CircularLoader />
  }


  return (
    <Grid container xs={12} spacing={2} className={classes.outlinee}>

      {noLedgerAccFound ?

        <>
          <Grid container xs={12} style={{ margin: "1rem" }}>
            <Dialog
              disableBackdropClick
              aria-labelledby="simple-dialog-title"
              open={true}>
              <DialogTitle id="simple-dialog-title">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs="auto">
                    <Typography variant="h2" style={{ fontWeight: 500 }}>Earning & Payout</Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <IconButton onClick={() => navigate(-1)}>
                      <CloseIcon style={{ fontSize: 20 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Grid container justify="center" className={classes.popUp}>
                    <Typography variant="h3">
                      Sorry, There is no earning for you yet.
                      Go and start writing paid series.
                    </Typography>
                    <Fab
                      onClick={() => handleLinkNav("/portal/rachnaye/series/create")}
                      size="medium"
                      variant="extended"
                    >Write Series</Fab>
                  </Grid>

                </DialogContentText>
                <GuestUsersNameChange open={askForGuestUsersInfoOpen} handleClose={() => setAskForGuestUsersInfoOpen(false)} titleMessage="Add your Info" subMessage="You are a guest user , you need to add your actual name before proceeding to write" />
              </DialogContent>
            </Dialog>
          </Grid>
        </>
        :
        <>
          {/* CARDS */}
          <Grid container xs={12} alignItems="center" justify="center">
            <AmountCard title="Total Earned" amount={ledgerAccount.totalRevenue} />
            <AmountCard title="Withdrawn So Far" amount={ledgerAccount.totalPaid} />
            <AmountCard title="Withdrawable Amount" amount={ledgerAccount.withdrawableAmount} />
            <AmountCard title="In Processing" amount={ledgerAccount.amountWithheld} />
            <Grid item xs='auto' className={classes.card}>
              <Paper elevation={3} style={{ borderRadius: "14px" }}>
                <Grid container justify="center" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                  <Fab
                    // onClick={() => navigate("/portal/rachnaye/series/create")}
                    size="small"
                    variant="extended"
                    onClick={() => setWithdrawMethodDialog(true)}
                    disabled={ledgerAccount.withdrawableAmount < 50}
                  >
                    Withdraw</Fab>
                </Grid>
                <Grid container justify="center" style={{ padding: "1rem 1rem" }}>
                  <Typography align="center" variant="body2">{`Min balance required ₹50`}</Typography>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container xs={12}>
            {ledgerAccount.payouts.length > 0 &&
              <Grid item style={{ padding: '1rem' }}>
                <Typography variant="h4" color="primary"
                  onClick={() => navigate("/portal/payouts")}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  You Payout history</Typography>
              </Grid>
            }
          </Grid>

          {/* ACCOUNT DETAILS */}
          <Grid container xs={12} justify="space-evenly" alignItems="center">
            <Grid item xs={12} style={{ padding: '1rem' }}>
              <Typography variant="h2" style={{ fontWeight: "bolder" }}> Account Details </Typography>
            </Grid>
            {/* UPI */}
            <Grid item md={5} sm={12} xs={12}>
              <Paper elevation={3} style={{ borderRadius: "14px" }}>
                <Grid container style={{ padding: "1.5rem" }}>
                  <Grid container xs={12} alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="h3">
                        UPI
                      </Typography>
                    </Grid>
                    <Grid container xs={9} justify="flex-end" style={{ paddingRight: '1.5rem' }}>
                      {isEditableUpiDetail ?
                        <>
                          {upiLoading ? <></> :
                            <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer", marginRight: "1rem" }} color="primary"
                              onClick={updateUpiDetails}>
                              Save
                            </Typography>}

                          <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer" }}
                            onClick={handleCancelUpiEdit}>
                            Cancel
                          </Typography>
                        </>
                        :
                        <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer" }} color="primary"
                          onClick={() => {
                            if (vpa.lastValidatedOn) {
                              if (moment().diff(moment(vpa.lastValidatedOn), "days") > 14) {
                                setIsEditableUpiDetail(true)
                              } else {
                                snackbar({
                                  variant: SnackBarVariant.WARNING,
                                  message: "You can change UPI details only after 14 days"
                                })
                              }
                            } else {
                              setIsEditableUpiDetail(true)
                            }
                          }}
                        >
                          Edit
                        </Typography>}
                    </Grid>
                  </Grid>
                  {
                    isEditableUpiDetail ?
                      upiLoading ?
                        <Grid container xs={12} justify="center" alignContent="center" style={{ marginTop: "2rem" }}>
                          <CircularProgress />
                        </Grid>
                        :
                        <Grid container xs={12} style={{ marginTop: "1rem" }}>
                          <Grid item xs={12} style={{ padding: "0.6rem 0rem" }}>
                            <Typography variant="subtitle2">
                              UPI ID/VPA
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={vpa.vpa}
                              fullWidth
                              onChange={(e) => {
                                setVpa({
                                  ...vpa,
                                  vpa: e.target.value
                                })
                              }}
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ padding: "0.6rem 0rem" }}>
                            <Typography variant="subtitle2">
                              Name of Account Holder
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={vpa.name}
                              fullWidth
                              onChange={(e) => {
                                setVpa({
                                  ...vpa,
                                  name: e.target.value
                                })
                              }}
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ padding: "0.6rem 0rem" }}>
                            <Typography variant="subtitle2">
                              Email ID
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={vpa.emailId}
                              fullWidth
                              onChange={(e) => {
                                setVpa({
                                  ...vpa,
                                  emailId: e.target.value
                                })
                              }}
                              className={classes.textField}
                            />
                          </Grid>
                        </Grid>
                      :
                      <Grid container xs={12} spacing={2} style={{ marginTop: "1rem" }}>
                        <Grid item xs={12} style={{ padding: "0.6rem 0.6rem" }}>
                          <Typography variant="subtitle2" style={{ display: "inline", marginRight: "1rem" }}>
                            Your UPI ID :
                          </Typography>
                          <Typography variant="body1" style={{ display: "inline" }}>
                            {vpa.vpa ? maskEmail(vpa.vpa) : "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0.6rem 0.6rem" }}>
                          <Typography variant="subtitle2" style={{ display: "inline", marginRight: "1rem" }}>
                            UPI Holder Name :
                          </Typography>
                          <Typography variant="body1" style={{ display: "inline" }}>
                            {vpa.name ? maskName(vpa.name) : "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0.6rem 0.6rem" }}>
                          <Typography variant="subtitle2" style={{ display: "inline", marginRight: "1rem" }}>
                            Your Email ID :
                          </Typography>
                          <Typography variant="body1" style={{ display: "inline" }}>
                            {vpa.emailId ? maskEmail(vpa.emailId) : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                  }
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={1} sm={12} xs={12}  >
              <Grid container justify="center" alignItems="center">
                <Typography variant="h3">OR</Typography>
              </Grid>
            </Grid>
            {/* BANK ACCOUNT */}
            <Grid item md={5} sm={12} xs={12}>
              <Paper elevation={3} style={{ borderRadius: "14px" }}>
                <Grid container style={{ padding: "1.5rem" }}>
                  <Grid container xs={12} alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="h3">
                        BANK
                      </Typography>
                    </Grid>
                    <Grid container xs={9} justify="flex-end" style={{ paddingRight: '1.5rem' }}>
                      {isEditableBankDetail ?
                        <>
                          {bankLoading ? <></> :
                            <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer", marginRight: "1rem" }} color="primary"
                              onClick={updateBankDetails}>
                              Save
                            </Typography>}
                          <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer" }}
                            onClick={handleCancleBankEdit}>
                            Cancel
                          </Typography>
                        </>
                        :
                        <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer" }} color="primary"
                          onClick={() => {
                            if (bankDetails.lastValidatedOn) {
                              if (moment().diff(moment(bankDetails.lastValidatedOn), "days") > 14) {
                                setIsEditableBankDetail(true)
                              } else {
                                snackbar({
                                  variant: SnackBarVariant.WARNING,
                                  message: "You can change Bank details only after 14 days"
                                })
                              }
                            } else {
                              setIsEditableBankDetail(true)
                            }
                          }}>
                          Edit
                        </Typography>}
                    </Grid>
                  </Grid>
                  {
                    isEditableBankDetail ?
                      bankLoading ?
                        <Grid container xs={12} justify="center" alignContent="center" style={{ marginTop: "2rem" }}>
                          <CircularProgress />
                        </Grid>
                        :
                        <Grid container xs={12} style={{ marginTop: "1rem" }}>
                          <Grid item xs={12} style={{ padding: "0.6rem 0rem" }}>
                            <Typography variant="subtitle2">
                              Bank Name
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={bankDetails.bankName}
                              fullWidth
                              onChange={(e) => {
                                setBankDetails({
                                  ...bankDetails,
                                  bankName: e.target.value || ""
                                })
                              }}
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ padding: "0.6rem 0rem" }}>
                            <Typography variant="subtitle2">
                              Account Holder's Name
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={bankDetails.accountName}
                              fullWidth
                              onChange={(e) => {
                                setBankDetails({
                                  ...bankDetails,
                                  accountName: e.target.value || ""
                                })
                              }}
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ padding: "0.6rem 0rem" }}>
                            <Typography variant="subtitle2">
                              Account Number
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={bankDetails.accountNumber}
                              fullWidth
                              onChange={(e) => {
                                setBankDetails({
                                  ...bankDetails,
                                  accountNumber: e.target.value || ""
                                })
                              }}
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ padding: "0.6rem 0rem" }}>
                            <Typography variant="subtitle2">
                              IFSC Code
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={bankDetails.ifsc}
                              fullWidth
                              onChange={(e) => {
                                setBankDetails({
                                  ...bankDetails,
                                  ifsc: e.target.value || ""
                                })
                              }}
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ padding: "0.6rem 0rem" }}>
                            <Typography variant="subtitle2">
                              Account Type
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={bankDetails.accountType}
                              fullWidth
                              onChange={(e) => {
                                setBankDetails({
                                  ...bankDetails,
                                  accountType: e.target.value || ""
                                })
                              }}
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ padding: "0.6rem 0rem" }}>
                            <Typography variant="subtitle2">
                              Email ID
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={bankDetails.emailId}
                              fullWidth
                              onChange={(e) => {
                                setBankDetails({
                                  ...bankDetails,
                                  emailId: e.target.value
                                })
                              }}
                              className={classes.textField}
                            />
                          </Grid>
                        </Grid>
                      :
                      <Grid container xs={12} spacing={2} style={{ marginTop: "1rem" }}>
                        <Grid item xs={12} style={{ padding: "0.6rem 0.6rem" }}>
                          <Typography variant="subtitle2" style={{ display: "inline", marginRight: "1rem" }}>
                            Bank Name:
                          </Typography>
                          <Typography variant="body1" style={{ display: "inline" }}>
                            {bankDetails.bankName || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0.6rem 0.6rem" }}>
                          <Typography variant="subtitle2" style={{ display: "inline", marginRight: "1rem" }}>
                            Account Holder's Name:
                          </Typography>
                          <Typography variant="body1" style={{ display: "inline" }}>
                            {bankDetails.accountName ? maskName(bankDetails.accountName) : "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0.6rem 0.6rem" }}>
                          <Typography variant="subtitle2" style={{ display: "inline", marginRight: "1rem" }}>
                            Account Number:
                          </Typography>
                          <Typography variant="body1" style={{ display: "inline" }}>
                            {bankDetails.accountNumber ? maskString(bankDetails.accountNumber, 4) : "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0.6rem 0.6rem" }}>
                          <Typography variant="subtitle2" style={{ display: "inline", marginRight: "1rem" }}>
                            IFSC Code:
                          </Typography>
                          <Typography variant="body1" style={{ display: "inline" }}>
                            {bankDetails.ifsc || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0.6rem 0.6rem" }}>
                          <Typography variant="subtitle2" style={{ display: "inline", marginRight: "1rem" }}>
                            Account Type:
                          </Typography>
                          <Typography variant="body1" style={{ display: "inline" }}>
                            {bankDetails.accountType || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0.6rem 0.6rem" }}>
                          <Typography variant="subtitle2" style={{ display: "inline", marginRight: "1rem" }}>
                            Email ID:
                          </Typography>
                          <Typography variant="body1" style={{ display: "inline" }}>
                            {bankDetails.emailId ? maskEmail(bankDetails.emailId) : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                  }
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          {/* ORDERS TABLE */}
          <Grid container xs={12} style={{ margin: "1.5rem 0rem" }}>
            <Grid item xs={12} style={{ padding: '1rem' }}>
              <Typography variant="h2" style={{ fontWeight: "bolder" }}> Purchases </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={3} style={{ borderRadius: "14px" }}>
                <Grid container style={{ padding: '1rem' }}>
                  <PurchasedSeries />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </>
      }
      <Dialog
        maxWidth={"sm"}
        open={withdrawMethodDialog}
        onClose={() => setWithdrawMethodDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={false}
      >
        {processingWithdrawlLoader ?
          <div className={classes.withdrawalLoader}>
            <CircularLoader />
          </div>
          :
          <>
            <DialogTitle id="alert-dialog-title">
              <Typography variant="h3">
                Select method for withdrawl
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container xs={12}>
                <Card style={{ display: "flex", width: "100%" }}>
                  <CardContent style={{ flex: '1 0 auto' }}>
                    <Grid container xs={12}>
                      <Grid item xs={10}>
                        <Typography component="h5" variant="h3">
                          UPI Withdrawl
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Withdrawl charges: {`₹${getWithdrawlCharges(TRANSFER_MODES.UPI, ledgerAccount.withdrawableAmount)}`}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Amount to be credited: {`₹${ledgerAccount.withdrawableAmount - getWithdrawlCharges(TRANSFER_MODES.UPI, ledgerAccount.withdrawableAmount)}`}
                        </Typography>
                      </Grid>
                      <Grid container xs={2} alignItems="center" justify="center">
                        <ArrowForwardIcon className={classes.forwardIcon} onClick={() => requestWithdrawl(TRANSFER_MODES.UPI)} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card style={{ display: "flex", width: "100%", marginTop: "0.2rem" }}>
                  <CardContent style={{ flex: '1 0 auto' }}>
                    <Grid container xs={12}>
                      <Grid item xs={10}>
                        <Typography component="h5" variant="h3">
                          IMPS Withdrawl
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Withdrawl charges: {`₹${getWithdrawlCharges(TRANSFER_MODES.IMPS, ledgerAccount.withdrawableAmount)}`}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Amount to be credited: {`₹${ledgerAccount.withdrawableAmount - getWithdrawlCharges(TRANSFER_MODES.IMPS, ledgerAccount.withdrawableAmount)}`}
                        </Typography>
                      </Grid>
                      <Grid container xs={2} alignItems="center" justify="center">
                        <ArrowForwardIcon className={classes.forwardIcon} onClick={() => requestWithdrawl(TRANSFER_MODES.IMPS)} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card style={{ display: "flex", width: "100%", marginTop: "0.2rem" }}>
                  <CardContent style={{ flex: '1 0 auto' }}>
                    <Grid container xs={12}>
                      <Grid item xs={10}>
                        <Typography component="h5" variant="h3">
                          NEFT Withdrawl
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Withdrawl charges: {`₹${getWithdrawlCharges(TRANSFER_MODES.NEFT, ledgerAccount.withdrawableAmount)}`}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Amount to be credited: {`₹${ledgerAccount.withdrawableAmount - getWithdrawlCharges(TRANSFER_MODES.NEFT, ledgerAccount.withdrawableAmount)}`}
                        </Typography>
                      </Grid>
                      <Grid container xs={2} alignItems="center" justify="center">
                        <ArrowForwardIcon className={classes.forwardIcon} onClick={() => requestWithdrawl(TRANSFER_MODES.NEFT)} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Fab variant="extended" size="medium" onClick={() => setWithdrawMethodDialog(false)} className="blackBackButton">
                Cancel
              </Fab>
            </DialogActions>
          </>
        }
      </Dialog>

    </Grid >
  );
};

export default WritersEarnings;