import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import CustomizedSelects from "./CustomizedSelects";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { CircularProgress, Grid, Typography, Paper } from "@material-ui/core";
import { useSelector } from "react-redux";

import {
  Filler, LinearScale, LineController, Title, LineElement, PointElement, Chart,
} from 'chart.js';

Chart.register(Filler, LineController, LineElement, PointElement, LinearScale, Title);

const RentalvsPurchases = () => {
  const fetchData = async (spaceId: string) => {
    setIsLoading(true);
    try {
      const timezone = 'Asia/Kolkata';
      const res1 = await callApi(PATHNAMES.PUBLISHER_RENTAL_V_PURCHASE(spaceId || "", selectedMonthCount, timezone), HTTP_METHODS.GET)
      setRentalvPurchase(res1?.data)
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);

    }
  }
  const [RentalvPurchase, setRentalvPurchase] = useState<any>([])
  const [selectedMonthCount, setSelectedMonthCount] = useState<number>(1);
  const userState = useSelector((state: IAppState) => state.user);
  const [isLoading, setIsLoading] = useState<Boolean>(true);

  useEffect(() => {
    if (userState && userState.spaceId) {
      fetchData(userState.spaceId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState, selectedMonthCount]);
  let label: any
  let rentalArr, paperbackRevenueArr: any[], hardboundRevenueArr: any[], RentalArr, PurchasesArr, data: any

  if (!isLoading && RentalvPurchase) {
    rentalArr = RentalvPurchase?.map((rental: any) => {
      return rental.rentalRevenue
    })

    paperbackRevenueArr = RentalvPurchase?.map((paperBack: any) => {
      return paperBack.paperbackRevenue
    })

    hardboundRevenueArr = RentalvPurchase?.map((paperBack: any) => {
      return paperBack.hardboundRevenue
    })

    let purchasesArr = paperbackRevenueArr?.map((a: any, i: any) => a + hardboundRevenueArr[i]);

    let endArr = RentalvPurchase?.map((paperBack: any) => {
      return paperBack.endDate
    })

    label = [RentalvPurchase[0]?.startDate, ...endArr]

    RentalArr = [0, ...rentalArr]
    PurchasesArr = [0, ...purchasesArr]

    data = {
      labels: [...label],
      datasets: [
        {
          label: "RENTALS",
          data: RentalArr,
          fill: 'start',
          backgroundColor: "rgba(255,92,62,0.4)",
          borderColor: "rgb(255,92,62)",
          tension: 0.4
        },
        {
          label: "PURCHASES",
          data: PurchasesArr,
          fill: 'start',
          borderColor: "rgb(92,216,159)",
          backgroundColor: "rgba(92,216,159,0.5)",
          tension: 0.4
        }
      ]
    };
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
        }
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: (value: any) => `₹ ${value}`
        }
      }
    },
  };

  const handleMonthCount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedMonthCount(event.target.value as number);
  };

  return (
    <Paper elevation={5} style={{ width: "100%" }} >
      {isLoading ? (<Grid item style={{ marginLeft: '40%', marginTop: '30%' }}><CircularProgress /></Grid>) : (
        <>
          <Grid container xs={8} style={{ padding: "1rem", paddingBottom: "0.3rem" }}>
            <Typography variant="h3" style={{ fontWeight: 600 }}>
              Rental VS Purchase
            </Typography>
          </Grid>
          {RentalvPurchase ? (
            <Grid item xs>
              <CustomizedSelects value={selectedMonthCount} onChange={handleMonthCount} type={'lifetime'} />
              <Line options={options} data={data} style={{ padding: '1rem' }} />
            </Grid>
          ) : (<Grid container justify="center" alignContent="center" style={{ marginTop: '20%' }}><Typography variant="h2">No Data to display</Typography></Grid>)}

        </>
      )}
    </Paper>
  );
}
export default RentalvsPurchases;
