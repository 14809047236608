import { createStyles, IconButton, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import _ from "lodash";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions
} from "mui-datatables";
import LoopIcon from '@material-ui/icons/Loop';

import React from "react";
import { IFilter, IFiltersAndSorting } from "../../../utils/common.interface";

import { Pagination, SelectableRows, TableActions } from "./types";
import { useSnackBar } from "../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../SnackbarWrapper/SnackbarWrapper";

const useStyles = makeStyles((theme) =>
  createStyles({
    tableContainer: {
      marginRight: "0px"
    },
    refreshBtn: {
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  })
);

interface IProps {
  columns: MUIDataTableColumnDef[];
  rows: (object | number[] | string[])[];
  title: string | React.ReactNode;
  options?: MUIDataTableOptions;
  setSelection?(items: any): void;
  hideSelectableRows?: boolean;
  fetchData: (args: IFiltersAndSorting) => void;
  serverSide: boolean;
}

export const DataTable = (props: IProps) => {
  // FIXME: Add option to hide checkboxes
  const classes = useStyles();
  const snackbar = useSnackBar();
  const search = _.debounce(props.fetchData, 800)

  const onRowsSelect = (currentRowsSelected: any[], allRowsSelected: any[]) => {
    if (props.setSelection) {
      const selection = allRowsSelected.map(
        (item: any) => props.rows[item.dataIndex]
      );
      props.setSelection(selection);
    }
  };

  const handleRefresh = () => {
    props.fetchData({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
    snackbar({
      message: "Refreshed successfully",
      variant: SnackBarVariant.SUCCESS
    })
  }

  // default options
  const options: MUIDataTableOptions = {
    // customToolbarSelect code is added just to disable the delete icon from table, to be removed later on
    customToolbarSelect: (
      selectedRows: any,
      displayData: any,
      setSelectedRows: any
    ) => <></>,
    customToolbar: () => {
      return (
        <IconButton className={classes.refreshBtn} onClick={handleRefresh} >
          <LoopIcon titleAccess="Refresh" />
        </IconButton>
      );
    },
    onRowsSelect,
    search: true,
    selectableRows: props.hideSelectableRows ? "none" : SelectableRows.SINGLE,
    // custom search for making search case-insensitive
    customSearch: (searchQuery: any, currentRow: any, columns: any) => {
      let isFound = false;
      currentRow.forEach((col: any) => {
        if (col) {
          const value = col.toString().toLowerCase();
          if (searchQuery && value.indexOf(searchQuery.toLowerCase()) >= 0) {
            isFound = true;
          }
        }
      });
      return isFound;
    },
    downloadOptions: {
      filename: "tableDownload.csv",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    },
    serverSide: props.serverSide,
    // table actions when server side pagination and filter is enabled (serverSide = true)
    onTableChange: (action, tableState) => {
      const { filterList, columns, searchText, sortOrder, page, rowsPerPage } = tableState

      let customFilterArr: IFilter[] = []
      filterList.forEach((data: string[], idx: number) => {
        if (data.length) {
          const columnName = columns[idx].name
          customFilterArr.push({ data, columnName })
        }
      })

      // API CALL BASED ON TABLE ACTION -start
      if ([TableActions.CHANGE_PAGE, TableActions.CHANGE_ROWS_PER_PAGE].includes(action)) {
        if (customFilterArr.length && !searchText) {
          props.fetchData({
            pageNumber: page,
            pageSize: rowsPerPage,
            searchText: searchText || "",
            applyFilter: true,
            filters: customFilterArr
          });
        } else {
          props.fetchData({
            pageNumber: page,
            pageSize: rowsPerPage,
            searchText: searchText || "",
            sortBy: sortOrder
          });
        }
      }

      if (TableActions.SEARCH === action) {
        search({
          pageNumber: Pagination.DEFAULT_PAGE_NUMBER,
          pageSize: rowsPerPage,
          searchText: searchText || ""
        });
      }
      if (TableActions.SORT === action) {
        props.fetchData({
          pageNumber: Pagination.DEFAULT_PAGE_NUMBER,
          pageSize: rowsPerPage,
          searchText: searchText || "",
          sortBy: sortOrder
        });
      }

      if (TableActions.FILTER_CHANGE === action) {
        props.fetchData({
          pageNumber: Pagination.DEFAULT_PAGE_NUMBER,
          pageSize: rowsPerPage,
          searchText: searchText || "",
          sortBy: sortOrder,
          applyFilter: true,
          filters: customFilterArr
        });
      }

      // API CALL BASED ON TABLE ACTION -end

    },
    onFilterChange: (changedColumn, filterList, type) => {
      if (type === TableActions.FILTER_ACTION_RESET) {
        props.fetchData({
          pageNumber: Pagination.DEFAULT_PAGE_NUMBER,
          pageSize: Pagination.DEFAULT_PAGE_SIZE,
          searchText: "",
        });
      }
    }
  };

  const myOptions = props.options ? { ...options, ...props.options } : options;

  const renderRowCount = (rowsPerPage: number, page: number, rowsVisible: number) => {
    if ((page + 1) * rowsPerPage < rowsVisible) {
      return `${page * rowsPerPage}-${(page + 1) * rowsPerPage}`
    }
    else {
      return `${page * rowsPerPage}-${rowsVisible}`
    }
  }
  return (
    <div className={["table-container", classes.tableContainer].join(" ")} style={{ width: "100%" }}>
      <MUIDataTable

        title={props.title}
        data={props.rows}
        columns={props.columns}
        options={{
          ...myOptions,
          customFooter: (
            rowCount: number,
            page: number,
            rowsPerPage: number,
            changeRowsPerPage: any,
            changePage: any
          ) => {
            return <div style={{ fontSize: 14, margin: "10px 20px 20px 0", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <span>Rows per page:</span>
              <div style={{ padding: "0 40px 0 10px" }}>
                <Select
                  variant="outlined"
                  id="MUI_TABLE_NO_OF_ROWS_CHANGE"
                  value={rowsPerPage}
                  onChange={(e) => {
                    const value = parseInt(`${e.target.value}`)
                    changeRowsPerPage(value)
                    if (page * value > rowCount) {
                      let page = Math.floor(rowCount / value) - 1;
                      if(page < 0)
                        page = 0;
                      changePage(page);
                    }
                  }}
                  SelectDisplayProps={{
                    style: { paddingTop: 8, paddingBottom: 8 }
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </div>
              <span style={{ marginRight: 20 }}>{renderRowCount(rowsPerPage, page, rowCount)} of {rowCount}</span>
              <IconButton disabled={page < 1} onClick={() => changePage(page - 1)}>
                <ChevronLeftIcon />
              </IconButton>
              <IconButton disabled={(page + 1) * rowsPerPage >= rowCount} onClick={() => changePage(page + 1)}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          }
        }}
      />

    </div>
  );
};
