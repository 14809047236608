import { CircularProgress, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IDistributor } from "../../../../reducers/distributors/types";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import DistributorDetailTabView from "./DistributorDetailTabView";
import DistributorSummary from "./DistributorSummary"

const DistributorDetail: React.FC = () => {
  const location = useLocation();
  const [value, setValue] = useState<IDistributor>();
  const userState = useSelector((state: IAppState) => state.user);

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search);
      const distributorId = params.get("distributor");
      if (distributorId) {
        fetchDistributor(distributorId)
      }
    }
  }, [location, userState]);

  const fetchDistributor = async (distributorId: string) => {
    const res = await callApi(PATHNAMES.GET_DISTRIBUTOR_BY_ID(distributorId), HTTP_METHODS.GET)
    if (res && res.success && res.data) {
      setValue(res.data);
    }
  }


  if (!value) {
    return (
      <Grid container xs={12} justify='center'>
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <Grid container xs={12} style={{ marginRight: "1rem" }}>
      <DistributorSummary distributor={value} />
      <DistributorDetailTabView />
    </Grid>
  )
};

export default DistributorDetail;