import { Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IBook } from "../../../reducers/books/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import CircularLoader from "../../common/CircularLoader";
import { Pagination } from "../../common/Datatable/types";
import BooksGrid from "./BooksGrid";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: '0.5rem'
  },
  graphContainer: {
    padding: "1rem",
    minHeight: '20rem'
  },
  viewAllBtn: {
    marginTop: "0.3rem",
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: "inline",
  },
  hoverEffect: {
    // cursor: 'pointer',
    transition: 'transform 0.4s ease',

    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
  card: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)", // Adjust the shadow values as needed
  },
  gridItem: {
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "translateY(-5px)",
    },
  },
  largeCard: {
    width: '240px',
    height: '65px', // Adjust the height as per your preference
  },
}))


const MobileUserDashboard: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [featuredBooks, setFeaturedBooks] = useState<IBook[]>([]);
  const [topReadBooks, setTopReadBooks] = useState<IBook[]>([]);
  const [newArrivalsBooks, setNewArrivalsBooks] = useState<IBook[]>([]);
  const [books, setBooks] = useState<any>();

  useEffect(() => {
    fetchBooksForPortal();
  }, [mobileUserState.id]);


  const fetchBooksForPortal = async () => {

    callApi(PATHNAMES.GET_FEATURED_BOOKS_PORTAL(Pagination.DEFAULT_PAGE_NUMBER,Pagination.DEFAULT_PAGE_SIZE), HTTP_METHODS.GET).then((res1) => {
      if (res1 && res1.data) {
        setFeaturedBooks(res1.data)
      }
    })
    callApi(PATHNAMES.GET_TOP_READ_BOOKS_PORTAL(Pagination.DEFAULT_PAGE_NUMBER,Pagination.DEFAULT_PAGE_SIZE), HTTP_METHODS.GET).then((res2) => {
      if (res2 && res2.data) {
        setTopReadBooks(res2.data)
      }
    })
    callApi(PATHNAMES.GET_NEW_ARRIVALS_BOOKS_PORTAL(Pagination.DEFAULT_PAGE_NUMBER,Pagination.DEFAULT_PAGE_SIZE), HTTP_METHODS.GET).then((res3) => {
      if (res3 && res3.data) {
        setNewArrivalsBooks(res3.data)
      }
    })
    // callApi(PATHNAMES.GET_RECOMMENDED_BOOKS_PORTAL(), HTTP_METHODS.GET).then((res4) => {
    //   if (res4 && res4.data) {
    //     setRecommendedBooks(res4.data)
    //   }
    // })
    const res = await callApi(PATHNAMES.LIST_BOOKS_BY_CATERGORIES_PORTAL, HTTP_METHODS.GET)
    if (res && res.success && res.data) {
      setBooks(res.data);
      const response = await callApi(PATHNAMES.GET_RESOURCE_BY_ID("en"), HTTP_METHODS.GET)
      if (response && response.success && response.data?.content) {
        setIsLoading(false)
        const booksArr: any = []
        for (let key in res.data) {
          const category = response.data.content[key.toUpperCase()];
          if (category) {
            const item = {
              label: category,
              books: res.data[key]
            }
            booksArr.push(item)
          }
        }
      }
    }
  }

  const renderBooksByCategories = () => {
    return Object.keys(books).map((category) => {
      const booksArr = books[category];
      const categoryName = category.replaceAll("-", " ").split(" ").map(str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).join(" ")
      return (
        <Grid container xs={12} spacing={2} style={{ margin: "2rem 0rem", marginLeft: "1.5rem" }}>
          <Grid container xs={12}>
            <Grid item xs={11}>
              <Typography variant="h3" style={{ fontWeight: "bolder" }}>
                {categoryName}
              </Typography>
            </Grid>
            <Grid container xs={1}>
              <Typography variant="h4" className={classes.viewAllBtn}
                onClick={() => navigate(`/portal/category?name=${category}`)}>
                View All
              </Typography>
            </Grid>
          </Grid>
          <BooksGrid books={booksArr} />
        </Grid>
      )
    })

  }

  if (isLoading) {
    return <CircularLoader message={"Wait for a bit, We are fetching books for you."} />
  }

  return (
    <Grid container xs={12} justify="flex-start" >
      {featuredBooks.length > 0 && <Grid container xs={12} spacing={2} style={{ margin: "2rem 0rem", marginLeft: "1.5rem" }}>
        <Grid container xs={12}>
          <Grid item xs={11}>
            <Typography variant="h3" style={{ fontWeight: "bolder" }}>
              Featured Books
            </Typography>
          </Grid>
          <Grid container xs={1}>
            <Typography variant="h4" className={classes.viewAllBtn}
              onClick={() => navigate("/portal/featured")}>
              View All
            </Typography>
          </Grid>
        </Grid>
        <BooksGrid books={featuredBooks} />
      </Grid>
      }
      {newArrivalsBooks.length > 0 && <Grid container xs={12} spacing={2} style={{ margin: "2rem 0rem", marginLeft: "1.5rem" }}>
        <Grid container xs={12}>
          <Grid item xs={11}>
            <Typography variant="h3" style={{ fontWeight: "bolder" }}>
              New Arrival Books
            </Typography>
          </Grid>
          <Grid container xs={1}>
            <Typography variant="h4" className={classes.viewAllBtn}
              onClick={() => navigate("/portal/newArrivals")}>
              View All
            </Typography>
          </Grid>
        </Grid>
        <BooksGrid books={newArrivalsBooks} />
      </Grid>
      }

      {/* DONT SHOW RECOMMENDED BOOKS UNTILL RECOMMENDATION API IS FIXED (recommendation engine in progress) */}
      {/* {recommendedBooks.length > 0 && <Grid container xs={12} spacing={2} style={{ margin: "2rem 0rem", marginLeft: "1.5rem" }}>
        <Grid container xs={12}>
          <Grid item xs={11}>
            <Typography variant="h3" style={{ fontWeight: "bolder" }}>
              Recommended Books
            </Typography>
          </Grid>
          <Grid container xs={1}>
            <Typography variant="h4" className={classes.viewAllBtn}
              onClick={() => navigate("/portal/recommended")}>
              View All
            </Typography>
          </Grid>
        </Grid>
        // <Grid container xs={12} spacing={2}>
          <BooksGrid books={recommendedBooks} />
        // </Grid>
      </Grid>
      } */}

      {topReadBooks.length > 0 && <Grid container xs={12} spacing={2} style={{ margin: "2rem 0rem", marginLeft: "1.5rem" }}>
        <Grid container xs={12}>
          <Grid item xs={11}>
            <Typography variant="h3" style={{ fontWeight: "bolder" }}>
              Top Reads
            </Typography>
          </Grid>
          <Grid container xs={1}>
            <Typography variant="h4" className={classes.viewAllBtn}
              onClick={() => navigate("/portal/top-reads")}>
              View All
            </Typography>
          </Grid>
        </Grid>
        <BooksGrid books={topReadBooks} />
      </Grid>
      }
      {renderBooksByCategories()}
    </Grid >
  )
};

export default MobileUserDashboard;