import { CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import RevenueCard from "../../common/RevenueCard";
import PieChartRevenue from "./PieChartRevenue";
import TopPerformingBooks from "./TopPerformBarchart";
import RentalvsPurchases from "./RentalvsPurchases";
import Demographic from './Demographic';
import IncomeVsExpense from "./IncomeVsExpense"
import RevenueDateRangeSelect from "./RevenueDateRangeSelect";

interface IStats {
  books: number,
  orders: number,
  revenue: number,
  readers: number,
  followers: number
};
const useStyles = makeStyles(() => ({
  cardContainer: {
    padding: '0.5rem'
  },
  graphContainer: {
    padding: "1rem",
    minHeight: '20rem'
  }
}))
const Dashboard: React.FC = () => {
  const classes = useStyles()
  const userState = useSelector((state: IAppState) => state.user);
  const [stats, setStats] = useState<IStats>();
  const [isLoading, setIsLoading] = useState<Boolean>(true);

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: "",
    endDate: ""
  });


  const handleSelctedDateRange = (timeObj: any) => {
    setSelectedDateRange(v => timeObj);
  };

  const fetchPublisherData = async (spaceId: string, selectedDateRange: any) => {
    try {
      const res = await callApi(PATHNAMES.PUBLISHER_STATS(spaceId, selectedDateRange.startDate, selectedDateRange.endDate), HTTP_METHODS.GET)
      if (res?.data) {
        setStats(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }


  useEffect(() => {
    if (userState && userState.spaceId && selectedDateRange.endDate && selectedDateRange.startDate) {
      fetchPublisherData(userState.spaceId, selectedDateRange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);



  const handleClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
  }

  if (userState.space?.status === "pending") {
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title"
        open={true}>
        <DialogTitle id="simple-dialog-title">Awaiting Account Approval</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sorry for the incovenience caused! Please contact the administrator to get your account approved.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Grid container xs={12} justify="flex-start">
      <RevenueDateRangeSelect heading="Revenue :" handleOptionChange={handleSelctedDateRange} />
      {isLoading ?
        <CircularProgress />
        :
        <Grid container xs={12} spacing={2} style={{ marginTop: "1rem" }}>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="revenue"
              name="Total Revenue"
              value={stats?.revenue || 0}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="books"
              name="Total Books"
              value={stats?.books || 0}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="users"
              name="Total Readers"
              value={stats?.readers || 0}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="orders"
              name="Total Orders"
              value={stats?.orders || 0}
            />
          </Grid>
          <Grid container xs={12} sm={6} md={4} lg={3} xl={3} className={classes.cardContainer}>
            <RevenueCard
              icon="followers"
              name="Total Followers"
              value={stats?.followers || 0}
            />
          </Grid>
        </Grid>
      }
      <Grid container xs={12} spacing={3} style={{ marginTop: '2rem' }}>
        <Grid container xs={12} sm={6} md={6} lg={4} xl={3} className={classes.graphContainer}>
          <PieChartRevenue />
        </Grid>
        <Grid container xs={12} sm={6} md={6} lg={4} xl={3} className={classes.graphContainer}>
          <TopPerformingBooks />
        </Grid>
        <Grid container xs={12} sm={6} md={6} lg={4} xl={3} className={classes.graphContainer}>
          <RentalvsPurchases />
        </Grid>
        <Grid container xs={12} sm={6} md={6} lg={4} xl={3} className={classes.graphContainer}>
          <Demographic />
        </Grid>
        <Grid container xs={12} sm={6} md={6} lg={4} xl={3} className={classes.graphContainer}>
          <IncomeVsExpense />
        </Grid>
      </Grid>

    </Grid >
  );
};
export default Dashboard;

