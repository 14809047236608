import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Slide,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography
} from "@material-ui/core";
import { AddCircleOutline, CheckCircle, Delete, ExpandLess, ExpandMore } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { IAudiBookForAPICall, IAudioBook, IAudioBookContent, IAudioBookPreviewContent, IBook } from "../../../../reducers/books/types";
import callApi from "../../../../utils/apiUtil";
import { getLanguageLabelByValue } from "../../../../utils/bookUtils";
import { BOOK_LANGUAGES, HTTP_METHODS, ResourceTypes } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { uploadAudioFileToS3, uploadFileToS3 } from "../../../../utils/s3Util";
import { ErrorHandler } from "../../../../utils/utils";
import ImageUploader from "../../../common/ImageUploader";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { TransitionProps } from "@material-ui/core/transitions";

export interface IProps {
  book: IBook;
  open: boolean;
  handleClose: () => void;
}

interface ISelectedChapter {
  content: IAudioBookContent;
  idx: number;
  side: string;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: "white",
    color: "black"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: '100%',
    elevation: 5,
    backgroundColor: "lightgray",
    border: "0.5px solid gray",
    margin: "0 5px 5px 0"
  },
  nested: {
    paddingLeft: "2rem"
  },
  subContent: {
    fontSize: '13px',
    fontWeight: 500,
    paddingTop: '0.5rem',
    color: "#000000",
  },
  margin: {
    paddingTop: '0.4rem',
    height: '1.8rem',
    "& .MuiFilledInput-input": {
      paddingTop: '10px',
      fontSize: '12px',
    },
    "& .MuiFilledInput-root": {
      "border-radius": '8px',
      "paddingTop": '1px'
    },
    "& .MuiFilledInput-underline:before": {
      "border-bottom": 'none'
    },
    "& .MuiFilledInput-underline:after": {
      "border-bottom": 'none'
    },
  },
  uploadButton: {
    width: "8rem",
    backgroundColor: "#FF5C3E",
    color: "white",
    padding: ".8rem 1rem",
    borderRadius: ".3rem",
  },
  subHeading: {
    fontSize: '12px',
    fontWeight: 500,
    color: "#808080"
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide
    direction="up"
    mountOnEnter
    unmountOnExit
    ref={ref} {...props} />;
});

const defaultValues: IAudioBook = {
  status: "DRAFT",
  language: "",
  price: 0,
  totalDuration: 0,
  previewMedia: undefined,
  frontContent: [],
  bodyContent: [],
  backContent: []
}

const defaultPreviewData = {
  mediaUrl: "",
  duration: 0,
  title: "Sample",
  idx: 0
};

const UploadAudiobook: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [audioBook, setAudioBook] = useState<IAudioBook>({ ...defaultValues, language: props.book.language });
  const [frontOpen, setFrontOpen] = useState<boolean>(false);
  const [bodyOpen, setBodyOpen] = useState<boolean>(false);
  const [backOpen, setBackOpen] = useState<boolean>(false);
  const [selectedChapter, setSelectedChapter] = useState<ISelectedChapter>();
  const [selectedAudio, setSelectedAudio] = useState<any>();
  const [audioBookThumbnail, setAudiBookThumbnail] = useState<string>("");
  const snackbar = useSnackBar();


  useEffect(() => {
    if (props.book.audioBook) {
      setAudioBook(props.book.audioBook)
      if (props.book.audioBook.thumbnailUrl?.includes("cloudfront.net")) {
        setAudiBookThumbnail(props.book.audioBook.thumbnailUrl)
      }

      if (props.book.audioBook.frontContent.length > 0) {
        setFrontOpen(true)
      }
      if (props.book.audioBook.bodyContent.length > 0) {
        setBodyOpen(true)
      }
      if (props.book.audioBook.backContent.length > 0) {
        setBackOpen(true)
      }
    }
  }, [props.book.audioBook])

  const addRow = (side: string) => {
    const data: IAudioBookContent = {
      idx: 0,
      title: "New Chapter",
      mediaUrl: "",
      duration: 0
    }
    switch (side) {
      case "front":
        const frontContent = audioBook?.frontContent || []
        if (frontContent.length) {
          data.idx = frontContent.length
        }
        frontContent.push(data)
        if (audioBook) {
          setAudioBook({
            ...audioBook,
            frontContent
          })
        }
        break;
      case "body":
        const bodyContent = audioBook?.bodyContent || []
        if (bodyContent.length) {
          data.idx = bodyContent.length
        }
        bodyContent.push(data)
        if (audioBook) {
          setAudioBook({
            ...audioBook,
            bodyContent
          })
        }
        break;
      case "back":
        const backContent = audioBook?.backContent || []
        if (backContent.length) {
          data.idx = backContent.length
        }
        backContent.push(data)
        if (audioBook) {
          setAudioBook({
            ...audioBook,
            backContent
          })
        }
        break;
      case "preview":
        data.title = "Sample"
        if (audioBook) {
          setAudioBook({
            ...audioBook,
            previewMedia: data
          })
        }
        break;
      default:
        break;
    }
  }

  const uploadDocument = async (file: File) => {
    const urlObj = URL.createObjectURL(file);
    setAudiBookThumbnail(urlObj)
    const key = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.BOOK_IMAGE,
      isAdmin: false,
      publisherId: props.book.publisher
    });
    setAudioBook({
      ...audioBook,
      thumbnailUrl: key
    })
  }

  const calculateTotalDuration = () => {
    let totalDuration = 0;
    if (audioBook) {
      audioBook.frontContent.forEach((item => {
        totalDuration += item.duration
      }))
      audioBook.bodyContent.forEach((item => {
        totalDuration += item.duration
      }))
      audioBook.backContent.forEach((item => {
        totalDuration += item.duration
      }))
      if (audioBook.previewMedia && audioBook.previewMedia.duration) {
        totalDuration += audioBook.previewMedia?.duration
      }
    }
    return totalDuration;
  }

  const handlePriceChange = async () => {
    try {
      const response = await callApi(PATHNAMES.UPDATE_AUDIOBOOK_PRICE(props.book.id, audioBook.price), HTTP_METHODS.PATCH)
      snackbar({
        message: response.message,
        variant: SnackBarVariant.SUCCESS
      })
    } catch (e: any) {
      snackbar({
        message: e.message || "Something went wrong",
        variant: SnackBarVariant.ERROR
      })
    }
  }

  const handleDeleteChapter = async (side: string, idx: number) => {
    //  allow published chapters to be deleted
    switch (side) {
      case "front":
        let frontContent = audioBook?.frontContent || []
        if (frontContent.length) {
          const otherChapterExceptIdx = frontContent.filter(chapter => chapter.idx !== idx)
          frontContent = otherChapterExceptIdx
        }

        //index re-arrangment
        frontContent = frontContent.map((chapter, idx) => {
          chapter.idx = idx;
          return chapter
        })

        // hide uploading audio page if selected chapter gets deleted
        if (selectedChapter?.side === side && selectedChapter.idx === idx) {
          setSelectedChapter(undefined)
        }
        if (audioBook) {
          setAudioBook({
            ...audioBook,
            frontContent
          })
        }
        break;
      case "body":
        let bodyContent = audioBook?.bodyContent || []
        if (bodyContent.length) {
          const otherChapterExceptIdx = bodyContent.filter(chapter => chapter.idx !== idx)
          bodyContent = otherChapterExceptIdx
        }

        //index re-arrangment
        bodyContent = bodyContent.map((chapter, idx) => {
          chapter.idx = idx;
          return chapter
        })

        // hide uploading audio page if selected chapter gets deleted
        if (selectedChapter?.side === side && selectedChapter.idx === idx) {
          setSelectedChapter(undefined)
        }
        if (audioBook) {
          setAudioBook({
            ...audioBook,
            bodyContent
          })
        }
        break;
      case "back":
        let backContent = audioBook?.backContent || []
        if (backContent.length) {
          const otherChapterExceptIdx = backContent.filter(chapter => chapter.idx !== idx)
          backContent = otherChapterExceptIdx
        }

        //index re-arrangment
        backContent = backContent.map((chapter, idx) => {
          chapter.idx = idx;
          return chapter
        })

        // hide uploading audio page if selected chapter gets deleted
        if (selectedChapter?.side === side && selectedChapter.idx === idx) {
          setSelectedChapter(undefined)
        }
        if (audioBook) {
          setAudioBook({
            ...audioBook,
            backContent
          })
        }
        break;
      case "preview":
        const data = { ...audioBook }
        delete data.previewMedia

        // hide uploading audio page if selected chapter gets deleted
        if (selectedChapter?.side === side) {
          setSelectedChapter(undefined)
        }
        if (audioBook) {
          setAudioBook({
            ...data
          })
        }
        break;
      default:
        break;
    }
  }

  const convertS3UrltoNormalWithoutDomainUrlForDB = (url: string) => {
    if (!url) {
      return ""
    }
    if (url.includes("cloudfront.net")) {
      const parsedUrl = new URL(url);
      const pathWithoutDomain = parsedUrl.pathname.substring(1); // Remove leading "/"
      return pathWithoutDomain;
    }
    return url
  }

  const validateAndAddChapterThatWasLeftToFillUp = (side: string, chapter: IAudioBookPreviewContent | IAudioBookContent) => {
    if (!chapter.mediaUrl) {
      return {
        side,
        title: chapter.title
      }
    }
    return null
  }

  const validateNullVaulesBeforeSaving = () => {
    let validValues = false
    if (audioBook.frontContent.length || audioBook.backContent.length || audioBook.bodyContent.length) {
      validValues = true
    }
    return validValues
  }

  const publishAudioBook = async (publish: boolean) => {
    try {
      const validated = await validateNullVaulesBeforeSaving()
      if (!validated) {
        snackbar({
          message: "Can not save draft without adding audiobook files/chapters",
          variant: SnackBarVariant.WARNING
        })
        return
      }

      const totalDuration = calculateTotalDuration();
      let data: IAudiBookForAPICall = { ...audioBook }
      data.language = props.book.language

      let remainedChapterToFill: any

      data.thumbnailUrl = convertS3UrltoNormalWithoutDomainUrlForDB(data?.thumbnailUrl || "")
      data.frontContent = data.frontContent?.map((item) => {
        let { file, ...rest } = item
        rest.mediaUrl = convertS3UrltoNormalWithoutDomainUrlForDB(rest.mediaUrl)
        const noData = validateAndAddChapterThatWasLeftToFillUp("Front Content", rest)
        remainedChapterToFill = remainedChapterToFill ? remainedChapterToFill : noData
        return rest
      })
      data.bodyContent = data.bodyContent?.map((item) => {
        let { file, ...rest } = item
        rest.mediaUrl = convertS3UrltoNormalWithoutDomainUrlForDB(rest.mediaUrl)
        const noData = validateAndAddChapterThatWasLeftToFillUp("Body Content", rest)
        remainedChapterToFill = remainedChapterToFill ? remainedChapterToFill : noData
        return rest
      })
      data.backContent = data.backContent?.map((item) => {
        let { file, ...rest } = item
        rest.mediaUrl = convertS3UrltoNormalWithoutDomainUrlForDB(rest.mediaUrl)
        const noData = validateAndAddChapterThatWasLeftToFillUp("Back Content", rest)
        remainedChapterToFill = remainedChapterToFill ? remainedChapterToFill : noData
        return rest
      })
      if (data.previewMedia) {
        let { file, idx, ...rest } = data.previewMedia
        rest.mediaUrl = convertS3UrltoNormalWithoutDomainUrlForDB(rest.mediaUrl)
        const noData = validateAndAddChapterThatWasLeftToFillUp("Preview Content", rest)
        remainedChapterToFill = remainedChapterToFill ? remainedChapterToFill : noData
        data.previewMedia = rest
      }
      data.totalDuration = totalDuration

      // validation for un uploaded mediafile for any chapter before saving
      if (remainedChapterToFill) {
        throw new ErrorHandler(`${remainedChapterToFill.side}'s ${remainedChapterToFill.title} need to be completed first`)
      }


      if (publish) {
        if (!data.bodyContent.length) {
          throw new ErrorHandler("Please add body content before publishing")
        }
        const response = await callApi(PATHNAMES.PUBLISH_AUDIOBOOK(props.book.id), HTTP_METHODS.PATCH, {
          body: JSON.stringify({ data })
        })
        snackbar({
          message: response.message,
          variant: SnackBarVariant.SUCCESS
        })
      } else {
        const response = await callApi(PATHNAMES.SAVE_AUDIOBOOK_DRAFT(props.book.id), HTTP_METHODS.PATCH, {
          body: JSON.stringify({ data })
        })
        snackbar({
          message: response.message,
          variant: SnackBarVariant.SUCCESS
        })
      }
    } catch (e: any) {
      snackbar({
        message: e.message || "Something went wrong",
        variant: SnackBarVariant.ERROR
      })
      return
    }
  }

  if (!audioBook) {
    return <CircularProgress />
  }

  return (
    <Dialog
      fullScreen
      // maxWidth={"xl"}
      fullWidth={true}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {audioBook.status === "PUBLISHED" ? "AudioBook" : "Upload AudioBook"}
          </Typography>
          <Fab
            size="small"
            variant="extended"
            className="blackBackButton"
            onClick={props.handleClose}
          >
            Cancel
          </Fab>
          <Box pl={2}></Box>
          <Fab size="medium" variant="extended" disabled={audioBook.status === "PUBLISHED"} onClick={() => publishAudioBook(false)}>
            Save Draft
          </Fab>
          <Box pl={2}></Box>
          <Fab size="medium" variant="extended" onClick={() => publishAudioBook(true)}>
            {audioBook.status === "PUBLISHED" ? "Update" : "Publish"}
          </Fab>
        </Toolbar>
      </AppBar>
      <DialogContent dividers={true}>
        <Grid container xs={12} style={{ marginLeft: "2rem" }}>
          <Grid container xs={3} style={{ borderRight: "1px solid black" }}>
            <List component={"nav"} className={classes.root}>
              <ListItem button >
                <ListItemText primary="Preview Content" />
                {audioBook.previewMedia ? <></> :
                  <ListItemIcon onClick={(e) => {
                    e.stopPropagation()
                    addRow("preview")
                  }}>
                    <AddCircleOutline />
                  </ListItemIcon>
                }
              </ListItem>
              <Divider />
              <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {!audioBook.previewMedia ? <></> :
                    <>
                      <ListItem button className={classes.nested}
                        onClick={() => {
                          if (audioBook.previewMedia) {
                            const content = audioBook.previewMedia
                            setSelectedChapter({
                              content,
                              idx: 0,
                              side: "preview"
                            })
                            if (content.file) {
                              const urlObj = URL.createObjectURL(content.file);
                              setSelectedAudio(urlObj)
                            } else if (content.mediaUrl) {
                              setSelectedAudio(content.mediaUrl)
                            }
                          }
                        }}
                      >
                        <ListItemText primary={`${audioBook.previewMedia.title}`} />
                        {audioBook.previewMedia.file && (audioBook.previewMedia.isUploading ? <ListItemIcon>
                          <CircularProgress size={"1.5rem"} />
                        </ListItemIcon> : <ListItemIcon>
                          <CheckCircle style={{ color: "green" }} />
                        </ListItemIcon>)}
                        <ListItemIcon onClick={(e) => {
                          e.stopPropagation()
                          return handleDeleteChapter("preview", 0)
                        }} >
                          <Delete />
                        </ListItemIcon>
                      </ListItem>
                      <Divider />
                    </>
                  }
                </List>
              </Collapse>
            </List>
            <Divider />
            <List component={"nav"} className={classes.root}>
              <ListItem button onClick={() => setFrontOpen(!frontOpen)} style={{ cursor: "pointer" }}>
                <ListItemText primary="Front Content" />
                <ListItemIcon onClick={(e) => {
                  e.stopPropagation()
                  addRow("front")
                }}>
                  <AddCircleOutline />
                </ListItemIcon>
                {audioBook?.frontContent.length ? (frontOpen ? <ExpandLess /> : <ExpandMore />) : ""}
              </ListItem>
              <Divider />
              <Collapse in={frontOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {audioBook?.frontContent.map((content) => {
                    return <>
                      <ListItem button className={classes.nested}
                        onClick={() => {
                          setSelectedChapter({
                            content: content,
                            idx: content.idx,
                            side: "front"
                          })
                          if (content.file) {
                            const urlObj = URL.createObjectURL(content.file);
                            setSelectedAudio(urlObj)
                          } else if (content.mediaUrl) {
                            setSelectedAudio(content.mediaUrl)
                          }
                        }}
                      >
                        <ListItemText primary={`${content.idx + 1}. ${content.title}`} />
                        {content.file && (content.isUploading ? <ListItemIcon>
                          <CircularProgress size={"1.5rem"} />
                        </ListItemIcon> : <ListItemIcon>
                          <CheckCircle style={{ color: "green" }} />
                        </ListItemIcon>)}
                        <ListItemIcon onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteChapter("front", content.idx)
                        }} >
                          <Delete />
                        </ListItemIcon>
                      </ListItem>
                      <Divider />
                    </>
                  })}
                </List>
              </Collapse>
            </List>
            <Divider />
            <List component={"nav"} className={classes.root}>
              <ListItem button onClick={() => setBodyOpen(!bodyOpen)} style={{ cursor: "pointer" }}>
                <ListItemText primary="Body Content" />
                <ListItemIcon onClick={(e) => {
                  e.stopPropagation()
                  addRow("body")
                }}>
                  <AddCircleOutline />
                </ListItemIcon>
                {audioBook?.bodyContent.length ? (bodyOpen ? <ExpandLess /> : <ExpandMore />) : ""}
              </ListItem>
              <Divider />
              <Collapse in={bodyOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {audioBook?.bodyContent.map((content) => {
                    return <>
                      <ListItem button className={classes.nested}
                        onClick={() => {
                          setSelectedChapter({
                            content: content,
                            idx: content.idx,
                            side: "body"
                          })
                          if (content.file) {
                            const urlObj = URL.createObjectURL(content.file);
                            setSelectedAudio(urlObj)
                          } else if (content.mediaUrl) {
                            setSelectedAudio(content.mediaUrl)
                          }
                        }}
                      >
                        <ListItemText primary={`${content.idx + 1}. ${content.title}`} />
                        {content.file && (content.isUploading ? <ListItemIcon>
                          <CircularProgress size={"1.5rem"} />
                        </ListItemIcon> : <ListItemIcon>
                          <CheckCircle style={{ color: "green" }} />
                        </ListItemIcon>)}
                        <ListItemIcon onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteChapter("body", content.idx)
                        }} >
                          <Delete />
                        </ListItemIcon>
                      </ListItem>
                      <Divider />
                    </>
                  })}
                </List>
              </Collapse>
            </List>
            <Divider />
            <List component={"nav"} className={classes.root}>
              <ListItem button onClick={() => setBackOpen(!backOpen)} style={{ cursor: "pointer" }}>
                <ListItemText primary="Back Content" />
                <ListItemIcon onClick={(e) => {
                  e.stopPropagation()
                  addRow("back")
                }}>
                  <AddCircleOutline />
                </ListItemIcon>
                {audioBook?.backContent.length ? (backOpen ? <ExpandLess /> : <ExpandMore />) : ""}
              </ListItem>
              <Divider />
              <Collapse in={backOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {audioBook?.backContent.map((content) => {
                    return <>
                      <ListItem button className={classes.nested}
                        onClick={() => {
                          setSelectedChapter({
                            content: content,
                            idx: content.idx,
                            side: "back"
                          })
                          if (content.file) {
                            const urlObj = URL.createObjectURL(content.file);
                            setSelectedAudio(urlObj)
                          } else if (content.mediaUrl) {
                            setSelectedAudio(content.mediaUrl)
                          }
                        }}
                      >
                        <ListItemText primary={`${content.idx + 1}. ${content.title}`} />
                        {content.file && (content.isUploading ? <ListItemIcon>
                          <CircularProgress size={"1.5rem"} />
                        </ListItemIcon> : <ListItemIcon>
                          <CheckCircle style={{ color: "green" }} />
                        </ListItemIcon>)}
                        <ListItemIcon onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteChapter("back", content.idx)
                        }} >
                          <Delete />
                        </ListItemIcon>
                      </ListItem>
                      <Divider />
                    </>
                  })}
                </List>
              </Collapse>
            </List>
          </Grid>
          <Grid xs={4} style={{ borderRight: "1px solid black" }}>
            {selectedChapter && !_.isEmpty(selectedChapter) && <>

              <Grid container xs={10} spacing={2} style={{ margin: "1rem" }}>
                <Typography variant='h5' className={classes.subHeading}>Chapter Name</Typography>
                <TextField
                  variant="filled"
                  value={selectedChapter.content.title}
                  className={classes.margin}
                  fullWidth
                  onChange={(e) => {
                    let previewMedia: IAudioBookContent | undefined = audioBook.previewMedia;
                    const frontContent: IAudioBookContent[] = audioBook?.frontContent || [];
                    const bodyContent: IAudioBookContent[] = audioBook?.bodyContent || [];
                    const backContent: IAudioBookContent[] = audioBook?.backContent || [];
                    switch (selectedChapter.side) {
                      case "front":
                        if (frontContent[selectedChapter.idx]) {
                          frontContent[selectedChapter.idx] = {
                            ...selectedChapter.content,
                            title: e.target.value || ""
                          }
                        }
                        break;
                      case "body":
                        if (bodyContent[selectedChapter.idx])
                          bodyContent[selectedChapter.idx] = {
                            ...selectedChapter.content,
                            title: e.target.value || ""
                          }
                        break;
                      case "back":
                        if (backContent[selectedChapter.idx])
                          backContent[selectedChapter.idx] = {
                            ...selectedChapter.content,
                            title: e.target.value || ""
                          }
                        break;
                      case "preview":
                        previewMedia = audioBook.previewMedia || defaultPreviewData
                        if (previewMedia)
                          previewMedia = {
                            ...selectedChapter.content,
                            title: e.target.value || ""
                          }
                        break;
                      default:
                        break;
                    }
                    setSelectedChapter({
                      ...selectedChapter,
                      content: {
                        ...selectedChapter.content,
                        title: e.target.value || ""
                      }
                    })
                    if (audioBook) {
                      setAudioBook({
                        ...audioBook,
                        frontContent,
                        bodyContent,
                        backContent,
                        previewMedia
                      })
                    }
                  }}
                />
              </Grid>
              <Grid item container xs={12} spacing={1} style={{ margin: "1rem" }}>
                {!selectedChapter.content.mediaUrl && <Grid item xs={12}>
                  <input
                    id={`btn-upload`}
                    name={`btn-upload`}
                    style={{ display: "none" }}
                    type="file"
                    accept=".mp3,audio/*"
                    onChange={async (e) => {
                      if (e.target.files?.length) {
                        const file = e.target.files[0];
                        const urlObj = URL.createObjectURL(e.target.files[0]);
                        let fileDuration = 0;
                        const audio = document.createElement('audio');
                        audio.src = urlObj;
                        audio.addEventListener('loadedmetadata', () => {
                          fileDuration = Math.floor(audio.duration)
                        });
                        audio.remove();
                        setSelectedAudio(urlObj)
                        let previewMedia: IAudioBookContent | undefined = audioBook?.previewMedia
                        const frontContent: IAudioBookContent[] = audioBook?.frontContent || [];
                        const bodyContent: IAudioBookContent[] = audioBook?.bodyContent || [];
                        const backContent: IAudioBookContent[] = audioBook?.backContent || [];
                        setSelectedChapter({
                          ...selectedChapter,
                          content: {
                            ...selectedChapter.content,
                            file: file,
                            isUploading: true,
                            duration: fileDuration
                          }
                        })
                        const key = await uploadAudioFileToS3({
                          file,
                          isAdmin: false,
                          publisherId: props.book.publisher,
                          bookId: props.book.id
                        });

                        switch (selectedChapter.side) {
                          case "front":
                            if (frontContent[selectedChapter.idx]) {
                              frontContent[selectedChapter.idx] = {
                                ...selectedChapter.content,
                                file,
                                mediaUrl: key,
                                duration: fileDuration
                              }
                            }
                            break;
                          case "body":
                            if (bodyContent[selectedChapter.idx]) {
                              bodyContent[selectedChapter.idx] = {
                                ...selectedChapter.content,
                                file,
                                mediaUrl: key,
                                duration: fileDuration
                              }
                            }
                            break;
                          case "back":
                            if (backContent[selectedChapter.idx]) {
                              backContent[selectedChapter.idx] = {
                                ...selectedChapter.content,
                                file,
                                mediaUrl: key,
                                duration: fileDuration
                              }
                            }
                            break;
                          case "preview":
                            previewMedia = audioBook.previewMedia || defaultPreviewData
                            if (previewMedia) {
                              previewMedia = {
                                ...selectedChapter.content,
                                file,
                                mediaUrl: key,
                                duration: fileDuration
                              }
                            }
                            break;
                          default:
                            break;
                        }
                        setSelectedChapter({
                          ...selectedChapter,
                          content: {
                            ...selectedChapter.content,
                            isUploading: false,
                            mediaUrl: key,
                            duration: fileDuration
                          }
                        })
                        if (audioBook) {
                          setAudioBook({
                            ...audioBook,
                            frontContent,
                            bodyContent,
                            backContent,
                            previewMedia
                          })
                        }
                      }
                    }}
                  />
                  <label htmlFor={`btn-upload`}>
                    <Button
                      variant="contained"
                      size="small"
                      component="span"
                      className={classes.uploadButton}
                    >
                      Choose File
                    </Button>
                  </label>
                </Grid>}
                {selectedChapter.content.mediaUrl &&
                  <Grid item xs={12}>
                    <figure>
                      <Typography style={{ display: 'inline' }} variant="h4">{selectedChapter.content.file?.name || ""}</Typography>
                      <IconButton
                        onClick={() => {
                          if (audioBook.status === "PUBLISHED") {
                            snackbar({
                              message: "Can not delete file, Since chapter has been published",
                              variant: SnackBarVariant.ERROR
                            })
                            return
                          }
                          let previewMedia: IAudioBookContent = audioBook?.previewMedia || defaultPreviewData
                          const frontContent: IAudioBookContent[] = audioBook?.frontContent || [];
                          const bodyContent: IAudioBookContent[] = audioBook?.bodyContent || [];
                          const backContent: IAudioBookContent[] = audioBook?.backContent || [];
                          switch (selectedChapter.side) {
                            case "front":
                              if (frontContent[selectedChapter.idx]) {
                                frontContent[selectedChapter.idx] = {
                                  ...selectedChapter.content,
                                  mediaUrl: ""
                                }
                                delete frontContent[selectedChapter.idx].file
                              }
                              break;
                            case "body":
                              if (bodyContent[selectedChapter.idx]) {
                                bodyContent[selectedChapter.idx] = {
                                  ...selectedChapter.content,
                                  mediaUrl: ""
                                }
                                delete bodyContent[selectedChapter.idx].file
                              }
                              break;
                            case "back":
                              if (backContent[selectedChapter.idx]) {
                                backContent[selectedChapter.idx] = {
                                  ...selectedChapter.content,
                                  mediaUrl: ""
                                }
                                delete backContent[selectedChapter.idx].file
                              }
                              break;
                            case "preview":
                              previewMedia = {
                                ...selectedChapter.content,
                                mediaUrl: ""
                              }
                              delete previewMedia.file
                              break;
                            default:
                              break;
                          }
                          let data = selectedChapter;
                          data.content.mediaUrl = "";
                          delete data.content.file;
                          setSelectedChapter(data)
                          if (audioBook) {
                            setAudioBook({
                              ...audioBook,
                              frontContent,
                              bodyContent,
                              backContent,
                              previewMedia
                            })
                          }
                        }}
                        aria-label="delete"
                        style={{ display: 'inline' }}
                      >
                        <CancelIcon />
                      </IconButton>
                      <audio controls src={selectedAudio}>
                        <a href={selectedChapter.content.mediaUrl}> Download audio </a>
                      </audio>
                    </figure>
                    {/* <audio controls>
                      <source src={selectedChapter.content.mediaUrl} type={"audio/mpeg"} />
                    </audio> */}
                  </Grid>
                }
              </Grid>
            </>}
          </Grid>
          <Grid container xs={4} style={{ paddingLeft: "1rem" }}>
            <Grid item xs={12}>
              <Typography variant='h5' className={classes.subHeading}>LANGUAGE</Typography>
              <Autocomplete
                options={BOOK_LANGUAGES}
                autoHighlight
                disablePortal={true}
                disabled={true}
                defaultValue={{ label: getLanguageLabelByValue(audioBook.language), value: audioBook?.language || "" }}
                getOptionLabel={(language: any) => language.label}
                renderOption={(language) => (
                  <>
                    {`${language.label}`}
                  </>
                )}
                style={{ paddingRight: '0rem' }}
                renderInput={(params) => (
                  <TextField {...params} variant="filled" className={classes.margin} placeholder="Search for language" />
                )}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              <Typography variant='h5' className={classes.subHeading}>DESCRIPTION</Typography>
              <TextareaAutosize
                rowsMin={7}
                rowsMax={7}

                className={classes.subContent}
                style={{ marginTop: "1rem", width: "98%", backgroundColor: "#f2f2f2" }}
                value={audioBook?.description || ""}
                onChange={(e) => {
                  setAudioBook({
                    ...audioBook,
                    description: e.target.value
                  })
                }}
              />
            </Grid>
            <Grid container xs={12} spacing={2} style={{ marginTop: "1rem" }}>
              <Grid item xs={6}>
                <Typography variant='h5' className={classes.subHeading}>Studio Name</Typography>
                <TextField
                  variant="filled"
                  value={audioBook.studio}
                  className={classes.margin}
                  fullWidth
                  onChange={(e) => {
                    setAudioBook({
                      ...audioBook,
                      studio: e.target.value
                    })
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5' className={classes.subHeading}>Narrated By</Typography>
                <TextField
                  variant="filled"
                  value={audioBook.narratedBy}
                  className={classes.margin}
                  fullWidth
                  onChange={(e) => {
                    setAudioBook({
                      ...audioBook,
                      narratedBy: e.target.value
                    })
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5' className={classes.subHeading}> PRICE</Typography>
                <NumericFormat
                  allowNegative={false}
                  value={audioBook.price || 0}
                  customInput={TextField}
                  decimalScale={2}
                  variant="filled"
                  className={classes.margin}
                  InputProps={{
                    startAdornment: <InputAdornment style={{ marginTop: '0rem' }} position="start">₹</InputAdornment>
                  }}
                  fullWidth
                  onValueChange={(value: NumberFormatValues) => {
                    // floatValue always gives a number or undefined
                    if (!value.floatValue) {
                      setAudioBook({
                        ...audioBook,
                        price: 0
                      })
                    } else {
                      setAudioBook({
                        ...audioBook,
                        price: value.floatValue
                      })
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                {audioBook.status === "PUBLISHED" &&
                  <Typography variant="h4" color="primary" style={{ cursor: "pointer", padding: "2rem 0rem" }} onClick={handlePriceChange}>Save Price </Typography>
                }
              </Grid>
            </Grid>
            <Grid container item xs={6} style={{ marginTop: "1rem" }}>
              <Card style={{ height: '13rem', maxWidth: '8rem' }}>
                {audioBookThumbnail ?
                  <CardMedia
                    component="img"
                    height="160"
                    alt="Front Cover"
                    title="Front Cover"
                    image={audioBookThumbnail}
                  /> :
                  <Grid container justify="center" alignContent="center" xs={12} style={{ height: 160 }}>
                    <Typography variant='h3'>Add Image</Typography>
                  </Grid>
                }
                <CardActions>
                  <Typography style={{ marginTop: "0.2rem", color: "#808080" }}>Cover Image</Typography>
                  <ImageUploader id={'AudioBookCover'} handleSave={uploadDocument} />
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default UploadAudiobook;
