import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  CircularProgress,
  Fab
} from "@material-ui/core";
import React from "react";
import { CsvUploadStatusType, ICsvUploadError } from "../../../utils/constants";
import { FilePicker } from "../../common/FilePicker";
import InputField from "../../common/InputField";

interface IBulkCsvUploadProps {
  title: string;
  subTitle?: string;
  csvTemplateLink: string;
  uploadStatus: CsvUploadStatusType;
  uploadErrors: ICsvUploadError[];
  fileName: string;
  handleClose: () => void;
  onDocumentSelect: (event: any) => void;
  uploadCsv: () => void;
  message?: string;
}

export const BulkCsvUploadDialog: React.FC<IBulkCsvUploadProps> = (props) => {
  return (
    <Dialog
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{ padding: "20px 24px 10px" }}>
        <Typography variant="h2">Upload Multiple {props.title}</Typography>
      </DialogTitle>
      <DialogContent style={{ overflowY: "hidden", paddingBottom: "20px" }}>
        <Grid item container xs={12} spacing={2}>
          <Grid container item xs={12}>
            <Typography variant="body1">
              Download templates .xlsx file:
              <a
                style={{ color: "#000" }}
                href={props.csvTemplateLink}
              >
                {" "}
                {props.subTitle}{" "}
              </a>
            </Typography>
            {props.message && <Typography variant="body1" style={{ marginTop: 8 }}>
              {`(${props.message})`}
            </Typography>}
          </Grid>
          <Grid container item xs={8}>
            <InputField
              handleInput={() => { }}
              placeholder={"File Name"}
              value={props.fileName}
              name={"fileName"}
              disabled
            />
          </Grid>
          <Grid container item xs={4}>
            <FilePicker
              onFilePick={(e: any) => {
                props.onDocumentSelect(e);
              }}
              accept=".xlsx"
            />
          </Grid>
          <Grid container item xs={6}>
            {props.uploadStatus === CsvUploadStatusType.IN_PROGRESS ? (
              <CircularProgress />
            ) : (
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="uploadXlsx"
                onClick={props.uploadCsv}
                disabled={!props.fileName}
              >
                Upload
              </Fab>
            )}
          </Grid>
          {props.uploadErrors.length > 0 && (
            <Grid item container xs={12}>
              <div>
                {props.uploadErrors.map((upldError: ICsvUploadError, idx: number) => {
                  return (
                    <div
                      key={idx}
                    >{`${props.title} can't be uploaded at row ${upldError.index +
                      1} because ${upldError.message}`}</div>
                  );
                })}
              </div>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
