import { Chip, Collapse, Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles, TextField, Typography } from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { useState } from "react";
import offer from "../../../../assets/offer.png";
import { IValidCouponResponse } from "../../../../reducers/offers/types";
import { IOrderResponse } from "../../../../reducers/orders/types";

interface IProps {
  order: IOrderResponse;
  coupons: IValidCouponResponse[];
  handleRemoveCoupon: () => void;
  handleApplyCoupon: (couponCode: string) => void;
}


const useStyles = makeStyles(() => ({
  removeRippel: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  borderStyle: {
    borderRadius: 4,
    border: "1px solid #9e9694"
  },
  couponContainer: { display: 'flex', alignItems: 'center', padding: '0.7rem 0.6rem' },
  couponImg: { height: "1.8rem", padding: "0.1rem 0.6rem" },
  msg1: { fontWeight: 500, display: 'inline', color: 'green', margin: '0rem 0.6rem' },
  removeBtn: { cursor: "pointer", padding: "0.2rem 0rem 0rem 1rem", color: "red" },
  searchBox: { padding: '0rem 2rem 1.6rem 2rem' },
  applyBtn: { cursor: "pointer", paddingTop: "1rem", color: "red" },
  applyBtn2: { cursor: "pointer", paddingTop: "0.2rem", color: "red" },
  couponInfoInLines: { display: 'inline', margin: '0rem 0.6rem' },
  couponInfoLine1: { display: 'inline' },
  couponInfoLine2: { display: 'inline', margin: '0rem 0.6rem' },
  couponInfoLine3: {
    display: 'inline', margin: '0rem 0.6rem', fontSize: 11,
    textDecoration: "underline", color: "#777777", cursor: 'pointer'
  },
}))

const CouponContainer: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [openCouponList, setOpenCouponList] = useState<boolean>(false);
  const [openViewCoupon, setOpenViewCoupon] = useState<boolean>(false);
  const [userTypedCoupon, setUserTypedCoupon] = useState<string>("")
  const [veiwCoupon, setVeiwCoupon] = useState<IValidCouponResponse>()

  const handlesetOpenCouponList = () => {
    setOpenCouponList(v => !v)
  }

  return (
    <Grid item xs={12} className={classes.borderStyle} style={{ marginTop: '1rem' }}>
      {/* HEADER  */}
      <Grid container xs={12}>
        <Grid item xs={11} justify='center' >
          <div className={classes.couponContainer}>
            <img alt="Offer" src={offer} className={classes.couponImg} />
            <Typography variant="h3" >Use Coupons</Typography>
          </div>
        </Grid>
        {!props.order.offerCode && <Grid container xs={1} justify='center'>
          <IconButton className={classes.removeRippel} onClick={handlesetOpenCouponList}>
            {openCouponList ?
              <RemoveIcon style={{ fontSize: 30 }} /> :
              <ChevronRightIcon fontSize="large" />
            }
          </IconButton>
        </Grid>}

      </Grid>

      {/* COUPONS CONTAINER */}
      {props.order.offerCode ?
        // SHOW COUPON NAME IF APPLIED 
        <Grid container xs={12} style={{ padding: '0.3rem' }}>
          <Grid item xl={10} lg={10} md={10} sm={10} xs={12} style={{ paddingLeft: '1rem' }}>
            <Chip
              size='small'
              style={{ borderRadius: 5, fontSize: '0.7rem' }}
              label={props.order.offerCode}
            />
            <Typography variant="body1" className={classes.msg1}>has been applied</Typography>
          </Grid>
          <Grid item xl={1} lg={1} md={1} sm={1} xs={12}>
            <Typography variant="body1" onClick={props.handleRemoveCoupon} className={classes.removeBtn}>Remove</Typography>
          </Grid>
        </Grid>
        :
        // ELSE SHOW LIST TO SEARCH AND SELECT COUPON 

        <Collapse in={openCouponList} timeout="auto" unmountOnExit>
          <Grid container xs={12} style={{ padding: "0.4rem" }} >
            {/* SEARCH BOX  */}
            <Grid container xs={12} justify='center' className={classes.searchBox}>
              <Grid item xl={10} lg={10} md={10} sm={10} xs={12} >
                <TextField
                  variant="standard"
                  fullWidth
                  label="Type coupon code here"
                  value={userTypedCoupon}
                  style={{ height: "1rem" }}
                  onChange={(e) => {
                    setUserTypedCoupon(v => e.target.value.trim().toUpperCase())
                  }}
                />
              </Grid>
              <Grid item xs={2} style={{ padding: '1rem' }}>
                <Typography variant="body1" onClick={() => props.handleApplyCoupon(userTypedCoupon)} className={classes.applyBtn}>Apply</Typography>
              </Grid>

            </Grid>
            {/* LIST OF COUPONS  */}
            {props.coupons.length > 0 ?

              props.coupons.map((coupon, idx) => (
                <Grid container key={idx} xs={12} style={{ padding: '0.3rem' }}>
                  <Grid item xl={10} lg={10} md={10} sm={10} xs={10} style={{ paddingLeft: '1rem' }}>
                    <Chip
                      size='small'
                      style={{ borderRadius: 5, fontSize: '0.7rem' }}
                      label={coupon.code}
                    />

                    <div className={classes.couponInfoInLines}>
                      <Typography variant="h4" className={classes.couponInfoLine1} >{coupon.name}</Typography>
                      <Typography variant="h5" className={classes.couponInfoLine2}>{coupon.discountMsg}</Typography>
                      <Typography variant="body2" onClick={() => {
                        setVeiwCoupon(v => ({ ...coupon }))
                        setOpenViewCoupon(true)
                      }} className={classes.couponInfoLine3}>View Details</Typography>

                    </div>

                  </Grid>

                  <Grid item xl={1} lg={1} md={1} sm={1} xs={2}>
                    <Typography variant="body1" onClick={() => props.handleApplyCoupon(coupon.code)} className={classes.applyBtn2}>Apply</Typography>
                  </Grid>

                </Grid>))
              :
              <Grid container xs={12} justify='center' style={{ padding: '0rem 2rem 1.6rem 2rem' }}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12} >
                  <Typography variant="body1" style={{}}>{"No related coupon are available right now :( "}</Typography>
                </Grid>
              </Grid>
            }
          </Grid>
        </Collapse>
      }

      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={openViewCoupon}
        onClose={() => setOpenViewCoupon(false)}
      >
        <DialogTitle>
          <Grid container xs={12}>
            <Grid item xs={11}>
              <Typography variant="h3">{veiwCoupon?.name}</Typography>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon style={{ fontSize: 25, cursor: 'pointer' }} onClick={() => setOpenViewCoupon(false)} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true} style={{ minHeight: "30vh" }}>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Chip
                size='small'
                style={{ borderRadius: 5, fontSize: '0.7rem' }}
                label={veiwCoupon?.code}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3">{veiwCoupon?.discountMsg}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" style={{ fontSize: '0.8rem', color: 'blue' }}>{veiwCoupon?.discountMsg2}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" style={{ fontSize: '0.8rem', color: 'red' }}>{veiwCoupon?.validOnTotalValueMsg}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Description :-</Typography>
              <Typography variant="body2">{`• ${veiwCoupon?.description}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Terms & conditions :-</Typography>
              {veiwCoupon?.details?.map(info => (
                <Typography variant="body2">{`• ${info}`}</Typography>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

    </Grid>
  )
}

export default CouponContainer;