import { CircularProgress, Fab } from "@material-ui/core";
import React, { useState } from "react";
import { useSnackBar } from '../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from "./SnackbarWrapper/SnackbarWrapper";
import callApi from "../../utils/apiUtil";
import { HTTP_METHODS } from "../../utils/constants";
import { PATHNAMES } from "../../utils/pathNames";
var XLSX = require("xlsx");


interface IProps {
  publisherId: string;
  className?: string
}

const BooksExcelDownloadBtn: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar()
  const [loading, setLoading] = useState<boolean>(false)



  const handleExcelDownload = async () => {
    try {
      setLoading(true)

      const data = await fetchPublisherBooks()

      // Convert data to Excel format (assuming you have a function for this)
      const excelData: any = await convertToExcel(data);

      // Create blob from Excel data
      const blob = new Blob([excelData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

      // Create URL for the blob
      const bookURL = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = `Books_${new Date().getTime()}.xlsx`;
      link.href = bookURL
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(bookURL);
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      snackbar({
        message: error.message,
        variant: SnackBarVariant.ERROR
      })
    }
  }


  const fetchPublisherBooks = async () => {
    try {
      const res = await callApi(PATHNAMES.GET_BOOKS_FOR_EXCEL_BY_PUBLISHER_ID(props.publisherId), HTTP_METHODS.GET)
      if (!res || !res.success) {
        throw new Error("Books not found for download")
      }
      return res.data
    } catch (error: any) {
      throw error
    }
  }


  const convertToExcel = (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Books");
    const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
    return excelBuffer
  }

  return <Fab
    variant="extended"
    size="medium"
    className={props.className}
    disabled={!props.publisherId}
    onClick={handleExcelDownload}
  >
    {loading ? <CircularProgress size="1.6rem" style={{ color: "white" }} /> : "Download books"}
  </Fab>

}


export default BooksExcelDownloadBtn;