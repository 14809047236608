import {
  CircularProgress,
  createStyles,
  Fab,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: "1rem",
      padding: "1rem",
      width: "100%",
      marginRight: "1rem"
    },
    addButton: {
      color: "black!important",
      backgroundColor: "#FFD36E!important",
      marginRight: "1rem",
    },
  })
);

const LoginAndSecurity: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar()
  const user = useSelector((state: IAppState) => state.user);
  const [otp, setOtp] = useState<number>();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [otpSent, setOtpSent] = useState<boolean>(false);

  const sendResetPasswordOtp = () => {
    setLoading(true)
    const data = {
      emailId: user.emailId.toLowerCase()
    }
    callApi(PATHNAMES.SEND_RESET_PASSWORD_OTP, HTTP_METHODS.POST, {
      body: JSON.stringify({ data })
    }).then((res) => {
      setLoading(false)
      if (res && res.success) {
        setOtpSent(true)
      }
    })
  }

  const resetPassword = () => {
    if (!password || !confirmPassword) {
      snackbar({
        message: "Password cannot be empty",
        variant: SnackBarVariant.ERROR
      });
      return
    }
    if (password !== confirmPassword) {
      snackbar({
        message: "Password do not match",
        variant: SnackBarVariant.ERROR
      });
      return
    }
    const data = {
      emailId: user.emailId.toLowerCase(),
      otp,
      newPassword: confirmPassword
    }
    callApi(PATHNAMES.RESET_PASSWORD, HTTP_METHODS.POST, {
      body: JSON.stringify({ data })
    }).then((res) => {
      setLoading(false)
      if (res && res.success) {
        setOtpSent(false)
        snackbar({
          message: "Password reset successfully",
          variant: SnackBarVariant.SUCCESS
        });
      }
    })
  }

  return (
    <Grid container xs={6}>
      <Paper className={classes.root}>
        <Grid container xs={6}>
          <Grid container item xs={12}>
            <Typography variant="h2" style={{ fontWeight: 600 }}>
              Reset Password
            </Typography>
          </Grid>
        </Grid>
        {!otpSent ?
          <Grid container xs={12} style={{ marginTop: "1rem" }}>
            <Fab
              variant="extended"
              size="medium"
              disabled={loading}
              className={classes.addButton}
              onClick={() => sendResetPasswordOtp()}
            >
              GET OTP on email
            </Fab>
          </Grid>
          : <Grid container xs={12} spacing={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={6}>
              <Typography variant="h4" style={{ color: "#808080" }}>
                Enter OTP
              </Typography>
              <TextField
                id="outlined-number"
                type="number"
                fullWidth
                value={otp || 0}
                onChange={(e) => {
                  if (parseInt(e.target.value) >= 0) {
                    setOtp(parseInt(e.target.value))
                  }
                }}
                style={{ marginTop: "0.5rem" }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Typography variant="h4" style={{ color: "#808080" }}>
                Enter Password
              </Typography>
              <TextField
                variant="outlined"
                value={password}
                type="password"
                label="Password"
                fullWidth
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                style={{ marginTop: "0.5rem" }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4" style={{ color: "#808080" }}>
                Confirm Password
              </Typography>
              <TextField
                variant="outlined"
                value={confirmPassword}
                type="password"
                label="Confirm Password"
                fullWidth
                onChange={(e) => {
                  setConfirmPassword(e.target.value)
                }}
                style={{ marginTop: "0.5rem" }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "1rem" }} spacing={1}>
              <Fab
                size="medium"
                variant="extended"
                disabled={!otp || !password || !confirmPassword}
                onClick={() => resetPassword()}
              >
                {loading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                Save
              </Fab>
            </Grid>
          </Grid>
        }
      </Paper>
    </Grid>);
};

export default LoginAndSecurity;