import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAppState } from "../../../store";
import { useSelector } from "react-redux";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import GuestUsersNameChange from "../../common/GuestUsersNameChange";

const useStyles = makeStyles(() => ({
  gridContainer: {
    margin: "2rem auto",
  },
  card: {
    fontFamily: "Poppins, sans-serif",
    width: "250px",
    height: "85px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    position: "relative",
    "&:hover": {
      transform: "scale(1.10,1.23)",
      boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
      zIndex: 100,
    },
    marginLeft: "5px",
    marginBottom: "-11px",
  },
  cardTitle: {
    fontFamily: "Poppins",
    fontSize: "1.3rem",
    lineHeight: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "1rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    transition: "top 0.5s ease, font-size 0.5s ease",
    opacity: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardDescription: {
    fontFamily: "Sans-serif, sans-serif",
    fontSize: "0.9rem",
    lineHeight: "0.7rem",
    textAlign: "center",
    opacity: 0,
    transition: "opacity 0.3s ease",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    overflow: "hidden",
  },
  centerContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  cardTitleUnderline: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "2px",
    width: 0,
    background: "#333333",
    transition: "width 0.3s ease",
  },
}));

const PortalUserHome: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const [askForGuestUsersInfoOpen, setAskForGuestUsersInfoOpen] = useState<boolean>(false);
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  useEffect(() => {
    if (!mobileUserState.id) {
      return
    }
    let requestedUrl: any = localStorage.getItem("requestedUrl")
    if (requestedUrl) {
      requestedUrl = new URL(requestedUrl)
      const urls = requestedUrl.href.split(requestedUrl.host)
      // if requested url is one of portal urls then redirect else don't
      if (urls && urls.length > 1) {
        const [, url] = urls
        if (url && url.includes('/portal')) {
          navigate(url)
        } else {
          snackbar({
            message: "Not authorized to access",
            variant: SnackBarVariant.ERROR
          })
        }
        localStorage.removeItem("requestedUrl")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState]);

  const navigateTo = (path: string) => {
    //  path is of create new rachnaye/series then check guest user, and don't allow to create
    // all rachnaye/series has "/rachnaye" as prefix
    if (path.includes("/rachnaye") && mobileUserState.firstName.includes("Guest_")) {
      setAskForGuestUsersInfoOpen(true)
      return
    }
    navigate(path);
  };

  let timeoutId: NodeJS.Timeout;

  const handleMouseEnter = (event: {
    currentTarget: { querySelector: (arg0: string) => any };
  }) => {
    clearTimeout(timeoutId); // Clear any existing timeout

    const title = event.currentTarget.querySelector(".cardTitle");
    const description = event.currentTarget.querySelector(".cardDescription");

    title.style.top = "13%";
    title.style.fontSize = "1.2rem";
    title.style.transition = "top 0.3s, font-size 0.3s";

    description.style.opacity = 0;
    description.style.width = "80%";
    description.style.fontSize = "0.6rem";
    description.style.top = "100%";
    description.style.transform = "translate(-50%, 0)";
    description.style.transition =
      "opacity 0.3s, top 0.5s, font-size 0.5s, transform 0.5s";

    timeoutId = setTimeout(() => {
      description.style.opacity = 1;
      description.style.top = "60%";
      description.style.transform = "translate(-50%, -50%)";
    }, 150);
  };

  const handleMouseLeave = (event: {
    currentTarget: { querySelector: (arg0: string) => any };
  }) => {
    clearTimeout(timeoutId); // Clear the timeout

    const title = event.currentTarget.querySelector(".cardTitle");
    const description = event.currentTarget.querySelector(".cardDescription");

    title.style.top = "50%";
    title.style.fontSize = "1.35rem";
    title.style.transition = "top 0.5s, font-size 0.5s";

    description.style.opacity = 0;
    description.style.top = "120%";
    description.style.fontSize = "0.9rem";
    description.style.transform = "translate(-50%, -50%)";
    description.style.transition =
      "opacity 0.5s, top 0.5s, font-size 0.5s, transform 0.5s";
    setTimeout(() => {
      title.style.top = "50%";
    }, 500);
  };


  const exploreItems1stRow = [
    {
      title: "Explore",
      description: " On this digital emporium, where literature resides, Unearth \n treasures aplenty, where imagination presides. Explore the \n uncharted realms, where dreams take flight, And let the \n written word guide you, day and night.",
      link: "/portal/explore-books",
      color: "linear-gradient(45deg, #E6E6FA 20%, #FFFACD 80%)"
    },
    //TODO: move rad only to 4th row items , remove from here when bundles and offers are working
    {
      title: "Read Only...",
      description: "Only Read",
      link: "/portal/digitalReads",
      color: "linear-gradient(45deg, #F5F5F5 20%, #FFFAF0 80%)"
    },
    // {
    //   title: "Explore Bundles",
    //   description: "Explore the landscapes of prose, wild and grand, In stories, \nfind solace, take a stand. Through bundles, wander, explore\nwithout end, A literary odyssey, where hearts and minds\ntranscend.",
    //   link: "/portal/bundles",
    //   color: "linear-gradient(45deg, #FFE4E1 20%, #F0FFFF 80%)"
    // },
    // {
    //   title: "Explore Offers",
    //   description: "Embrace the adventure that books provide, Across Rachnaye\n platform, take a joyous stride. With offers so awesome, you\n can’t resist, Discover new worlds that cannot be missed.",
    //   link: "/portal/Offers",
    //   color: "linear-gradient(45deg, #FAFAD2 20%, #F8F8FF 80%)"
    // }
  ]
  const exploreItems2ndRow = [
    {
      title: "Write Small",
      description: "In languages diverse, from near and far, Let brevity transcend\n any cultural bar, Unite the world with words so grand, Write\n small, let brevity expand.",
      link: "/portal/rachnaye/post/create?type=text",
      color: "linear-gradient(45deg, #F0FFFF 20%, #FFF5EE 80%)"
    },
    {
      title: "Write Single Rachnaye",
      description: "So on this writing platform, wide and vast, Let writers from\n all walks of contrast, Embrace the power of words so tight,\n you write, let brevity take flight.",
      link: "/portal/rachnaye/post/create?type=text",
      color: "linear-gradient(45deg, #FFF0F5 20%, #F5F5F5 80%)"
    },
    {
      title: "Write a Series",
      description: "With strokes broad and bold, your canvas unfold, Across genres\n diverse, a story untold. In prose or verse, let your voice\n resound, A symphony of words, where dreams are found.",
      link: "/portal/rachnaye/series/create?type=text",
      color: "linear-gradient(45deg, #FFFAF0 20%, #F0FFF0 80%)"
    }
  ]

  const exploreItems3rdRow = [
    {
      title: "Narrate Small",
      description: "So, step forward with confidence, let your voice resound, With small items narrated, let your talent astound. On this digital platform, where hearts can be won, May your poetic style finds its place in the sun.",
      link: "/portal/rachnaye/post/create?type=video",
      color: "linear-gradient(45deg, #F5F5F5 20%, #FFFAF0 80%)"
    },
    {
      title: "Narrate Rachnaye",
      description: "In the kaleidoscope of writing, diverse and grand, Find your\nunique voice, let your dialect expand. Bring forth the nuances\nof your heritage and grace, Leave an indelible mark, a smile\non every face.",
      link: "/portal/rachnaye/post/create?type=video",
      color: "linear-gradient(45deg, #F0F0F0 20%, #F0FFF0 80%)"
    },
    {
      title: "Narrate a Series",
      description: "Let languages dance upon your silver tongue, Transcending\n barriers, uniting old and young. For every word spoken, every\n phrase you impart, Shall resonate deeply, stirring souls and\n hearts.",
      link: "/portal/rachnaye/series/create?type=video",
      color: "linear-gradient(45deg, #FFF8DC 20%, #FFFFF0 80%)"
    }
  ]

  // const exploreItems4thRow = [
  //   {
  //     title: "Read Only...",
  //     description: "Only Read",
  //     link: "/portal/categoryLanguageSelection",
  //     color: "linear-gradient(45deg, #F5F5F5 20%, #FFFAF0 80%)"
  //   }
  // ]

  const exploreSachets = [exploreItems1stRow, exploreItems2ndRow, exploreItems3rdRow]

  return (
    <Grid
      container
      spacing={1}
      className={`${classes.gridContainer} ${classes.centerContainer}`}
    >
      <Grid item xs={12}>
        <Typography
          variant="h1"
          align="center"
          style={{
            marginBottom: "2rem",
            marginTop: "-2rem",
            marginLeft: "-21rem",
          }}
        >
          On Rachnaye, I would like to...
        </Typography>

        {exploreSachets.map(row => (
          <Grid
            container
            spacing={1}
            className={`${classes.gridContainer} ${classes.centerContainer}`}
          >

            {row.map(card => (<>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card
                  className={classes.card}
                  onClick={() => navigateTo(card.link)}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    background: card.color,
                    color: "#333333",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h5"
                      className={`${classes.cardTitle} cardTitle`}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={`${classes.cardDescription} cardDescription`}
                    >
                      {card.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </>))}
          </Grid>
        ))}
      </Grid>
      <GuestUsersNameChange open={askForGuestUsersInfoOpen} handleClose={() => setAskForGuestUsersInfoOpen(false)} titleMessage="Add your Info" subMessage="You are a guest user , you need to add your actual name before you can write or narrate" />
    </Grid>
  );
};

export default PortalUserHome;
