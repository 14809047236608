import { createSlice } from '@reduxjs/toolkit';
import { IIamUserState } from "./types";
const initialUserState: IIamUserState = {
  id: "",
  spaceId: "",
  firstName: "",
  lastName: "",
  emailId: "",
  roleId: "",
  status: "",
  primaryLanguage: "",
  emailVerified: false,
  phoneVerified: false
};

const userSlice = createSlice({
  name: 'iamUser',
  initialState: initialUserState,
  reducers: {
    logout: () => {
      return initialUserState;
    },
    login (state, action) {
      return {
        ...state,
        ...action.payload
      };
    },
    signup (state, action) {
      return {
        ...state,
        ...action.payload
      };
    },
    updateIamUser (state, action) {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const { logout, login, signup, updateIamUser } = userSlice.actions;
export default userSlice.reducer;