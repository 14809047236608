import {
  CircularProgress, Grid, makeStyles, Typography
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({

  loaderDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "5rem 0rem"
  },
  message: {
    marginTop: "1rem"
  }

}))

interface IProp {
  message?: string
  className?: string
  style?: object
}
const CircularLoader: React.FC<IProp> = (props) => {
  const classes = useStyles();
  return (
    <Grid container xs={12} justify='center' alignContent="center" >
      <div className={`${classes.loaderDiv} ${props.className}`} style={{ ...props.style }}>
        <CircularProgress size={"2rem"} />
        {props.message && props.message.trim().length > 1 ?
          <Typography variant="body1" className={classes.message} >{props.message}</Typography>
          : <></>
        }
      </div>
    </Grid>
  )
};

export default CircularLoader;