import { Avatar, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, Paper, Switch, Typography } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from "moment";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IBundleOffer } from "../../../reducers/offers/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../utils/common.interface";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { DataTable } from "../../common/Datatable/Datatable";
import { Pagination } from "../../common/Datatable/types";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { IContextMenuPosition } from "../../common/CustomContextMenu/types";
import CustomContextMenu from "../../common/CustomContextMenu/CustomContextMenu";
import FlatPickerBar from "../../common/FlatPickerBar";
import { ErrorHandler } from "../../../utils/utils";

const BundleStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive"
}

const Bundles: React.FC = () => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.user);

  const [fetchingBundles, setFetchingBundles] = useState<boolean>(true);
  const [bundleOffers, setBundleOffers] = useState<IBundleOffer[]>([]);
  const [totalBundleOffers, setTotalBundleOffers] = useState<number>(0);
  const [contextMenu, setContextMenu] = useState<IContextMenuPosition | null>(null);
  const [menuAttributes, setMenuAttributes] = useState({
    link: "",
  })

  const [extendDateDialogBoxOpen, setExtendDateDialogBoxOpen] = useState<boolean>(false);
  const [expiredBundlesEndDate, setExpiredBundlesEndDate] = useState<string>("");
  const [expiredBundleToUpdate, setExpiredBundleToUpdate] = useState<IBundleOffer>();
  const [updating, setUpdating] = useState<boolean>(false)  // to prevent multiple clicks


  useEffect(() => {
    if (userState && userState.id && userState.spaceId) {
      setFetchingBundles(true);
      fetchBundleOffers({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
      setFetchingBundles(false);
    }
  }, [userState])

  const fetchBundleOffers = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args

    callApi(PATHNAMES.GET_ORG_BUNDLE_OFFERS(pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then((offersResponse) => {
      if (offersResponse && offersResponse.success && offersResponse.data) {
        setBundleOffers(offersResponse.data.bundles);
        setTotalBundleOffers(offersResponse.data.totalBundles);
      }
    })
  }

  const validateAndChangeBundleOfferStatus = (bundleId: string) => {
    // check for bundles date expired or not befor activating.
    // if expired then ask to update the date also.

    const bundle = bundleOffers.find(bundle => bundle.id === bundleId);
    if (bundle && bundle.status === BundleStatus.INACTIVE) {
      // bundle is inactive and expired, but user is trying to activaye it, ask to update its end date.
      if (moment().toISOString() > moment(bundle.endDate).toISOString()) {
        setExpiredBundlesEndDate(bundle.endDate)
        setExpiredBundleToUpdate(bundle)
        setExtendDateDialogBoxOpen(true)
        return
      }
    }
    // bundle is not expired state to , change the status
    updateBundleOfferStatus(bundleId)
    return
  }


  const updateBundleOfferStatus = (bundleId: string) => {
    //  just update the status [ACTIVE/INACTIVE]
    callApi(PATHNAMES.CHANGE_BUNDLE_OFFER_STATUS(bundleId), HTTP_METHODS.PATCH).then((res) => {
      if (res && res.success) {
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: res.message
        })
        if (userState.spaceId) {
          fetchBundleOffers({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
        }
      } else {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: res.message
        })
      }
    })
  }


  const updateBundleEndDateAndStatus = async () => {
    try {
      setUpdating(true)
      // validation
      if (moment(expiredBundleToUpdate?.endDate).toISOString() < moment().toISOString()) {
        throw new ErrorHandler("Please select valid date to proceed")
      }
      // update date 
      const data = expiredBundleToUpdate
      delete data?.image;
      const res = await callApi(PATHNAMES.UPDATE_BUNDLE_OFFER(expiredBundleToUpdate?.id || ""), HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      })
      if (res && res.success) {
        // update satus
        updateBundleOfferStatus(expiredBundleToUpdate?.id || "")
        setUpdating(false)
        setExtendDateDialogBoxOpen(false)
      } else {
        throw new ErrorHandler(res.message)
      }
    } catch (error: any) {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
      setUpdating(false)
    }
  }

  const convertStatusInBool = (value: string) => value === BundleStatus.ACTIVE

  const handleContextMenuClick = (
    event: React.MouseEvent, bundleId: string
  ) => {
    event.preventDefault();
    const value: any = contextMenu === null ? {
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    } : null;
    setContextMenu(value);
    setMenuAttributes({
      ...menuAttributes,
      link: `/update-bundle?bundle=${bundleId}`
    })
  };

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      label: "Cover",
      name: "image",
      options: {
        viewColumns: false,
        setCellProps: () => ({ style: { width: '8rem' } }),
        customBodyRender: (data: any) => {
          return (
            <Avatar
              alt="Publisher"
              variant="rounded"
              style={{ borderRadius: "50%" }}
              src={data}
            ></Avatar>
          );
        },
      },
    },
    {
      label: "NAME",
      name: "name"
    },
    {
      label: "START DATE",
      name: "startDate",
      options: {
        customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY")
      }
    },
    {
      label: "END DATE",
      name: "endDate",
      options: {
        customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY")
      }
    },
    {
      label: "ACTIVE",
      name: "status",
      options: {
        customBodyRender: (data: string) => <FiberManualRecordIcon
          style={{ paddingLeft: "1rem" }}
          fontSize="small"
          htmlColor={data === BundleStatus.ACTIVE ? "#5CD89F" : "#FF5C3E"}
        />
      }
    },
    {
      label: "ACTIONS",
      name: "status",
      options: {
        setCellProps: () => ({ style: { padding: '0rem' } }),
        customBodyRender: (value: string, tableMeta: any) => {
          return <>
            <FormControlLabel
              control={
                <Switch
                  checked={convertStatusInBool(value)}
                  onClick={(e: any) => {
                    e.stopPropagation()
                    validateAndChangeBundleOfferStatus(tableMeta.rowData[0])
                  }}
                  value="checkedSwitch"
                />
              }
              label=""
            />
          </>
        }
      }
    }
  ]

  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    onRowClick: (rowData: any) => {
      const [id] = rowData
      let url = `/update-bundle?bundle=${id}`;
      navigate(url)
    },
    setRowProps: (row: any, rowIndex: number) => {
      return {
        onContextMenu: (event: React.MouseEvent) => {
          const [bundleId] = row
          return handleContextMenuClick(event, bundleId)
        }
      }
    },
    searchPlaceholder: "Search by name, status or time [dd/mm/yyyy]",
    count: totalBundleOffers,
  };

  if (fetchingBundles) {
    return <CircularProgress />
  }

  return (
    <Grid container xs={12}>
      <Paper elevation={0} style={{ width: "98%", padding: "1rem" }}>
        <Grid container xs={12}>
          <Grid container item xs={4}>
            <Typography variant="h2" style={{ fontWeight: 600, padding: "1rem" }}>
              Bundle Offers
              <Typography variant="h2" color="primary" style={{ display: "inline", marginLeft: "1rem" }}>
                {totalBundleOffers}
              </Typography>
            </Typography>
          </Grid>
          <Grid container item xs={8} justify="flex-end">
            <Fab
              variant="extended"
              size="medium"
              onClick={() => navigate("/create-bundle")}
            >
              Create Bundle
            </Fab>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <DataTable
            title={""}
            rows={bundleOffers}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchBundleOffers}
          />
          <CustomContextMenu
            onClose={() => setContextMenu(null)}
            position={contextMenu}
            attributes={menuAttributes}
          />
          <Dialog
            maxWidth={"sm"}
            fullWidth={true}
            open={extendDateDialogBoxOpen}
            onClose={() => setExtendDateDialogBoxOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
              <Grid container xs={12}>
                <Typography variant="h3">Change End Date </Typography>
              </Grid>
            </DialogTitle>
            <DialogContent dividers={true} style={{ minHeight: '30rem' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h3" style={{ fontWeight: 600 }}>You are trying to <div style={{ color: "green", display: "inline" }}>Activate</div> an Expired Bundle, you must extend an End Date.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" style={{ padding: "0rem 0rem 0.4rem 0rem", fontSize: "1rem" }}><div style={{ fontWeight: 600, display: "inline" }}>Bundle</div> : {expiredBundleToUpdate?.name}</Typography>
                  <Typography variant="h5" style={{ padding: "0.4rem 0rem", fontSize: "1rem" }}><div style={{ fontWeight: 600, display: "inline" }}>StartDate</div> : {moment(expiredBundleToUpdate?.startDate).format("DD-MM-YYYY  - hh:mm:ss a")}</Typography>
                  <Typography variant="h5" style={{ padding: "0.4rem 0rem", fontSize: "1rem" }}><div style={{ fontWeight: 600, display: "inline" }}>EndDate</div> : {moment(expiredBundlesEndDate).format("DD-MM-YYYY  - hh:mm:ss a")}</Typography>
                </Grid>
                <Grid item xs={7}>
                  <FlatPickerBar
                    handleDateChange={(value: Date) => {
                      if (expiredBundleToUpdate) {
                        setExpiredBundleToUpdate({
                          ...expiredBundleToUpdate,
                          endDate: moment(value).endOf("day").toISOString()
                        })
                      }
                    }}
                    label="New End Date"
                    identifier="endDate"
                    name="endDate"
                    minDate={moment().toDate()}

                  />
                </Grid>
              </Grid>

            </DialogContent>
            <DialogActions >
              <Grid container item xs={12} justify="flex-end" style={{ marginBottom: '0rem' }}>
                <Fab
                  size="medium"
                  variant="extended"
                  className="blackBackButton"
                  onClick={() => setExtendDateDialogBoxOpen(false)}
                >
                  Cancel
                </Fab>
                <Box pl={2}></Box>
                <Fab
                  disabled={updating}
                  size="medium"
                  variant="extended"
                  onClick={updateBundleEndDateAndStatus}
                >
                  Proceed
                </Fab>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Bundles;