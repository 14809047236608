import { Accordion, AccordionDetails, AccordionSummary, Fab, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import callApi from '../../../utils/apiUtil';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import FlatPickerBar from '../../common/FlatPickerBar';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';
import SelectedBookOrders from './SelectedBookOrders';

const OPTIONS_LIMIT = 8;
const bookSearchKeyFilter = createFilterOptions<any>({
  matchFrom: 'any',
  stringify: (book) => book.title + book.type + book?.authors?.join("") + book.publisherName
});

const limitedBooksListToShow = (options: any, state: any) => {
  return bookSearchKeyFilter(options, state).slice(0, OPTIONS_LIMIT);
};

export enum BookChangeableFieldType {
  BOUGHT_PRICE = 'boughtPrice',
  SOLD_QUANTITY = 'soldQuantity',
  AGGT_SOLD_PRICE = 'aggSoldPrice',
}

export interface IBook {
  id: string;
  publisherId: string;
  publisherName: string;
  title: string;
  type: string
  booksAvailable: number;
  authors?: string[];
  boughtPrice?: number
  soldPrice?: number
  quantity?: number
  sellingMRP: number;
}


const useStyles = makeStyles((theme) => ({
  autocompleteFont: {
    fontSize: '1rem',
  },
  textArea: {
    fontSize: '1rem',
    minWidth: "20rem"
  },
  tagsChip: {
    fontSize: '1.2rem',
    fontWeight: 500,
    borderRadius: 20,
    background: "#e1e1e1",
    border: "1px solid #cacaca",
    "&.MuiAutocomplete-tag": {
      margin: "10px 0px 5px 10px"
    }
  },
  autoCompleteOptionHeader: {
    position: 'sticky',
    top: '-8px',
    fontSize: "1rem",
    padding: "0.7rem",
    color: theme.palette.primary.dark,
    backgroundColor: "#FFD36E"
  },
  autoCompleteOptionItem: {
    padding: '0.5rem'
  },
  textAreaStyle: { minWidth: '98%', height: "100%", resize: 'none', fontSize: "1.1rem" },
  selectMenu: {
    marginTop: "0rem",
    width: "100%",
    height: "3rem",
    fontSize: '12px'
  },
  button: {
    color: "black!important",
    backgroundColor: "#FFD36E!important",
    margin: "0rem 1rem"
  },
  clearText: {
    padding: "0rem 1rem",
    display: "inline",
    "&:hover": { textDecoration: "underline" },
    cursor: "pointer"
  }
}))


const CreateOfflineOrder: React.FC = () => {
  const classes = useStyles()
  const snackbar = useSnackBar();

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false)
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState<boolean>(false)

  const [booksAC, setBooksAC] = useState<IBook[]>([]); // options for autocomplete from api call
  const [selectedBooksForOrder, setSelectedBooksForOrder] = useState<IBook[]>([]); // autocomplete selections
  const [failedToUploadOrders, setFailedToUploadOrders] = useState<any>(); //  failed while uploading
  const [bookSoldDate, setBookSoldDate] = useState<string>(""); // passed while uploading

  // GET ALL BOOKS
  useEffect(() => {
    getBooks()
  }, [])

  const getBooks = () => {
    callApi(PATHNAMES.GET_ALL_PUBLISHER_PAPER_BOOKS("offlineSale"), HTTP_METHODS.GET).then((booksResponse) => {
      if (booksResponse && booksResponse.success && booksResponse.data) {
        setBooksAC(booksResponse.data);
      }
    })
  }


  const handleOrderValueChanges = (fieldType: BookChangeableFieldType, bookIdx: number, updateValue: number | undefined) => {
    let field: keyof IBook = 'soldPrice'
    switch (fieldType) {
      case BookChangeableFieldType.AGGT_SOLD_PRICE:
        field = 'soldPrice'
        break;
      case BookChangeableFieldType.BOUGHT_PRICE:
        field = 'boughtPrice'
        break;
      case BookChangeableFieldType.SOLD_QUANTITY:
        field = 'quantity'
        break;
      default:
        return
    }
    //  find book with idx and update the new value on particular field of book
    setSelectedBooksForOrder(prvArr => prvArr.map((book, idx) => idx === bookIdx ? { ...book, [field]: updateValue } : book))
  }

  const handleSameBoughtPriceToAllBookById = (bookIdx: number) => {
    const { boughtPrice, id } = selectedBooksForOrder[bookIdx]
    setSelectedBooksForOrder(prvArr => prvArr.map(_book => _book.id === id && _book.boughtPrice !== boughtPrice ? { ..._book, boughtPrice } : _book))
  }

  const handleDeleteRowBook = (bookIdx: number) => {
    setSelectedBooksForOrder(prvArr => prvArr.filter((_book, idx) => idx !== bookIdx))
  }

  const validateBeforeSubmitting = () => {
    const missingValuesBook = selectedBooksForOrder.find(book => !book.boughtPrice || !book.soldPrice || !book.quantity || !book.booksAvailable)
    if (missingValuesBook) {
      snackbar({
        message: `${missingValuesBook.title}'s fields are missing please fill before submitting`,
        variant: SnackBarVariant.ERROR
      })
      return false
    }
    return true
  }

  const handleClearAllbtn = () => selectedBooksForOrder.length > 0 ? setOpenDeleteConfirmation(true) : null
  const handleCancelSubmitingBtn = () => setOpenSubmitConfirmation(false)
  const handleCancelClearAllBtn = () => setOpenDeleteConfirmation(false)
  const handleSubmitBtn = () => {
    if (selectedBooksForOrder.length < 1) {
      return
    }
    const passed: boolean = validateBeforeSubmitting()
    return passed ? setOpenSubmitConfirmation(true) : null
  }
  const handleClearAllFn = () => {
    setSelectedBooksForOrder([])
    setOpenDeleteConfirmation(false)
  }
  const handleSubmitRecordsFn = () => {
    setOpenSubmitConfirmation(false)
    createOrderApi()
    setSelectedBooksForOrder([])
    setBookSoldDate('')
    return
  }

  const createOrderApi = () => {
    const orders = selectedBooksForOrder.map(book => ({
      bookId: book.id,
      orderType: book.type,
      quantity: book.quantity,
      unitPrice: book.sellingMRP,
      boughtPrice: book.boughtPrice,
      soldPrice: book.soldPrice,
      soldDate: bookSoldDate
    }))
    callApi(PATHNAMES.CREATE_OFFLINE_ORDERS(), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: { orders }
      })
    }).then((res) => {
      if (res && res.success) {
        const { failedOrders, } = res.data
        setFailedToUploadOrders(failedOrders)
        const { message, variant } = determinerAPICallResMessage(res.data)
        snackbar({
          message,
          variant
        })
      }
    })
  }

  const determinerAPICallResMessage = ({ failedOrders, fullfilledOrders }: any) => {
    let message = "No orders were uploaded"
    let variant = SnackBarVariant.ERROR

    if (!fullfilledOrders.length) {
      message = "something went wrong, Orders rejected"
    } else if (!failedOrders.length) {
      message = "Orders uploaded successfully";
      variant = SnackBarVariant.SUCCESS;
    } else {
      message = `${fullfilledOrders.length} Orders uploaded successfully, but ${failedOrders.length} failed`;
      variant = SnackBarVariant.WARNING;
    }
    return { message, variant }
  }

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} style={{ width: '99%' }} elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h2' style={{ fontWeight: "bolder" }}>Add Order</Typography>
      </AccordionSummary>
      <AccordionDetails >
        <Grid container xs={12} style={{ height: "fit-content" }}>
          {/* BOOKS */}
          <Grid item xs={7}>
            <Autocomplete
              options={booksAC.sort((a, b) => -b.publisherName.localeCompare(a.publisherName))}
              groupBy={(option: IBook) => option.publisherName}
              filterSelectedOptions
              disableCloseOnSelect
              filterOptions={limitedBooksListToShow}
              getOptionLabel={(item: IBook) => item.title}
              renderOption={(option: IBook) => (
                <Typography style={{ fontSize: "1.2rem" }}>{`${option.title}    ->  ${option.type}`}</Typography>
              )}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder="Search and select books with publisher or author name"
                  label="Books"
                />)
              }
              renderGroup={(params: any) => (
                <li>
                  <div className={classes.autoCompleteOptionHeader}>{params.group}</div>
                  <div className={classes.autoCompleteOptionItem}>{params.children}</div>
                </li>
              )}
              onChange={(event: any, bookValue: IBook | null) => {
                if (bookValue) {
                  setSelectedBooksForOrder(v => [...v, bookValue]) //user selected options as chip
                }
              }}
            />
          </Grid>
          {/* BUTTONS */}
          <Grid item container xs={5} justify='flex-end' alignItems='center'>
            <Typography variant='h2' style={{ display: "inline", backgroundColor: "#0066FF", color: "white", fontWeight: 500, padding: "0.4rem 2rem", borderRadius: 20 }}> Added {selectedBooksForOrder.length}</Typography>
            <Fab
              variant="extended"
              size="medium"
              type="reset"
              className={classes.button}
              onClick={handleClearAllbtn}
            >
              Clear All Rows
            </Fab>
            <Fab
              variant="extended"
              size="medium"
              onClick={handleSubmitBtn}
            >
              Submit
            </Fab>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: "1.5rem" }} alignItems='center'>
            <Grid item xs={4}>
              <FlatPickerBar
                handleDateChange={(value: Date) => {
                  setBookSoldDate(moment(value).toISOString())
                }}
                label="Books Sold Date"
                identifier="soldDate"
                name="soldDate"
                required
                value={bookSoldDate}
              />
            </Grid>
            <Grid item xs={3}>
              <Fab
                variant="extended"
                size="medium"
                type="reset"
                className={classes.button}
                onClick={() => setBookSoldDate('')}
              >
                reset
              </Fab>
            </Grid>
          </Grid>
          {/*BOOK ORDERS LISTING  */}
          <Grid item container xs={12} style={{ paddingTop: "1rem" }}>
            {selectedBooksForOrder.length > 0 &&
              <SelectedBookOrders
                books={selectedBooksForOrder}
                handleOrderValueChanges={handleOrderValueChanges}
                handleSameBoughtPrice={handleSameBoughtPriceToAllBookById}
                handleDeleteRow={handleDeleteRowBook}
              />
            }
          </Grid>


          {/* failed orders information */}
          <Grid item container xs={12} style={{ paddingTop: "2rem 1rem" }}>
            {failedToUploadOrders?.length > 0 &&
              <Grid item container xs={12}>
                < Grid item xs={12} style={{ padding: "1rem 0rem" }}>
                  <Typography variant='h3' style={{ display: "inline" }}>Failed To Upload</Typography>
                  <Typography variant='h4'
                    color='primary'
                    className={classes.clearText}
                    onClick={() => setFailedToUploadOrders([])}

                  >Clear</Typography>
                </Grid>
                < Grid item xs={6} style={{ backgroundColor: "#FCF5E8", padding: "0.5rem 0rem" }}>
                  <Typography variant="h4" >Book title</Typography>
                </Grid>
                < Grid item xs={6} style={{ backgroundColor: "#FCF5E8", padding: "0.5rem 0rem" }}>
                  <Typography variant="h4" >Reason</Typography>
                </Grid>
                {failedToUploadOrders.map((order: any) => (
                  <Grid container item xs={12} style={{ padding: "0.3rem 0rem" }}>
                    < Grid item xs={6}>
                      <Typography variant="h4" >{order.order.bookId}</Typography>
                    </Grid>
                    < Grid item xs={6} >
                      <Typography variant="h4" >{order.errorMsg}</Typography>
                    </Grid>
                  </Grid>
                ))}

              </Grid>

            }
          </Grid>


        </Grid>
        <ConfirmationDialog
          isOpen={openDeleteConfirmation}
          onCancel={handleCancelClearAllBtn}
          onConfirm={handleClearAllFn}
          title={"Confirm Delete All"}
          description={"Are you sure, you want to delete all selected books orders, you will lost all entries"}
        />
        <ConfirmationDialog
          isOpen={openSubmitConfirmation}
          onCancel={handleCancelSubmitingBtn}
          onConfirm={handleSubmitRecordsFn}
          title={"Confirm Submiting"}
          description={"It will submit all order, please cross check before proceeding."}
        />
      </AccordionDetails>
    </Accordion >
  )
}


export default CreateOfflineOrder;