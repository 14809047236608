import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { IBook } from "../../../reducers/books/types";
import BookImage from "../../common/BookImage";

interface IProps {
  books: IBook[];
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    gap: "6rem 2rem",
    overflowX: 'auto',
    overflowY:'hidden',
    height: "100%",
    padding: "1.5rem 0rem 4rem 0rem",
    [theme.breakpoints.down("xs")]: {
      "&::-webkit-scrollbar": {
        display: 'none'
      }
    }
  },
}))


const BooksGrid: React.FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid container xs={12} wrap="nowrap" className={classes.mainContainer}>
      {props.books.map((book) => <BookImage book={book} showTitle />)}
    </Grid>
  )
}

export default BooksGrid;