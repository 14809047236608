import { Chip, CircularProgress, Grid, IconButton, Tooltip, Typography, makeStyles } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import React, { useEffect, useState } from 'react';
import callApi from '../../../utils/apiUtil';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';


const useStyles = makeStyles(() => ({
  tagsChip: {
    fontSize: '1.2rem',
    fontWeight: 600,
    borderRadius: 20,
    border: "1px solid #cacaca",
    cursor: 'pointer',
    position: 'relative',
  },
  chipStyle: {
    margin: "0.6rem",
    "&:hover": {
      backgroundColor: "#98FE98"
    }
  },
  icon: {
    position: 'absolute',
    top: '50%',
    right: '5px',
    transform: 'translateY(-50%)',
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
  },
  tooltip: {
    fontSize: '13px',
    maxWidth: 400
  },
}))

const BackUpOTPs: React.FC = () => {
  const snackbar = useSnackBar();
  const classes = useStyles()

  const [otps, setOtps] = useState([])
  const [updating, setUpdating] = useState<boolean>(false)


  useEffect(() => {
    fetchBackupOTPs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGenerateNewOTPs = async () => {
    if (updating) {
      return
    }
    setUpdating(true)
    const res = await callApi(PATHNAMES.GENERATE_NEW_BACKUP_OTPS(), HTTP_METHODS.POST, {})

    if (res && res.success && res.data) {
      setOtps(res.data)
      snackbar({
        message: res.message || "otps created successfully",
        variant: SnackBarVariant.SUCCESS
      })
    } else {
      snackbar({
        message: res.message || "Something went wrong",
        variant: SnackBarVariant.ERROR
      })
    }
    setUpdating(false)
  }

  const fetchBackupOTPs = async () => {
    if (updating) {
      return
    }
    setUpdating(true)
    const res = await callApi(PATHNAMES.GET_NEW_BACKUP_OTPS(), HTTP_METHODS.GET, {})
    if (res && res.success && res.data) {
      setOtps(res.data)
    } else {
      snackbar({
        message: res.message || "Something went wrong",
        variant: SnackBarVariant.ERROR
      })
    }
    setUpdating(false)
  }


  const handleChipClick = (text: number) => {
    navigator.clipboard.writeText(String(text))
      .catch((err) => {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: "Failed to copy otp"
        })
      });

    snackbar({
      variant: SnackBarVariant.SUCCESS,
      message: `OTP (${text}) copied successfully`
    })

  };

  const handleCopyAllOTPs = () => {
    const allOtps = otps.join(", ")
    navigator.clipboard.writeText(allOtps)
      .catch((err) => {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: "Failed to copy otp"
        })
      });

    snackbar({
      variant: SnackBarVariant.SUCCESS,
      message: `All OTPs copied successfully`
    })

  }

  return (
    <Grid container xs={12} style={{ padding: "1rem 0rem 2rem 0rem" }} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" style={{ fontWeight: "bolder" }}>OTP's for International Logins</Typography>
      </Grid>
      <Grid container xs={12} spacing={2} alignItems='center' style={{ padding: "1rem 1rem" }}>
        <Grid item xs={'auto'}>
          <div style={{
            border: "1px solid grey",
            borderRadius: "5px",
            padding: "0.3rem",
            display: "inline-block"
          }}>
            {otps.map(item => {
              return (
                <Tooltip title="Click to Copy" key={item} classes={{ tooltip: classes.tooltip }} >
                  <Chip
                    classes={{ root: classes.tagsChip }}
                    className={classes.chipStyle}
                    label={item}
                    onClick={() => handleChipClick(item)}
                    icon={<FileCopyOutlinedIcon className="icon" />}
                  />
                </Tooltip>
              )
            })}
          </div>
        </Grid>

        <Grid item xs={'auto'}>
          {updating ?
            <CircularProgress />
            :
            <div style={{ display: "flex", alignItems: "center", padding: "0rem 1rem" }}>
              <IconButton title='Copy All OTPs' onClick={handleCopyAllOTPs}>
                <FileCopyOutlinedIcon />
              </IconButton>

              <Typography variant="h4" style={{ paddingLeft: "1rem", fontWeight: 600, cursor: "pointer" }} color="primary"
                onClick={handleGenerateNewOTPs}>
                Generate New OTPs
              </Typography>
            </div>

          }
        </Grid>
      </Grid>


    </Grid>
  )
}

export default BackUpOTPs;