import imageCompression from "browser-image-compression";
import { nanoid } from "nanoid";
import { IAddress } from "../reducers/orders/types";
import { isValidImage } from "./bookUtils";
import { TRANSFER_MODES, USER_TYPES } from "./constants";
export const isLogin = () => {
  if (localStorage.getItem("token")) {
    return true;
  }
  return false;
};

export const getUserType = () => {
  const type = localStorage.getItem("userType");
  if (type && type === USER_TYPES.MOBILE) {
    return USER_TYPES.MOBILE
  }
  return USER_TYPES.WEBAPP
}

export const getInitials = (name: string) => {
  let arr = name.split(" ");
  arr = arr.slice(0, 2);
  let initials = "";
  arr.forEach((word: string) => {
    initials += word.charAt(0).toUpperCase();
  });
  return initials;
};

export const generateAddressString = (address: IAddress) => {
  return `${address.street}, ${address.city}, ${address.state}, ${address.country} - ${address.pincode}`;
};

export const capitalizeFirstLetter = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;

export class ErrorHandler extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

export const handleRowHover = (rowIndex: number) => {
  let control = document.getElementById('Edit-' + rowIndex);
  if (control) {
    control.style.display = 'block';
  }
}

export const maskString = (str: string, showValues: number, maskBy: string = "x") => str.slice(0, -showValues).replace(/[^ ]/g, maskBy) + str.slice(-showValues)

export const maskName = (str: string, maskBy: string = "x") => {
  str = str.trim()
  const splitName = str.split(" ")
  const maskedNameArray = splitName.map((v: string) => {
    if (v.length === 1) {
      return v
    }
    if (v.length > 4) {
      const k = v.slice(2, -2).replace(/[^ ]/g, maskBy)
      return v.slice(0, 2) + k + v.slice(-2)
    } else {
      const k = v.slice(1, -1).replace(/[^ ]/g, maskBy)
      return v[0] + k + v.slice(-1)
    }
  })
  return maskedNameArray.join(" ")
}

export const maskEmail = (str: string, maskBy: string = "x") => {
  const splitEmail = str.split("@")
  const email = splitEmail[0]
  const domain = splitEmail[1]
  const maskEmailFrom2ndPosition = email.slice(2).replace(/./g, maskBy)
  return email.slice(0, 2) + maskEmailFrom2ndPosition + "@" + domain
}


export const handleRowHoverLeave = (rowIndex: number) => {
  let control = document.getElementById('Edit-' + rowIndex);
  if (control) {
    control.style.display = 'none';
  }
}
export function roundToTwo(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export const getWithdrawlCharges = (mode: string, withdrawableAmount: number) => {
  let amount = withdrawableAmount || 0;
  if (amount < 0) {
    amount = 0
  }
  let charges = 10;
  switch (mode) {
    case TRANSFER_MODES.UPI:
      if (amount <= 1000) {
        charges = 3;
      } else if (amount > 1000 && amount <= 10000) {
        charges = 6;
      } else {
        charges = 10;
      }
      break;
    case TRANSFER_MODES.IMPS:
      if (amount <= 1000) {
        charges = 2.5;
      } else if (amount > 1000 && amount <= 10000) {
        charges = 5;
      } else {
        charges = 10;
      }
      break;
    case TRANSFER_MODES.NEFT:
      if (amount <= 1000) {
        charges = 1.75;
      } else if (amount > 1000 && amount <= 10000) {
        charges = 1.75;
      } else {
        charges = 1.75;
      }
      break;
    default:
      break;
  }
  return charges;
}

export const replaceHyphen = (texts: string[]) => {
  let replacedTexts = texts.map((text) => {
    let replacedText = text.replace(
      /-(.)/g,
      (match, nextChar) => ` ${nextChar.toUpperCase()}`
    );
    replacedText =
      replacedText.charAt(0).toUpperCase() + replacedText.slice(1);
    replacedText = replacedText.replace(/ And /g, " and ");
    return replacedText;
  });
  return replacedTexts;
};

export const imageCompressor = async (originalImage: File) => {
  try {
    if (!originalImage) {
      throw new ErrorHandler("Please provide image !");
    }
    const isImage: Boolean = isValidImage(originalImage);
    if (!isImage) {
      throw new ErrorHandler("Only Png and Jpeg files can be uploaded");
    }
    if (isImage) {
      const options = {
        maxSizeMB: 1,
        alwaysKeepResolution: true
      };
      const compressedImage: File = await imageCompression(originalImage, options);
      return compressedImage;
    }
  } catch (error) {
    throw new ErrorHandler("Image Not Uploaded");
  }
};

export const setOrGetDeviceId = () => {
  let deviceId = localStorage.getItem("deviceId")
  if (!(deviceId && validDeviceId(deviceId))) {
    deviceId = generateDeviceId()
    localStorage.setItem("deviceId", deviceId)
  }
  return deviceId
}

const validDeviceId = (deviceId: string) => {
  const deviceRegex = /^d-(chrome|firefox|edge|ie|safari)-.{5}$/;   // d-prefix for device , next word indicates browers type , remaining 5 letters are unique number
  return deviceId && deviceRegex.test(deviceId)
}

const generateDeviceId = () => {
  let deviceId
  const userAgent = window.navigator.userAgent;

  // Detect the browser name based on the user agent string
  let browserName = 'unknown';

  if (userAgent.indexOf('Chrome') !== -1) {
    browserName = 'chrome';
  } else if (userAgent.indexOf('Firefox') !== -1) {
    browserName = 'firefox';
  } else if (userAgent.indexOf('Safari') !== -1) {
    browserName = 'safari';
  } else if (userAgent.indexOf('Edge') !== -1) {
    browserName = 'edge';
  } else if (userAgent.indexOf('IE') !== -1 || userAgent.indexOf('MSIE') !== -1) {
    browserName = 'ie';
  }

  deviceId = "d-" + browserName + "-" + nanoid(5)

  return deviceId
}


export const getPlatformOSAndOSVersion = () => {
  const userAgent = window.navigator.userAgent
  let platformOS, osVersion

  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'MAC OS'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod', 'iOS']

  const windowsRegex = /Windows NT (\d+\.\d+)/,
    macRegex = /Mac OS X (\d+[\._]\d+)/,
    linuxRegex = /Linux (\d+(\.\d+)?)/,
    iosRegex = /OS (\d+_\d+_\d+)/,
    androidRegex = /Android (\d+\.\d+)/



  if (windowsPlatforms.some(value => userAgent.includes(value))) {
    platformOS = 'Windows';
    const match = windowsRegex.exec(userAgent)
    if (match) {
      osVersion = match[1];
    }
  } else if (macosPlatforms.some(value => userAgent.includes(value))) {
    platformOS = 'Mac OS';
    const match = macRegex.exec(userAgent)
    if (match) {
      osVersion = match[1].replace("_", ".");
    }
  } else if (iosPlatforms.some(value => userAgent.includes(value))) {
    platformOS = 'iOS';
    const match = iosRegex.exec(userAgent)
    if (match) {
      osVersion = match[1].replace(/_/g, '.');
    }
  } else if (userAgent.includes('Linux')) {
    platformOS = 'Linux';
    const match = linuxRegex.exec(userAgent)
    if (match) {
      osVersion = match[1];
    }
  } else if (userAgent.includes('Android')) {
    platformOS = 'Android';
    const match = androidRegex.exec(userAgent)
    if (match) {
      osVersion = match[1];
    }
  } else {
    platformOS = 'Unknown';
    osVersion = ""
  }
  return { platformOS, osVersion, userAgent }
}

export function logOut() {
  localStorage.removeItem("token");
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("fcmToken");
  localStorage.removeItem("userType");
}

export function setBackendUrl() {
  let backend
  switch (window.location.origin) {
    case "https://develop.rachnaye.com":
      backend = "https://devapi.rachnaye.com";
      break;
    case "https://demo.rachnaye.com":
      backend = "https://stagingapi.rachnaye.com";
      break;
    case "https://testing.rachnaye.com":
      backend = "https://testingapi.rachnaye.com";
      break;
    case "https://app.rachnaye.com":
      backend = "https://api.rachnaye.com";
      break;
    default:
      backend = "http://localhost:3002";
      break;
  }
  localStorage.setItem("backend", backend);
}

