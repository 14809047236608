import {
  Grid, makeStyles, Paper, TextField, Typography
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import React from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { roundToTwo } from "../../../utils/utils";
import { BookChangeableFieldType, IBook } from "./CreateOfflineOrder";

interface ISelectedBookProps {
  books: IBook[]
  handleOrderValueChanges: (fieldType: BookChangeableFieldType, bookIdx: number, value: number | undefined) => void;
  handleSameBoughtPrice: (bookIdx: number) => void;
  handleDeleteRow: (bookIdx: number) => void;
}


interface IBookCardProps {
  idx: number;
  book: IBook
  key: string;
  saveChange: (fieldType: BookChangeableFieldType, bookIdx: number, value: number | undefined) => void;
  handleSameBoughtPrice: (bookIdx: number) => void;
  handleDeleteRow: (bookIdx: number) => void;
}

const useStyles = makeStyles((theme) => ({
  inputBoxStyle: {
    width: '7rem',
    "& .MuiOutlinedInput-input": {
      padding: '0.5rem'
    },
    paddingBottom: "0rem"
  },
  mainContainer: { paddingTop: '1rem' },
  headRow: { borderBottom: '1px solid' },
  headertext: { margin: '1rem' },
  subHeader1text: { padding: "0.6rem", paddingLeft: '1rem' },
  subHeader2text: { padding: "0.6rem 0.6rem 0.6rem 0rem", },
  bookCardRow: {
    padding: '0.6rem 0rem',
    '&:hover $hideDeleteIcon': {
      display: 'block',
    },
  },
  bookCardText: { display: "inline-block", margin: '0rem 1rem', fontWeight: 400 },
  bookCardInventoryText: { display: "inline-block", margin: '0rem 1rem', fontWeight: 400, paddingLeft: '1rem' },
  totalValueContainer: { margin: '1rem 0rem', padding: '0.3rem', border: '1px solid red' },
  totalValueText: {},
  iconDiv: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  copyDiv: {
    padding: "0.2rem",
  },
  deleteIcon: {
    padding: "0.2rem 2rem"
  },
  hideDeleteIcon: {
    display: "none"
  }
}))



const SelectedBookOrders: React.FC<ISelectedBookProps> = (props) => {
  const classes = useStyles();
  const getTotalprice = () => {
    const priceArr = props.books.map(book => book.soldPrice ? book.soldPrice : 0)
    const totalPrice = priceArr.reduce((prev, next) => prev + next, 0)
    return roundToTwo(totalPrice)
  }

  const getTotalQuantity = () => {
    const quantityAtt = props.books.map(book => book.quantity || 0)
    const totalQuantity = quantityAtt.reduce((prev, next) => prev + next, 0)
    return totalQuantity
  }

  return (
    <Grid item xs={12} className={classes.mainContainer}>
      <Paper elevation={2} >
        {/* HEADER CONTAINER  */}
        <Grid container xs={12} className={classes.headRow}>
          {/* TEXT  */}
          <Grid container xs={6}>
            <Typography variant="h2" className={classes.headertext}> Selected Books</Typography>
          </Grid>
        </Grid>

        {/* INDEX HAEDING ROW */}
        < Grid container xs={12} style={{ backgroundColor: '#FCF5E8' }}  >
          < Grid item xs={3}>
            <Typography variant="h4" className={classes.subHeader1text}>Book title</Typography>
          </Grid>
          < Grid item xs={1} >
            <Typography variant="h4" className={classes.subHeader1text} >Type</Typography>
          </Grid>
          < Grid item xs={1} >
            <Typography variant="h4" className={classes.subHeader1text} >Inventory</Typography>
          </Grid>
          < Grid item xs={1} >
            <Typography variant="h4" className={classes.subHeader1text} >M.R.P</Typography>
          </Grid>
          < Grid item xs={2} >
            <Typography variant="h4" className={classes.subHeader2text}>{"Bought At(₹)"}</Typography>
          </Grid>
          < Grid item xs={2} >
            <Typography variant="h4" className={classes.subHeader2text}>{"Sold Quantity"}</Typography>
          </Grid>
          < Grid item xs={2} >
            <Typography variant="h4" className={classes.subHeader1text}>{"Agg Sold Prc.(₹)"}</Typography>
          </Grid>
        </Grid>

        {/* BOOKS MAPPING  */}
        {props.books.map((book, idx) => <BookOrderCard book={book} idx={idx} key={book.id + idx} saveChange={props.handleOrderValueChanges} handleSameBoughtPrice={props.handleSameBoughtPrice} handleDeleteRow={props.handleDeleteRow} />)}
        <Grid container xs={12} justify='flex-end' className={classes.totalValueContainer}>
          <Grid item xs={2} className={classes.totalValueText}>
            <Typography variant="h3" >{`Total ${getTotalQuantity()}`}</Typography>
          </Grid>
          <Grid item xs={2} className={classes.totalValueText}>
            <Typography variant="h3" >{`Total ₹ ${getTotalprice()}`}</Typography>
          </Grid>
        </Grid>

      </Paper>
    </Grid>
  )
}

const BookOrderCard: React.FC<IBookCardProps> = (props) => {
  const classes = useStyles();

  return (
    < Grid container xs={12} className={classes.bookCardRow} key={props.key} style={{ backgroundColor: props.idx % 2 ? "#EBEBEB" : undefined }} justify="center" alignItems="center">
      < Grid item xs={3}>
        <Typography variant="h4" className={classes.bookCardText}>{props.book.title.toUpperCase()}</Typography>
      </Grid>

      < Grid item xs={1} >
        <Typography variant="h4" className={classes.bookCardText}>{props.book.type.charAt(0).toUpperCase()}</Typography>
      </Grid>

      < Grid item xs={1} >
        <Typography variant="h4" className={classes.bookCardInventoryText}>{props.book.booksAvailable}</Typography>
      </Grid>

      < Grid item xs={1} >
        <Typography variant="h4" className={classes.bookCardInventoryText}>{props.book.sellingMRP}</Typography>
      </Grid>

      < Grid container item xs={2}>
        <NumericFormat
          customInput={TextField}
          className={classes.inputBoxStyle}
          name={BookChangeableFieldType.BOUGHT_PRICE}
          label=""
          placeholder="Add price"
          allowNegative={false}
          fullWidth
          decimalScale={2}
          value={props.book.boughtPrice}
          onValueChange={(eventValues: NumberFormatValues) => {
            props.saveChange(BookChangeableFieldType.BOUGHT_PRICE, props.idx, eventValues.floatValue)
          }}
        />
        <div className={`${classes.iconDiv} ${classes.copyDiv}`} onClick={() => props.handleSameBoughtPrice(props.idx)} >
          <SelectAllIcon titleAccess={`assign price to all ${props.book.title}`} />
        </div>
      </Grid>

      < Grid item xs={2} >
        <NumericFormat
          customInput={TextField}
          className={classes.inputBoxStyle}
          name={BookChangeableFieldType.SOLD_QUANTITY}
          label=""
          placeholder="Add qunatity"
          allowNegative={false}
          fullWidth
          decimalScale={0}
          value={props.book.quantity}
          onValueChange={(eventValues: NumberFormatValues) => {
            props.saveChange(BookChangeableFieldType.SOLD_QUANTITY, props.idx, eventValues.floatValue,)
          }}
        />
      </Grid>

      < Grid container item xs={2}>
        <NumericFormat
          customInput={TextField}
          className={classes.inputBoxStyle}
          name={BookChangeableFieldType.AGGT_SOLD_PRICE}
          label=""
          placeholder="Add price"
          allowNegative={false}
          fullWidth
          decimalScale={2}
          value={props.book.soldPrice}
          onValueChange={(eventValues: NumberFormatValues) => {
            props.saveChange(BookChangeableFieldType.AGGT_SOLD_PRICE, props.idx, eventValues.floatValue,)
          }}
        />
        <div className={`${classes.iconDiv} ${classes.deleteIcon} ${classes.hideDeleteIcon}`} onClick={() => props.handleDeleteRow(props.idx)} >
          <DeleteIcon titleAccess={`Remove current row`} />
        </div>
      </Grid>

    </Grid>)
}

export default SelectedBookOrders;