import { Box, Card, CardContent, CardMedia, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import moment from "moment";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/rachnayeLight.png";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";


const useStyles = makeStyles((theme) =>
  createStyles({
    mainContainer: {
      padding: "2rem 0.6rem",
      width: "100%"
    },
    div1: {
      backgroundColor: "#F5F7F9",
      borderRadius: "4px", width: "100%"
    },
    div2: {
      padding: "1rem",
      width: "100%"
    },
    card: {
      display: "flex"
    },
    cardMedia: {
      width: 100,
      height: 144
    },
    contentBox: {
      width: "95%",
      cursor: "pointer"
    },
    cardContent: {
      padding: "1rem 1.6rem 0rem 1.6rem"
    },
    timeStamp: {
      fontSize: "11px"
    },
    editor: {
      "& .ql-editor": {
        cursor: "pointer",
        height: "3.5rem",
        overflowY: "hidden",
        padding: "1rem 0rem",
      },
    },
    deleteIconBox: {
      width: "5%"
    },
    emptyBox: {
      height: "3.5rem"
    },
    deleteBtn: {
      padding: "1rem 0.1rem",
      "& :hover": {
        cursor: 'pointer',
        color: theme.palette.primary.main
      }
    }
  })
);


interface IProps {
  items?: any[];
  fetchData?: (reset?: boolean) => void;
  lastElementRef?: (node: any) => void
}

const UsersDraftRachnaye: React.FC<IProps> = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const snackbar = useSnackBar();

  const [open, setOpen] = useState<boolean>(false)
  const [postIdToDelete, setPostIdToDelete] = useState<string>("") // postId

  const isLastElementinList = (index: number): boolean => props.items?.length === index + 1

  const deleteDraftPost = async () => {
    if (postIdToDelete) {
      const res = await callApi(PATHNAMES.DELETE_DRAFT_POST(postIdToDelete), HTTP_METHODS.DELETE)
      if (res && res.success) {
        if (typeof props.fetchData === 'function') {
          await props.fetchData(true)
        }
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: res.message || "Post deleted successfully"
        })
      } else {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: res.message || "Error while deleting post"
        })
      }
      setOpen(v => false)
      setPostIdToDelete(v => "")
    }
  }


  return (
    <Grid container xs={12} className={classes.mainContainer}>

      {props.items && props.items.length > 0 && props?.items.map((post: any, index: number) => (

        <Grid key={post.id} ref={isLastElementinList(index) ? props.lastElementRef : null} container className={classes.div1}>
          <Grid item className={classes.div2}>

            <Card variant="outlined" className={classes.card}>
              <CardMedia
                component="img"
                className={classes.cardMedia}
                image={post.coverImage || logo}
                alt="Cover Img"
              />

              <Box className={classes.contentBox} onClick={() => navigate(`/portal/rachnaye/post/edit?id=${post.id}&type=${post.type}`)}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="subtitle1">
                    {post.title}
                  </Typography>
                  {post.content ?
                    <ReactQuill
                      preserveWhitespace
                      readOnly
                      theme="bubble"
                      value={JSON.parse(post.content)}
                      className={classes.editor}
                    /> : <Box className={classes.emptyBox} ></Box>
                  }
                  <Typography variant="subtitle2" className={classes.timeStamp}>
                    {`Last updated at : ${moment(post.updatedAt).format("LLL")}`}
                  </Typography>
                </CardContent>

              </Box>
              <Box className={classes.deleteIconBox}>
                <DeleteIcon fontSize="small" className={classes.deleteBtn} onClick={() => {
                  setPostIdToDelete(v => post.id)
                  setOpen(true)
                }} />
              </Box>

            </Card>
          </Grid>
        </Grid>
      ))}

      <ConfirmationDialog
        isOpen={open}
        onCancel={() => setOpen(false)}
        onConfirm={deleteDraftPost}
        title={"Confirm Post Delete"}
        description={"Are you sure, you want to delete this Draft post permanently"}
      />
    </Grid>
  );
};

export default UsersDraftRachnaye;
