import {
  Grid,
  Typography,
  makeStyles,
  TextField
} from "@material-ui/core";
import React, { useState } from "react";
import callApi from "../../../../utils/apiUtil";
import { PATHNAMES } from "../../../../utils/pathNames";
import { HTTP_METHODS } from "../../../../utils/constants";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { ErrorHandler } from "../../../../utils/utils";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import EditIcon from "@material-ui/icons/Edit"
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    position: "relative",
    top: "0.3rem",
    marginLeft: "0.5rem",
    fontSize: '1rem',
    cursor: 'pointer'
  },
  cancelIcon: {
    fontSize: '18px', marginRight: '0.1rem',
    cursor: 'pointer',
    marginLeft: '0.6rem',
  },
  saveButton: {
    marginTop: "0.3rem",
    fontSize: '12px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: '1rem',
    display: 'inline'
  },
  cancelButton: {
    fontSize: '12px',
    marginTop: "0.3rem",
    marginLeft: '1rem',
    cursor: 'pointer',
    display: 'inline'
  },
  inputBoxStyle: {
    height: "0.2rem",
    width: '4rem',
    padding: '0rem',
    "& .MuiOutlinedInput-input": {
      fontSize: '12px',
      padding: '0.3rem'
    }
  }
}))


interface IProps {
  data: any;
  distributorId: string;
  isAvailable: boolean;
  quantity: number
  bookType: string;
}

interface IInventoryData {
  bookId: string;
  publisherId: string;
  bookType: string;
  quantity: number;
}

const CustomBookQuantityUpdate: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const snackBar = useSnackBar()
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [oldValue, setOldValue] = useState<number>(props.quantity)
  const [inventoryData, setInventoryData] = useState<IInventoryData>({
    bookId: props.data.rowData[0], // row data coming from columns - where component is getting called
    publisherId: props.data.rowData[1],
    bookType: props.bookType,
    quantity: props.quantity
  });

  const UpdateInventory = async (inventory: IInventoryData) => {
    try {
      setIsVisible(false)
      const data = {
        inventory
      }
      const response = await callApi(PATHNAMES.UPDATE_BOOK_INVENTORY_BY_DISTRIBUTOR(props.distributorId), HTTP_METHODS.PATCH, {
        body: JSON.stringify({ data })
      })

      if (response && response.success && response.message) {
        setIsEditable(false)
        setOldValue(inventory.quantity)
        snackBar({
          variant: SnackBarVariant.SUCCESS,
          message: response.message || "updated successfully",
        })
      } else {
        throw new ErrorHandler(response.message)
      }
    } catch (error: any) {
      setIsEditable(false)
      snackBar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
    }

  }

  const handleCancel = () => {
    setIsEditable(false)
    setIsVisible(false)
    setInventoryData({
      ...inventoryData,
      quantity: oldValue
    })
  }

  // if book type is not available to sell or book is available for not for distributor to sell
  // i.e hardbouns is available but hardbound is not getting sold by distributor  
  if (!props.isAvailable || !(inventoryData.quantity >= 0)) {
    return (
      <Grid item xs={12}>
        <Typography variant='h5'>not available</Typography>
      </Grid>
    )
  }

  return (
    <Grid container xs={12} justify='flex-start'>
      {isEditable ?
        <Grid item xs={6}>
          <NumericFormat
            allowNegative={false}
            value={inventoryData.quantity || 0}
            customInput={TextField}
            className={classes.inputBoxStyle}
            decimalScale={0}
            variant="outlined"
            fullWidth
            onValueChange={(value: NumberFormatValues) => {
              // floatValue always gives a number or undefined
              if (value.floatValue) {
                setInventoryData({
                  ...inventoryData,
                  quantity: value.floatValue
                })
              } else {
                setInventoryData({
                  ...inventoryData,
                  quantity: 0
                })
              }
            }}
          />

          <CheckCircleIcon color='primary' onClick={() => UpdateInventory(inventoryData)} />
          <CancelIcon color='secondary' onClick={handleCancel} />
        </Grid>
        :
        <Grid item xs={6}>
          <span onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
            <Typography variant='h5' style={{ display: 'inline' }}>{inventoryData.quantity}</Typography>
            {isVisible && <EditIcon className={classes.editIcon} color='primary' onClick={() => setIsEditable(true)} />}
          </span>
        </Grid>
      }
    </Grid>
  )
}

export default CustomBookQuantityUpdate;