import { Grid, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import moment from 'moment';
import { MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import callApi, { buildQueryParamFromObject } from '../../../utils/apiUtil';
import { IFiltersAndSorting } from '../../../utils/common.interface';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import CircularLoader from '../../common/CircularLoader';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import { DataTable } from '../../common/Datatable/Datatable';
import { Pagination } from '../../common/Datatable/types';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';
import { capitalizeFirstLetter } from '../../../utils/utils';


interface IScheduleNotificationRes {
  count: number;
  template: string;
  createdAt: Date;
}

const ScheduledNotifications: React.FC = () => {
  const snackbar = useSnackBar();
  const [notifications, setNotifications] = useState<IScheduleNotificationRes[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasMoreNotifications, setHasMoreNotifications] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [deleteNotificationGrpId, setDeleteNotificationGrpId] = useState<string>("");

  // FETCH ORDER
  useEffect(() => {
    fetchScheduleNotifications({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])


  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    count: totalCount,
    searchPlaceholder: "Search by subject/template",
  };

  const columns: any = [
    {
      label: "Notification Group ID",
      name: "template",
    },
    {
      label: "Title",
      name: "title",
    },
    {
      label: "Sender",
      name: "sender",
      options: {
        customBodyRender: (data: string) => {
          if (data) {
            return capitalizeFirstLetter(data)
          }
          return "System"
        }
      }
    },
    {
      label: "Scheduled For",
      name: "scheduledDate",
      options: {
        customBodyRender: (data: string) => {
          if (data) {
            return moment(data).format("DD/MM/YYYY, h:mm A")
          }
          return "Not scheduled"
        }
      }
    },
    {
      label: "Count",
      name: "count",
    },
    {
      label: "Created At",
      name: "createdAt",
      options: {
        customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY, h:mm A")
      }
    },
    {
      label: "Delete",
      name: "",
      options: {
        filter: false,
        setCellProps: () => ({ style: { minWidth: "2rem", maxHeight: '2rem' } }),
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta, updateValue: any) => {
          return (
            <IconButton
              id={`Edit-${tableMeta.rowIndex}`}
              size='small'
              onClick={() => {
                const [grpId] = tableMeta.rowData
                setDeleteNotificationGrpId(grpId)
                setOpenDeleteConfirmation(true)
              }}
            >
              <DeleteIcon titleAccess="Edit Distributor" color='primary' style={{ fontSize: '1em' }} />
            </IconButton>
          );
        }
      }
    },
  ]

  const fetchScheduleNotifications = (args: IFiltersAndSorting) => {
    const queryParam = buildQueryParamFromObject({
      ...args,
    })

    callApi(PATHNAMES.GET_SCHEDULED_NOTIFICATIONS(queryParam), HTTP_METHODS.GET).then((notificationRes) => {
      if (notificationRes && notificationRes.success && notificationRes.data) {
        setNotifications(notificationRes.data.messgess);
        setTotalCount(notificationRes.data.totalMessagesCount)
        if (!hasMoreNotifications) {
          setHasMoreNotifications(notificationRes.data.msgPresent)
        }
      }
    })
    setLoading(false)
  }

  const handleSchduleNotificationDelete = () => {
    if (!deleteNotificationGrpId) {
      snackbar({
        message: "Notification Group Id not defined",
        variant: SnackBarVariant.ERROR
      })
      return
    }

    const encodedGrpId = encodeURIComponent(deleteNotificationGrpId);
    callApi(PATHNAMES.DELETE_SCHEDULED_NOTIFICATIONS(encodedGrpId), HTTP_METHODS.DELETE).then((notificationRes) => {
      if (notificationRes && notificationRes.success) {
        setOpenDeleteConfirmation(false)
        setLoading(true)
        fetchScheduleNotifications({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
        snackbar({
          message: "Notification Deleted Successfully",
          variant: SnackBarVariant.SUCCESS
        })
      }
    })
    setLoading(false)
  }


  if (loading) {
    return <CircularLoader />
  }


  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h2'>Scheduled Notifications</Typography>
      </Grid>
      <Grid container xs={12}>
        <DataTable
          title={`Total Scheduled notifications (${totalCount})`}
          rows={notifications}
          columns={columns}
          options={options}
          hideSelectableRows={true}
          serverSide
          fetchData={fetchScheduleNotifications}
        />
      </Grid>
      <ConfirmationDialog
        isOpen={openDeleteConfirmation}
        onCancel={() => {
          setDeleteNotificationGrpId('')
          setOpenDeleteConfirmation(false)
        }}
        onConfirm={handleSchduleNotificationDelete}
        title={"Confirm Deletion"}
        description={`Are you sure, you want to delete ${deleteNotificationGrpId} Schedule Notification`}
      />
    </Grid>
  )
}

export default ScheduledNotifications;