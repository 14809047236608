import { AppBar, Grid, Paper, Tab, Tabs, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { TabPanel } from "../../../common/TabPanel";
import DistributorBooks from "./DistributorBooks";
import DistributorOrders from "./DistributorOrders";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
    minHeight: "55vh",
  }
}));

const DistributorDetailTabView: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  return (
    <Grid container>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Books" />
          <Tab label="Orders" />
        </Tabs>
      </AppBar>
      <Paper className={classes.root}>
        <TabPanel value={value} index={0}>
          <DistributorBooks />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DistributorOrders />
        </TabPanel>
      </Paper>
    </Grid>
  );
};

export default DistributorDetailTabView;