import {
  Button, Card,
  CardActions, CardMedia, Chip, CircularProgress,
  FormControl,
  FormControlLabel, Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextareaAutosize,
  TextField, Tooltip, Typography, useMediaQuery, useTheme
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import countryList from 'react-select-country-list';
import { IAuthor, IBook } from "../../../../reducers/books/types";
import { IDistributor, IFormattedDistributor } from "../../../../reducers/distributors/types";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { getAgeRangeValueOrLabel, getCategoryLabelByValue, getLanguageLabelByValue, validateBookData } from "../../../../utils/bookUtils";
import { BOOK_AGE_RANGE, BOOK_CATEGORIES, BOOK_CONTENT_TYPE, BOOK_LANGUAGES, BOOK_STATUS, BookContentTypes, BookTypes, HTTP_METHODS, IamUserType, ResourceTypes } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { uploadFileToS3 } from "../../../../utils/s3Util";
import { ErrorHandler } from "../../../../utils/utils";
import useUnsavedChangesWarning from "../../../common/AlertUnsavedWarning";
import { ConfirmationDialog } from "../../../common/ConfirmationDialog";
import FlatPickerBar from "../../../common/FlatPickerBar";
import ImageUploader from "../../../common/ImageUploader";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import Author from "./Author";
import LookInside from "./LookInside";
import PhysicalBook from "./PhysicalBook";
import UploadAudiobook from "./UploadAudioBook";
import UploadEbook, { IDigitalBookInput } from "./UploadEbook";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontStyle: 'Bold',
    fontSize: '16px',
  },
  editButton: {
    marginTop: "0.3rem",
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  subHeading: {
    fontSize: '12px',
    fontWeight: 500,
    color: "#808080"
  },
  subContent: {
    fontSize: '13px',
    fontWeight: 500,
    paddingTop: '0.5rem',
    color: "#000000",
  },
  paper: {
    "border-radius": "15px"
  },
  saveButton: {
    marginTop: "0.3rem",
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: '1rem'
  },
  tagsChip: {
    borderRadius: 4,
    background: "#FAFAFA",
    border: "1px solid #E8E8E8",
    "&.MuiAutocomplete-tag": {
      margin: "10px 10px 5px 0px"
    }
  },
  cancelButton: {
    marginTop: "0.3rem",
    cursor: 'pointer',
  },
  margin: {
    paddingTop: '0.4rem',
    height: '1.8rem',
    "& .MuiFilledInput-input": {
      paddingTop: '10px',
      fontSize: '12px',
    },
    "& .MuiFilledInput-root": {
      "border-radius": '8px',
      "paddingTop": '1px'
    },
    "& .MuiFilledInput-underline:before": {
      "border-bottom": 'none'
    },
    "& .MuiFilledInput-underline:after": {
      "border-bottom": 'none'
    },
  },
  bookPriceSubItem: {
    marginBottom: '1rem'
  },
  bookPriceMiddleSubItem: {
    marginBottom: '1rem',
    paddingLeft: '1.8rem',
    paddingRight: '1.8rem',
  },
  tooltip: {
    fontSize: '13px',
    maxWidth: 400
  },
  textArea: {
    "& .MuiOutlinedInput-notchedOutline": {
      minHeight: 120
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(15px, 1px) scale(0.75)"
    }
  },
  navButton: {
    minWidth: '0rem',
  },
  preOrderheading: {
    paddingTop: '1.4rem'
  }
}))

export interface IDistributors {
  publisherDistributors: IDistributor[];
  bookDistributors: IFormattedDistributor[];
}

const BookDetail: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [uploadAudioBookModalOpen, setUploadAudioBookModalOpen] = useState<boolean>(false);
  const [uploadEbookModalOpen, setUploadEbookModalOpen] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEditableAuthor, setIsEditableAuthor] = useState<boolean>(false);
  const [isEditableContentType, setIsEditableContentType] = useState<boolean>(false);
  const [isEditableStatus, setIsEditableStatus] = useState<boolean>(false);
  const [oldBook, setOldBook] = useState<IBook>(); // book state for cancle button
  const snackbar = useSnackBar();
  const location = useLocation();
  const [book, setBook] = useState<IBook>();
  const [authors, setAuthors] = useState<IAuthor[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [thumbnail, setThumbnail] = useState<string>("");
  const [bookAuthors, setBookAuthors] = useState<IAuthor[]>([]);
  const [currentAuthorIndex, setCurrentAuthorIndex] = useState<number>(0);
  const userState = useSelector((state: IAppState) => state.user);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [, setAlert, removeAlert] = useUnsavedChangesWarning("If you leave, Your changes will be lost.\nClick Ok to Leave\nCancel to Stay");
  const countries = useMemo(() => countryList().getData(), [])
  const [wordCount, setWordCount] = useState<number>(0);
  const [hideNavigation, setHideNavigation] = useState<boolean>(false);
  const [authorACOpen, setAuthorACOpen] = useState<boolean>(false); //AC-autocomplete
  const [categoriesACOpen, setCategoriesACOpen] = useState<boolean>(false);
  const [categoriesACInputValue, setCategoriesACInputValue] = useState<string>('');
  const [countriesACOpen, setCountriesACOpen] = useState<boolean>(false);
  const [countriesACInputValue, setCountriesACInputValue] = useState<string>('');
  const [keywordsACOpen, setKeywordsACOpen] = useState<boolean>(false);
  const [keywordsSuggestions, setKeywordsSuggestions] = useState<string[]>([]);
  const [keywordInputValue, setKeywordInputValue] = useState<string>("");


  //fetch authors 
  useEffect(() => {
    callApi(PATHNAMES.GET_ALL_AUTHORS, HTTP_METHODS.GET).then((authorsResponse) => {
      if (authorsResponse && authorsResponse.success && authorsResponse.data) {
        setAuthors(authorsResponse.data);
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    if (isEditable || isEditableAuthor || isEditableContentType || isEditableStatus) {
      setAlert();
    } else {
      removeAlert()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable, isEditableAuthor, isEditableContentType, isEditableStatus])

  //fetch book data
  useEffect(() => {
    if (location && location.search && userState && userState.id && userState.spaceId) {
      const params = new URLSearchParams(location.search);
      const bookId = params.get("book");
      if (bookId) {
        //fetch book data
        getBookData(bookId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, userState]);

  //fetch suggested keywords list for autocomplete
  useEffect(() => {
    if (keywordInputValue) {
      fetchKeywordsSuggestions(keywordInputValue)
    }
  }, [keywordInputValue]);

  //get keywords suggestion for input value
  const fetchKeywordsSuggestions = (searchValue: string) => {
    callApi(PATHNAMES.GET_KEYWORD_SUGGESTIONS(searchValue), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setKeywordsSuggestions(res.data)
      }
    })
  }

  //get book data
  const getBookData = (bookId: string) => {
    callApi(PATHNAMES.GET_BOOK_BY_ID(userState.id, bookId), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setOldBook(res.data)
        setBook(res.data);
        setKeywordsSuggestions([...res?.data?.keywords])
        setThumbnail(res?.data?.thumbnailFront)
        setBookAuthors(res?.data?.authors)
        if (res.data?.description) {
          const tempWordCount = res.data?.description?.split(" ")?.length || 0
          setWordCount(tempWordCount)
        }
      }
    })
  }

  //update book
  const updateBook = async (bookObj: any, validate = true) => {
    try {
      if (book && book.id && userState.id) {
        if (!bookObj?.pricing?.planName) {
          bookObj = {
            ...bookObj,
            pricing: {
              ...bookObj.pricing,
              planName: "BASIC"
            }
          }
        }
        if (validate) {
          bookObj = await validateBookData(bookObj)
          // if publisher disables both paperback and hardbound the, preOrder will not be available. 
          if ((!bookObj.hasPaperback && !book.hasHardbound) || bookObj.contentType === BookContentTypes.FREE) {
            bookObj.preOrderAvailable = false
          }
        }
        if (bookObj.url && bookObj.url.includes("https")) {
          delete bookObj.url
        }
        if (bookObj.thumbnailFront) {
          delete bookObj.thumbnailFront
        }
        delete bookObj.hasAudiobook
        delete bookObj.audioBook
        if (bookObj.thumbnailBack) {
          delete bookObj.thumbnailBack
        }
        if (bookObj?.previews?.length) {
          delete bookObj.previews
        }
        if (bookObj?.authors?.length) {
          bookObj.authors = bookObj.authors.map((author: any) => {
            return {
              id: author.id,
              bio: author?.bio
            }
          })
        }

        const response = await callApi(PATHNAMES.BOOK_UPDATE(book.id), HTTP_METHODS.PUT, {
          body: JSON.stringify({ data: bookObj })
        });
        if (response && response.success) {
          snackbar({
            message: "Book updated successfully",
            variant: SnackBarVariant.SUCCESS
          });
          callApi(PATHNAMES.GET_BOOK_BY_ID(userState.id, book.id), HTTP_METHODS.GET).then((res) => {
            if (res && res.success && res.data) {
              if (res.data?.description) {
                const tempWordCount = res.data?.description?.split(" ")?.length || 0
                setWordCount(tempWordCount)
              }
              updateAllUseStateValue(res.data)
              setIsEditable(false)
            }
          })
        } else {
          throw new ErrorHandler(response.message)
        }
      }

    } catch (error: any) {
      snackbar({
        message: error.message || "something went wrong",
        variant: SnackBarVariant.WARNING
      });
    }
  }

  // update book authors
  const updateAuthors = async (authors: any) => {
    try {
      if (book && book.id && userState.id) {
        if (!authors.length) {
          throw new ErrorHandler('Please add Author')
        }
        authors = authors.map((author: any) => {
          return {
            id: author.id,
            bio: author?.bio,
            image: author?.imagePath
          }
        })
        const data = { authors }
        const response = await callApi(PATHNAMES.UPDATE_BOOK_AUTHORS(book.id), HTTP_METHODS.PUT, {
          body: JSON.stringify({ data })
        });
        if (response && response.success && response.data) {
          snackbar({
            message: "Book Authors updated successfully",
            variant: SnackBarVariant.SUCCESS
          });
          setBookAuthors(response?.data?.authors)
          setCurrentAuthorIndex(0)
          setIsEditableAuthor(false)
        } else {
          throw new ErrorHandler(response.message)
        }
      }
    } catch (error: any) {
      snackbar({
        message: error.message || "something went wrong",
        variant: SnackBarVariant.WARNING
      });
    }
  }

  // update book's front cover image
  const updateFrontCover = async (bookObj: any) => {
    try {
      if (book && book.id && userState.id) {

        if (bookObj.thumbnailFront && bookObj.thumbnailFront.includes("https")) {
          delete bookObj.thumbnailFront
        }
        const data = { thumbnail: bookObj.thumbnailFront }
        const response = await callApi(PATHNAMES.UPDATE_BOOK_FRONT_COVER(book.id), HTTP_METHODS.PATCH, {
          body: JSON.stringify({ data })
        });

        if (response && response.success && response.data) {
          snackbar({
            message: response.message,
            variant: SnackBarVariant.SUCCESS
          });
          setThumbnail(response.data.thumbnailFront)
          updateAllUseStateValue(response.data)
        } else {
          throw new ErrorHandler(response.message)
        }
      }
    } catch (error: any) {
      snackbar({
        message: error.message || "something went wrong",
        variant: SnackBarVariant.WARNING
      });
    }
  }

  // Upload Epub for Non-digital book
  const uploadEBook = async (bookData: IDigitalBookInput) => {
    setUploadEbookModalOpen(false)
    const key: string = await uploadFileToS3({
      file: bookData.file,
      resourceType: ResourceTypes.BOOK_EPUB,
      isAdmin: false,
      publisherId: book?.publisher
    })
    if (book) {
      const data = { bookUrl: key }
      const response = await callApi(PATHNAMES.UPLOAD_EPUB_FOR_NONDIGITAL(book.id), HTTP_METHODS.PATCH, {
        body: JSON.stringify({ data })
      })
      if (response && response.success) {
        snackbar({
          message: response.message,
          variant: SnackBarVariant.SUCCESS
        });
        callApi(PATHNAMES.GET_BOOK_BY_ID(userState.id, book.id), HTTP_METHODS.GET).then((res) => {
          if (res && res.success && res.data) {
            updateAllUseStateValue(res.data)
          }
        })
        if (book.hasDigital && checkPriceTrue()) {
          setIsEditable(true)
        }
      } else {
        snackbar({
          message: response.message || "something went wrong",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }
  //update book status , activated or deactivaed 
  const updateBookStatus = async (bookStatus: boolean) => {
    if (book?.id && userState?.id) {
      const data = {
        isActivated: bookStatus
      }
      const response = await callApi(PATHNAMES.BOOK_STATUS_UPDATE(book.id), HTTP_METHODS.PATCH, {
        body: JSON.stringify({ data })
      });
      if (response && response.success) {
        snackbar({
          message: response.message,
          variant: SnackBarVariant.SUCCESS
        });
        setOpenConfirmationDialog(false)
        callApi(PATHNAMES.GET_BOOK_BY_ID(userState.id, book.id), HTTP_METHODS.GET).then((res) => {
          if (res && res.success && res.data) {
            updateAllUseStateValue(res.data)
            setIsEditableStatus(false)
          }
        })
      } else {
        snackbar({
          message: response.message || "something went wrong",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  const updateAllUseStateValue = (data: IBook) => {
    setOldBook(data);
    setBook(data);
    setIsEditableContentType(false)
    setOpenConfirmationDialog(false)
  }

  const bookStatus = (type: boolean) => {
    if (type === true) {
      return BOOK_STATUS[0].label
    } else {
      return BOOK_STATUS[1].label
    }
  }

  const uploadDocument = async (file: File) => {
    const key = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.BOOK_IMAGE,
      isAdmin: false,
      publisherId: book?.publisher
    });
    const bookData: any = { ...book }

    if (bookData && bookData.id) {
      bookData.thumbnailFront = key;
    }
    await updateFrontCover(bookData)
  }


  //handle Edit to add haspaparback for nondigital book
  const handleEditClickForbook = () => {
    if (book && !book.hasDigital && !book.hasRental && !book.hasHardbound) {
      if (book) {
        setBook({ ...book, hasPaperback: true })
      }

    }
    return setIsEditable(true)
  }

  //go left
  const goLeft = () => {
    setCurrentAuthorIndex((currentAuthorIndex - 1))
  }

  //go right
  const goRight = () => {
    setCurrentAuthorIndex((currentAuthorIndex + 1))
  }

  //check prices if bookType is paid before save
  const checkPriceTrue = () => {
    if (!book?.pricing?.sellPrice) {
      snackbar({
        message: "Please Add Price Detail to Book",
        variant: SnackBarVariant.INFORMATION,
      })
      return true
    }
    return false
  }

  //#region handle switch buttons to be diabled for Digital book
  const toggleDigitalButton = () => {
    // if digital book then -
    if (book?.hasRental) {
      // if rental, then enable toggle
      return false
    }
    //disable toggle -by default
    return true
  }
  const toggleRentalButton = () => {
    // if digital book then -
    if (book?.hasDigital) {
      // if digital, then enable toggle
      return false
    }
    //disable toggle -by default
    return true
  }

  //#region handle switch buttons to be diabled for Non-Digital book
  const togglePaperbackButton = () => {
    // if Non -digital book then -
    // don't disable paperback when toggling digital toggle if epub was uploaded once 
    if (!book?.hasDigital && !book?.url) {
      if (book?.hasHardbound) {
        return false
      }
      // disable toggle - by default 
      return true
    } else {
      //enable toggle
      return false
    }
  }

  const toggleHardboundButton = () => {
    //if paperback is not choosen then make hardbound enabled by default
    if (!book?.hasDigital && !book?.url) {
      if (!book?.hasPaperback) {
        return true
      }
      return false
    }
  }
  //#endregion handle switch buttons to be diabled for Non-Digital book

  //handle switch buttons for preOrder switch button
  const isPreOrderButtonDisable = () => {
    if (!book?.preOrderAvailable && !isAvailableForPreOrder()) {
      return true
    }
    return false
  }

  const isAvailableForPreOrder = () => {
    if (book?.contentType === BookContentTypes.FREE) {
      return false
    }
    if (book?.contentType === BookContentTypes.PAID && isPaperBookPriceNotAvailable()) {
      return false
    }
    return true
  }

  const isPaperBookPriceNotAvailable = () => !book?.hasPaperback && !book?.hasHardbound

  if (!book || !oldBook || loading) {
    return <CircularProgress />
  }

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify='flex-start'>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={3} style={{ marginTop: '1rem' }}>
        {/*Book section  */}
        <Grid container item xs={12} sm={12} md={7} lg={7} xl={7}   >
          <Paper elevation={1} className={classes.paper} style={{ width: "100%", padding: "1rem" }}>
            {/* heading */}
            <Grid container spacing={2} xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '0.8rem', paddingLeft: '0.7rem' }}>
              <Grid container item xs={6} sm={4} md={4} lg={4} xl={4}>
                <Typography variant='h3' className={classes.title}> Book Details</Typography>
              </Grid>
              <Grid container item xs={6} sm={8} md={8} lg={8} xl={8} style={{ paddingRight: '0px' }} justify="flex-end">
                {userState.role?.category === IamUserType.ORG && <Typography variant="h4" className={classes.editButton}
                  style={{ marginRight: '2.5rem', borderBottom: "1px solid" }}
                  onClick={() => setUploadAudioBookModalOpen(true)}
                >
                  {book.audioBook?.status === "PUBLISHED" ? "AudioBook" : "Upload AudioBook"}
                </Typography>}

                <Typography variant="h4" className={classes.editButton}
                  style={{ marginRight: '2.5rem', borderBottom: "1px solid" }}
                  onClick={() => setUploadEbookModalOpen(true)}
                >
                  {book.url ? "Update Epub" : "Upload Epub"}
                </Typography>

                {isEditable ?
                  <>
                    <Typography variant="h4" className={classes.saveButton}
                      onClick={() => updateBook(book)}
                    >
                      Save
                    </Typography>
                    <Typography variant="h4" className={classes.cancelButton}
                      onClick={() => { return (setIsEditable(false), setBook(oldBook)) }}>
                      Cancel
                    </Typography>
                  </>
                  :
                  <Typography variant="h4" className={classes.editButton}
                    onClick={handleEditClickForbook}>
                    Edit
                  </Typography>}
              </Grid>
            </Grid>
            {/* content part */}
            <Grid container spacing={3} xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '0.8rem', paddingLeft: '0.7rem' }}>
              {/* book front cover */}
              <Grid container item xs={8} sm={4} md={4} lg={3} xl={3} style={{ padding: "0.6rem 0rem 0.6rem 0.6rem" }}>
                <Card style={{ height: '13rem', maxWidth: '8rem' }}>
                  {thumbnail ?
                    <CardMedia
                      component="img"
                      height="160"
                      alt="Front Cover"
                      title="Front Cover"
                      image={thumbnail}
                    /> :
                    <Grid container justify="center" alignContent="center" xs={12} style={{ height: 160 }}>
                      <Typography variant='h3'>Add Image</Typography>
                    </Grid>
                  }
                  <CardActions>
                    <Typography style={{ marginTop: "0.2rem", color: "#808080" }}>Front Cover</Typography>
                    <ImageUploader id={'BookFrontCover'} handleSave={uploadDocument} />
                  </CardActions>
                </Card>
              </Grid>
              <Grid container item xs={12} sm={8} md={8} lg={9} xl={9} style={{ padding: '1rem 1rem 0rem 1rem' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} direction="column" spacing={3}>
                  {/* book title  */}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: '1rem 1rem 1.5rem 1rem' }}>
                    <Typography variant='h5' className={classes.subHeading}>BOOK NAME</Typography>
                    {isEditable ?
                      <TextField
                        variant="filled"
                        className={classes.margin}
                        value={book.title}
                        fullWidth
                        onChange={(e) => {
                          setBook({
                            ...book,
                            title: e.target.value
                          })
                        }}
                      /> :
                      <Typography variant='h6' className={classes.subContent}>{book.title}</Typography>
                    }
                  </Grid>
                  {/* book description  */}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: '1rem 1rem 1.5rem 1rem' }}>
                    <Typography variant='h5' className={classes.subHeading}>DESCRIPTION</Typography>
                    {isEditable ?
                      <TextareaAutosize
                        rowsMin={7}
                        rowsMax={7}

                        className={classes.subContent}
                        style={{ marginTop: "1rem", width: "98%", backgroundColor: "#f2f2f2" }}
                        value={book.description}
                        onChange={(e) => {
                          setBook({
                            ...book,
                            description: e.target.value
                          })
                        }}
                      />
                      :
                      wordCount < 10 ?
                        <Typography variant='h6' paragraph={true} className={classes.subContent}>{book.description ? book.description : "Please add Description"}</Typography>
                        :
                        <Typography variant='h6' paragraph={true} className={classes.subContent}>
                          {`${book.description.slice(0, 150)}...`}
                          <Tooltip arrow classes={{ tooltip: classes.tooltip }} title={book.description || ""}><span style={{ color: "#FF5C3E", textDecoration: 'underline' }}> {`more `}</span></Tooltip>
                        </Typography>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* cat and lang and Ebook container */}
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={0} style={{ margin: '0rem 0rem 0rem 0rem' }}   >
                  {isEditable ?
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={1} style={{ margin: '0rem 0rem 0rem 0rem' }}   >
                      <Grid item xs={12} style={{ display: "flex" }}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={isPreOrderButtonDisable()}
                                checked={book.preOrderAvailable}
                                onClick={(e: any) => {
                                  setBook({
                                    ...book,
                                    preOrderAvailable: !book.preOrderAvailable
                                  })
                                }}

                                value="checkedSwitch"
                              />
                            }
                            label="Available For Pre-Order"
                          />
                        </Grid>
                        {book.preOrderAvailable && <Grid container xs={12} sm={12} md={8} lg={8} xl={8} spacing={2}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <FlatPickerBar
                              handleDateChange={(value: Date) => {
                                setBook({
                                  ...book,
                                  preOrderStartDate: moment(value).startOf("day").toISOString()
                                })
                              }}
                              label="Pre-Order Start Date"
                              identifier="preOrderStartDate"
                              name="preOrderStartDate"
                              minDate={moment().startOf("day").toDate()}
                              value={book.preOrderStartDate}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <FlatPickerBar
                              handleDateChange={(value: Date) => {
                                setBook({
                                  ...book,
                                  bookLaunchDate: moment(value).startOf("day").toISOString()
                                })
                              }}
                              label="Book Launch Date"
                              identifier="bookLaunchDate"
                              name="bookLaunchDate"
                              minDate={moment().startOf("day").toDate()}
                              value={book.bookLaunchDate}
                            />
                          </Grid>
                        </Grid>}
                        {isPreOrderButtonDisable() &&
                          <Grid container xs={12} sm={12} md={8} lg={8} xl={8} spacing={2}>
                            <Typography variant='h5' className={classes.preOrderheading} > Pre-order will be enable only for paid books having paperback and hardbound</Typography>
                          </Grid>}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Typography variant='h5' className={classes.subHeading}>CATEGORY</Typography>
                        <Autocomplete
                          open={categoriesACOpen}
                          onOpen={() => {
                            // only open when in focus and inputValue is not empty
                            if (categoriesACInputValue) {
                              setCategoriesACOpen(true);
                            }
                          }}
                          onClose={() => setCategoriesACOpen(false)}
                          inputValue={categoriesACInputValue}
                          onInputChange={(e, value, reason) => {
                            setCategoriesACInputValue(value);
                            // only open when inputValue is not empty after the user typed something
                            if (!value.trim()) {
                              setCategoriesACOpen(false);
                            }
                          }}
                          options={BOOK_CATEGORIES}
                          autoHighlight
                          defaultValue={{ label: getCategoryLabelByValue(book.category), value: book.category || "" }}
                          onChange={(e: any, selectedCategory) => {
                            if (selectedCategory) {
                              setBook({
                                ...book,
                                category: selectedCategory.value,
                              })
                            }
                          }}
                          getOptionLabel={(category: any) => category.label}
                          renderOption={(category) => (
                            <>
                              {`${category.label}`}
                            </>
                          )}
                          style={{ paddingRight: '0rem' }}
                          renderInput={(params) => (
                            <TextField {...params} variant="filled" className={classes.margin} placeholder="Search for category" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <Typography variant='h5' className={classes.subHeading}>LANGUAGE</Typography>
                        <Autocomplete
                          options={BOOK_LANGUAGES}
                          autoHighlight
                          disablePortal={true}
                          defaultValue={{ label: getLanguageLabelByValue(book.language), value: book.language || "" }}
                          onChange={(e: any, selectedLanguage) => {
                            if (selectedLanguage) {
                              setBook({
                                ...book,
                                language: selectedLanguage.value,
                              })
                            }
                          }}
                          getOptionLabel={(language: any) => language.label}
                          renderOption={(language) => (
                            <>
                              {`${language.label}`}
                            </>
                          )}
                          style={{ paddingRight: '0rem' }}
                          renderInput={(params) => (
                            <TextField {...params} variant="filled" className={classes.margin} placeholder="Search for language" />
                          )}
                        />


                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={4} className={classes.bookPriceSubItem}>
                        <Typography variant='h5' className={classes.subHeading}>COUNTRY OF ORIGIN</Typography>
                        <Autocomplete
                          open={countriesACOpen}
                          onOpen={() => {
                            // only open when in focus and inputValue is not empty
                            if (countriesACInputValue) {
                              setCountriesACOpen(true);
                            }
                          }}
                          onClose={() => setCountriesACOpen(false)}
                          inputValue={countriesACInputValue}
                          onInputChange={(e, value, reason) => {
                            setCountriesACInputValue(value);
                            // only open when inputValue is not empty after the user typed something
                            if (!value.trim()) {
                              setCountriesACOpen(false);
                            }
                          }}
                          options={countries}
                          autoHighlight
                          defaultValue={{ label: book.originCountry?.replace('_', " "), value: "" }}
                          onChange={(e: any, selectedCountry) => {
                            if (selectedCountry) {
                              const str = selectedCountry.value.replace(" ", "_")
                              setBook({
                                ...book,
                                originCountry: str,
                              })
                            }
                          }}
                          getOptionLabel={(country: any) => country.label}
                          renderOption={(country) => (
                            <>
                              {`${country.label}`}
                            </>
                          )}
                          style={{ paddingRight: '0rem' }}
                          renderInput={(params) => (
                            <TextField {...params} variant="filled" className={classes.margin} placeholder="Search a Country" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2} xl={2} className={classes.bookPriceSubItem}>
                        <Typography variant='h5' className={classes.subHeading}>AGE RANGE</Typography>
                        <FormControl variant="filled" fullWidth className={classes.margin} >
                          <Select
                            value={getAgeRangeValueOrLabel(false, book)}
                            onChange={(e: any) => {
                              setBook({
                                ...book,
                                ageRange: {
                                  lowerLimit: e.target.value.lowerLimit,
                                  upperLimit: e.target.value.upperLimit
                                }
                              })
                            }}
                            style={{ marginTop: "0rem", width: "100%", height: "3rem", fontSize: '12px' }}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null
                            }}
                          >
                            {BOOK_AGE_RANGE.map(
                              (ageRange: any, key: number) => {
                                return (
                                  <MenuItem value={ageRange.value} key={key}>
                                    {ageRange.label}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      {isEditable && (book.hasDigital || book.hasRental) ?
                        <Grid xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                          <Grid item xs={12} style={{ padding: '1rem 1rem 0rem 0.3rem' }} className={classes.bookPriceSubItem}>
                            <Typography variant='h5' className={classes.subHeading}>PAGES</Typography>
                            <NumericFormat
                              allowNegative={false}
                              value={book.digital?.pages || 0}
                              customInput={TextField}
                              decimalScale={0}
                              variant="filled"
                              className={classes.margin}
                              fullWidth
                              onValueChange={(value: NumberFormatValues) => {
                                // floatValue always gives a number or undefined
                                if (!value.floatValue) {
                                  setBook({
                                    ...book,
                                    digital: {
                                      ...book.digital,
                                      pages: 0
                                    }
                                  })
                                } else {
                                  setBook({
                                    ...book,
                                    digital: {
                                      ...book.digital,
                                      pages: value.floatValue
                                    }
                                  })
                                }
                              }}
                            />
                          </Grid>
                          {/* ISBN */}
                          <Grid item xs={12} style={{ padding: '1rem 1rem 0rem 0.3rem' }} className={classes.bookPriceSubItem}>
                            <Typography variant='h5' className={classes.subHeading}>ISBN NUMBER</Typography>
                            <TextField
                              variant="filled"
                              className={classes.margin}
                              value={book.digital?.isbn || ""}
                              fullWidth
                              onChange={(e) => {
                                setBook({
                                  ...book,
                                  digital: {
                                    ...book.digital,
                                    isbn: e.target.value
                                  }
                                })
                              }}
                            />
                          </Grid>
                        </Grid> :
                        <></>
                      }
                      <Grid item xs={12} sm={6} md={8} lg={9} xl={9} spacing={1} style={{ minHeight: 120, padding: '1rem' }} className={classes.bookPriceSubItem}>
                        <Autocomplete
                          open={keywordsACOpen}
                          onClose={() => setKeywordsACOpen(false)}
                          onInputChange={(e, value) => {
                            value = value?.trim()
                            if (value) {
                              setKeywordsACOpen(true);
                              setKeywordInputValue(value)
                            } else {
                              setKeywordsACOpen(false);
                            }
                          }}
                          multiple
                          freeSolo
                          fullWidth
                          closeIcon={true}
                          id="fixed-tags-demo"
                          options={keywordsSuggestions}
                          getOptionLabel={(option) => option}
                          value={book.keywords || []}
                          filterSelectedOptions
                          onChange={(event, newValue) => {
                            setBook({
                              ...book,
                              keywords: newValue.filter((item) => item.trim().length)
                            })
                          }}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip
                                classes={{ root: classes.tagsChip }}
                                label={option}
                                deleteIcon={<CancelIcon style={{ fontSize: 14 }} />}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              className={classes.textArea}
                              {...params}
                              label="Add Keywords"
                              placeholder="Add Keywords"
                            />
                          )}
                        />
                      </Grid>

                    </Grid>
                    : <PhysicalBook type={BookTypes.FREE} book={book} />}
                </Grid>

                {/* Book pricing section  */}
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ margin: '0rem 0rem 0rem 0rem' }}>

                  {/* working digital */}
                  {isEditable && book.url && book.contentType === 'PAID' ?
                    <>
                      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={1} style={{ marginLeft: '0rem', marginTop: '0.2rem' }}>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={book.hasDigital}
                                disabled={toggleDigitalButton()}
                                onClick={(e: any) => {
                                  setBook({
                                    ...book,
                                    hasDigital: !book.hasDigital
                                  })
                                }}

                                value="checkedSwitch"
                              />
                            }
                            label="DIGITAL"
                          />
                        </Grid>

                        {book.hasDigital && <>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                            <Typography variant='h5' className={classes.subHeading}>EBOOK PRICE</Typography>
                            <NumericFormat
                              allowNegative={false}
                              value={book.pricing?.sellPrice || 0}
                              customInput={TextField}
                              decimalScale={2}
                              variant="filled"
                              className={classes.margin}
                              InputProps={{
                                startAdornment: <InputAdornment style={{ marginTop: '0rem' }} position="start">₹</InputAdornment>
                              }}
                              fullWidth
                              onValueChange={(value: NumberFormatValues) => {
                                // floatValue always gives a number or undefined
                                if (!value.floatValue) {
                                  setBook({
                                    ...book,
                                    pricing: {
                                      ...book.pricing,
                                      sellPrice: 0
                                    }
                                  })
                                } else {
                                  setBook({
                                    ...book,
                                    pricing: {
                                      ...book.pricing,
                                      sellPrice: value.floatValue
                                    }
                                  })
                                }
                              }}
                            />
                          </Grid>
                        </>}
                      </Grid>
                    </>
                    :
                    <PhysicalBook book={book} type={BookTypes.DIGITAL} />
                  }

                  {/* working  rental */}
                  {isEditable && book.url && book.contentType === 'PAID' ?
                    <>
                      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={1} style={{ marginLeft: '0rem', marginTop: '0.2rem' }}>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={book.hasRental}
                                disabled={toggleRentalButton()}
                                onClick={(e: any) => {
                                  setBook({
                                    ...book,
                                    hasRental: !book.hasRental
                                  })
                                }}

                                value="checkedSwitch"
                              />
                            }
                            label="RENTAL"
                          />
                        </Grid>

                        {book.hasRental && <>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceMiddleSubItem}>
                            <Typography variant='h5' className={classes.subHeading}>WEEKLY PRICE</Typography>
                            <NumericFormat
                              allowNegative={false}
                              value={book.pricing?.rentalPrice?.weekly || 0}
                              customInput={TextField}
                              decimalScale={2}
                              variant="filled"
                              className={classes.margin}
                              InputProps={{
                                startAdornment: <InputAdornment style={{ marginTop: '0rem' }} position="start">₹</InputAdornment>
                              }}
                              fullWidth
                              onValueChange={(value: NumberFormatValues) => {
                                // floatValue always gives a number or undefined
                                if (!value.floatValue) {
                                  setBook({
                                    ...book,
                                    pricing: {
                                      ...book.pricing,
                                      rentalPrice: {
                                        ...book.pricing.rentalPrice,
                                        weekly: 0
                                      }
                                    }
                                  })
                                } else {
                                  setBook({
                                    ...book,
                                    pricing: {
                                      ...book.pricing,
                                      rentalPrice: {
                                        ...book.pricing.rentalPrice,
                                        weekly: value.floatValue
                                      }
                                    }
                                  })
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                            <Typography variant='h5' className={classes.subHeading}> FORTNIGHTLY PRICE</Typography>
                            <NumericFormat
                              allowNegative={false}
                              value={book.pricing?.rentalPrice?.fortNightly || 0}
                              customInput={TextField}
                              decimalScale={2}
                              variant="filled"
                              className={classes.margin}
                              InputProps={{
                                startAdornment: <InputAdornment style={{ marginTop: '0rem' }} position="start">₹</InputAdornment>
                              }}
                              fullWidth
                              onValueChange={(value: NumberFormatValues) => {
                                // floatValue always gives a number or undefined
                                if (!value.floatValue) {
                                  setBook({
                                    ...book,
                                    pricing: {
                                      ...book.pricing,
                                      rentalPrice: {
                                        ...book.pricing.rentalPrice,
                                        fortNightly: 0
                                      }
                                    }
                                  })
                                } else {
                                  setBook({
                                    ...book,
                                    pricing: {
                                      ...book.pricing,
                                      rentalPrice: {
                                        ...book.pricing.rentalPrice,
                                        fortNightly: value.floatValue
                                      }
                                    }
                                  })
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                            <Typography variant='h5' className={classes.subHeading}>MONTHLY PRICE</Typography>
                            <NumericFormat
                              allowNegative={false}
                              value={book.pricing?.rentalPrice?.monthly || 0}
                              customInput={TextField}
                              decimalScale={2}
                              variant="filled"
                              className={classes.margin}
                              InputProps={{
                                startAdornment: <InputAdornment style={{ marginTop: '0rem' }} position="start">₹</InputAdornment>
                              }}
                              fullWidth
                              onValueChange={(value: NumberFormatValues) => {
                                // floatValue always gives a number or undefined
                                if (!value.floatValue) {
                                  setBook({
                                    ...book,
                                    pricing: {
                                      ...book.pricing,
                                      rentalPrice: {
                                        ...book.pricing.rentalPrice,
                                        monthly: 0
                                      }
                                    }
                                  })
                                } else {
                                  setBook({
                                    ...book,
                                    pricing: {
                                      ...book.pricing,
                                      rentalPrice: {
                                        ...book.pricing.rentalPrice,
                                        monthly: value.floatValue
                                      }
                                    }
                                  })
                                }
                              }}
                            />
                          </Grid>
                        </>}
                      </Grid>
                    </>
                    :
                    <PhysicalBook book={book} type={BookTypes.RENTAL_DIGITAL} />
                  }

                  {/* working  audiobook */}
                  {isEditable ?
                    <>
                    </>
                    :
                    <PhysicalBook book={book} type={BookTypes.AUDIOBOOK} />
                  }

                  {/* working  paperback */}
                  {isEditable ?
                    <>
                      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={1} style={{ marginLeft: '0rem', marginTop: '0.2rem' }}>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={book.hasPaperback}
                                disabled={togglePaperbackButton()}
                                onClick={(e: any) => {
                                  setBook({
                                    ...book,
                                    hasPaperback: !book.hasPaperback
                                  })
                                }}

                                value="checkedSwitch"
                              />
                            }
                            label="PAPERBACK"
                          />
                        </Grid>

                        {book.hasPaperback && <>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                            <Typography variant='h5' className={classes.subHeading}>ISBN NUMBER</Typography>
                            <TextField
                              variant="filled"
                              className={classes.margin}
                              value={book.paperback?.isbn || ""}
                              fullWidth
                              onChange={(e) => {
                                setBook({
                                  ...book,
                                  paperback: {
                                    ...book.paperback,
                                    isbn: e.target.value
                                  }
                                })
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceMiddleSubItem}>
                            <Typography variant='h5' className={classes.subHeading}>PAGES</Typography>
                            <NumericFormat
                              allowNegative={false}
                              value={book.paperback?.pages || 0}
                              customInput={TextField}
                              decimalScale={0}
                              variant="filled"
                              className={classes.margin}
                              fullWidth
                              onValueChange={(value: NumberFormatValues) => {
                                // floatValue always gives a number or undefined
                                if (!value.floatValue) {
                                  setBook({
                                    ...book,
                                    paperback: {
                                      ...book.paperback,
                                      pages: 0
                                    }
                                  })
                                } else {
                                  setBook({
                                    ...book,
                                    paperback: {
                                      ...book.paperback,
                                      pages: value.floatValue
                                    }
                                  })
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                            <Typography variant='h5' className={classes.subHeading}> PRICE</Typography>
                            <NumericFormat
                              allowNegative={false}
                              value={book.pricing?.paperBackPrice || 0}
                              customInput={TextField}
                              decimalScale={2}
                              variant="filled"
                              className={classes.margin}
                              InputProps={{
                                startAdornment: <InputAdornment style={{ marginTop: '0rem' }} position="start">₹</InputAdornment>
                              }}
                              fullWidth
                              onValueChange={(value: NumberFormatValues) => {
                                // floatValue always gives a number or undefined
                                if (!value.floatValue) {
                                  setBook({
                                    ...book,
                                    pricing: {
                                      ...book.pricing,
                                      paperBackPrice: 0
                                    }
                                  })
                                } else {
                                  setBook({
                                    ...book,
                                    pricing: {
                                      ...book.pricing,
                                      paperBackPrice: value.floatValue
                                    }
                                  })
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                            <Typography variant='h5' className={classes.subHeading}>LENGTH</Typography>

                            <NumericFormat
                              allowNegative={false}
                              value={book?.paperback?.height?.value || 0}
                              customInput={TextField}
                              decimalScale={1}
                              variant="filled"
                              className={classes.margin}
                              fullWidth
                              InputProps={{
                                endAdornment: <InputAdornment position="end">cm</InputAdornment>
                              }}
                              onValueChange={(value: NumberFormatValues) => {
                                if (!value.floatValue) {
                                  setBook({
                                    ...book,
                                    paperback: {
                                      ...book.paperback,
                                      height: {
                                        value: 0,
                                        unit: "cm"
                                      }
                                    }
                                  })
                                } else {
                                  setBook({
                                    ...book,
                                    paperback: {
                                      ...book.paperback,
                                      height: {
                                        value: value.floatValue,
                                        unit: "cm"
                                      }
                                    }
                                  })
                                }
                              }}
                            />

                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                            <Typography variant='h5' className={classes.subHeading}>BREADTH</Typography>

                            <NumericFormat
                              allowNegative={false}
                              value={book?.paperback?.width?.value || 0}
                              customInput={TextField}
                              decimalScale={1}
                              variant="filled"
                              className={classes.margin}
                              fullWidth
                              InputProps={{
                                endAdornment: <InputAdornment position="end">cm</InputAdornment>
                              }}
                              onValueChange={(value: NumberFormatValues) => {
                                if (!value.floatValue) {
                                  setBook({
                                    ...book,
                                    paperback: {
                                      ...book.paperback,
                                      width: {
                                        value: 0,
                                        unit: "cm"
                                      }
                                    }
                                  })
                                } else {
                                  setBook({
                                    ...book,
                                    paperback: {
                                      ...book.paperback,
                                      width: {
                                        value: value.floatValue,
                                        unit: "cm"
                                      }
                                    }
                                  })
                                }
                              }}
                            />

                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                            <Typography variant='h5' className={classes.subHeading}>HEIGHT</Typography>

                            <NumericFormat
                              allowNegative={false}
                              value={book?.paperback?.depth?.value || 0}
                              customInput={TextField}
                              decimalScale={1}
                              variant="filled"
                              className={classes.margin}
                              fullWidth
                              InputProps={{
                                endAdornment: <InputAdornment position="end">cm</InputAdornment>
                              }}
                              onValueChange={(value: NumberFormatValues) => {
                                if (!value.floatValue) {
                                  setBook({
                                    ...book,
                                    paperback: {
                                      ...book.paperback,
                                      depth: {
                                        value: 0,
                                        unit: "cm"
                                      }
                                    }
                                  })
                                } else {
                                  setBook({
                                    ...book,
                                    paperback: {
                                      ...book.paperback,
                                      depth: {
                                        value: value.floatValue,
                                        unit: "cm"
                                      }
                                    }
                                  })
                                }
                              }}
                            />

                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceMiddleSubItem}>
                            <Typography variant='h5' className={classes.subHeading}>WEIGHT</Typography>

                            <TextField
                              variant="filled"
                              type="number"
                              className={classes.margin}
                              defaultValue={book.paperback?.weight?.value || 0}
                              InputProps={{
                                endAdornment: <InputAdornment position="end" style={{ marginTop: '0.3rem' }}>gm</InputAdornment>
                              }}
                              fullWidth
                              onChange={(e) => {
                                if (parseFloat(e.target.value) >= 0) {
                                  setBook({
                                    ...book,
                                    paperback: {
                                      ...book.paperback,
                                      weight: {
                                        value: parseFloat(e.target.value),
                                        unit: "grams"
                                      }
                                    }
                                  })
                                }
                              }}
                            />
                          </Grid>
                        </>}
                      </Grid>
                    </>
                    :
                    <PhysicalBook book={book} type={BookTypes.PAPERBACK} />
                  }
                  {/* working  hardbound */}
                  {isEditable ?
                    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={1} style={{ marginLeft: '0rem', marginTop: '0.2rem' }}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={book.hasHardbound}
                              disabled={toggleHardboundButton()}
                              onClick={(e: any) => {
                                setBook({
                                  ...book,
                                  hasHardbound: !book.hasHardbound
                                })
                              }}
                              value="checkedSwitch"
                            />
                          }
                          label="HARDBOUND"
                        />
                      </Grid>

                      {book.hasHardbound && <>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem} >
                          <Typography variant='h5' className={classes.subHeading}>ISBN NUMBER</Typography>
                          <TextField
                            variant="filled"
                            className={classes.margin}
                            value={book.hardbound?.isbn || ""}
                            fullWidth
                            onChange={(e) => {
                              setBook({
                                ...book,
                                hardbound: {
                                  ...book.hardbound,
                                  isbn: e.target.value
                                }
                              })
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceMiddleSubItem}>
                          <Typography variant='h5' className={classes.subHeading}>PAGES</Typography>
                          <NumericFormat
                            allowNegative={false}
                            value={book.hardbound?.pages || 0}
                            customInput={TextField}
                            decimalScale={0}
                            variant="filled"
                            className={classes.margin}
                            fullWidth
                            onValueChange={(value: NumberFormatValues) => {
                              // floatValue always gives a number or undefined
                              if (!value.floatValue) {
                                setBook({
                                  ...book,
                                  hardbound: {
                                    ...book.hardbound,
                                    pages: 0
                                  }
                                })
                              } else {
                                setBook({
                                  ...book,
                                  hardbound: {
                                    ...book.hardbound,
                                    pages: value.floatValue
                                  }
                                })
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                          <Typography variant='h5' className={classes.subHeading}> PRICE</Typography>
                          <NumericFormat
                            allowNegative={false}
                            value={book.pricing?.hardboundPrice || 0}
                            customInput={TextField}
                            decimalScale={2}
                            variant="filled"
                            className={classes.margin}
                            InputProps={{
                              startAdornment: <InputAdornment style={{ marginTop: '0rem' }} position="start">₹</InputAdornment>
                            }}
                            fullWidth
                            onValueChange={(value: NumberFormatValues) => {
                              // floatValue always gives a number or undefined
                              if (!value.floatValue) {
                                setBook({
                                  ...book,
                                  pricing: {
                                    ...book.pricing,
                                    hardboundPrice: 0
                                  }
                                })
                              } else {
                                setBook({
                                  ...book,
                                  pricing: {
                                    ...book.pricing,
                                    hardboundPrice: value.floatValue
                                  }
                                })
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                          <Typography variant='h5' className={classes.subHeading}>LENGTH</Typography>

                          <NumericFormat
                            allowNegative={false}
                            value={book?.hardbound?.height?.value || 0}
                            customInput={TextField}
                            decimalScale={1}
                            variant="filled"
                            className={classes.margin}
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">cm</InputAdornment>
                            }}
                            onValueChange={(value: NumberFormatValues) => {
                              if (!value.floatValue) {
                                setBook({
                                  ...book,
                                  hardbound: {
                                    ...book.hardbound,
                                    height: {
                                      value: 0,
                                      unit: "cm"
                                    }
                                  }
                                })
                              } else {
                                setBook({
                                  ...book,
                                  hardbound: {
                                    ...book.hardbound,
                                    height: {
                                      value: value.floatValue,
                                      unit: "cm"
                                    }
                                  }
                                })
                              }
                            }}
                          />

                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                          <Typography variant='h5' className={classes.subHeading}>BREADTH</Typography>

                          <NumericFormat
                            allowNegative={false}
                            value={book?.hardbound?.width?.value || 0}
                            customInput={TextField}
                            decimalScale={1}
                            variant="filled"
                            className={classes.margin}
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">cm</InputAdornment>
                            }}
                            onValueChange={(value: NumberFormatValues) => {
                              if (!value.floatValue) {
                                setBook({
                                  ...book,
                                  hardbound: {
                                    ...book.hardbound,
                                    width: {
                                      value: 0,
                                      unit: "cm"
                                    }
                                  }
                                })
                              } else {
                                setBook({
                                  ...book,
                                  hardbound: {
                                    ...book.hardbound,
                                    width: {
                                      value: value.floatValue,
                                      unit: "cm"
                                    }
                                  }
                                })
                              }
                            }}
                          />

                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceSubItem}>
                          <Typography variant='h5' className={classes.subHeading}>HEIGHT</Typography>

                          <NumericFormat
                            allowNegative={false}
                            value={book?.hardbound?.depth?.value || 0}
                            customInput={TextField}
                            decimalScale={1}
                            variant="filled"
                            className={classes.margin}
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">cm</InputAdornment>
                            }}
                            onValueChange={(value: NumberFormatValues) => {
                              if (!value.floatValue) {
                                setBook({
                                  ...book,
                                  hardbound: {
                                    ...book.hardbound,
                                    depth: {
                                      value: 0,
                                      unit: "cm"
                                    }
                                  }
                                })
                              } else {
                                setBook({
                                  ...book,
                                  hardbound: {
                                    ...book.hardbound,
                                    depth: {
                                      value: value.floatValue,
                                      unit: "cm"
                                    }
                                  }
                                })
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} className={classes.bookPriceMiddleSubItem}>
                          <Typography variant='h5' className={classes.subHeading}>WEIGHT</Typography>
                          <TextField
                            variant="filled"
                            type="number"
                            className={classes.margin}
                            defaultValue={book.hardbound?.weight?.value || 0}
                            InputProps={{
                              endAdornment: <InputAdornment position="end" style={{ marginTop: '0.3rem' }}>gm</InputAdornment>
                            }}
                            fullWidth
                            onChange={(e) => {
                              if (parseFloat(e.target.value) >= 0) {
                                setBook({
                                  ...book,
                                  hardbound: {
                                    ...book.hardbound,
                                    weight: {
                                      value: parseFloat(e.target.value),
                                      unit: "grams"
                                    }
                                  }
                                })
                              }
                            }}
                          />
                        </Grid>
                      </>}

                    </Grid>
                    :
                    <PhysicalBook book={book} type={BookTypes.HARDBOUND} />
                  }
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* Author section  */}
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} direction='column' spacing={3} style={{ paddingRight: "2rem", justifyContent: 'flex-end' }}>
          {/* Author part  */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: '24rem' }} >
            <Paper elevation={1} className={classes.paper} style={{ width: "100%", height: '19rem', padding: "1rem", marginRight: '0.7rem' }}>
              {/* heading */}
              <Grid container spacing={2} xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '0.8rem', paddingLeft: '0.7rem' }}>
                <Grid container item xs={7} sm={7} md={8} lg={8} xl={8}>
                  <Typography variant='h3' className={classes.title}> Author(s) Details</Typography>
                </Grid>
                <Grid container item xs={5} sm={5} md={4} lg={4} xl={4} style={{ paddingRight: '0px' }} justify="flex-end" >
                  {isEditableAuthor ?
                    <>
                      <Typography variant="h4" className={classes.saveButton}
                        onClick={() => updateAuthors(bookAuthors)}
                      >
                        Save
                      </Typography>
                      <Typography variant="h4" className={classes.cancelButton}
                        onClick={() => { return (setIsEditableAuthor(false), setBookAuthors(oldBook.authors)) }}>
                        Cancel
                      </Typography>
                    </>
                    :
                    <Typography variant="h4" className={classes.editButton}
                      onClick={() => setIsEditableAuthor(true)}>
                      Edit
                    </Typography>}
                </Grid>
              </Grid>
              {/* content */}
              {isEditableAuthor ?
                <Grid container spacing={2} xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '0.8rem', paddingLeft: '0.7rem', }}>
                  <Grid container justify="center" xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingLeft: '1.5rem' }}>
                    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Autocomplete
                        open={authorACOpen}
                        onClose={() => setAuthorACOpen(false)}
                        onInputChange={(e, value) => {
                          if (value.trim()) {
                            setAuthorACOpen(true);
                          } else {
                            setAuthorACOpen(false);
                          }
                        }}
                        multiple
                        options={authors} // options provided to autocomplete
                        value={bookAuthors} // values selected in chips
                        getOptionLabel={(author: any) => author.name} // options to show
                        disableClearable
                        disableCloseOnSelect
                        filterSelectedOptions
                        onChange={(e, selectedAuthors: any) => {
                          const uniqueArr = _.unionBy(bookAuthors, selectedAuthors, "id")
                          setBookAuthors(uniqueArr)
                        }}
                        renderTags={(tagValues) => {
                          return tagValues.map((option) =>
                          (
                            <Chip
                              label={option.name}
                              deleteIcon={<CancelIcon style={{ fontSize: 14 }} />}
                              onDelete={(e) => {
                                const newAuthorArr = bookAuthors.filter(author => author.id !== option.id)
                                setBookAuthors(newAuthorArr)
                              }}
                            />
                          ))
                        }
                        }
                        style={{ width: 500 }}
                        renderOption={(author) => author.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={classes.textArea}
                            variant="outlined"
                            label="Authors"
                            placeholder="Search for Authors"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                :
                <Author
                  authors={bookAuthors}
                  authorNumber={currentAuthorIndex}
                  bookId={book.id}
                  publisherId={book.publisher}
                  handleHideNavigation={() => setHideNavigation(!hideNavigation)}
                  fetchBook={getBookData}
                />
              }
              {/* navi  gation and button  */}
              <Grid container xs={12} sm={12} md={12} lg={12} xl={12} style={{ fontSize: '13px', margin: isSmallScreen ? '0rem 1rem 1rem 0rem' : '0rem 1rem 1rem 0rem', paddingLeft: isSmallScreen ? '0rem' : '2rem', alignItems: 'center', justifyContent: 'center' }}>
                {isEditableAuthor || hideNavigation ?
                  <></>
                  :
                  <>
                    {
                      bookAuthors?.length <= 0 ? <></> :
                        <>
                          <Button onClick={goLeft} disabled={currentAuthorIndex === 0} className={classes.navButton}> <ChevronLeftIcon /> </Button>
                          {`Author ${currentAuthorIndex + 1} of ${bookAuthors.length}`}
                          <Button onClick={goRight} disabled={currentAuthorIndex === bookAuthors.length - 1} className={classes.navButton}><ChevronRightIcon /></Button>
                        </>}
                  </>
                }
              </Grid>
            </Paper>
          </Grid>
          {/* Sample image preview */}
          <Paper elevation={2} className={classes.paper} style={{ width: "100%", height: '18rem', padding: "1rem", marginRight: '0.7rem', marginBottom: "3rem" }}>
            <Grid container style={{ height: "15rem" }} >
              <Grid xs={12} container style={{ height: "15rem" }} >
                <LookInside
                  bookId={book.id} />
              </Grid>
            </Grid>
          </Paper>
          {/* Plan and status part  */}
          <Grid item spacing={2} >
            <Grid container xs={12} spacing={0} >
              {/* plan part  */}
              <Grid item xs={6} style={{ paddingRight: '2rem' }} >
                <Paper elevation={1} className={classes.paper} style={{ width: "100%", padding: '1rem', marginRight: '0.7rem' }}>
                  {/* heading */}
                  <Grid container spacing={2} xs={12} style={{ paddingTop: '0.8rem', paddingLeft: '0.7rem' }}>
                    <Grid container xs={6}>
                      <Typography variant='h3' className={classes.title}>Book Type</Typography>
                    </Grid>
                    <Grid container xs={6} style={{ paddingRight: '0px' }} justify="flex-end" >
                      {isEditableContentType ?
                        <>
                          <Typography variant="h4" className={classes.saveButton}
                            onClick={() => setOpenConfirmationDialog(true)}
                          >
                            Save
                          </Typography>
                          <Typography variant="h4" className={classes.cancelButton}
                            onClick={() => { return (setIsEditableContentType(false), setBook(oldBook)) }}>
                            Cancel
                          </Typography>
                        </>
                        :
                        !book.hasDigital ? <></>
                          :
                          <Typography variant="h4" className={classes.editButton}
                            onClick={() => setIsEditableContentType(true)}>
                            Edit
                          </Typography>}
                    </Grid>
                  </Grid>
                  {/* content */}
                  <Grid container spacing={2} xs={12} style={{ padding: '0.5rem' }}>
                    <Grid item xs={12}>
                      {isEditableContentType ?
                        <FormControl variant="filled" style={{ paddingTop: '0rem' }} fullWidth className={classes.margin} >
                          <Select
                            value={book?.contentType}
                            onChange={(e) => {
                              if (e.target.value && typeof e.target.value === "string") {
                                setBook({
                                  ...book,
                                  contentType: e.target.value,
                                })
                                if (e.target.value === BookContentTypes.PAID && book.hasDigital && checkPriceTrue()) {
                                  setIsEditable(true)
                                }
                              }
                            }}
                            style={{ marginTop: "0rem", width: "95%", height: "3rem" }}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null
                            }}
                          >
                            {BOOK_CONTENT_TYPE.map(
                              (bookType: any, key: number) => {
                                return (
                                  <MenuItem value={bookType.value} key={key}>
                                    {bookType.label}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </FormControl>
                        :
                        <Typography variant='h4' className={classes.subContent}>{book?.contentType?.toUpperCase()}</Typography>
                      }
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {/* status part  */}
              <Grid item xs={6} style={{ paddingLeft: '2rem' }}>
                <Paper elevation={1} className={classes.paper} style={{ width: "100%", padding: "1rem", marginRight: '1rem' }}>
                  {/* heading */}
                  <Grid container spacing={2} xs={12} style={{ paddingTop: '0.8rem', paddingLeft: '0.7rem' }}>
                    <Grid container xs={5}>
                      <Typography variant='h3' className={classes.title}>Status</Typography>
                    </Grid>
                    <Grid container xs={7} style={{ paddingRight: '0px' }} justify="flex-end" >
                      {isEditableStatus ?
                        <>
                          <Typography variant="h4" className={classes.saveButton}
                            onClick={() => setOpenConfirmationDialog(true)}
                          >
                            Save
                          </Typography>
                          <Typography variant="h4" className={classes.cancelButton}
                            onClick={() => { return (setIsEditableStatus(false), setBook(oldBook)) }}>
                            Cancel
                          </Typography>
                        </>
                        :
                        <Typography variant="h4" className={classes.editButton}
                          onClick={() => setIsEditableStatus(true)}>
                          Edit
                        </Typography>}
                    </Grid>
                  </Grid>
                  {/* content */}
                  <Grid container spacing={2} xs={12} style={{ padding: '0.5rem' }}>
                    <Grid item xs={12}>
                      {isEditableStatus ?
                        <FormControl variant="filled" style={{ paddingTop: '0rem' }} fullWidth className={classes.margin} >
                          <Select
                            value={book.isActivated}
                            onChange={(e) => {
                              if (typeof e.target.value === "boolean") {
                                setBook({
                                  ...book,
                                  isActivated: e.target.value
                                })
                              }
                            }}
                            style={{ marginTop: "0rem", width: "95%", height: "3rem" }}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null
                            }}
                          >
                            {BOOK_STATUS.map(
                              (bookStatus: any, key: number) => {
                                return (
                                  <MenuItem value={bookStatus.value} key={key}>
                                    {bookStatus.label}
                                  </MenuItem>
                                );
                              }
                            )}

                          </Select>
                        </FormControl>
                        :
                        <Typography variant='h4' className={classes.subContent}>{bookStatus(book.isActivated)}</Typography>
                      }
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
      <div>
        <UploadEbook
          open={uploadEbookModalOpen}
          handleClose={() => setUploadEbookModalOpen(false)}
          uploadDigitalBook={uploadEBook}
        />
      </div>
      <div>
        <UploadAudiobook
          open={uploadAudioBookModalOpen}
          handleClose={() => setUploadAudioBookModalOpen(false)}
          book={book}
        // uploadAudioBook={uploadEBook}
        />
      </div>
      <div>
        <ConfirmationDialog
          isOpen={openConfirmationDialog}
          onCancel={() => setOpenConfirmationDialog(false)}
          onConfirm={() => {
            if (isEditableStatus) {
              return updateBookStatus(book.isActivated)
            }
            if (isEditableContentType) {
              return updateBook(book, false)
            }
          }}
          title={isEditableStatus ? "Confirm Book Status" : "Confirm Book Type"}
          description={`Are you sure, you want to change the Book's ${isEditableStatus ? "Status" : "Type"}?`}
        />
      </div>
    </Grid >
  )
}

export default BookDetail;