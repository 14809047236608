import { Backdrop, Box, CircularProgress, Fab, Grid, IconButton, LinearProgress, Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateMobileUser } from "../../../../reducers/mobileUser/reducer";
import { ICartItem, IOrderItem, OrderPlatformType, OrderType } from "../../../../reducers/orders/types";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { HTTP_METHODS, OrderItemTypes } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { roundToTwo } from "../../../../utils/utils";
import CircularLoader from "../../../common/CircularLoader";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import theme from "../../../common/Theme";
import { IOrderInput } from "../../Dashboard/DigitalBookDetail/DigitalBookDetail";
import CartItemCard from "./CartItemCard";
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CartBookImage from "./CartBookImage";
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import ConfettiExplosion from 'react-confetti-explosion';


interface ICartRes {
  cartItems: ICartItem[]
  cartSubTotal: number
  cartTotalSaved: number;
  cartTotalItemQuantity: number
  errorMessage: string;
  globalOffer: string;
  globalOfferApplicable: false
  globalOfferApplied: false
  globalOfferDiscountOff: number
  orderValueRemaining: number
  progressBarPercentage: number,
  globalOfferName: string
  removedItems: ICartItem[]
}
interface ISaveForLaterRes extends Omit<ICartRes, "cartItems"> {
  saveForLaterItems: ICartItem[]
}

interface IProps {
  open: boolean
}


const MobileUserCart: React.FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const [cartRes, setCartRes] = useState<ICartRes>();
  const [saveForLaterRes, setSaveForLaterRes] = useState<ISaveForLaterRes>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [showBackdropLoader, setShowBackdropLoader] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [cartLoading, setCartLoading] = useState<boolean>(true);



  useEffect(() => {
    if (mobileUserState && mobileUserState.id) {
      fetchUserCart(mobileUserState.id)
      fetchUserSaveForLater(mobileUserState.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState.id]);


  const offerPriceIsPresentAndNotSameAsSellingPrice = (newOfferedPrice: number | any, mrpPrice: number) => newOfferedPrice > 0 && (newOfferedPrice !== mrpPrice)


  const fetchUserCart = async (userId: string) => {
    const res = await callApi(PATHNAMES.GET_MOBILE_USER_CART(userId), HTTP_METHODS.GET);
    if (res && res.success && res.data) {
      setCartRes(res.data);
      dispatch(updateMobileUser({ ...mobileUserState, cartQuantity: res.data.cartTotalItemQuantity }));
      setCartLoading(false)
      if (res.data.errorMessage) {
        snackbar({
          message: res.data.errorMessage,
          variant: SnackBarVariant.WARNING
        })
      }
    }
  }


  const fetchUserSaveForLater = async (userId: string) => {
    const res = await callApi(PATHNAMES.GET_MOBILE_USER_SAVE_FOR_LATER(userId), HTTP_METHODS.GET);
    if (res && res.success && res.data) {
      setSaveForLaterRes(res.data);
      if (res.data.errorMessage) {
        snackbar({
          message: res.data.errorMessage,
          variant: SnackBarVariant.WARNING
        })
      }
    }
  }


  const deleteCartOrSaveForLaterItem = async (itemId: string, type: string) => {
    const res = await callApi(PATHNAMES.DELETE_CART_ITEM(itemId), HTTP_METHODS.DELETE);
    if (res && res.success) {
      if (type === 'cart') {
        fetchUserCart(mobileUserState.id)
      } else (
        fetchUserSaveForLater(mobileUserState.id)
      )
    }
  }

  const moveItemToCartOrSaveForLaterList = async (itemId: string) => {
    const res = await callApi(PATHNAMES.MOVE_CART_ITEM_TO_CART_SAVE_FOR_LATER(itemId), HTTP_METHODS.PATCH);
    if (res && res.success) {
      setShowBackdropLoader(true)
      await Promise.all([
        fetchUserCart(mobileUserState.id),
        fetchUserSaveForLater(mobileUserState.id)
      ])
      setTimeout(() => setShowBackdropLoader(false), 1000)
      snackbar({
        message: res.message,
        variant: SnackBarVariant.SUCCESS
      })
    } else {
      snackbar({
        message: res.message,
        variant: SnackBarVariant.ERROR
      })
    }
  }


  const updateQuantity = async (itemId: string, type: "increment" | "decrement") => {
    const res = await callApi(PATHNAMES.UPDATE_CART_ITEM_QUANTITY(itemId, type), HTTP_METHODS.PATCH);
    if (res && res.success) {
      fetchUserCart(mobileUserState.id)
    } else {
      snackbar({
        message: res.message,
        variant: SnackBarVariant.ERROR
      })
    }
  };


  const createOrder = async () => {

    const orderItems = cartRes?.cartItems?.map(item => {
      return {
        unitPrice: item.unitPrice,
        subTotal: item.subTotal,
        orderType: item.bookType,
        quantity: item.quantity,
        offerId: item.offerId,
        bookId: item.bookId,
        publisherId: item.publisherId,
        isReturnable: item.isReturnable
      }
    }) ?? []
    const orderData: IOrderInput = {
      orderItems,
      orderType: OrderType.CART,
      platform: OrderPlatformType.WEBAPP,
      billingAddress: mobileUserState.defaultBillingAddress || "",
      shippingAddress: mobileUserState.defaultShippingAddress || ""
    }
    setOrderLoading(true)
    const res = await callApi(PATHNAMES.CREATE_PORTAL_ORDER, HTTP_METHODS.POST, {
      body: JSON.stringify({ data: orderData })
    })
    if (res && res.success && res.data) {
      setOrderLoading(false)
      navigate(`/portal/orderSummary?orderId=${res.data.id}`)
    }
  }

  const disbaleProceedToBuy = () => orderLoading || cartRes?.cartItems.length === 0 ? true : false


  if (cartLoading) {
    return (<CircularLoader message="wait, we are loading your cart..." />)
  }


  const BackDropLoader: React.FC<IProps> = (props) => {
    return <Backdrop style={{
      zIndex: 1000,
      color: '#fff',
    }} open={props.open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  }



  return (
    <Grid container xs={12} justify="center" style={{ position: "relative", backgroundColor: "#F5F6F8", margin: "0rem", padding: '0rem' }}>
      <Grid container item xs={10} style={{ margin: "2.5rem 2rem" }}>
        <Typography variant="h1" style={{ fontWeight: 800 }}>YOUR CART</Typography>


        {/* checkout btn */}
        <Grid container xs={12}
          justifyContent="space-between"
          alignItems="center"
          style={{
            borderRadius: "1rem",
            backgroundColor: "#FFF",
            margin: "2rem 0rem 2.5rem 0rem",
            padding: "2rem",
            position: 'sticky',
            top: 55,
            zIndex: 10,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            transition: "all 0.3s ease"
          }}
        >
          <Grid container xs={12} md={7}>
            <Grid item xs={12}>
              <Typography variant="h2" style={{ lineHeight: "2rem", fontWeight: 700 }}>{`Subtotal (${cartRes?.cartTotalItemQuantity} Items) : ₹ ${cartRes?.cartSubTotal}`}</Typography>
            </Grid>
            {
              cartRes && cartRes.cartTotalSaved > 0 &&
              <Grid item xs={12}>
                <Typography variant="h3" color="primary" style={{ lineHeight: "2rem", fontWeight: 500 }}>{`You saving ₹ ${cartRes?.cartTotalSaved}`}</Typography>
              </Grid>
            }
          </Grid>
          <Grid container xs={12} md={3} justifyContent="flex-end">
            <Fab
              variant="extended"
              size="medium"
              onClick={createOrder}
              disabled={disbaleProceedToBuy()}
            >
              {orderLoading ? <CircularProgress size={14} /> : "Proceed To Buy"}
            </Fab>
          </Grid>
          <Grid container xs={12} style={{ marginTop: "3rem" }}>
            {
              cartRes && cartRes.globalOfferApplicable &&
              <>
                <div style={{ width: "100%", }}>
                  <LinearProgress style={{ height: "0.6rem", borderRadius: "5px" }} variant="determinate" value={(cartRes?.progressBarPercentage || 8) * 100} />
                </div>
                <Typography variant="h3" style={{ fontWeight: 400, marginTop: "1rem" }}>
                  {cartRes.globalOfferApplied ? (
                    <>
                      Woohoo !! Congratulations you have unlocked{" "}
                      <span style={{ color: "#FF5C3E", fontWeight: "600" }}>
                        {cartRes?.globalOfferName ? cartRes.globalOfferName : "Rachnaye Global Offer"}
                      </span>
                    </>
                  ) : (
                    <>
                      You are eligible for{" "}
                      <span style={{ color: "#FF5C3E", fontWeight: "600" }}>
                        {cartRes?.globalOfferName ? cartRes.globalOfferName : "Rachnaye Global Offer"}
                      </span>, shop for <span style={{ fontWeight: 'bold',color: "#FF5C3E" }}>₹{cartRes.orderValueRemaining}</span> more to avail the discount
                    </>)
                  }
                </Typography>

              </>

            }
          </Grid>
        </Grid>


        {/* border and title */}
        <Box borderBottom={1} m={1} style={{ width: "100%", borderBottom: "2px solid grey" }} />
        <Grid item xs={12} style={{ padding: "2.5rem 2rem 2rem 2rem" }}>
          <Typography variant="h2" style={{ fontWeight: 600, fontSize: "30px" }}>{`Items (${cartRes?.cartTotalItemQuantity})`}</Typography>
        </Grid>


        {/* shopping cart */}

        {cartRes?.cartItems?.length === 0 ?
          <Grid container xs={12}
            justifyContent="center"
            alignItems="center"
            style={{
              borderRadius: "1rem",
              backgroundColor: "#FFF",
              margin: "1rem 0rem",
              padding: "0rem 2rem",
              minHeight: "8rem"
            }}
          >
            <Typography variant="h2" style={{ fontWeight: 400, }}>There are no items in cart.</Typography>
          </Grid>

          : cartRes?.cartItems?.map((item: ICartItem) => {
            return <Grid key={item.id} container xs={12}
              justifyContent="space-between"
              style={{ borderRadius: "1rem", backgroundColor: "#FFF", margin: "1rem 0rem", padding: "0rem 2rem" }}
            >

              {/* img */}
              <Grid style={{ padding: "2rem 0rem" }} container item xs={12} md={2} >
                <CartBookImage
                  bookName={item.bookName}
                  imageUrl={item.imageUrl}
                  bookId={item.bookId}
                  preOrderAvailable={item.preOrderAvailable}
                  rachnayeEssential={item.rachnayeEssential}
                  rachnayeFullfilled={item.rachnayeFulfilled} />
              </Grid>
              {/* content */}
              <Grid style={{ padding: "2rem 0rem" }} container justifyContent="space-between" xs={12} md={4}>

                {/* title and types */}
                <Grid container xs={12}>
                  {/* Title */}
                  <Grid item xs={12}>
                    <Typography variant="h2" style={{ fontWeight: 600, marginBottom: "0.6rem" }}>{item.bookName}</Typography>
                  </Grid>


                  {/* Book type and instock */}
                  <Grid container xs={12}>
                    <Grid item xs={12}>
                      <div style={{ padding: "0.3rem", borderRadius: "5px", marginBottom: "0.6rem", backgroundColor: "#E57373", display: 'inline-block' }}>
                        <Typography variant="h3" style={{ color: "#fff", fontWeight: 400 }}>{item.bookType.toUpperCase()}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      {item.booksAvailable > 0 ?
                        <div style={{ padding: "0.3rem", borderRadius: "5px", marginBottom: "0.6rem", backgroundColor: "#39d688", display: 'inline-block' }}>
                          <Typography variant="h3" style={{ color: "#fff", fontWeight: 400 }}>{"In Stock"}</Typography>
                        </div>
                        :
                        <Typography variant="h3" style={{ color: "red", fontWeight: 400 }}>{"Out of stock"}</Typography>
                      }
                    </Grid>
                  </Grid>
                </Grid>

                {/*  price*/}
                <Grid container xs={12}>
                  {offerPriceIsPresentAndNotSameAsSellingPrice(item.unitOfferPrice, item.unitPrice) ?
                    <Grid container xs={'auto'}>
                      <Grid item xs={'auto'} justify='flex-end' style={{ paddingRight: '1rem' }}>  <Typography variant="h2" style={{ textDecoration: "line-through" }} >{`₹ ${item.unitPrice}`}</Typography></Grid>
                      <Grid item xs={'auto'}>  <Typography variant="h2" style={{ display: "inline", fontWeight: 600 }}>{`₹ ${item.unitOfferPrice}`}</Typography></Grid>
                    </Grid> :
                    <Grid item xs={"auto"}>  <Typography variant="h2" style={{ fontWeight: 600 }}>{`₹ ${item.unitPrice}`}</Typography></Grid>
                  }
                </Grid>
              </Grid>


              {/* subtotal */}
              <Grid style={{ padding: "2rem 0rem" }} item xs={2} >
                <Typography variant="h2" style={{ fontWeight: 400, paddingBottom: "0.6rem" }}>{`Item Subtotal`}</Typography>
                <Typography variant="h2" style={{ fontWeight: 600 }}>{`₹ ${item.subTotal}`}</Typography>
              </Grid>


              {/* counter and delete & save for later btns */}
              <Grid style={{ padding: "2rem 0rem" }} container xs={3} justifyContent="flex-end" alignContent="center" alignItems="center" >

                <Grid item xs={8}>
                  <Grid container xs={"auto"} alignItems="center" justifyContent="flex-end">
                    <div style={{ display: "flex", alignItems: "center", border: "1px solid #FF5C3E", borderRadius: "10px" }}>
                      <IconButton title="Decrease Quantity" style={{ margin: "0.6rem 1rem" }} onClick={() => updateQuantity(item.id, "decrement")}>
                        <RemoveIcon />
                      </IconButton>
                      <Typography color='primary' variant="h2" style={{ fontWeight: 600 }}>{item.quantity}</Typography>
                      <IconButton title="Increase Quantity" style={{ margin: "0.6rem 1rem" }} onClick={() => updateQuantity(item.id, "increment")}>
                        <AddIcon />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>

                <Grid container xs={7} style={{ height: '3rem', margin: "1rem", marginTop: "2rem" }} justifyContent="flex-end">
                  {/* delete */}
                  <IconButton title={`Remove this item`} style={{ height: "auto", width: "auto" }} onClick={() => deleteCartOrSaveForLaterItem(item.id, item.type)}>
                    <DeleteIcon />
                  </IconButton>

                  <Box borderRight={1} m={1} style={{ height: "90%", borderRight: "1px solid grey" }} />


                  {/* save for later */}
                  <IconButton title="Move to save for later" style={{ height: "auto", width: "auto" }} onClick={() => moveItemToCartOrSaveForLaterList(item.id)}>
                    <BookmarksOutlinedIcon />
                  </IconButton>
                </Grid>

              </Grid>



            </Grid>
          })}




        {/* save for later */}
        {saveForLaterRes && saveForLaterRes.saveForLaterItems?.length > 0 &&
          <>
            <Box borderBottom={1} m={1} style={{ width: "100%", margin: "2rem 0rem", borderBottom: "2px solid grey" }} />
            <Grid container xs={12}>
              <Grid item xs={12} style={{ margin: "2.5rem 0rem" }}>
                <Typography variant="h2" style={{ fontWeight: 600, fontSize: "30px" }}>Save for Later</Typography>
              </Grid>

              {saveForLaterRes?.saveForLaterItems?.map((item: ICartItem) => {
                return <Grid key={item.id} container xs={12}
                  justifyContent="space-between"
                  style={{ borderRadius: "1rem", backgroundColor: "#FFF", margin: "1rem 0rem", padding: "0rem 2rem" }}
                >

                  {/* img */}
                  <Grid style={{ padding: "2rem 0rem" }} container item xs={12} md={2} >
                    <CartBookImage
                      bookName={item.bookName}
                      imageUrl={item.imageUrl}
                      bookId={item.bookId}
                      preOrderAvailable={item.preOrderAvailable}
                      rachnayeEssential={item.rachnayeEssential}
                      rachnayeFullfilled={item.rachnayeFulfilled} />
                  </Grid>
                  {/* content */}
                  <Grid style={{ padding: "2rem 0rem" }} container justifyContent="space-between" xs={12} md={4}>

                    {/* title and types */}
                    <Grid container xs={12}>
                      {/* Title */}
                      <Grid item xs={12}>
                        <Typography variant="h2" style={{ fontWeight: 600, marginBottom: "0.6rem" }}>{item.bookName}</Typography>
                      </Grid>


                      {/* Book type and instock */}
                      <Grid container xs={12}>
                        <Grid item xs={12}>
                          <div style={{ padding: "0.3rem", borderRadius: "5px", marginBottom: "0.6rem", backgroundColor: "#E57373", display: 'inline-block' }}>
                            <Typography variant="h3" style={{ color: "#fff", fontWeight: 400 }}>{item.bookType.toUpperCase()}</Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          {item.booksAvailable > 0 ?
                            <div style={{ padding: "0.3rem", borderRadius: "5px", marginBottom: "0.6rem", backgroundColor: "#39d688", display: 'inline-block' }}>
                              <Typography variant="h3" style={{ color: "#fff", fontWeight: 400 }}>{"In Stock"}</Typography>
                            </div>
                            :
                            <Typography variant="h3" style={{ color: "red", fontWeight: 400 }}>{"Out of stock"}</Typography>
                          }
                        </Grid>
                      </Grid>
                    </Grid>

                    {/*  price*/}
                    <Grid container xs={12}>
                      {offerPriceIsPresentAndNotSameAsSellingPrice(item.unitOfferPrice, item.unitPrice) ?
                        <Grid container xs={'auto'}>
                          <Grid item xs={'auto'} justify='flex-end' style={{ paddingRight: '1rem' }}>  <Typography variant="h2" style={{ textDecoration: "line-through" }} >{`₹ ${item.unitPrice}`}</Typography></Grid>
                          <Grid item xs={'auto'}>  <Typography variant="h2" style={{ display: "inline", fontWeight: 600 }}>{`₹ ${item.unitOfferPrice}`}</Typography></Grid>
                        </Grid> :
                        <Grid item xs={"auto"}>  <Typography variant="h2" style={{ fontWeight: 600 }}>{`₹ ${item.unitPrice}`}</Typography></Grid>
                      }
                    </Grid>
                  </Grid>


                  {/* subtotal */}
                  <Grid style={{ padding: "2rem 0rem" }} item xs={2} >
                    <Typography variant="h2" style={{ fontWeight: 400, paddingBottom: "0.6rem" }}>{`Item Subtotal`}</Typography>
                    <Typography variant="h2" style={{ fontWeight: 600 }}>{`₹ ${item.subTotal}`}</Typography>
                  </Grid>


                  {/* counter and delete & save for later btns */}
                  <Grid style={{ padding: "2rem 0rem" }} container xs={3} justifyContent="flex-end" alignContent="center" alignItems="center" >

                    <Grid container xs={7} style={{ height: '3rem', margin: "1rem", marginTop: "2rem" }} justifyContent="flex-end">
                      {/* delete */}
                      <IconButton title={`Remove this item`} style={{ height: "auto", width: "auto" }} onClick={() => deleteCartOrSaveForLaterItem(item.id, item.type)}>
                        <DeleteIcon />
                      </IconButton>

                      <Box borderRight={1} m={1} style={{ height: "90%", borderRight: "1px solid grey" }} />


                      {/* save for later */}
                      <IconButton title="Move to Cart" style={{ height: "auto", width: "auto" }} onClick={() => moveItemToCartOrSaveForLaterList(item.id)}>
                        <AddShoppingCartOutlinedIcon />
                      </IconButton>
                    </Grid>

                  </Grid>

                </Grid>
              })}

            </Grid>
          </>
        }

      </Grid>
      <BackDropLoader open={showBackdropLoader} />
      {cartRes?.globalOfferApplied && <div style={{
        position: "absolute",
        width: "100%",
        top: 0,
        left: "50%",
        zIndex: 20
      }

      }>
        <ConfettiExplosion
          force={0.8}
          duration={3000}
          particleCount={400}
          width={5000}
          zIndex={20}
        /></div>}
    </Grid >
  )
}






export default MobileUserCart;