
import {
  Button,
  Fab,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-material-ui";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateIamUser } from "../../../reducers/user/reducer";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS, USER_TYPES, DEFAULT_COUNTRY_CODE } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";


const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      marginTop: "20vh",
      maxWidth: "25%",
      alignContent: "center",
      justifyContent: "center",
    },
    formItem: {
      minWidth: "20rem",
      marginBottom: "1rem",
    },
    submitBtn: {
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
      minWidth: "20rem",
      marginTop: '1rem'
    },
    redirectText: {
      marginTop: "1rem",
      justifyContent: "center",
      color: "#005D68",
      marginBottom: "1rem",
    },
    redirectLink: {
      display: "inline",
      cursor: "pointer",
      color: theme.palette.primary.main,
      marginLeft: "0.5em",
    },
    mainText: {
      fontWeight: 600,
      fontSize: 36,
      color: theme.palette.primary.main,
      marginBottom: "08%",
    },
    secondaryText: {
      fontWeight: 500,
      fontSize: 20,
      marginBottom: "1rem",
      color: "#5E614C",
      marginTop: "1rem",
    },
    timerText: {
      fontWeight: 400,
      fontSize: 22,
      color: theme.palette.primary.main,
      marginBottom: "1%",
      marginTop: "10%",
    },
    resendButton: {
      display: "inline",
      cursor: "pointer",
      color: theme.palette.primary.main,
      marginLeft: "0.5em",
      border: "0",
    },
  })
);
const twoDigits = (num: number) => String(num).padStart(2, "0");

function useInterval(callback: any, delay: any) {
  const savedCallback: any = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const VerifyOtp: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const user = useSelector((state: IAppState) => state.user);


  const [values, setValues] = useState({
    email: user.emailId,
    phoneNumber: user.contactNumber?.phoneNumber || "",
    emailOtp: "",
    phoneNumberOtp: ""
  });

  useEffect(() => {
    if (user && user.id && user.spaceId) {
      setValues({
        ...values,
        email: user.emailId,
        phoneNumber: user.contactNumber?.phoneNumber || "",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const STATUS = {
    STARTED: "Started",
    STOPPED: "Stopped",
  };

  const INITIAL_COUNT = 120; // 2 min  resend otp timer

  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const [status, setStatus] = useState(STATUS.STARTED);

  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;

  const handleReset = () => {
    setStatus(STATUS.STOPPED);
    setSecondsRemaining(INITIAL_COUNT);
    setStatus(STATUS.STARTED);
  };

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // passing null stops the interval
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleResendOtp = async () => {
    if (status === STATUS.STOPPED) {
      const data = { emailId: values.email };
      const response = await callApi(
        PATHNAMES.IAM_USER_RESEND_EMAILOTP,
        HTTP_METHODS.POST,
        {
          body: JSON.stringify({ data }),
        }
      );
      handleReset();
      if (response && response.success) {
        snackbar({
          message: response.message,
          variant: SnackBarVariant.SUCCESS,
        });
      } else {
        snackbar({
          message: response.message || "something went wrong",
          variant: SnackBarVariant.ERROR,
        });
      }
    } else {
      snackbar({
        message: "Wait For 2 Minutes",
        variant: SnackBarVariant.ERROR,
      });
    }
  };

  const handleSubmit = async () => {
    const data = {
      emailId: values.email,
      phoneNumber: values.phoneNumber,
      emailOtp: parseInt(values.emailOtp),
      phoneNumberOtp: parseInt(values.phoneNumberOtp)
    }
    const response = await callApi(
      PATHNAMES.IAM_USER_VERIFY_EMAILOTP,
      HTTP_METHODS.POST,
      {
        body: JSON.stringify({ data }),
      }
    );
    if (response && response.success) {
      let token;
      if (localStorage.getItem("temp-token")) {
        token = localStorage.getItem("temp-token");
        if (token) {
          localStorage.setItem("token", token);
          localStorage.setItem("userType", USER_TYPES.WEBAPP);
          localStorage.removeItem("temp-token");
          dispatch(updateIamUser(user));
          snackbar({
            message: response.message,
            variant: SnackBarVariant.SUCCESS,
          });
          if (user?.role?.category === "org") {
            navigate("/admin-dashboard");
          } else {
            navigate("/dashboard");
          }

        }
      }
    } else {
      snackbar({
        message: response.message || "Invalid Credentials",
        variant: SnackBarVariant.ERROR,
      });
    }
  };

  const validationSchema = Yup.object().shape({});

  return (
    <Grid container justify="center">
      <Grid container xs={12} className={classes.formContainer}>
        <Grid container xs={12} justify="center">
          <Typography className={classes.mainText}>
            Verify Your Credential
          </Typography>
        </Grid>
        <Grid item container xs={12} className={classes.redirectText}>
          <Typography variant="body1">
            OTP sent for below details,
            <span
              className={classes.redirectLink}
              onClick={() => navigate("/signup")}
            >
              Edit details.
            </span>
          </Typography>
        </Grid>
        <Grid container xs={12}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={values}
            onSubmit={(data, { setSubmitting }) => {
              handleSubmit();
              setSubmitting(false);
            }}
          >
            {(formikProps) => (
              <Form style={{ width: "100%" }}>
                <Grid item xs={12}>
                  <Typography className={classes.secondaryText}>{values.email}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    fullWidth
                    required
                    className={classes.formItem}
                    label="Email OTP"
                    placeholder="Enter Email OTP"
                    name="emailOtp"
                    type="password"
                    InputProps={{
                      value: values.emailOtp,
                      onChange: handleChange,
                    }}
                  ></Field>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.secondaryText}>{`${DEFAULT_COUNTRY_CODE}-${values.phoneNumber}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    fullWidth
                    required
                    className={classes.formItem}
                    label="Phone Number OTP"
                    placeholder="Enter Phone Number OTP"
                    name="phoneNumberOtp"
                    type="password"
                    InputProps={{
                      value: values.phoneNumberOtp,
                      onChange: handleChange,
                    }}
                  ></Field>
                </Grid>
                <Grid container xs={12} justify="center">
                  <Fab
                    variant="extended"
                    size="medium"
                    className={classes.submitBtn}
                    type="submit"
                  >
                    verify
                  </Fab>
                </Grid>
                <Grid container xs={12} justify="center">
                  <Typography
                    hidden={secondsToDisplay < 1}
                    className={classes.timerText}
                  >
                    {twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}
                  </Typography>
                </Grid>
                <Grid item container xs={12} style={{ marginTop: '1.3rem' }} className={classes.redirectText}>
                  <Typography variant="body1">
                    Didn't get a OTP?
                    <Button
                      disabled={status === STATUS.STARTED}
                      className={classes.resendButton}
                      onClick={() => {
                        handleResendOtp();
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      Resend OTP
                    </Button>
                  </Typography>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VerifyOtp;
