import { Avatar, Box, Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import moment from "moment";
import React from "react";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS, ReactionOn, ReactionTypes } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { getInitials } from "../../../utils/utils";
import PostViewsAndLike from "./PostViewsAndComment";



const useStyles = makeStyles((theme) =>
  createStyles({
    imgBox: {
      padding: '0.4rem'
    },
    userNamebox: {
      padding: '0.2rem 0.6rem'
    },
    mainAvatar: {
      backgroundColor: "#FB8F67",
      width: "2rem",
      height: "2rem",
    },
  }))

interface IProps {
  items?: any[];
  fetchData?: (reset?: boolean) => void;
  lastElementRef?: (node: any) => void

}

const Comments: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const isLastElementinList = (index: number): boolean => props.items?.length === index + 1

  const addLikeReactionToPost = (commentId: string) => {
    callApi(PATHNAMES.ADD_REACTION(), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          reactionOnId: commentId,
          reactionType: ReactionTypes.LIKE,
          reactionOn: ReactionOn.COMMENT,
        }
      }),
    }).then(res => {
      if (res && res.success && res.data) {
        if (typeof props.fetchData === 'function') {
          props?.fetchData(true)
        }
      }
    })
  }


  return (
    <Grid container xs={12} style={{ padding: "0.6rem 0.6rem 2rem 0.6rem", borderTop: "1px solid black" }}>
      {props.items && props.items.length > 0 && props.items.map((comment: any, index: number) => (
        // {data.map((comment: any, index: number) => (
        <Grid key={comment.id} ref={isLastElementinList(index) ? props.lastElementRef : null} item xs={12} style={{ marginBottom: "0.8rem" }}>
          {/* USER IMAGE AND NAME CONTAINER */}
          <Grid container>
            <Box className={classes.imgBox}>
              {comment.user.profileImageUrl ?
                <Avatar alt={comment.user.firstName} src={comment.user.profileImageUrl} className={classes.mainAvatar} /> :
                <Avatar alt={comment.user.firstName} className={classes.mainAvatar}>
                  {getInitials(`${comment.user.firstName} ${comment.user.lastName}`)}
                </Avatar>
              }
            </Box>
            <Box className={classes.userNamebox}>
              <Grid container alignItems="center" style={{ height: "100%" }}>
                <Grid item xs={12} >
                  <Typography variant="subtitle1">
                    {comment.user.penName ? comment.user.penName : `${comment.user.firstName} ${comment.user.lastName}`}
                  </Typography>
                  <Typography variant="subtitle2" style={{ fontSize: "11px" }}>
                    {`${moment(comment.createdAt).format("LL")}`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* USER COMMENT */}
          <Grid container style={{ padding: '0.6rem 1rem' }}>
            <Typography variant="body1" style={{ lineHeight: "" }}>
              {comment.content}
            </Typography>
          </Grid>
          <Grid container style={{ padding: '0.6rem 1rem' }}>
            <PostViewsAndLike
              image={ThumbUpAltIcon}
              onClick={() => addLikeReactionToPost(comment.id)}
              text={comment.totalReactions}
              reactionGiven={comment.isReactionAvailable}
              style={{
                icon: { fontSize: "20px", },
                text: { fontSize: "16px", padding: "0rem 0.2rem" },
                mainDiv: { padding: '0rem', cursor: 'pointer' }
              }} />
          </Grid>
        </Grid>
      ))}
    </Grid>

  );
}

export default Comments;
