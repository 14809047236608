import { Avatar, Box, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, Grid, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IIamUserState } from "../../../reducers/user/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { getInitials } from "../../../utils/utils";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
interface IProps {
  user: IIamUserState;
  showUpdateRole: boolean;
  fetchSecondaryUsers: (publisherId: string) => void;
}

const statuses = [
  "active",
  "suspended",
]
interface IRole {
  id: string;
  name: string;
  active: string;
  permissionGroups: string[];
  category: string;
}
const useStyles = makeStyles((theme) => ({
  mainAvatar: {
    backgroundColor: "#FB8F67",
    width: "3.5rem",
    height: "3.5rem",
  },
  margin: {
    paddingTop: '1.2rem',
    "& .MuiFilledInput-input": {
      paddingTop: '15px'
    },
    "& .MuiFilledInput-root": {
      "border-radius": '10px',
      "paddingTop": '4px'
    },
    "& .MuiFilledInput-underline:before": {
      "border-bottom": 'none'
    },
    "& .MuiFilledInput-underline:after": {
      "border-bottom": 'none'
    },
  },
  editIcon: {
    fontSize: '19px', marginRight: '0.1rem',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  saveButton: {
    marginTop: "0.3rem",
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: '1rem'
  },
  cancelButton: {
    marginTop: "0.3rem",
    cursor: 'pointer',
  },
}))

const UserSummary: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [editRole, setEditRole] = useState<boolean>(false);
  const [roles, setRoles] = useState([]);
  const userState = useSelector((state: IAppState) => state.user);
  const [selectedRole, setSelectedRole] = useState<IRole>({
    id: "",
    name: "",
    active: "",
    permissionGroups: [],
    category: ""
  });
  const [selectedStatus, setSelectedStatus] = useState<string>(props.user.status);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
  const [openConfirmEditDialog, setOpenConfirmEditDialog] = useState<boolean>(false);
  const snackbar = useSnackBar();

  useEffect(() => {
    callApi(PATHNAMES.GET_ALL_ROLES, HTTP_METHODS.GET).then((res) => {
      if (res && res.data) {
        setRoles(res.data)
        const currUserRole = res.data.find((role: IRole) => role.id === props.user.roleId)
        setSelectedRole(currUserRole)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateStatusTerminated = async () => {
    if (props.user.status === "suspended") {
      const response = await callApi(PATHNAMES.DELETE_SECONDARYUSER_BY_SUPERADMIN(props.user.id), HTTP_METHODS.PATCH);
      if (response && response.success) {
        props.fetchSecondaryUsers(props.user.spaceId || "")
        snackbar({
          message: response.message,
          variant: SnackBarVariant.SUCCESS
        });
        setOpenConfirmDeleteDialog(false)
      } else {
        snackbar({
          message: response.message || "something went wrong",
          variant: SnackBarVariant.ERROR
        });
      }
    }
    else {
      snackbar({
        message: "Make User Inactive before deleting",
        variant: SnackBarVariant.WARNING
      });
    }
  }

  const updateUserRole = async () => {
    if (selectedRole && selectedRole.id) {
      const data = {
        roleId: selectedRole.id, status: selectedStatus
      }
      const response = await callApi(PATHNAMES.UPDATE_SECONDARYUSER_BY_SUPERADMIN(props.user.id), HTTP_METHODS.PATCH, {
        body: JSON.stringify({ data })
      });
      if (response && response.success) {
        props.fetchSecondaryUsers(props.user.spaceId || "")
        snackbar({
          message: response.message,
          variant: SnackBarVariant.SUCCESS
        });
        setOpenConfirmEditDialog(false)
        setEditRole(false)
      } else {
        snackbar({
          message: response.message || "something went wrong",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  return (
    <Grid container xs={12} style={{ marginTop: "0rem" }}>
      {props.showUpdateRole &&
        <Grid container xs={12} md={12} lg={12} justify='flex-end'>
          Status:{props.user.status === "suspended" ?
            <FiberManualRecordIcon
              fontSize="small"
              htmlColor="#FF5C3E"
              style={{ margin: "0.2rem 0rem 0rem 0.6rem" }}
            />
            :
            <FiberManualRecordIcon
              fontSize="small"
              htmlColor="#5CD89F"
              style={{ margin: "0.2rem 0rem 0rem 0.6rem" }}
            />
          }
        </Grid>}
      <Grid container xs={3}>
        {props.user.profileImageUrl ?
          <Avatar alt={props.user.firstName} src={props.user.profileImageUrl} style={{ height: "3.5rem", width: "3.5rem" }} /> :
          <Avatar className={classes.mainAvatar}>
            {getInitials(`${props.user.firstName} ${props.user.lastName}`)}
          </Avatar>}
      </Grid>
      <Grid container xs={9}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{ fontWeight: 600 }}>
            {`${props.user.firstName} ${props.user.lastName}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" style={{ fontWeight: 500 }}>
            {props.user.emailId}
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ marginTop: "2rem" }}>
        <Grid container item xs={6}>
          <Typography variant="h4" style={{ fontWeight: 500 }}>
            ROLE:<Typography variant="h4" style={{ fontWeight: 600, display: "inline", marginLeft: "1rem" }}>
              {props.user?.role?.name?.toUpperCase()}
            </Typography>
          </Typography>
        </Grid>
        {props.showUpdateRole && <Grid container item xs={6} style={{ paddingRight: '0px' }} justify="flex-end">
          {editRole ?
            <>
            </>
            :
            <>
              {(userState?.role?.name === "superAdmin" || userState?.role?.name === "admin") &&
                props.user?.role?.name !== "superAdmin" &&
                <Typography style={{ fontWeight: 450, cursor: "pointer", marginRight: '0.6rem' }} color="primary"
                  onClick={() => setEditRole(true)}>
                  <EditIcon className={classes.editIcon} />
                </Typography>}
              {userState?.role?.name === "superAdmin" &&
                props.user?.role?.name !== "superAdmin" &&
                <Typography style={{ fontWeight: 450, cursor: "pointer" }} color="primary"
                  onClick={() => setOpenConfirmDeleteDialog(true)}>
                  <DeleteIcon className={classes.editIcon} fontSize="large" />
                </Typography>}
            </>
          }
        </Grid>
        }
      </Grid>

      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={editRole}
        onClose={() => setEditRole(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Grid container xs={12}>
            <Typography variant="h3">Edit Secondary User</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container xs={12} spacing={2}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Typography style={{
                  display: "flex", justifyContent: "center", padding: "10px",
                  fontSize: "20px", fontWeight: 500
                }}>Update Role :</Typography>
              </Grid>
              <Grid item xs={6}> {editRole &&
                <FormControl variant="filled" style={{ paddingTop: '0rem' }} fullWidth className={classes.margin} >
                  <Select
                    value={selectedRole}
                    label="Role"
                    onChange={(e: any) => {
                      if (e.target.value && typeof e.target.value !== 'string') {
                        setSelectedRole(e.target.value)
                      }
                    }}
                    style={{ marginTop: "0rem", width: "95%", height: "3rem" }}
                  >
                    {roles.map(
                      (role: any, key: number) => {
                        return (
                          <MenuItem value={role} key={key}>
                            {role.name.toUpperCase()}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              }</Grid>
              <Grid item xs={6}>
                <Typography style={{
                  display: "flex", justifyContent: "center",
                  padding: "10px", fontSize: "20px", fontWeight: 500
                }}>
                  Edit Status :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {editRole &&
                  <FormControl variant="filled" style={{ paddingTop: '0rem' }} fullWidth className={classes.margin} >
                    <Select
                      value={selectedStatus}
                      label="Role"
                      onChange={(e: any) => {
                        if (e.target.value && typeof e.target.value === 'string') {
                          setSelectedStatus(e.target.value)
                        }
                      }}
                      style={{ marginTop: "0rem", width: "95%", height: "3rem" }}
                    >
                      {statuses.map(
                        (status: any) => {
                          return (
                            <MenuItem value={status} key={status}>
                              {status.toUpperCase()}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                }
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item xs={12} justify="flex-end">
            <Fab
              size="medium"
              variant="extended"
              className="blackBackButton"
              onClick={() => setEditRole(false)}
            >
              Cancel
            </Fab>
            <Box pl={2}></Box>
            <Fab
              size="medium"
              variant="extended"
              onClick={() => setOpenConfirmEditDialog(true)}
            >
              Proceed
            </Fab>
          </Grid>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        isOpen={openConfirmEditDialog}
        onCancel={() => setOpenConfirmEditDialog(false)}
        onConfirm={updateUserRole}
        title="Confirm Edit User"
        description={`Are you sure, you want to save the changes?`}
      />
      <ConfirmationDialog
        isOpen={openConfirmDeleteDialog}
        onCancel={() => setOpenConfirmDeleteDialog(false)}
        onConfirm={updateStatusTerminated}
        title="Confirm Delete Secondary User"
        description={`Are you sure, you want to delete this User?`}
      />
    </Grid>
  )
};

export default UserSummary;
