import { Box, Button, Checkbox, Chip, createStyles, Fab, FormControl, FormControlLabel, Grid, IconButton, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PhotoIcon from '@material-ui/icons/Photo';
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { getCategoryLabelByValue, getLanguageLabelByValue, isValidImage, isValidVideo } from "../../../utils/bookUtils";
import { AUTOSAVE_TIME_IN_MILLISECOND, BOOK_AGE_RANGE, BOOK_CATEGORIES, BOOK_LANGUAGES, HTTP_METHODS, MAXIMUM_VIDEO_SIZE_LIMIT, PostType, ResourceTypes } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { deleteFileFromS3, uploadFileToS3 } from "../../../utils/s3Util";
import CircularLoader from "../../common/CircularLoader";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import VideoUplaod from "../../../assets/VideoUpload.png";
import { Delete } from "@material-ui/icons";


interface ICopyright {
  id: string;
  title: string;
  description: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    stick: {
      position: "sticky",
      top: "4.2rem",
      zIndex: 1000,
    },
    fixed: {
      position: "fixed",
      top: "4.2rem",
      zIndex: 1000,
    },
    imgBox: {
      cursor: "pointer",
      height: "13rem",
      width: "9rem",
      backgroundColor: "#EEE",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    },
    coverImageInfo: {
      marginLeft: "1rem",
      width: "50%",
      height: "18rem",
      display: "flex",
      flexDirection: "column"
    },
    ageSelectMenu: {
      marginTop: "0rem",
      width: "100%",
      height: "3rem",
      fontSize: '12px'
    },
    qContainer: {
      "& .ql-container": {
        fontSize: "1rem",
        fontFamily: theme.typography.h2.fontFamily,
        border: 'none',
        padding: "1rem 0.6rem"
      },
      "& .ql-toolbar": {
        // FIXME:not showing toolbar because of compatibilty issue, if decided to show again just remove display none
        display: "none",
        // 
        border: 'none',
        padding: "1.5rem 0rem",
        position: "sticky",
        top: "3.6em",
        backgroundColor: "white",
        zIndex: 900,
        [theme.breakpoints.down('xs')]: {
          padding: "3rem 0rem",
        },
      },
      "& .ql-editor": {
        lineHeight: "30px",
        overflowY: "visible",
        padding: "0.6rem 0rem",
        "&:before": {
          position: "absolute",
          left: 10,
          fontFamily: theme.typography.h1.fontFamily,
          fontWeight: 300,
          fontStyle: "normal",
          color: "#A2A2A2"
        },
      }
    },
    cancelButton: {
      "&:hover": {
        color: theme.palette.primary.main
      }
    },
    textArea: {
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(15px, 1px) scale(0.75)"
      },
    },
    tagsChip: {
      borderRadius: 2,
      background: "#FAFAFA",
      border: "1px solid #E8E8E8",
      "&.MuiAutocomplete-tag": {
        margin: "10px 0px 5px 10px"
      }
    },
    copyrightSelectMenu: {
      marginTop: "0rem",
      width: "100%",
      height: "3rem",
      fontSize: '12px',
      whiteSpace: 'normal',
      "& .MuiSelect-selectMenu": {
        whiteSpace: "normal"
      }
    },
    margin: {
      paddingTop: '0.4rem',
      height: '1.8rem',
      "& .MuiFilledInput-input": {
        paddingTop: '10px',
        fontSize: '12px',
      },
      "& .MuiAutocomplete-clearIndicator": {
        fontSize: '1rem'
      },
      "& .MuiFilledInput-root": {
        "border-radius": '8px',
        "paddingTop": '1px'
      },
      "& .MuiFilledInput-underline:before": {
        "border-bottom": 'none'
      },
      "& .MuiFilledInput-underline:after": {
        "border-bottom": 'none'
      },
    },
  })
);


let intervalId: any

// https://www.npmjs.com/package/react-quill#custom-toolbar:~:text=Custom%20Toolbar-,Default%20Toolbar%20Elements,-The%20Quill%20Toolbar
// keeping a empty array so toolbar will be empty to prevent adding any formatted text in editor since mobile app is not supporting formatted text
const QuillMdouleAndFormats = {
  quillToolBar: { toolbar: [] },
  quillFormats: []
}

const NewPost: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const snackbar = useSnackBar();
  const inputFile = useRef<HTMLInputElement>(null);


  // content will be in json quill delta format 
  // it will change while writing in html string format becouse of compatibility and value change for component
  const [id, setId] = useState<string>("");
  const [title, setTitle] = useState<string>("")
  const [content, setContent] = useState<any>('');
  const [jsonContent, setJsonContent] = useState<any>(); // to be updated in DB
  const [language, setLanguage] = useState<string>("")
  const [category, setCategory] = useState<string>("")
  const [keywords, setKeywords] = useState<string[]>([])
  const [selectedCopyright, setSelectedCopyright] = useState<string>()
  const [selectedCopyrightsDescription, setSelectedCopyrightsDescription] = useState<string>()
  const [ageRange, setAgeRange] = useState<object>()
  const [thumbnail, setThumbnail] = useState<string>() // value to be updated in DB
  const [thumbnailForPreview, setThumbnailForPreview] = useState<string>() // signed url to show img
  const [narrationVideo, setNarrationVideo] = useState<string>() // value to be updated in DB
  const [narrationVideoForPreview, setNarrationVideoForPreview] = useState<string>() // signed url to show img

  // counter variables to keep eye on continuous draft update
  const [intervalChanged, setIntervalChanged] = useState<number>(0);
  const [publishBtn1, setPublishBtn1] = useState<boolean>(false); // on editor page
  const [callApiFn, setCallApiFn] = useState<boolean>(false); // used to make update api call inside interval fn
  const [agreeTermsCondtn, setAgreeTermsCondtn] = useState<boolean>(false); // on publish page
  const [copyrights, setCopyrights] = useState<ICopyright[]>([])
  const [loading, setLoading] = useState<boolean>(true);
  const [postType, setPostType] = useState<string>(PostType.TEXT);


  // when mounts
  useEffect(() => {
    callApi(PATHNAMES.GET_COPYRIGHTS_INFO(), HTTP_METHODS.GET).then((res) => {
      if (res && res.success) {
        setCopyrights(res.data.content.copyrights)
      }
    })

    // since updating directly via setInterval is haywired.. use counter to update/create post data
    intervalId = setInterval(() => setIntervalChanged(v => v + 1), AUTOSAVE_TIME_IN_MILLISECOND)

    // stop updating content when unmount
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const postId = params.get("id");
      const postType = params.get("type");
      if (postType) {
        if (postType === PostType.VIDEO) {
          setPostType(PostType.VIDEO)
        }
        if (postType === PostType.TEXT) {
          setPostType(PostType.TEXT)
        }
      }
      if (!postId) {
        setLoading(false)
        return
      }
      if (postId) {
        setLoading(true)
        callApi(PATHNAMES.GET_POST_BY_ID(mobileUserState.id, postId), HTTP_METHODS.GET).then(res => {
          if (res && res.success && res.data) {
            setApiData(res.data)
          }
        })
      }
    }
  }, [location, mobileUserState]);

  // stop loader
  useEffect(() => {
    if (id) {
      setLoading(false)
    }
  }, [id])


  const setApiData = (post: any) => {
    const { id, title, content, coverImage, language, category, ageRange, copyright, mediaUrl } = post
    setId(v => id);
    setTitle(v => title)
    if (content) {
      setContent((v: any) => JSON.parse(content))
    }
    setLanguage(v => language)
    setCategory(v => category)
    if (ageRange) {
      setAgeRange(v => ageRange)
    }
    if (coverImage) {
      setThumbnailForPreview(v => coverImage)
      const urlFinder = ".com/"
      const splittedText = coverImage.split(urlFinder)
      if (splittedText.length > 1) {
        // amazom url + image key
        setThumbnail(v => splittedText[1])
      }
    }
    if (mediaUrl) {
      setNarrationVideoForPreview(v => mediaUrl)
      const urlFinder = ".com/"
      const splittedText = mediaUrl.split(urlFinder)
      if (splittedText.length > 1) {
        // amazom url + image key
        setNarrationVideo(v => splittedText[1])
      }
    }
    // TODO: make sure mobile app also need to select from manual selection, remove data from create post api
    // if (copyright) {
    //   const _copyright = copyrights.find(cr => cr.title === copyright)
    //   setSelectedCopyright(v => _copyright?.id)
    //   setSelectedCopyrightsDescription(_copyright?.description)
    // }
  }


  useEffect(() => {
    createOrupdatePost()
    setCallApiFn(v => false)
  }, [intervalChanged])



  const createOrupdatePost = async () => {
    if (!callApiFn) {   // callApIFn becomes true whenever there is data to be updated or new data gets created
      return
    }
    if (id) {      // if post has id, then update other data which gets added or modified
      await updatePostContent()
      return
    }
    if (postType === PostType.TEXT) {
      if (title && jsonContent) {  // id is not peresent in content means , document has not been saved in DB at all , create new 
        await createPost()
        return
      }
    }
    if (postType === PostType.VIDEO) {
      if (title && narrationVideo) {  // id is not peresent in content means , document has not been saved in DB at all , create new 
        await createPost()
        return
      }
    }
  }


  const createPost = async () => {
    const data = {
      title,
      content: jsonContent,
      mediaUrl: narrationVideo ? narrationVideo : undefined, //TODO: fix o api side ""
      type: postType
    }
    const res = await callApi(PATHNAMES.CREATE_POST(), HTTP_METHODS.POST, {
      body: JSON.stringify({ data }),
    })
    if (res && res.success && res.data) {
      navigate(`/portal/rachnaye/post/edit?id=${res.data.id}&type=${postType}`, { replace: true })
      snackbar({
        message: "Your post gets auto saved. Keep writing",
        variant: SnackBarVariant.SUCCESS,
        duration: 5000
      })
    } else {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: res.message || "Error while creating post"
      })
    }
  }

  const updatePostContent = async () => {
    const data: any = {
      title,
      content: jsonContent,
      language,
      category,
      keywords,
      copyright: selectedCopyright,
      ageRange,
      coverImage: thumbnail ? thumbnail : "",
      mediaUrl: narrationVideo ? narrationVideo : ""
    }
    const res = await callApi(PATHNAMES.UPDATE_POST(id), HTTP_METHODS.PUT, {
      body: JSON.stringify({ data }),
    })
    if (!res || !res.success) {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: res.message || "Error while updating post"
      })
    }
  }

  const publishPost = async () => {
    const res = await callApi(PATHNAMES.PUBLISH_POST(id), HTTP_METHODS.PATCH)
    if (res && res.success && res.data) {
      snackbar({
        variant: SnackBarVariant.SUCCESS,
        message: res.message || "Post Published successfully"
      })
      clearInterval(intervalId)
      navigate("/portal/myRachnaye", { replace: true })
      return () => clearInterval(intervalId)
    } else {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: res.message || "Error while updating post"
      })
    }
  }

  const deleteCover = async () => {
    if (thumbnailForPreview) {
      setThumbnail("")
      setThumbnailForPreview("")
    }
  }

  const uploadDocument = async (file: File) => {
    if (thumbnail) {
      await deleteFileFromS3(thumbnail)
    }
    const key = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.USER_POST,
      isAdmin: false,
      publisherId: mobileUserState.id
    });
    if (key) {
      setThumbnail(key)
      setCallApiFn(v => true)
    }
    const res = await callApi(PATHNAMES.GET_SIGNED_URL, HTTP_METHODS.POST, {
      body: JSON.stringify({ data: { key } })
    });

    if (res && res.success && res.data) {
      setThumbnailForPreview(res.data)
    }
  }

  const uploadVideoDocument = async (file: File) => {
    if (narrationVideo) {
      await deleteFileFromS3(narrationVideo)
    }
    const key = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.USER_POST,
      isAdmin: false,
      publisherId: mobileUserState.id
    });
    if (key) {
      setNarrationVideo(key)
      setCallApiFn(v => true)
    }
    const res = await callApi(PATHNAMES.GET_SIGNED_URL, HTTP_METHODS.POST, {
      body: JSON.stringify({ data: { key } })
    });

    if (res && res.success && res.data) {
      setNarrationVideoForPreview(res.data)
    }
  }

  const handleRemoveVideo = async () => {
    if (narrationVideo) {
      await deleteFileFromS3(narrationVideo)
      setNarrationVideoForPreview("")
      setNarrationVideo("")
    }
  }

  const handleOpenFileExplorer = () => {
    const videoInput = document.getElementById("videoSelector")
    videoInput?.click()
  }

  const handleImgeUploadViaInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {

      const isImage = isValidImage(e.target.files[0])
      if (!isImage) {
        snackbar({
          message: "Only jpg/jpeg/png are allowed",
          variant: SnackBarVariant.WARNING
        })
      }
      if (isImage) {
        uploadDocument(e.target.files[0])
      }
    }
  }


  const button1Disable = () => {
    if (postType === PostType.TEXT) {
      return !(title && content)
    }
    if (postType === PostType.VIDEO) {
      return !(title && narrationVideo)
    }
    if (!id) {
      return true
    }
  }

  const handlePublishButton1Click = () => setPublishBtn1(true)

  const button2Disable = () => {
    if (!agreeTermsCondtn || !category || !language || !ageRange || !selectedCopyright) {
      return true //disable
    }
    return false // enable
  }

  const onImgContainerClick = () => {
    inputFile?.current?.click();
  };


  if (loading) {
    return <CircularLoader />
  }


  // when user has pressed publish button on editor
  if (publishBtn1) {
    return (
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center" style={{ padding: "1rem" }}>
        {/* CANCEL BUTTON */}
        <Grid container xs={12} justify='flex-end' className={classes.stick}>
          <Grid container justify='flex-end' xs={1} >
            <IconButton className={classes.cancelButton} onClick={() => setPublishBtn1(false)}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </Grid>
        </Grid>

        {/* IMAGE AND PUBLISHING CONTENT */}

        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: "0.6rem" }}>
          {/* IMAGE COVER */}
          <Grid container xs={12} sm={12} md={6} lg={6} xl={6} justify="center">
            <Grid container xs={12} sm={12} md={12} lg={12} xl={8}>
              <Box onClick={onImgContainerClick} className={classes.imgBox}>

                {thumbnailForPreview ?
                  <img alt="Preview Thumbnail" style={{ objectFit: "cover", height: "100%", width: "100%" }} src={thumbnailForPreview} />
                  :
                  <Box>
                    <PhotoIcon fontSize="small" color="primary" style={{ margin: "0rem 2rem" }} />
                    <Typography variant="body1">Add a Cover</Typography>
                  </Box>
                }
                <input
                  ref={inputFile}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={handleImgeUploadViaInputField}
                />
              </Box>
              <Box className={classes.coverImageInfo}>
                <Box style={{}}>
                  <Typography style={{ padding: '0.8rem', fontWeight: 600 }} variant="h3">Why Cover is Important?</Typography>
                  <Typography style={{ padding: '0.8rem' }} variant="subtitle2">Stories get more attention from users if they have a cover image.</Typography>
                  <Typography style={{ padding: '0rem 0.8rem' }} variant="subtitle2">A suitable cover gives good exposure to stories on Rachnaye.</Typography>
                  {thumbnail && <IconButton onClick={() => deleteCover()} title="Delete cover image">
                    <Delete fontSize="small" color="primary" />
                  </IconButton>}
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* RIGHT SIDE CONTAINER (LANGUAGE CATEGORY AND OTHER INFO */}
          <Grid container xs={12} sm={12} md={6} lg={6} xl={6} >

            {/* LANGUAGE AND CATEGORY CONTAINER */}
            <Grid container xs={6} justify='center' style={{ padding: '1rem' }}>
              <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
                <Typography style={{ fontWeight: 400, display: "inline" }} variant="h4">Select Language</Typography>
                <Typography color="primary" style={{ fontWeight: 400, display: "inline" }} variant="h4">*</Typography>
                <Autocomplete
                  options={BOOK_LANGUAGES}
                  autoHighlight
                  disablePortal={true}
                  defaultValue={{ label: getLanguageLabelByValue(language), value: language || "" }}
                  onChange={(e: any, selectedLanguage) => {
                    if (selectedLanguage) {
                      setLanguage(selectedLanguage.value)
                      setCallApiFn(v => true)
                    }
                  }}
                  getOptionLabel={(language: any) => language.label}
                  renderOption={(language) => (
                    <Typography style={{ fontSize: "12px" }}>{language.label}</Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="filled" className={classes.margin} placeholder="Search for language" />
                  )}
                />
              </Grid>

              <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
                <Typography style={{ fontWeight: 400, display: "inline" }} variant="h4">Select Category</Typography>
                <Typography color="primary" style={{ fontWeight: 400, display: "inline" }} variant="h4">*</Typography>
                <Autocomplete
                  options={BOOK_CATEGORIES}
                  autoHighlight
                  disablePortal={true}
                  defaultValue={{ label: getCategoryLabelByValue(category), value: category || "" }}
                  onChange={(e: any, selectedCategory) => {
                    if (selectedCategory) {
                      setCategory(selectedCategory.value)
                      setCallApiFn(v => true)
                    }
                  }}
                  getOptionLabel={(category: any) => category.label}
                  renderOption={(category) => (
                    <Typography style={{ fontSize: "12px" }}>{category.label}</Typography>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="filled" className={classes.margin} placeholder="Search for category" />
                  )}
                />
              </Grid>

            </Grid>
            {/* AGE AND COPYRIGHT CONTAINER */}
            <Grid container xs={6} justify='center' style={{ padding: '1rem' }}>
              <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
                <Typography style={{ fontWeight: 400, display: "inline" }} variant="h4">Select Age Range</Typography>
                <Typography color="primary" style={{ fontWeight: 400, display: "inline" }} variant="h4">*</Typography>
                <FormControl variant="filled" fullWidth className={classes.margin} >
                  <Select
                    value={ageRange}
                    onChange={(e: any) => {
                      setAgeRange(e.target.value)
                      setCallApiFn(v => true)
                    }}
                    className={classes.ageSelectMenu}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {BOOK_AGE_RANGE.map(
                      (ageRange: any, key: number) => {
                        return (
                          <MenuItem value={ageRange.value} key={key}>
                            <Typography style={{ fontSize: '12px' }}> {ageRange.label}</Typography>
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
                <Typography style={{ fontWeight: 400, display: "inline" }} variant="h4">Copyright</Typography>
                <Typography color="primary" style={{ fontWeight: 400, display: "inline" }} variant="h4">*</Typography>
                <FormControl variant="filled" fullWidth className={classes.margin}>
                  <Select
                    value={selectedCopyright}
                    onChange={(e: any) => {
                      const selectedCopyRightId = e.target.value
                      setSelectedCopyright(selectedCopyRightId)
                      const copyright = copyrights.find(cr => cr.id === selectedCopyRightId)
                      setSelectedCopyrightsDescription(copyright?.description)
                      setCallApiFn(v => true)
                    }}
                    className={classes.copyrightSelectMenu}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {copyrights.map(
                      (copyright: ICopyright, key: number) => {
                        return (
                          <MenuItem value={copyright.id} key={key}>
                            <Typography style={{ fontSize: '12px' }}>{copyright.title}</Typography>
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {selectedCopyrightsDescription &&
              <Grid container xs={12} style={{ padding: '0rem 1rem 1rem 1rem' }}>
                <Typography color="primary" style={{ fontSize: '12px' }}>{selectedCopyrightsDescription}</Typography>
              </Grid>
            }
            {/* KEYWORDS AND CONDITION CHECKBOX CONTAINER */}
            <Grid container xs={12} style={{ padding: '0rem 1rem' }}>
              <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
                <Typography style={{ fontWeight: 400, paddingBottom: "0.2rem" }} variant="h4">Keywords</Typography>
                <Typography style={{ fontWeight: 300, paddingBottom: "0.4rem", fontSize: '10px' }} variant="body1">Adding keywords will help you post for better recommendation </Typography>
                <Autocomplete
                  multiple
                  fullWidth
                  closeIcon={true}
                  value={keywords || []}
                  freeSolo={true}
                  onChange={(event, newValue) => {
                    setKeywords(newValue.filter((item) => item.trim().length))
                  }}
                  style={{ backgroundColor: "#EEE" }}
                  options={keywords || []}
                  filterOptions={() => []}
                  getOptionLabel={(option) => option}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        classes={{ root: classes.tagsChip }}
                        label={option}
                        deleteIcon={<CloseIcon style={{ fontSize: "14px", color: "black", width: "12px" }} />}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      className={classes.textArea}
                      multiline
                      {...params}
                      // label="Add Keywords"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label={<Typography style={{ fontWeight: 400 }} variant="subtitle2">I agree that this content is written by me, I also agree that I have the right to use the selected image as the cover for my Rachnaye</Typography>}
                  control={
                    <Checkbox
                      color="primary"
                      size='small'
                      checked={agreeTermsCondtn}
                      onChange={(e: any) => { setAgreeTermsCondtn(e.target.checked) }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container justify="flex-end" xs={12} style={{ padding: "2rem 0rem" }}>
              <Fab
                variant="extended"
                size="medium"
                disabled={button2Disable()}
                onClick={publishPost}
              >
                Publish Now
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    )
  }

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center" style={{ padding: "1rem 3rem", }}>
      <Grid container xs={12} sm={6} md={6} lg={6} xl={6} ></Grid>  {/* container seperator */}
      <Grid container xs={12} sm={6} md={6} lg={6} xl={6} justify='flex-end' className={classes.stick}>
        <Grid container xs={5} md={3} justify='flex-end' >
          <Fab
            variant="extended"
            size="medium"
            onClick={handlePublishButton1Click}
            disabled={button1Disable()}
          >
            <Typography variant="body1">Proceed</Typography>
          </Fab>
        </Grid>
      </Grid>



      <Grid item xs={12}>
        <TextField
          placeholder="Give a Title"
          fullWidth
          variant="standard"
          inputProps={{ style: { fontSize: "1.6rem" } }} // font size of input text
          value={title}
          onChange={(e) => {
            let newTitle: string
            if (!title && e.target.value) {
              const value: string = e.target.value
              const firstLetter = value.slice(0, 1).toUpperCase()
              const remainingLetter = value.slice(1)
              newTitle = firstLetter + remainingLetter
            } else {
              newTitle = e.target.value
            }
            setTitle(newTitle)
            setCallApiFn(v => true)
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ padding: "2rem 0rem" }}>
        {postType === PostType.TEXT ?
          <ReactQuill
            preserveWhitespace
            theme="snow"
            value={content}
            modules={QuillMdouleAndFormats.quillToolBar}
            formats={QuillMdouleAndFormats.quillFormats}
            onChange={(updatedValue, delta, source, editor) => {
              setContent((v: any) => updatedValue)
              setJsonContent((v: any) => JSON.stringify(editor.getContents().ops))
              setCallApiFn(v => true)
            }}
            className={classes.qContainer}
            placeholder="Write here .. "
          />
          :
          narrationVideoForPreview ?
            <Grid xs={12} container alignItems="center">
              <Grid container xs={12} sm={9}>
                <video
                  controls
                  controlsList="nodownload"
                  style={{ width: "90%", height: "60vh", borderRadius: "10px", marginTop: "1rem" }}
                  src={narrationVideoForPreview}
                />
              </Grid>
              <Grid container alignItems="center" spacing={2} direction="column" xs={12} sm={3}>
                <Grid item xs={12}>
                  <Fab variant='extended' size="medium" onClick={handleOpenFileExplorer}>Change video</Fab>
                </Grid>
                <Grid item xs={12}>
                  <Fab variant='extended' size="medium" onClick={handleRemoveVideo}>Remove video</Fab>
                </Grid>
              </Grid>
            </Grid> :


            <Grid id='v1' container justify="center"
              onClick={handleOpenFileExplorer}
              style={{ borderRadius: '5px', border: "2px dashed #FB8F67", minHeight: "40vh", marginTop: "4rem", padding: "3rem 1rem", cursor: "pointer" }} >
              <Grid container xs={12} justify="center" alignItems="center">
                <img src={VideoUplaod} height='50px' alt="video" />
              </Grid>
              <Grid item xs='auto'>
                <Typography align="center" variant='h2'>Upload you narration video here</Typography>
              </Grid>
            </Grid>
        }
        <input
          id='videoSelector'
          type="file"
          hidden
          onClick={(e: any) => {
            e.target.value = null;
          }}
          accept="video/mp4,video/x-m4v,video/*"
          onChange={(e: any) => {
            const isVideo = isValidVideo(e.target.files[0])
            if (!isVideo) {
              snackbar({
                message: "Only mp4/m4v/mkv videos are allowed",
                variant: SnackBarVariant.WARNING
              })
            }
            // In mobile app there is a limit of 50 mb, webapp side restriction is stopped for time being.
            // const size = e?.target?.files[0]?.size || 0
            // const sizeInMB = (size / (1024 * 1024)).toFixed(2);
            // if (parseInt(sizeInMB) > MAXIMUM_VIDEO_SIZE_LIMIT) {   
            //  snackbar({
            //   message: `Maximum file size should be ${MAXIMUM_VIDEO_SIZE_LIMIT} MB`,    
            //   variant: SnackBarVariant.WARNING,
            // })
            //    return 
            // }
            uploadVideoDocument(e.target.files[0])
          }}

        />

      </Grid>
    </Grid>
  );
};

export default NewPost;
