import {
  Avatar,
  Box,
  CircularProgress,
  Fab,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import moment from "moment";
import { MUIDataTableMeta, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FlatPickerBar from "../../common/FlatPickerBar";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { DataTable } from "../../common/Datatable/Datatable";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { ErrorHandler } from "../../../utils/utils";
import  { NumberFormatValues,NumericFormat } from "react-number-format";
import { Autocomplete } from "@material-ui/lab";
import { IPublisher } from "../../../reducers/publishers/types";
import CustomContextMenu from "../../common/CustomContextMenu/CustomContextMenu";
import { IContextMenuPosition } from "../../common/CustomContextMenu/types";
import { IFiltersAndSorting } from "../../../utils/common.interface";
import { Pagination } from "../../common/Datatable/types";


interface IFeaturedAuthor {
  charges: number;
  featuredFrom: string;
  featuredTill: string;
  publisherId: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "98%",
    borderBottom: "solid 0.5px rgba(0, 0, 0, 0.18)",
  },
  bookHeader: {
    height: "3rem",
    padding: "1rem",
  },
  headRow: {
    backgroundColor: "#FFF4F2",
    height: "2rem",
  },
  addButton: {
    color: "black!important",
    backgroundColor: "#FFD36E!important",
    marginRight: "1rem",
  },
  fileSelectButton: {
    color: "white",
    backgroundColor: theme.palette.primary.dark,
  },
  dividerClass: {
    color: "gray",
  },
  markFeaturedBtn: {
    fontWeight: 400,
    textAlign: 'start',
    maxWidth: '6rem'
  }
}));

const validateFeaturedAuthorData = async (args: IFeaturedAuthor) => {
  if (!args.featuredFrom) {
    throw new ErrorHandler("Featured From time is required")
  }
  if (moment(args.featuredFrom) > moment(args.featuredTill)) {
    throw new ErrorHandler("Featured From day should be less than Featured Till")
  }
  if (!args.featuredTill) {
    throw new ErrorHandler("Featured Till time is required")
  }
  if (moment(args.featuredTill) < moment(args.featuredFrom)) {
    throw new ErrorHandler("Featured Till day should be grater than Featured From")
  }
  if (!args.publisherId) {
    throw new ErrorHandler("Please select publisher")
  }
}

const Authors: React.FC = () => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.user);

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [authors, setAuthors] = useState([]);
  const [totalAuthors, setTotalAuthors] = useState<number>(0);
  const [authorId, setAuthorId] = useState<string>("");
  const [publishers, setPublishers] = useState<IPublisher[]>([]);
  const [featuredAuthor, setFeaturedAuthor] = useState<IFeaturedAuthor>({
    charges: 0,
    featuredFrom: moment().toISOString(),
    featuredTill: moment().endOf("day").toISOString(),
    publisherId: ""
  });

  const [contextMenu, setContextMenu] = useState<IContextMenuPosition | null>(null);
  const [menuAttributes, setMenuAttributes] = useState({
    link: "",
  })

  useEffect(() => {
    if (userState && userState.id) {
      fetchAuthors({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
      if (userState) {
        callApi(PATHNAMES.GET_ALL_PUBLISHERS_LIST(), HTTP_METHODS.GET).then(res => {
          if (res && res.success && res.data.length) {
            setPublishers(res.data);
          }
        })
      }
    }
  }, [userState]);

  const fetchAuthors = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args

    callApi(PATHNAMES.GET_AUTHORS(pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then(
      (authorsResponse) => {
        if (
          authorsResponse &&
          authorsResponse.success &&
          authorsResponse.data
        ) {
          setAuthors(authorsResponse.data.authors);
          setTotalAuthors(authorsResponse.data.totalAuthors)
        }
      }
    );
  }


  const markAuthorAsFeatured = async () => {
    try {
      if (userState && userState.spaceId && authorId) {
        await validateFeaturedAuthorData(featuredAuthor)
        const response = await callApi(PATHNAMES.FEATURE_AUTHOR(authorId), HTTP_METHODS.PUT, {
          body: JSON.stringify({
            data: featuredAuthor,
          })
        })
        if (response && response.success) {
          handleClose()
          setLoading(false);
          snackBar({
            variant: SnackBarVariant.SUCCESS,
            message: "Author featured successfully"
          })
          fetchAuthors({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
        } else {
          throw new ErrorHandler(response.message)
        }
      }
    } catch (error: any) {
      setOpen(false)
      snackBar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
    }
  }

  const handleClose = () => {
    setOpen(false);
    setFeaturedAuthor({
      charges: 0,
      featuredFrom: moment().toISOString(),
      featuredTill: moment().endOf("day").toISOString(),
      publisherId: ""

    })
  }

  const handleDisableButton = () => {
    if (featuredAuthor &&
      featuredAuthor.publisherId &&
      featuredAuthor.featuredFrom &&
      featuredAuthor.featuredTill
    ) {
      //not disabled
      return false
    }
    //disabled
    return true
  }


  const handleContextMenuClick = (
    event: React.MouseEvent, authorId: string
  ) => {
    event.preventDefault();
    const value: any = contextMenu === null ? {
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    } : null;
    setContextMenu(value);
    setMenuAttributes({
      ...menuAttributes,
      link: `update-author?author=${authorId}`
    })
  };


  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      label: "Profile",
      name: "image",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { fontWeight: 600, fontSize: '14px' } }),
        customBodyRender: (data: any) => {
          return (
            <Avatar
              variant="rounded"
              style={{ borderRadius: "50%" }}
              src={data}
            ></Avatar>
          );
        },
      },
    },
    {
      label: "Author Name",
      name: "name",
    },
    {
      label: "Popular Name",
      name: "popularName",
    },
    {
      label: "Featured Till",
      name: "featuredTill",
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({ style: { fontWeight: 600, fontSize: '14px' } }),
        customBodyRender: (data: string, tableData: MUIDataTableMeta) => {
          //if FeaturedTill not there or if it's over then
          if (!data || (moment() > moment(data))) {
            return (<Typography variant="h5" className={classes.markFeaturedBtn} color="primary" onClick={(event) => {
              const [id] = tableData.rowData
              setAuthorId(id)
              event.stopPropagation()
              setOpen(true)
            }}
            > Mark Featured
            </Typography >)
          } else {
            return moment(data).format("DD/MM/YY, hh:mm A")
          }
        }
      }
    }
  ];

  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    onRowClick: (rowData: any, rowMeta: any) => {
      const item: any = authors[rowMeta.dataIndex];
      if (item) {
        navigate(`/update-author?author=${item.id}`);
      }
    },
    setRowProps: (row: any, rowIndex: number) => {
      return {
        onContextMenu: (event: React.MouseEvent) => {
          const [authorId] = row
          return handleContextMenuClick(event, authorId)
        }
      }
    },
    searchPlaceholder: "Search by name, popular name",
    count: totalAuthors
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container xs={12}>
      <Paper elevation={0} style={{ width: "100%", padding: "1rem" }}>
        <Grid container xs={12} >
          <Grid container item xs={4}>
            <Typography variant="h2" style={{ fontWeight: "bolder", padding: "1rem", }}>
              Authors
              <Typography variant="h2" color="primary" style={{ display: "inline", marginLeft: "1rem" }}>
                {totalAuthors}
              </Typography>
            </Typography>
          </Grid>
          <Grid container item xs={8} justify="flex-end" >
            <Fab
              variant="extended"
              size="medium"
              className={classes.addButton}
              onClick={() => navigate("/create-author")}
            >
              Create New
            </Fab>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <DataTable
            title={""}
            rows={authors}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchAuthors}
          />
          <CustomContextMenu
            onClose={() => setContextMenu(null)}
            position={contextMenu}
            attributes={menuAttributes}
          />
        </Grid>
        <Dialog
          maxWidth={"md"}
          fullWidth={true}
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <Grid container xs={12}>
              <Typography variant="h3">Feature Author</Typography>
            </Grid>
          </DialogTitle>
          <DialogContent dividers={true} style={{ minHeight: "45vh" }}>
            <Grid container xs={12} spacing={4}>
              <Grid item xs={4}>
                <FlatPickerBar
                  handleDateChange={(value: Date) => {
                    if (moment(value) > moment(featuredAuthor.featuredTill)) {
                      setFeaturedAuthor({
                        ...featuredAuthor,
                        featuredFrom: moment(value).toISOString(),
                        featuredTill: moment(value).endOf("day").toISOString()
                      })
                    } else {
                      setFeaturedAuthor({
                        ...featuredAuthor,
                        featuredFrom: moment(value).toISOString()
                      })
                    }
                  }}
                  label="Feature From"
                  identifier="featuredFrom"
                  name="featuredFrom"
                  minDate={moment().startOf("day").toDate()}
                  value={featuredAuthor.featuredFrom}
                />
              </Grid>
              <Grid item xs={4}>
                <FlatPickerBar
                  handleDateChange={(value: Date) => {
                    setFeaturedAuthor({
                      ...featuredAuthor,
                      featuredTill: moment(value).endOf("day").toISOString()
                    })
                  }}
                  label="Feature Till"
                  identifier="featuredTill"
                  name="featuredTill"
                  minDate={moment(featuredAuthor.featuredFrom).toDate()}
                  value={featuredAuthor.featuredTill}
                />
              </Grid>
              <Grid item xs={2}>
                <NumericFormat
                  allowNegative={false}
                  value={featuredAuthor.charges || 0}
                  customInput={TextField}
                  decimalScale={0}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>
                  }}
                  label="Charges"
                  required
                  onValueChange={(value: NumberFormatValues) => {
                    // floatValue always gives a number or undefined
                    if (!value.floatValue) {
                      setFeaturedAuthor({
                        ...featuredAuthor,
                        charges: 0
                      })
                    } else {
                      setFeaturedAuthor({
                        ...featuredAuthor,
                        charges: value.floatValue
                      })
                    }
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Autocomplete
                  options={publishers}
                  getOptionLabel={(publisher) => `${publisher.name} `}
                  renderOption={(publisher) => `${publisher.name.trim().toUpperCase()}`}
                  onChange={(event: any, newValue: IPublisher | null) => {
                    if (newValue && newValue.id) {
                      setFeaturedAuthor({
                        ...featuredAuthor,
                        publisherId: newValue.id
                      })
                    } else {
                      setFeaturedAuthor({
                        ...featuredAuthor,
                        publisherId: ""
                      })
                    }
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} variant="outlined" placeholder='Select a Publisher' />
                  )}
                />
                <Typography variant='h5' color="primary" style={{ margin: '0.5rem 0.4rem' }} >* publisher who has requested for featured author</Typography>
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Grid container item xs={12} justify="flex-end">
              <Fab
                size="medium"
                variant="extended"
                className="blackBackButton"
                onClick={handleClose}
              >
                Cancel
              </Fab>
              <Box pl={2}></Box>
              <Fab
                size="medium"
                variant="extended"
                onClick={markAuthorAsFeatured}
                disabled={handleDisableButton()}
              >
                Proceed
              </Fab>
            </Grid>
          </DialogActions>
        </Dialog>
      </Paper>
    </Grid>
  );
};

export default Authors;
