import { DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextareaAutosize, Typography, createStyles, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from '@material-ui/icons/Send';
import React, { useEffect, useRef, useState } from "react";
import callApi from "../../../utils/apiUtil";
import { CommentType, HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import Comments from "./Comments";
import InfiniteScroll from "./InfiniteScroll";

interface IApiCall {
  pageNumber: number;
  pageSize: number;
  signal: AbortSignal;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogPosition: {
      position: 'fixed',
      top: '3.6rem',
      right: 0,
      backgroundColor: "#fcfaf6",
      zIndex: 200,
      width: '26rem',
      height: "100%",
      boxShadow: "0px 4px 12px #D9D9D9",
      overflow: "auto",
      '&::-webkit-scrollbar': {
        display: 'none'
      },
    },
    imgBox: {
      padding: '0.4rem'
    },
    userNamebox: {
      padding: '0.2rem 0.6rem'
    },
    mainAvatar: {
      backgroundColor: "#FB8F67",
      width: "2rem",
      height: "2rem",
    },
    icon: {
      "& :hover": {
        color: 'red'
      }
    },
  }))

interface IComments {
  open: boolean;
  handleClose: () => void;
  fetchPost: () => void;
  totalComments: number;
  postId: string;
}


const CommentsDialogBoxPost: React.FC<IComments> = (props) => {
  const classes = useStyles();
  const [reloadApi, setReloadApi] = useState<number>(0)
  const [userComment, setUserComment] = useState<string>("")
  const textarea_ref = useRef<any>();

  useEffect(() => {
    textarea_ref?.current?.focus();
  }, [props])

  useEffect(() => {
    setReloadApi(v => v + 1)
  }, [props.postId])

  const fetchComments = async ({ pageNumber, pageSize, signal }: IApiCall) => {
    const response = await callApi(PATHNAMES.GET_COMMENTS(props.postId, CommentType.COMMENT_ON_POST, pageNumber, pageSize), HTTP_METHODS.GET, { signal })
    if (response && response.success) {
      return response.data
    } else {
      return []
    }
  }


  const handleAddCommentToPost = () => {
    callApi(PATHNAMES.CREATE_COMMENT(), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          content: userComment,
          postId: props.postId,
          type: CommentType.COMMENT_ON_POST
        }
      }),
    }).then(res => {
      if (res && res.success && res.data) {
        setUserComment("")
        setReloadApi(v => v + 1)
        props.fetchPost()
      }
    })
  }


  return (
    <div
      onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
      hidden={!props.open}
      className={classes.dialogPosition}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h3">
            {`Comments  (${props.totalComments})`}
          </Typography>
          <IconButton style={{ padding: '0.3rem' }} onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {/* {"Add comment"} */}
          <Grid container alignItems="center">
            <Grid item xs={11} style={{ paddingRight: "1rem", }}>
              <TextareaAutosize
                id='addCommentTextArea'
                ref={textarea_ref}
                aria-label="minimum height"
                placeholder="Add Comment ... "
                value={userComment}
                onChange={(e) => {
                  setUserComment(e.target.value)
                }}
                style={{ width: "100%", border: 'none', outline: "none", fontSize: "14px", fontFamily: "Poppins", backgroundColor: "transparent", resize: "none" }}
              />

            </Grid>
            <Grid item xs={1} >
              <IconButton size="small" className={classes.icon} onClick={handleAddCommentToPost}>
                <SendIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContentText>
        <InfiniteScroll
          pageNumber={0}
          pageSize={50}
          apiCall={fetchComments}
          pagination
          reLoadApi={reloadApi}
          msgWhenDataIsEmpty="No comments yet, Be the fisrt one to comment"
          noMoreDataMessage="No more comments"
        >
          <Comments />
        </InfiniteScroll>

      </DialogContent>
    </div>
  );
}

export default CommentsDialogBoxPost;
