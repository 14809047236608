export const OFFER_APPLICABILITY_TYPES = {
  BOOKS: "books",
  CATEGORIES: "categories"
}

export enum allSelectionIds {
  PUBLISHERS = "allPublishers",
  CATEGORIES = "allCategories",
  BOOKS = "allBooks",
  BOOK_TYPES = "allBookTypes",
}
export enum allSelectionLable {
  PUBLISHERS = "All Publishers",
  CATEGORIES = "All CATEGORIES",
  BOOKS = "All Books",
  BOOK_TYPES = "ALL TYPES",
}

export enum getArrOf {
  PUBLISHERS = "publishers",
  CATEGORIES = "categories",
  BOOKS = "books",
  BOOKS_TYPES = "bookTypes",
}

export enum OfferedByUser {
  PUBLISHER = "publisher",
  ADMIN = "admin",
}

export enum OfferDiscountType {
  PERCENTAGE = "percentage",
  AMOUNT = "amount",
}

export enum BundleOfferContentStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}