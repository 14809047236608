import { createStyles, Drawer, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { AppMenu } from './AppMenu';

const UseStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      position: "fixed",
      whiteSpace: "nowrap",
      marginTop: "3.5rem",
      width: "inherit",
      height: "100%",
      "&::-webkit-scrollbar": {
        width: "6px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#002F6E",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "white",
      }
    }
  })
);

const SideBar: React.FC = ()  => {
  const classes = UseStyles()
  return (
    <Drawer
      variant="permanent"
      style={{ width: "15%"}}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <AppMenu />
    </Drawer>
  )
}

export default SideBar;