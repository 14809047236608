import {
  Divider, Grid, makeStyles
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  divider: {
    borderTop: "3px dashed #bbb",
    margin: "4rem 0rem 3rem 0rem",
  }
}))

interface IProp {
  className?: string
}
const CustomDivider: React.FC<IProp> = (props) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} style={{ paddingRight: "1rem" }}>
      <Divider
        variant='fullWidth'
        orientation='horizontal'
        className={`${classes.divider} ${props.className}`}
      />
    </Grid>)

};

export default CustomDivider;