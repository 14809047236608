import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import callApi, { buildQueryParamFromObject } from '../../../utils/apiUtil';
import { IFiltersAndSorting } from '../../../utils/common.interface';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import CircularLoader from '../../common/CircularLoader';
import { DataTable } from '../../common/Datatable/Datatable';
import { Pagination } from '../../common/Datatable/types';

const IncompleteOrders: React.FC = () => {
  const [orders, setOrders] = useState<any[]>([]);
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [hasOrder, setHasOrder] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);


  // FETCH ORDER
  useEffect(() => {
    fetchIncompleteOrders({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
  }, [])


  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    count: totalOrders,
    searchPlaceholder: "Search by date [dd/mm/yy] , refId, status, publisher, book, price, userno.",
  };

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      label: "Order Id",
      name: "referenceNumber"
    },
    {
      label: "Order Type",
      name: "orderType",
      options: {
        customBodyRender: (data: string) => data.replace("_", " ").toUpperCase()
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (data: string) => data.replace("_", " ").toUpperCase()
      }
    },
    {
      label: "Order Time",
      name: "createdAt",
      options: {
        customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY, h:mm A")
      }
    },
    {
      label: "Book",
      name: "book",
      options: {
        customBodyRender: (data: any) => data.title
      }
    },
    {
      label: "Price",
      name: "totalAmount",
      options: {
        customBodyRender: (data: number) => "₹" + data?.toLocaleString('en-IN')
      }
    },
    {
      label: "Publisher Name",
      name: "publisher",
      options: {
        customBodyRender: (data: any) => data.name
      }
    },
    {
      label: "Order From",
      name: "platform",
      options: {
        customBodyRender: (data: string) => data?.toUpperCase()
      }
    },
    {
      label: "User Name",
      name: "user",
      options: {
        customBodyRender: (data: any) => data.firstName + " " + (data.lastName || "")
      }
    },
    {
      label: "User Number",
      name: "user",
      options: {
        customBodyRender: (data: any) => data.contactNumber.phoneNumber
      }
    },
  ]

  const fetchIncompleteOrders = (args: IFiltersAndSorting) => {
    const queryParam = buildQueryParamFromObject({
      ...args,
      // filters: [{ columnName: "title", }]
    })

    callApi(PATHNAMES.GET_SUPERADMIN_INCOMPLETE_SUB_ORDERS(queryParam), HTTP_METHODS.GET).then((ordersResponse) => {
      if (ordersResponse && ordersResponse.success && ordersResponse.data) {
        setOrders(ordersResponse.data.subOrders);
        setTotalOrders(ordersResponse.data.totalSubOrdersCount)
        setHasOrder(ordersResponse.data.subOrderPresent)
      }
    })
    setLoading(false)
  }

  if (loading) {
    return <CircularLoader />
  }

  return (
    <Grid container xs={12} style={{ paddingRight: "2rem" }}>
      {!hasOrder ?
        <Grid item xs={12} style={{ padding: "2rem 0rem" }}>
          <Typography variant="h3">
            There are no new orders confirmed or in progress.
          </Typography>
        </Grid>
        :
        <Grid container xs={12}>
          <DataTable
            title={`In Progress Orders (${totalOrders})`}
            rows={orders}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchIncompleteOrders}
          />
        </Grid>
      }
    </Grid>
  )
}

export default IncompleteOrders;