import {
  Fab, Grid,
  Typography
} from "@material-ui/core";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IDistributor } from "../../../../reducers/distributors/types";
import callApi from "../../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../../utils/common.interface";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { DataTable } from "../../../common/Datatable/Datatable";
import { Pagination } from "../../../common/Datatable/types";


interface IProps {
  publisherId: string;
}

const PublisherDistributors: React.FC<IProps> = (props) => {
  const navigate = useNavigate()
  const [distributors, setDistributors] = useState<IDistributor[]>([]);
  const [totalDistributors, setTotalDistributors] = useState<number>(0);
  const [publisherHasDistributor, setPublisherHasDistributor] = useState<boolean>(true);



  useEffect(() => {
    if (props.publisherId) {
      fetchDistributors({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.publisherId])


  const fetchDistributors = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args

    callApi(PATHNAMES.GET_DISTRIBUTORS_FOR_PUBLISHER_WITH_PAGINATION(props.publisherId, pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then((res) => {
      if (res && res.success && res.data) {
        setTotalDistributors(res.data.totalDistributorCount)
        setDistributors(res.data.distributors);
        // re-render only when we get new value
        setPublisherHasDistributor(oldValue => oldValue === res.data.publisherHasDistributor ? oldValue : res.publisherHasDistributor)
      }
    })
  }

  const options: MUIDataTableOptions = {
    elevation: 0,
    onRowClick: (rowData: any, rowMeta: any) => {
      const distributor: any = distributors[rowMeta.dataIndex];
      if (distributor) {
        navigate(`/view-distributor?distributor=${distributor.id}&publisher=${props.publisherId}`);
      }
    },
    filter: false,
    count: totalDistributors,
    searchPlaceholder: "Search by name, refname, email, contact or address",
  };

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      label: "NAME",
      name: "name",
      options: {
        filter: false,
      }
    },
    {
      label: "REF NAME",
      name: "refName",
      options: {
        filter: false,
      }
    },
    {
      label: "Email",
      name: "email",
      options: {
        filter: false,
      }
    },
    {
      label: "Address",
      name: "address",
      options: {
        filter: false,
        customBodyRender: (data: any) => `${data.city}, ${data.state}`
      }
    },
    {
      label: "Contact No.",
      name: "contactPerson",
      options: {
        filter: false,
        customBodyRender: (data: any) => `${data.contact.countryCode} ${data.contact.phoneNumber}`
      }
    },
  ]

  return (
    <Grid container xs={12}>
      <Grid container xs={12} justify="flex-end">
        <Fab
          variant="extended"
          size="medium"
          onClick={() => navigate(`/create-distributor?publisher=${props.publisherId}`)}
        >
          Create Distributor
        </Fab>
      </Grid>
      {!publisherHasDistributor ?
        <Typography variant="body1">
          Publisher has no distributors
        </Typography> :
        <Grid container xs={12}>
          <DataTable
            title={`Distributors (${totalDistributors})`}
            rows={distributors}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchDistributors}
          />
        </Grid>
      }

    </Grid>
  )
}

export default PublisherDistributors;