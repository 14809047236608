import { Fab, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import callApi, { buildQueryParamFromObject } from '../../../utils/apiUtil';
import { IFiltersAndSorting } from '../../../utils/common.interface';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import CircularLoader from '../../common/CircularLoader';
import { DataTable } from '../../common/Datatable/Datatable';
import { Pagination } from '../../common/Datatable/types';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';

const BooksByPriceRange: React.FC = () => {
  const snackbar = useSnackBar()
  const [books, setBooks] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [startPrice, setStartPrice] = useState<number | null>(0);
  const [endPrice, setEndPrice] = useState<number | null>(100);
  const [hasMoreUserBooks, setHasMoreUserBooks] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  // FETCH ORDER
  useEffect(() => {
    fetchBooksInPriceRangeForAdmin({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])


  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    count: totalCount,
    searchPlaceholder: "Search publisher, book , bookType",
  };

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      label: "Book",
      name: "bookTitle",
    },
    {
      label: "Book Type",
      name: "bookType",
    },
    {
      label: "Publisher",
      name: "publisherName",
    },
    {
      label: "Price",
      name: "price",
      options: {
        customBodyRender: (data: any) => `₹ ${data}`
      }
    },
    {
      label: "Bundles",
      name: "bundles",
      options: {
        customBodyRender: (data: any) => {
          if (data && data?.length) {
            return data.join(", ")
          }
          return "-"
        }
      }
    },

  ]

  const fetchBooksInPriceRangeForAdmin = (args: IFiltersAndSorting) => {
    if (startPrice === null || endPrice === null) {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: "Please add price range"
      })
      return
    }
    const queryParam = buildQueryParamFromObject({
      ...args,
      startPrice,
      endPrice
    })

    callApi(PATHNAMES.GET_BOOKS_IN_PRICE_RANGE(queryParam), HTTP_METHODS.GET).then((bookResponse) => {
      if (bookResponse && bookResponse.success && bookResponse.data) {
        setBooks(bookResponse.data.bookRes);
        setTotalCount(bookResponse.data.totalBooksCount)
        if (!hasMoreUserBooks) {
          setHasMoreUserBooks(bookResponse.data.bookPresent)
        }
      }
    })
    setLoading(false)
  }


  if (loading) {
    return <CircularLoader />
  }

  return (
    <Grid container xs={12} style={{ padding: "1rem 0.6rem" }}>
      <Grid style={{ margin: "0rem 0rem 3rem 0rem" }}>
        <Typography variant='h1'>Paper and Hardbound Book by Price Range </Typography>
      </Grid>
      <Grid container xs={12} alignItems='center'>
        <Grid style={{ paddingRight: "1rem" }}>
          <Typography variant='h2' style={{ fontWeight: 400 }}>Search for books in price range from </Typography>
        </Grid>
        <Grid style={{ paddingRight: "1rem" }}>
          <NumericFormat
            allowNegative={false}
            value={startPrice}
            customInput={TextField}
            decimalScale={0}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">₹</InputAdornment>
            }}
            label="Start Price"
            required
            onValueChange={(value: NumberFormatValues) => {
              // floatValue always gives a number or undefined
              if (value.floatValue === undefined) {
                setStartPrice(null)
              } else {
                setStartPrice(value.floatValue)
              }
            }}
          />
        </Grid>
        <Grid style={{ paddingRight: "1rem" }}>
          <Typography variant='h2' style={{ fontWeight: 400, display: "inline" }}>to</Typography>
        </Grid>
        <Grid style={{ paddingRight: "1rem" }}>
          <NumericFormat
            allowNegative={false}
            value={endPrice}
            customInput={TextField}
            decimalScale={0}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">₹</InputAdornment>
            }}
            label="End Price"
            required
            onValueChange={(value: NumberFormatValues) => {
              // floatValue always gives a number or undefined
              if (value.floatValue === undefined) {
                setEndPrice(null)
              } else {
                setEndPrice(value.floatValue)
              }
            }}
          />
        </Grid>
        <Grid>
          <Fab
            variant="extended"
            size="medium"
            onClick={() => fetchBooksInPriceRangeForAdmin({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })}
          >
            Search
          </Fab>
        </Grid>
      </Grid>


      {!hasMoreUserBooks ?
        <Grid container xs={12} style={{ padding: "4rem 0rem" }}>
          <Typography variant="h3" color='primary'>
            There are no books in this price range
          </Typography>
        </Grid>
        :
        <Grid container xs={12} style={{ padding: "2rem 0rem" }}>
          <DataTable
            title={`Books(${totalCount})`}
            rows={books}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchBooksInPriceRangeForAdmin}
          />
        </Grid>
      }
    </Grid>
  )
}

export default BooksByPriceRange;