import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { IApiCall } from "../../../../utils/common.interface";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import InfiniteScroll from "../../MyRachnaye/InfiniteScroll";
import PortalOrderCard from "./PortalOrderCard";
import _, { set } from "lodash";
import CircularLoader from "../../../common/CircularLoader";
const useStyles = makeStyles((theme) => ({
  margin: {
    height: '1.8rem',
    "& .MuiFilledInput-input": {
      paddingTop: '10px',
      fontSize: '12px',
    },
    "& .MuiFilledInput-root": {
      "border-radius": '8px',
      "paddingTop": '1px'
    },
    "& .MuiFilledInput-underline:before": {
      "border-bottom": 'none'
    },
    "& .MuiFilledInput-underline:after": {
      "border-bottom": 'none'
    },
  },
  filterButton: {
    display: "flex",
    fontWeight: "bold",
    border: "1px solid #a2a2a2",
    borderRadius: "4px",

    paddingBlock: "0.5rem",
    alignSelf: "flex-end",
    '&:hover': {
      backgroundColor: "#eaeaeb",
      cursor: "pointer"
    },
    [theme.breakpoints.down('md')]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up('md')]: {
      fontSize: "14px",
    },
  },
  applyButton: {
    fontWeight: "bold",
    border: "1px solid #a2a2a2",
    borderRadius: "4px",
    width: "50%",
    alignSelf: "center",
    '&:hover': {
      backgroundColor: "#eaeaeb",
      cursor: "pointer"
    }
  },
  popOverContainer: {
    display: "flex", flexDirection: "column", overflow: "hidden",
  },
  closeIconContainer: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.action.hover, // You can customize this color
    },
  },
  searchBar: {
    paddingBlock: "0.8rem",
    width: "100%"
  },
  searchContainer: {
    [theme.breakpoints.up('md')]: {
      justifyContent: "flex-end"
    },

  },
  IconButton: {
    [theme.breakpoints.down('md')]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up('md')]: {
      fontSize: "20px",
    },
  }
}))

interface IOrderFilter {
  lable: string,
  value: string
}

const FilterValues = {
  TIMERANGE: 'timeRange',
  ORDER_STATUS: 'orderStatus'
};

const search = _.debounce((value, setSearchTitle) => {
  setSearchTitle(value);
}, 800);

const PortalUserOrders: React.FC = () => {
  const classes = useStyles()
  const [reloadApi, setReloadApi] = useState<number>(0)

  const [timeFilters, setTimeFilters] = useState<IOrderFilter[]>([]);
  const [currentSelectedTimeFilter, setCurrentSelectedTimeFilter] = useState<string>("");

  const [statusFilters, setStatusFilters] = useState<IOrderFilter[]>([]);
  const [currentSelectedStatusFilter, setCurrentSelectedStatusFilter] = useState<string>("");

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);

  const [searchTitle, setSearchTitle] = useState("");

  const [filterChange, setFilterChange] = useState({ time: '', status: '' })

  const [notifyApply, setNotifyApply] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setFilterChange((prev) => ({ status: currentSelectedStatusFilter, time: currentSelectedTimeFilter }));
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }
  useEffect(() => {
    if (mobileUserState && mobileUserState.id) {
      fetchOrderFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState.id]);

  useEffect(() => {
    if (mobileUserState && mobileUserState.id) {
      setReloadApi(v => v + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifyApply, searchTitle]);

  const fetchOrdersForUser = async ({ pageNumber, pageSize, signal }: IApiCall) => {
    if (mobileUserState?.id) {
      const res = await callApi(PATHNAMES.GET_PORTAL_USER_ORDERS(mobileUserState.id, currentSelectedTimeFilter, currentSelectedStatusFilter, searchTitle, pageNumber, pageSize), HTTP_METHODS.GET, { signal });
      if (res && res.success && res.data) {
        return res.data
      } else {
        return []
      }
    }
  }

  const fetchOrderFilters = async () => {
    setLoading(true)
    if (mobileUserState?.id) {
      const timeResponse = await callApi(PATHNAMES.GET_PORTAL_USER_ORDERS_FILTERS(mobileUserState.id, FilterValues.TIMERANGE), HTTP_METHODS.GET);
      if (timeResponse && timeResponse.success && timeResponse.data) {
        setTimeFilters(timeResponse.data)
        setCurrentSelectedTimeFilter(timeResponse.data[0]?.value)
        setFilterChange((prev) => ({ ...prev, time: timeResponse.data[0]?.value }));
      }

      const statusResponse = await callApi(PATHNAMES.GET_PORTAL_USER_ORDERS_FILTERS(mobileUserState.id, FilterValues.ORDER_STATUS), HTTP_METHODS.GET);
      if (statusResponse && statusResponse.success && statusResponse.data) {
        setStatusFilters(statusResponse.data)
        setCurrentSelectedStatusFilter(statusResponse.data[0]?.value)
        setFilterChange((prev) => ({ ...prev, status: statusResponse.data[0]?.value }));
      }
    }
    setLoading(false)
  }

  const handleFilterApply = () => {
    setCurrentSelectedTimeFilter(filterChange.time)
    setCurrentSelectedStatusFilter(filterChange.status)
    setNotifyApply((prev) => !prev)
    setOpenDialog(false)
  }
  return (
    loading ?
      <CircularLoader />
      :
      <Grid container xs={12}>
        <Dialog
          open={openDialog}
          onClose={handleClose}
        >
          <Grid container xs={12} style={{ width: "450px", padding: "1rem" }} justifyContent="center" alignContent="center" >
            <Grid container style={{ paddingBottom: "1rem" }} direction="row" alignItems="center">
              <Grid item>
                <Typography variant="h3">FILTER</Typography>
              </Grid>
              <Grid item xs={10} >
                <Grid container justifyContent="flex-end" alignItems="center">
                  <CloseIcon onClick={handleClose} className={classes.closeIconContainer} />
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} className={classes.popOverContainer} style={{}} direction="column">
              <Grid xs={12} container style={{ width: "100%" }}>
                <form onSubmit={(e) => { e.preventDefault(); handleFilterApply() }} style={{ width: "100%" }} >
                  <Grid container style={{ width: "100%" }}>
                    <FormLabel >Time</FormLabel>

                    <RadioGroup aria-label="Time" name="time" style={{ width: "100%" }} value={filterChange.time} onChange={(event: any) => {
                      const value = event.target.value
                      setFilterChange((prev) => ({ ...prev, time: value }));
                    }}>
                      {timeFilters.map((filter, idx) => (
                        <FormControlLabel value={filter.value} control={<Radio />} label={filter.lable} />
                      ))}
                    </RadioGroup>
                    <Grid item xs={12}>
                      <FormLabel style={{ marginTop: "1rem" }}>Status</FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup aria-label="Status" name="status" value={filterChange.status} onChange={(event) => {
                        const value = event.target.value
                        setFilterChange((prev) => ({ ...prev, status: value }));
                      }}>
                        {statusFilters.map(status => (
                          <FormControlLabel value={status.value} control={<Radio />} label={status.lable} />
                        ))}
                      </RadioGroup>
                    </Grid>
                    <Grid container xs={12} justifyContent="center" alignItems="center" className={classes.applyButton}>
                      <Button type="submit" style={{ display: "flex", width: "100%", padding: "0.5rem" }}>Apply</Button>
                    </Grid >
                  </Grid>
                </form>
              </Grid>

            </Grid>

          </Grid>
        </Dialog>

        <Grid container xs={12} alignItems="center">
          <Grid item xs={12} md={3}>
            <Typography variant="h2" style={{ fontWeight: "bolder" }}>
              Your Orders
            </Typography>
          </Grid>
          <Grid container xs={12} md={9} spacing={1} alignItems="center" className={classes.searchContainer}>
            <Grid item xs={9} md={6}>
              <TextField
                placeholder="Search in orders"
                id="outlined-start-adornment"
                InputProps={{
                  onChange: (e) => {
                    search(e.target.value, setSearchTitle);
                  },
                  classes: { input: classes.searchBar },
                  startAdornment: <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>,
                }}
                variant="outlined"
                size="small"
                className={classes.searchBar}
              />
            </Grid>
            <Grid item>
              <Box onClick={handleOpenDialog} style={{ borderColor: "secondary", paddingInline: "0.8rem", justifyContent: "center", alignItems: "center" }} className={classes.filterButton}>
                <Grid item ><TuneIcon className={classes.IconButton}></TuneIcon></Grid>
                <Grid item>FILTER</Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <InfiniteScroll
            pageNumber={0}
            pageSize={10}
            apiCall={fetchOrdersForUser}
            pagination
            reLoadApi={reloadApi}
            msgWhenDataIsEmpty="No Orders found"
            noMoreDataMessage="No more orders to load."
          >
            <Orders />
          </InfiniteScroll>
        </Grid>
      </Grid>
  )
}




interface IOrderProps {
  items?: any[]
  lastElementRef?: (node: any) => void
}
const Orders: React.FC<IOrderProps> = (props) => {
  const isLastElementinList = (index: number): boolean => props.items?.length === index + 1

  return (
    <Grid container xs={12}>
      {props.items?.map((order, idx) => (
        <Grid item xs={12} key={idx} ref={isLastElementinList(idx) ? props.lastElementRef : null}>
          <PortalOrderCard order={order} />
        </Grid>
      ))}
    </Grid>
  )
}



export default PortalUserOrders;