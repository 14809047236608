import { Avatar, Box, Card, CardMedia, createStyles, Fab, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import BooksIcon from '@material-ui/icons/LibraryBooks';
import ShareIcon from '@material-ui/icons/Share';
import Comment from '@material-ui/icons/Textsms';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from "moment";
import React, { useEffect, useState } from "react";
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../../assets/rachnayeLight.png";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { getInitials } from "../../../utils/utils";
import CircularLoader from "../../common/CircularLoader";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import PostViewsAndLike from "./PostViewsAndComment";

const useStyles = makeStyles((theme) =>
  createStyles({
    mainPage: {
      padding: "2rem 6rem"
    },
    userInfoHeader: {
      borderBottom: "1px solid black",
      paddingBottom: "0.7rem"
    },
    postTitle: {
      padding: '1rem 0rem',
      fontWeight: 300,
      fontSize: "1.4rem",
      wordBreak: "break-word",
      lineHeight: "30px"
    },
    imgBox: {
      padding: '0.4rem'
    },
    card: {
      display: "flex",
      height: 170
    },
    cardMedia: {
      width: 120,
      height: 170
    },
    userNamebox: {
      padding: '0.2rem 0.6rem'
    },
    mainAvatar: {
      backgroundColor: "#FB8F67",
      width: "2.6rem",
      height: "2.6rem",
    },
    shareButton: {
      "&:hover": {
        color: theme.palette.primary.main
      }
    },
    userPenNameText: {
      fontSize: "0.8rem"
    },
    qContainer: {
      "& .ql-container": {
        fontSize: "1rem",
        fontFamily: theme.typography.h2.fontFamily,
        border: 'none',
        padding: "1rem 0rem"
      },
      "& .ql-editor": {
        lineHeight: "30px",
        overflowY: "visible",
        padding: "0.6rem 0rem",  // add padding when want to move quill editor data
      }
    },
  })
);


const ReadSeries
  : React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
    const snackbar = useSnackBar();
    const [series, setSeries] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);


    // fetch post detail
    useEffect(() => {
      getSeriesData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, mobileUserState]);


    useEffect(() => {
      if (series.id) {
        setLoading(false)
      }
    }, [series]);



    const getSeriesData = () => {
      if (location) {
        const params = new URLSearchParams(location.search);
        const seriesId = params.get("id");
        if (seriesId) {
          callApi(PATHNAMES.GET_SERIES_BY_ID_FOR_READERS(seriesId), HTTP_METHODS.GET,).then(res => {
            if (res && res.success && res.data) {
              setSeries(res.data)
            }
          })
        }
      }
    }

    const handleCopyLink = async () => {
      if (series && series.id) {
        const data = {
          id: series.id,
          resourceType: "series"
        }
        const res = await callApi(PATHNAMES.CREATE_DYNAMIC_LINK, HTTP_METHODS.POST, {
          body: JSON.stringify({ data })
        });
        if (res && res.success && res.data) {
          const { shortLink } = res.data
          navigator.clipboard.writeText(shortLink)
          snackbar({
            variant: SnackBarVariant.SUCCESS,
            message: "Link copied successfully"
          })
          return
        }
      } else {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: "Something went wrong while copying link"
        })
        return
      }
    }

    const handleStartReading = () => {
      const chapterId = series.publishedChapters[0].id
      const seriesId = series.id
      navigate(`/portal/rachnaye/chapter/read?seriesId=${seriesId}&chapterId=${chapterId}`)
    }
    if (loading) {
      return <CircularLoader />
    }


    return (
      // PAGE CONTAINER
      <Grid container spacing={3} xs={12} sm={12} md={12} lg={12} xl={12} justify={"center"} className={classes.mainPage}>
        {/* MIDDLE VIEW CONTAINER */}
        <Grid container xs={9} >
          {/* USER NAME AND INFO HEADER */}
          <Grid container xs={12} justify="space-between" className={classes.userInfoHeader}>
            <Grid container xs={9}>
              <Box className={classes.imgBox}>
                {series.writerDetails.profileImageUrl ?
                  <Avatar alt={series.writerDetails.firstName ?? ""} src={series.writerDetails.profileImageUrl} className={classes.mainAvatar} /> :
                  <Avatar className={classes.mainAvatar}>
                    {getInitials(`${series.writerDetails.firstName ?? ""} ${series.writerDetails.lastName ?? ""}`)}
                  </Avatar>
                }
              </Box>
              <Box className={classes.userNamebox}>
                <Grid container alignItems="center" style={{ height: "100%" }}>
                  <Grid item xs={12} >
                    <Typography variant="subtitle1">
                      {series.writerDetails.penName ? series.writerDetails.penName : `${series.writerDetails.firstName ?? ""} ${series.writerDetails.lastName ?? ""}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid container alignItems="center" xs={1}>
              <IconButton onClick={handleCopyLink} className={classes.shareButton} title="Copy Share Link">
                <ShareIcon />
              </IconButton>
            </Grid>
          </Grid>

          {/* blurred image */}
          <Grid container style={{
            position: "relative",
            paddingTop: "1rem",
            marginBottom: "7rem"
          }}>
            <Grid item xs={12} style={{
              minHeight: "20rem",
              backgroundImage: `url(${series.coverImage || logo})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              filter: "blur(2px)",
              WebkitFilter: "blur(3px)"
            }}>
            </Grid>
            <Grid item style={{
              position: "absolute",
              top: "80%",
            }}>
              <Card variant="outlined" className={classes.card}>
                <CardMedia
                  component="img"
                  className={classes.cardMedia}
                  image={series.coverImage || logo}
                  alt="Cover Img"
                />
              </Card>
              {/* <img height="40%" src={series.coverImage || logo}></img> */}
            </Grid>
          </Grid>


          {/* POST TITLE CONTAINER */}
          <Grid container xs={12} direction="column" justify="center" alignItems="center" style={{ minHeight: "30vh", }}>
            <Grid container justify="center" alignItems="center" style={{ width: "80%" }}>
              <Typography align="center" className={classes.postTitle}>
                {series.title}
              </Typography>
            </Grid>
            <Grid container justify="center" alignItems="center" style={{ paddingBottom: "0rem" }}>
              <Typography style={{ fontSize: "11px" }}>
                {`${moment(series.publishedAt).format("LL")} • ©${series?.copyright?.title}`}
              </Typography>
              <Typography display="block" style={{ fontSize: "11px" }}>

              </Typography>
            </Grid>
            <Grid container justify="center" alignItems="center" style={{ marginTop: "1rem" }}>
              <PostViewsAndLike
                image={ThumbUpAltIcon}
                text={series.reactionsOnSeries}
                reactionGiven={series.reactionGiven}
                style={{
                  icon: { fontSize: "26px", },
                  text: { fontSize: "20px", padding: "0rem 0.2rem" },
                  mainDiv: { padding: "0.8rem 1.2rem", cursor: "pointer" }
                }} />
              <PostViewsAndLike
                image={VisibilityIcon}
                text={series.viewsOnSeries}
                reactionGiven={series.userViewGiven}
                style={{
                  icon: { fontSize: "26px" },
                  text: { fontSize: "20px", padding: "0rem 0.2rem" }

                }} />
              <div style={{ cursor: "pointer" }}>
                <PostViewsAndLike image={Comment}
                  text={series.commentsOnSeries}
                  style={{
                    icon: { fontSize: "26px" },
                    text: { fontSize: "20px", padding: "0rem 0.2rem" },
                    mainDiv: { padding: "0.8rem 1.2rem", }

                  }} />
              </div>
              <PostViewsAndLike
                image={BooksIcon}
                text={`${series.publishedChapters.length} parts`}
                style={{
                  icon: { fontSize: "26px" },
                  text: { fontSize: "20px", padding: "0rem 0.2rem" }

                }} />
            </Grid>
          </Grid>

          {/* POST CONTENT CONTAINER */}
          <Grid container xs={12}>

            {series.description ?
              <Typography variant="body1" style={{ lineHeight: "1.5rem" }}>{series?.description}</Typography>
              : <></>
            }

          </Grid>

          <Grid container justify="center" style={{ margin: '2rem 0rem' }}>
            <Grid item>
              <Fab
                variant="extended"
                size="medium"
                onClick={handleStartReading}
              >
                Start Reading
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

export default ReadSeries
  ;
