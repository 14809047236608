import React from "react"
import {
  Button,
  makeStyles
} from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

interface IDownloadButton {
  title: string;
  disable?: boolean;
  action: () => void;
  showIcon: boolean;
}
const useStyle = makeStyles(() => ({
  button: {
    color: "black!important",
    backgroundColor: "#FFD36E!important",
    fontSize: "10px",
    marginRight: "0.8rem",
    padding: "0.9rem",
    width: "auto",
    blockSize: "1rem",
  },
  iconStyle: {
    fontSize: '13px',
    marginLeft: '5px'
  }
}));
const DownloadButton: React.FC<IDownloadButton> = (props) => {
  const classes = useStyle();
  return (<Button
    variant="contained"
    disabled={props.disable || false}
    size="small"
    className={classes.button}
    onClick={props.action}
  >
    {props.title}
    {props.showIcon ?
      <ArrowDownwardIcon className={classes.iconStyle} /> :
      <></>
    }
  </Button>)
}

export default DownloadButton