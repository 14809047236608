import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import MobileUserHeader from '../components/common/MobileUserHeader';
import PublicHeader from '../components/common/PublicHeader';
import SideBar from '../components/common/Sidebar';
import { useSnackBar } from '../components/common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../components/common/SnackbarWrapper/SnackbarWrapper';
import { IMobileUserState } from '../reducers/mobileUser/types';
import { IIamUserState } from '../reducers/user/types';
import { IAppState } from '../store';
import { RouteAccess, USER_TYPES } from '../utils/constants';
import { getUserType, isLogin } from '../utils/utils';

const useStyles = makeStyles(() => ({
  headerContainer: {
    height: "3.5rem",
  }
}))

const PrivateRoute = ({ component: Component, access = RouteAccess.ALL_USERS, ...rest }: any) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const [userType] = useState<string>(getUserType())
  const userState: IMobileUserState | IIamUserState = useSelector((state: IAppState) => (userType === USER_TYPES.MOBILE ? state.mobileUser : state.user));
  const [hasRouteAccess, setHasRouteAccess] = useState<boolean | undefined>()

  useEffect(() => {
    // user is webappUser(admin/superAdmin) && user has access to route the give access
    // user is mobile user and has access to route then give access

    if ((userState && userState.id)) {
      if (userType === USER_TYPES.WEBAPP) {

        const checkUserRouteAccess = () => {
          const isUserPublisher = (userState as IIamUserState).role?.category === "publisher"
          const isAccessForAdmin = access === RouteAccess.ADMIN

          const isUserOrg = (userState as IIamUserState).role?.category === "org"
          const isAccessForSuperAdmin = access === RouteAccess.SUPER_ADMIN

          const isAcessForAllUsers = access === RouteAccess.ALL_USERS
          const isAcessForAdminAndSuperAdmin = access === RouteAccess.ADMIN_AND_SUPER_ADMIN

          return (isUserPublisher && isAccessForAdmin) ||
            (isUserOrg && isAccessForSuperAdmin) ||
            isAcessForAllUsers ||
            isAcessForAdminAndSuperAdmin
        }

        if (checkUserRouteAccess()) {
          setHasRouteAccess(true)
        } else {
          setHasRouteAccess(false)
        }
      }
      if (userType === USER_TYPES.MOBILE) {

        if (access === RouteAccess.MOBILE_USER || access === RouteAccess.ALL_USERS) {
          setHasRouteAccess(true)
        } else {
          setHasRouteAccess(false)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState])

  useEffect(() => {
    if (hasRouteAccess === false) {
      snackbar({
        message: "Not authorized to access page",
        variant: SnackBarVariant.ERROR
      })
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRouteAccess])


  if (!isLogin()) {
    //React-rotuer-dom
    //Note: Be aware that the v5 <Redirect /> uses replace logic by default (you may change it via push prop), on the other hand,
    // the v6 <Navigate /> uses push logic by default and you may change it via replace prop. 
    return <Navigate to="/login" state={{ from: { pathname: window.location.pathname, search: window.location.search } }} replace />;
  }

  return (
    hasRouteAccess && <Grid container xs={12}>
      {userType === USER_TYPES.MOBILE ?
        <>
          <Grid container xs={12} className={classes.headerContainer}>
            <MobileUserHeader />
          </Grid>
          <Grid id="visibleBody" container xs={12} style={{ display: "flex", width: "100%" }} justify="center">
            <Grid container xs={10} style={{ paddingTop: "1rem" }}>
              <Outlet />
            </Grid>
          </Grid>
        </> :
        <>
          <Grid container xs={12} className={classes.headerContainer}>
            <PublicHeader />
          </Grid>
          <Grid container xs={12} style={{ display: "flex", width: "100%" }}>
            <Grid container xs={2}>
              <SideBar />
            </Grid>
            <Grid container xs={10} style={{ paddingTop: "1rem" }}>
              <Outlet />
            </Grid>
          </Grid>
        </>
      }
    </Grid>
  );
};

export default PrivateRoute;