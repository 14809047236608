import {
  Card,
  CardActions,
  CardMedia, Chip, createStyles, Fab, FormControl, FormControlLabel, Grid, makeStyles, MenuItem, Select, Switch, TextareaAutosize,
  TextField,
  Typography
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-material-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { IBundleOfferResponse } from "../../../reducers/offers/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { BookShareTypes, HTTP_METHODS, ResourceTypes } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { uploadFileToS3 } from "../../../utils/s3Util";
import { ErrorHandler, roundToTwo } from "../../../utils/utils";
import FlatPickerBar from "../../common/FlatPickerBar";
import ImageUploader from "../../common/ImageUploader";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import SelectedBooksDetail from "./SelectedBooks";
import CircularLoader from "../../common/CircularLoader";
import { BundleOfferContentStatus } from "../../../reducers/offers/constants";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";

const OPTIONS_LIMIT = 8;
const bookSearchKeyFilter = createFilterOptions<any>({
  matchFrom: 'any',
  stringify: (book) => book.title + book.type + book?.authors?.join("") + book.publisherName
});

const limitedBooksListToShow = (options: any, state: any) => {
  return bookSearchKeyFilter(options, state).slice(0, OPTIONS_LIMIT);
};

const BundleSets = ["SET_1", "SET_2", "SET_3", "SET_4", "SET_5"]

interface IBook {
  id: string;
  publisherId: string;
  publisherName: string;
  title: string;
  type: string
  authors?: string[];
  booksAvailable: number;
  platformShare?: number;
  publisherShare?: number;
  price?: number;
  sellingMRP: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    autoCompleteOptionHeader: {
      position: 'sticky',
      top: '-8px',
      padding: '4px 10px',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.light
    },
    autoCompleteOptionItem: {
      padding: '0rem'
    },
    textAreaStyle: { minWidth: '98%', height: "100%", resize: 'none', fontSize: "1.1rem" },
    selectMenu: {
      marginTop: "0rem",
      width: "100%",
      height: "3rem",
      fontSize: '12px'
    },
  })
)


const UpdateBundle: React.FC = () => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const userState = useSelector((state: IAppState) => state.user);
  const [firstTimePageLoad, setFirstTimePageLoad] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)

  const [booksAC, setBooksAC] = useState<IBook[]>([]); // options for autocomplete from api call
  const [selectedBooksInAC, setSelectedBooksInAC] = useState<IBook[]>([]); // autocomplete selections
  const [selectedBooks, setSelectedBooks] = useState<IBook[]>([]); // container selection with price changes to be made

  const [thumbnail, setThumbnail] = useState<string>("")
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [values, setValues] = useState<IBundleOfferResponse>({
    id: "",
    name: "",
    image: "",
    description: "",
    termsAndConditions: "",
    booksSalebleOnlyViaBundle: false,
    minBookBuy: undefined,
    shippingCharges: undefined,
    startDate: "",
    endDate: "",
    books: [],
    set: BundleSets[0]
  })


  // GET BUNDLE
  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const bundleId = params.get("bundle");
      if (bundleId && userState?.spaceId) {
        callApi(PATHNAMES.GET_BUNDLE_OFFER(bundleId), HTTP_METHODS.GET).then(res => {
          if (res && res.success) {
            setValues(res.data)
            // set image
            if (res.data.image) {
              setThumbnail(res.data.image)
            }
          }
        })
      }
    }
  }, [location, userState])


  // GET ALL BOOKS
  useEffect(() => {
    getBooks()
  }, [userState])


  //SET BOOK SHARE LISTING ON FIRST LOAD BELOW AUTOCOMPLETE (SHARE LISTING)
  useEffect(() => {
    if (userState && userState.id && userState.spaceId) {
      setLoading(true)
      if (values.books.length) {
        const formattedBooks: IBook[] = values.books.map(bookInBundle => {
          const bookInAc = booksAC.find(bookInAC => bookInAC.id === bookInBundle.bookId && bookInAC.type === bookInBundle.type)
          return ({
            id: bookInBundle.bookId,
            title: bookInBundle.title,
            platformShare: bookInBundle.platformShare,
            publisherShare: bookInBundle.publisherShare,
            type: bookInBundle.type,
            price: bookInBundle.price,
            booksAvailable: bookInBundle.booksAvailable,
            publisherId: bookInBundle.publisherId,
            publisherName: bookInBundle.publisherName,
            sellingMRP: bookInAc?.sellingMRP || 0
          })
        })
        setSelectedBooks(formattedBooks)
        setLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.books, booksAC])



  // SET SELECTED BOOKS ON PAGE LOAD IN AUTOCOMPLETE
  useEffect(() => {
    if (userState && userState.id && userState.spaceId) {
      if (firstTimePageLoad) {
        if (values.books.length) {
          const preSelectedBooks = booksAC.filter(bookInAC => {
            const bookPresent = values.books.find(bookInBundle => bookInBundle.bookId === bookInAC.id && bookInBundle.type === bookInAC.type)
            if (bookPresent) {
              return bookPresent
            }
            return false
          })
          setSelectedBooksInAC(preSelectedBooks)
          setFirstTimePageLoad(false)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booksAC, firstTimePageLoad])



  // to prevent mixing autocomplete and selected book together, make autocomplete flow immutable while changing selected books share
  useEffect(() => {
    const newArr: IBook[] = selectedBooksInAC.map(book => {
      const bookPresentPreviously = selectedBooks.find(_book => _book.id === book.id && _book.type === book.type)
      if (bookPresentPreviously) {
        return { ...bookPresentPreviously }
      } else {
        return { ...book }
      }
    })
    setSelectedBooks(newArr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBooksInAC])


  const getBooks = () => {
    callApi(PATHNAMES.GET_ALL_PUBLISHER_PAPER_BOOKS('bundle'), HTTP_METHODS.GET).then((booksResponse) => {
      if (booksResponse && booksResponse.success && booksResponse.data) {
        setBooksAC(booksResponse.data);
      }
    })
  }

  const handleShareChange = (bookObj: IBook, shareType: string, value: number | undefined) => {
    const newArr = selectedBooks.map(book => {
      if (book.id === bookObj.id && book.type === bookObj.type) {
        if (shareType === BookShareTypes.PLATFORM_SHARE) {
          book.platformShare = value;
          book.price = roundToTwo((book.platformShare || 0) + (book.publisherShare || 0))
        }
        if (shareType === BookShareTypes.PUBLISHER_SHARE) {
          book.publisherShare = value;
          book.price = roundToTwo((book.platformShare || 0) + (book.publisherShare || 0))
        }
        return { ...book }
      }
      return { ...book }
    })

    setSelectedBooks(newArr)
  }

  const handleRemoveAll = () => {
    setSelectedBooksInAC([])
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const uploadDocument = async (file: File) => {
    const key = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.BUNDLE_IMAGE,
      isAdmin: true
    });
    const data = { ...values }
    if (data) {
      data.image = key;
      setValues(data)
    }
    const res = await callApi(PATHNAMES.GET_SIGNED_URL, HTTP_METHODS.POST, {
      body: JSON.stringify({ data: { key } })
    });
    if (res && res.success && res.data) {
      setThumbnail(res.data)
    }
  }


  const formatDataforApiCall = async (formatingType: 'publish' | 'update') => {
    const data = {
      ...values,
      books: selectedBooks.map(book => {
        return {
          bookId: book.id,
          publisherId: book.publisherId,
          type: book.type,
          price: book.price,
          publisherShare: book.publisherShare,
          platformShare: book.platformShare
        }
      })
    }

    if (formatingType === "publish") {
      data.contentStatus = BundleOfferContentStatus.PUBLISHED
    }
    if (data.image?.includes("http")) {
      delete data.image
    }
    return data
  }

  // handle update bundle (submit)
  const handleSubmit = async (submitType: 'publish' | 'update') => {
    try {
      await validateValues()
      const data = await formatDataforApiCall(submitType)
      const res = await callApi(PATHNAMES.UPDATE_BUNDLE_OFFER(data.id), HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      })
      if (res && res.success) {
        navigate("/bundles")
        snackbar({
          message: res.message,
          variant: SnackBarVariant.SUCCESS
        })
      } else {
        throw new ErrorHandler(res.message)
      }
    } catch (error: any) {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
    }
  }


  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().min(3, 'Bundle name is too short - give some meaningful name.')
      .required("Bundle name is required."),
    minBookBuy: Yup.number()
  });

  const validateValues = async () => {
    if (!values.name) {
      throw new ErrorHandler("Bundle name is empty")
    }
    if (!values.minBookBuy) {
      throw new ErrorHandler("Minimum books to buy is empty")
    }
    if (values.shippingCharges === undefined || isNaN(values.shippingCharges as number)) {
      throw new ErrorHandler("Shipping Charge needed")
    }
    if (!values.startDate) {
      throw new ErrorHandler('Bundle start date is empty')
    }
    if (!values.image) {
      throw new ErrorHandler("Please add image for bundle")
    }
    if (!values.endDate) {
      throw new ErrorHandler("Bundle end date is empty")
    }
    if (!selectedBooks.length) {
      throw new ErrorHandler("Please select books")
    }
    // allow publisher share to be 0
    if (selectedBooks.some(book => !book.platformShare)) {
      throw new ErrorHandler("Please add shares in selected book")
    }
    if (selectedBooks.some(book => book.price === null || book.price === undefined)) {
      throw new ErrorHandler("Please check the book for null price")
    }
  }


  if (loading) {
    return (<CircularLoader message="wait, we are loading bundle" />)
  }
  return (
    <Grid container>
      <Grid container xs={12}>
        <Typography variant="h2" style={{ fontWeight: 600 }}>
          Update Bundle Offer
        </Typography>
      </Grid>
      <Grid container xs={12} style={{ marginTop: "2rem" }}>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={values}
          onSubmit={(data, { setSubmitting }) => {
            handleSubmit("update");
            setSubmitting(false);
          }}
        >
          {() => (
            <Form style={{ width: "100%" }}>
              {/* NAME AND DATE ROW  */}
              <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} >
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    component={InputField}
                    fullWidth
                    required
                    label="Bundle Name"
                    placeholder="Bundle Name"
                    name="name"
                    InputProps={{
                      value: values.name,
                      onChange: handleChange
                    }}
                  ></Field>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    component={NumericFormat}
                    customInput={TextField}
                    name="minBookBuy"
                    label="Minimum book to buy"
                    placeholder="Minimum book needed to buy from bundle"
                    variant="outlined"
                    allowNegative={false}
                    value={values.minBookBuy}
                    fullWidth
                    decimalScale={0}
                    onValueChange={(eventValues: NumberFormatValues) => {
                      setValues({
                        ...values,
                        minBookBuy: eventValues.floatValue
                      })

                    }}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <FlatPickerBar
                    handleDateChange={(value: Date) => {
                      setValues({
                        ...values,
                        startDate: moment(value).startOf("day").toISOString()
                      })
                      if (moment(value) > moment(values.endDate)) {
                        setValues({
                          ...values,
                          endDate: moment(value).endOf("day").toISOString()
                        })
                      }
                    }}
                    label="Start Date"
                    identifier="startDate"
                    name="startDate"
                    required
                    minDate={moment(values.startDate).startOf("day").toDate()}
                    value={values.startDate}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <FlatPickerBar
                    handleDateChange={(value: Date) => {
                      setValues({
                        ...values,
                        endDate: moment(value).endOf("day").toISOString()
                      })
                    }}
                    label="End Date"
                    identifier="endDate"
                    name="endDate"
                    value={values.endDate}
                    minDate={moment(values.startDate).toDate()}
                  />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <FormControl variant="outlined" fullWidth  >
                    <Select
                      value={values.set}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          set: e.target.value
                        })
                      }}
                      className={classes.selectMenu}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      {BundleSets.map(
                        (set: string, key: number) => {
                          return (
                            <MenuItem value={set} key={key}>
                              <Typography style={{ fontSize: '12px' }}> {set.replace("_", " ")}</Typography>
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Field
                    component={NumericFormat}
                    customInput={TextField}
                    name="shippingCharges"
                    label="Shipping Charges For Bundle"
                    placeholder="Shipping charges applied on bundle"
                    variant="outlined"
                    allowNegative={false}
                    value={values.shippingCharges}
                    fullWidth
                    decimalScale={2}
                    onValueChange={(eventValues: NumberFormatValues) => {
                      setValues({
                        ...values,
                        shippingCharges: eventValues.floatValue
                      })

                    }}
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Field
                    as={FormControlLabel}
                    name="booksSalebleOnlyViaBundle"
                    control={
                      <Switch
                        checked={values.booksSalebleOnlyViaBundle}
                        onClick={(e: any) => {
                          setValues({ ...values, booksSalebleOnlyViaBundle: !values.booksSalebleOnlyViaBundle })
                        }}
                        value="checkedSwitch"
                      />
                    }
                    label="Do you want books in this bundle to sell only via this bundle offer?"
                  />
                </Grid>
              </Grid>

              {/* IMAGE AND DESCRIPTION */}
              <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} style={{ marginTop: '2rem' }}>
                {/* IAMGE  */}
                <Grid item xl={4} lg={5} md={6} sm={12} xs={12} >
                  <Card>
                    {thumbnail ?
                      <CardMedia
                        component="img"
                        alt="Bundle Cover"
                        title="Bundle Cover"
                        image={thumbnail}
                      /> :
                      <Grid container justify="center" alignContent="center" xs={12} style={{ height: 160 }}>
                        <Typography variant='h3'>Add Cover Image</Typography>
                      </Grid>
                    }
                    <CardActions style={{ backgroundColor: "#fcf5e8" }}>
                      <Typography style={{ marginTop: "0.2rem", color: "#808080" }}>Bundle Cover</Typography>
                      <ImageUploader id={'BundleImage'} handleSave={uploadDocument} />
                    </CardActions>
                  </Card>
                </Grid>

                {/* Description and term and condition  */}
                <Grid container xl={8} lg={7} md={6} sm={6} xs={12} spacing={2} direction='column' style={{ minHeight: '10rem', padding: '1rem' }} >
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={6} style={{ minWidth: '100%' }}>
                    <Field
                      component={TextareaAutosize}
                      fullWidth
                      placeholder="Add some description for bundle ..."
                      name="description"
                      rowsMin={7}
                      rowsMax={7}
                      className={classes.textAreaStyle}
                      value={values.description}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          description: e.target.value
                        })
                      }}
                    ></Field>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={6} style={{ minWidth: '100%' }}>
                    <Field
                      component={TextareaAutosize}
                      fullWidth
                      placeholder="Add some Terms and conditions for bundle ..."
                      name="termsAndConditions"
                      rowsMin={7}
                      rowsMax={7}
                      className={classes.textAreaStyle}
                      value={values.termsAndConditions}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          termsAndConditions: e.target.value
                        })
                      }}
                    ></Field>
                  </Grid>
                </Grid>
              </Grid>

              {/* AVAILABILITY */}
              <Grid container xl={12} lg={12} md={12} sm={12} xs={12} spacing={1} style={{ marginTop: '2rem' }}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography variant='h3'>Select Availability</Typography>
                </Grid>

                {/* BOOKS */}
                <Grid item xl={10} lg={10} md={9} sm={12} xs={12}>
                  <Field
                    component={Autocomplete}
                    multiple
                    name="books"
                    value={selectedBooksInAC} // to empty the AC on remove all click
                    disablePortal={false}  // true => shows list of option below the field
                    options={booksAC.sort((a, b) => -b.publisherName.localeCompare(a.publisherName))}
                    groupBy={(option: IBook) => option.publisherName}
                    required
                    filterSelectedOptions
                    disableCloseOnSelect
                    filterOptions={limitedBooksListToShow}
                    getOptionLabel={(item: IBook) => item.title}
                    renderTags={(tagValue: IBook[], getTagProps: any) => (  // to show chip with title and book type
                      tagValue.map((option: IBook, index: number) => {
                        return (
                          <Chip
                            style={{ fontSize: "1rem" }}
                            label={`${option.title} -> ${option.type}`}
                            {...getTagProps({ index })}
                          />
                        )
                      }))
                    }
                    renderOption={(option: IBook) => (
                      <Typography style={{ fontSize: "1rem" }}>{`${option.title}    ->  ${option.type}`}</Typography>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Search and select books"
                        label="Books"
                      />)
                    }
                    renderGroup={(params: any) => (
                      <li>
                        <div className={classes.autoCompleteOptionHeader}>{params.group}</div>
                        <div className={classes.autoCompleteOptionItem}>{params.children}</div>
                      </li>
                    )}
                    onChange={(event: any, bookValues: IBook[]) => {
                      setSelectedBooksInAC(bookValues) //user selected options as chip
                    }}
                  />
                </Grid>
                {/* BUTTON  */}
                <Grid container xl={2} lg={2} md={3} sm={12} xs={12} justify='flex-end' spacing={2} style={{ padding: '1rem' }}>
                  <Grid item>
                    <Fab
                      variant="extended"
                      size="medium"
                      type="submit"
                      title="Update bundle and Save it"
                    >
                      Update
                    </Fab>
                  </Grid>
                  {values.contentStatus === BundleOfferContentStatus.DRAFT &&
                    <Grid item>
                      <Fab
                        variant="extended"
                        size="medium"
                        type="button"
                        title="Update bundle and Publish it"
                        onClick={() => setOpenConfirmationDialog(true)}
                      >
                        Publish
                      </Fab>
                    </Grid>
                  }
                </Grid>
              </Grid>

              {/* SELECTED BOOKS TABLE  */}
              {selectedBooks.length > 0 && <SelectedBooksDetail books={selectedBooks} handleShareChange={handleShareChange} handleRemoveAll={handleRemoveAll} />}
            </Form>
          )
          }
        </Formik >
      </Grid >
      <ConfirmationDialog
        isOpen={openConfirmationDialog}
        onCancel={() => setOpenConfirmationDialog(false)}
        onConfirm={() => handleSubmit("publish")}
        title={"Confirm Publishing A bundle"}
        description={`Are you sure, you want to publish this bundle, it will be visible to user from now ?`}

      />
    </Grid >
  )
}

export default UpdateBundle;