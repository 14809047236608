import { Card, CardActions, CardMedia, Fab, Grid, makeStyles, TextareaAutosize, TextField, Typography } from "@material-ui/core";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IAuthorWrite } from "../../../reducers/authors/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS, ResourceTypes } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { uploadFileToS3 } from "../../../utils/s3Util";
import { DragDropImages } from "../../common/DragDropImages";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#EAE9E0",
    marginTop: "1rem",
    padding: "1rem"
  },
  cameraBtn: {
    paddingTop: "0.5rem",
    cursor: "pointer"
  },
  featuredBtn: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    padding: "0.5rem"
  }
}))

const AuthorDetail: React.FC = () => {
  const snackbar = useSnackBar();
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [values, setValues] = useState<IAuthorWrite>({
    id: "",
    name: "",
    popularName: "",
    bio: "",
    image: "",
    featuredFrom: "",
    featuredTill: ""
  });
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.user);
  const [nameskipped, setNameSkipped] = useState(false);

  useEffect(() => {
    if (location && location.search && userState && userState.id) {
      const params = new URLSearchParams(location.search);
      const authorId = params.get("author");
      if (authorId) {
        setIsUpdate(true)
        fetchAuthor(authorId);
      }
    }
  }, [location, userState]);


  const fetchAuthor = (authorId: string) => {
    callApi(PATHNAMES.GET_AUTHOR_BY_ID(authorId), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setValues({
          id: res.data.id || "",
          name: res.data.name || "",
          popularName: res.data.popularName || "",
          bio: res.data.bio || "",
          image: res.data.image || "",
          featuredFrom: res.data.featuredFrom || "",
          featuredTill: res.data.featuredTill || ""
        });
      }
    })
  }

  const uploadAuthor =async (author: IAuthorWrite) => {
    author.name= author.name.trim()
    author.popularName= author.popularName.trim()
    author.bio= author.bio.trim()

    if (!author.name) {
      snackbar({
        message: "Name is required",
        variant: SnackBarVariant.ERROR,
      });
      return;
    }

    if (isUpdate && values.id) {
      const data: any = {
        name: author.name,
        popularName: author.popularName,
        image: author.image,
        bio: author.bio
      }
      if (data.image && data.image.includes("https")) {
        delete data.image
      }
      callApi(PATHNAMES.UPDATE_AUTHOR(values.id), HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      }).then((res) => {
        if (res && res.success) {
          snackbar({
            message: "Author updated successfully",
            variant: SnackBarVariant.SUCCESS
          })
          navigate("/authors")
          setValues({
            id: res.data.id || "",
            name: res.data.name || "",
            popularName: res.data.popularName || "",
            bio: res.data.bio || "",
            image: res.data.image || "",
            featuredFrom: res.data.featuredFrom || "",
            featuredTill: res.data.featuredTill || ""
          })
        }
      })
    } else {
      const data = {
        name: author.name,
        popularName: author.popularName,
        image: author.image,
        bio: author.bio
      }
      callApi(PATHNAMES.CREATE_AUTHOR, HTTP_METHODS.POST, {
        body: JSON.stringify({ data })
      }).then((res) => {
        if (res && res.success) {
          snackbar({
            message: "Author created successfully",
            variant: SnackBarVariant.SUCCESS
          })
          setIsUpdate(true)
          setValues({
            id: res.data.id || "",
            name: res.data.name || "",
            popularName: res.data.popularName || "",
            bio: res.data.bio || "",
            image: res.data.image || ""
          })
        }
      })
    }
  }

  const uploadDocument = async (files: File[]) => {
    const key = await uploadFileToS3({
      file: files[0],
      resourceType: ResourceTypes.AUTHOR,
      isAdmin: true
    });
    const data = { ...values }
    if (data) {
      data.image = key;
      setValues(data)
    }
    setModalOpen(false);
    uploadAuthor(data);
  }

  const isFeatured = () => {
    if (values?.featuredTill) {
      if (moment(values.featuredTill) < moment()) {
        return false
      }
      return true
    }
    return false
  }

  return (
    <Grid container xs={12} spacing={2}>
      <Grid container xs={12} style={{ padding: "1rem" }}>
        <Grid item xs={6}>
          <Typography variant="h2">
            {isUpdate ? "Update" : "New"} Author
          </Typography>
        </Grid>
        <Grid container xs={6} item justify="flex-end">
          {isFeatured() ?
            <Typography variant="h4" className={classes.featuredBtn}>
              Featured till {moment(values.featuredTill).format("DD/MM/YYYY")}
            </Typography> :
            <></>}
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid container xs={2}>
          <Grid container xs={12} style={{ padding: "1rem" }}>
            <Card>
              <CardMedia
                component="img"
                height="120"
                width="120"
                alt="Profile Image"
                title="Profile Image"
                image={values.image}
              />
              <CardActions>
                <Typography style={{ marginTop: "1rem", color: "#808080", height: "2rem" }}>
                  Profile Image
                </Typography>
                <PhotoCameraIcon
                  className={classes.cameraBtn}
                  onClick={() => setModalOpen(true)}
                />
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <Grid container xs={10} spacing={2}>
          <Grid item xs={5}>
            <Typography variant="h4" style={{ color: "#808080" }}>
              Name
            </Typography>
            <TextField
              variant="outlined"
              value={values.name}
              fullWidth
              onChange={(e) => {
                setValues({
                  ...values,
                  name: e.target.value
                })
              }}
              onBlur={() => {
                if (!values.name) {
                  setValues({
                    ...values,
                    name: ""
                  });
                }
                setNameSkipped(true);
              }}
              error={!values.name.trim() && nameskipped} // we are showing an error through red border when name is empty
              helperText={!values.name.trim() && nameskipped && "Name Is Required"}
              style={{ marginTop: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h4" style={{ color: "#808080" }}>
              Popular Name
            </Typography>
            <TextField
              variant="outlined"
              value={values.popularName}
              fullWidth
              onChange={(e) => {
                setValues({
                  ...values,
                  popularName: e.target.value
                })
              }}
              style={{ marginTop: "0.5rem" }}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h4" style={{ color: "#808080" }}>
              Bio
            </Typography>
            <TextareaAutosize
              rowsMin={7}
              value={values.bio}
              onChange={(e) => {
                setValues({
                  ...values,
                  bio: e.target.value
                })
              }}
              style={{ marginTop: "0.5rem", width: "96%" }}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} style={{ marginTop: "2rem" }}>
          <Fab
            size="medium"
            variant="extended"
            onClick={() => {
              uploadAuthor(values);
            }}
          >
            Save
          </Fab>
        </Grid>
      </Grid>
      <div>
        <DragDropImages
          open={modalOpen}
          handleCloseDragDrop={() => setModalOpen(false)}
          handleSave={uploadDocument}
          initialFiles={[]}
          filesLimit={1}
          title={"Upload Image"}
        />
      </div>

    </Grid>
  )
}

export default AuthorDetail;

