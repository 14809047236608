import {
  Chip,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core";
import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import { IBook } from "../../../../reducers/books/types";
import { getAgeRangeValueOrLabel, getCategoryLabelByValue } from "../../../../utils/bookUtils";
import { BookContentTypes, BookTypes } from "../../../../utils/constants";
import UpdateInventory from "./UpdateInventory";

interface IProps {
  book: IBook;
  type: string;
  editable?: boolean;
}

const useStyles = makeStyles((theme) => ({
  subHeading: {
    fontSize: '12px',
    fontWeight: 500,
    color: "#808080"
  },
  subContent: {
    fontSize: '13px',
    fontWeight: 500,
    paddingTop: '0.5rem',
    color: "#000000",
  },
  inventoryText: {
    marginTop: "0.3rem",
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  tagsChip: {
    borderRadius: 4,
    background: "#FAFAFA",
    border: "1px solid #E8E8E8",
    "&.MuiAutocomplete-tag": {
      margin: "10px 0px 5px 10px"
    }
  },
}))

const PhysicalBook: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const [book, setBook] = useState(props.book)
  const [updateInventory, setUpdateInvetory] = useState<boolean>(false)

  useEffect(() => {
    if (props.book) {
      setBook(props.book)
    }
  }, [props.book])

  if (props.type === BookTypes.PAPERBACK) {
    return (
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ marginTop: '0rem' }}>
        {book.hasPaperback && <>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: '0rem' }}>
            <Typography variant='h3' style={{ paddingLeft: '0rem', fontWeight: 200, display: "inline" }} > PAPERBACK</Typography>
            <Typography variant="h4" className={classes.inventoryText} style={{ borderBottom: "1px solid", display: 'inline', margin: "0rem 2rem" }}
              onClick={() => setUpdateInvetory(true)}>
              Update Inventory
            </Typography>
          </Grid>

          {!book?.paperback.isbn ? <></> :
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }}>
              <Typography variant='h5' className={classes.subHeading}>ISBN NUMBER</Typography>
              <Typography variant='h6' className={classes.subContent}>{book?.paperback?.isbn}</Typography>
            </Grid>
          }

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }} >
            <Typography variant='h5' className={classes.subHeading}>PAGES</Typography>
            <Typography variant='h6' className={classes.subContent}>{book?.paperback?.pages}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }} >
            <Typography variant='h5' className={classes.subHeading}> PRICE</Typography>
            <Typography variant='h6' className={classes.subContent}>{`₹ ${book?.pricing?.paperBackPrice}`}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }} >
            <Typography variant='h5' className={classes.subHeading}>LENGTH</Typography>
            <Typography variant='h6' className={classes.subContent}>{`${book?.paperback?.height?.value} cm`}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }} >
            <Typography variant='h5' className={classes.subHeading}>BREADTH</Typography>
            <Typography variant='h6' className={classes.subContent}>{`${book?.paperback?.width?.value} cm`}</Typography>
          </Grid>

          {book?.paperback?.depth?.value && <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }} >
            <Typography variant='h5' className={classes.subHeading}>HEIGHT</Typography>
            <Typography variant='h6' className={classes.subContent}>{`${book?.paperback?.depth?.value} cm`}</Typography>
          </Grid>}

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }} >
            <Typography variant='h5' className={classes.subHeading}>WEIGHT</Typography>
            <Typography variant='h6' className={classes.subContent}>{`${book?.paperback?.weight?.value} gm`}</Typography>
          </Grid>
        </>}
        <UpdateInventory
          open={updateInventory}
          handleClose={() => setUpdateInvetory(false)}
          inventoryType={props.type}
          book={props.book}
        />
      </Grid>
    )
  } else if (props.type === BookTypes.HARDBOUND) {
    return (
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ marginTop: '0rem' }}>
        {book.hasHardbound && <>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: '0rem' }}>
            <Typography variant='h3' style={{ paddingLeft: '0rem', fontWeight: 200, display: "inline" }} > HARDBOUND</Typography>
            <Typography variant="h4" className={classes.inventoryText} style={{ borderBottom: "1px solid", display: 'inline', margin: "0rem 2rem" }}
              onClick={() => setUpdateInvetory(true)}>
              Update Inventory
            </Typography>
          </Grid>

          {!book?.hardbound.isbn ? <></> :
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }}>
              <Typography variant='h5' className={classes.subHeading}>ISBN NUMBER</Typography>
              <Typography variant='h6' className={classes.subContent}>{book?.hardbound?.isbn}</Typography>
            </Grid>}

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }} >
            <Typography variant='h5' className={classes.subHeading}>PAGES</Typography>
            <Typography variant='h6' className={classes.subContent}>{book?.hardbound?.pages}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }} >
            <Typography variant='h5' className={classes.subHeading}> PRICE</Typography>
            <Typography variant='h6' className={classes.subContent}>{`₹ ${book?.pricing?.hardboundPrice}`}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }} >
            <Typography variant='h5' className={classes.subHeading}>LENGTH</Typography>
            <Typography variant='h6' className={classes.subContent}>{`${book?.hardbound?.height?.value} cm`}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }} >
            <Typography variant='h5' className={classes.subHeading}>BREADTH</Typography>
            <Typography variant='h6' className={classes.subContent}>{`${book?.hardbound?.width?.value} cm`}</Typography>
          </Grid>

          {book?.hardbound?.depth?.value && <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }} >
            <Typography variant='h5' className={classes.subHeading}>HEIGHT</Typography>
            <Typography variant='h6' className={classes.subContent}>{`${book?.hardbound?.depth?.value} cm`}</Typography>
          </Grid>}

          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }} >
            <Typography variant='h5' className={classes.subHeading}>WEIGHT</Typography>
            <Typography variant='h6' className={classes.subContent}>{`${book?.hardbound?.weight?.value} gm`}</Typography>
          </Grid>
        </>}
        <UpdateInventory
          open={updateInventory}
          handleClose={() => setUpdateInvetory(false)}
          inventoryType={props.type}
          book={props.book}
        />
      </Grid>)
  } else if (props.type === BookTypes.DIGITAL) {
    return (
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ marginTop: '0rem', marginBottom: '0.3rem' }}>
        {book.hasDigital && <>
          {!book?.pricing?.sellPrice ? <></> :
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: '0rem' }}>
                <Typography variant='h3' style={{ paddingLeft: '0rem', fontWeight: 200 }} > DIGITAL</Typography>
              </Grid>

              {!book?.digital.isbn ? <></> :
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <Typography variant='h5' className={classes.subHeading}>ISBN NUMBER</Typography>
                  <Typography variant='h6' className={classes.subContent}>{book?.digital?.isbn}</Typography>
                </Grid>
              }

              {!book?.pricing.sellPrice ? <></> :
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <Typography variant='h5' className={classes.subHeading}>EBOOK PRICE</Typography>
                  <Typography variant='h6' className={classes.subContent}>{`₹ ${book?.pricing?.sellPrice}`}</Typography>
                </Grid>}
            </>}
        </>}

      </Grid>
    )
  } else if (props.type === BookTypes.RENTAL_DIGITAL) {
    return (
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ marginTop: '0rem', marginBottom: '0.3rem' }}>
        {book.hasRental && <>
          {book.contentType === BookContentTypes.FREE && !book?.pricing?.rentalPrice?.weekly ? <></> :
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: '0rem' }}>
                <Typography variant='h3' style={{ paddingLeft: '0rem', fontWeight: 200 }} > RENTAL</Typography>
              </Grid>

              {!book?.digital.isbn ? <></> :
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <Typography variant='h5' className={classes.subHeading}>ISBN NUMBER</Typography>
                  <Typography variant='h6' className={classes.subContent}>{book?.digital?.isbn}</Typography>
                </Grid>
              }

              {!book?.pricing?.rentalPrice?.weekly ? <></> :
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
                  <Typography variant='h5' className={classes.subHeading}>WEEKLY PRICE</Typography>
                  <Typography variant='h6' className={classes.subContent}>{`₹ ${book?.pricing?.rentalPrice?.weekly}`}</Typography>
                </Grid>}


              {!book?.pricing?.rentalPrice?.fortNightly ? <></> :
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
                  <Typography variant='h5' className={classes.subHeading}> FORTNIGHTLY PRICE</Typography>
                  <Typography variant='h6' className={classes.subContent}>{`₹ ${book?.pricing?.rentalPrice?.fortNightly}`}</Typography>
                </Grid>}

              {!book?.pricing?.rentalPrice?.monthly ? <></> :
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }}>
                  <Typography variant='h5' className={classes.subHeading}>MONTHLY PRICE</Typography>
                  <Typography variant='h6' className={classes.subContent}>{`₹ ${book?.pricing?.rentalPrice?.monthly}`}</Typography>
                </Grid>}
            </>}
        </>}

      </Grid>
    )
  } else if (props.type === BookTypes.AUDIOBOOK) {
    return (
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ marginTop: '0rem', marginBottom: '0.3rem' }}>
        {book.hasAudiobook && <>
          {!book?.audioBook?.price ? <></> :
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingBottom: '0rem' }}>
                <Typography variant='h3' style={{ paddingLeft: '0rem', fontWeight: 200 }} > AUDIOBOOK</Typography>
              </Grid>

              {!book?.audioBook?.totalDuration ? <></> :
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
                  <Typography variant='h5' className={classes.subHeading}> DURATION</Typography>
                  <Typography variant='h6' className={classes.subContent}>{`${book?.audioBook?.totalDuration}`}</Typography>
                </Grid>}

              {!book?.audioBook?.previewMedia ? <></> :
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
                  <Typography variant='h5' className={classes.subHeading}> PREVIEW</Typography>
                  <Typography variant='h6' className={classes.subContent}>{`${book?.audioBook?.previewMedia ? 1 : 0}`}</Typography>
                </Grid>}

              {!book?.audioBook?.frontContent.length ? <></> :
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
                  <Typography variant='h5' className={classes.subHeading}> FRONT CONTENT</Typography>
                  <Typography variant='h6' className={classes.subContent}>{`${book?.audioBook?.frontContent.length}`}</Typography>
                </Grid>}

              {!book?.audioBook?.bodyContent.length ? <></> :
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
                  <Typography variant='h5' className={classes.subHeading}> BODY CONTENT</Typography>
                  <Typography variant='h6' className={classes.subContent}>{`${book?.audioBook?.bodyContent.length}`}</Typography>
                </Grid>}


              {!book?.audioBook?.backContent.length ? <></> :
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
                  <Typography variant='h5' className={classes.subHeading}> BACK CONTENT</Typography>
                  <Typography variant='h6' className={classes.subContent}>{`${book?.audioBook?.backContent.length}`}</Typography>
                </Grid>}

              {!book?.audioBook?.price ? <></> :
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }}>
                  <Typography variant='h5' className={classes.subHeading}>PRICE</Typography>
                  <Typography variant='h6' className={classes.subContent}>{`₹ ${book?.audioBook?.price}`}</Typography>
                </Grid>}
            </>}
        </>}

      </Grid>
    )
  }
  else {
    return (
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} style={{ marginTop: '0rem' }}>
        {book.epubName && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant='h4' className={classes.subHeading}>Ebook File Name</Typography>
          <Typography variant='h6' className={classes.subContent}>{book.epubName.toUpperCase()}</Typography>
        </Grid>}

        {book.preOrderAvailable && <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Typography variant='h4' className={classes.subHeading}>Pre-Order Available</Typography>
          <Typography variant='h6' className={classes.subContent}>Yes</Typography>
        </Grid>}

        {book.preOrderAvailable && book.preOrderStartDate && <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Typography variant='h4' className={classes.subHeading}>Pre-Order Start Date</Typography>
          <Typography variant='h6' className={classes.subContent}>{moment(book.preOrderStartDate).format("DD/MM/YYYY")}</Typography>
        </Grid>}

        {book.preOrderAvailable && book.bookLaunchDate && <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <Typography variant='h5' className={classes.subHeading}>Book Launch Date</Typography>
          <Typography variant='h6' className={classes.subContent}>{moment(book.bookLaunchDate).format("DD/MM/YYYY")}</Typography>
        </Grid>}

        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <Typography variant='h5' className={classes.subHeading}>CATEGORY</Typography>
          <Typography variant='h6' className={classes.subContent}>{book?.category ? getCategoryLabelByValue(book.category) : "Please add Category"}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <Typography variant='h5' className={classes.subHeading}>LANGUAGE</Typography>
          <Typography variant='h6' className={classes.subContent}>{book?.language ? book?.language?.toUpperCase() : "Please add Language"}</Typography>
        </Grid>

        {!props.editable && !book?.originCountry ? <></> :
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4} >
            <Typography variant='h5' className={classes.subHeading}>COUNTRY OF ORIGIN</Typography>
            <Typography variant='h6' className={classes.subContent}>{book?.originCountry?.replace("_", " ")}</Typography>
          </Grid>
        }

        {!props.editable && !book?.ageRange ? <></> :
          <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
            <Typography variant='h5' className={classes.subHeading}>AGE RANGE</Typography>
            <Typography variant='h6' className={classes.subContent}>{getAgeRangeValueOrLabel(true, book) as ReactNode}</Typography>
          </Grid>
        }

        {!props.editable && !book?.digital?.pages ? <></> :
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: '1rem' }}>
            <Typography variant='h5' className={classes.subHeading}>PAGES</Typography>
            <Typography variant='h6' className={classes.subContent}>{book?.digital?.pages}</Typography>
          </Grid>
        }

        {!props.editable && !book?.keywords?.length ? <></> :
          <Grid item xs={12} sm={6} md={12} lg={9} xl={9} style={{ marginBottom: '1rem' }}>
            <Typography variant='h5' className={classes.subHeading}>KEYWORDS</Typography>
            <Grid container xs={12}>
              {book?.keywords?.map(item => {
                return (
                  <Chip
                    classes={{ root: classes.tagsChip }}
                    style={{ margin: '0.5rem 0.5rem 1rem 0rem', background: "#C4C4C4" }}
                    label={item}
                  />
                )
              })
              }
            </Grid>
          </Grid>
        }


      </Grid>
    )
  }
}

export default PhysicalBook;
