import React from "react";
import { NumericFormat } from 'react-number-format';

interface IProps {
  inputRef: (instance: typeof NumericFormat | null) => void;
  onChange: (value: any) => void;
  name: string;
  value: number;
  maxIntegerDigitsLength: number;
  maxMantissaDigitsLength: number;
  hasCurrencyPrefix: boolean;
  allowNegative: boolean;
}

export const FloatInput = React.memo(
  (props: IProps) => {
    const {
      onChange,
      inputRef,
      value,
      name,
      maxMantissaDigitsLength,
      maxIntegerDigitsLength,
      hasCurrencyPrefix,
      allowNegative,
      ...other
    } = props;
    return (
      <NumericFormat
        {...other}
        name={name}
        getInputRef={inputRef}
        value={value / Math.pow(10, maxMantissaDigitsLength)}
        onValueChange={(values: any) => {
          let finalValue;
          if (values.floatValue === 0) {
            finalValue = 0;
          } else {
            let oldValueString = value.toString();
            const newValueString = Math.round(
              parseFloat(values.value) * Math.pow(10, maxMantissaDigitsLength)
            ).toString();
            if (value === 0) {
              finalValue = parseInt(newValueString);
            } else {
              if (newValueString.length < oldValueString.length) {
                finalValue = parseInt(newValueString);
              } else {
                const lengthOverflow =
                  newValueString.length -
                  maxIntegerDigitsLength -
                  maxMantissaDigitsLength;

                if (
                  lengthOverflow > 0 ||
                  newValueString.length === oldValueString.length
                ) {
                  if (newValueString.length !== oldValueString.length) {
                    const diff = newValueString.length - oldValueString.length;
                    oldValueString = (value * Math.pow(10, diff)).toString();
                  }

                  let breakPoint = 0;
                  for (let x = 0; x < newValueString.length; x++) {
                    if (oldValueString[x] !== newValueString[x]) {
                      breakPoint = x;
                      break;
                    }
                  }
                  const finalValueString =
                    newValueString.substr(0, breakPoint + 1) +
                    oldValueString.substr(breakPoint + 1);

                  finalValue = Math.floor(
                    parseInt(finalValueString) /
                    (lengthOverflow > 0 ? Math.pow(10, lengthOverflow) : 1)
                  );
                } else {
                  finalValue = parseInt(newValueString);
                }
              }
            }
          }

          onChange({
            target: {
              name,
              value: finalValue ? finalValue : 0
            }
          });
        }}
        isAllowed={(values) => {
          return true;
        }}
        thousandSeparator
        displayType={"input"}
        decimalSeparator={"."}
        fixedDecimalScale
        decimalScale={maxMantissaDigitsLength}
        allowNegative={allowNegative}
        allowLeadingZeros
        prefix={hasCurrencyPrefix ? "₹" : ""}
        valueIsNumericString
      />
    );
  },
  (prevProps: IProps, nextProps: IProps) => prevProps === nextProps
);
