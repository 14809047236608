import { Avatar, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { IPublisher } from "../../../reducers/publishers/types";
import { getInitials } from "../../../utils/utils";

interface IProps {
  publisher: IPublisher
}

const useStyles = makeStyles((theme) => ({
  mainAvatar: {
    backgroundColor: "#FB8F67",
    width: "3.5rem",
    height: "3.5rem",
  }
}))

const PublisherSummaryCard: React.FC<IProps> = (props) => {
  const classes = useStyles();
  return (
    <Grid container xs={12} style={{ marginTop: "2rem" }}>
      <Grid container xs={3}>
        {props.publisher.profileImageUrl ? 
        <Avatar alt="Cindy Baker" src={props.publisher.profileImageUrl} style={{height: "3.5rem", width: "3.5rem" }} /> : 
        <Avatar className={classes.mainAvatar}>
          {getInitials(props.publisher.name)}
        </Avatar>}
      </Grid>
      <Grid container xs={9}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{ fontWeight: 600 }}>
            {props.publisher.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ marginTop: "2rem" }}>
        <Typography variant="h4" style={{ fontWeight: 500 }}>
          GST:
        </Typography>
        <Typography variant="h4" style={{ fontWeight: 600, display: "inline", marginLeft: "1rem" }}>
          {props.publisher.gstin}
        </Typography>
      </Grid>
    </Grid>
  )
};

export default PublisherSummaryCard;