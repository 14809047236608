import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import PrimaryUser from "./PrimaryUser";
import SecondaryUsers from "./SecondaryUsers";

const AdminSettings: React.FC = () => {
  const userState = useSelector((state: IAppState) => state.user);
  return (
    <Grid container xs={12}>
      <Grid container xs={12}>
        <Grid container xs={12} sm={12} md={6} lg={6} xl={6}>
          <PrimaryUser />
        </Grid>
      </Grid>
      {userState.role?.category === "org" && <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <SecondaryUsers />
      </Grid>}
    </Grid>
  )
}
export default AdminSettings;