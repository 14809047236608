
import { Fab, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";

import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';

interface IDropDownOption {
  label: string;
  value: string;
  startDate: string;
  endDate: string;
}


const dropDownDefaultOptions: IDropDownOption[] = [
  {
    label: "Overall",
    value: "Overall",
    startDate: "",
    endDate: "",
  },
  {
    label: "Last 7 Days",
    value: "Last 7 Days",
    startDate: "",
    endDate: "",
  },
  {
    label: "This Month",
    value: "This Month",
    startDate: "",
    endDate: "",
  },
  {
    label: "Last Month",
    value: "Last Month",
    startDate: "",
    endDate: "",
  },
  {
    label: "Custom Date",
    value: "Custom Date",
    startDate: "",
    endDate: "",
  },
]



// values by 'react-date-range'
interface ICalendarDate {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: string;
};

interface IProps {
  heading: string;
  handleOptionChange: (data: any) => void
}


function getFinancialYears(startDate: Date) {
  let financialYears = [];

  // Start from the year of the given start date
  let currentYear = moment().year();
  // if date is in jan,feb,march then add previous year also
  const startDateMoment = moment(startDate)
  const startDateMonth = startDateMoment.month()
  const isMonthInJanFebMarch = startDateMonth >= 0 && startDateMonth <= 2;  //jan =0 , dec = 11
  const startDateYear = isMonthInJanFebMarch ? startDateMoment.year() - 1 : startDateMoment.year()

  // Iterate until the current year
  while (currentYear >= startDateYear) {
    // Calculate the start and end dates of the financial year
    const startDate = moment({ year: currentYear, month: 3, day: 1 }); // Financial year starts from April 1st
    const endDate = moment({ year: currentYear + 1, month: 2, day: 31 }); // Financial year ends on March 31st of next year
    const financialYear = startDate.format('YYYY') + '-' + endDate.format('YYYY');
    const fnYearInfo = {
      label: 'FY ' + financialYear,
      value: 'FY ' + financialYear,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    }

    financialYears.push(fnYearInfo);
    currentYear--;
  }

  return financialYears;
}


const RevenueDateRangeSelect: React.FC<IProps> = (props) => {
  const userState = useSelector((state: IAppState) => state.user);
  const [dropDown, setDropDown] = useState<IDropDownOption[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState("");

  const [showCalendar, setShowCalendar] = useState<Boolean>(false);
  const [calendarDateApplied, setCalendarDateApplied] = useState<Boolean>(false);
  const [selectedCalendarDate, setSelectedCalendarDate] = useState<ICalendarDate>({
    startDate: new Date(),
    endDate: undefined,
    key: 'selection'
  });
  const [formattedCaledarDate, setFormattedCalendarDate] = useState<string | null>(null) // to show selected calendar date in ui, 


  // fill dropdown options on page load
  useEffect(() => {
    if (userState && userState.spaceId) {
      const _dropDownOptions = getDropDownOptions()
      setDropDown(_dropDownOptions)
      setSelectedDateRange(_dropDownOptions[2].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.id]);


  // handle select menu change
  const handleChange = (event: any) => {
    setSelectedDateRange(event.target.value);
  };


  //  show calendar on custom date option selection
  useEffect(() => {
    if (userState && userState.spaceId && selectedDateRange) {
      if (selectedDateRange === "Custom Date") {
        setShowCalendar(true)
        return
      } else {
        setShowCalendar(false)
        setFormattedCalendarDate(null)
      }

      // for other values handle sending selected option's times
      // send value to parent
      const selectedOption = dropDown.find(option => option.value === selectedDateRange)
      if (selectedOption) {
        const timeObj = {
          startDate: selectedOption.startDate,
          endDate: selectedOption.endDate,
        }
        props.handleOptionChange(timeObj)
      }
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);


  //  special handling for custom Date selection by user
  useEffect(() => {
    if (userState && userState.spaceId && selectedDateRange) {
      if (selectedDateRange === "Custom Date" &&
        selectedCalendarDate.startDate &&
        selectedCalendarDate.endDate &&
        calendarDateApplied
      ) {

        // user has selected a date range in calendar then show date value in dropdown
        const customDateRangeOption = dropDown.find(option => option.value === selectedDateRange)
        if (!customDateRangeOption) {
          return
        }
        const index = dropDown.indexOf(customDateRangeOption)
        const startDate = moment(selectedCalendarDate.startDate).startOf('day').toISOString();
        const endDate = moment(selectedCalendarDate.endDate).endOf('day').toISOString();

        const updatedDropDown = [...dropDown]
        updatedDropDown[index].startDate = startDate
        updatedDropDown[index].endDate = endDate

        setDropDown(updatedDropDown)
        // UI 
        const fortattedDateRange = moment(selectedCalendarDate.startDate).format("DD/MM/YY") + "  -  " + moment(selectedCalendarDate.endDate).format("DD/MM/YY")
        setFormattedCalendarDate(fortattedDateRange)
        // send value to parent
        const timeObj = {
          startDate,
          endDate
        }
        props.handleOptionChange(timeObj)
        setCalendarDateApplied(false)
        setShowCalendar(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarDateApplied]);



  const getDropDownOptions = () => {

    // default options
    let organizationCreateYear
    if (userState && userState.id && userState.createdAt) {
      organizationCreateYear = moment(userState.createdAt).toDate()
    } else {
      return []
    }

    let defaultOptions = dropDownDefaultOptions.map(option => {
      let optionInfo = {
        ...option,
        startDate: "",
        endDate: ""
      }

      if (option.label === "Overall") {
        optionInfo.startDate = moment("2019-01-01").toISOString();
        optionInfo.endDate = moment().endOf('month').toISOString();
      }
      if (option.label === "This Month") {
        optionInfo.startDate = moment().startOf('month').toISOString();
        optionInfo.endDate = moment().endOf('month').toISOString();
      }
      if (option.label === "Last Month") {
        const lastMonth = moment().subtract(1, 'month')
        optionInfo.startDate = lastMonth.startOf("month").toISOString();
        optionInfo.endDate = lastMonth.endOf('month').toISOString();
      }
      if (option.label === "Last 7 Days") {
        const last7Days = moment().subtract(7, "days")
        optionInfo.startDate = last7Days.startOf('day').toISOString();
        optionInfo.endDate = moment().endOf('day').toISOString();
      }

      return optionInfo
    })

    // financial year
    const financialYearsOptions = getFinancialYears(organizationCreateYear)

    return [...defaultOptions, ...financialYearsOptions]
  }

  return (
    <Grid container spacing={2} alignItems="center" xs={12} style={{ padding: "1rem 0rem 0rem 0rem" }}>
      <Grid item xs={6} md={1}>
        <Typography variant="h3" color="primary" style={{ fontWeight: "bold" }}> Revenue :</Typography>
      </Grid>
      <Grid item xs={6} md={2}>
        <FormControl fullWidth variant="outlined" >
          <InputLabel id="revenue-date-range-select-label"
          >Select a Date Range</InputLabel>
          <Select
            labelId="revenue-date-range-select-label"
            id="revenue-date-range-select"
            value={selectedDateRange}
            label="Select a Date Range"
            placeholder="Select a Date Range"
            onChange={handleChange}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              style: {
                fontSize: "2rem"
              },
              getContentAnchorEl: null
            }}
          >
            {dropDown?.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {
        !showCalendar && selectedDateRange === "Custom Date" &&
        <Grid item xs={7} md={2}>
          <TextField
            variant="outlined"
            size="medium"
            fullWidth
            value={formattedCaledarDate}
            InputProps={{
              style: { fontSize: "1rem" },
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="Change Date"
                  onClick={() => setShowCalendar(true)}
                  edge="end"
                  size="small"
                >
                  <EventOutlinedIcon color="primary" />
                </IconButton>
              </InputAdornment>
            }}

          />
        </Grid>
      }


      {/* absolute center popup */}
      {
        showCalendar &&
        <Grid container xs={12}
          justifyContent="center"
          spacing={2}
          style={{
            padding: "1rem",
            position: "absolute",
            zIndex: 1,
            width: "25rem",
            top: "5rem",
            left: "40%",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            backgroundColor: "white",
            animation: "popUp 0.5s ease forwards"
          }}>
          <Grid item>
            <DateRange
              editableDateInputs={false} // prevent user from changing date manually, only selection is allowed
              maxDate={new Date()}
              minDate={moment(userState.createdAt).toDate()}
              onChange={item => {
                const { startDate, endDate, key } = item.selection;
                if (startDate && endDate && key) {
                  setSelectedCalendarDate({
                    startDate,
                    endDate,
                    key
                  });
                }
              }
              }
              moveRangeOnFirstSelection={false}
              ranges={[selectedCalendarDate]}
            />
          </Grid>
          <Grid item>
            <Fab
              variant="extended"
              size="medium"
              onClick={() => {
                setCalendarDateApplied(true)
              }}
            >
              Apply
            </Fab>
          </Grid>

        </Grid>
      }

    </Grid>
  )
};

export default RevenueDateRangeSelect;