import { createSlice } from '@reduxjs/toolkit';

export interface ISidebarState {
  navbarItem: null | string;
  isNavbarOpen: boolean;
  listExpanded: string;
}
const initialState: ISidebarState = {
  isNavbarOpen: true,
  listExpanded: '',
  navbarItem: null
};
//test navbar items thoroughly try to switch between direct assignment on state values or return a new object
// 
const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    getNavbarItem (state, action) {
      state.navbarItem = action.payload;
    },
    setIsNavbarOpen (state, action) {
      state.isNavbarOpen = action.payload;
    },
    updateListExpanded (state, action) {
      state.listExpanded = action.payload;
    }
  }
});

export const { getNavbarItem, setIsNavbarOpen, updateListExpanded } = sidebarSlice.actions;
export default sidebarSlice.reducer;