import { Grid, createStyles, makeStyles } from "@material-ui/core";
import React from "react";


interface IVideoPlayer {
  videoLink: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    videoContainer: {
      maxWidth: "700px",
      position: "relative",
      display: "flex",
      overflow: "hidden",
      borderRadius: "10px",
    },
  }))

const VideoPlayer: React.FC<IVideoPlayer> = (props) => {
  const classes = useStyles();

  return (
    <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
      <Grid container justify="center" className={classes.videoContainer}>
        <video
          style={{ height: "60vh", width: "100%" }}
          controls
          controlsList="nodownload"
          src={props.videoLink}
        />
      </Grid>

    </Grid>)

};

export default VideoPlayer;