import { Box, CircularProgress, Fab, Grid, InputAdornment, TextField, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import callApi from '../../../utils/apiUtil';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import { ErrorHandler } from '../../../utils/utils';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';



const useStyles = makeStyles((theme) => ({
  button1: {
    color: "black!important",
    backgroundColor: "#FFD36E!important",
  },
  numberField: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
}))

interface ISelectedTemplate {
  subject: string;
  body?: string;
  key: string;
}

export interface ISendTestNotificationArgs {
  phoneNumber: string // it is string for db purpose
  subject: string;
  content: string;
  action: string;
  resourceEntityId?: string;
}

interface IAPIRes {
  message: string,
  status: string,
  data: {
    content: {
      [key: string]: {
        subject: string;
        body: string;
        action: string
      }
    }
  }
}

const UpdateTemplates: React.FC = () => {
  const classes = useStyles()
  const snackbar = useSnackBar();
  const [updating, setUpdating] = useState<boolean>(false)
  const [sendingNotificationToTest, setSendingNotificationToTest] = useState<boolean>(false)

  const [actualServerTemplates, setActualServerTemplates] = useState<IAPIRes['data']['content']>()
  const [templates, setTemplates] = useState<ISelectedTemplate[]>([])
  const [templateKeys, setTemplateKeys] = useState<string[]>([]) // only for autocomplte rendering purpose
  const [selectedTemplateKey, setSelectedTemplateKey] = useState<string | null>(null)
  const [selectedTemplate, setSelectedTemplate] = useState<ISelectedTemplate>({
    key: "",
    subject: "",
    body: ""
  })
  const [testNumber, setTestNumber] = useState<string>() // phone number will be sent as string 


  // GET ALL BOOKS
  useEffect(() => {
    getTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const data = templates.find(tempData => tempData.key === selectedTemplateKey)
    if (data) {
      setSelectedTemplate(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateKey])


  const getTemplates = () => {
    callApi(PATHNAMES.GET_RESOURCE_BY_ID("push-message-template"), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        //  just for reference , needed update api call
        updateActualRefAndTemplateListData(res)
        return
      }
    })
  }

  const updateTemplate = async (data: any) => {
    try {
      const res = await callApi(PATHNAMES.UPDATE_RESOURCE_BY_ID("push-message-template"), HTTP_METHODS.PUT, {
        body: JSON.stringify({ data })
      })

      if (!res || !res.success || !res.data) {
        throw new ErrorHandler(res)
      }
      updateActualRefAndTemplateListData(res)
      setUpdating(false)
      snackbar({
        message: "Template Updated ",
        variant: SnackBarVariant.SUCCESS
      })
      return
    } catch (error) {
      throw error
    }

  }

  const updateActualRefAndTemplateListData = (res: IAPIRes) => {
    setActualServerTemplates(res.data.content)

    const templateKeys = Object.keys(res.data.content)
    // version content not in proper tempalte format, it is an metadata, removing 
    setTemplateKeys(templateKeys)

    const formattedData = templateKeys.map(key => {
      const data = res.data.content[key]
      return { ...data, key }
    })

    setTemplates(formattedData)
  }


  const handleTestNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    // maximum length of 10 digits
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setTestNumber(inputValue);
    }
  }

  const handleCancelBtn = () => {
    setSelectedTemplate({
      key: "",
      subject: "",
      body: ""
    })
    setSelectedTemplateKey(null)
    setTestNumber(undefined)
    return
  }

  const handleUpdateBtn = async () => {
    try {
      setUpdating(true)
      if (!selectedTemplateKey) {
        return
      }

      // validation of subject and content
      handleTemplateValidation()

      // validation completed, update the template.
      const { key, ...rest } = selectedTemplate
      const data = {
        content: {
          ...actualServerTemplates,
          [selectedTemplateKey]: {
            ...rest
          }
        }
      }
      // api call to update 
      await updateTemplate(data)
      return
    } catch (error: any) {
      snackbar({
        message: error.message,
        variant: SnackBarVariant.ERROR
      })
      setUpdating(false)
      return
    }
  }

  const handleClickToTest = async () => {
    try {
      setSendingNotificationToTest(true)
      if (!testNumber || !testNumber.length) {
        throw new ErrorHandler("Valid Number is required to send Demo Notification")
      }

      if (!selectedTemplate.subject || !selectedTemplate.body) {
        throw new ErrorHandler("Subject and conent is missing for Demo Notification")
      }

      // validation of subject and content
      handleTemplateValidation()

      // api call to send notification
      const data: ISendTestNotificationArgs = {
        phoneNumber: testNumber,
        subject: selectedTemplate.subject,
        content: selectedTemplate.body || "",
        resourceEntityId: "",
        action: ""
      }

      const res = await callApi(PATHNAMES.SEND_TEST_NOTIFICATION(), HTTP_METHODS.POST, {
        body: JSON.stringify({ data })
      })
      if (res && res.success) {
        snackbar({
          message: res.message,
          variant: SnackBarVariant.SUCCESS
        })
      }
      setSendingNotificationToTest(false)
      return
    } catch (error: any) {
      snackbar({
        message: error.message,
        variant: SnackBarVariant.ERROR
      })
      setSendingNotificationToTest(false)
      return
    }
  }


  const handleTemplateValidation = () => {
    try {
      if (!selectedTemplate || !selectedTemplate.subject || !selectedTemplate.body) {
        throw new ErrorHandler("Fill Subject and body before updating")
      }

      const originalTemplate = templates.find(tmplt => tmplt.key === selectedTemplateKey)

      // variable finder regex
      const regex = /#([^#]+)#/g;

      const ogTempltsubjectVariableMatches = [];
      const updatedSubjectVariableMatches = [];
      const ogTempltBodyVariableMatches = [];
      const updatedTempltBodyVariableMatches = [];
      let match;

      // original subject variables
      while ((match = regex.exec(originalTemplate?.subject || "")) !== null) {
        ogTempltsubjectVariableMatches.push(match[0]);
      }
      match = null

      // updated subject variables
      while ((match = regex.exec(selectedTemplate?.subject || "")) !== null) {
        updatedSubjectVariableMatches.push(match[0]);
      }
      match = null

      while ((match = regex.exec(originalTemplate?.body || "")) !== null) {
        ogTempltBodyVariableMatches.push(match[0]);
      }

      match = null

      while ((match = regex.exec(selectedTemplate?.body || "")) !== null) {
        updatedTempltBodyVariableMatches.push(match[0]);
      }


      // variables can be deleted to shorten the message bu, variable name change should not be allowed,
      // as it is a placeholder for server function to fill the info

      // subject match & body match
      const newSubVariables = _.difference(updatedSubjectVariableMatches, ogTempltsubjectVariableMatches)
      const newBodyVariables = _.difference(updatedTempltBodyVariableMatches, ogTempltBodyVariableMatches)

      if (newSubVariables.length) {
        throw new ErrorHandler(`${newSubVariables.join(", ")} : Subject variable have changed, remove them`)
      }
      if (newBodyVariables.length) {
        throw new ErrorHandler(`${newBodyVariables.join(", ")} : Body variable have changed, remove them`)
      }
      return
    } catch (error) {
      throw error
    }
  }

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h2'> Update Basic System Templates</Typography>
      </Grid>

      <Grid container xs={12}>
        {/* template Id select */}
        <Grid item xs={12} sm={5} style={{ padding: "0.6rem" }}>
          <Autocomplete
            autoSelect
            fullWidth
            options={templateKeys}
            value={selectedTemplateKey}
            onInputChange={(event, newInputValue) => {
              setSelectedTemplateKey(newInputValue);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  required
                  label="Template ID"
                  variant="outlined"
                  placeholder="Search or Select template" />
              )
            }}
          />
        </Grid>

        {/* instructions */}
        <Grid item xs={12} sm={7} style={{ padding: "0.6rem" }}>
          {/* add more notes f needed */}
          {[
            "Note: Don't add new dynamic variable or change the spelling of variables in #value#.",
            "Do Test before saving changes",
          ].map(item => {
            return (<Typography variant='h6' style={{ color: 'red', padding: "0.3rem" }}> {`• ${item}`}</Typography>)
          })}
        </Grid>
      </Grid>

      {/* subject and content */}
      <Grid container xs={12}>
        <Grid item xs={12} md={5} style={{ padding: "0.6rem" }}>
          <TextField
            style={{ backgroundColor: "#E8E8E8" }}
            fullWidth
            onChange={(e) => {
              setSelectedTemplate({
                ...selectedTemplate,
                subject: e.target.value
              })
            }}
            placeholder={"Subject"}
            InputProps={{
              style: { fontSize: '1rem' }
            }}
            value={selectedTemplate?.subject}
            name={"subject"}
            variant='outlined'
          />
        </Grid>
        <Grid item xs={12} md={7} style={{ padding: "0.6rem" }}>
          <TextField
            style={{ backgroundColor: "#E8E8E8", fontSize: "3rem" }}
            fullWidth
            onChange={(e) => {
              setSelectedTemplate({
                ...selectedTemplate,
                body: e.target.value
              })
            }}
            InputProps={{
              style: { fontSize: '1rem' }
            }}
            placeholder={"Content"}
            value={selectedTemplate?.body}
            name={"content"}
            variant='outlined'
          />
        </Grid>

      </Grid>
      <Grid container xs={12}>
        <Grid container xs={12} lg={7} alignItems='center'>
          <Grid item xs={12} md={4} style={{ padding: "0.6rem" }}>
            <TextField
              label="Phone Number"
              type="numberic"
              className={classes.numberField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {`+91 `}
                  </InputAdornment>
                ),
              }}
              value={testNumber}
              onChange={handleTestNumberChange}
              variant="filled" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Fab
              variant="extended"
              size="medium"
              className={classes.button1}
              onClick={handleClickToTest}
            >
              {sendingNotificationToTest ?
                <CircularProgress size={'1rem'} style={{ color: "black" }} /> :
                "click to test"
              }
            </Fab>
          </Grid>
        </Grid>
        <Grid container xs={12} lg={5} justify='flex-end'>
          <Grid container item xs={12} justify="flex-end" alignItems='center' style={{ padding: "0.6rem" }}>
            <Fab
              variant="extended"
              size="medium"
              onClick={handleUpdateBtn}
            >
              {updating ?
                <CircularProgress size={'1rem'} style={{ color: "white" }} />
                :
                "Update"
              }
            </Fab>
            <Box
              pl={2}
            />
            <Fab
              variant="extended"
              size="medium"
              className={'blackBackButton'}
              onClick={handleCancelBtn}
            >Cancel
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}


export default UpdateTemplates;