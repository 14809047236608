import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
// import UserActions from '../../../actions/user/userActions';
import { updateIamUser } from "../../../reducers/user/reducer";
import { IAppState } from '../../../store';
import callApi from '../../../utils/apiUtil';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import { useSnackBar } from '../../common/SnackBarContext/SnackBarContext';
import { SnackBarVariant } from '../../common/SnackbarWrapper/SnackbarWrapper';

const OrderTaxesAndFees: React.FC = () => {
  const user = useSelector((state: IAppState) => state.user)
  const snackbar = useSnackBar();
  const dispatch = useDispatch();

  const [digitalBookTax, setDigitalBookTax] = useState<number | undefined>(0)
  const [paperBookTax, setPaperBookTax] = useState<number | undefined>(0)
  const [audioBookTax, setAudioBookTax] = useState<number | undefined>(0)
  const [writerSeriesTax, setwriterSeriesTax] = useState<number | undefined>(0)
  const [shippingCharges, setShippingCharges] = useState<number | undefined>(0)
  const [freeShippingOrder, setFreeShippingOrder] = useState<number | undefined>(0)
  const [platformFee, setPlatformFee] = useState<number | undefined>(0)

  const [edit, setEdit] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)

  useEffect(() => {
    loadAllFeesAndTaxes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id])


  const loadAllFeesAndTaxes = () => {
    setDigitalBookTax(user.digitalBookGstPercentage || 0)
    setPaperBookTax(user.paperBookGstPercentage || 0)
    setAudioBookTax(user.audioBookGstPercentage || 0)
    setwriterSeriesTax(user.writerSeriesGstPercentage || 0)
    setFreeShippingOrder(user.minimumOrderValueForFreeShipping || 0)
    setShippingCharges(user.shippingCharges || 0)
    setPlatformFee(user.platformConvenienceFee || 0)
  }

  const handleChange = () => setEdit(true)
  const handleCancel = () => setEdit(false)

  const updateAllFeesAndTaxes = async () => {
    if (updating) {
      return
    }
    setUpdating(true)
    const res = await callApi(PATHNAMES.UPDATE_PLATFORM_GST_AND_CHARGES_FEES(user.id), HTTP_METHODS.PUT, {
      body: JSON.stringify({
        data: {
          platformConvenienceFee: platformFee,
          digitalBookGstPercentage: digitalBookTax,
          paperBookGstPercentage: paperBookTax,
          audioBookGstPercentage: audioBookTax,
          writerSeriesGstPercentage: writerSeriesTax,
          minimumOrderValueForFreeShipping: freeShippingOrder,
          shippingCharges
        }
      })
    })

    if (res && res.success && res.data) {
      setPlatformFee(res.data.platformConvenienceFee)
      setDigitalBookTax(res.data.digitalBookGstPercentage)
      setPaperBookTax(res.data.paperBookGstPercentage)
      setAudioBookTax(res.data.audioBookGstPercentage)
      setwriterSeriesTax(res.data.writerSeriesGstPercentage)
      setFreeShippingOrder(res.data.minimumOrderValueForFreeShipping)
      setShippingCharges(res.data.shippingCharges)

      dispatch(updateIamUser({
        ...user,
        platformConvenienceFee: platformFee,
        digitalBookGstPercentage: digitalBookTax,
        paperBookGstPercentage: paperBookTax,
        audioBookGstPercentage: audioBookTax,
        writerSeriesGstPercentage: writerSeriesTax,
        minimumOrderValueForFreeShipping: freeShippingOrder,
        shippingCharges
      }));
      snackbar({
        message: res.message || "updated successfully",
        variant: SnackBarVariant.SUCCESS
      })
    } else {
      snackbar({
        message: res.message || "Something went wrong",
        variant: SnackBarVariant.ERROR
      })
    }
    setUpdating(false)
    setEdit(false)
  }

  return (
    <Grid container xs={12} style={{ padding: "1rem 0rem 2rem 0rem", }} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" style={{ fontWeight: "bolder" }}>Platform Taxes, Charges and Fees</Typography>
      </Grid>
      <Grid container xs={12} spacing={2} style={{ padding: "1rem 1rem" }}>
        <Grid item xs={'auto'}>
          <Typography variant='h4' style={{ paddingBottom: "1rem", fontWeight: 400 }}>Digital Book Tax</Typography>
          <NumericFormat
            customInput={TextField}
            label=""
            disabled={!edit}
            placeholder="Add Digital Book GST "
            allowNegative={false}
            decimalScale={2}
            value={digitalBookTax}
            InputProps={{
              style: { color: "black" },
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            onValueChange={(eventValues: NumberFormatValues) => {
              setDigitalBookTax(eventValues.floatValue)
            }}
          />
        </Grid>
        <Grid item xs={'auto'}>
          <Typography variant='h4' style={{ paddingBottom: "1rem", fontWeight: 400 }}>Paper/Hardbound Book Tax</Typography>
          <NumericFormat
            customInput={TextField}
            label=""
            disabled={!edit}
            placeholder="Add Paper Book  GST "
            allowNegative={false}
            decimalScale={2}
            value={paperBookTax}
            InputProps={{
              style: { color: "black" },
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            onValueChange={(eventValues: NumberFormatValues) => {
              setPaperBookTax(eventValues.floatValue)
            }}
          />
        </Grid>
        <Grid item xs={'auto'}>
          <Typography variant='h4' style={{ paddingBottom: "1rem", fontWeight: 400 }}>Audio Book Tax</Typography>
          <NumericFormat
            customInput={TextField}
            label=""
            disabled={!edit}
            placeholder="Add Audio Book GST "
            allowNegative={false}
            decimalScale={2}
            value={audioBookTax}
            InputProps={{
              style: { color: "black" },
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            onValueChange={(eventValues: NumberFormatValues) => {
              setAudioBookTax(eventValues.floatValue)
            }}
          />
        </Grid>
        <Grid item xs={'auto'}>
          <Typography variant='h4' style={{ paddingBottom: "1rem", fontWeight: 400 }}>Series Tax</Typography>
          <NumericFormat
            customInput={TextField}
            label=""
            disabled={!edit}
            placeholder="Add Writer Series GST "
            allowNegative={false}
            decimalScale={2}
            value={writerSeriesTax}
            InputProps={{
              style: { color: "black" },
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            onValueChange={(eventValues: NumberFormatValues) => {
              setwriterSeriesTax(eventValues.floatValue)
            }}
          />
        </Grid>
        <Grid item xs={'auto'}>
          <Typography variant='h4' style={{ paddingBottom: "1rem", fontWeight: 400 }}>Minimum Order for Free Shipping</Typography>
          <NumericFormat
            customInput={TextField}
            label=""
            disabled={!edit}
            placeholder="Add Minimum Order Free Shipping "
            allowNegative={false}
            decimalScale={2}
            value={freeShippingOrder}
            InputProps={{
              style: { color: "black" },
              startAdornment: <InputAdornment position="start">₹</InputAdornment>
            }}
            onValueChange={(eventValues: NumberFormatValues) => {
              setFreeShippingOrder(eventValues.floatValue)
            }}
          />
        </Grid>
        <Grid item xs={'auto'}>
          <Typography variant='h4' style={{ paddingBottom: "1rem", fontWeight: 400 }}>Shipping Charge</Typography>
          <NumericFormat
            customInput={TextField}
            label=""
            disabled={!edit}
            placeholder="Add Shipping Charges"
            allowNegative={false}
            decimalScale={2}
            value={shippingCharges}
            InputProps={{
              style: { color: "black" },
              startAdornment: <InputAdornment position="start">₹</InputAdornment>
            }}
            onValueChange={(eventValues: NumberFormatValues) => {
              setShippingCharges(eventValues.floatValue)
            }}
          />
        </Grid>
        <Grid item xs={'auto'}>
          <Typography variant='h4' style={{ paddingBottom: "1rem", fontWeight: 400 }}>Convenience Fee</Typography>
          <NumericFormat
            customInput={TextField}
            label=""
            disabled={!edit}
            placeholder="Add Convenience Fee"
            allowNegative={false}
            decimalScale={2}
            value={platformFee}
            InputProps={{
              style: { color: "black" },
              startAdornment: <InputAdornment position="start">₹</InputAdornment>
            }}
            onValueChange={(eventValues: NumberFormatValues) => {
              setPlatformFee(eventValues.floatValue)
            }}
          />
        </Grid>

        <Grid item container xs={6} justify='flex-start' alignContent="center" alignItems='center'  >
          {edit ?
            <Grid item xs={'auto'}>
              <Typography variant="h4" color='primary' style={{ display: "inline", fontWeight: 600, cursor: "pointer", marginRight: "1rem" }}
                onClick={updateAllFeesAndTaxes}>
                Save
              </Typography>
              <Typography variant="h4" style={{ display: "inline", fontWeight: 600, cursor: "pointer" }}
                onClick={handleCancel}>
                Cancel
              </Typography>
            </Grid>
            :
            <Grid item xs={"auto"}>
              <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer" }} color="primary"
                onClick={handleChange}>
                Change
              </Typography>
            </Grid>
          }
        </Grid>
      </Grid>


    </Grid>
  )
}

export default OrderTaxesAndFees;