import {
  Box,
  Card,
  CardActions,
  CardMedia,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  makeStyles,
  Paper, Typography
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField as InputField } from "formik-material-ui";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { updateIamUser } from "../../../reducers/user/reducer";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { DEFAULT_COUNTRY_CODE, HTTP_METHODS, NUMBERS_REGEX, ResourceTypes } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { uploadFileToS3 } from "../../../utils/s3Util";
import ImageUploader from "../../common/ImageUploader";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import UserSummary from "./UserSummary";



const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: "1rem",
      padding: "1rem",
      width: "100%",
      marginRight: "1rem"
    },
    addButton: {
      color: "black!important",
      backgroundColor: "#FFD36E!important",
      marginRight: "1rem",
    },
    cameraBtn: {
      paddingTop: "0.5rem",
      cursor: "pointer"
    }
  })
);

const PrimaryUser: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const user = useSelector((state: IAppState) => state.user);
  const [profileImage, setProfileImage] = useState<string>(user.profileImageUrl || "");
  const [values, setValues] = useState<any>({
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    phone: user.contactNumber?.phoneNumber || "",
  });
  const [open, setOpen] = useState<boolean>(false);


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };


  const uploadDocument = async (file: File) => {
    const key = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.IAM_USER_PROFILE,
      isAdmin: false,
      publisherId: user.spaceId
    });
    const data = { ...values }
    if (data) {
      data.profileImageUrl = key;
      setValues(data)
    }
    const res = await callApi(PATHNAMES.GET_SIGNED_URL, HTTP_METHODS.POST, {
      body: JSON.stringify({ data: { key } })
    });
    if (res && res.success && res.data) {
      setProfileImage(res.data)
    }
  }

  const handleSubmit = () => {
    const data: any = {
      firstName: values.firstName?.trim(),
      lastName: values.lastName?.trim(),
      contactNumber: {
        phoneNumber: values.phone?.trim(),
        countryCode: DEFAULT_COUNTRY_CODE
      }
    }
    if (values.profileImageUrl) {
      data.profileImageUrl = values.profileImageUrl
    }
    callApi(PATHNAMES.UPDATE_IAM_USER_SELF(user.id), HTTP_METHODS.PATCH, {
      body: JSON.stringify({ data })
    }).then((res) => {
      if (res && res.success && res.data) {
        snackbar({
          message: "Profile updated successfully",
          variant: SnackBarVariant.SUCCESS
        })
        const userData = {
          ...user,
          ...res.data
        }
        dispatch(updateIamUser(userData));
        setOpen(false)
      }
    })
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("Enter Fist name")
      .min(3, "First name is too short - should be 3 chars minimum")
      .max(20, "First name is too long - should be 20 chars maximum"),
    lastName: Yup.lazy(value => {
      if (value) {
        return Yup.string().trim().required()
          .min(3, "Last name is too short - should be 3 chars minimum")
          .max(20, "Last name is too long - should be 20 chars maximum")
      }
      return Yup.string().trim().notRequired()
    }),
    phone: Yup.string().trim().optional()
      .matches(NUMBERS_REGEX, "Only Numbers are allowed, no other character please")
      .min(10, "Only 10 digits allowed")
      .max(10, "Only 10 digits allowed"),
  });

  // disable will be false when values are valid and true
  // const isDisable = () => !validationSchema.isValidSync(values)

  return (
    <Grid container xs={12}>
      <Paper className={classes.root}>
        <Grid container xs={12}>
          <Grid container item xs={8} style={{ marginBottom: "2rem" }}>
            <Typography variant="h2" style={{ fontWeight: 600 }}>
              Primary User
            </Typography>
          </Grid>
          <Grid container xs={4} justify="flex-end">
            <Typography variant="h4" style={{ fontWeight: 600, cursor: "pointer" }} color="primary"
              onClick={() => setOpen(true)}>
              Edit
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={8}>
          <UserSummary showUpdateRole={false} user={user} fetchSecondaryUsers={() => ""} />
        </Grid>
      </Paper>
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Grid container xs={12}>
            <Typography variant="h3">Edit Primary User</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true} style={{ minHeight: '14rem' }} >
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={values}
            onSubmit={(data, { setSubmitting }) => {
              handleSubmit();
              setSubmitting(false);
            }}
          >
            {() => (
              <Form style={{ width: "100%" }}>
                <Grid container xs={12} spacing={2}>
                  <Grid container xs={3} style={{ padding: "1rem" }}>
                    <Card style={{ height: '13rem', maxWidth: '8rem' }}>
                      {profileImage ?
                        <CardMedia
                          component="img"
                          height="160"
                          alt="Profile Image"
                          title="Profile Image"
                          image={profileImage}
                        /> :
                        <Grid container justify="center" alignContent="center" xs={12} style={{ height: 160 }}>
                          <Typography variant='h3'>Add Image</Typography>
                        </Grid>
                      }
                      <CardActions>
                        <Typography variant='h5' style={{ marginTop: "0.2rem", color: "#808080" }}>Profile Image</Typography>
                        <ImageUploader id={'PrimaryUser'} handleSave={uploadDocument} />
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid container xs={9} spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        component={InputField}
                        fullWidth
                        required
                        variant="outlined"
                        label="First Name"
                        placeholder="First Name"
                        name="firstName"
                        InputProps={{
                          value: values.firstName,
                          onChange: handleChange
                        }}
                        style={{ marginTop: "0.5rem" }}
                      ></Field>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                      <Field
                        component={InputField}
                        fullWidth
                        variant="outlined"
                        label="Last Name"
                        placeholder="Last Name"
                        name="lastName"
                        InputProps={{
                          value: values.lastName,
                          onChange: handleChange
                        }}
                        style={{ marginTop: "0.5rem" }}
                      ></Field>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                      <Field
                        component={InputField}
                        fullWidth
                        required
                        variant="outlined"
                        label="Phone Number"
                        placeholder="Phone Number"
                        name="phone"
                        inputProps={{ maxLength: 10 }}
                        InputProps={{
                          value: values.phone,
                          onChange: handleChange
                        }}
                        style={{ marginTop: "0.5rem" }}
                      ></Field>
                    </Grid>
                  </Grid>
                </Grid>
                <DialogActions >
                  <Grid container item xs={12} justify="flex-end" style={{ marginBottom: '0rem' }}>
                    <Fab
                      size="medium"
                      variant="extended"
                      className="blackBackButton"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </Fab>
                    <Box pl={2}></Box>
                    <Fab
                      size="medium"
                      variant="extended"
                      type='submit'
                    >
                      Proceed
                    </Fab>
                  </Grid>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default PrimaryUser;