import {
  Button,
  makeStyles,
} from "@material-ui/core";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import React from "react";
import { isValidImage } from "../../utils/bookUtils";
import { imageCompressor } from "../../utils/utils";
import { useSnackBar } from "../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../common/SnackbarWrapper/SnackbarWrapper";

interface IProps {
  id: string;
  handleSave: (files: File) => void;
  buttonClassName?: any
  cameraIconClassName?: any
}

export interface IDigitalBookInput {
  file: any;
  showLoader: boolean;
  uploadComplete: boolean;
}

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '0rem',
    padding: '0rem',
    "&:hover": {
      backgroundColor: "#FFF"
    }
  },
  cameraIcon: {
    paddingTop: "0.5rem",
    cursor: "pointer"
  },
}));

const ImageUploader: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const snackbar = useSnackBar();

  const handleCompression = async (originalImage: File) => {
    try {
      return await imageCompressor(originalImage);
    } catch (error) {
      throw error;
    }
  }
  return (
    <>
      <input
        id={props.id}
        style={{ display: "none" }}
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        onChange={async (e) => {
          if (e.target.files?.length) {
            const isImage = isValidImage(e.target.files[0])
            if (!isImage) {
              snackbar({
                message: "Only jpg/jpeg/png are allowed",
                variant: SnackBarVariant.WARNING
              })
            }
            if (isImage) {
              const compressedImage = await handleCompression(e.target.files[0]);
              if (!compressedImage) {
                snackbar({
                  message: "Unable to upload the Image",
                  variant: SnackBarVariant.ERROR
                })
                return;
              }
              else {
                props.handleSave(compressedImage);
              }
            }
          }
        }}
      />
      <label htmlFor={props.id} style={{ all: "initial" }}>
        <Button
          component="span"
          disableRipple
          className={`${classes.button} ${props.buttonClassName}`}
        >
          <PhotoCameraIcon className={`${classes.cameraIcon} ${props.cameraIconClassName}`} />
        </Button>
      </label></>
  );
};

export default ImageUploader;
