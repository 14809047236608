import { Grid, Paper, Tooltip, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import callApi from '../../../utils/apiUtil';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import CircularLoader from '../../common/CircularLoader';

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      backgroundColor: "#FAFAFA",
      margin: "1rem 1rem",
      minWidth: "13rem",
      transition: "transform .3s",
      "&:hover": {
        transform: "scale(1.1)"
      }
    },
    selection: {
      "& :hover": {
        backgroundColor: "#CBF7B8",
        cursor: "pointer",
      }
    },
    papercolor: {
      backgroundColor: "#FFDED8",
    },
    tooltip: {
      fontSize: "1rem",
    },
    loader: {
      margin: "2rem 0rem"
    }
  })
);

interface IStatsCardProp {
  title: string;
  cardType: string;
  cardSelected: boolean;
  handleClick: (type: string) => void;
}

const StatsCard: React.FC<IStatsCardProp> = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [count, setCount] = useState<number>();


  useEffect(() => {
    fetchMobileDeviceStats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchMobileDeviceStats = () => {
    callApi(PATHNAMES.GET_MOBILE_DEVICE_STATS(props.cardType), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setCount(res.data.data)
        setLoading(false)
      }
    })
  }

  return (
    <Tooltip placement='top' title={`Click to see ${props.title} list`} classes={{ tooltip: classes.tooltip }}>
      <Grid item xs={'auto'} spacing={2} className={props.cardSelected ? "" : classes.selection} >
        <Grid item xs='auto' className={classes.card}>
          <Paper elevation={3} style={{ borderRadius: "14px" }} className={props.cardSelected ? classes.papercolor : ""}>
            {loading ?
              <CircularLoader className={classes.loader} /> :
              <div onClick={() => {
                props.handleClick(props.cardType)
              }}>
                <Grid container justifyContent="center" style={{ padding: "1rem 1rem 0rem 1rem" }}>
                  <Typography variant="h3" style={{ color: "#6c6c6c" }} >{props.title}</Typography>
                </Grid>
                <Grid container justifyContent="center" style={{ padding: "1rem 1rem" }}>
                  <Typography align="center" variant="h2" style={{ fontWeight: 500 }}>{count}</Typography>
                </Grid>
              </div >
            }
          </Paper>
        </Grid>
      </Grid>
    </Tooltip>
  )
}

export default StatsCard;