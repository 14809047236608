import { DialogContent, DialogContentText, DialogTitle, Fab, Grid, IconButton, TextField, Typography, createStyles, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateMobileUser } from "../../reducers/mobileUser/reducer";
import { IAppState } from "../../store";
import callApi from "../../utils/apiUtil";
import { HTTP_METHODS } from "../../utils/constants";
import { PATHNAMES } from "../../utils/pathNames";
import { useSnackBar } from "./SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "./SnackbarWrapper/SnackbarWrapper";


const useStyles = makeStyles(() =>
  createStyles({
    dialogPosition: {
      position: 'fixed',
      top: '3.6rem',
      right: 0,
      backgroundColor: "#fcfaf6",
      zIndex: 200,
      width: '26rem',
      height: "100%",
      boxShadow: "0px 4px 12px #D9D9D9",
      overflow: "auto",
      '&::-webkit-scrollbar': {
        display: 'none'
      },
    },
    imgBox: {
      padding: '0.4rem'
    },
    userNamebox: {
      padding: '0.2rem 0.6rem'
    },
    mainAvatar: {
      backgroundColor: "#FB8F67",
      width: "2rem",
      height: "2rem",
    },
    icon: {
      "& :hover": {
        color: 'red'
      }
    },
  }))

interface IGuestUsersNameChange {
  titleMessage: string
  subMessage: string
  open: boolean;
  handleClose: () => void;
}


const GuestUsersNameChange: React.FC<IGuestUsersNameChange> = (props) => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const dispatch = useDispatch();

  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    penName: ''
  })

  const handleUpdatePersonalInfo = async () => {
    if (!personalInfo.firstName) {
      snackbar({
        message: "Add First Name",
        variant: SnackBarVariant.ERROR
      })
      return
    }
    const res = await saveUserDetailsApi(personalInfo)
    if (res && res.success) {
      dispatch(updateMobileUser({ ...mobileUserState, ...personalInfo }))
    }
    return props.handleClose()
  }

  const saveUserDetailsApi = async (data: any) => {
    const res = await callApi(PATHNAMES.UPDATE_MOBILE_USER(mobileUserState.id), HTTP_METHODS.PUT, {
      body: JSON.stringify({ data })
    })
    if (res && res.success) {
      snackbar({
        message: "Your profile updated successfully",
        variant: SnackBarVariant.SUCCESS
      })
      props.handleClose()
    } else {
      snackbar({
        message: res.message || "Error occured while upating profile",
        variant: SnackBarVariant.ERROR
      })
    }
    return res
  }
  return (
    <div
      onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
      hidden={!props.open}
      className={classes.dialogPosition}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container xs={12} style={{ padding: "2rem 0rem" }}>
          <Grid item container justify="space-between" alignItems="center" >
            <Typography variant="h2" color="primary" style={{ fontWeight: 'bolder' }}>
              {props.titleMessage || "Add your Personal infor to continue"}
            </Typography>
            <IconButton style={{ padding: '0.3rem' }} onClick={props.handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} style={{ padding: "2rem 0rem" }}>
            <Typography variant="body1">
              {props.subMessage || ""}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container xs={12} style={{ gap: '1rem 0rem' }}>
            <Grid item xs={12}>
              <TextField
                label="First Name"
                placeholder={mobileUserState.firstName}
                variant="outlined"
                value={personalInfo.firstName}
                required
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  setPersonalInfo({ ...personalInfo, firstName: value })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Name"
                placeholder={mobileUserState.lastName || ""}
                variant="outlined"
                value={personalInfo.lastName}
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  setPersonalInfo({ ...personalInfo, lastName: value })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Pen Name"
                placeholder={mobileUserState.penName || ""}
                value={personalInfo.penName}
                variant="outlined"
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  setPersonalInfo({ ...personalInfo, penName: value })
                }}
              />
            </Grid>
          </Grid>

          <Grid container xs={12} justify="center" style={{ padding: "3rem 1rem" }}>
            <Fab
              variant="extended"
              size="medium"
              onClick={handleUpdatePersonalInfo}
            >
              Submit</Fab>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </div>
  );
}

export default GuestUsersNameChange;
