import { CircularProgress, Fab, FormControlLabel, Grid, Paper, Switch, Typography } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from "moment";
import { MUIDataTableMeta, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { IOffer } from "../../../reducers/offers/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../utils/common.interface";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { DataTable } from "../../common/Datatable/Datatable";
import { Pagination } from "../../common/Datatable/types";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import CustomContextMenu from "../../common/CustomContextMenu/CustomContextMenu";
import { IContextMenuPosition } from "../../common/CustomContextMenu/types";

const OfferType = {
  OFFER: "offer",
  COUPON: "coupon",
  GLOBAL_OFFER: "globalOffer",
}

const Offers: React.FC = () => {
  const snackbar = useSnackBar();
  const navigate = useNavigate();
  const location = useLocation();
  const [offers, setOffers] = useState<IOffer[]>([]);
  const [totalOffers, setTotalOffers] = useState<number>(0);
  const [fetchingOffers, setFetchingOffers] = useState<boolean>(true);
  const userState = useSelector((state: IAppState) => state.user);
  const [offerType, setOfferType] = useState<string>("");
  const [contextMenu, setContextMenu] = useState<IContextMenuPosition | null>(null);
  const [menuAttributes, setMenuAttributes] = useState({
    link: "",
  })

  useEffect(() => {
    if (location && location.pathname && userState && userState.id) {
      const pathName = location.pathname;
      let _offerType: string = ""
      switch (pathName) {
        case "/offers":
          _offerType = OfferType.OFFER
          break;

        case "/coupons":
          _offerType = OfferType.COUPON
          break;

        case "/global-offers":
          _offerType = OfferType.GLOBAL_OFFER
          break;
        default:
          break;
      }
      if (_offerType) {
        setOfferType(_offerType)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState, location.pathname])

  useEffect(() => {
    if (userState && userState.id && userState.spaceId) {
      setFetchingOffers(true);
      fetchOffers({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
      setFetchingOffers(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState, offerType])

  const fetchOffers = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args

    if (offerType === OfferType.COUPON) {
      callApi(PATHNAMES.GET_ORG_OFFERS(OfferType.COUPON, pageNumber, pageSize), HTTP_METHODS.POST, {
        body: JSON.stringify({
          data: {
            searchText,
            sortBy,
            applyFilter,
            filters
          }
        }),
      }).then((offersResponse) => {
        if (offersResponse && offersResponse.success && offersResponse.data) {
          setOffers(offersResponse.data.offers);
          setTotalOffers(offersResponse.data.totalOffers)
        }
      })
    }
    if (offerType === OfferType.OFFER) {
      callApi(PATHNAMES.GET_ORG_OFFERS(OfferType.OFFER, pageNumber, pageSize), HTTP_METHODS.POST, {
        body: JSON.stringify({
          data: {
            searchText,
            sortBy,
            applyFilter,
            filters
          }
        }),
      }).then((offersResponse) => {
        if (offersResponse && offersResponse.success && offersResponse.data) {
          setOffers(offersResponse.data.offers);
          setTotalOffers(offersResponse.data.totalOffers)
        }
      })
    }

    if (offerType === OfferType.GLOBAL_OFFER) {
      callApi(PATHNAMES.GET_ORG_OFFERS(OfferType.GLOBAL_OFFER, pageNumber, pageSize), HTTP_METHODS.POST, {
        body: JSON.stringify({
          data: {
            searchText,
            sortBy,
            applyFilter,
            filters
          }
        }),
      }).then((offersResponse) => {
        if (offersResponse && offersResponse.success && offersResponse.data) {
          setOffers(offersResponse.data.offers);
          setTotalOffers(offersResponse.data.totalOffers)
        }
      })
    }
  }

  const changeOfferStatus = (offerId: string) => {
    callApi(PATHNAMES.CHANGE_OFFER_STATUS(offerId), HTTP_METHODS.PATCH).then((res) => {
      if (res && res.success) {
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: res.message
        })
        if (userState.spaceId) {
          fetchOffers({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
        }
      }
    })
  }

  const handleContextMenuClick = (
    event: React.MouseEvent, offerId: string
  ) => {
    event.preventDefault();
    const value: any = contextMenu === null ? {
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    } : null;
    setContextMenu(value);
    let url: string = ""
    if (offerType === OfferType.COUPON) {
      url = `/update-coupon?id=${offerId}`;
    }
    if (offerType === OfferType.OFFER) {
      url = `/update-offer?id=${offerId}`;
    }
    if (offerType === OfferType.GLOBAL_OFFER) {
      url = `/update-global-offer?id=${offerId}`;
    }
    setMenuAttributes({
      ...menuAttributes,
      link: url
    })
  };

  const convertStatusInBool = (value: string) => value === "active"

  const getOfferApplicableOn = (data: any) => {
    if (data?.bookIds?.length) { return `Seleceted Books` }
    else if (data?.categories?.length) { return ` Seleceted Categories` }
    else { return `All Categories` }
  }

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      label: "LifeTime",
      name: "isLifeTime",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      label: "NAME",
      name: "name"
    },
    {
      label: "CODE",
      name: "code"
    },
    {
      label: "DISCOUNT",
      name: "discount",
      options: {
        customBodyRender: (data: any) => data.type === 'percentage' ? `${data.value}%` : `${data.value}₹`
      }
    },
    {
      label: "Applicable",
      name: "applicability",
      options: {
        customBodyRender: getOfferApplicableOn
      }
    },
    {
      label: "Min Order of",
      name: "applicability",
      options: {
        customBodyRender: (data: any) => data.orderValue > 1 ? `${data.orderValue}₹` : "Nill"
      }
    },
    {
      label: "START DATE",
      name: "startDate",
      options: {
        customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY")
      }
    },
    {
      label: "END DATE",
      name: "endDate",
      options: {
        customBodyRender: (data: string, tableMeta: MUIDataTableMeta) => {
          const [,isLifeTime] = tableMeta.rowData
          return isLifeTime ? "Lifetime Offer" : data ? moment(data).format("DD/MM/YYYY") : "-"
        }
      }
    },
    {
      label: "ACTIVE",
      name: "status",
      options: {
        customBodyRender: (data: string) => <FiberManualRecordIcon
          style={{ paddingLeft: "1rem" }}
          fontSize="small"
          htmlColor={data === "active" ? "#5CD89F" : "#FF5C3E"}
        />
      }
    },

    {
      label: "ACTIONS",
      name: "status",
      options: {
        setCellProps: () => ({ style: { padding: '0rem' } }),
        customBodyRender: (value: string, tableMeta: any) => {
          return <div style={{ padding: "0rem" }} >
            <FormControlLabel
              control={
                <Switch
                  checked={convertStatusInBool(value)}
                  onClick={(e: any) => {
                    e.stopPropagation()
                    changeOfferStatus(tableMeta.rowData[0])
                  }}
                  value="checkedSwitch"
                />
              }
              label=""
            />
          </div>
        }
      }
    }
  ]

  const options: MUIDataTableOptions = {
    elevation: 0,
    onRowClick: (rowData: any) => {
      const [id] = rowData
      let url: string = ""
      if (offerType === OfferType.COUPON) {
        url = `/update-coupon?id=${id}`;
      }
      if (offerType === OfferType.OFFER) {
        url = `/update-offer?id=${id}`;
      }
      if (offerType === OfferType.GLOBAL_OFFER) {
        url = `/update-global-offer?id=${id}`;
      }
      navigate(url)
    },
    setRowProps: (row: any, rowIndex: number) => {
      return {
        onContextMenu: (event: React.MouseEvent) => {
          const [offerId] = row
          return handleContextMenuClick(event, offerId)
        }
      }
    },
    filter: false,
    searchPlaceholder: "Search by name,code,discount, order value, status or date",
    count: totalOffers,
  };


  const getTitle = () => {
    let title = ""
    switch (offerType) {
      case OfferType.COUPON:
        title = 'Coupon'
        break;
      case OfferType.OFFER:
        title = 'Offer'
        break;
      case OfferType.GLOBAL_OFFER:
        title = 'Global Offer'
        break;
      default:
        break;
    }
    return title
  }

  const renderButton = () => {
    const buttonLinks = [
      {
        type: OfferType.OFFER,
        title: 'Create Offer',
        link: "/create-offer"
      },
      {
        type: OfferType.COUPON,
        title: 'Create Coupon',
        link: "/create-coupon"
      },
      {
        type: OfferType.GLOBAL_OFFER,
        title: 'Create Global Offer',
        link: "/create-global-offer"
      },
    ]

    const buttonToRender = buttonLinks.find(buttonLink => buttonLink.type === offerType)

    if (!buttonToRender) {
      return <Fab
        variant="extended"
        size="medium"
      >
        <CircularProgress size={'1rem'} style={{ color: "white" }} />
      </Fab>
    }

    return <Fab
      variant="extended"
      size="medium"
      onClick={() => navigate(buttonToRender.link)}
    >
      {buttonToRender.title}
    </Fab>
  }


  if (fetchingOffers) {
    return <CircularProgress />
  }

  return (
    <Grid container xs={12}>
      <Paper elevation={0} style={{ width: "98%", padding: "1rem" }}>
        <Grid container xs={12}>
          <Grid container item xs={4}>
            <Typography variant="h2" style={{ fontWeight: 600, padding: "1rem" }}>
              {getTitle()}
              <Typography variant="h2" color="primary" style={{ display: "inline", marginLeft: "1rem" }}>
                {totalOffers}
              </Typography>
            </Typography>
          </Grid>
          <Grid container item xs={8} justify="flex-end" >
            {renderButton()}
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <DataTable
            title={""}
            rows={offers}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide={true}
            fetchData={fetchOffers}
          />
          <CustomContextMenu
            onClose={() => setContextMenu(null)}
            position={contextMenu}
            attributes={menuAttributes}
          />
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Offers;