import { Icon, Typography } from "@material-ui/core";
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import AllInboxOutlinedIcon from '@material-ui/icons/AllInboxOutlined';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import ExtensionIcon from '@material-ui/icons/ExtensionOutlined';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import ReorderOutlinedIcon from '@material-ui/icons/ReorderOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import React from "react";
import AccountsMenuIcon from "../../../assets/AccountsMenuIcon.svg";
import BillingMenuIcon from "../../../assets/BillingMenuIcon.svg";
import BooksMenuIcon from "../../../assets/BooksMenuIcon.svg";
import DashboardMenuIcon from "../../../assets/DashboardMenuIcon.svg";
import OrdersMenuIcon from "../../../assets/OrdersMenuIcon.svg";
import PaymentMenuIcon from "../../../assets/PaymentMenuIcon.svg";
import SecurityMenuIcon from "../../../assets/SecurityMenuIcon.svg";
import SettingsMenuIcon from "../../../assets/SettingsMenuIcon.svg";
import UserAccountsMenuIcon from "../../../assets/UserAccountsMenuIcon.svg";

import {
  ACCOUNTANT_ALLOWED,
  ADMIN_ALLOWED, ALL_ALLOWED_EXCEPT_SUPERADMIN, EDITOR_ALLOWED,
  SUPERVISOR_ALLOWED
} from "../hoc/Authorization";

const linkTextStyle = {
  fontSize: 14,
  fontFamily: "Poppins",
  fontStyle: "normal",
  lineHeight: "21px",
  marginLeft: "1rem",
  fontweight: 500,
};

const Sub_linkTextStyle = {
  fontSize: 14,
  fontFamily: "Poppins",
  fontStyle: "normal",
  lineHeight: "21px",
  marginLeft: "1rem",
  fontweight: 500,
};

const subLinkIconStyle = {
  height: "16px",
  marginLeft: "2rem",
  fontweight: 500,
  color: "#545454"
};
const subLinkTextStyle = {
  fontSize: 12,
  marginLeft: "1rem",
  fontweight: 500,
};

const iconStyle = {
  height: "20px",
  fontweight: 500,
  color: "#000000"
};

export const NavBarLinks: any = [
  //Admin nav
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Dashboard"}
      </Typography>
    ),
    link: "/admin-dashboard",
    allowed: ADMIN_ALLOWED,
    Icon: <img alt="Dashboard" src={DashboardMenuIcon} style={iconStyle} />,
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Support"}
      </Typography>
    ),
    Icon: <Icon style={{ color: "black" }}><HeadsetMicOutlinedIcon /></Icon>,
    allowed: ADMIN_ALLOWED,
    items: [
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Publishers"}
          </Typography>
        ),
        link: "/publishers",
        allowed: ADMIN_ALLOWED,
        Icon: <Icon style={{ fontSize: "1.6rem", color: "black", marginLeft: "2rem" }}><AccountBalanceOutlinedIcon /></Icon>,
      },
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Authors"}
          </Typography>
        ),
        link: "/authors",
        allowed: ADMIN_ALLOWED,
        Icon: <Icon style={{ fontSize: "1.6rem", color: "black", marginLeft: "2rem" }}><AccountCircleOutlinedIcon /></Icon>,
      },
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Distributors"}
          </Typography>
        ),
        link: "/distributors",
        allowed: ADMIN_ALLOWED,
        Icon: <Icon style={{ fontSize: "1.6rem", color: "black", marginLeft: "2rem" }}><StoreOutlinedIcon /></Icon>,
      },
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Offers"}
          </Typography>
        ),
        link: "/offers",
        allowed: ADMIN_ALLOWED,
        Icon: <Icon style={{ fontSize: "1.6rem", color: "black", marginLeft: "2rem" }}><LocalOfferOutlinedIcon /></Icon>,
      },
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Global Offers"}
          </Typography>
        ),
        link: "/global-offers",
        allowed: ADMIN_ALLOWED,
        Icon: <Icon style={{ fontSize: "1.6rem", color: "black", marginLeft: "2rem" }}><LocalOfferOutlinedIcon /></Icon>,
      },
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Coupons"}
          </Typography>
        ),
        link: "/coupons",
        allowed: ADMIN_ALLOWED,
        Icon: <Icon style={{ fontSize: "1.6rem", color: "black", marginLeft: "2rem" }}><LoyaltyOutlinedIcon /></Icon>,
      },
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Bundles"}
          </Typography>
        ),
        link: "/bundles",
        allowed: ADMIN_ALLOWED,
        Icon: <Icon style={{ fontSize: "1.6rem", color: "black", marginLeft: "2rem" }}><ReorderOutlinedIcon /></Icon>,
      },
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Verified Users"}
          </Typography>
        ),
        link: "/verifiedContentCreators",
        allowed: ADMIN_ALLOWED,
        Icon: <Icon style={{ fontSize: "1.6rem", color: "black", marginLeft: "2rem" }}><SentimentVerySatisfiedOutlinedIcon /></Icon>,
      },
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Platform settings"}
          </Typography>
        ),
        link: "/platformSettings",
        allowed: ADMIN_ALLOWED,
        Icon: <Icon style={{ fontSize: "1.6rem", color: "black", marginLeft: "2rem" }}><BuildOutlinedIcon /></Icon>,
      },
    ],
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Books"}
      </Typography>
    ),
    Icon: <img alt="Books" src={BooksMenuIcon} style={iconStyle} />,
    allowed: EDITOR_ALLOWED,
    items: [
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Publishers"}
          </Typography>
        ),
        link: "/publishers",
      },
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Authors"}
          </Typography>
        ),
        link: "/authors",

      },
      {
        name: (
          <Typography variant="body1" style={subLinkTextStyle}>
            {"All Books"}
          </Typography>
        ),
        link: "/books",
        Icon: (
          <img alt="All Books" src={BooksMenuIcon} style={subLinkIconStyle} />
        ),
      },

    ],
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Settings"}
      </Typography>
    ),
    Icon: <Icon style={{ color: "black" }}><SettingsOutlinedIcon /></Icon>,
    allowed: ADMIN_ALLOWED,
    items: [
      {
        name: (
          <Typography variant="body1" style={subLinkTextStyle}>
            {"User Accounts"}
          </Typography>
        ),
        link: "/settings",
        allowed: ADMIN_ALLOWED,
        Icon: <Icon fontSize="small" style={{ color: "black", marginLeft: "2rem" }}><PeopleOutlineOutlinedIcon /></Icon>,
      },


    ]
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Digital Orders"}
      </Typography>
    ),
    link: "/digital-orders",
    allowed: ADMIN_ALLOWED,
    Icon: <Icon style={{ color: "black" }}><AddToQueueIcon /></Icon>,
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Paperback Orders"}
      </Typography>
    ),
    link: "/incomplete-orders",
    allowed: ADMIN_ALLOWED,
    Icon: <Icon style={{ color: "black" }}><LibraryBooksOutlinedIcon /></Icon>,
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Rachnaye Invetory"}
      </Typography>
    ),
    link: "/rachnaye-inventory",
    allowed: ADMIN_ALLOWED,
    Icon: <Icon style={{ color: "black" }}><AllInboxOutlinedIcon /></Icon>,
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Books By Price"}
      </Typography>
    ),
    link: "/rachnaye-books-by-price",
    allowed: ADMIN_ALLOWED,
    Icon: <Icon style={{ color: "black" }}><AttachMoneyIcon /></Icon>,
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Offline Sales"}
      </Typography>
    ),
    link: "/offline-sales",
    allowed: ADMIN_ALLOWED,
    Icon: <Icon style={{ color: "black" }}><PinDropOutlinedIcon /></Icon>,
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Notify Me Users"}
      </Typography>
    ),
    link: "/notify-me-users",
    allowed: ADMIN_ALLOWED,
    Icon: <Icon style={{ color: "black" }}><NotificationsActiveOutlinedIcon /></Icon>,
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Notification"}
      </Typography>
    ),
    link: "/notification-controls",
    allowed: ADMIN_ALLOWED,
    // Icon: <img alt="" src={NotificationsMenuIcon} style={iconStyle} />,
    Icon: <Icon style={{ color: "black" }}><NotificationsActiveOutlinedIcon /></Icon>,
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Reports"}
      </Typography>
    ),
    allowed: ADMIN_ALLOWED,
    items: [
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Mobile Devices"}
          </Typography>
        ),
        link: "/reports/mobile-devices-stats",
        allowed: ADMIN_ALLOWED,
        Icon: <Icon style={{ fontSize: "1.6rem", color: "black", marginLeft: "2rem" }}><PhoneAndroidIcon /></Icon>,
      },
      {
        name: (
          <Typography variant="body1" style={Sub_linkTextStyle}>
            {"Annual Report"}
          </Typography>
        ),
        link: "/reports/annual-report",
        allowed: ADMIN_ALLOWED,
        Icon: <Icon style={{ fontSize: "1.6rem", color: "black", marginLeft: "2rem" }}><AssessmentIcon /></Icon>,
      },
    ],
    Icon: <Icon style={{ color: "black" }}><AssessmentIcon /></Icon>,
  },
  //Publisher Nav
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Dashboard"}
      </Typography>
    ),
    link: "/dashboard",
    allowed: ALL_ALLOWED_EXCEPT_SUPERADMIN,
    Icon: <img alt="" src={DashboardMenuIcon} style={iconStyle} />,
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Books"}
      </Typography>
    ),
    Icon: <img alt="Books" src={BooksMenuIcon} style={iconStyle} />,
    allowed: SUPERVISOR_ALLOWED,
    items: [
      {
        name: (
          <Typography variant="body1" style={subLinkTextStyle}>
            {"All Books"}
          </Typography>
        ),
        link: "/books",
        Icon: (
          <img alt="All Books" src={BooksMenuIcon} style={subLinkIconStyle} />
        ),
      },
      {
        name: (
          <Typography variant="body1" style={subLinkTextStyle}>
            {"Orders"}
          </Typography>
        ),
        link: "/orders",
        Icon: (
          <img alt="Orders" src={OrdersMenuIcon} style={subLinkIconStyle} />
        ),
      },
    ],
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Manage"}
      </Typography>
    ),
    Icon: (
      <ExtensionIcon style={iconStyle} />
    ),
    allowed: SUPERVISOR_ALLOWED,
    items: [
      {
        name: (
          <Typography variant="body1" style={subLinkTextStyle}>
            {"Distributors"}
          </Typography>
        ),
        link: "/distributors",
        Icon: (
          <img alt="Distributors" src={BooksMenuIcon} style={subLinkIconStyle} />
        ),
      }
    ]
  },
  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Accounts"}
      </Typography>
    ),
    Icon: <img alt="Accounts" src={AccountsMenuIcon} style={iconStyle} />,
    allowed: ACCOUNTANT_ALLOWED,
    items: [
      {
        name: (
          <Typography variant="body1" style={subLinkTextStyle}>
            {"Billing"}
          </Typography>
        ),
        link: "/billing",
        Icon: (
          <img alt="Billing" src={BillingMenuIcon} style={subLinkIconStyle} />
        ),
      },
      {
        name: (
          <Typography variant="body1" style={subLinkTextStyle}>
            {"Payment"}

          </Typography>
        ),
        link: "/payment",
        Icon: (
          <img alt="Payment" src={PaymentMenuIcon} style={subLinkIconStyle} />
        ),
      },
    ],
  },

  {
    name: (
      <Typography variant="body1" style={linkTextStyle}>
        {"Settings"}
      </Typography>
    ),
    Icon: <img alt="Settings" src={SettingsMenuIcon} style={iconStyle} />,
    allowed: ALL_ALLOWED_EXCEPT_SUPERADMIN,
    items: [
      {
        name: (
          <Typography variant="body1" style={subLinkTextStyle}>
            {"User Accounts"}
          </Typography>
        ),
        link: "/settings",
        Icon: (
          <img
            alt="User Accounts"
            src={UserAccountsMenuIcon}
            style={subLinkIconStyle}
          />
        ),
      },
      {
        name: (
          <Typography variant="body1" style={subLinkTextStyle}>
            {"Login and Security"}
          </Typography>
        ),
        link: "/login-and-security",
        Icon: (
          <img alt="Login and Security" src={SecurityMenuIcon} style={subLinkIconStyle} />
        ),
      }
    ]
  }
];
