import { Box, Card, Chip, createStyles, Fab, Grid, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../../assets/rachnayeLight.png";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import CircularLoader from "../../common/CircularLoader";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import DeleteIcon from '@material-ui/icons/Delete';
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";


const useStyles = makeStyles((theme) =>
  createStyles({
    mainPage: {
      padding: "2rem 6rem"
    },
    card: {
      margin: "0.3rem 0rem",
      cursor: 'pointer',
    },
    userInfoHeader: {
      borderBottom: "1px solid black",
      paddingBottom: "0.7rem"
    },
    deleteBtn: {
      padding: "1rem 0.1rem",
      "& :hover": {
        cursor: 'pointer',
        color: theme.palette.primary.main
      }
    },
    postTitle: {
      padding: '1rem 0rem',
      fontWeight: 300,
      fontSize: "1.4rem",
      wordBreak: "break-word",
      lineHeight: "30px"
    },
    imgBox: {
      height: "10rem",
      width: "7rem",
      backgroundColor: "#EEE",
    },
    userNamebox: {
      padding: '0.2rem 0.6rem'
    },
    mainAvatar: {
      backgroundColor: "#FB8F67",
      width: "2.6rem",
      height: "2.6rem",
    },
    userPenNameText: {
      fontSize: "0.8rem"
    },
    tagsChip: {
      borderRadius: 4,
      fontSize: "10px",
      fontWeight: 800,
      background: "#D7E8B4",
      border: "1px solid #E8E8E8",
    },
    qContainer: {
      "& .ql-container": {
        fontSize: "1rem",
        fontFamily: theme.typography.h2.fontFamily,
        border: 'none',
        padding: "1rem 0rem"
      },
      "& .ql-editor": {
        lineHeight: "30px",
        overflowY: "visible",
        padding: "0.6rem 0rem",  // add padding when want to move quill editor data
      }
    },
  })
);


const SeriesDescription: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const snackbar = useSnackBar();
  const [loading, setLoading] = useState<boolean>(true);
  const [series, setSeries] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false)
  const [chapterIdToDelete, setChapterIdToDelete] = useState<string>("") // chapterId


  // fetch post detail
  useEffect(() => {
    getSeriesData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, mobileUserState]);

  const deleteDraftChapter = async () => {
    if (chapterIdToDelete) {
      const res = await callApi(PATHNAMES.DELETE_DRAFT_CHAPTER(series.id, chapterIdToDelete), HTTP_METHODS.DELETE)
      if (res && res.success) {
        const newChapterList = series.chapters.filter((chapter: any) => chapter.id !== chapterIdToDelete)
        setSeries((v: any) => ({ ...v, chapters: newChapterList }))
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: res.message || "Post deleted successfully"
        })
      } else {
        snackbar({
          variant: SnackBarVariant.ERROR,
          message: res.message || "Error while deleting post"
        })
      }
      setOpen(v => false)
      setChapterIdToDelete(v => "")
    }
  }

  useEffect(() => {
    if (series.id) {
      setLoading(false)
    }
  }, [series]);

  const getSeriesData = () => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const seriesId = params.get("id");
      if (seriesId) {
        callApi(PATHNAMES.GET_SERIES_BY_ID(mobileUserState.id, seriesId), HTTP_METHODS.GET).then(res => {
          if (res && res.success && res.data) {
            setSeries(res.data)
          }
        })
        setLoading(false)
      }
    }
  }

  const handlePublishButton1Click = () => {
    navigate(`/portal/rachnaye/series/publish?seriesId=${series.id}`)
  }

  if (loading) {
    return <CircularLoader />
  }


  return (
    // PAGE CONTAINER
    <Grid container spacing={3} xs={12} sm={12} md={12} lg={12} xl={12} justify={"center"} className={classes.mainPage}>
      {/* MIDDLE VIEW CONTAINER */}
      <Grid container xs={9} >
        {/* IMAGE AND TITLE */}
        <Grid container xs={12}>
          <Box className={classes.imgBox}>
            {/* IMAGE COVER CENTER BOX */}
            <img alt={series?.title} style={{ objectFit: "cover", height: "100%", width: "100%" }} src={series?.coverImage || logo} />
          </Box>
          <Box style={{ width: "50%", padding: "0.3rem 2rem" }}>
            <Typography style={{ fontWeight: 300, lineHeight: "2rem" }} variant="h3">{"Title"}</Typography>
            <Typography style={{ fontWeight: 600, }} variant="h3">{series?.title}</Typography>
            <Typography style={{ paddingTop: '1rem' }} variant="body2">{`Created on : - ${moment(series?.createdAt).format("LLL")}`}</Typography>

          </Box>
        </Grid>
        {/* DESCRIPTION & META INFO */}
        <Grid container xs={12}>
          <Grid item xs={12} style={{ padding: "1.5rem 0rem" }}>
            <Grid container justify="space-between">
              <Grid item xs={3}>
                <Typography style={{ fontWeight: 300, lineHeight: "3rem" }} variant="h3">{"Description"}</Typography>
              </Grid>
              <Grid container justify="flex-end" xs={2}>
                <Typography color="primary"
                  onClick={() => navigate(`/portal/rachnaye/series/edit?id=${series.id}`)}
                  style={{ textDecoration: "underline", cursor: "pointer", fontWeight: 300, lineHeight: "3rem", paddingRight: "1rem" }}
                  variant="h6">{"Edit"}</Typography>
              </Grid>
            </Grid>
            <Typography variant="body1" style={{ lineHeight: "1.5rem" }}>{series?.description}</Typography>
          </Grid>
          {series.language ?
            <Grid item xs={3} style={{ padding: "1rem 0rem" }}>
              <Typography style={{ fontWeight: 300, lineHeight: "3rem" }} variant="h3">{"Language"}</Typography>
              <Typography variant="body1">{series?.language?.toUpperCase()}</Typography>
            </Grid> : <></>
          }
          {series.category ?
            <Grid item xs={3} style={{ padding: "1rem 0rem" }}>
              <Typography style={{ fontWeight: 300, lineHeight: "3rem" }} variant="h3">{"Category"}</Typography>
              <Typography variant="body1">{series?.category?.toUpperCase()?.replace("-", " ")}</Typography>
            </Grid> : <></>
          }
          {
            series?.tags?.length > 0 ?
              <Grid item xs={6} style={{ padding: "1rem 0rem" }}>
                <Typography style={{ fontWeight: 300, lineHeight: "3rem" }} variant="h3">{"Language"}</Typography>
                <Grid container xs={12}>
                  {series?.tags?.map((item: string) => {
                    return (
                      <Chip
                        classes={{ root: classes.tagsChip }}
                        style={{ margin: '0.5rem 0.5rem 1rem 0rem', background: "#C4C4C4" }}
                        label={item}
                      />
                    )
                  })
                  }
                </Grid>
              </Grid> : <></>
          }

        </Grid>
        {/* CHAPTERS LIST */}
        <Grid container xs={12}>
          <Grid container justify="space-between" alignItems="center" xs={12} style={{ padding: "1rem 0rem" }}>
            <Grid item>
              <Typography style={{ fontWeight: 600, lineHeight: "2rem" }} variant="h2">{"Table of content"}</Typography>
            </Grid>
            <Grid item>
              <Fab
                variant="extended"
                size="medium"
                style={{ marginRight: "1rem" }}
                onClick={() => navigate(`/portal/rachnaye/series/read?id=${series.id}`)}
              >
                <Typography variant="body1">Read</Typography>
              </Fab>
              <Fab
                variant="extended"
                size="medium"
                style={{ marginRight: "1rem" }}
                onClick={() => navigate(`/portal/rachnaye/series/chapter/create?seriesId=${series.id}`)}
              >
                <Typography variant="body1">+ Add Chapter</Typography>
              </Fab>
              <Fab
                variant="extended"
                size="medium"
                onClick={handlePublishButton1Click}
              >
                <Typography variant="body1">Proceed</Typography>
              </Fab>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: "0rem 0rem" }}>
            {series?.chapters?.map((chapter: any) => {
              return (
                <Card className={classes.card} onClick={() => {
                  if (chapter.status === 'draft') {
                    navigate(`/portal/rachnaye/series/chapter/edit?seriesId=${series.id}&chapterId=${chapter.id}&page=${chapter.chapterType}`)
                  }
                }}>
                  <Grid key={chapter.id} container xs={12} alignItems="center" justify="space-between" style={{ minHeight: '4rem', margin: '0.2rem 0rem' }}>
                    <Grid container xs={6} style={{ padding: "1rem" }}>
                      <Grid item xs={12}>
                        <Typography style={{ fontWeight: 300, lineHeight: "2rem" }} variant="body2">{chapter.chapterType.toUpperCase()}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={{ fontWeight: 600, lineHeight: "2rem" }} variant="h3">{chapter.title}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Chip
                          classes={{ root: classes.tagsChip }}
                          style={{ margin: '0.5rem 0.5rem 1rem 0rem', backgroundColor: chapter.status === 'draft' ? "#FED8B1" : undefined }}
                          label={chapter.status.toUpperCase()}
                        />
                      </Grid>
                    </Grid>
                    <Grid container xs={1} alignItems="center" justify="center">
                      {
                        chapter.status === "draft" ?
                          <Grid item >
                            <Typography style={{ textDecoration: "underline" }} color="primary" variant="body1">Edit</Typography>
                            <DeleteIcon fontSize="small" className={classes.deleteBtn} onClick={(e) => {
                              e.stopPropagation()
                              setChapterIdToDelete(v => chapter.id)
                              setOpen(true)
                            }} />
                          </Grid>

                          : <></>
                      }

                    </Grid>

                  </Grid>
                </Card>
              )
            })
            }
          </Grid>
        </Grid>

      </Grid>
      <ConfirmationDialog
        isOpen={open}
        onCancel={() => setOpen(false)}
        onConfirm={deleteDraftChapter}
        title={"Confirm Chapter Delete"}
        description={"Are you sure, you want to delete this Draft chapter permanently"}
      />
    </Grid>
  );
};

export default SeriesDescription;
