import {
  Fab,
  Grid,
  IconButton,
  Paper,
  Typography
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { MUIDataTableMeta, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IDistributor } from "../../../reducers/distributors/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../utils/common.interface";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import CircularLoader from "../../common/CircularLoader";
import CustomContextMenu from "../../common/CustomContextMenu/CustomContextMenu";
import { IContextMenuPosition } from "../../common/CustomContextMenu/types";
import { DataTable } from "../../common/Datatable/Datatable";
import { Pagination } from "../../common/Datatable/types";

const Distributors: React.FC = () => {
  const navigate = useNavigate();
  const userState = useSelector((state: IAppState) => state.user);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false)
  const [onPageLoad, setOnPageLoad] = useState<boolean>(false);

  const [distributors, setDistributors] = useState<IDistributor[]>([]);
  const [totalDistributors, setTotalDistributors] = useState<number>(0);

  const [contextMenu, setContextMenu] = useState<IContextMenuPosition | null>(null);
  const [menuAttributes, setMenuAttributes] = useState({
    link: "",
  })


  useEffect(() => {
    if (userState && userState.id && userState.spaceId) {
      const role = userState.role;
      if (role && role.active) {
        setOnPageLoad(true);
        //superAdmin view
        if (role.category === "org") {
          setIsSuperAdmin(true)
        }
        fetchDistributors({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.id])

  const fetchDistributors = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args

    // decide api link 
    const DISTRIBUTOR_LINK = userState?.role?.category === 'org' ?
      PATHNAMES.GET_ALL_DISTRIBUTORS_WITH_PAGINATION(pageNumber, pageSize) :   //when admin views
      PATHNAMES.GET_DISTRIBUTORS_FOR_PUBLISHER_WITH_PAGINATION(userState.spaceId || "", pageNumber, pageSize)   //when publisher views

    callApi(DISTRIBUTOR_LINK, HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then((res) => {
      if (res && res.success && res.data) {
        setTotalDistributors(res.data.totalDistributorCount)
        setDistributors(res.data.distributors);
        setOnPageLoad(false);
      }
    })
  }

  const handleContextMenuClick = (
    event: React.MouseEvent, distributorId: string
  ) => {
    event.preventDefault();
    const value: any = contextMenu === null ? {
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    } : null;
    setContextMenu(value);
    let url = "";
    if (isSuperAdmin) {
      url = `/update-distributor?distributor=${distributorId}`
    } else {
      url = `view-distributor?distributor=${distributorId}&publisher=${userState.spaceId}`
    }
    setMenuAttributes({
      ...menuAttributes,
      link: url
    })
  };

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      label: "NAME",
      name: "name",
      options: {
        filter: false,
      }
    },
    {
      label: "REF NAME",
      name: "refName",
      options: {
        filter: false,
      }
    },
    {
      label: "Email",
      name: "email",
      options: {
        filter: false,
      }
    },
    {
      label: "Address",
      name: "address",
      options: {
        filter: false,
        customBodyRender: (data: any) => `${data.city}, ${data.state}`
      }
    },
    {
      label: "Contact No.",
      name: "contactPerson",
      options: {
        filter: false,
        customBodyRender: (data: any) => `${data.contact.countryCode} ${data.contact.phoneNumber}`
      }
    },
    {
      label: "",
      name: "",
      options: {
        filter: false,
        setCellProps: () => ({ style: { minWidth: "2rem", maxHeight: '2rem' } }),
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta, updateValue: any) => {
          if (isSuperAdmin) {
            return (
              <IconButton
                id={`Edit-${tableMeta.rowIndex}`}
                size='small'
                // style={{ display: 'none' }}
                onClick={() => {
                  const [id] = tableMeta.rowData
                  navigate(`/update-distributor?distributor=${id}`)
                }}
              >
                <EditIcon titleAccess="Edit Distributor" color='primary' style={{ fontSize: '1em' }} />
              </IconButton>
            );
          }
        }
      }
    },
  ]

  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    setRowProps: (row: any, rowIndex: number) => {
      return {
        // Let edit be visible
        // onMouseEnter: () => handleRowHover(rowIndex),
        // onMouseLeave: () => handleRowHoverLeave(rowIndex),
        onContextMenu: (event: React.MouseEvent) => {
          const [distributorId] = row
          return handleContextMenuClick(event, distributorId)
        }
      };
    },
    onRowClick: (rowData: any, rowMeta: any) => {
      //when publisher views
      if (!isSuperAdmin) {
        const item: any = distributors[rowMeta.dataIndex];
        if (item) {
          navigate(`/view-distributor?distributor=${item.id}&publisher=${userState.spaceId}`);
        }
      }
    },
    searchPlaceholder: "Search by name, refname, email, contact or address",
    count: totalDistributors,
  };



  if (onPageLoad) {
    return <CircularLoader message={"Fetching your Distributors.."} />
  }

  return (
    <Grid container xs={12}>
      <Paper elevation={0} style={{ width: "98%", padding: "1rem" }}>
        <Grid container xs={12}>
          <Grid container item xs={4}>
            <Typography variant="h2" style={{ fontWeight: 600, padding: "1rem", }}>
              Distributors
              <Typography variant="h2" color="primary" style={{ display: "inline", marginLeft: "1rem" }}>
                {totalDistributors}
              </Typography>
            </Typography>
          </Grid>
          <Grid container item xs={8} justify="flex-end" >
            <Fab
              variant="extended"
              size="medium"
              onClick={() => {
                if (isSuperAdmin) {
                  navigate(`/create-distributor`)
                } else {
                  navigate(`/create-distributor?publisher=${userState.spaceId}`)
                }
              }}
            >
              Create Distributor
            </Fab>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <DataTable
            title={""}
            rows={distributors}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchDistributors}
          />
        </Grid>
        <CustomContextMenu
          onClose={() => setContextMenu(null)}
          position={contextMenu}
          attributes={menuAttributes}
        />
      </Paper>
    </Grid>
  )
}

export default Distributors;