
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Grid, Typography, CircularProgress, Paper, makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { IAppState } from './../../../store';
import callApi from './../../../utils/apiUtil';
import { PATHNAMES } from "../../../utils/pathNames";
import { HTTP_METHODS } from './../../../utils/constants';
import CustomizedSelects from './CustomizedSelects';

ChartJS.register(ArcElement, Tooltip, Legend);
interface IPieStats {
  orders: {
    rentalDigital: {
      count: number;
      revenue: number;
    },
    buyDigital: {
      count: number;
      revenue: number;
    },
    paperback: {
      count: number;
      revenue: number;
    },
    hardbound: {
      count: number;
      revenue: number;
    },
    total: number;
  },
  totalRevenue: number;
}

const useStyles = makeStyles(() => ({
  revenueText: {
    fontWeight: 600,
    fontSize: "14px", color:
      "#FF5C3E", marginTop: "6px"
  },
  header: {
    fontWeight: 600,
    padding: "1rem 0rem 0.3rem 1rem"
  },
  legendSpan: {
    position: "relative",
    top: "0.4rem"
  },
  legendTitle: {
    fontSize: "12px",
    fontWeight: "normal",
    color: "#808080"
  },
  typography: {
    fontWeight: 600,
    fontSize: "14px",
    marginLeft: "1rem"
  }
}))
const PieChartRevenue: React.FC = () => {
  const classes = useStyles()
  const userState = useSelector((state: IAppState) => state.user);
  const [selectedMonthCount, setSelectedMonthCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [stats, setStats] = useState<IPieStats>();
  const fetchPublisherData = async (spaceId: string) => {
    setIsLoading(true);
    try {
      const res = await callApi(PATHNAMES.PUBLISHER_REVENUE_PIECHART(spaceId || "", selectedMonthCount), HTTP_METHODS.GET)
      if (res?.data) {
        setStats(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (userState && userState.spaceId) {
      fetchPublisherData(userState.spaceId)
    }
  }, [userState, selectedMonthCount]);

  const getRevenueString = (revenue: number) => {
    if (revenue) {
      return `₹${revenue.toLocaleString('en-IN')}`
    }
    return `₹0`;
  }

  const getRevenuePercentage = (revenue: number) => {
    const sum = stats?.totalRevenue || 0;
    if (revenue) {
      const fraction = revenue / sum;
      return `${(fraction * 100).toFixed(1)}%`
    }
    return `0%`;
  }
  const orders = stats?.orders
  const state = {
    labels: ["Rental", "Paperback", "Hardbound"],
    data: [
      orders?.rentalDigital?.revenue,
      orders?.paperback?.revenue,
      orders?.hardbound?.revenue,
    ],

    datasets: [
      {
        label: "Custom Labels",
        backgroundColor: ["#FF5C3E", "#FFD36E", "#5CD89F"],
        fontfamily: "Poppins",
        fontWeight: "bold",
        fontsize: "12px",
        border: "1px",
        borderWidth: 2,
        data: [
          orders?.rentalDigital?.revenue,
          orders?.paperback?.revenue,
          orders?.hardbound?.revenue,
        ],
      },],
  };

  const options: any = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
  const handleMonthCount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedMonthCount(event.target.value as number);
  };
  return (
    <Paper elevation={5} style={{ width: "100%" }} >
      {isLoading ?
        <div style={{ marginLeft: '40%', marginTop: '30%' }}><CircularProgress /></div>
        :
        <>
          <Grid container xs={12} sm={12} md={12} lg={12} xl={12} >
            <Typography variant="h3" className={classes.header} >
              REVENUE BY STREAM
            </Typography>
          </Grid>
          {stats && stats?.totalRevenue > 0 ?
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={5}>
                <CustomizedSelects value={selectedMonthCount} onChange={handleMonthCount} type={'lifetime'} />
              </Grid>
              <Grid container xs={12} spacing={1} style={{ padding: "1rem" }}>
                <Grid container xs={5}>
                  <Grid item xs={12}>
                    <div className="RevenuePiechart">
                      <Pie data={state} options={options} />
                    </div>
                  </Grid>
                </Grid>
                <Grid container xs={4}>
                  <Grid item xs={12}>
                    <Typography variant={"h3"} className={classes.typography}>
                      <span className={classes.legendSpan}>
                        <FiberManualRecordIcon
                          fontSize="small"
                          htmlColor="#FF5C3E"
                        />
                      </span>
                      <span className={classes.legendTitle}>RENTALS</span><br />
                      <span style={{ marginLeft: "20px" }}>{getRevenueString(stats?.orders?.rentalDigital?.revenue)}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"h3"} className={classes.typography}>
                      <span className={classes.legendSpan}>
                        <FiberManualRecordIcon
                          fontSize="small"
                          htmlColor="#FFD36E"
                        />
                      </span>
                      <span className={classes.legendTitle}>PAPERBACK</span><br />
                      <span style={{ marginLeft: "20px" }}>{getRevenueString(stats?.orders?.paperback?.revenue)}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"h3"} className={classes.typography}>
                      <span className={classes.legendSpan}>
                        <FiberManualRecordIcon
                          fontSize="small"
                          htmlColor="#5CD89F"
                        />
                      </span>
                      <span className={classes.legendTitle}>HARDBOUND</span><br />
                      <span style={{ marginLeft: "20px" }}>{getRevenueString(stats?.orders?.hardbound?.revenue)}</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container xs={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.revenueText}>
                      {getRevenuePercentage(stats?.orders?.rentalDigital?.revenue)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.revenueText}>
                      {getRevenuePercentage(stats?.orders?.paperback?.revenue)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.revenueText}>
                      {getRevenuePercentage(stats?.orders?.hardbound?.revenue)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
            :
            <Grid container justify="center" alignContent="center" style={{ marginTop: '20%' }}><Typography variant="h2">No Data to display</Typography></Grid>
          }
        </>
      }
    </Paper>
  );
};

export default PieChartRevenue;

