import {
  Box,
  Fab,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { replaceHyphen } from "../../../utils/utils";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { useNavigate } from "react-router-dom";


interface IValues {
  language: string[];
  category: string[];
}

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "1rem",
    position: "relative",
    width: "100%",
    height: "96%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(45deg, #f3e9d270 50%, #f7cac978 90%)",
    color: "#333333",
    overflow: "auto",
    padding: "20px",
  },
  heading: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: "bold",
    fontSize: "24px",
    marginBottom: "6px",
    whiteSpace: "normal",
  },
  form: {
    width: "100%",
  },
  fieldset: {
    marginBottom: "20px",
    width: "75%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    borderRadius: "10px",
    maxHeight: "350px",
    overflowY: "hidden",
    transition: "max-height 0.3s ease",
    "&:hover": {
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "transparent",
      display: "none",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      height: "-2px",
      background: "#888",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  legend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  label: {
    display: "flex",
    marginBottom: "5px",
    cursor: "pointer",
    marginRight: "10px",
    padding: "3px",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "10px",
    width: "266px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s ease",
    "&:hover": {
      scale: "1",
      backgroundColor: "#ff5c3e",
      color: "#fff",
    },
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    fontWeight: "bold",
    backgroundColor: "#ff5c3e",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e53d26",
    },
  },
  selectedLabel: {
    backgroundColor: "#ff5c3e",
    color: "#fff",
  },
  scrollContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxHeight: "160px",
    overflowY: "auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  scrollButton: {
    backgroundColor: "rgb(0 0 0 / 3%)",
    "&:hover": {
      color: "black"
    },
    "&:disabled": {
      backgroundColor: "#f1f1f1",
      cursor: "not-allowed",
    },
    animation: "$blink",
    animationDuration: "1s",
    animationTimingFunction: "linear",
    animationIterationCount: "4",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "1.3rem",
    marginRight: "0.12rem",
    alignItems: "center",
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 2,
  },
  boxDiv: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxHeight: "160px",
  },
  "@keyframes blink": {
    "0%": { opacity: 0 },
    "50%": { opacity: .5, color: theme.palette.primary.main, backgroundColor: "rgb(0 0 0 / 6%)" },
    "100%": { opacity: 1, color: theme.palette.primary.main, backgroundColor: "rgb(0 0 0 / 8%)" },
  },
}));

const languageNamesInLocal = "languageNamesInLocal";
const bookCategories = "bookCategories";

const CategoryLanguageSelection: React.FC = () => {
  const classes = useStyles();
  const snackbar = useSnackBar();
  const navigate = useNavigate()
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);

  const [selectedValues, setSelectedValues] = useState<IValues>({
    language: [],
    category: [],
  });
  const [languages, setLanguages] = useState<any>({});
  const [categories, setCategories] = useState<object>({});

  useEffect(() => {
    if (!mobileUserState.id) {
      return
    }
    setSelectedValues(() => ({
      language: mobileUserState.readingLanguage,
      category: replaceHyphen(mobileUserState.preferredCategories)
    })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState.id])

  const getData = async (id: string) => {
    callApi(PATHNAMES.GET_RESOURCE_BY_ID(id), HTTP_METHODS.GET)
      .then((res) => {
        if (res && res.success && res.data) {
          if (id === bookCategories) {
            setCategories(replaceHyphen(res.data.content.BookCategories));
          } else if (id === languageNamesInLocal) {
            setLanguages(res.data.content.languageNamesInLocal);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateData = async () => {
    try {
      const data = {
        preferredCategories: selectedValues.category,
        readingLanguage: selectedValues.language,
      };
      callApi(
        PATHNAMES.UPDATE_CATEGORIES_USER(mobileUserState.id),
        HTTP_METHODS.PATCH,
        {
          body: JSON.stringify({ data }),
        }
      ).then((res) => {
        if (res && res.success && res.data) {
          snackbar({
            message: "Selection updated",
            variant: SnackBarVariant.SUCCESS,
          })
          navigate("/portal/digitalReads")
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData(languageNamesInLocal);
    getData(bookCategories);
  }, [mobileUserState]);

  const handleScroll = (elementId: string, amount: number) => {
    const element = document?.getElementById(elementId);
    if (element) {
      element.scrollLeft += amount;
    }
  };

  const handleRightClick = (elementId: string) => {
    handleScroll(elementId, 920);
  };

  const handleLeftClick = (elementId: string) => {
    handleScroll(elementId, -920);
  };

  const handleLanguageChange = (language: string) => {
    setSelectedValues((prevValues: IValues) => {
      let updatedLanguage;
      if (prevValues.language.includes(language)) {
        updatedLanguage = prevValues.language.filter(
          (lang) => lang !== language
        );
      } else {
        updatedLanguage = [...prevValues.language, language];
      }
      return {
        ...prevValues,
        language: updatedLanguage,
      };
    });
  };

  const handleCategoryChange = (genre: string) => {
    setSelectedValues((prevValues: IValues) => {
      let updatedGenre;
      if (prevValues.category.includes(genre)) {
        updatedGenre = prevValues.category.filter((gen) => gen !== genre);
      } else {
        updatedGenre = [...prevValues.category, genre];
      }
      return {
        ...prevValues,
        category: updatedGenre,
      };
    });
  };

  const handleCategorySelect = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateData();
  };

  const isLanguageSelected = (lang: string) => {
    return selectedValues.language.includes(lang);
  };

  const isGenreSelected = (genre: string) => {
    return selectedValues.category.includes(genre);
  };

  const renderLanguageOptions = () => {
    const languageKeysOptions = Object.keys(languages);
    languageKeysOptions.sort();
    const rows: JSX.Element[] = [];

    for (let i = 0; i < languageKeysOptions.length; i++) {
      const language = languageKeysOptions[i];
      const isSelected = isLanguageSelected(language as string);

      const row = (
        <div key={language as string} className={classes.row}>
          <label
            style={{ width: "9.06rem" }}
            key={language as string}
            className={`${classes.label} ${isSelected ? classes.selectedLabel : ""
              }`}
            onClick={() => handleLanguageChange(language as string)}
            data-value={language}
          >
            <div>{languages[language]}</div>
          </label>
        </div>
      );
      rows.push(row);
    }
    return rows;
  };

  const renderCategoryOptions = () => {
    const categoryOptions = Object.values(categories);
    categoryOptions.sort();
    const rows: JSX.Element[] = [];

    for (let i = 0; i < categoryOptions.length; i++) {
      const category = categoryOptions[i];
      const isSelected = isGenreSelected(category as string);

      const row = (
        <div key={category as string} className={classes.row}>
          <label
            key={category as string}
            className={`${classes.label} ${isSelected ? classes.selectedLabel : ""
              }`}
            onClick={() => handleCategoryChange(category as string)}
            data-value={category}
          >
            {category}
          </label>
        </div>
      );
      rows.push(row);
    }
    return rows;
  };

  return (
    <Grid className={classes.container}>
      <Typography variant="h1" className={classes.heading}>
        Help us, so that we can curate the content for you
      </Typography>
      <Grid>
        <Typography variant="body1" style={{ margin: "0 0 12px" }}>
          Choose atleast
          <strong> one </strong>
          language and <strong>three</strong> genres
          that you can enjoy reading. This will help us to curate the content for you.
        </Typography>
      </Grid>

      <form onSubmit={handleCategorySelect} className={classes.form}>
        <Box className={classes.box}>
          <IconButton
            onClick={() => handleLeftClick("languageScroller")}
            className={classes.scrollButton}
          >
            <ChevronLeftIcon style={{ fontSize: "2rem" }} />
          </IconButton>
          <fieldset className={classes.fieldset} id="languageScroller">
            <legend className={classes.legend}>Languages</legend>
            <div className={classes.boxDiv}>{renderLanguageOptions()}</div>
          </fieldset>
          <IconButton
            onClick={() => handleRightClick("languageScroller")}
            className={classes.scrollButton}
          >
            <ChevronRightIcon style={{ fontSize: "2rem" }} />
          </IconButton>
        </Box>
        <Box className={classes.box}>
          <IconButton
            onClick={() => handleLeftClick("categoryScroller")}
            className={classes.scrollButton}
          >
            <ChevronLeftIcon style={{ fontSize: "2rem" }} />
          </IconButton>
          <fieldset className={classes.fieldset} id="categoryScroller">
            <legend className={classes.legend}>Category</legend>
            <div className={classes.boxDiv}>{renderCategoryOptions()}</div>
          </fieldset>
          <IconButton
            onClick={() => handleRightClick("categoryScroller")}
            className={classes.scrollButton}
          >
            <ChevronRightIcon style={{ fontSize: "2rem" }} />
          </IconButton>
        </Box>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
          <Fab
            type="submit"
            variant="extended"
            className={classes.button}
            style={{
              opacity:
                selectedValues.language.length > 0 &&
                  selectedValues.category.length > 2
                  ? 1
                  : 0.5,
              cursor:
                selectedValues.language.length > 0 &&
                  selectedValues.category.length > 2
                  ? "pointer"
                  : "not-allowed",
            }}
            disabled={
              !(
                selectedValues.language.length > 0 &&
                selectedValues.category.length > 2
              )
            }
          >
            Proceed
          </Fab>
        </Grid>
      </form>
    </Grid>
  );
};

export default CategoryLanguageSelection;
