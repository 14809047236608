import { Grid } from '@material-ui/core';
import React from 'react';
import CustomDivider from '../../common/Divider';
import ScheduledNotifications from './ScheduledNotifications';
import SendGeneralNotification from './SendGeneralNotification';
import UpdateTemplates from './UpdateTemplates';

const NotificationControl: React.FC = () => {
  return (
    <Grid container xs={12} style={{ marginBottom: "10rem" }}>
      <UpdateTemplates />
      <CustomDivider />
      <SendGeneralNotification />
      <CustomDivider />
      <ScheduledNotifications />
    </Grid>
  )
}

export default NotificationControl;