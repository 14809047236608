import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { IOrderResponse } from "../../../../reducers/orders/types";


interface IProps {
  order: IOrderResponse | any
}

const useStyles = makeStyles(() => ({
  borderStyle: {
    borderRadius: 4,
    border: "1px solid #9e9694"
  },
  priceContainer: { padding: '1rem 1.2rem 0rem 1.5rem' },
  priceLastContainer: { padding: '1.2rem 1rem 1rem 1.5rem' },
}))

const OrderPriceContainer: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const offerPriceIsPresentAndNotSameAsSellingPrice = () => props.order.newOfferedPrice > 0 && (props.order.newOfferedPrice !== props.order.totalSellingPrice)
  return (
    <Grid item xs={12} className={classes.borderStyle} style={{ marginTop: '1rem' }}>
      <Grid container xs={12} className={classes.priceContainer}>
        <Grid item xs={offerPriceIsPresentAndNotSameAsSellingPrice() ? 8 : 10}>  <Typography variant="h3" >{"Items Total (incl. of taxes)"}</Typography></Grid>
        {offerPriceIsPresentAndNotSameAsSellingPrice() ?
          <>
            <Grid container xs={2} justify='flex-end' style={{ paddingRight: '1rem' }}>  <Typography variant="h3" style={{ textDecoration: "line-through" }} >{`₹ ${props.order.totalSellingPrice}`}</Typography></Grid>
            <Grid item xs={2}>  <Typography variant="h3" style={{ display: "inline" }}>{`₹ ${props.order.newOfferedPrice}`}</Typography></Grid>
          </> :
          <Grid item xs={2}>  <Typography variant="h3" >{`₹ ${props.order.totalSellingPrice}`}</Typography></Grid>
        }
      </Grid>
      {props.order.offerCode &&
        <Grid container xs={12} className={classes.priceContainer}>
          <Grid item xs={10}>  <Typography variant="h3" >Coupon Discount</Typography></Grid>
          <Grid item xs={2}>  <Typography variant="h3" style={{ fontWeight: 400 }} >{`- ₹ ${props.order.totalCouponDiscount}`}</Typography></Grid>
        </Grid>
      }
      {props.order.globalOfferApplied &&
        <Grid container xs={12} className={classes.priceContainer}>
          <Grid item xs={10}>  <Typography variant="h3" >Exclusive Discount</Typography></Grid>
          <Grid item xs={2}>  <Typography variant="h3" style={{ fontWeight: 400 }} >{`- ₹ ${props.order.totalGlobalOfferDiscount}`}</Typography></Grid>
        </Grid>
      }
      {props.order.exclusiveBirthDayOfferApplied &&
        <Grid container xs={12} className={classes.priceContainer}>
          <Grid item xs={10}>  <Typography variant="h3" >Birthday Discount</Typography></Grid>
          <Grid item xs={2}>  <Typography variant="h3" style={{ fontWeight: 400 }} >{`- ₹ ${props.order.totalExclusiveBirthDayOfferDiscount}`}</Typography></Grid>
        </Grid>
      }

      <Grid container xs={12} className={classes.priceContainer}>
        <Grid item xs={10}>  <Typography variant="h4" style={{ fontWeight: 300 }} >{"(Tax Amount on Effective Price)"}</Typography></Grid>
        <Grid item xs={2}>  <Typography variant="h4" style={{ fontWeight: 300 }} >{`(₹ ${props.order.taxAmount})`}</Typography></Grid>
      </Grid>
      {props.order?.shippingCharges ? <Grid container xs={12} className={classes.priceContainer}>
        <Grid item xs={10}>  <Typography variant="h3" >Shipping Charge</Typography></Grid>
        <Grid item xs={2}>  <Typography variant="h3" >{`₹ ${props.order.shippingCharges}`}</Typography></Grid>
      </Grid> : <></>}
      <Grid container xs={12} className={classes.priceContainer}>
        <Grid item xs={10}>  <Typography variant="h3" >Convenience Fee</Typography></Grid>
        <Grid item xs={2}>  <Typography variant="h3" >{`₹ ${props.order.convenienceFee}`}</Typography></Grid>
      </Grid>
      <Grid container xs={12} className={classes.priceLastContainer}>
        <Grid item xs={9} sm={10}>  <Typography variant="h4" style={{ fontSize: '1.3rem' }}  >To Be Paid</Typography></Grid>
        <Grid item xs={3} sm={2}>  <Typography variant="h4" style={{ fontSize: '1.3rem' }} >{`₹ ${props.order.totalAmount}`}</Typography></Grid>
      </Grid>
    </Grid>
  )
}

export default OrderPriceContainer;