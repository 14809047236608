import "flatpickr/dist/themes/material_green.css";
import "./index.scss";
import React from "react";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { connect } from "react-redux";
import { IAppState } from "../../../store";
import { IIamUserState } from '../../../reducers/user/types';
interface IProps {
  handleDateChange: (value: Date) => void;
  identifier?: string;
  defaultDateTime?: any;
  enableTime?: boolean;
  placeholderValue?: string;
  onBlur?: any;
  name?: string | undefined;
  value?: string;
  required?: boolean;
  minDate?: Date | string;
  maxDate?: Date | string;
  disabled?: boolean;
  type?: string;
  label?: string;
  userReducer: IIamUserState;
}
class FlatPickerBar extends React.Component<IProps> {
  public static defaultProps = {
    enableTime: true
  };
  private fp = React.createRef<any>();
  public clearDate = () => {
    if (this.fp.current) {
      this.fp.current.flatpickr.clear();
    }
  };
  public validateDate(date: string) {
    const dateTimePattern = new RegExp(
      //eslint-disable-next-line
      /^^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4}$/ 
    );
    return dateTimePattern.test(date);
  }
  public render() {
    const {
      identifier,
      placeholderValue,
      name,
      handleDateChange,
      minDate,
      maxDate,
      type,
      label,
      required
    } = this.props;
    return (
      <div
        className={
          `${identifier === "fleet-calendar"
            ? `fleet-calendar-container`
            : `flatpickr-container ${required ? "required-date" : ""}`}
            ${type === "simple-time-picker" ?
            "simple-time-picker"
            : type === "date-time-picker" ?
              "date-time-picker" : ""
          }`
        }
      >
        <span className={"flatpicker-date-label"}>{label ? label : ""}{required ? <sup className="flat-picker-required">*</sup> : ""}</span>
        <Flatpickr
          options={{
            enableTime: false,
            static: true,
            minuteIncrement: 1,
            dateFormat: "d/m/Y",
            defaultDate: this.props.defaultDateTime,
            minDate,
            maxDate
          }}
          disabled={this.props.disabled ? this.props.disabled : false}
          className={
            identifier === "fleet-calendar"
              ? "fleet-calendar"
              : "flatpickr-input"
          }
          placeholder={placeholderValue}
          value={this.props.value ? (this.validateDate(this.props.value) ? this.props.value : moment(this.props.value).format('DD/MM/YYYY')) : ""}
          name={name}
          onChange={(date) => {
            handleDateChange(moment(this.fp.current.flatpickr.formatDate(date[0], "Y-m-d")).toDate());
          }}
          ref={this.fp}
          required={this.props.required}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: IAppState) => ({ userReducer: state.user });

export default connect(mapStateToProps, {})(FlatPickerBar);
