import {
  Box,
  Drawer,
  Grid,
  List,
  ListItem,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/LogoRegisteredDark.svg";
import { updateIamUser } from "../../reducers/user/reducer";
import { IAppState } from "../../store";
import callApi from "../../utils/apiUtil";
import { HTTP_METHODS } from "../../utils/constants";
import { PATHNAMES } from "../../utils/pathNames";
import { isLogin } from "../../utils/utils";
import NotificationCard from "./NotificationCard";
import UserCard from "./UserCard";

const useStyles = makeStyles((theme) =>
  createStyles({
    headerContainer: {
      minHeight: "3.5rem",
      alignItems: "center",
      backgroundColor: "white",
      position: "fixed",
      zIndex: 900,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)"
    },
    logoText: {
      color: "#fff",
      marginLeft: "3rem",
      cursor: "pointer"
    },
    linkText: {
      color: "#fff",
      marginRight: "2rem",
      cursor: "pointer"
    },
    logoStyle: {
      marginLeft: "1rem",
    },
    headerItem: {
      fontWeight: 600,
      fontSize: "16px",
      cursor: "pointer"
    },
    menuIcon: {
      cursor: "pointer",
      marginRight: "1rem"
    },
    menuItem: {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
    publisherLogo: {
      display: "inline",
      marginLeft: "1rem",
      fontWeight: 600,
      position: "relative",
      bottom: "0.7rem"
    },
    btnIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: "2rem",
      width: "3rem",

      padding: '0.7rem 0.6rem',
      cursor: "pointer",
    },
  })
);

const PublicHeader: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const userState = useSelector((state: IAppState) => state.user);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (userState) {
      setLoggedIn(isLogin())
      if (!userState.id) {

        const userId = localStorage.getItem("userId")
        if (userId) {
          callApi(PATHNAMES.GET_IAM_USER(userId), HTTP_METHODS.GET).then((res) => {
            if (res && res.success && res.data) {
              dispatch(updateIamUser(res.data));
              localStorage.setItem("userId", res.data.id);
            }
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState]);

  const toggleDrawer = (data: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(data);
  };

  return (
    <Grid container className={classes.headerContainer}>
      <Grid container item xs={2} >
        <Box style={{ marginLeft: "3%" }} >
          <img src={logo} alt="logo" className={classes.logoStyle} width={170} />
        </Box>
      </Grid>
      {!isSmallScreen ? <Grid container xs={10}>
        {loggedIn &&
          <Grid container xs={12} justify="center" alignItems="center">
            <Grid item xs={5}>
              {
                userState.space?.profileImageUrl &&
                <>
                  <img src={userState.space?.profileImageUrl} alt="logoPublisher" height={40} />
                  <Typography variant="h3" className={classes.publisherLogo}>
                    {userState.space?.name}
                  </Typography>
                </>
              }
            </Grid>
            <Grid container xs={7} justify="flex-end" style={{ alignItems: "center" }}>
              <Box pl={2} title='Notification' className={classes.btnIconContainer}>
                <NotificationCard />
              </Box>
              <Box pl={2} title={"Your Profile\n"+userState.firstName} className={classes.btnIconContainer}>
                <UserCard />
              </Box>
            </Grid>
          </Grid>
        }
      </Grid> :
        <>
          <Grid container item xs={9} justify="flex-end">
            <MenuIcon onClick={() => setOpen(true)} className={classes.menuIcon} />
          </Grid>
          <Drawer anchor={"right"} open={open} onClose={toggleDrawer(false)}>
            <List>
              <ListItem
                className={classes.menuItem}
                onClick={() => {
                  navigate("/about-us")
                  setOpen(false)
                }}
              >
                About Us
              </ListItem>
              <ListItem
                className={classes.menuItem}
                onClick={() => {
                  navigate("/contact-us")
                  setOpen(false)
                }}
              >
                Contact Us
              </ListItem>
            </List>
          </Drawer>
        </>
      }
    </Grid>
  );
};

export default PublicHeader;