import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import CircularLoader from '../components/common/CircularLoader';
import { IMobileUserState } from '../reducers/mobileUser/types';
import { IIamUserState } from '../reducers/user/types';
import { IAppState } from '../store';
import { IamUserType, USER_TYPES } from '../utils/constants';
import { getUserType, isLogin } from '../utils/utils';

const DefaultRoute: React.FC = () => {
  const [userType] = useState<string>(getUserType())
  const userState: IMobileUserState | IIamUserState = useSelector((state: IAppState) => (userType === USER_TYPES.MOBILE ? state.mobileUser : state.user));
  const navigate = useNavigate()

  // redirect user to dashboard if unmatched route provided when user logged in
  // Otherwise, redirect the user to login page
  useEffect(() => {

    if (!isLogin()) {
      return navigate("/login", { replace: true })
    }

    //if mobile user
    if (userType === USER_TYPES.MOBILE) {
      return navigate("/portal/home")
    }

    // - else IamUser , check for admin Or publisher
    const role = (userState as IIamUserState)?.role;
    if (role && role.active) {
      if (role.category === IamUserType.ORG) {
        return navigate("/admin-dashboard")
      }
      if (role.category === IamUserType.PUBLISHER) {
        console.log("This line inside Default rount Ran ---->")
        return navigate("/dashboard")
      }
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
    [userState])

  return <CircularLoader />;
};

export default DefaultRoute;