import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  createStyles,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonIcon from "@material-ui/icons/Person";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { USER_TYPES } from "../../../utils/constants";
import theme from "../../common/Theme";
import IamUserLogin from "./IamUserLogin";
import MobileUserLogin from "./MobileUserLogin";

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      marginTop: "1vh",
      alignContent: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
    },
    formItem: {
      minWidth: "20rem",
      marginBottom: "1rem"
    },
    submitBtn: {
      marginTop: "1rem",
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
      minWidth: "20rem"
    },
    redirectText: {
      marginTop: "2rem",
      justifyContent: "center",
      color: "#005D68"
    },
    redirectLink: {
      display: "inline",
      cursor: "pointer",
      color: theme.palette.primary.main,
      marginLeft: "0.5rem"
    },
    mainText: {
      fontWeight: 600,
      fontSize: "2.1rem",
      [theme.breakpoints.down('sm')]: {
        fontSize: "1.8rem",
      },
      color: "#0C2D48",
      marginBottom: "0.6rem",

    },
    loginMessage: {
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.75rem"
      }
    },
    loginHeading: {
      backgroundColor: "#fff",
      margin: "1.3rem 0 0",
      padding: "0rem 3rem 1rem",
      [theme.breakpoints.down('sm')]: {
        padding: "0rem"
      }
    },
    lockAvatar: {
      border: "2px solid #0000001f",
      background: "transparent",
      color: " black",
      borderRadius: "0.75rem",
      margin: "0 1.2rem 0 0.8rem"
    },
    cardItem: {
      border: "none",
      boxShadow: "none",
      backgroundColor: "#aeb3b500",
      margin: "2rem 0",
    },
    cardHeading: {
      fontWeight: 500,
      display: "flex",
      alignItems: "flex-start",
      [theme.breakpoints.down('sm')]: {
        fontSize: "1rem",
        lineHeight: "1.6rem",
      }
    },
    cardContent: {
      padding: "0.4rem 0 0 2rem",
      [theme.breakpoints.down('sm')]: {
        padding: "0rem 0 0 1.7rem",
        fontSize: "0.625rem",
        lineHeight: "1.1rem",
      }
    },
    cardIcons: {
      alignSelf: "center",
      fontSize: "1.4rem",
      marginRight: "0.7rem",
      [theme.breakpoints.down('sm')]: {
        fontSize: "1.1rem",
        marginRight: "0.5rem",
      }
    }
  })
);

const Login: React.FC = () => {
  const classes = useStyles();
  const location: any = useLocation();
  const [userType, setUserType] = useState<string>(USER_TYPES.MOBILE);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    if (location?.state?.url && !location.state.url.includes("/login")) {
      localStorage.setItem("requestedUrl", location.state.url);
    }
  }, [location]);

  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}
      style={{ height: "100vh" }}>

      <Grid container xs={12} sm={12} md={4} lg={3} xl={3}
        style={{
          backgroundColor: "#e4eaed4f",
          padding: "0 1rem",
          display: "block"
        }}>
        <Grid item
          style={{
            height: "fit-content",
            fontSize: isSmallScreen ? "0.8rem" : ""
          }}>
          <h2>Be part of Rachnaye Ecosystem</h2>
        </Grid>

        <Box
          style={{
            backgroundColor:
              userType === USER_TYPES.MOBILE ? "#aeb3b538" : "",
            borderRadius: "4px",
          }}
          onClick={() => {
            setUserType(USER_TYPES.MOBILE);
          }}>
          <Card
            className={classes.cardItem}
          >
            <CardActionArea>
              <CardContent
                style={{ padding: isSmallScreen ? "0.3rem" : "0.7rem" }}>
                <Typography
                  variant="h2"
                  component="div"
                  className={classes.cardHeading}
                >
                  <PersonIcon
                    fontSize="default"
                    className={classes.cardIcons}
                  />
                  As a Reader & Writer
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.cardContent}
                >
                  Explore digital and non-digital books across Indian languages directly from Publishers and Writers across genres using our Web/Mobile App.
                  As a Writer, connect with your fan base or create one, write anything from a small quote to a storybook in Indian languages and genres, and explore earning opportunities.
                  Be part of Rachnaye Ecosystem.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>

        <Box
          style={{
            backgroundColor:
              userType === USER_TYPES.WEBAPP ? "#aeb3b538" : "",
            borderRadius: "4px"
          }}
          onClick={() => {
            setUserType(USER_TYPES.WEBAPP);
          }}>
          <Card
            className={classes.cardItem}
          >
            <CardActionArea>
              <CardContent
                style={{ padding: isSmallScreen ? "0.3rem" : "0.7rem" }}>
                <Typography
                  variant="h2"
                  component="div"
                  className={classes.cardHeading}
                >
                  <AccountBalanceIcon
                    fontSize="default"
                    className={classes.cardIcons}
                  />
                  As a Publisher
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.cardContent}
                >
                  Connect with Rachnaye Ecosystem for a top-notch marketplace with sales and marketing support and insights, get last-mile book delivery through Rachnaye too.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Grid>

      <Grid container xs={12} sm={12} md={8} lg={9} xl={9}
        className={classes.formContainer}>
        <Grid justify="center" alignItems="center">
          <Avatar
            variant="square"
            className={classes.lockAvatar}
          >
            <LockOutlinedIcon />
          </Avatar>
        </Grid>
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}
          justify="center"
          alignItems="center"
          className={classes.loginHeading}
        >
          <Typography
            variant="h1"
            align="center"
            className={classes.mainText}
          >
            Log in to Rachnaye
          </Typography>
        </Grid>
        {userType === USER_TYPES.WEBAPP && <IamUserLogin />}
        {userType === USER_TYPES.MOBILE && <MobileUserLogin />}
      </Grid>
    </Grid>
  );
};

export default Login;
