import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import RachnayeCard from "./RachnayeCard";

interface IPost {
  id: string,
  title: string;
  coverImage: string;
}
interface IProps {
  posts: IPost[];
  type: string;
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    gap: "6rem 2rem",
    overflowX: 'auto',
    height: "100%",
    padding: "1.5rem 0rem 3rem 0rem",
    [theme.breakpoints.down("xs")]: {
      "&::-webkit-scrollbar": {
        display: 'none'
      }
    }
  },
}))


const RachnayeGrid: React.FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid container xs={12} wrap="nowrap" className={classes.mainContainer}>
      {props.posts.map((post) => <RachnayeCard post={post} type={props.type} showTitle />)}
    </Grid>
  )
}

export default RachnayeGrid;