import {
  CircularProgress,
  Grid,
  Typography
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";

const PayoutDetails: React.FC = () => {
  const location = useLocation();
  const [payout, setPayout] = useState<any>();
  const userState = useSelector((state: IAppState) => state.user);

  useEffect(() => {
    if (location && location.search && userState && userState.spaceId) {
      const params = new URLSearchParams(location.search);
      const payoutId = params.get("payout");
      if (payoutId) {
        callApi(PATHNAMES.GET_PAYOUT_BY_ID(userState.spaceId, payoutId), HTTP_METHODS.GET).then((res) => {
          if (res && res.success && res.data && res.data) {
            setPayout(res.data);
          }
        })
      }
    }
  }, [location, userState]);

  if (!payout) {
    return <CircularProgress />
  }

  return (
    <Grid container xs={12}>
      <Grid container xs={12} style={{ borderBottom: "2px solid #C0C0C0", paddingBottom: "1rem", marginRight: "1rem" }}>
        <Grid item xs={4}>
          <Typography variant={"subtitle1"}>
            Payout Id:
          </Typography>
          <Typography variant={"subtitle2"} style={{ display: "inline", marginLeft: "1rem", marginTop: "0.2rem" }}>
            {payout.referenceNumber}
          </Typography>
        </Grid>
        <Grid container xs={8} justify="flex-end">
          <Typography variant={"subtitle1"}>
            Payout Status:
          </Typography>
          <Typography variant={"subtitle2"} style={{ display: "inline", marginLeft: "1rem", marginTop: "0.2rem", marginRight: "1rem" }}>
            {payout.status.toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ borderBottom: "2px solid #C0C0C0", paddingBottom: "1rem", marginRight: "1rem", marginTop: "1rem" }}>
        <Grid item xs={2}>
          <Typography variant={"h3"} color="textSecondary">
            {"Date & Time"}
          </Typography>
          <Typography variant={"body1"}>
            {moment(payout.withdrawlRequestTime).format("DD/MM/YYYY, HH:mm A")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"h3"} color="textSecondary">
            {"Withdrawn By"}
          </Typography>
          <Typography variant={"body1"}>
            {`${payout.withdrawnBy.firstName} ${payout.withdrawnBy.lastName}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ borderBottom: "2px solid #C0C0C0", paddingBottom: "1rem", marginRight: "1rem", marginTop: "1rem" }}>
        <Typography variant={"h2"}>
          Invoice Items
        </Typography>
        <Grid container xs={12} spacing={1}>

        </Grid>
      </Grid>
    </Grid>
  )
};

export default PayoutDetails;