import { Grid, Typography } from "@material-ui/core";
import React from "react";

interface IProps {
  label: string;
  value: string;
  onClick?: () => void;
  color?: string;
}

export const SummaryField = (props: IProps) => {
  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Typography variant="h4" >
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" onClick={props.onClick}>
          {props.value}
        </Typography>
      </Grid>
    </Grid>
  );
};
