import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
  TextareaAutosize,
  TextField,
  Typography
} from "@material-ui/core";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { MUIDataTableMeta, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IFormattedDistributor } from "../../../../reducers/distributors/types";
import { ISubOrder } from "../../../../reducers/orders/types";
import callApi from "../../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../../utils/common.interface";
import { HTTP_METHODS, OrderItemTypes, SubOrderStatus } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { handleRowHover, handleRowHoverLeave } from "../../../../utils/utils";
import { DataTable } from "../../../common/Datatable/Datatable";
import { Pagination } from "../../../common/Datatable/types";
import FlatPickerBar from "../../../common/FlatPickerBar";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";

interface IProps {
  publisherId: string;
}

const OrderAction = {
  DELIVER_ORDER: "delivered",
  CANCEL_ORDER: "cancelled",
  IN_TRANSIT_ORDER: "inTransit"
}

interface IReasons {
  reasonKey: string;
  reasonValue: string;
}
const useStyle = makeStyles((theme) => ({
  margin: {
    width: "32rem",
  },
  inputField: {
    paddingTop: '1rem',
  },
  datePicker: {
    height: '20rem'
  },
  redirectLink: {
    borderBottom: '1px solid',
    display: 'inline',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 300
  },
}));

const PublisherOrders: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const classes = useStyle();
  const navigate = useNavigate();

  const [orders, setOrders] = useState<ISubOrder[]>([]);
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [publisherHasOrders, setPublisherHasOrders] = useState<boolean>(true);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [cancellationReasons, setCancellationReasons] = useState<IReasons[]>([]);

  const [radioButtonSelection, setRadioButtonSelection] = useState<string>("");
  const [selectedReason, setSelectedReason] = useState<string>("");
  const [additionalCommentForCancellation, setAdditionalCommentForCancellation] = useState<string>("");
  const [additionalCommentForDelivered, setAdditionalCommentForForDelivered] = useState<string>("");

  const [distributors, setDistributors] = useState<IFormattedDistributor[]>([]);
  const [selectedDistributor, setSelectedDistributor] = useState<string>()
  const [rachnayeOwnedBook, setRachnayeOwnedBook] = useState<boolean>(false)

  const [trackingData, setTrackingData] = useState({
    courierAgencyName: "",
    consignmentId: "",
    eta: "",
    trackingUrl: "",
  });


  // order where action will be taken
  const [currentOrderStatus, setCurrentOrderStatus] = useState<string>("");
  const [currentOrderId, setCurrentOrderId] = useState<string>("");
  const [currentBookId, setCurrentBookId] = useState<string>("");
  const [currentBookOrderType, setCurrentBookOrderType] = useState<string>("");
  const [currentOrderQuantity, setCurrentOrderQuantity] = useState<number>(0);


  // FETCH ORDER
  useEffect(() => {
    if (props.publisherId) {
      fetchPublisherOrders({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.publisherId])

  // FETCH CANCELLTAION REASON
  useEffect(() => {
    if (!cancellationReasons.length) {
      fetchCancellationReasons()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    fetchDistributors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBookId])

  const fetchDistributors = async () => {
    const bookDistributorRes = await callApi(PATHNAMES.GET_DISTRIBUTORS_FOR_BOOK(props.publisherId || "", currentBookId, currentBookOrderType.toLowerCase()), HTTP_METHODS.GET)
    if (bookDistributorRes?.success && bookDistributorRes?.data) {
      if (currentBookId && currentBookOrderType) {
        if (currentBookOrderType === 'PAPERBACK') {
          setDistributors(bookDistributorRes.data.filter((item: IFormattedDistributor) => (item.inventory.paperbackQuantity >= currentOrderQuantity)))
        } else {
          setDistributors(bookDistributorRes.data.filter((item: IFormattedDistributor) => (item.inventory.hardboundQuantity >= currentOrderQuantity)))
        }
      }
    }
  }


  // RESET DIALOG BOX VALUE 
  const resetDialogBoxValues = async () => {
    setCurrentOrderId("")
    setCurrentOrderStatus("")
    setRadioButtonSelection("")
    setSelectedReason("")
    setAdditionalCommentForCancellation("")
    setAdditionalCommentForForDelivered("")
    setTrackingData({
      courierAgencyName: "",
      consignmentId: "",
      eta: "",
      trackingUrl: "",
    })
  }

  // CLOSE DIALOG BOX
  const handleDialogBoxClose = () => {
    setOpenModal(false)
    setTimeout(resetDialogBoxValues, 500)
  }

  // DISABLE PROCEED BUTTON
  function handleDisableButton() {
    // not selected then disable 
    if (!radioButtonSelection) {
      return true;
    }
    if (radioButtonSelection === OrderAction.CANCEL_ORDER && !selectedReason) {
      return true;
    }
    if (radioButtonSelection === OrderAction.IN_TRANSIT_ORDER && (!trackingData.courierAgencyName || !trackingData.consignmentId || !trackingData.eta)) {
      return true;
    }

    return false;
  }

  //  HANDLE PROCEED BUTTON ACTIONS
  const handleProceedButton = () => {
    if (radioButtonSelection === OrderAction.DELIVER_ORDER) {
      markOrderAsCompleted(currentOrderId)
    }
    if (radioButtonSelection === OrderAction.CANCEL_ORDER) {
      markOrderAsCancelled(currentOrderId)
    }
    if (radioButtonSelection === OrderAction.IN_TRANSIT_ORDER) {
      markOrderAsShipped(currentOrderId)
    }

  }
  // FETCH CANCELLATION RESONS API
  const fetchCancellationReasons = () => {
    callApi(PATHNAMES.GET_ORDER_CANCELLATION_REASONS(), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        setCancellationReasons(res.data)
      }
    })
  }

  // MARK ORDER AS COMPLETED API
  const markOrderAsCompleted = (subOrderId: string) => {
    const data = {
      additionalComment: additionalCommentForDelivered
    }
    callApi(PATHNAMES.MARK_ORDER_AS_DELIVERED_SUPERADMIN(subOrderId), HTTP_METHODS.POST, {
      body: JSON.stringify({ data }),
    }).then((res) => {
      if (res && res.success && res.data) {
        fetchPublisherOrders({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
        handleDialogBoxClose()
        snackbar({
          message: res.message || "order marked as delivered successfully",
          variant: SnackBarVariant.SUCCESS
        })
      } else {
        snackbar({
          message: res.message || "can not proceed now",
          variant: SnackBarVariant.ERROR
        })
      }
    })
  }

  // MARK ORDER AS CANCELLED API
  const markOrderAsCancelled = (subOrderId: string) => {
    const data = {
      reason: selectedReason,
      additionalComment: additionalCommentForCancellation
    }
    callApi(PATHNAMES.CANCEL_USER_ORDER(subOrderId), HTTP_METHODS.POST, {
      body: JSON.stringify({ data }),
    }).then((res) => {
      if (res && res.success) {
        fetchPublisherOrders({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
        handleDialogBoxClose()
        snackbar({
          message: res.message || "order marked as delivered successfully",
          variant: SnackBarVariant.SUCCESS
        })
      } else {
        snackbar({
          message: res.message || "can not proceed now",
          variant: SnackBarVariant.ERROR
        })
      }
    })
  }

  // MARK ORDER AS SHIPPED (IN TRANSIT)
  const markOrderAsShipped = (subOrderId: string) => {
    const data = {
      courierAgencyName: trackingData.courierAgencyName,
      consignmentId: trackingData.consignmentId,
      shippedOn: moment().toISOString(),
      eta: trackingData.eta,
      trackingUrl: trackingData.trackingUrl,
      distributorId: selectedDistributor,
      rachnayeOwnedBook,
    }

    callApi(PATHNAMES.MARK_ORDER_AS_SHIPPED_BY_ADMIN(subOrderId), HTTP_METHODS.POST, {
      body: JSON.stringify({ data }),
    }).then((res) => {
      if (res && res.success && res.data) {
        fetchPublisherOrders({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
        handleDialogBoxClose()
        snackbar({
          message: res.message || "order marked as delivered successfully",
          variant: SnackBarVariant.SUCCESS
        })
      }
    });
  };





  // FETCH PUBLISHER ORDERS API
  const fetchPublisherOrders = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args
    callApi(PATHNAMES.GET_PUBLISHER_SUB_ORDERS(props.publisherId, pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then((ordersResponse) => {
      if (ordersResponse && ordersResponse.success && ordersResponse.data) {
        setOrders(ordersResponse.data.subOrders);
        setTotalOrders(ordersResponse.data.totalSubOrdersCount)
        // re-render only when we get new value
        setPublisherHasOrders(oldValue => oldValue === ordersResponse.data.publisherHasOrders ? oldValue : ordersResponse.data.publisherHasOrders)
      }
    })
  }


  const options: MUIDataTableOptions = {
    elevation: 0,
    setRowProps: (row: any, rowIndex: number) => {
      return {
        onMouseEnter: () => handleRowHover(rowIndex),
        onMouseLeave: () => handleRowHoverLeave(rowIndex),
      };
    },
    filter: false,
    count: totalOrders,
    searchPlaceholder: "Search by order Id, type, user, date [dd/mm/yy]",
  };

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      label: "Order Id",
      name: "referenceNumber"
    },
    {
      label: "BOOK",
      name: "book",
      options: {
        filter: false,
        customBodyRender: (data: { id: string, title: string }) => data.title
      }
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (data: string) => data.replace("_", " ").toUpperCase()
      }
    },
    {
      label: "ORDER BY",
      name: "user",
      options: {
        customBodyRender: (data: any) => data?.firstName + " " + data?.lastName,
      }
    },
    {
      label: "Order Type",
      name: "orderType",
      options: {
        customBodyRender: (data: string) => data.replace("_", " ").toUpperCase()
      }
    },
    {
      label: "Unit",
      name: "unitPrice",
      options: {
        customBodyRender: (data: number) => "₹" + data?.toLocaleString('en-IN')
      }
    },
    {
      label: "Nos.",
      name: "quantity",
    },
    {
      label: "Offer",
      name: "offerDiscountPrice",
      options: {
        customBodyRender: (data: number | undefined) => data !== undefined ? "₹" + data?.toLocaleString('en-IN') : "-"
      }
    },
    {
      label: "Coupon",
      name: "couponDiscountPrice",
      options: {
        customBodyRender: (data: number | undefined) => data !== undefined ? "₹" + data?.toLocaleString('en-IN') : "-"
      }
    },
    {
      label: "Global Offer",
      name: "globalOfferDiscountPrice",
      options: {
        customBodyRender: (data: number | undefined) => data !== undefined ? "₹" + data?.toLocaleString('en-IN') : "-"
      }
    },
    {
      label: "Birthday Offer",
      name: "exclusiveBirthDayDiscountPrice",
      options: {
        customBodyRender: (data: number | undefined) => data !== undefined ? "₹" + data?.toLocaleString('en-IN') : "-"
      }
    },
    {
      label: "Total",
      name: "totalAmount",
      options: {
        customBodyRender: (data: number | undefined) => data !== undefined ? "₹" + data?.toLocaleString('en-IN') : "-"
      }
    },
    {
      label: "Sub T.",
      name: "subTotal",
      options: {
        customBodyRender: (data: number) => data !== undefined ? "₹" + data?.toLocaleString('en-IN') : "-"
      }
    },
    {
      label: "Taxes",
      name: "taxAmount",
      options: {
        customBodyRender: (data: number) => "₹" + data?.toLocaleString('en-IN')
      }
    },
    {
      label: "Publisher Shr",
      name: "publisherShare",
      options: {
        customBodyRender: (data: number) => "₹" + data?.toLocaleString('en-IN')
      }
    },
    {
      label: "Platform Shr",
      name: "platformShare",
      options: {
        customBodyRender: (data: number) => "₹" + data?.toLocaleString('en-IN')
      }
    },
    {
      label: "Order Date",
      name: "createdAt",
      options: {
        customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY, h:mm A")
      }
    },
    {
      label: "Order From",
      name: "platform",
      options: {
        customBodyRender: (data: string) => data?.toUpperCase()
      }
    },
    {
      label: "",
      name: "",
      options: {
        // setCellProps: () => ({ style: { minWidth: "2rem", maxHeight: '2rem' } }),
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const [subOrderId, , book, status, , orderType, , quantity] = tableMeta.rowData
          if ([SubOrderStatus.CONFIRMED, SubOrderStatus.INTRANSIT, SubOrderStatus.PICKUP_SCHEDULED].includes(status)) {
            return (
              <IconButton
                size="small"
                onClick={() => {
                  setOpenModal(true)
                  setCurrentOrderStatus(status)
                  setCurrentOrderId(subOrderId)
                  setCurrentBookId(book.id)
                  setCurrentBookOrderType(orderType)
                  setCurrentOrderQuantity(quantity)
                }}
              >
                <LocalShippingIcon color="primary" />
              </IconButton>

            )
          }
          return <></>
        }
      }
    }
  ]


  return (
    <Grid container xs={12}>
      {!publisherHasOrders ?
        <Typography variant="body1">
          Publisher don't have any orders yet
        </Typography> :
        <Grid container xs={12}>
          <DataTable
            title={`Orders (${totalOrders})`}
            rows={orders}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchPublisherOrders}
          />
        </Grid>
      }
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        keepMounted={false}
        open={openModal}
        onClose={handleDialogBoxClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Grid container xs={12}>
            <Grid item xs={8}>
              <Typography variant="h3">Order Action</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h3">{`Order Status : ${currentOrderStatus.toUpperCase()}`}</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers={true} style={{ minHeight: "10vh" }}>
          <Grid item xs={12} spacing={4} style={{ padding: '0.6rem', }}>
            <FormControl>
              <RadioGroup
                name="orderStatusType"
                onChange={(event) => {
                  setRadioButtonSelection(event.target.value)
                }}
              >
                <FormControlLabel value={OrderAction.DELIVER_ORDER} control={<Radio color="primary" />} label={
                  <Typography variant="h3">{"Mark Order as Delivered"}</Typography>
                }
                />
                {radioButtonSelection === OrderAction.DELIVER_ORDER &&
                  <Grid item xs={12} style={{ padding: "1rem" }}>
                    <TextareaAutosize
                      rowsMin={6}
                      rowsMax={6}
                      placeholder="Write notes ..."
                      style={{ marginTop: "1rem", width: "100%", resize: 'none', backgroundColor: "#f2f2f2" }}
                      onChange={(e) => {
                        setAdditionalCommentForForDelivered(e.target.value)
                      }}
                    />
                  </Grid>
                }
                <FormControlLabel value={OrderAction.CANCEL_ORDER} control={<Radio color="primary" />} label={
                  <Typography variant="h3">{"Cancel Order"}</Typography>
                }
                />
                {radioButtonSelection === OrderAction.CANCEL_ORDER &&
                  <Grid item xs={12} style={{ padding: '1rem' }}>
                    <FormControl >
                      <RadioGroup
                        name="cancellationReasons"
                        onChange={(event) => {
                          setSelectedReason(event.target.value)
                        }}
                      >
                        {cancellationReasons.map((reason, key) => <>
                          <FormControlLabel
                            value={reason.reasonValue}
                            control={<Radio color="primary" size="small" />}
                            label={<Typography variant="body2">{reason.reasonKey.replaceAll("_", " ")}</Typography>}

                          />
                        </>)}
                      </RadioGroup>
                    </FormControl>
                    <TextareaAutosize
                      rowsMin={6}
                      rowsMax={6}
                      placeholder="Write additional reason ..."
                      style={{ marginTop: "1rem", width: "100%", resize: 'none', backgroundColor: "#f2f2f2" }}
                      onChange={(e) => {
                        setAdditionalCommentForCancellation(e.target.value)
                      }}
                    />
                  </Grid>
                }
                {currentOrderStatus === SubOrderStatus.CONFIRMED &&
                  <FormControlLabel value={OrderAction.IN_TRANSIT_ORDER} control={<Radio color="primary" />} label={
                    <Typography variant="h3">{"Mark Order as In Transit"}</Typography>
                  }
                  />
                }
                {radioButtonSelection === OrderAction.IN_TRANSIT_ORDER &&
                  <Grid container xs={12} spacing={2} >
                    <Grid item xs={12} style={{ paddingRight: "1rem" }}>
                      <Divider
                        variant='fullWidth'
                        orientation='horizontal'
                        style={{
                          borderTop: "3px dashed #bbb",
                          margin: "1rem 0rem",
                        }} />
                    </Grid>
                    <Grid item container xs={12} alignItems="center">
                      <Grid item >
                        <Switch name='rachnayeOwnedBook' checked={rachnayeOwnedBook} onChange={(event) => {
                          setRachnayeOwnedBook(event.target.checked)
                        }} />
                      </Grid>
                      <Grid item style={{ backgroundColor: rachnayeOwnedBook ? "#CBF7B8" : "white" }}>
                        <Typography variant="h3" >Rachnaye Owned Book.</Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={7}>
                      {distributors.length > 0 ?
                        <Autocomplete
                          options={distributors}
                          getOptionLabel={(option) => `${option.name} `}
                          renderOption={(option) => `${option.name.toUpperCase()} - ${option.address.city}, ${option.address.state} - ${currentBookOrderType === OrderItemTypes.PAPERBACK ? `Paperback Quantity:${option.inventory.paperbackQuantity}` : `Hardbound Quantity:${option.inventory.hardboundQuantity}`} `}
                          style={{ width: 750 }}
                          onChange={(event: any, newValue: IFormattedDistributor | null) => {
                            if (newValue && newValue.id) {
                              setSelectedDistributor(newValue.id)
                            }
                          }}
                          renderInput={(params) => (
                            <TextField required {...params} variant="outlined" className={classes.margin} placeholder='Select a distributor' />
                          )}
                        /> :
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '1rem' }}>
                          <Typography variant='h4' className={classes.redirectLink} onClick={() => navigate(`view-book?book=${currentBookId}`)}> {`Please update ${currentBookOrderType.toLowerCase()} inventory before proceeding >`}</Typography>
                        </Grid>
                      }

                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        required
                        label="Courier Agency Name"
                        placeholder="Courier Agency Name"
                        name="courierAgencyName"
                        value={trackingData.courierAgencyName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value && typeof e.target.value === "string") {
                            setTrackingData({
                              ...trackingData,
                              courierAgencyName: e.target.value
                            })
                          };
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={4} >
                      <TextField
                        fullWidth
                        required
                        label="Consignment Id"
                        placeholder="Consignment Id"
                        name="consignmentId"
                        value={trackingData.consignmentId}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setTrackingData({
                            ...trackingData,
                            consignmentId: e.target.value || ""
                          })
                        }}
                      ></TextField>

                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        required
                        label="Tracking URL"
                        name="trackingUrl"
                        value={trackingData.trackingUrl}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setTrackingData({
                            ...trackingData,
                            trackingUrl: e.target.value || ""
                          })
                        }}
                      ></TextField>

                    </Grid>
                    <Grid item xs={4} className={classes.datePicker} >
                      <FlatPickerBar
                        required
                        handleDateChange={(value: Date) => {
                          setTrackingData({
                            ...trackingData,
                            eta: moment(value)
                              .startOf("day")
                              .toISOString(),
                          });
                        }}
                        label="Estimated Time of Arrival"
                        identifier="eta"
                        name="eta"
                        minDate={moment()
                          .startOf("day")
                          .toDate()}
                        value={trackingData.eta}
                      />
                    </Grid>
                  </Grid>
                }
              </RadioGroup>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container item xs={12} justify="flex-end">
            <Fab
              size="medium"
              variant="extended"
              className="blackBackButton"
              onClick={handleDialogBoxClose}
            >
              Cancel
            </Fab>
            <Box pl={2}></Box>
            <Fab
              size="medium"
              variant="extended"
              onClick={handleProceedButton}
              disabled={handleDisableButton()}
            >
              Proceed
            </Fab>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default PublisherOrders;