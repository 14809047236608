import { Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import callApi from "../../../utils/apiUtil";
import { IApiCall } from "../../../utils/common.interface";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import BookImage from "../../common/BookImage";
import { Pagination } from "../../common/Datatable/types";
import InfiniteScroll from "../MyRachnaye/InfiniteScroll";

const useStyles = makeStyles(() => ({
  hoverProperties: {
    transition: "all 0.5s ease-out",
    "&:hover": {
      transform: "scale3d(1.10, 1.10, 1)"
    },
  },
}));

const NewArrivalBooks: React.FC = () => {
  const fetchBooks = async ({ pageNumber = Pagination.DEFAULT_PAGE_NUMBER, pageSize = Pagination.DEFAULT_PAGE_SIZE, signal }: IApiCall) => {
    const res = await callApi(PATHNAMES.GET_NEW_ARRIVALS_BOOKS_PORTAL(pageNumber, pageSize), HTTP_METHODS.GET, { signal });
    if (res && res.success && res.data && Array.isArray(res.data) && res.data.length > 0) {
      return res.data
    } else {
      return []
    }
  };

  return (
    <Grid container xs={12}>
      <Grid container xs={12} style={{ marginBottom: "1rem" }}>
        <Typography variant='h2' style={{ fontWeight: "bolder" }}>
          New Arrival Books
        </Typography>
      </Grid>
      <InfiniteScroll
        pageNumber={Pagination.DEFAULT_PAGE_NUMBER}
        pageSize={Pagination.DEFAULT_PAGE_SIZE}
        apiCall={fetchBooks}
        msgWhenDataIsEmpty="No new arrival books available"
        noMoreDataMessage="No more data to load"
        pagination
      >
        <NewArrival />
      </InfiniteScroll>
    </Grid>
  )
}

interface INewArrivalProps {
  items?: any[]
  lastElementRef?: (node: any) => void
}
const NewArrival: React.FC<INewArrivalProps> = (props) => {
  const classes = useStyles()
  const isLastElementinList = (index: number): boolean => props.items?.length === index + 1

  return (
    <Grid container xs={12} style={{ margin: "2rem 0rem", gap: "6rem 2rem" }}>
      {props.items?.map((book, index) => {
        return (
          <Grid item xs='auto' key={index} ref={isLastElementinList(index) ? props.lastElementRef : null} >
            <BookImage book={book} className={classes.hoverProperties} showTitle />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default NewArrivalBooks;