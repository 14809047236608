import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../../utils/common.interface";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { DataTable } from "../../../common/Datatable/Datatable";
import { Pagination } from "../../../common/Datatable/types";

const PurchasedSeries: React.FC = () => {
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [writerHasOrders, setWriterHasOrders] = useState<boolean>(true);
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState<number>(0);


  useEffect(() => {
    if (mobileUserState && mobileUserState.id) {
      fetchSeriesOrders({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState.id])

  const fetchSeriesOrders = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args
    callApi(PATHNAMES.GET_WRITER_ORDERS(mobileUserState.id, pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then((res) => {
      if (res && res.success && res.data && res.data) {
        setOrders(res.data.orders);
        setTotalOrders(res.data.totalOrders);
        // re-render only when we get new value
        setWriterHasOrders(oldValue => oldValue === res.data.writerHasOrders ? oldValue : res.data.writerHasOrders)
      }
    })
  }

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      label: "DATE & TIME",
      name: "createdAt",
      options: {
        customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY, h:mm A"),
      }
    },
    {
      label: "ORDER ID",
      name: "referenceNumber",
      options: {
      }
    },
    {
      label: "Series",
      name: "series",
      options: {
        customBodyRender: (data: any) => data?.title || "",
      }
    },
    {
      label: "ORDER BY",
      name: "user",
      options: {
        customBodyRender: (data: any) => data?.firstName + " " + data?.lastName,
      }
    },
    {
      label: "PRICE",
      name: "totalAmount",
      options: {
        customBodyRender: (data: number) => "₹" + data?.toLocaleString('en-IN'),
      }
    },
    {
      label: "YOU EARN",
      name: "writerShare",
      options: {
        customBodyRender: (data: number) => "₹" + data?.toLocaleString('en-IN'),
      }
    },
  ]


  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    searchPlaceholder: "Search by order by, order Id,  Date[dd/mm/yyyy]",
    count: totalOrders
  };

  return (
    <Grid container xs={12}>
      {!writerHasOrders ?
        <Typography variant="body1">
          No purchases yet
        </Typography> :
        <DataTable
          title={`Total Orders (${totalOrders})`}
          rows={orders}
          columns={columns}
          options={options}
          hideSelectableRows={true}
          serverSide
          fetchData={fetchSeriesOrders}
        />
      }
    </Grid>
  );
};

export default PurchasedSeries;