import {
  Grid
} from "@material-ui/core";
import { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { IUser } from "../../../../reducers/orders/types";
import callApi from "../../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../../utils/common.interface";
import { HTTP_METHODS } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { DataTable } from "../../../common/Datatable/Datatable";
import { Pagination } from "../../../common/Datatable/types";

interface IProps {
  publisherId: string;
}

const columns: any = [
  {
    label: "First Name",
    name: "firstName"
  },
  {
    label: "Last Name",
    name: "lastName"
  },
  {
    label: "Email",
    name: "emailId"
  },
  {
    label: "Status",
    name: "status"
  },
  {
    label: "Role",
    name: "role",
    options: {
      customBodyRender: (data: any) => data?.name?.toUpperCase() || ""
    }
  }
]

const PublisherUsers: React.FC<IProps> = (props) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  useEffect(() => {
    if (props.publisherId) {
      fetchPublisherUsers({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.publisherId])


  const fetchPublisherUsers = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args
    callApi(PATHNAMES.PUBLISHER_USERS(props.publisherId, pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then((res: any) => {
      if (res && res.success && res.data) {
        setUsers(res.data.iamUsers);
        setTotalUsers(res.data.totalIamUsers)
      }
    })
  }

  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    searchPlaceholder: "Search with user name email status or role",
    count: totalUsers,
  };
  return (
    <Grid container xs={12}>
      <Grid container xs={12}>
        <DataTable
          title={`Users (${totalUsers})`}
          rows={users}
          columns={columns}
          options={options}
          hideSelectableRows={true}
          serverSide
          fetchData={fetchPublisherUsers}
        />
      </Grid>
    </Grid>
  )
}

export default PublisherUsers;