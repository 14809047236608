import {
  Avatar,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Grid,
  IconButton,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import CreateIcon from '@material-ui/icons/Create';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from "@material-ui/icons/Error";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import VerifiedIcon from "@material-ui/icons/VerifiedUserRounded";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from "react-redux";
import { defaultUserAddress } from "../../../../reducers/mobileUser/constants";
import { updateMobileUser } from "../../../../reducers/mobileUser/reducer";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { EMAIL_REGEX, HTTP_METHODS, ResourceTypes } from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import { uploadFileToS3 } from "../../../../utils/s3Util";
import FlatPickerBar from "../../../common/FlatPickerBar";
import ImageUploader from "../../../common/ImageUploader";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import EditUserAddress from "./EditUserAddress";
import UserAddressCard from "./UserAddressCard";


// CSS
const useStyles = makeStyles(() => ({
  alignCicularProgessBarText: {
    "& .CircularProgressbar-text": {
      dominantBaseline: "central",
      fontWeight: "bolder"
    },
  },
  cartCount: {
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '50%',
    padding: '0.1rem 0.4rem',
    fontSize: '0.8rem',
    position: 'absolute',
    top: '0.1rem',
    right: '6.4rem',
  },
  textArea: {
    width: "100%",
    borderRadius: '0.3rem',
    padding: "1rem",
    outline: "none",
    fontSize: "14px",
    fontFamily: "Poppins",
    backgroundColor: "transparent",
    resize: "none"
  },
  cameraButton: {
    padding: "0rem",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  cameraIcon: {
    paddingTop: "0rem",
    color: "white"
  },
  verified: {
    color: "green",
    fontSize: 18,
    marginLeft: "0.3rem",
    position: "relative",
    top: "0.2rem"
  },
  errorVerified: {
    color: "red",
    fontSize: 18,
    marginLeft: "0.3rem",
    position: "relative",
    top: "0.2rem"
  },
  editButtonTooltip: {
    fontSize: '15px',
  },
}))


// INTERFACES
interface IPersonalInfo {
  firstName: string;
  lastName: string;
  penName: string;
  dateOfBirth: string;
  canChangeDOB: boolean;
}

interface IContactInfo {
  email: string;
  phoneNumber: string;
}
interface ITitleSubtitleAndInputField {
  title: string;
  subTitle: string;
  showVerifiedIcon?: boolean;
  verifiedValue?: boolean;
  editMode?: boolean;
  editFieldType?: string;
  handleEditField: (e: any) => void;
  editFieldValue?: string | any
  disabled?: boolean
  showToolTipMessage?: boolean;
  toolTipMessage?: string
}

interface IEditAndSaveCancelBtnProps {
  editMode: boolean;
  handleClickEdit: () => void;
  handleClickSave: () => void;
  handleClickCancel: () => void;
}


// COMPONENTS
const TitleSubtitleAndInputField: React.FC<ITitleSubtitleAndInputField> = (props) => {
  const classes = useStyles()
  return (
    <Grid container xs={12} style={{ margin: "1rem 0rem" }} alignItems="flex-start">
      <Grid item xs={12} style={{ marginBottom: "0.6rem" }}>
        <Typography variant="h6" style={{ fontWeight: 400, color: "#60677A" }}>{props.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        {props.editMode ?
          // Edit View
          props.editFieldType === "text" ?
            <TextField
              variant="outlined"
              required
              disabled={props.disabled}
              defaultValue={props.editFieldValue}
              fullWidth
              onChange={props.handleEditField}
            />
            :
            <FlatPickerBar
              handleDateChange={props.handleEditField}
              identifier="dob"
              name="dob"
              disabled={props.disabled}
              value={props.editFieldValue}
            />



          :
          // Normal Readable View
          <>
            <Typography variant="h4" style={{ fontWeight: 500, display: "inline" }}>{props.subTitle}</Typography>
            {props.showVerifiedIcon &&
              <>
                {props.verifiedValue ?
                  <Tooltip title="Verified">
                    <VerifiedIcon className={classes.verified} />
                  </Tooltip> :
                  <Tooltip title="Unverified">
                    <ErrorIcon className={classes.errorVerified} />
                  </Tooltip>}
              </>
            }
          </>
        }
      </Grid>
      <Grid item xs={1} style={{ width: "2rem" }}>
        {props.editMode && props.showToolTipMessage &&
          <IconButton>
            <Tooltip title={props.toolTipMessage || ""} placement="top" classes={{ tooltip: classes.editButtonTooltip }}>
              <ErrorOutlineIcon />
            </Tooltip>
          </IconButton>
        }
      </Grid>
    </Grid>
  )
}

const EditSaveAndCancelButtons: React.FC<IEditAndSaveCancelBtnProps> = (props) => {
  return (
    props.editMode ?
      <div>
        <Fab variant="extended" size="small" onClick={props.handleClickSave} style={{ padding: '0.6rem', marginRight: "1rem" }}>Save</Fab>
        <Fab variant="extended" size="small" onClick={props.handleClickCancel} style={{ padding: '0.6rem', backgroundColor: "grey" }}>Cancel</Fab>
      </div>
      :
      <Fab variant="extended" size="small" onClick={props.handleClickEdit} style={{ padding: '0.6rem' }}>
        <CreateIcon fontSize="small" />
      </Fab>
  )
}


const ProfileContainer: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch();
  const snackbar = useSnackBar();
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);

  const [bio, setBio] = useState<string>(mobileUserState.aboutMe ?? "")
  const [personalInfo, setPersonalInfo] = useState<IPersonalInfo>({
    firstName: "",
    lastName: "",
    penName: "",
    dateOfBirth: "",
    canChangeDOB: false,
  })
  const [contactInfo, setContactInfo] = useState<IContactInfo>({
    email: "",
    phoneNumber: ""
  })
  const [shallEditPersonalInfo, setShallEditPersonalInfo] = useState<boolean>(false)
  const [shallEditContactInfo, setShallEditContactInfo] = useState<boolean>(false)
  const [shallEditBio, setShallEditBio] = useState<boolean>(false)
  const [profileImageUploading, setProfileImageUploading] = useState<boolean>(false)
  const [openAddAddress, setOpenAddAddress] = useState<boolean>(false)
  const [openOtpVerification, setOpenOtpVerification] = useState<boolean>(false)

  const handleEditAddressClose = () => {
    setOpenAddAddress(false)
    if (mobileUserState?.id) {
      callApi(PATHNAMES.GET_MOBILE_USER(mobileUserState.id), HTTP_METHODS.GET).then((res) => {
        if (res && res.success && res.data) {
          dispatch(updateMobileUser(res.data));
        }
      })
    }
  }

  const handleOptVerificationClose = () => setOpenOtpVerification(false)

  const getAddresses = () => {
    if (mobileUserState.addresses) {
      return mobileUserState.addresses.map((addr) => {
        const isDefault = mobileUserState.defaultShippingAddress === addr.id;
        return { ...addr, isDefault };
      }).sort((a, b) => Number(b.isDefault) - Number(a.isDefault));
    }
    return [];
  };



  useEffect(() => {
    if (mobileUserState.aboutMe) {
      setBio(mobileUserState.aboutMe)
    }
  }, [mobileUserState.aboutMe])

  useEffect(() => {
    setPersonalInfo({
      firstName: mobileUserState.firstName,
      lastName: mobileUserState.lastName,
      penName: mobileUserState.penName ?? "",
      dateOfBirth: mobileUserState.dateOfBirth,
      canChangeDOB: mobileUserState.canChangeDOB
    })
  }, [mobileUserState.firstName, mobileUserState.lastName, mobileUserState.penName, mobileUserState.dateOfBirth])


  useEffect(() => {
    setContactInfo({
      phoneNumber: mobileUserState.contactNumber?.phoneNumber ?? '',
      email: mobileUserState.email,
    })
  }, [mobileUserState.email, mobileUserState.contactNumber?.phoneNumber])

  const saveUserDetailsApi = async (data: any) => {
    const res = await callApi(PATHNAMES.UPDATE_MOBILE_USER(mobileUserState.id), HTTP_METHODS.PUT, {
      body: JSON.stringify({ data })
    })

    if (res && res.success) {
      snackbar({
        message: "Your profile updated successfully",
        variant: SnackBarVariant.SUCCESS
      })
      setPersonalInfo(v => ({
        ...v,
        canChangeDOB: res.data.canChangeDOB
      }))

    } else {
      snackbar({
        message: res.message || "Error occured while upating profile",
        variant: SnackBarVariant.ERROR
      })
    }
    return res
  }

  const uploadDocument = async (file: File) => {
    setProfileImageUploading(true)
    const key = await uploadFileToS3({
      file,
      resourceType: ResourceTypes.USER_PROFILE,
      isAdmin: false,
      publisherId: mobileUserState.id
    });
    const res = await callApi(PATHNAMES.GET_SIGNED_URL, HTTP_METHODS.POST, {
      body: JSON.stringify({ data: { key } })
    });
    if (res && res.success && res.data) {
      dispatch(updateMobileUser({ ...mobileUserState, profileImageUrl: res.data }))
    }

    await saveUserDetailsApi({
      profileImageUrl: key
    })
    setProfileImageUploading(false)
  }

  const handleOptVerify = async (otp: number) => {
    const data = {
      email: mobileUserState.email,
      otp
    }
    const res = await callApi(PATHNAMES.VERIFY_MOBILE_USER_EMAIL(), HTTP_METHODS.POST, {
      body: JSON.stringify({ data })
    })

    if (res && res.success) {
      dispatch(updateMobileUser({ ...mobileUserState, emailStatus: "VERIFIED" }))
      handleOptVerificationClose()
      snackbar({
        message: res.message || "Email updated ",
        variant: SnackBarVariant.SUCCESS
      })
    } else {
      snackbar({
        message: res.message || "Error occured while upating profile",
        variant: SnackBarVariant.ERROR
      })
    }
    return res
  }

  const handleClickBioSave = async () => {
    const res = await saveUserDetailsApi({ aboutMe: bio })
    if (res && res.success) {
      dispatch(updateMobileUser({ ...mobileUserState, aboutMe: bio }))
    }
    return setShallEditBio(false)
  }

  const handleClickPersonalInfoSave = async () => {
    if (!personalInfo.firstName.trim()) {
      snackbar({
        message: "First name cannot be empty",
        variant: SnackBarVariant.ERROR
      })
      return
    }
    if (personalInfo.firstName.trim().length < 3) {
      snackbar({
        message: "First name must be atleat 3 char long",
        variant: SnackBarVariant.ERROR
      })
      return
    }
    const res = await saveUserDetailsApi(personalInfo)
    if (res && res.success) {
      dispatch(updateMobileUser({ ...mobileUserState, ...personalInfo }))
    }
    return setShallEditPersonalInfo(false)
  }

  const handleClickContactInfoSave = async () => {
    if (!EMAIL_REGEX.test(contactInfo.email)) {
      snackbar({
        message: "Invalid email id",
        variant: SnackBarVariant.ERROR
      })
      return
    }
    const res = await callApi(PATHNAMES.ADD_MOBILE_USER_EMAIL(mobileUserState.id), HTTP_METHODS.POST, {
      body: JSON.stringify({ data: { email: contactInfo.email } })
    })
    if (res && res.success) {
      dispatch(updateMobileUser({ ...mobileUserState, email: contactInfo.email }))
      setOpenOtpVerification(true)
      snackbar({
        message: res.message || "Your profile updated successfully",
        variant: SnackBarVariant.SUCCESS
      })
    } else {
      snackbar({
        message: res.message || "Error occured while upating profile",
        variant: SnackBarVariant.ERROR
      })
    }
    return setShallEditContactInfo(false)
  }

  const handleFirstNameSave = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPersonalInfo({ ...personalInfo, firstName: e.target.value })
  }
  const handleLastNameSave = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPersonalInfo({ ...personalInfo, lastName: e.target.value })
  }
  const handlePenNameSave = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPersonalInfo({ ...personalInfo, penName: e.target.value })
  }
  const handleEmailSave = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactInfo({ ...contactInfo, email: e.target.value })
  }

  const handleDateOfBirthSave = (value: Date) => {
    setPersonalInfo({ ...personalInfo, dateOfBirth: moment(value).startOf("day").toISOString() })
  }

  return (
    <Grid container item xs={12} md={7} style={{ borderRadius: "1rem", backgroundColor: "#FFF", marginBottom: "1rem" }}>
      <Grid item container xs={12} style={{ padding: "2.5rem 0rem", borderBottom: "3px solid #F5F6F8" }}>
        <Grid container xs={12} >
          <Grid container item justify="center" style={{ width: '100%' }}>
            <div style={{ position: "relative" }}>
              <div style={{ height: 125, width: 125, borderRadius: "50%", backgroundColor: "grey", overflow: "hidden" }}>
                {/* If image is in uploading state show circular bar , else show image or avatar based on user has img or not  */}
                {profileImageUploading ? <CircularProgress style={{ padding: "2.5rem" }} /> :
                  mobileUserState.profileImageUrl ?
                    <img alt={mobileUserState.firstName || "Profile Image"} style={{ width: "100%", height: "100%", objectFit: "cover" }} src={mobileUserState.profileImageUrl} />
                    :
                    <Avatar style={{ width: "100%", height: "100%", backgroundColor: "grey" }} />
                }
              </div>
              <IconButton color="primary" style={{ position: "absolute", bottom: -5, right: 7, backgroundColor: "#FF5C3E", padding: "0.6rem" }}>
                <ImageUploader id={'UserProfileImage'} handleSave={uploadDocument} buttonClassName={classes.cameraButton} cameraIconClassName={classes.cameraIcon} />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {/* PERSONAL INFO BOX */}
      <Grid item container xs={12} style={{ padding: "2rem" }}>
        <Grid item container xs={12} style={{ borderRadius: "15px", padding: "1rem", border: "3px solid #F5F6F8" }}>
          {/* HEADER */}
          <Grid item container alignItems="center" xs={12} justify="space-between">
            <Grid item sm={6} xs={shallEditPersonalInfo ? 12 : 6} >
              <Typography variant="h3" style={{ fontWeight: 800 }}>Personal Info</Typography>
            </Grid>
            <Grid container item sm={6} xs={shallEditPersonalInfo ? 12 : 6} justify="flex-end">
              <EditSaveAndCancelButtons editMode={shallEditPersonalInfo} handleClickCancel={() => setShallEditPersonalInfo(false)} handleClickEdit={() => setShallEditPersonalInfo(true)} handleClickSave={handleClickPersonalInfoSave} />
            </Grid>
          </Grid>
          <Grid item container xs={12} style={{ paddingTop: "0.6rem" }} justifyContent="space-between">
            <Grid item xs={'auto'}>
              <TitleSubtitleAndInputField editFieldType="text" title="First Name" subTitle={mobileUserState.firstName} editMode={shallEditPersonalInfo} handleEditField={handleFirstNameSave} editFieldValue={personalInfo.firstName} />
            </Grid>
            <Grid item xs={'auto'}>
              <TitleSubtitleAndInputField editFieldType="text" title="Last Name" subTitle={mobileUserState.lastName || "-"} editMode={shallEditPersonalInfo} handleEditField={handleLastNameSave} editFieldValue={personalInfo.lastName} />
            </Grid>
            <Grid item xs={'auto'}>
              <TitleSubtitleAndInputField editFieldType="text" title="Pen Name" subTitle={mobileUserState.penName || "-"} editMode={shallEditPersonalInfo} handleEditField={handlePenNameSave} editFieldValue={personalInfo.penName} />
            </Grid>
            <Grid item xs={'auto'}>
              <TitleSubtitleAndInputField editFieldType="date" title="Date of Birth" subTitle={moment(mobileUserState.dateOfBirth).format("DD-MM-YYYY") ?? "-"} editMode={shallEditPersonalInfo} handleEditField={handleDateOfBirthSave} editFieldValue={personalInfo.dateOfBirth} disabled={!personalInfo.canChangeDOB} showToolTipMessage toolTipMessage="DOB can be changed after 90 Days" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* CONTACT INFO */}
      <Grid item container xs={12} style={{ padding: "0rem 2rem 2rem 2rem" }}>
        <Grid item container xs={12} style={{ borderRadius: "15px", padding: "1rem", border: "3px solid #F5F6F8" }}>
          {/* HEADER */}
          <Grid item container alignItems="center" xs={12} justify="space-between">
            <Grid item sm={6} xs={shallEditContactInfo ? 12 : 6} >
              <Typography variant="h3" style={{ fontWeight: 800 }}>Contact Info</Typography>
            </Grid>
            <Grid container item sm={6} xs={shallEditContactInfo ? 12 : 6} justify="flex-end">
              <EditSaveAndCancelButtons editMode={shallEditContactInfo} handleClickCancel={() => setShallEditContactInfo(false)} handleClickEdit={() => setShallEditContactInfo(true)} handleClickSave={handleClickContactInfoSave} />
            </Grid>
          </Grid>
          <Grid item container xs={12} style={{ paddingTop: "0.6rem", }}>
            <Grid item xs={12} lg={6}>
              <TitleSubtitleAndInputField editFieldType="text" title="Email" subTitle={mobileUserState.email || "-"} showVerifiedIcon verifiedValue={mobileUserState.emailStatus === "VERIFIED"} editMode={shallEditContactInfo} handleEditField={handleEmailSave} editFieldValue={contactInfo.email} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TitleSubtitleAndInputField editFieldType="text" title="Phone" handleEditField={() => ""} showVerifiedIcon verifiedValue={mobileUserState.phoneVerified} subTitle={`${mobileUserState.contactNumber?.countryCode}-${mobileUserState.contactNumber?.phoneNumber}`} editMode={shallEditContactInfo} editFieldValue={contactInfo.phoneNumber} disabled />
            </Grid>

          </Grid>

        </Grid>
      </Grid>

      {/* BIO BOX */}
      <Grid item container xs={12} style={{ padding: "0rem 2rem 2rem 2rem" }}>
        <Grid item container xs={12} style={{ borderRadius: "15px", padding: "1rem", border: "3px solid #F5F6F8" }}>
          {/* HEADER */}
          <Grid item container alignItems="center" xs={12} justify="space-between">
            <Grid item sm={6} xs={shallEditBio ? 12 : 6}>
              <Typography variant="h3" style={{ fontWeight: 800 }}>About Me</Typography>
            </Grid>
            <Grid container item sm={6} xs={shallEditBio ? 12 : 6} justify="flex-end">
              <EditSaveAndCancelButtons editMode={shallEditBio} handleClickCancel={() => setShallEditBio(false)} handleClickEdit={() => setShallEditBio(true)} handleClickSave={handleClickBioSave} />
            </Grid>
          </Grid>
          {/* content */}
          <Grid item container xs={12} style={{ paddingTop: "1rem" }}>
            {shallEditBio ?
              <TextareaAutosize
                placeholder="Write about you, it will help other readers know about you ... "
                value={bio}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setBio(e.target.value)
                }}
                className={classes.textArea}
              />
              :
              <Typography style={{ fontWeight: 500 }}>{mobileUserState.aboutMe ?? "-"}</Typography>
            }
          </Grid>
        </Grid>
      </Grid>

      {/* ADDRESS BOX */}
      <Grid item container xs={12} style={{ padding: "0rem 2rem 2rem 2rem" }}>
        <Grid item container xs={12} style={{ borderRadius: "15px", padding: "1rem", border: "3px solid #F5F6F8" }}>
          {/* HEADER */}
          <Grid item container alignItems="center" xs={12} justify="space-between">
            <Grid item sm={6} xs={shallEditBio ? 12 : 6}>
              <Typography variant="h3" style={{ fontWeight: 800 }}>Addresses</Typography>
            </Grid>
            <Grid container item sm={6} xs={shallEditBio ? 12 : 6} justify="flex-end">
              <Fab variant="extended" size="small" onClick={() => setOpenAddAddress(true)} style={{ padding: '0.6rem' }}>
                <AddOutlinedIcon fontSize="small" />
              </Fab>
            </Grid>
          </Grid>
          {/* content */}
          <Grid item container xs={12} style={{ paddingTop: "1rem" }}>
            <Grid container spacing={2}>
              {getAddresses().map((address) => <Grid item xs={12} lg={6}>
                <UserAddressCard address={address} user={mobileUserState} />
              </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <EditUserAddress open={openAddAddress} onClose={handleEditAddressClose} address={defaultUserAddress} />
      <OTPVerification open={openOtpVerification} handleClose={() => setOpenOtpVerification(false)} handleSave={handleOptVerify} />
    </Grid>
  )
}

const ProgressBarContainer: React.FC = () => {
  const classes = useStyles()
  const [percentage, setPercentage] = useState<number>(0)
  const [title, setTitle] = useState<string>("Complete your profile")
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const profileItems = [
    {
      text: "Add your actual name",
      icon: "ok",
      percentageWeightage: 20,
      isComplete: () => mobileUserState.firstName?.includes("Guest_") ? false : true
    },
    {
      text: "Upload your photo",
      icon: "ok",
      percentageWeightage: 15,
      isComplete: () => typeof mobileUserState.profileImageUrl === 'string',
    },
    {
      text: "Personal Info  ",
      icon: "ok",
      percentageWeightage: 25,
      isComplete: () => mobileUserState.firstName && !mobileUserState.firstName?.includes("Guest_") && mobileUserState.penName && mobileUserState.dateOfBirth ? true : false
    },
    {
      text: "Contact Info  ",
      icon: "ok",
      percentageWeightage: 15,
      isComplete: () => mobileUserState.contactNumber?.phoneNumber && mobileUserState.email ? true : false
    },
    {
      text: "About me",
      icon: "ok",
      percentageWeightage: 15,
      isComplete: () => mobileUserState.aboutMe?.trim() ? true : false
    },
    {
      text: "Add Address",
      icon: "ok",
      percentageWeightage: 10,
      isComplete: () => mobileUserState.defaultBillingAddress && mobileUserState.defaultShippingAddress ? true : false
    },
  ]

  useEffect(() => {
    computerProfilesProgressPercentage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState])

  const computerProfilesProgressPercentage = () => {
    let total = 0
    profileItems.forEach(item => {
      if (item.isComplete()) {
        total += item.percentageWeightage
      }
    })
    setPercentage(total)
    if (total === 100) {
      setTitle("Your profile is complete, congrats!")
    }
  }

  return (
    <Grid container item xs={12} sm={9} md={4} style={{ backgroundColor: "#FFF", borderRadius: "1rem", alignSelf: "flex-start", marginLeft: "1rem", marginBottom: "1rem", position: "sticky", top: 60 }}>
      <Grid item xs={12} style={{ padding: "2rem 0rem" }}>
        <Typography variant="h3" align="center" style={{ fontWeight: 800 }}>{title}</Typography>
      </Grid>
      <Grid item container justify="center" xs={12}>
        <div style={{ width: 150, height: 150, }} className={classes.alignCicularProgessBarText}>
          <CircularProgressbar value={percentage}
            text={`${percentage}%`}
            strokeWidth={16}
            styles={buildStyles({
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'butt',
              textSize: '18px',
              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.5,
              pathColor: "#07AF03",
              textColor: '#07AF03',
            })}
          />
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "1rem" }}>
        {profileItems.map(item => (
          <Grid container item xs={12} justify="flex-start" style={{ padding: '1rem 2rem' }}>
            {item.isComplete() ?
              <>
                <DoneIcon style={{ margin: "0rem 1rem" }} color={"primary"} />
                <Typography variant="h4" style={{ fontWeight: 600, display: "inline" }}>{item.text} </Typography>
                <Typography variant="h4" color='textSecondary' style={{ fontWeight: 600, paddingLeft: "0.6rem" }}>{`${item.percentageWeightage}%`} </Typography>
              </>
              : <>
                <ClearIcon style={{ margin: "0rem 1rem" }} color={'disabled'} />
                <Typography variant="h4" color='textSecondary' style={{ fontWeight: 600, display: "inline" }}>{item.text} </Typography>
                <Typography style={{ fontSize: "1rem", fontWeight: 600, color: '#07AF03', paddingLeft: "0.6rem" }}>{`+${item.percentageWeightage}%`} </Typography>
              </>
            }
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}


interface IOTPVerification {
  open: boolean;
  handleClose: () => void;
  handleSave: (otp: number) => void;
}
const OTPVerification: React.FC<IOTPVerification> = (props) => {

  const [otp, setOTP] = useState(['', '', '', '', '', '']); // Initialize with an array of empty strings
  const inputRefs = [useRef<HTMLInputElement>(), useRef<HTMLInputElement>(), useRef<HTMLInputElement>(), useRef<HTMLInputElement>(), useRef<HTMLInputElement>(), useRef<HTMLInputElement>()];
  const [validNumberOtp, setValidNumberOtp] = useState<number>(0)
  useEffect(() => {
    convertOTPArrayToOtp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp])

  const convertOTPArrayToOtp = () => {
    const _otp = parseInt(otp.join(""))
    setValidNumberOtp(_otp)
  }
  const handleChange = (e: any, index: number) => {
    const value = e.target.value;

    // check if value is a number , extact latest digit and set to input field & move focus to new field
    if (value.length >= 1 && /^\d*$/.test(value)) {
      const latestValue = value.slice(-1)
      const newOTP = [...otp];

      newOTP[index] = latestValue;
      setOTP(newOTP);

      // Move focus to the next input field, if available
      const nextInputIndex = index + 1
      if (inputRefs[nextInputIndex]) {
        const nextInputField = inputRefs[nextInputIndex].current
        if (index < otp.length - 1 && nextInputField) {
          nextInputField.focus()
        }
      }
    }
  };



  return (
    <Dialog
      maxWidth={"sm"}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogContent>
        <Grid container xs={12} justify="center">
          <Typography variant="h2" align="center" style={{ padding: "1rem" }}>
            Verify Your Email
          </Typography>
          <Typography variant="h4" align="center" color="primary" style={{ padding: "1rem", fontWeight: 400 }}>
            OTP send to your entered Email Id, please enter OTP to very your email
          </Typography>
        </Grid>
        <Grid container xs={12} justify="center" style={{ padding: "1.5rem" }}>
          {otp.map((digit, index) => (
            <Grid item xs={2} key={index} style={{ padding: "0.3rem" }}>
              <TextField
                inputRef={inputRefs[index]}
                variant="outlined"
                fullWidth
                type="text"
                value={digit}
                onChange={(e) => handleChange(e, index)}
                inputProps={{
                  style: { textAlign: 'center', fontSize: "1rem", },
                }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions >
        <Grid container xs={12} justify="center" style={{ padding: "1rem" }}>
          <Fab variant="extended" size="small" onClick={() => props.handleSave(validNumberOtp)} >
            Verify
          </Fab>
        </Grid>
      </DialogActions>

    </Dialog>
  )
}


// MAIN COMPONENT
const PortalUserProfile: React.FC = () => {
  return (
    <Grid container xs={12} justify="center" style={{ backgroundColor: "#F5F6F8", margin: "0rem", padding: '0rem' }}>
      <Grid container xs={11}>
        <Grid container xs={12} justify={"center"}>
          <Grid container item xs={11} style={{ margin: "2rem 0rem" }}>
            <Typography variant="h1" style={{ fontWeight: 800 }}>Edit Profile</Typography>
          </Grid>
          <ProfileContainer />
          <ProgressBarContainer />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PortalUserProfile;