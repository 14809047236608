import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { HTTP_METHODS, RouteAccess, USER_TYPES } from "../src/utils/constants";
import Billing from "./components/views/Accounts/Billing";
import Payment from "./components/views/Accounts/Payment";
import Login from "./components/views/Auth/Login";
import ResetPassword from "./components/views/Auth/ResetPassword";
import Signup from "./components/views/Auth/Signup";
import VerifyOtp from "./components/views/Auth/VerifyOtp";
import AuthorDetail from "./components/views/Authors/AuthorDetail";
import Authors from "./components/views/Authors/Authors";
import PortalUserAuthorDescriptionPage from "./components/views/Authors/PortalUserAuthorDescriptionPage";
import BookDetail from "./components/views/Books/BookDetails/BookDetail";
import Books from "./components/views/Books/Books";
import OrderDetails from "./components/views/Books/OrderDetails/OrderDetails";
import Orders from "./components/views/Books/Orders";
import Bundles from "./components/views/Bundles/Bundles";
import NewBundle from "./components/views/Bundles/NewBundle";
import UpdateBundle from "./components/views/Bundles/UpdateBundle";
import AdminDashboard from "./components/views/Dashboard/AdminDashboard";
import Dashboard from "./components/views/Dashboard/Dashboard";
import DigitalBookDetail from "./components/views/Dashboard/DigitalBookDetail/DigitalBookDetail";
import DigitalBookOrderSummary from "./components/views/Dashboard/DigitalBookDetail/DigitalBookOrderSummary";
import DigitalPayment from "./components/views/Dashboard/DigitalPayment";
import FeaturedBooks from "./components/views/Dashboard/FeaturedBooks";
import MobileUserDashboard from "./components/views/Dashboard/MobileUserDashboard";
import NewArrivalBooks from "./components/views/Dashboard/NewArrivalBooks";
import RecommendedBooks from "./components/views/Dashboard/RecommendedBooks";
import TopReadBooks from "./components/views/Dashboard/TopReadBooks";
import DistributorDetail from "./components/views/Distributors/DistributorDetails/DistributorDetail";
import Distributors from "./components/views/Distributors/Distributors";
import NewDistributor from "./components/views/Distributors/NewDistributor";
import VerifiedContentCreators from "./components/views/MobileUsers/VerifiedContentCreators";
import BooksByPriceRange from "./components/views/Monitoring/BooksByPriceRange";
import DigitalOrders from "./components/views/Monitoring/DigitalOrders";
import IncompleteOrders from "./components/views/Monitoring/IncompleteOrders";
import LessQuantityBooks from "./components/views/Monitoring/LessQuantityBooks";
import NotifyMeForBookUsers from "./components/views/Monitoring/NotifyMeForBookUsers";
import MyRachnaye from "./components/views/MyRachnaye/MyRachnaye";
import NewChapter from "./components/views/MyRachnaye/NewChapter";
import NewPost from "./components/views/MyRachnaye/NewPost";
import NewSeries from "./components/views/MyRachnaye/NewSeries";
import PublishSeries from "./components/views/MyRachnaye/PublishSeries";
import ReadChapter from "./components/views/MyRachnaye/ReadChapter";
import ReadRachnaye from "./components/views/MyRachnaye/ReadRachnaye";
import ReadSeries from "./components/views/MyRachnaye/ReadSeries";
import SeriesDescription from "./components/views/MyRachnaye/SeriesDescription";
import SeriesOrderSummary from "./components/views/MyRachnaye/SeriesOrderSummary";
import CategoryLanguageSelection from "./components/views/NewFeatures/CategoryLanguageSelection";
import PortalDigitalReads from "./components/views/NewFeatures/PortalDigitalReads";
import PortalUserHome from "./components/views/NewFeatures/PortalUserHome";
import NotificationControls from "./components/views/NotificationControl/index";
import NewOffer from "./components/views/Offers/NewOffer";
import Offers from "./components/views/Offers/Offers";
import UpdateCoupon from "./components/views/Offers/UpdateOffer";
import OfflineSales from "./components/views/OfflineSales/index";
import PayoutDetails from "./components/views/Payouts/PayoutDetails";
import PlatformSettings from "./components/views/PlatformSettings/index";
import MobileUserCart from "./components/views/Portal/Cart/MobileUserCart";
import CategoryBooks from "./components/views/Portal/CategoryBooks";
import PortalOrderDetails from "./components/views/Portal/Orders/PortalOrderDetails";
import PortalSeriesOrderDetails from "./components/views/Portal/Orders/PortalSeriesOrderDetails";
import PortalUserOrders from "./components/views/Portal/Orders/PortalUserOrders";
import WritersEarnings from "./components/views/Portal/Payouts/WriterEarnings";
import WritersPayouts from "./components/views/Portal/Payouts/WriterPayouts";
import PortalUserProfile from "./components/views/Portal/Profile/PortalUserProfile";
import PortalUserPublisherDescriptionPage from "./components/views/Publishers/PortalUserPublisherDescriptionPage";
import PublisherDetail from "./components/views/Publishers/PublisherDetails/PublisherDetail";
import Publishers from "./components/views/Publishers/Publishers";
import FinancialReport from "./components/views/Reports/FinancialReport";
import MobileDevicesStats from "./components/views/Reports/MobileDevicesStats";
import LoginAndSecurity from "./components/views/Settings/LoginAndSecurity";
import Settings from "./components/views/Settings/Settings";
import UserAccounts from "./components/views/Settings/UserAccounts";
import { updateMobileUser } from "./reducers/mobileUser/reducer";
import { updateIamUser } from "./reducers/user/reducer";
import DefaultRoute from "./routes/DefaultRoute";
import NotFound from "./routes/NotFound";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { IAppState } from "./store";
import callApi from "./utils/apiUtil";
import { PATHNAMES } from "./utils/pathNames";
import { getUserType } from "./utils/utils";


const App: React.FC = () => {

  const [userType] = useState<string>(getUserType())
  const userState = useSelector((state: IAppState) => (userType === USER_TYPES.MOBILE ? state.mobileUser : state.user));
  const dispatch = useDispatch();

  useEffect(() => {
    if (userState.id) {
      return
    }
    const userId = localStorage.getItem("userId")

    if (!userId) {
      return
    }
    if (userType === USER_TYPES.MOBILE) {
      callApi(PATHNAMES.GET_MOBILE_USER(userId), HTTP_METHODS.GET).then((res) => {
        if (res && res.success && res.data) {
          dispatch(updateMobileUser(res.data));
        }
      })
      return
    }
    callApi(PATHNAMES.GET_IAM_USER(userId), HTTP_METHODS.GET).then((res) => {
      if (res && res.success && res.data) {
        dispatch(updateIamUser(res.data));
      }
    })
    return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.id]);

  const routes = [
    {
      path: '/',
      element: <DefaultRoute />
    },
    {
      path: '*',
      element: <NotFound />
    },
    {
      element: <PublicRoute />,
      children: [
        { path: '/login', element: <Login /> },
        { path: '/signup', element: <Signup /> },
        { path: '/verifyOtp', element: <VerifyOtp /> },
        { path: '/ResetPassword', element: <ResetPassword /> },
      ]
    },
    {
      element: <PrivateRoute access={RouteAccess.ADMIN} />,
      children: [
        { path: '/dashboard', element: <Dashboard /> },
        { path: "/books", element: <Books /> },
        { path: "/orders", element: <Orders /> },
        { path: "/billing", element: <Billing /> },
        { path: "/payment", element: <Payment /> }
      ]
    },
    {
      element: <PrivateRoute access={RouteAccess.SUPER_ADMIN} />,
      children: [
        { path: '/admin-dashboard', element: <AdminDashboard /> },
        { path: '/digital-orders', element: <DigitalOrders /> },
        { path: '/incomplete-orders', element: <IncompleteOrders /> },
        { path: '/rachnaye-inventory', element: <LessQuantityBooks /> },
        { path: '/offline-sales', element: <OfflineSales /> },
        { path: '/publishers', element: <Publishers /> },
        { path: '/authors', element: <Authors /> },
        { path: '/create-author', element: <AuthorDetail /> },
        { path: '/update-author', element: <AuthorDetail /> },
        { path: '/update-publisher', element: <PublisherDetail /> },
        { path: '/offers', element: <Offers /> },
        { path: '/coupons', element: <Offers /> },
        { path: '/global-offers', element: <Offers /> },
        { path: '/create-offer', element: <NewOffer /> },
        { path: '/create-coupon', element: <NewOffer /> },
        { path: '/create-global-offer', element: <NewOffer /> },
        { path: '/update-offer', element: <UpdateCoupon /> },
        { path: '/update-coupon', element: <UpdateCoupon /> },
        { path: '/update-global-offer', element: <UpdateCoupon /> },
        { path: '/bundles', element: <Bundles /> },
        { path: '/create-bundle', element: <NewBundle /> },
        { path: '/update-bundle', element: <UpdateBundle /> },
        { path: '/update-distributor', element: <NewDistributor /> },
        { path: '/verifiedContentCreators', element: <VerifiedContentCreators /> },
        { path: '/platformSettings', element: <PlatformSettings /> },
        { path: '/notify-me-users', element: <NotifyMeForBookUsers /> },
        { path: '/notification-controls', element: <NotificationControls /> },
        { path: '/reports/mobile-devices-stats', element: <MobileDevicesStats /> },
        { path: '/reports/annual-report', element: <FinancialReport /> },
        { path: '/rachnaye-books-by-price', element: <BooksByPriceRange /> }
      ]
    },
    {
      element: <PrivateRoute access={RouteAccess.ADMIN_AND_SUPER_ADMIN} />,
      children: [
        { path: '/view-distributor', element: <DistributorDetail /> },
        { path: '/settings', element: <Settings /> },
        { path: '/distributors', element: <Distributors /> },
        { path: '/create-distributor', element: <NewDistributor /> },
        { path: '/user-accounts', element: <UserAccounts /> },
        { path: '/login-and-security', element: <LoginAndSecurity /> },
        { path: '/view-book', element: <BookDetail /> },
        { path: '/view-order', element: <OrderDetails /> },
        { path: '/view-payout', element: <PayoutDetails /> },
      ]
    },
    {
      element: <PrivateRoute access={RouteAccess.MOBILE_USER} />,
      children: [
        { path: '/portal/explore-books', element: <MobileUserDashboard /> },
        { path: '/portal/recommended', element: <RecommendedBooks /> },
        { path: '/portal/newArrivals', element: <NewArrivalBooks /> },
        { path: '/portal/featured', element: <FeaturedBooks /> },
        { path: '/portal/top-reads', element: <TopReadBooks /> },
        { path: '/portal/category', element: <CategoryBooks /> },
        { path: '/portal/book', element: <DigitalBookDetail /> },
        { path: '/portal/orderSummary', element: <DigitalBookOrderSummary /> },
        { path: '/portal/orders', element: <PortalUserOrders /> },
        { path: '/portal/orderDetails/:subOrderId', element: <PortalOrderDetails /> },
        { path: '/portal/seriesOrderDetails/:seriesId', element: <PortalSeriesOrderDetails /> },
        { path: '/portal/user/profile', element: <PortalUserProfile /> },
        { path: '/portal/user/cart', element: <MobileUserCart /> },
        { path: '/portal/payment', element: <DigitalPayment /> },
        { path: '/portal/myRachnaye', element: <MyRachnaye /> },
        { path: '/portal/rachnaye/post/create', element: <NewPost /> },
        { path: '/portal/rachnaye/post/edit', element: <NewPost /> },
        { path: '/portal/rachnaye/series/create', element: <NewSeries /> },
        { path: '/portal/rachnaye/series/edit', element: <NewSeries /> },
        { path: '/portal/rachnaye/series/description', element: <SeriesDescription /> },
        { path: '/portal/rachnaye/chapter/read', element: <ReadChapter /> },
        { path: '/portal/rachnaye/series/read', element: <ReadSeries /> },
        { path: '/portal/rachnaye/series/publish', element: <PublishSeries /> },
        { path: '/portal/rachnaye/series/order', element: <SeriesOrderSummary /> },
        { path: '/portal/rachnaye/series/chapter/create', element: <NewChapter /> },
        { path: '/portal/rachnaye/series/chapter/edit', element: <NewChapter /> },
        { path: '/portal/rachnaye/post/read', element: <ReadRachnaye /> },
        { path: '/portal/earningAndPayouts', element: <WritersEarnings /> },
        { path: '/portal/payouts', element: <WritersPayouts /> },
        { path: '/portal/home', element: <PortalUserHome /> },
        { path: '/portal/categoryLanguageSelection', element: <CategoryLanguageSelection /> },
        { path: '/portal/author', element: <PortalUserAuthorDescriptionPage /> },
        { path: '/portal/publisher', element: <PortalUserPublisherDescriptionPage /> },
        { path: '/portal/digitalReads', element: <PortalDigitalReads /> },
      ]
    },
  ];

  const router = createBrowserRouter(routes)

  return (<RouterProvider router={router} />)
};

export default App;
