import { Button, CircularProgress, createStyles, Dialog, DialogTitle, FormControl, Grid, InputBase, InputLabel, makeStyles, MenuItem, Select, TextareaAutosize, Typography, withStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import callApi from "../../../../utils/apiUtil";
import { PATHNAMES } from "../../../../utils/pathNames";
import { CancellationReasons, HTTP_METHODS, ReasonType } from "../../../../utils/constants";
import CircularLoader from "../../../common/CircularLoader";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store";

const useStyles = makeStyles((theme) => createStyles({
    select: {
        marginTop: "0.5rem",
        padding: "0.5rem",
        border: "1px solid black",
        borderRadius: "4px",
        '&:hover': {
            border: '1px solid',
            backgroundColor: "#eaeaeb",
        },
    },
    DialogContainer: {
        width: "450px",
        minHeight: "450px",
        alignContent: "space-between",
        padding: "2rem",
        '& .MuiGrid-container': {
            justifyContent: 'space-between',
            marginTop: "1rem"
        },
    },

}))

interface ICancelDialogProps {
    showDialog: boolean;
    onClose: (show: boolean, succcess: boolean) => void;
    subOrderId: string
}

interface CancellationReason {
    reasonKey: string,
    reasonValue: string
}

const CancelOrReturnOrder: React.FC<ICancelDialogProps> = ({ showDialog, onClose, subOrderId }) => {
    const classes = useStyles();
    const snackbar = useSnackBar()
    const [cancellationReasons, setCancellationReasons] = useState<null | CancellationReason[]>(null)
    const [selectedCancellationReason, setSelectedCancellationReason] = React.useState('');
    const [additionalComment, setAdditionalComment] = useState<string | null>(null)
    const mobileUserState = useSelector((state: IAppState) => state.mobileUser);

    const [loading, setLoading] = useState(true)
    useEffect(() => {

        const fetchCancellationReasons = async () => {
            try {
                const res = await callApi(PATHNAMES.GET_PORTAL_USER_ORDERS_CANCELLATION_REASONS(ReasonType.CANCEL), HTTP_METHODS.GET);
                if (res && res.success && res.data) {
                    const modifiedData = res.data.map((item : CancellationReason )=> ({
                        ...item,
                        reasonKey: item.reasonKey.replace(/_/g, ' ')
                    }));
                    setCancellationReasons(modifiedData)
                    setSelectedCancellationReason(modifiedData[0].reasonValue);
                }
            } catch (error) {

            }
            finally {
                setLoading(false)
            }
        }
        fetchCancellationReasons()
    }, [])

    const handleDialogClose = () => {
        onClose(false,false);
    };

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedCancellationReason(event.target.value as string);
    };

    const handleCancelOrder = async () => {
        try {
            setLoading(true)
            if (selectedCancellationReason === CancellationReasons.REASON_NOT_LISTED && !additionalComment) {
                snackbar({
                    message: "Write your cancellation reason!",
                    variant: SnackBarVariant.ERROR
                })
                return
            }

            const body = additionalComment === null
                ? { data: { reason: selectedCancellationReason } }
                : { data: { reason: selectedCancellationReason, additionalComment: additionalComment } };

            const res = await callApi(PATHNAMES.GET_PORTAL_USER_ORDER_CANCEL(mobileUserState.id, subOrderId), HTTP_METHODS.POST,{
                body: JSON.stringify(body)
              });
            if (res && res.success && res.data) {
                setLoading(false)
                snackbar({
                    message: "Order Cancelled",
                    variant: SnackBarVariant.SUCCESS
                })
                onClose(false,true)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <Grid xs={12} style={{}}>
            <Dialog fullWidth style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "600px", minWidth: "500px" }} onClose={handleDialogClose} aria-labelledby="simple-dialog-title" open={showDialog}>
                {loading ? (
                    <Grid container xs={12} style={{ padding: "1rem" }} justifyContent="center" alignContent="center">
                        <CircularProgress />
                    </Grid>

                ) : cancellationReasons ? (
                    <Grid xs={12} className={classes.DialogContainer}>
                        <Typography variant="h2" style={{ fontWeight: "500" }}>Select Cancellation Reason</Typography>

                        <FormControl fullWidth>
                            <Select
                                placeholder="Reason"
                                disableUnderline
                                className={classes.select}
                                labelId="cancellationReason"
                                id="cancellationReason"
                                value={selectedCancellationReason}
                                onChange={handleChange}
                            >
                                {cancellationReasons.map((reason, index) => (
                                    <MenuItem key={index} value={reason.reasonValue}>
                                        {reason.reasonKey}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {selectedCancellationReason === CancellationReasons.REASON_NOT_LISTED &&
                            <TextareaAutosize onChange={(event) => { setAdditionalComment(event.target.value) }} style={{ marginTop: "1rem", width: "100%", minHeight: "250px" }} aria-label="cancellation reason" placeholder="Write your cancellation reason" />
                        }
                        <Grid container>
                            <Button type="submit" onClick={handleCancelOrder} fullWidth style={{ padding: "1rem", border: "2px solid black" }}>Cancel</Button>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid xs={12}>
                        <Typography>Some Error occurred</Typography>
                    </Grid>
                )}
            </Dialog>
        </Grid>

    );
};

export default CancelOrReturnOrder;