import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSnackBar } from "../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../SnackbarWrapper/SnackbarWrapper";
import PostAddIcon from '@material-ui/icons/PostAdd';
import LaunchIcon from '@material-ui/icons/Launch';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import ReplayIcon from '@material-ui/icons/Replay';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { IContextMenuPosition } from "./types";
interface IProp {
  position: IContextMenuPosition | null
  onClose: () => void;
  attributes: {
    link: string
  }
}

const useStyles = makeStyles(() => ({
  icons: {
    minWidth: "25px"
  },
}))
const CustomContextMenu: React.FC<IProp> = (props) => {
  const classes = useStyles()
  const snackbar = useSnackBar();
  const [contextMenu, setContextMenu] = useState<IContextMenuPosition | null>(null);

  useEffect(() => {
    setContextMenu(props.position)
  }, [props.position])

  const open = Boolean(contextMenu)

  const handleNewTab = () => {
    props.onClose()
    window.open(props.attributes.link, "_blank");
  }
  const handleNewWindow = () => {
    props.onClose()
    window.open(props.attributes.link, "_blank", "width=auto, height=auto");
  }

  const handleReloadWindow = () => {
    props.onClose()
    window.location.reload()
  }

  const handleCopyLink = () => {
    props.onClose()
    let originUrl = window.location.origin;
    let url = new URL(props.attributes.link, originUrl);
    navigator.clipboard.writeText(url.href)
    snackbar({
      message: "Link copied successfully",
      variant: SnackBarVariant.SUCCESS
    })
  }

  return (
    <div onContextMenu={(e) => {
      e.preventDefault()
    }}>
      <Menu
        open={open}
        onClose={props.onClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleNewTab}>
          <ListItemIcon className={classes.icons} ><PostAddIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Open in new tab</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleNewWindow}>
          <ListItemIcon className={classes.icons} ><LaunchIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Open in new window</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleCopyLink}>
          <ListItemIcon className={classes.icons} ><FileCopyTwoToneIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Copy link</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleReloadWindow}>
          <ListItemIcon className={classes.icons} ><ReplayIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Reload page</ListItemText>
        </MenuItem>
        <MenuItem onClick={props.onClose}>
          <ListItemIcon className={classes.icons} ><HighlightOffIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Close</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default CustomContextMenu;