import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import callApi from '../../../utils/apiUtil';
import { IFiltersAndSorting } from '../../../utils/common.interface';
import { HTTP_METHODS } from '../../../utils/constants';
import { PATHNAMES } from '../../../utils/pathNames';
import { DataTable } from '../../common/Datatable/Datatable';
import { Pagination } from '../../common/Datatable/types';

const OfflineOrders: React.FC = () => {
  const [orders, setOrders] = useState<any[]>([]);
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [hasOfflineOrder, setHasOfflineOrder] = useState<number>(0);

  // FETCH ORDER
  useEffect(() => {
    fetchOfflineOrders({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
  }, [])


  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    count: totalOrders,
    searchPlaceholder: "Search by order Id, type, book name, date [dd/mm/yy]",
  };

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
      }
    },
    {
      label: "Book",
      name: "book",
      options: {
        customBodyRender: (data: any) => data.title
      }
    },
    {
      label: "Order Id",
      name: "referenceNumber"
    },
    {
      label: "Order Type",
      name: "orderType",
      options: {
        customBodyRender: (data: string) => data.replace("_", " ").toUpperCase()
      }
    },
    {
      label: "Buy Price",
      name: "boughtPrice",
      options: {
        customBodyRender: (data: number) => "₹" + data?.toLocaleString('en-IN')
      }
    },
    {
      label: "Sold Qnt.",
      name: "quantity",
    },
    {
      label: "Publisher shr",
      name: "publisherShare",
      options: {
        customBodyRender: (data: number) => "₹" + data?.toLocaleString('en-IN')
      }
    },
    {
      label: "Sold Price",
      name: "soldPrice",
      options: {
        customBodyRender: (data: number) => "₹" + data?.toLocaleString('en-IN')
      }
    },
    {
      label: "Platform shr",
      name: "platformShare",
      options: {
        customBodyRender: (data: number) => "₹" + data?.toLocaleString('en-IN')
      }
    },
    {
      label: "Sold Date",
      name: "soldDate",
      options: {
        customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY, h:mm A")
      }
    },
    {
      label: "Entry Date",
      name: "createdAt",
      options: {
        customBodyRender: (data: string) => moment(data).format("DD/MM/YYYY, h:mm A")
      }
    }
  ]

  const fetchOfflineOrders = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, filters } = args
    callApi(PATHNAMES.GET_SUPERADMIN_OFFLINE_SUB_ORDERS(pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter: false, // don't apply MUI Table filters for offline order
          filters
        }
      }),
    }).then((ordersResponse) => {
      if (ordersResponse && ordersResponse.success && ordersResponse.data) {
        setOrders(ordersResponse.data.subOrders);
        setTotalOrders(ordersResponse.data.totalSubOrdersCount)
        setHasOfflineOrder(ordersResponse.data.offlineOrderPresent)
      }
    })
  }

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} style={{ width: '99%' }} elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant='h2' style={{ fontWeight: "bolder" }}>Orders History</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container xs={12}>
          {!hasOfflineOrder ?
            <Typography variant="body1">
              There are no offline orders yet
            </Typography> :
            <Grid container xs={12}>
              <DataTable
                title={`Orders (${totalOrders})`}
                rows={orders}
                columns={columns}
                options={options}
                hideSelectableRows={true}
                serverSide
                fetchData={fetchOfflineOrders}
              />
            </Grid>
          }
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default OfflineOrders;