import {
  Button, Grid, makeStyles, Paper, TextField, Typography
} from "@material-ui/core";
import React from "react";
import { NumberFormatValues,NumericFormat } from "react-number-format";
import { BookShareTypes } from "../../../utils/constants";
import { roundToTwo } from "../../../utils/utils";


interface IBook {
  id: string;
  publisherId: string;
  publisherName: string;
  title: string;
  type: string
  price?: number;
  sellingMRP: number;
  booksAvailable: number;
  publisherShare?: number
  platformShare?: number
}

interface ISelectedBookProps {
  books: IBook[]
  handleShareChange: (book: IBook, type: string, value: number | undefined) => void;
  handleRemoveAll: () => void
}


interface IBookCardProps {
  book: IBook
  key: string;
  saveChange: (book: IBook, type: string, value: number | undefined,) => void
}

const useStyles = makeStyles(() => ({
  inputBoxStyle: {
    width: '7rem',
    "& .MuiOutlinedInput-input": {
      padding: '0.5rem'
    }
  },
  mainContainer: { paddingRight: '1rem', margin: '2rem 0rem' },
  headRow: { borderBottom: '1px solid' },
  headertext: { margin: '1rem' },
  subHeader1text: { padding: "0.6rem", paddingLeft: '1rem' },
  subHeader2text: { padding: "0.6rem 0.6rem 0.6rem 0rem", },
  bookCardRow: { paddingTop: '0.6rem', },
  bookCardText: { display: "inline-block", margin: '0rem 1rem', fontWeight: 400 },
  bookCardInventoryText: { display: "inline-block", margin: '0rem 1rem', fontWeight: 400, paddingLeft: '1rem' },
  totalValueContainer: { margin: '1rem 0rem', padding: '0.3rem', border: '1px solid red' },
  totalValueText: { margin: "0rem 1.5rem", paddingLeft: '1rem' }
}))



const SelectedBooksDetail: React.FC<ISelectedBookProps> = (props) => {
  const classes = useStyles();
  const getTotalprice = () => {
    const priceArr = props.books.map(book => book.price ? book.price : 0)
    const totalPrice = priceArr.reduce((prev, next) => prev + next, 0)
    return roundToTwo(totalPrice)
  }

  return (
    <Grid item xs={12} className={classes.mainContainer}>
      <Paper elevation={2}>
        {/* HEADER CONTAINER  */}
        <Grid container xs={12} className={classes.headRow}>
          {/* TEXT  */}
          <Grid container xs={6}>
            <Typography variant="h3" className={classes.headertext}> Selected Books</Typography>
          </Grid>
          {/* REMOVE AL BUTTON  */}
          <Grid container justify="flex-end" xs={6}>
            <Button onClick={props.handleRemoveAll} >Remove ALL</Button>
          </Grid>

        </Grid>

        {/* INDEX HAEDING ROW */}
        < Grid container xs={12} style={{ backgroundColor: '#FCF5E8' }}  >
          < Grid item xs={2}>
            <Typography variant="h4" className={classes.subHeader1text}>Book title</Typography>
          </Grid>
          < Grid item xs={2} >
            <Typography variant="h4" className={classes.subHeader1text} >Type</Typography>
          </Grid>
          < Grid item xs={2} >
            <Typography variant="h4" className={classes.subHeader1text} >Inventory</Typography>
          </Grid>
          < Grid item xs={1} >
            <Typography variant="h4" className={classes.subHeader1text} >M.R.P</Typography>
          </Grid>
          < Grid item xs={2} >
            <Typography variant="h4" className={classes.subHeader2text}>{"Publisher share(₹)"}</Typography>
          </Grid>
          < Grid item xs={2} >
            <Typography variant="h4" className={classes.subHeader2text}>{"Platform share(₹)"}</Typography>
          </Grid>
          < Grid item xs={1} >
            <Typography variant="h4" className={classes.subHeader1text}>{"Price(₹)"}</Typography>
          </Grid>
        </Grid>

        {/* BOOKS MAPPING  */}
        {props.books.map((book) => <BookCard book={book} key={`${book.id}-${book.type}`} saveChange={props.handleShareChange} />)}
        <Grid container xs={12} justify='flex-end' className={classes.totalValueContainer}>
          <Grid item xs={1} className={classes.totalValueText}>
            <Typography variant="h3" >{`Total ${getTotalprice()}`}</Typography>
          </Grid>
        </Grid>

      </Paper>
    </Grid>
  )
}

const BookCard: React.FC<IBookCardProps> = (props) => {
  const classes = useStyles();

  return (
    < Grid container xs={12} key={props.key} >
      < Grid item xs={2} className={classes.bookCardRow}>
        <Typography variant="h4" className={classes.bookCardText}>{props.book.title.toUpperCase()}</Typography>
      </Grid>

      < Grid item xs={2} className={classes.bookCardRow} >
        <Typography variant="h4" className={classes.bookCardText}>{props.book.type}</Typography>
      </Grid>

      < Grid item xs={2} className={classes.bookCardRow} >
        <Typography variant="h4" className={classes.bookCardInventoryText}>{props.book.booksAvailable}</Typography>
      </Grid>

      < Grid item xs={1} className={classes.bookCardRow} >
        <Typography variant="h4" className={classes.bookCardInventoryText}>{props.book.sellingMRP}</Typography>
      </Grid>

      < Grid item xs={2} className={classes.bookCardRow}>
        <NumericFormat
          customInput={TextField}
          className={classes.inputBoxStyle}
          name={BookShareTypes.PUBLISHER_SHARE}
          label=""
          placeholder="Add share"
          allowNegative={false}
          fullWidth
          decimalScale={2}
          value={props.book.publisherShare}
          onValueChange={(eventValues: NumberFormatValues) => {
            props.saveChange(props.book, BookShareTypes.PUBLISHER_SHARE, eventValues.floatValue)
          }}
        />
      </Grid>

      < Grid item xs={2} className={classes.bookCardRow} >
        <NumericFormat
          customInput={TextField}
          className={classes.inputBoxStyle}
          name={BookShareTypes.PLATFORM_SHARE}
          label=""
          placeholder="Add share"
          allowNegative={false}
          fullWidth
          decimalScale={2}
          value={props.book.platformShare}
          onValueChange={(eventValues: NumberFormatValues) => {
            props.saveChange(props.book, BookShareTypes.PLATFORM_SHARE, eventValues.floatValue,)
          }}
        />
      </Grid>

      < Grid item xs={1} className={classes.bookCardRow}>
        <Typography variant="h4" className={classes.bookCardInventoryText}>{props.book.price || "-"}</Typography>
      </Grid>

    </Grid>)
}

export default SelectedBooksDetail;