import { createMuiTheme } from "@material-ui/core";
import typography from "./typography";
import palette from "./palette";
import overrides from "./overrides";


const theme = createMuiTheme({
  overrides,
  palette,
  typography,
  spacing: 10,
  props: {
    MuiPaper: {},
    MuiTextField: {
      variant: "outlined",
      color: "primary",

    },
    MuiInputBase: {
      color: "secondary",
      style: {
        height: "auto",
        alignItems: "center"
      }
    },
    MuiCheckbox: {
      color: "secondary"
    }
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
