import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { HTTP_METHODS } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";


import InfiniteScroll from "./InfiniteScroll";
import UsersDraftRachnaye from "./UsersDraftRachnaye";
import UsersDraftSeries from "./UsersDraftSeries";
import UsersPublishedRachnaye from "./UsersPublishedRachnaye";
import UsersPublishedSeries from "./UsersPublishedSeries";

interface IApiCall {
  pageNumber: number;
  pageSize: number;
  signal: AbortSignal;
}

const MyRachnaye: React.FC = () => {

  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const fetchDraftRachnaye = async ({ pageNumber, pageSize, signal }: IApiCall) => {
    const res = await callApi(PATHNAMES.GET_USER_POSTS(mobileUserState.id, true, pageNumber, pageSize), HTTP_METHODS.GET, { signal })
    let response = []

    if (res && res.success && res.data) {
      response = res.data
    }
    return response
  }

  const fetchPublishedRachnaye = async ({ pageNumber, pageSize, signal }: IApiCall) => {
    const res = await callApi(PATHNAMES.GET_USER_POSTS(mobileUserState.id, false, pageNumber, pageSize), HTTP_METHODS.GET, { signal })
    let response = []

    if (res && res.success && res.data) {
      response = res.data
    }
    return response

  }

  const fetchDraftSeries = async ({ pageNumber, pageSize, signal }: IApiCall) => {
    const res = await callApi(PATHNAMES.GET_USER_SERIES(mobileUserState.id, true, pageNumber, pageSize), HTTP_METHODS.GET, { signal })
    let response = []

    if (res && res.success && res.data) {
      response = res.data
    }
    return response
  }

  const fetchPublishedSeries = async ({ pageNumber, pageSize, signal }: IApiCall) => {
    const res = await callApi(PATHNAMES.GET_USER_SERIES(mobileUserState.id, false, pageNumber, pageSize), HTTP_METHODS.GET, { signal })
    let response = []
    if (res && res.success && res.data) {
      response = res.data
    }
    return response
  }

  return (
    <Grid container xl={6} lg={8} md={12} sm={12} xs={12} style={{ padding: "2rem 1rem" }}>
      <Grid container xs={12} style={{ width: "100%", borderBottom: "1px solid grey", }}>
        <Grid container xs={11}>
          <Box style={{ width: "100%" }}>
            <Tabs textColor="primary" value={value} onChange={handleChange} variant="scrollable">
              <Tab label="Draft Rachnaye" style={{ fontSize: "16px" }} />
              <Tab label="Published Rachnaye" style={{ fontSize: "16px" }} />
              <Tab label="Draft Series" style={{ fontSize: "16px" }} />
              <Tab label="Published Series" style={{ fontSize: "16px" }} />
            </Tabs>
          </Box>
        </Grid>
      </Grid>
      <Grid container xs={12} style={{ width: "100%" }}>
        <div hidden={value !== 0} style={{ width: "100%" }}>
          <InfiniteScroll
            pageNumber={0}
            pageSize={20}
            apiCall={fetchDraftRachnaye}
            msgWhenDataIsEmpty="Your Draft Rachnaye Will Appear Here"
            noMoreDataMessage="No more data to load"

          >
            <UsersDraftRachnaye />
          </InfiniteScroll>
        </div>
        <div hidden={value !== 1} style={{ width: "100%" }}>
          <InfiniteScroll
            pageNumber={0}
            pageSize={20}
            apiCall={fetchPublishedRachnaye}
            msgWhenDataIsEmpty="Your Published Rachnaye Will Appear Here"
            noMoreDataMessage="No more data to load"

          >
            <UsersPublishedRachnaye />
          </InfiniteScroll>
        </div>
        <div hidden={value !== 2} style={{ width: "100%" }}>
          <InfiniteScroll
            pageNumber={0}
            pageSize={10}
            pagination
            apiCall={fetchDraftSeries}
            msgWhenDataIsEmpty="Your Draft Series Will Appear Here"
            noMoreDataMessage="No more data to load"

          >
            <UsersDraftSeries />
          </InfiniteScroll>
        </div>
        <div hidden={value !== 3} style={{ width: "100%" }}>
          <InfiniteScroll
            pageNumber={0}
            pageSize={10}
            pagination
            apiCall={fetchPublishedSeries}
            msgWhenDataIsEmpty="Your Published Series Will Appear Here"
            noMoreDataMessage="No more data to load"

          >
            <UsersPublishedSeries />
          </InfiniteScroll>
        </div>
      </Grid>
    </Grid>
  );
};

export default MyRachnaye;
