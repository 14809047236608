export const PATHNAMES = {
  MOBILE_USER_SEND_OTP: {
    url: "/user/portal/sendOtp",
    operationId: "sendOtpForMobileUserLogin"
  },
  MOBILE_USER_VERIFY_OTP: {
    url: "/user/portal/verifyOtp",
    operationId: "verifyOtpForMobileUserLogin"
  },
  MOBILE_USER_LOGOUT: {
    url: "/user/portal/logout",
    operationId: "mobileUserLogout"
  },
  GET_PORTAL_USER_ORDERS: (userId: string, orderFilter: string, orderStatus: string, searchTitle: string, pageNumber: number, pageSize: number) => ({
    url: `/portal/user/${userId}/subOrders?search=${orderFilter}&orderStatus=${orderStatus}&searchTitle=${searchTitle}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getSubOrdersForPortalUser"
  }),
  GET_USER_SUB_ORDER_BY_ID: (userId: string, subOrderId: string) => ({
    url: `/user/${userId}/subOrder/${subOrderId}`,
    operationId: "getSubOrderDetail"
  }),
  GET_SERIES_SUB_ORDER_BY_ID: (orderId: string) => ({
    url: `/payg/order/${orderId}/details`,
    operationId: "getSeriesSubOrderDetail"
  }),
  GET_PORTAL_USER_ORDERS_FILTERS: (userId: string, type: string) => ({
    url: `/user/${userId}/subOrders/filters?type=${type}`,
    operationId: "getSubOrdersFiltersForUser"
  }),
  GET_PORTAL_USER_ORDERS_CANCELLATION_REASONS: (type: string) => ({
    url: `/portal/subOrder/cancellationOrReturnReasons?type=${type}`,
    operationId: "getSubOrderCancellationAndReturnReasons"
  }),
  GET_PORTAL_USER_ORDER_CANCEL: (userId: string, subOrderId: string) => ({
    url: `/user/${userId}/subOrder/${subOrderId}/cancel`,
    operationId: "updateSubOrderAsCancel"
  }),

  IAM_USER_LOGIN: {
    url: "/iamUser/login",
    operationId: "iamUserLogin"
  },
  IAM_USER_LOGOUT: {
    url: "/iamUser/logout",
    operationId: "iamUserLogout"
  },
  IAM_USER_SIGNUP: {
    url: "/iamUser/register",
    operationId: "publisherRegistration"
  },
  IAM_USER_RESEND_EMAILOTP: {
    url: "/iamUser/resendOtp",
    operationId: "resendOtpForIamUser"
  },
  IAM_USER_VERIFY_EMAILOTP: {
    url: "/iamUser/verifyOtp",
    operationId: "verifyOtpForIamUser"
  },
  IAM_USER_CHANGE_PASSWORD: {
    url: "/iamUser/changePassword",
    operationId: "changePasswordOfIamUser"
  },
  SEND_RESET_PASSWORD_OTP: {
    url: "/iamUser/sendResetPasswordOtp",
    operationId: "sendResetPasswordOtpToIamUser"
  },
  RESET_PASSWORD: {
    url: "/iamUser/resetPassword",
    operationId: "resetPasswordForIamUser"
  },
  UPDATE_SECONDARYUSER_BY_SUPERADMIN: (iamUserId: string) => ({
    url: `/iamUser/${iamUserId}`,
    operationId: "updateIamUserBySuperAdmin"
  }),
  DELETE_SECONDARYUSER_BY_SUPERADMIN: (iamUserId: string) => ({
    url: `/iamUser/${iamUserId}/delete`,
    operationId: "deleteIamUser"
  }),
  GET_BOOK_BY_ID: (iamUserId: string, bookId: string) => ({
    url: `/iamUser/${iamUserId}/book/${bookId}`,
    operationId: "getPublisherBook"
  }),
  CONTACT_US_QUERY: {
    url: "/utility/userQuery",
    operationId: "createUserQueryMail"
  },
  SEND_USER_INVITE: {
    url: "/utility/userInvite",
    operationId: "createUserInvite"
  },
  PUBLISHER_STATS: (publisherId: string, startDate: string, endDate: string) => ({
    url: `/publisher/${publisherId}/stats?startDate=${startDate}&endDate=${endDate}`,
    operationId: "getPublisherStats"
  }),
  PUBLISHER_TOP_PERFORMING: (publisherId: string, count: number, diff: number) => ({
    url: `/publisher/${publisherId}/topPerforming/books?diff=${diff}&count=${count}`,
    operationId: "getPublisherTopPerformingBooksGraph"
  }),
  PUBLISHER_RENTAL_V_PURCHASE: (publisherId: string, monthCount: number, timezone: string) => ({
    url: `/publisher/${publisherId}/rentalVsPurchases?monthCount=${monthCount}&timezone=${timezone}`,
    operationId: "getPublisherRentalVsPurchaseGraph"
  }),
  PUBLISHER_REVENUE_PIECHART: (publisherId: string, monthCount: number) => ({
    url: `/publisher/${publisherId}/revenuePieChart?diff=${monthCount}`,
    operationId: "getPublisherRevenuePieChart"
  }),
  PUBLISHER_INCOME_VS_EXPENSE_PIECHART: (publisherId: string, monthCount: number) => ({
    url: `/publisher/${publisherId}/incomeExpenseBarchart?monthCount=${monthCount}`,
    operationId: "getPublisherIncomeExpenseBarchart"
  }),
  PUBLISHER_READER_DEMOGRAPHIC: (publisherId: string, diff: number) => ({
    url: `/publisher/${publisherId}/readerDemographics?diff=${diff}`,
    operationId: "getPublisherReaderDemographics"
  }),
  ADMIN_READER_DEMOGRAPHIC: (diff: number) => ({
    url: `/admin/readerDemographics?diff=${diff}`,
    operationId: "getAdminReaderDemographics"
  }),
  GET_PAYOUTS: (publisherId: string, pageNumber: number, pageSize: number) => ({
    url: `/publisher/${publisherId}/payouts?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getPayouts"
  }),
  BOOK_UPDATE: (bookId: string) => ({
    url: `/book/${bookId}`,
    operationId: "updateBookById"
  }),
  UPDATE_BOOK_AUTHORS: (bookId: string) => ({
    url: `/book/${bookId}/author`,
    operationId: "updateBookAuthor"
  }),
  UPDATE_BOOK_FRONT_COVER: (bookId: string) => ({
    url: `/book/${bookId}/frontcover`,
    operationId: "updateBookFrontCover"
  }),
  BOOK_STATUS_UPDATE: (bookId: string) => ({
    url: `/book/${bookId}/activateDeactivate`,
    operationId: "updateBookStatus"
  }),
  PUBLISHER_BOOKS_LIST: (publisherId: string, pageNumber: number, pageSize: number) => ({
    url: `/publisher/${publisherId}/booksForIamUser?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getPublisherBooksForIamUser"
  }),

  PUBLISHER_USERS: (publisherId: string, pageNumber: number, pageSize: number) => ({
    url: `/publisher/${publisherId}/allIamUsers?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getPublisherUsersForSuperAdmin"
  }),

  PUBLISHER_BOOKS_FILTERS: (publisherId: string) => ({
    url: `/publisher/${publisherId}/bookFilters`,
    operationId: "getPublisherBookFiltersForIamUser"
  }),
  PUBLISHER_SUBORDER_FILTERS: (publisherId: string) => ({
    url: `/publisher/${publisherId}/subOrderFilters`,
    operationId: "getPublisherSubOrderFiltersForIamUser"
  }),
  PUBLISHER_PUBLISHED_BOOKS_LIST: (publisherId: string) => ({
    url: `/publisher/${publisherId}/publishedBooksForIamUser`,
    operationId: "getPublisherPublishedBookForIamUser"
  }),
  ALL_ACTIVE_PUBLISHERS: () => ({
    url: `/admin/activePublishers`,
    operationId: "getAllActivePublishers"
  }),
  GET_PUBLISHER_CATEGORY_BOOKS: () => ({
    url: `/admin/filteredBooks`,
    operationId: "getBooksByCategoryAndPublisher"
  }),
  DOWNLOAD_ALL_BOOKS: {
    url: `/admin/books/download`,
    operationId: "downloadAllBooksExcel",
  },
  UPDATE_ALL_BOOKS: {
    url: `/admin/books/upload`,
    operationId: "uploadAllBooksExcel",
  },
  GET_ALL_PUBLISHER_PAPER_BOOKS: (booksFor: string) => ({
    url: `/publisher/paperBooks?booksFor=${booksFor}`,
    operationId: "getPublishersPaperbooks"
  }),
  // DIGITAL_BOOKS_UPLOAD: {
  //   url: "/book/digital",
  //   operationId: "uploadDigitalBook"
  // },
  // NON_DIGITAL_BOOK_UPLOAD: {
  //   url: "/book/nonDigital",
  //   operationId: "uploadNonDigitalBook"
  // },
  UPLOAD_BOOKS_VIA_CSV: {
    url: "/book/csvUpload",
    operationId: "uploadCsvBook"
  },
  UPDATE_BOOKS_VIA_CSV: {
    url: "/book/csvUpdate",
    operationId: "updateCsvBook"
  },
  GET_PUBLISHER_SUB_ORDERS: (publisherId: string, pageNumber: number, pageSize: number) => ({
    url: `/publisher/${publisherId}/subOrders?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getPublisherSubOrders"
  }),
  GET_PUBLISHER_SUB_ORDER_BY_ID: (publisherId: string, subOrderId: string) => ({
    url: `/publisher/${publisherId}/subOrder/${subOrderId}`,
    operationId: "getPublisherSubOrder"
  }),
  GET_PRESIGNED_URL: {
    url: "/utility/presignedUrl",
    operationId: "createPreSignedUrl"
  },
  DELETE_FILE_FROM_S3: (filekey: string) => ({
    url: `/utility/file/${filekey}`,
    operationId: "deleteFileFromS3"
  }),
  UPLOAD_PREVIEWS: (bookId: string) => ({
    url: `/book/${bookId}/preview`,
    operationId: "updateBookPreview"
  }),
  REARRANGE_PREVIEWS: (bookId: string) => ({
    url: `/book/${bookId}/preview/reArrange`,
    operationId: "reArrangeBookPreview"
  }),
  GET_SIGNED_URL: {
    url: "/utility/signedUrl",
    operationId: "getPreSignedUrl"
  },
  GET_ALL_PUBLISHERS_FOR_ADMIN: (adminId: string, pageNumber: number, pageSize: number) => ({
    url: `/admin/${adminId}/publishers?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getPublishersForSuperAdmin"
  }),
  GET_ALL_PUBLISHERS_LIST: () => ({
    url: `/publisher/allPublishers`,
    operationId: "getPublishersForSuperAdmin"
  }),
  ADMIN_STATS: (adminId: string, startDate: string, endDate: string) => ({
    url: `/admin/${adminId}/stats?startDate=${startDate}&endDate=${endDate}`,
    operationId: "getSuperAdminStats"
  }),
  GET_ALL_AUTHORS: {
    url: "/author/all/expanded",
    operationId: "getAuthors"
  },
  GET_AUTHORS: (pageNumber: number, pageSize: number) => ({
    url: `/author/allAuthors?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getAuthors"
  }),
  CREATE_AUTHOR: {
    url: "/author",
    operationId: "createAuthor"
  },
  UPDATE_AUTHOR: (authorId: string) => ({
    url: `/author/${authorId}`,
    operationId: "updateAuthor"
  }),
  GET_AUTHOR_BY_ID: (authorId: string) => ({
    url: `/author/${authorId}`,
    operationId: "getAuthor"
  }),
  CREATE_SECONDARY_IAM_USER: {
    url: "/iamUser/create",
    operationId: "createSecondaryIamUser"
  },
  GET_SECONDARY_USERS: (spaceId: string) => ({
    url: `/space/${spaceId}/secondaryIamUsers`,
    operationId: "getSecondaryUsers"
  }),
  GET_ALL_ROLES: {
    url: "/rolesMgmt/roles",
    operationId: "getRoles"
  },
  GET_PUBLISHER_BY_ID_FOR_USER: (publisherId: string) => ({
    url: `/publisher/${publisherId}/portal`,
    operationId: "getPublisherForUser"
  }),
  GET_PUBLISHER_BY_ID: (adminId: string, publisherId: string) => ({
    url: `/admin/${adminId}/publisher/${publisherId}`,
    operationId: "getPublisherForSuperAdmin"
  }),
  TERMINATE_PUBLISHER_BY_ID: (adminId: string, publisherId: string) => ({
    url: `/admin/${adminId}/publisher/${publisherId}/terminate`,
    operationId: "terminatePublisher"
  }),
  APPROVE_PUBLISHER_BY_ID: (adminId: string, publisherId: string) => ({
    url: `/admin/${adminId}/publisher/${publisherId}/approval`,
    operationId: "updatePublisherApproval"
  }),
  UPDATE_PUBLISHER_SHARE: (adminId: string, publisherId: string) => ({
    url: `/admin/${adminId}/publisher/${publisherId}/revenueShare`,
    operationId: "updatePublisherRevenueShare"
  }),
  GET_LEDGER_ACCOUNT: (spaceId: string) => ({
    url: `/space/${spaceId}/ledgerAccount`,
    operationId: "getLedgerAccount"
  }),
  REQUEST_WITHDRAWAL: {
    url: "/payout",
    operationId: "createPayout"
  },
  GET_SPACE_INVOICES: (spaceId: string, pageNumber: number, pageSize: number) => ({
    url: `/space/${spaceId}/invoices?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getInvoices"
  }),
  GET_SPACE_INVOICE_BY_ID: (spaceId: string, invoiceId: string) => ({
    url: `/space/${spaceId}/invoice/${invoiceId}`,
    operationId: "getInvoice"
  }),
  GET_PAYMENT_ACCOUNT: (spaceId: string) => ({
    url: `/space/${spaceId}/paymentAccount`,
    operationId: "getPublisherPaymentAccount"
  }),
  UPDATE_BANK_DETAILS: (spaceId: string) => ({
    url: `/space/${spaceId}/paymentAccount/bank`,
    operationId: "updatePublisherBankDetail"
  }),
  UPDATE_UPI_DETAILS: (spaceId: string) => ({
    url: `/space/${spaceId}/paymentAccount/upi`,
    operationId: "updatePublisherUpiDetail"
  }),
  UPDATE_PRIMARY_PAYOUT_METHOD: (spaceId: string) => ({
    url: `/space/${spaceId}/paymentAccount/primaryPayoutMethod`,
    operationId: "updatePublisherPrimaryPayoutMethod"
  }),
  GET_PAYOUT_BY_ID: (spaceId: string, payoutId: string) => ({
    url: `/space/${spaceId}/payout/${payoutId}`,
    operationId: "getPayout"
  }),
  UPDATE_FCM_TOKEN: {
    url: `/iamUsers/updateFcmToken`,
    operationId: "updateIamUserFcmToken"
  },
  GET_NOTIFICATIONS: (iamUserId: string) => ({
    url: `/user/${iamUserId}/notifications`,
    operationId: "getUserNotifications"
  }),
  MARK_NOTIFICATION_AS_READ: (notificationId: string) => ({
    url: `/notification/${notificationId}`,
    operationId: "markNotificationAsRead"
  }),
  MARK_NOTIFICATIONS_AS_READ: {
    url: `/notification/markRead/all`,
    operationId: "markNotificationsAsRead"
  },
  RAISE_SHIPPING_REQUEST: (publisherId: string, orderId: string) => ({
    url: `/publisher/${publisherId}/subOrder/${orderId}/requestShipping`,
    operationId: "raiseSubOrderShipping"
  }),
  MARK_ORDER_AS_SHIPPED: (publisherId: string, orderId: string) => ({
    url: `/publisher/${publisherId}/subOrder/${orderId}/shipped`,
    operationId: "updateSubOrderAsShipped"
  }),
  MARK_ORDER_AS_SHIPPED_BY_ADMIN: (orderId: string) => ({
    url: `/admin/subOrder/${orderId}/shipped`,
    operationId: "updateSubOrderAsShipped"
  }),
  MARK_ORDER_AS_DELIVERED: (publisherId: string, orderId: string) => ({
    url: `/publisher/${publisherId}/subOrder/${orderId}/delivered`,
    operationId: "updateSubOrderAsDelivered"
  }),
  GET_ORDER_CANCELLATION_REASONS: () => ({
    url: `/portal/subOrder/cancellationOrReturnReasons?type=cancel`,
    operationId: "getCancellationReasonsList"
  }),
  FEATURE_BOOK: (bookId: string) => ({
    url: `/book/${bookId}/featured`,
    operationId: "updateBookFeatured"
  }),
  FEATURE_PUBLISHER: {
    url: `/publisher/featured`,
    operationId: "createFeaturedPublisher"
  },
  FEATURE_AUTHOR: (authorId: string) => ({
    url: `/author/${authorId}/featured`,
    operationId: "createFeaturedAuthor"
  }),

  UPDATE_IAM_USER_SELF: (iamUserId: string) => ({
    url: `/iamUser/${iamUserId}/profile`,
    operationId: "updateIamUserProfileBySelf"
  }),
  UPDATE_PUBLISHER_BASIC_INFO: (publisherId: string) => ({
    url: `/publisher/${publisherId}`,
    operationId: "updatePublisher"
  }),
  GET_IAM_USER: (iamUserId: string) => ({
    url: `/iamUser/${iamUserId}`,
    operationId: "getIamUser"
  }),
  UPDATE_MANUAL_PAYOUT: (adminId: string, payoutId: string) => ({
    url: `/admin/${adminId}/payout/${payoutId}`,
    operationId: "updateSuperAdminManualPayout"
  }),
  GET_ORG_OFFERS: (type: string, pageNumber: number, pageSize: number) => ({
    url: `/admin/offers?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getOrgOffers"
  }),
  GET_ORG_BUNDLE_OFFERS: (pageNumber: number, pageSize: number) => ({
    url: `/admin/bundles?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getOrgBundleOffer"
  }),
  GET_OFFER: (offerId: string) => ({
    url: `/offer/${offerId}`,
    operationId: "getOffer"
  }),
  GET_BUNDLE_OFFER: (bundleId: string) => ({
    url: `/bundleOffer/${bundleId}`,
    operationId: "getBundleOffer"
  }),
  CHANGE_OFFER_STATUS: (offerId: string) => ({
    url: `/offer/${offerId}/changeStatus`,
    operationId: "changeOfferStatus"
  }),
  CHANGE_BUNDLE_OFFER_STATUS: (bundleId: string) => ({
    url: `/bundleOffer/${bundleId}/changeStatus`,
    operationId: "changeBundleOfferStatus"
  }),
  CREATE_OFFER: {
    url: `/offer`,
    operationId: "createOffer"
  },
  CREATE_BUNDLE: {
    url: `/bundleOffer`,
    operationId: "createOffer"
  },
  UPDATE_BUNDLE_OFFER: (bundleId: string) => ({
    url: `/bundleOffer/${bundleId}`,
    operationId: "updateBundleOffer"
  }),
  UPDATE_OFFER: (offerId: string) => ({
    url: `/offer/${offerId}`,
    operationId: "updateOffer"
  }),
  CREATE_DISTRIBUTOR: {
    url: `/distributor`,
    operationId: "createDistributor"
  },
  ADD_DISTRIBUTOR_TO_PUBLISHER: (distributorId: string) => ({
    url: `/distributor/${distributorId}`,
    operationId: "addDistributorToPublisher"
  }),
  UPDATE_DISTRIBUTOR: (distributorId: string) => ({
    url: `/distributor/${distributorId}`,
    operationId: "updateDistributor"
  }),
  GET_ALL_DISTRIBUTORS_WITH_PAGINATION: (pageNumber: number, pageSize: number) => ({
    url: `/distributor/allDistributors?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getDistributors"
  }),
  GET_ALL_DISTRIBUTORS_LIST: () => ({
    url: `/distributor/allDistributors/list`,
    operationId: "getDistributorsList"
  }),
  GET_DISTRIBUTOR_BY_ID: (distributorId: string) => ({
    url: `/distributor/${distributorId}`,
    operationId: "getDistributor"
  }),
  GET_DISTRIBUTORS_FOR_PUBLISHER_WITH_PAGINATION: (publisherId: string, pageNumber: number, pageSize: number) => ({
    url: `/publisher/${publisherId}/distributors?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getPublisherDistributors"
  }),
  GET_DISTRIBUTORS_FOR_PUBLISHER_LIST: (publisherId: string) => ({
    url: `/publisher/${publisherId}/distributors`,
    operationId: "getPublisherDistributorsList"
  }),
  GET_DISTRIBUTORS_FOR_BOOK: (publisherId: string, bookId: string, inventoryType: string) => ({
    url: `/publisher/${publisherId}/book/${bookId}/distributors?type=${inventoryType}`,
    operationId: "getBookDistributors"
  }),
  UPDATE_BOOK_INVENTORY: (bookId: string, inventoryType: string) => ({
    url: `/book/${bookId}/inventory?inventoryType=${inventoryType}`,
    operationId: "updateBookInventory"
  }),
  UPLOAD_EPUB_FOR_NONDIGITAL: (bookId: string) => ({
    url: `/book/${bookId}/epub`,
    operationId: "updateBookEpub"
  }),
  GET_BOOK_PREVIEWS: (bookId: string) => ({
    url: `/book/${bookId}/previews`,
    operationId: "getBookPreview"
  }),
  DELETE_PREVIEWS: (bookId: string) => ({
    url: `/book/${bookId}/preview`,
    operationId: "deleteBookPreview"
  }),
  GET_DISTRIBUTORS_BOOKS: (distributorId: string, publisherId: string) => ({
    url: `/distributor/${distributorId}/publisher/${publisherId}/books`,
    operationId: "getBooksOfDistributorsPublisher"
  }),
  GET_DISTRIBUTOR_ORDERS: (distributorId: string, publisherId: string, pageNumber: number, pageSize: number) => ({
    url: `/distributor/${distributorId}/publisher/${publisherId}/orders?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getOrdersOfDistributorsPublisher"
  }),
  UPDATE_BOOK_INVENTORY_BY_DISTRIBUTOR: (distributorId: string) => ({
    url: `/distributor/${distributorId}/inventory`,
    operationId: "updateDistributorInventory"
  }),
  UPDATE_BOOK_AUTHORS_BIO: (bookId: string) => ({
    url: `/book/${bookId}/authorBio`,
    operationId: "updateBookAuthorBio"
  }),
  UPDATE_BOOK_AUTHORS_IMAGE: (bookId: string) => ({
    url: `/book/${bookId}/authorImage`,
    operationId: "updateBookAuthorImage"
  }),
  LIST_BOOKS_BY_CATERGORIES_PORTAL: {
    url: `/book/portal/categorized`,
    operationId: "listBooksByCategoriesForPortal"
  },
  LIST_BOOKS_BY_CATERGORY_PORTAL: (name: string) => ({
    url: `/book/portal/categorized?category=${name}`,
    operationId: "listBooksByCategoriesForPortal"
  }),
  GET_MOBILE_USER: (userId: string) => ({
    url: `/user/portal/${userId}`,
    operationId: "getMobileUser"
  }),
  UPDATE_MOBILE_USER: (userId: string) => ({
    url: `/user/portal/${userId}`,
    operationId: "updateMobileUser"
  }),
  ADD_MOBILE_USER_EMAIL: (userId: string) => ({
    url: `/user/${userId}/addEmail`,
    operationId: "addUserEmail"
  }),
  VERIFY_MOBILE_USER_EMAIL: () => ({
    url: `/user/verifyEmailOtp`,
    operationId: "verifyUserEmail"
  }),
  GET_MOBILE_USER_CART: (userId: string) => ({
    url: `/cart/user/${userId}/cartItems`,
    operationId: "getMobileUserCart"
  }),
  GET_MOBILE_USER_CART_QUANTITY: (userId: string) => ({
    url: `/cart/user/${userId}/cartItemQuantity`,
    operationId: "getMobileUserCartQuantity"
  }),
  GET_MOBILE_USER_SAVE_FOR_LATER: (userId: string) => ({
    url: `/cart/user/${userId}/saveForLaterItems`,
    operationId: "getMobileUserSaveForLater"
  }),
  DELETE_CART_ITEM: (itemId: string) => ({
    url: `/cart/${itemId}`,
    operationId: "deleteCartItem"
  }),
  UPDATE_CART_ITEM_QUANTITY: (itemId: string, type: string) => ({
    url: `/cart/${itemId}/quantity?type=${type}`,
    operationId: "updateICarttemQunatity"
  }),
  MOVE_CART_ITEM_TO_CART_SAVE_FOR_LATER: (itemId: string) => ({
    url: `/cart/${itemId}/cartItemType`,
    operationId: "deleteCartItem"
  }),
  ADD_ITEM_TO_CART: () => ({
    url: `/cart`,
    operationId: "addItemToCart"
  }),
  GET_RESOURCE_BY_ID: (id: string) => ({
    url: `/resource/${id}`,
    operationId: "getResourcesById"
  }),
  UPDATE_RESOURCE_BY_ID: (id: string) => ({
    url: `/resource/${id}`,
    operationId: "updateResourcesById"
  }),
  SEND_TEST_NOTIFICATION: () => ({
    url: `/notification/test`,
    operationId: "updateResourcesById"
  }),
  SEND_GROUP_NOTIFICATION: () => ({
    url: `/notification/group`,
    operationId: "sendGroupNotification"
  }),
  GET_FEATURED_BOOKS_PORTAL: (pageNumber: number, pageSize: number) => ({
    url: `/book/portal/featured?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getFeaturedBooksForPortal"
  }),
  GET_TOP_READ_BOOKS_PORTAL: (pageNumber: number, pageSize: number) => ({
    url: `/book/portal/topReads?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getTopReadsBooksForPortal"
  }),
  GET_RECOMMENDED_BOOKS_PORTAL: () => ({
    url: `/book/portal/recommended`,
    operationId: "getRecommendedBooksForPortal"
  }),
  GET_NEW_ARRIVALS_BOOKS_PORTAL: (pageNumber: number, pageSize: number) => ({
    url: `/book/portal/newArrivals?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getNewArrivalsBooksForPortal"
  }),
  GET_BOOK_BY_ID_PORTAL: (userId: string, bookId: string) => ({
    url: `/user/${userId}/portal/book/${bookId}`,
    operationId: "getBookByIdForPortal"
  }),
  GET_BOOKS_BY_PUBLISHER_ID_PORTAL: (publisherId: string, excludingBook: string) => ({
    url: `/publisher/${publisherId}/digitalBooksForUser?excludingBook=${excludingBook}`,
    operationId: "getBookByPublisherIdForPortal"
  }),
  GET_BOOKS_FOR_EXCEL_BY_PUBLISHER_ID: (publisherId: string,) => ({
    url: `/publisher/${publisherId}/books`,
    operationId: "getPublisherBooksForExcel"
  }),
  GET_BOOKS_BY_AUTHOR_ID_PORTAL: (authorId: string) => ({
    url: `/author/${authorId}/digitalBooksForUser`,
    operationId: "getBookByAuthorIdForPortal"
  }),
  GET_TOP_BOOKS_BY_PUBLISHER_ID_PORTAL: (publisherId: string) => ({
    url: `/publisher/${publisherId}/topDigitalBooksForUser`,
    operationId: "getTopBookByPublisherIdForPortal"
  }),
  CREATE_PORTAL_ORDER: {
    url: "/order/portal",
    operationId: "createPortalOrder"
  },
  GET_PORTAL_ORDER: (orderId: string) => ({
    url: `/order/portal/${orderId}`,
    operationId: "getPortalOrder"
  }),
  RE_GENERATE_ORDER: (orderId: string) => ({
    url: `/order/${orderId}/reCreateOrder`,
    operationId: "reCreateOrder"
  }),
  GET_COUPONS_FOR_ORDER: (userId: string, orderId: string) => ({
    url: `/user/${userId}/order/${orderId}/coupons`,
    operationId: "getCouponForUserOrder"
  }),
  APPLY_COUPON_ON_ORDER: (offerCode: string,) => ({
    url: `/offer/${offerCode}/applyCoupon`,
    operationId: "applyCouponOnOrder"
  }),
  REMOVE_COUPON_FROM_ORDER: (offerCode: string,) => ({
    url: `/offer/${offerCode}/removeCoupon`,
    operationId: "removeCouponFromOrder"
  }),
  CREATE_CF_ORDER_PORTAL: (orderId: string) => ({
    url: `/order/${orderId}/portal/cfOrder`,
    operationId: "createCfOrderForPortal"
  }),
  VERIFY_PAYMENT_FOR_PORTAL_ORDER: (orderId: string) => ({
    url: `/order/${orderId}/portal/verifyOrder`,
    operationId: "verifyPaymentForPortalOrder"
  }),
  GET_USER_ADDRESS: (addressId: string) => ({
    url: `/address/${addressId}`,
    operationId: "getUserAddress"
  }),
  UPDATE_USER_ADDRESS: (addressId: string) => ({
    url: `/address/${addressId}`,
    operationId: "getUserAddress"
  }),
  GET_PINCODE_DETAIL: (pinCode: string, dialCode: string) => ({
    url: `/address/pincodeDetail/${pinCode}?dialCode=${dialCode}`,
    operationId: "getPincodeDetails"
  }),
  CREATE_USER_ADDRESS: () => ({
    url: `/address`,
    operationId: "createUserAddress"
  }),
  SET_DEFAULT_ADDRESSES: (addressId: string) => ({
    url: `/address/${addressId}/setDefault`,
    operationId: "setDefaultUserAddress"
  }),
  ADD_ADDRESS_IN_ORDER: (orderId: string, addressId: string) => ({
    url: `/order/${orderId}/portal/address/${addressId}`,
    operationId: "updateAddressInOrder"
  }),
  GET_ALL_CONTENT_CREATORS_FOR_ADMIN: (adminId: string, pageNumber: number, pageSize: number) => ({
    url: `/admin/${adminId}/contentCreators?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getContentCreatorsForSuperAdmin"
  }),
  CHANGE_USERS_VERIFIED_CREATOR_STATUS: (adminId: string, userId: string) => ({
    url: `/admin/${adminId}/user/${userId}/verifiedContentCreator`,
    operationId: "updateContentCreatorsStatus"
  }),
  CANCEL_USER_ORDER: (subOrderId: string) => ({
    url: `/admin/subOrder/${subOrderId}/cancel`,
    operationId: "cancelUserOrder"
  }),
  MARK_ORDER_AS_DELIVERED_SUPERADMIN: (orderId: string) => ({
    url: `/admin/subOrder/${orderId}/delivered`,
    operationId: "updateSubOrderAsDelivered"
  }),
  UPDATE_PLATFORM_GST_AND_CHARGES_FEES: (adminId: string) => ({
    url: `/admin/${adminId}/gstTaxesFees`,
    operationId: "updatePlatformGstAndFees"
  }),
  GENERATE_NEW_BACKUP_OTPS: () => ({
    url: `/utility/otpsForInternationalUsers`,
    operationId: "generateNewBackupOtps"
  }),
  GET_NEW_BACKUP_OTPS: () => ({
    url: `/utility/otpsForInternationalUsers`,
    operationId: "getBackupOtps"
  }),
  GET_COPYRIGHTS_INFO: () => ({
    url: `/resource/copyrights`,
    operationId: "getCopyrights"
  }),
  CREATE_POST: () => ({
    url: `/post`,
    operationId: "createPost"
  }),
  UPDATE_POST: (postId: string) => ({
    url: `/post/${postId}`,
    operationId: "updatePost"
  }),
  PUBLISH_POST: (postId: string) => ({
    url: `/post/${postId}/status`,
    operationId: "publishPost"
  }),
  GET_POST_BY_ID: (userId: string, postId: string) => ({
    url: `/user/${userId}/post/${postId}`,
    operationId: "getSelfPost"
  }),
  GET_USER_POSTS: (userId: string, isDraft: boolean, pageNumber: number, pageSize: number) => ({
    url: `/user/${userId}/posts?isDraft=${isDraft}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getUserPosts"
  }),
  DELETE_DRAFT_POST: (postId: string) => ({
    url: `/post/${postId}`,
    operationId: "deletePost"
  }),
  // note: commentId can be any postId or commentId itself if fetching replys
  GET_COMMENTS: (commentId: string, type: string, pageNumber: number, pageSize: number) => ({
    url: `/comment/${commentId}?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getComments"
  }),
  CREATE_COMMENT: () => ({
    url: `/comment`,
    operationId: "createComment"
  }),
  ADD_REACTION: () => ({
    url: `/reaction`,
    operationId: "addReaction"
  }),
  CREATE_SERIES: () => ({
    url: `/series`,
    operationId: "createSeries"
  }),
  UPDATE_SERIES: (seriesId: string) => ({
    url: `/series/${seriesId}`,
    operationId: "updateSeries"
  }),
  GET_SERIES_BY_ID: (userId: string, seriesId: string) => ({
    url: `/user/${userId}/series/${seriesId}`,
    operationId: "getUsersSeriesDetail"
  }),
  GET_SERIES_CHAPTER_BY_ID: (userId: string, seriesId: string, chapterId: string) => ({
    url: `/user/${userId}/series/${seriesId}/chapter/${chapterId}`,
    operationId: "getChapterForAuthor"
  }),
  CREATE_CHAPTER: (seriesId: string,) => ({
    url: `/series/${seriesId}/chapter`,
    operationId: "createSeriesChapter"
  }),
  UPDATE_CHAPTER: (seriesId: string, chapterId: string) => ({
    url: `/series/${seriesId}/chapter/${chapterId}`,
    operationId: "updateSeriesChapter"
  }),
  GET_DRAFT_CHAPTER_LIST: (seriesId: string) => ({
    url: `/series/${seriesId}/chapters/draftList`,
    operationId: "getDraftChapterList"
  }),
  GET_PAYG_PLANS_FOR_WRITER: () => ({
    url: `/payg/plans`,
    operationId: "getPaygPlans"
  }),
  PUBLISH_SERIES_TILL_CHAPTERS: (seriesId: string, chapterId: string) => ({
    url: `/series/${seriesId}/chapter/${chapterId}`,
    operationId: "publishSeriesTillChapter"
  }),
  ADD_SERIES_TO_PAYG_AND_PUBLISH: () => ({
    url: `/payg/series`,
    operationId: "addSeriesToPaygPlan"
  }),
  GET_USER_SERIES: (userId: string, isDraft: boolean, pageNumber: number, pageSize: number) => ({
    url: `/user/${userId}/series?isDraft=${isDraft}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getUserSeries"
  }),
  DELETE_DRAFT_SERIES: (seriesId: string) => ({
    url: `/series/${seriesId}`,
    operationId: "deleteSeries"
  }),
  CREATE_SERIES_ORDER: (seriesId: string) => ({
    url: `/payg/order/${seriesId}`,
    operationId: "createPaygOrder"
  }),
  GET_SERIES_ORDER_DETAIL: (orderId: string) => ({
    url: `/payg/order/${orderId}`,
    operationId: "getPaygOrderDetail"
  }),
  GET_SERIES_BY_ID_FOR_READERS: (seriesId: string) => ({
    url: `/series/${seriesId}/details`,
    operationId: "getSeriesDetailForReader"
  }),
  GET_SERIES_CHAPTER_BY_ID_FOR_READERS: (seriesId: string, chapterId: string) => ({
    url: `/series/${seriesId}/chapter/${chapterId}/details`,
    operationId: "getSeriesChapterDetailForReader"
  }),
  DELETE_DRAFT_CHAPTER: (seriesId: string, chapterId: string) => ({
    url: `/series/${seriesId}/chapter/${chapterId}`,
    operationId: "deleteSeriesChapter"
  }),
  ADD_USER_VIEW_FOR_CHAPTER: () => ({
    url: `/series/view`,
    operationId: "addChapterView"
  }),
  SERIES_SHORT_DETAIL: (seriesId: string) => ({
    url: `/series/${seriesId}/shortDetail`,
    operationId: "seriesShortDetail"
  }),
  GET_NEW_SERIES_PORTAL: () => ({
    url: `/series/newStories`,
    operationId: "getNewStoriesForPortal"
  }),
  GET_NEW_RACHNAYE_PORTAL: (userId: string) => ({
    url: `/user/${userId}/newRachnaye`,
    operationId: "getNewRachnaye"
  }),
  GET_ACCOLADES_RACHNAYE_PORTAL: (userId: string) => ({
    url: `/user/${userId}/accoladesRachnaye`,
    operationId: "getAccoladesRachnaye"
  }),
  GET_NARRATION_RACHNAYE_PORTAL: () => ({
    url: `/post/portal/narrations`,
    operationId: "getNarrationRachnaye"
  }),
  GET_NARRATION_SERIES_PORTAL: () => ({
    url: `/series/portal/narrations`,
    operationId: "getSeriesRachnaye"
  }),
  GET_WRITER_ORDERS: (userId: string, pageNumber: number, pageSize: number) => ({
    url: `/payg/user/${userId}/seriesOrders?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getWritersOrders"
  }),
  GET_LEDGER_ACCOUNT_FOR_WRITER: (userId: string) => ({
    url: `/payg/user/${userId}/ledgerAccount`,
    operationId: "getWriterLedgerAccount"
  }),
  GET_PAYMENT_ACCOUNT_FOR_WRITER: (userId: string) => ({
    url: `/user/${userId}/paymentAccount`,
    operationId: "getWriterPaymentAccount"
  }),
  UPDATE_BANK_DETAILS_FOR_WRITER: (userId: string) => ({
    url: `/user${userId}/paymentAccount/bank`,
    operationId: "updateWriterBankDetail"
  }),
  UPDATE_UPI_DETAILS_FOR_WRITER: (userId: string) => ({
    url: `/user/${userId}/paymentAccount/upi`,
    operationId: "updateWriterUpiDetail"
  }),
  REQUEST_WITHDRAWAL_FOR_WRITER: {
    url: "/payout/writer",
    operationId: "createPayoutForWriter"
  },
  GET_PAYOUTS_FOR_WRITER: (userId: string, pageNumber: number, pageSize: number) => ({
    url: `/user/${userId}/payouts?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getPayoutsForWriter"
  }),
  CREATE_DYNAMIC_LINK: {
    url: "/utility/dynamicLink",
    operationId: "createDynamicLink"
  },
  UPDATE_CATEGORIES_USER: (userId: string) => ({
    url: `/user/portal/${userId}`,
    operationId: "updateUser"
  }),
  GET_BOOKS_BY_AUTHOR_ID: (authorId: string) => ({
    url: `/author/${authorId}/portal/books`,
    operationId: "getBooksByAuthorId"
  }),
  GET_TOP_BOOKS_BY_AUTHOR_ID: (authorId: string) => ({
    url: `/author/${authorId}/portal/topBooks`,
    operationId: "getTopBooksByAuthorId"
  }),
  SAVE_AUDIOBOOK_DRAFT: (bookId: string) => ({
    url: `/book/${bookId}/audiobook`,
    operationId: "saveAudioBookDraft"
  }),
  UPDATE_AUDIOBOOK_PRICE: (bookId: string, price: number) => ({
    url: `/book/${bookId}/audiobook/price/${price}`,
    operationId: "updateAudioBookPrice"
  }),
  PUBLISH_AUDIOBOOK: (bookId: string) => ({
    url: `/book/${bookId}/audiobook/publish`,
    operationId: "publishAudioBook"
  }),
  GET_KEYWORD_SUGGESTIONS: (value: string) => ({
    url: `/utility/keywords?search=${value}`,
    operationId: "getSuggestedKeywords"
  }),
  GET_LIST_OF_TRENDING_SEARCHES: () => ({
    url: `/utility/trendingSearches`,
    operationId: "getTrendingSearches"
  }),
  UPDATE_LIST_OF_TRENDING_SEARCHES: () => ({
    url: `/utility/trendingSearches`,
    operationId: "updateTrendingSearches"
  }),
  UPDATE_RACHNAYE_COVER_IMAGES: () => ({
    url: `/utility/rachnayeCoverImages`,
    operationId: "updateRachnayeCoverImages"
  }),
  GET_RACHNAYE_COVER_IMAGES: () => ({
    url: `/utility/rachnayeCoverImages`,
    operationId: "getRachnayeCoverImages"
  }),
  CREATE_OFFLINE_ORDERS: () => ({
    url: `/offlineSubOrder`,
    operationId: "createOfflineOrders"
  }),
  GET_SUPERADMIN_OFFLINE_SUB_ORDERS: (pageNumber: number, pageSize: number) => ({
    url: `/offlineSubOrder/allOrders?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getOfflineAllSubOrders"
  }),
  GET_SUPERADMIN_INCOMPLETE_SUB_ORDERS: (queryParam: string) => ({
    url: `/admin/subOrders/incompleteOrders?${queryParam}`,
    operationId: "getIncompleteSubOrders"
  }),
  GET_SUPERADMIN_LESS_QUANTITY_BOOKS: (queryParam: string) => ({
    url: `/admin/books/lessQuantityBooks?${queryParam}`,
    operationId: "getLessQuantityBooks"
  }),
  GET_SUPERADMIN_DIGITAL_BOOKS_ORDERS: (queryParam: string) => ({   // except paperback and hardbound
    url: `/admin/subOrders/digitalBooks?${queryParam}`,
    operationId: "getDigitalBookSubOrders"
  }),
  GET_SUPERADMIN_SERIES_ORDERS: (queryParam: string) => ({
    url: `/admin/subOrders/series?${queryParam}`,
    operationId: "getSeriesSubOrders"
  }),
  GET_NOTIFY_ME_FOR_BOOKS_USERS: (queryParam: string) => ({
    url: `/notifyMe/users?${queryParam}`,
    operationId: "getNotifyMeBookUsers"
  }),
  GET_BOOKS_IN_PRICE_RANGE: (queryParam: string) => ({
    url: `/admin/books?${queryParam}`,
    operationId: "getBookInPriceRange"
  }),
  CREATE_NOTIFY_ME_FOR_BOOK: {
    url: "/notifyMe",
    operationId: "createNotifyMeForBook"
  },
  GET_SCHEDULED_NOTIFICATIONS: (queryParam: string) => ({
    url: `/notification/scheduled/distinct?${queryParam}`,
    operationId: "getScheduledNotification"
  }),
  DELETE_SCHEDULED_NOTIFICATIONS: (scheduleNotificationGrpId: string) => ({
    url: `/notification/scheduled/${scheduleNotificationGrpId}`,
    operationId: "deleteScheduledNotification"
  }),
  GET_MOBILE_DEVICE_STATS: (queryParam: string) => ({
    url: `/admin/reports/deviceStats?type=${queryParam}`,
    operationId: "getMobileDeviceStats"
  }),
  GET_MOBILE_DEVICE_STATS_TABLE_FOR_SELECTED_TYPE: (type: string, pageNumber: number, pageSize: number) => ({
    url: `/admin/reports/deviceStatsTable?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    operationId: "getMobileDeviceStatsTable"
  }),
  GET_FINANCIAL_REPORT: (startDate: string, endDate: string) => ({
    url: `/utility/monthWiseGraphData?startDate=${startDate}&endDate=${endDate}`,
    operationId: "getFinancialReportBbyMonth"
  }),
};
