import { IBook } from "../books/types";

export interface ISubOrder {
  id: string;
  referenceNumber: string;
  userId: string;
  publisherId: string;
  paymentId?: string;
  status: string;
  totalAmount: number;
  quantity: number;
  unitPrice: number;
  bookId: string;
  orderType: string;
  taxPercentage: number;
  isRental: boolean;
  rentalType?: string;
  user: IUser;
  book: IBook;
  createdAt: string;
}

export interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  address: IAddress;
  phoneNumber: string;
}

export interface IAddress {
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  pincode?: string;
}
export interface IOrderItem {
  itemType?: string;
  quantity: number;
  unitPrice: number;
  discountedPrice?: IPricing;
  taxPercentage?: number;
  orderType: string;
  bookId: string;
  rentalType?: string;
  publisherId: string;
  subTotal: number;
  isReturnable?: boolean;
  offerId?: string;
  couponId?: string;
  bookName?: string;
  imageUrl?: string;
  unitOfferPrice: number;
  rachnayeEssential: boolean;
  rachnayeFulfilled: boolean;
  preOrderAvailable: boolean;
  isDiscounted?: boolean;
}

export interface ICartItem extends IOrderItem {
  id: string //cartItem id
  bookType: string;  // bookType is in cart, orderType is in order api
  booksAvailable: number
  bookName: string;
  imageUrl: string;
  type: string;
}
export interface IPricing {
  planName: string;
  rentalPrice: IRentalPrice;
  sellPrice: number;
  paperBackPrice: number;
  audiobookPrice: number;
  hardboundPrice: number;
}

export interface IRentalPrice {
  weekly: number;
  fortNightly: number;
  monthly: number;
}

export interface IContactNumber {
  countryCode: string;
  phoneNumber: string;
}

export interface IOrderAddress {
  nickname: string;
  recipientName: string;
  address1: string;
  address2?: string;
  street?: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  contactNumber: IContactNumber;
}

export interface IOrder {
  id: string;
  referenceNumber: string;
  userId: string;
  sessionId: string;
  subOrders: string[];
  paymentId?: string;
  status: string;
  orderType: string;
  orderItems: IOrderItem[];
  totalAmount: number;
  billingAddress: IOrderAddress;
  shippingAddress: IOrderAddress;
  subTotal: number;
  taxAmount: number;
  shippingCharges?: number;
  bundleId?: string;
}

export interface ICreatOrderItemForOrder {
  quantity: number;
  unitPrice: number;
  orderType: string;
  bookId: string;
  rentalType?: string;
  publisherId: string;
  subTotal: number;
  isReturnable?: boolean;
  offerId?: string;
  couponId?: string;
  itemType?: string;
}

export interface IOrderResponse extends IOrder {
  totalDiscountOnSellingPrice?: number;
  newOfferedPrice: number;
  totalSellingPrice?: number;
  offerCode?: string;
  totalOfferDiscount?: number;
  totalCouponDiscount?: number;
  // to support old app
  publisherTotalDiscount?: number;
  couponDiscountPrice?: number;
  //
}



export enum OrderType {
  BUNDLE = 'bundleOrder',
  BUY_NOW = 'buyNow',
  CART = 'cart',
  WEBAPP = 'webapp'
}

export enum OrderPlatformType {
  WEBAPP = 'webapp',
  MOBILE = 'mobile'
}