import {
  Avatar, CircularProgress, Fab, Grid,
  IconButton,
  Paper,
  Typography
} from "@material-ui/core";
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';
import moment from "moment";
import { MUIDataTableMeta, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IPublisher } from "../../../reducers/publishers/types";
import { IAppState } from "../../../store";
import callApi from "../../../utils/apiUtil";
import { IFiltersAndSorting } from "../../../utils/common.interface";
import { bookSeoUpdateFieldsMap, CsvUploadStatusType, HTTP_METHODS, possibleCsvMimeTypes } from "../../../utils/constants";
import { PATHNAMES } from "../../../utils/pathNames";
import { ErrorHandler, handleRowHover, handleRowHoverLeave } from "../../../utils/utils";
import CircularLoader from "../../common/CircularLoader";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import CustomContextMenu from "../../common/CustomContextMenu/CustomContextMenu";
import { IContextMenuPosition } from "../../common/CustomContextMenu/types";
import { DataTable } from "../../common/Datatable/Datatable";
import { Pagination } from "../../common/Datatable/types";
import { useSnackBar } from "../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../common/SnackbarWrapper/SnackbarWrapper";
import { BulkCsvUpdateDialog } from "../Books/BulkCsvUpdateDialog";
const xlsxParser = require('xlsx-parse-json');


const Publishers: React.FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const userState = useSelector((state: IAppState) => state.user);

  const [loading, setLoading] = useState<boolean>(true);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [fileValue, setFileValue] = useState<File | undefined>();
  const [fileName, setFileName] = useState<string>("");
  const [uploadStatus, setUploadStatus] = useState<number>(CsvUploadStatusType.DEFAULT);
  const [bulkUpdate, setBulkUpdate] = useState<boolean>(false);

  const [publishers, setPublishers] = useState<IPublisher[]>([]);
  const [totalPublishers, setTotalPublishers] = useState<number>(0);
  const [publisherId, setPublisherId] = useState<string>(""); // publisher's id , which we want to delete
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isUploading, ] = useState<boolean>(false);
  const [contextMenu, setContextMenu] = useState<IContextMenuPosition | null>(null);
  const [menuAttributes, setMenuAttributes] = useState({
    link: "",
  })

  useEffect(() => {
    if (userState) {
      fetchPublishers({ pageNumber: Pagination.DEFAULT_PAGE_NUMBER, pageSize: Pagination.DEFAULT_PAGE_SIZE })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState])

  const fetchPublishers = (args: IFiltersAndSorting) => {
    const { pageNumber, pageSize, searchText = "", sortBy = {}, applyFilter = false, filters } = args

    callApi(PATHNAMES.GET_ALL_PUBLISHERS_FOR_ADMIN(userState.id, pageNumber, pageSize), HTTP_METHODS.POST, {
      body: JSON.stringify({
        data: {
          searchText,
          sortBy,
          applyFilter,
          filters
        }
      }),
    }).then(res => {
      if (res && res.success && res.data) {
        setPublishers(res.data.publishers);
        setTotalPublishers(res.data.publisherCount);
        setLoading(false)
      }
    })
  }

  const handleContextMenuClick = (
    event: React.MouseEvent, publisherId: string
  ) => {
    event.preventDefault();
    const value: any = contextMenu === null ? {
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    } : null;
    setContextMenu(value);
    setMenuAttributes({
      ...menuAttributes,
      link: `update-publisher?publisher=${publisherId}`
    })
  };

  const deletePublisher = async (publisherId: string) => {
    try {
      const response: any = await callApi(PATHNAMES.TERMINATE_PUBLISHER_BY_ID(userState.id, publisherId), HTTP_METHODS.PATCH)
      setOpenConfirmationDialog(false)
      if (response?.success && response?.message) {
        snackbar({
          message: response?.message || "Publisher terminated successfully",
          variant: SnackBarVariant.SUCCESS
        })
        // remove deleted publisher from list.
        const filteredPublishers = publishers.filter((publisher) => publisher.id !== publisherId)
        setPublishers(filteredPublishers)
      } else {
        throw new ErrorHandler(response.message)
      }
    } catch (error: any) {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: error.message || "Something went wrong",
      })
    }
  }

  const columns: any = [
    {
      label: "ID",
      name: "id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      label: "Profile",
      name: "profileImageUrl",
      options: {
        customBodyRender: (data: any) => {
          return (
            <Avatar
              alt="Publisher"
              variant="rounded"
              style={{ borderRadius: "50%" }}
              src={data}
            ></Avatar>
          );
        },
      },
    },
    {
      label: "Publisher Name",
      name: "name"
    },
    {
      label: "Requires Shipping",
      name: "requiresShipping",
      options: {
        customBodyRender: (data: any) => String(data).toUpperCase()
      },
    },
    {
      label: "Uploaded Books",
      name: "bookCount"
    },
    {
      label: "Status",
      name: "status"
    },
    {
      label: "",
      name: "",
      options: {
        setCellProps: () => ({ style: { minWidth: "2rem", maxHeight: '2rem' } }),
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta, updateValue: any) => {
          return (
            <IconButton
              id={`Edit-${tableMeta.rowIndex}`}
              title='Terminate Publisher'
              size='small'
              style={{ display: 'none' }}
              onClick={(event: any) => {
                const [publisherId] = tableMeta.rowData
                setPublisherId(publisherId)
                event.stopPropagation()
                setOpenConfirmationDialog(true)
              }}
            >
              <HighlightOffSharpIcon color='primary' style={{ fontSize: '1.5em' }} />
            </IconButton>
          );
        }
      }
    },
  ]

  const options: MUIDataTableOptions = {
    elevation: 0,
    filter: false,
    onRowClick: (rowData: any, rowMeta: any) => {
      const item: any = publishers[rowMeta.dataIndex];
      if (item) {
        navigate(`/update-publisher?publisher=${item.id}`);
      }
    },
    setRowProps: (row: any, rowIndex: number) => {
      return {
        onMouseEnter: () => handleRowHover(rowIndex),
        onMouseLeave: () => handleRowHoverLeave(rowIndex),
        onContextMenu: (event: React.MouseEvent) => {
          const [publisherId] = row
          return handleContextMenuClick(event, publisherId)
        }
      }
    },
    searchPlaceholder: "Search by name, uploaded books count or status",
    count: totalPublishers,
  };

  const downloadBooks = async () => {
    setIsDownloading(true)
    const res = await callApi(PATHNAMES.DOWNLOAD_ALL_BOOKS, HTTP_METHODS.GET)
    if (res && res.success && res.data?.key) {
      const file = await callApi(PATHNAMES.GET_SIGNED_URL, HTTP_METHODS.POST, {
        body: JSON.stringify({ data: { key: res.data.key } })
      });
      const fileName = `Books-${moment().format('DD/MM/YYYY')}.xlsx`;
      const url: any = file.data;
      fetch(url, {
        method: "GET"
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
          setIsDownloading(false);
        });
    }
  }

  const onSelectDocument = (event: any) => {
    const file: File = event.target.files[0];
    setUploadStatus(CsvUploadStatusType.DEFAULT);
    if (file) {
      setFileName(file.name);
      if (possibleCsvMimeTypes.includes(file.type)) {
        setFileValue(file);
      } else {
        return snackbar({
          message: "Invalid File Type. Allowed type: xlsx",
          variant: SnackBarVariant.ERROR
        });
      }
    }
  }

  const uploadBooksViaFile = async (booksInputArr: any[]) => {
    const response = await callApi(PATHNAMES.UPDATE_ALL_BOOKS, HTTP_METHODS.POST, {
      body: JSON.stringify({ books: booksInputArr })
    });
    if (response && response.success) {
      snackbar({
        message: "Books updated successfully",
        variant: SnackBarVariant.SUCCESS
      });
      setBulkUpdate(false);
      setUploadStatus(CsvUploadStatusType.SUCCESS);
      setFileValue(undefined);
      setFileName("");
    } else {
      setUploadStatus(CsvUploadStatusType.ERROR);
      snackbar({
        message: "Xlsx contains incorrect values",
        variant: SnackBarVariant.ERROR
      })
    }
  }

  const uploadFile = () => {
    try {
      if (fileValue) {
        setUploadStatus(CsvUploadStatusType.IN_PROGRESS);
        xlsxParser.onFileSelection(fileValue)
          .then((parsedData: any) => {
            const booksInputArr = [];
            if (parsedData && parsedData.Books?.length) {
              for (let index = 0; index < parsedData.Books.length; index++) {
                const element: any = parsedData.Books[index];
                const data: any = {}
                for (let key in element) {
                  data[bookSeoUpdateFieldsMap[key]] = element[key]
                }
                booksInputArr.push({
                  id: data.id,
                  metaTitle: data.metaTitle,
                  metaDescription: data.metaDescription,
                  metaKeywords: data.metaKeywords,
                  schemaMarkup: data.schemaMarkup
                });
              }
            }
            if (booksInputArr.length > 0) {
              uploadBooksViaFile(booksInputArr);
            } else {
              setUploadStatus(CsvUploadStatusType.ERROR);
              snackbar({
                message: "No data found.",
                variant: SnackBarVariant.ERROR
              });
            }
          });
      }
    } catch (error) {
      setUploadStatus(CsvUploadStatusType.ERROR);
    }
  }

  if (loading) {
    return <CircularLoader />
  }

  return (
    <Grid container>
      <Paper elevation={0} style={{ width: "100%", padding: "1rem" }}>
        <Grid container xs={12}>
          <Grid container xs={5}>
            <Typography variant="h2" style={{ fontWeight: "bolder", padding: "1rem", }}>
              Publishers
              <Typography variant="h2" color="primary" style={{ display: "inline", marginLeft: "1rem" }}>
                {totalPublishers}
              </Typography>
            </Typography>
          </Grid>
          <Grid container xs={7} justify="flex-end">
            <Fab variant="extended" size="medium" onClick={downloadBooks} style={{ marginRight: 10 }}>
              {isDownloading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
              {"Download"}
            </Fab>
            <Fab variant="extended" size="medium" onClick={() => setBulkUpdate(true)} style={{ marginRight: 10 }}>
              {isUploading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
              {"Upload"}
            </Fab>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <DataTable
            title={""}
            rows={publishers}
            columns={columns}
            options={options}
            hideSelectableRows={true}
            serverSide
            fetchData={fetchPublishers}
          />
          <CustomContextMenu
            onClose={() => setContextMenu(null)}
            position={contextMenu}
            attributes={menuAttributes}
          />
          <ConfirmationDialog
            isOpen={openConfirmationDialog}
            onCancel={() => setOpenConfirmationDialog(false)}
            onConfirm={() => deletePublisher(publisherId)}
            title={"Terminate Publisher"}
            description={`Are you sure, you want to terminate this publisher?`}
          />
        </Grid>
        <Grid container xs={12}>
          {bulkUpdate && (
            <BulkCsvUpdateDialog
              title={"Books"}
              subTitle={"Books"}
              csvTemplateLink=""
              uploadErrors={[]}
              uploadStatus={uploadStatus}
              onDocumentSelect={onSelectDocument}
              fileName={fileName}
              updateCsv={uploadFile}
              handleClose={() => setBulkUpdate(false)}
            />
          )}
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Publishers;