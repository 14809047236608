import { Box, CircularProgress, Fab, Grid, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import ShareIcon from '@material-ui/icons/Share';
import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../../assets/rachnayeLight.png";
import { IBook, IDigitalBookResponse } from "../../../../reducers/books/types";
import { updateMobileUser } from "../../../../reducers/mobileUser/reducer";
import { ICartItem, ICreatOrderItemForOrder, IOrderItem, OrderPlatformType, OrderType, } from "../../../../reducers/orders/types";
import { IAppState } from "../../../../store";
import callApi from "../../../../utils/apiUtil";
import { getAgeRangeValueOrLabel } from "../../../../utils/bookUtils";
import {
  BookContentTypes, BookRentalTypes, HTTP_METHODS, OrderItemTypes, TAX_PERCENTAGE,
} from "../../../../utils/constants";
import { PATHNAMES } from "../../../../utils/pathNames";
import BookImage from "../../../common/BookImage";
import CircularLoader from "../../../common/CircularLoader";
import { useSnackBar } from "../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../common/SnackbarWrapper/SnackbarWrapper";
import PriceContainer from "./PriceContainer";

const useStyles = makeStyles((theme) => ({
  disabledBtn: {
    backgroundColor: "grey!important"
  },
  blueTitle: {
    marginLeft: "1rem",
    display: "inline",
    color: "blue",
  },
  bookDetailHeader: {
    fontWeight: "bold",
    display: "inline"
  },
  noPaddingAndMargin: {
    padding: "0rem", margin: "0rem"
  },
  popover: {
    minWidth: "200px",
    padding: theme.spacing(1),
    borderRadius: "25px"
  },
  popoverTitle: {
    color: "red",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  authorItem: {
    marginBottom: theme.spacing(0),
  },
  box: {
    backgroundColor: "white",
    color: "black",
    width: "280px",
    borderRadius: "15%"
  },
  boxDiv: {
    "&:hover": { backgroundColor: "#c1c1a4" },
    backgroundColor: "#e0e0d1",
  },
  boxFirstDiv: {
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    "&:hover": { backgroundColor: "#c1c1a4" },
    backgroundColor: "#e0e0d1",
  },
  boxLastDiv: {
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    "&:hover": { backgroundColor: "#c1c1a4" },
    backgroundColor: "#e0e0d1",
  },
  tooltip: {
    backgroundColor: 'black',
    color: theme.palette.common.white,
    fontSize: 14,
    maxWidth: 300,
  },
  arrow: {
    color: 'black',
  },
  authorName: {
    "&:hover": {
      transform: "scale(1.05)",
      transition: "all 0.3s ease-in-out",
      boxShadow: "0 0 8px rgba(255, 0, 0, 0.5)",
      backgroundColor: "linear-gradient(to right, #ffa500, #ff6347)",
      fontWeight: "bold",
      cursor: "pointer",
    },
  },
  hoverProperties: {
    transition: "all 0.5s ease-out",
    "&:hover": {
      transform: "scale3d(1.10, 1.10, 1)"
    },
  },
  shareButton: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  bookImageInSmallScreen: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: 'center'
    }
  },
  underlineOnHover: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.main
    },
  },
  publisherHover: {
    marginLeft: "1rem",
    display: "inline",
    color: "orange",
    textDecoration: "none",
    "&:hover:": {
      color: theme.palette.primary.main,
      textDecoration: "underline"
    }
  }
}));


export interface IOrderInput {
  orderItems: ICreatOrderItemForOrder[];
  orderType: string;
  platform: string;
  billingAddress: string;
  shippingAddress: string;
}

interface IHeaderProps {
  title: string;
}

const Header: React.FC<IHeaderProps> = ({ title }) => {
  return (
    <Grid item xs={12} style={{ marginTop: "1rem" }}>
      <Typography variant="h2" style={{ fontWeight: "bold" }}>
        {title}
      </Typography>
    </Grid>)
}

interface IDescriptionProps {
  description: string;
}
const Description: React.FC<IDescriptionProps> = ({ description }) => {
  return (
    <Grid item xs={12} style={{ marginTop: "1rem" }}>
      <Typography variant="h4" style={{ fontWeight: 400, fontSize: 16, lineHeight: "30px" }}>
        {description}
      </Typography>
    </Grid>
  )
}

const DigitalBookDetail: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const [book, setBook] = useState<IDigitalBookResponse>();
  const [publisherId, setPublisherId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [publisherBooks, setPublisherBooks] = useState<IBook[]>([]);
  const mobileUserState = useSelector((state: IAppState) => state.mobileUser);
  const [bookAlreadyPurchased, setBookAlreadyPurchased] = useState<boolean>(false);
  const [bookIsFreeToRead, setBookIsFreeToRead] = useState<boolean>(false);
  const [selectedBookTypeToBuy, setSelectedBookTypeToBuy] = useState<string>(OrderItemTypes.BUY_DIGITAL);
  const [selectedRentalTypeToBuy, setSelectedRentalTypeToBuy] = useState<string>(BookRentalTypes.WEEKLY);
  const [cartItems, setCartItems] = useState<ICartItem[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (mobileUserState && mobileUserState.id) {
      fetchUserCart(mobileUserState.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileUserState.id]);

  const fetchUserCart = async (userId: string) => {
    const res = await callApi(
      PATHNAMES.GET_MOBILE_USER_CART(userId),
      HTTP_METHODS.GET
    );
    if (res && res.success && res.data?.cartItems) {
      setCartItems(res.data.cartItems);
      dispatch(updateMobileUser({ ...mobileUserState, cartQuantity: res.data.cartTotalItemQuantity }));
    }
  };

  //fetch book data
  useEffect(() => {
    if (location && location.search && mobileUserState && mobileUserState.id) {
      setBookAlreadyPurchased(false);
      const params = new URLSearchParams(location.search);
      const bookId = params.get("id");
      if (bookId) {
        //reset values
        setBookAlreadyPurchased(false);
        setBookIsFreeToRead(false);
        setSelectedRentalTypeToBuy(selectedRentalTypeToBuy || BookRentalTypes.WEEKLY);
        setLoading(true);
        // fetch book
        getBookData(bookId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, mobileUserState]);

  const setSelectedType = (book: IDigitalBookResponse) => {
    if (book.hasDigital)
      setSelectedBookTypeToBuy(OrderItemTypes.BUY_DIGITAL)
    else if (book.hasRental)
      setSelectedBookTypeToBuy(OrderItemTypes.RENTAL_DIGITAL)
    else if (book.hasPaperback)
      setSelectedBookTypeToBuy(OrderItemTypes.PAPERBACK)
    else if (book.hasHardbound)
      setSelectedBookTypeToBuy(OrderItemTypes.HARDBOUND)
    else if (book.hasAudiobook)
      setSelectedBookTypeToBuy(OrderItemTypes.AUDIOBOOK)
    else
      setSelectedBookTypeToBuy(OrderItemTypes.BUY_DIGITAL)
  }

  const getBookData = (bookId: string) => {
    callApi(
      PATHNAMES.GET_BOOK_BY_ID_PORTAL(mobileUserState.id, bookId),
      HTTP_METHODS.GET
    ).then((res) => {
    if (res && res.success && res.data) {
      setLoading(false);
      setBook(res.data);
      setPublisherId(res.data.publisherData.id);
      isBookPurchased(res.data)
      isBookFreeToRead(res.data)
      changePreSelectedBookType(res.data)
      setSelectedType(res.data)
      callApi(PATHNAMES.GET_BOOKS_BY_PUBLISHER_ID_PORTAL(res.data.publisher, bookId), HTTP_METHODS.GET).then(res2 => {
        if (res2 && res2.success && res2.data) {
          setPublisherBooks(res2.data);
        }
      });
    }
    });
  };

  const isBookPurchased = (book: IDigitalBookResponse) => {
    // book is purchased or book is free but he has read it previously

    if (book.canReadDigital || book.hasReadFreeDigital) {
      setBookAlreadyPurchased(true);
    }
    return false;
  };

  const isBookFreeToRead = (book: IDigitalBookResponse) => {
    // book is free but he has not read it once
    if (
      book.hasDigital &&
      book.contentType === BookContentTypes.FREE &&
      !book.hasReadFreeDigital
    ) {
      setBookIsFreeToRead(true);
    }
  };

  const changePreSelectedBookType = (book: IDigitalBookResponse) => {
    if (book.hasPaperback && book.contentType === BookContentTypes.PAID) {
      setSelectedBookTypeToBuy(OrderItemTypes.PAPERBACK);
      return
    }
    if (book.hasHardbound && book.contentType === BookContentTypes.PAID) {
      setSelectedBookTypeToBuy(OrderItemTypes.HARDBOUND);
      return
    }
    if (book.hasDigital) {
      setSelectedBookTypeToBuy(OrderItemTypes.BUY_DIGITAL);
      return
    }
    if (!book.hasDigital && book.hasRental) {
      setSelectedBookTypeToBuy(OrderItemTypes.RENTAL_DIGITAL);
      return
    }
  };

  const addToCart = async () => {
    if (book && mobileUserState) {
      let {
        orderType,
        rentalType,
      } = getOrderItemRelatedData();
      const cartItem: any = {
        bookId: book.id,
        userId: mobileUserState.id,
        bookType: orderType,
        quantity: 1
      };
      if (selectedBookTypeToBuy === OrderItemTypes.RENTAL_DIGITAL) {
        cartItem.rentalType = rentalType;
      }
      const data = cartItem
      const res = await callApi(
        PATHNAMES.ADD_ITEM_TO_CART(),
        HTTP_METHODS.POST,
        {
          body: JSON.stringify({ data }),
        }
      );
      if (res && res.success) {
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: "Book added to cart successfully",
        });
        fetchUserCart(mobileUserState.id);
      }
    }
  };

  const getOrderItemRelatedData = () => {
    let unitPrice = 0;
    let subTotal = 0;
    let offerId = "";
    let quantity = 1;
    let orderType = selectedBookTypeToBuy;
    let rentalType = selectedRentalTypeToBuy;

    if (book) {
      if (selectedBookTypeToBuy === OrderItemTypes.BUY_DIGITAL) {
        unitPrice = book.pricing.sellPrice;
        if (
          book.isDiscounted &&
          book?.discountedPrice &&
          book.discountedPrice.sellPrice < book.pricing.sellPrice
        ) {
          offerId = book.offerId || "";
          subTotal = quantity * book.discountedPrice.sellPrice;
        } else {
          subTotal = quantity * book.pricing.sellPrice;
        }
      } else if (selectedBookTypeToBuy === OrderItemTypes.RENTAL_DIGITAL) {
        if (selectedRentalTypeToBuy === BookRentalTypes.WEEKLY) {
          unitPrice = book.pricing.rentalPrice.weekly;
          if (
            book.isDiscounted &&
            book.discountedPrice &&
            book.discountedPrice.rentalPrice.weekly <
            book.pricing.rentalPrice.weekly
          ) {
            subTotal = quantity * book.discountedPrice.rentalPrice.weekly;
            offerId = book.offerId || "";
          } else {
            subTotal = quantity * book.pricing.rentalPrice.weekly;
          }
        }
        if (selectedRentalTypeToBuy === BookRentalTypes.FORTNIGHTLY) {
          unitPrice = book.pricing.rentalPrice.fortNightly;
          if (
            book.isDiscounted &&
            book.discountedPrice &&
            book.discountedPrice.rentalPrice.fortNightly <
            book.pricing.rentalPrice.fortNightly
          ) {
            subTotal = quantity * book.discountedPrice.rentalPrice.fortNightly;
            offerId = book.offerId || "";
          } else {
            subTotal = quantity * book.pricing.rentalPrice.fortNightly;
          }
        }
        if (selectedRentalTypeToBuy === BookRentalTypes.MONTHLY) {
          unitPrice = book.pricing.rentalPrice.monthly;
          if (
            book.isDiscounted &&
            book.discountedPrice &&
            book.discountedPrice.rentalPrice.monthly <
            book.pricing.rentalPrice.monthly
          ) {
            subTotal = quantity * book.discountedPrice.rentalPrice.monthly;
            offerId = book.offerId || "";
          } else {
            subTotal = quantity * book.pricing.rentalPrice.monthly;
          }
        }
      } else if (selectedBookTypeToBuy === OrderItemTypes.PAPERBACK) {
        unitPrice = book.pricing.paperBackPrice;
        if (
          book.isDiscounted &&
          book?.discountedPrice &&
          book.discountedPrice.paperBackPrice < book.pricing.paperBackPrice
        ) {
          offerId = book.offerId || "";
          subTotal = quantity * book.discountedPrice.paperBackPrice;
        } else {
          subTotal = quantity * book.pricing.paperBackPrice;
        }
      } else if (selectedBookTypeToBuy === OrderItemTypes.HARDBOUND) {
        unitPrice = book.pricing.hardboundPrice;
        if (
          book.isDiscounted &&
          book?.discountedPrice &&
          book.discountedPrice.hardboundPrice < book.pricing.hardboundPrice
        ) {
          offerId = book.offerId || "";
          subTotal = quantity * book.discountedPrice.hardboundPrice;
        } else {
          subTotal = quantity * book.pricing.hardboundPrice;
        }
      } else if (selectedBookTypeToBuy === OrderItemTypes.AUDIOBOOK) {
        //CHECKME: audio book price 
        unitPrice = book?.audioBook?.price || 0;
        if (
          book.isDiscounted &&
          book?.discountedPrice &&
          book.discountedPrice.audiobookPrice < (book.audioBook?.price || 0)
        ) {
          offerId = book.offerId || "";
          subTotal = quantity * book.discountedPrice.audiobookPrice;
        } else {
          subTotal = quantity * unitPrice;
        }
      }
    }
    return { unitPrice, subTotal, offerId, quantity, orderType, rentalType };
  };

  const createOrder = async () => {
    if (book) {
      let {
        unitPrice,
        subTotal,
        offerId,
        quantity,
        orderType,
        rentalType,
      } = getOrderItemRelatedData();

      const orderItem: ICreatOrderItemForOrder = {
        unitPrice,
        subTotal,
        orderType,
        quantity,
        offerId,
        bookId: book.id,
        publisherId: book.publisher,
        isReturnable: book.publisherData?.requiresShipping ? true : false,
      };

      if (selectedBookTypeToBuy === OrderItemTypes.RENTAL_DIGITAL) {
        orderItem.rentalType = rentalType;
      }

      const orderData: IOrderInput = {
        orderItems: [orderItem],
        orderType: OrderType.BUY_NOW,
        platform: OrderPlatformType.WEBAPP,
        billingAddress: mobileUserState.defaultBillingAddress || "",
        shippingAddress: mobileUserState.defaultShippingAddress || "",
      };
      setOrderLoading(true);
      const res = await callApi(
        PATHNAMES.CREATE_PORTAL_ORDER,
        HTTP_METHODS.POST,
        {
          body: JSON.stringify({ data: orderData }),
        }
      );
      if (res && res.success && res.data) {
        setOrderLoading(false);
        navigate(`/portal/orderSummary?orderId=${res.data.id}`);
      }
    }
  };

  const checkIfItemAddedToCart = () => {
    if (book && cartItems.length) {
      const cartItem = cartItems.find(
        (item) =>
          item.bookId === book.id && item.bookType === selectedBookTypeToBuy
      );
      if (cartItem) {
        return true;
      }
    }
    return false
  }

  const handleCopyLink = async () => {
    if (book && book.id) {
      const data = {
        id: book.id,
        resourceType: "book"
      }
      const res = await callApi(PATHNAMES.CREATE_DYNAMIC_LINK, HTTP_METHODS.POST, {
        body: JSON.stringify({ data })
      });
      if (res && res.success && res.data) {
        const { shortLink } = res.data
        navigator.clipboard.writeText(shortLink)
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: "Link copied successfully"
        })
        return
      }
    } else {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: "Something went wrong while copying link"
      })
      return
    }

  }

  const checkBookHasQuantity = () => {
    let flag = false;

    switch (selectedBookTypeToBuy) {
      case OrderItemTypes.BUY_DIGITAL:
      case OrderItemTypes.AUDIOBOOK:
      case OrderItemTypes.RENTAL_DIGITAL:
        // since all three above is considered as 1 quantity always
        flag = true;
        break;
      case OrderItemTypes.PAPERBACK:
        if (
          book?.paperback?.booksAvailable &&
          book?.paperback?.booksAvailable > 0
        ) {
          flag = true;
        }
        break;
      case OrderItemTypes.HARDBOUND:
        if (
          book?.hardbound?.booksAvailable &&
          book?.hardbound?.booksAvailable > 0
        ) {
          flag = true;
        }
        break;
      default:
        break;
    }

    return flag;
  };

  const checkBookValidForAddToCart = () => {
    let flag = false;

    switch (selectedBookTypeToBuy) {
      case OrderItemTypes.BUY_DIGITAL:
      case OrderItemTypes.AUDIOBOOK:
      case OrderItemTypes.RENTAL_DIGITAL:
        break;
      case OrderItemTypes.PAPERBACK:
      case OrderItemTypes.HARDBOUND:
        flag = true;
        break;
      default:
        break;
    }

    return flag;
  };

  const checkForDisabled = () => {
    if (
      orderLoading ||
      (![
        OrderItemTypes.PAPERBACK.toString(),
        OrderItemTypes.HARDBOUND.toString(),
        OrderItemTypes.AUDIOBOOK.toString(),
      ].includes(selectedBookTypeToBuy) &&
        bookAlreadyPurchased)
    ) {
      return true;
    }

    if (
      ([
        OrderItemTypes.PAPERBACK.toString(),
        OrderItemTypes.HARDBOUND.toString(),
      ].includes(selectedBookTypeToBuy))
      && book?.saleableTypesOnlyViaBundle.includes(selectedBookTypeToBuy)
    ) {
      return true
    }
    let flag = false;
    switch (selectedBookTypeToBuy) {
      case OrderItemTypes.BUY_DIGITAL:
        if (!book?.hasDigital) {
          flag = true;
        }
        break;
      case OrderItemTypes.RENTAL_DIGITAL:
        if (!book?.hasRental) {
          flag = true;
        }
        break;
      case OrderItemTypes.PAPERBACK:
        if (!book?.hasPaperback) {
          flag = true;
        }
        break;
      case OrderItemTypes.HARDBOUND:
        if (!book?.hasHardbound) {
          flag = true;
        }
        break;
      case OrderItemTypes.AUDIOBOOK:
        if (!book?.hasAudiobook || book.canListenAudioBook) {
          flag = true;
        }
        break;
      default:
        break;
    }
    return flag;
  };

  const checkDisabledForOutOfStock = () => {
    let disable = false
    if (book) {
      switch (selectedBookTypeToBuy) {
        case OrderItemTypes.PAPERBACK:
          if (book.notifyMe?.notifyMeForPaperback) {
            disable = true
          }
          break
        case OrderItemTypes.HARDBOUND:
          if (book.notifyMe?.notifyMeForHardbound) {
            disable = true
          }
          break
        default:
          break
      }
    }
    return disable
  }

  const changeSelectedBookType = (value: string) => {
    setSelectedBookTypeToBuy(value);
  };
  const changeSelectedRentalType = (value: string) => {
    setSelectedRentalTypeToBuy(value)
  }

  const renderPages = () => {
    if (book) {
      switch (selectedBookTypeToBuy) {
        case OrderItemTypes.BUY_DIGITAL:
        case OrderItemTypes.RENTAL_DIGITAL:
          return book.digital.pages;
        case OrderItemTypes.PAPERBACK:
          return book.paperback.pages;
        case OrderItemTypes.HARDBOUND:
          return book.hardbound.pages;
        default:
          return (
            book.digital?.pages ||
            book.paperback?.pages ||
            book.hardbound?.pages
          );
      }
    }
  };

  const [showAllAuthors] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

  const toggleShowAllAuthors = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(!popoverOpen);
  };
  const remainingAuthorsCount = (book?.authors?.length || 0) - 1;
  const closePopover = () => {
    setPopoverOpen(false);
  };

  if (!book || loading) {
    return <CircularLoader message="Bringing book to you  :)" />;
  }

  function capitalizeFirstLetter(str: string) {
    if (typeof str === 'string') {
      return str
        .split(' ')
        .map(str => str.charAt(0).toUpperCase() + str.slice(1))
        .join(' ');
    }
    return ""
  }

  const handleNotifyMeForBook = async () => {
    if (book && book.id) {
      const data = {
        bookId: book.id,
        bookType: selectedBookTypeToBuy
      }
      const res = await callApi(PATHNAMES.CREATE_NOTIFY_ME_FOR_BOOK, HTTP_METHODS.POST, {
        body: JSON.stringify({ data })
      });
      if (res && res.success && res.data) {
        snackbar({
          variant: SnackBarVariant.SUCCESS,
          message: res.message
        })
        getBookData(book.id)
        return
      }
    } else {
      snackbar({
        variant: SnackBarVariant.ERROR,
        message: "Something went wrong while copying link"
      })
      return
    }
  }

  return (
    <Grid container xs={12}>
      {/* IMAGE ROW  */}
      <Grid container xs={12}>
        {/* BOOK IMG AND SUMMARY  */}
        <Grid container lg={7} md={12} sm={12} xs={12} style={{ marginBottom: "1rem" }} className={classes.bookImageInSmallScreen}>
          <Grid style={{ paddingRight: "2rem" }}>
            <BookImage book={book} />
          </Grid>
          <Grid container lg={7} md={7} sm={7} xs={12} >
            <Grid item xs={12}>
              <Typography variant="h2" style={{ fontWeight: "bolder" }}>
                {book.title.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.bookDetailHeader}>
                Author(s):
              </Typography>
              <Typography variant="h4" className={classes.blueTitle}>
                <span
                  className={classes.underlineOnHover}
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/portal/author?id=${book.authors[0].id}`)}
                >
                  {capitalizeFirstLetter(book.authors[0].name)}
                </span>
              </Typography>
              <Tooltip
                title={
                  <Typography variant="body1">
                    {book.authors.map((author) => (
                      <div key={author.id}>{author.name}</div>
                    ))}
                  </Typography>
                }
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.arrow,
                }}
                arrow
                placement="bottom"
                PopperProps={{
                  disablePortal: true,
                }}
                enterDelay={300}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    marginLeft: "5px",
                    cursor: "pointer",
                    color: "orange",
                  }}
                  onClick={toggleShowAllAuthors}
                >
                  {showAllAuthors ? "+" : remainingAuthorsCount > 0 ? `+${remainingAuthorsCount} more` : ""}
                </span>
              </Tooltip>
              <Popover
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{
                  className: classes.popover,
                }}
              >
                <Box className={classes.box}>
                  <Grid container xs={12}>
                    <Grid container justify="center">
                      <Grid item xs={12}>
                        <Typography variant="h2" align="center" style={{ color: "red", fontWeight: "bold", marginBottom: "0.2rem" }} >Authors</Typography>
                      </Grid>
                    </Grid>
                    <Grid container xs={12}>
                      {book.authors.map((author, index) => (
                        <Grid
                          container
                          xs={12} className={classes.authorName}
                          style={{
                            borderRadius: "20px",
                            backgroundColor: "#f0f0f0",
                            marginBottom: "5px",
                          }}
                        >
                          <Grid container justify="center" xs={4}>
                            <div
                              style={{
                                borderRadius: "50%",
                                width: "60px",
                                height: "60px",
                              }}
                            >
                              <img
                                src={author.image || logo}
                                alt="Default"
                                width="90%"
                                height="90%"
                                style={{
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                  margin: "3px",
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid container justify="center" xs={6}>
                            <Typography
                              variant="h4"
                              style={{
                                fontWeight: "bold",
                                cursor: "pointer",
                                margin: "auto",
                                color: "#000000",
                              }}
                              onClick={() => navigate(`/portal/author?id=${author.id}`)}
                            >
                              {author.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              </Popover>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" className={classes.bookDetailHeader}>
                Publisher:
              </Typography>
              <Typography onClick={() => navigate(`/portal/publisher?id=${publisherId}`)} style={{ cursor: "pointer" }} variant="h4" className={`${classes.publisherHover} ${classes.underlineOnHover}`}  >
                {capitalizeFirstLetter(book?.publisherData?.name ?? "")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.bookDetailHeader}>
                Language:
              </Typography>
              <Typography variant="h4" className={classes.blueTitle}>
                {capitalizeFirstLetter(book.language)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.bookDetailHeader}>
                {selectedBookTypeToBuy === OrderItemTypes.AUDIOBOOK ? "Duration" : "Pages"}:
              </Typography>
              <Typography variant="h4" className={classes.blueTitle}>
                {selectedBookTypeToBuy === OrderItemTypes.AUDIOBOOK ? (book.audioBook?.totalDuration + " sec" || renderPages()) : renderPages()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.bookDetailHeader}>
                Country Of Origin:
              </Typography>
              <Typography variant="h4" className={classes.blueTitle}>
                {capitalizeFirstLetter(book.originCountry)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.bookDetailHeader}>
                Age Range:
              </Typography>
              <Typography variant="h4" className={classes.blueTitle}>
                {/* Though the function is returning string and object
                but typography is not taking string and object.
                here exclusively we have mentioned ReactNode
                if better solution found please fix in future
                
                */}

                {getAgeRangeValueOrLabel(true, book) as ReactNode}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.bookDetailHeader}>
                Average Reading Time:
              </Typography>
              <Typography variant="h4" className={classes.blueTitle}>
                {capitalizeFirstLetter(`${book.avgReadingTime} mins`)}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs='auto'>
            <IconButton onClick={handleCopyLink} className={classes.shareButton} title="Copy Share Link">
              <ShareIcon style={{ fontSize: "2rem" }} />
            </IconButton>
          </Grid>
        </Grid>
        {/* PRICE AND BUY BUTTON  */}
        <Grid container xl={4} lg={4} md={6} sm={8} xs={12} style={{ maxWidth: "22rem" }}>
          <Grid item xs={12} className={classes.noPaddingAndMargin}>
            <Grid container xs={12} spacing={2} className={classes.noPaddingAndMargin}>
              <PriceContainer
                book={book}
                selectedBookTypeToBuy={selectedBookTypeToBuy}
                selectedRentalTypeToBuy={selectedRentalTypeToBuy}
                bookIsFreeToRead={bookIsFreeToRead}
                bookAlreadyPurchased={bookAlreadyPurchased}
                changeSelectedBookType={changeSelectedBookType}
                changeSelectedRentalType={changeSelectedRentalType}
              />
              <Grid item xs={12}>
                {!bookIsFreeToRead &&
                  !(![OrderItemTypes.PAPERBACK.toString(), OrderItemTypes.HARDBOUND.toString()].includes(selectedBookTypeToBuy) &&
                    bookAlreadyPurchased && book.canListenAudioBook) && <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {checkBookHasQuantity() &&
                        <Fab
                          variant="extended"
                          size="small"
                          disabled={checkForDisabled()}
                          onClick={createOrder}
                          style={{ width: "100%" }}
                          className={checkForDisabled() ? classes.disabledBtn : "small"}
                        >
                          {orderLoading && <CircularProgress size={14} style={{ color: "white", marginRight: "10px" }} />}
                          {book.preOrderAvailable ? "Preorder Now" : "Buy Now"}
                        </Fab>
                      }
                    </Grid>
                    <Grid item xs={12}>
                      {checkIfItemAddedToCart() ?
                        <Fab
                          variant="extended"
                          size="small"
                          disabled={checkForDisabled()}
                          onClick={() => navigate("/portal/user/cart")}
                          style={{ width: "100%", color: "white", backgroundColor: "green" }}
                          className={checkForDisabled() ? classes.disabledBtn : "small"}
                        >
                          Go To Cart
                        </Fab> :
                        <>
                          {checkBookValidForAddToCart() && <>

                            {checkBookHasQuantity() ?
                              <Fab
                                variant="extended"
                                size="small"
                                disabled={checkForDisabled()}
                                onClick={addToCart}
                                style={{ width: "100%" }}
                                className={checkForDisabled() ? classes.disabledBtn : "small"}
                              >
                                Add To Cart
                              </Fab> :
                              <>
                                <Fab
                                  variant="extended"
                                  size="small"
                                  disabled
                                  style={{ width: "100%", cursor: "default" }}
                                >
                                  Out of stock
                                </Fab>
                                <Fab
                                  variant="extended"
                                  size="small"
                                  disabled={checkDisabledForOutOfStock()}
                                  onClick={handleNotifyMeForBook}
                                  style={{ width: "100%", cursor: checkDisabledForOutOfStock() ? "default" : "pointer", marginTop: "1rem" }}
                                >
                                  {checkDisabledForOutOfStock() ? "will be notified " : "Notify Me"}
                                </Fab>
                              </>
                            }

                          </>}
                        </>
                      }
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* DESCRIPTION ROW  */}
      <Grid container xs={12}>
        <Header title="Book Description" />
        <Description description={book.description} />
      </Grid>
      {/* ABOUT PUBLISHER ROW  */}
      {book?.publisherData?.bio?.length as number > 1 &&
        <Grid container xs={12}>
          <Header title="About Publisher" />
          <Description description={book.publisherData?.bio as string} />
        </Grid>
      }
      {/* MORE BOOKS FROM PUBLISHER */}
      <Grid container xs={12}>
        <Header title={`More Books from ${book.publisherData?.name}`} />
        <Grid container xs={12} style={{ margin: "2rem 0rem", gap: "6rem 2rem" }}>
          {publisherBooks.map((item, index) => {
            return (
              <Grid item xs='auto'>
                <BookImage book={item} className={classes.hoverProperties} showTitle />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DigitalBookDetail;